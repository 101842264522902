import * as React from 'react';
import {_className, LL_H_C, LL_H_T, LL_V_L, stopPropagation} from '@nu-art/thunderstorm/frontend';
import {DB_Customer} from '@app/shared';
import {ICONSV4} from '@res/icons';
import {ModuleFE_CustomerContact} from '@modules/shopify';
import {dispatcher_onShowDebugData} from '@consts/interfaces';
import {Dialogs} from '@dialog/app-dialogs';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';


const English = /[a-zA-Z-]+/;

export class EditorItem_Customer
	extends Item_Editor<DB_Customer> {

	render() {
		const item = this.props.editable.item;
		return <LL_H_T className="flex__grow match_height margin__inline">
			<LL_V_L className="editor-container margin__end">
				<LL_H_C className="editor-container">
					{this.input('firstName').vertical('Name', {className: 'margin__end'})}
					{this.input('lastName').vertical('Last Name', {className: 'margin__start'})}
				</LL_H_C>

				{this.input('company').vertical('Company')}
				{this.input('email').vertical('Email')}
				{this.input('phone').vertical('Phone')}

				<div className="ll_v_c">
					<ICONSV4.sync onClick={(e) => {
						stopPropagation(e);
						ModuleFE_CustomerContact._v1.shopify.sync({itemIds: item._id, fromShopify: true}).execute();
					}}/>
				</div>

				<ICONSV4.debug onClick={(e) => {
					stopPropagation(e);
					const {blob, ...ours} = item;
					if (!blob || !ours._id)
						return;

					dispatcher_onShowDebugData.dispatchUI(ours._id, 'Customer', {
						ours,
						shopify: JSON.parse(blob)
					});
				}}/>

				<ICONSV4.x onClick={(e) => {
					stopPropagation(e);

					Dialogs.Customer.delete(item as DB_Customer);
				}}/>

				<ICONSV4.translate
					className={_className(English.test(item.lastName || '') && !English.test(item.firstName || ''))}
					onClick={(e) => {
						stopPropagation(e);
						// CustomerModuleFE.delete(item._id)
					}}/>
			</LL_V_L>
		</LL_H_T>;
	}
}
