/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Language} from '@nu-art/thunderstorm/frontend';
import {DeclaredStrings} from './AppLanguage';


export const LanguageEN: Language = {
	shortName: 'English',
	shortLocale: 'en',
};
/**
 * Created by tacb0ss on 28/07/2018.
 */
export const Locale_EN: DeclaredStrings = {
	HelloWorld: 'Hello World',
	Logout: 'Logout',
	connect_a_new_store: 'Connect a Shopify Store',
	DialogTitle_DeleteCustomer: 'Delete Customer \'${0}\'',
	DialogTitle_DeleteInvoice: 'Delete Invoice',
	DialogBody_DeleteInvoice: 'Are you sure you would like to remove invoice: \'${0}\'',
	DialogTitle_DeleteMessageTemplate: 'Delete Message Template \'${0}\'',
	OK: 'OK',
	Cancel: 'Cancel',
	Save: 'Save',
	Create: 'Create',
	Copy: 'Copy',
	Delete: 'Delete',
	Purge: 'Purge',
	About: 'About',
	Products: 'Products',
	ProductTemplates: 'Product Templates',
	ProductTags: 'Product Tags',
	StoreInventory: 'Store Inventory',
	Permissions: 'Permissions',
	Translations: 'Translations',
	DecisionTree: 'Decision Tree',
	MessageTemplate: 'Message Templates',
	InvoiceParser: 'Invoice Parser',
	OrderGenerator: 'Order Generator',
	Product: 'Product',
	Orders: 'Orders',
	OrdersCount: 'Orders: ${0}',
	OrderedBy: 'Ordered By',
	Address: 'Address',
	ShippingAddress: 'Shipping Address',
	ItemsInOrder: 'Items in Order',
	OrderNo: 'Order No: #${0}',
	Customer: 'Customer',
	Customers: 'Customers',
	ShippingOptions: 'Delivery / Pickup',
	DeliveryMethod: 'Delivery Method',
	PaymentMethod: 'Payment Method',
	PastOrders: 'Past Orders',
	Order: 'Order',
	Vendors: 'Vendors',
	Vendor: 'Vendor',
	Date: 'Date',
	TotalValue: 'Total Value',
	Variants: 'Variants',
	Assets: 'Assets',
	Shipments: 'Shipments',
	Shipment: 'Shipment',
	Home: 'Home',
	Intro: 'Hi my name is ${0}, how are you doing',
	Intro2Params: 'param0: ${0}, param1: ${1}',
	InternalRef: 'About: @{About} Home: @{Home}',
	Tooltip_BeResponsible: 'Missing Tooltip',
	Tooltip_ToggleMenuVisibility: 'Show / Hide Menu',
	Tooltip_CreateNewProductTemplate: 'Create a new Product Template',
	Tooltip_CreateNewStoreInventory: 'Create a new Store Inventory',
	Tooltip_DeleteProductTemplate: 'Delete this Product Template',
	Tooltip_DeleteProductTag: 'Delete this Product Tag',
	Tooltip_DeleteStoreInventory: 'Delete this Inventory',
	Tooltip_CreateNewProduct: 'Create a new Product',
	Tooltip_CreateNewShipment: 'Create a new Shipment',
	Tooltip_ImportShipment: 'Import Shipment',
	Tooltip_CreateNewProductTag: 'Create a new Product Tag',
	Tooltip_SyncProductsWithShopify: 'Sync Products with Shopify',
	Tooltip_SyncCustomerOrdersWithShopify: 'Sync Orders with Shopify',
	Tooltip_SyncCustomersWithShopify: 'Sync Customers with Shopify',
	Tooltip_SyncShippingOptionsWithShopify: 'Sync Shipping Options with Shopify',
	Tooltip_SyncStore: 'Sync Store',
	Tooltip_ToggleDebugPanel: 'Show/Hide debug panel',
};
