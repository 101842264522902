"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionProjectsEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const editor_base_1 = require("./editor-base");
const module_pack_1 = require("../../core/module-pack");
const ModuleFE_PermissionsAssert_1 = require("../../modules/ModuleFE_PermissionsAssert");
const ts_common_1 = require("@nu-art/ts-common");
const ts_styles_1 = require("@nu-art/ts-styles");
const ui_props_1 = require("../ui-props");
class PermissionProjectsEditor extends editor_base_1.EditorBase {
    constructor() {
        //######################### Static #########################
        super(...arguments);
        this.module = module_pack_1.ModuleFE_PermissionsProject;
        this.itemName = 'Permission Project';
        this.itemNamePlural = 'Permission Projects';
        this.itemDisplay = (item) => item.name;
        //######################### Render #########################
        this.editorContent = () => {
            const project = this.state.editedItem;
            return React.createElement(frontend_1.LL_V_L, { className: 'match_parent', style: { gap: '8px' } },
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Name', style: { flex: 0 } },
                    React.createElement(frontend_1.TS_Input, { type: 'text', value: project.item.name, onChange: value => this.setProperty('name', value) })),
                this.renderApis());
        };
        this.renderListButton = () => {
            if (this.state.items.length)
                return React.createElement(React.Fragment, null);
            return React.createElement(frontend_1.TS_Button, { className: 'item-list__add-button', onClick: async () => await ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.v1.createProject({}).executeSync() }, "Create Project");
        };
        this.renderApis = () => {
            var _a;
            if (!((_a = this.state.apis) === null || _a === void 0 ? void 0 : _a.length))
                return '';
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'APIs', style: { flexGrow: 1, height: 0 } },
                React.createElement(frontend_1.LL_H_C, { className: 'api-editor' },
                    this.renderApiList(),
                    this.renderApiEditor()));
        };
        this.renderApiList = () => {
            var _a;
            if (!this.state.apis)
                return '';
            const filter = new ts_common_1.Filter(i => [i.path]);
            const apis = (0, ts_common_1.sortArray)(filter.filter(this.state.apis, (_a = this.state.searchValue) !== null && _a !== void 0 ? _a : ''), i => i.path);
            return React.createElement(frontend_1.LL_V_L, { className: 'api-editor__list-wrapper' },
                React.createElement(frontend_1.LL_H_C, { className: 'api-editor__search' },
                    React.createElement(frontend_1.TS_Input, { type: 'text', value: this.state.searchValue, onChange: searchValue => this.setState({ searchValue }) }),
                    React.createElement(ts_styles_1.TS_Icons.Search.component, null)),
                React.createElement(frontend_1.LL_V_L, { className: 'api-editor__list' }, apis.map(api => {
                    return React.createElement("div", { key: api._id, onClick: () => this.setState({ selectedApiId: api._id }), className: (0, frontend_1._className)('api-editor__list__item', api._id === this.state.selectedApiId ? 'selected' : undefined) }, api.path);
                })));
        };
        this.renderApiEditor = () => {
            var _a;
            const _api = (_a = this.state.apis) === null || _a === void 0 ? void 0 : _a.find(i => i._id === this.state.selectedApiId);
            if (!_api)
                return '';
            const api = new frontend_1.EditableDBItem(_api, module_pack_1.ModuleFE_PermissionsApi).setAutoSave(true);
            return React.createElement(frontend_1.LL_V_L, { className: 'api-editor__editor' },
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Path' },
                    React.createElement("div", null, api.item.path)),
                React.createElement(ui_props_1.MultiSelect.AccessLevel, { editable: api, prop: 'accessLevelIds', className: 'api-editor__editor__level-list', itemRenderer: (levelId, onDelete) => {
                        const level = module_pack_1.ModuleFE_PermissionsAccessLevel.cache.unique(levelId);
                        const domain = module_pack_1.ModuleFE_PermissionsDomain.cache.unique(level.domainId);
                        return React.createElement("div", { key: levelId, className: 'api-editor__editor__level-list__item' },
                            React.createElement(ts_styles_1.TS_Icons.x.component, { onClick: onDelete }),
                            `${domain.namespace}: ${level.name} (${level.value})`);
                    } }));
        };
    }
    //######################### Life Cycle #########################
    __onPermissionsApisLoaded() {
        this.reDeriveState();
    }
    __OnPermissionsProjectsUpdated(...params) {
        if ([frontend_1.EventType_Update, frontend_1.EventType_Create].includes(params[0])) {
            const project = params[1];
            this.reDeriveState({
                selectedItemId: project._id,
                editedItem: new frontend_1.EditableDBItem(project, module_pack_1.ModuleFE_PermissionsProject),
                searchValue: undefined
            });
        }
        if (params[0] === frontend_1.EventType_Delete)
            this.reDeriveState({ selectedItemId: undefined, editedItem: undefined, searchValue: undefined });
    }
    async deriveStateFromProps(nextProps, state) {
        state.items = module_pack_1.ModuleFE_PermissionsProject.cache.all();
        if (!state.editedItem && state.items.length) {
            state.editedItem = new frontend_1.EditableDBItem(state.items[0], module_pack_1.ModuleFE_PermissionsProject);
            state.selectedItemId = state.items[0]._id;
        }
        state.apis = state.editedItem ? module_pack_1.ModuleFE_PermissionsApi.cache.filter(i => { var _a; return i.projectId === ((_a = state.editedItem) === null || _a === void 0 ? void 0 : _a.item._id); }) : undefined;
        return state;
    }
}
PermissionProjectsEditor.defaultProps = {
    modules: [module_pack_1.ModuleFE_PermissionsProject]
};
exports.PermissionProjectsEditor = PermissionProjectsEditor;
