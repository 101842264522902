"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Notifications = void 0;
const ComponentSync_1 = require("../../core/ComponentSync");
const ModuleFE_Notifications_1 = require("../../component-modules/ModuleFE_Notifications");
const React = require("react");
const Layouts_1 = require("../Layouts");
const ts_common_1 = require("@nu-art/ts-common");
require("./TS_Notifications.scss");
const TS_ComponentTransition_1 = require("../TS_ComponentTransition");
const tools_1 = require("../../utils/tools");
class TS_Notifications extends ComponentSync_1.ComponentSync {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        this.overlayClass = 'ts-notification-overlay';
        this.containerClass = 'ts-notification-container';
        this.transitionTimeout = 300;
        this.timeout = undefined;
        // ######################### Logic #########################
        this.onClickToClose = (e, eventType) => {
            ModuleFE_Notifications_1.ModuleFE_Notifications.hideAllNotifications();
            const elements = document.elementsFromPoint(e.clientX, e.clientY);
            const firstNonNotificationElement = elements.find(item => {
                //not always false, svg className is an object
                if (typeof item.className !== 'string')
                    return false;
                return item.className && !item.className.includes(this.overlayClass) && !item.className.includes(this.containerClass);
            });
            const ev = new MouseEvent(eventType, {
                bubbles: true,
                cancelable: true,
                view: firstNonNotificationElement.ownerDocument.defaultView,
                detail: 1,
                screenX: e.screenX,
                screenY: e.screenY,
                clientX: e.clientX,
                clientY: e.clientY,
                ctrlKey: e.ctrlKey,
                altKey: e.altKey,
                shiftKey: e.shiftKey,
                metaKey: e.metaKey,
                button: e.button,
                buttons: e.buttons,
                relatedTarget: firstNonNotificationElement,
            });
            //@ts-ignore
            firstNonNotificationElement.dispatchEvent(ev);
        };
        this.deletePost = (e, notification) => {
            (0, tools_1.stopPropagation)(e);
            ModuleFE_Notifications_1.ModuleFE_Notifications.create(notification._id).delete();
            //Next line should be removed when the module dispatches new list after deletion
            this.setState({ notifications: this.state.notifications.filter(item => item._id !== notification._id) });
        };
        this.onNotificationClick = (e, notification) => {
            (0, tools_1.stopPropagation)(e);
        };
        this.renderNotifications = () => {
            if (!this.state.notifications.length)
                return '';
            return this.state.notifications.map(notification => this.renderNotification(notification));
        };
    }
    // ######################### Life Cycle #########################
    __showNotifications(notifications) {
        if (notifications.length === 0) { //If there are no notifications to show
            //Transition notifications out
            this.setState({ showNotifications: false }, () => {
                //Get rid of notifications in state after they are out of view
                this.timeout = setTimeout(() => {
                    this.setState({ notifications });
                }, this.transitionTimeout);
            });
        }
        else { //There are notifications to show
            clearTimeout(this.timeout);
            this.setState({ notifications: [...notifications], showNotifications: true });
            this.forceUpdate();
        }
    }
    deriveStateFromProps(nextProps) {
        return {
            notifications: [],
            showNotifications: false,
        };
    }
    // ######################### Render #########################
    renderNotification(notification) {
        return React.createElement(Layouts_1.LL_V_L, { className: `ts-notification ts-notification__${notification.status}`, key: notification._id, onClick: e => this.onNotificationClick(e, notification) },
            React.createElement(Layouts_1.LL_H_C, { className: 'ts-notification__header' },
                React.createElement("div", { className: 'ts-notification__title' }, notification.title),
                React.createElement("span", { className: 'ts-notification__close', onClick: (e) => {
                        if (notification.status === 'in-progress') {
                            ModuleFE_Notifications_1.ModuleFE_Notifications.hideAllNotifications();
                            return;
                        }
                        this.deletePost(e, notification);
                    } }, "\u2715")),
            React.createElement(Layouts_1.LL_V_L, { className: 'ts-notification__body' },
                React.createElement("div", { className: 'ts-notification__message' }, notification.message)),
            React.createElement(Layouts_1.LL_H_C, { className: 'ts-notification__footer' },
                React.createElement("div", { className: 'ts-notification__timestamp' }, (0, ts_common_1.formatTimestamp)('DD/M - hh:mm A', notification.__created))));
    }
    render() {
        const className = (0, tools_1._className)('ts-notification-container', this.state.notifications.length > 1 ? 'list' : undefined);
        return React.createElement(TS_ComponentTransition_1.TS_ComponentTransition, { trigger: this.state.showNotifications, transitionTimeout: this.transitionTimeout },
            React.createElement("div", { className: 'ts-notification-overlay', onClick: e => this.onClickToClose(e, 'click'), onContextMenu: e => this.onClickToClose(e, 'contextmenu') },
                React.createElement(Layouts_1.LL_V_L, { className: className, onClick: e => this.onClickToClose(e, 'click'), onContextMenu: e => this.onClickToClose(e, 'contextmenu') }, this.renderNotifications())));
    }
}
exports.TS_Notifications = TS_Notifications;
