"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_TranslationClient = exports.ModuleFE_TranslationClient_Class = exports.dispatch_onTranslationClientChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const v3_types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/v3_types");
const shared_1 = require("../shared");
exports.dispatch_onTranslationClientChanged = new v3_types_1.ThunderDispatcherV3('__onTranslationClientUpdated');
class ModuleFE_TranslationClient_Class extends frontend_1.ModuleFE_v3_BaseApi {
    constructor() {
        super(shared_1.DBDef_TranslationClient, exports.dispatch_onTranslationClientChanged);
        this._v1 = {
            '?': (0, frontend_1.apiWithBody)(shared_1.ApiDef_TranslationClient._v1['?']),
            '??': (0, frontend_1.apiWithQuery)(shared_1.ApiDef_TranslationClient._v1['??']),
        };
    }
}
exports.ModuleFE_TranslationClient_Class = ModuleFE_TranslationClient_Class;
exports.ModuleFE_TranslationClient = new ModuleFE_TranslationClient_Class();
