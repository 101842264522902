"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Thunder = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const thunder_dispatcher_1 = require("./thunder-dispatcher");
require("../styles/impl/basic.scss");
require("../styles/impl/icons.scss");
const RDC = require("react-dom/client");
const AppWrapper_1 = require("./AppWrapper");
class Thunder extends ts_common_1.ModuleManager {
    constructor() {
        super();
        this.listeners = [];
        this._DEBUG_FLAG.enable(false);
        // @ts-ignore
        thunder_dispatcher_1.ThunderDispatcher.listenersResolver = () => this.listeners;
    }
    static getInstance() {
        return Thunder.instance;
    }
    init() {
        var _a;
        ts_common_1.BeLogged.addClient(ts_common_1.LogClient_Browser);
        super.init();
        const appJsx = (_a = this.renderFunc) === null || _a === void 0 ? void 0 : _a.call(this, this.props);
        if (!appJsx)
            throw new ts_common_1.ImplementationMissingException('Could not get app from Thunder!');
        //Set root div and its attributes
        const rootDiv = document.createElement('div');
        rootDiv.classList.add('match_parent');
        rootDiv.setAttribute('id', 'root');
        document.body.appendChild(rootDiv);
        //Set app root
        RDC.createRoot(rootDiv).render(appJsx);
        return this;
    }
    addUIListener(listener) {
        this.logInfo(`Register UI listener: ${listener}`);
        this.listeners.push(listener);
    }
    removeUIListener(listener) {
        this.logInfo(`Unregister UI listener: ${listener}`);
        (0, ts_common_1.removeItemFromArray)(this.listeners, listener);
    }
    /**
     * Set up the entry point for the app.
     * @param mainApp - The entry point into the application, what will be rendered directly under root (usually App.tsx)
     * @param props - The props to go with the supplied main app
     * @param renderFunc - The app wrapper, which will usually provide a router. by default is "appWithJSX" which will just return the app
     */
    setMainApp(mainApp, props = {}, renderFunc = AppWrapper_1.appWithJSX) {
        this.props = {
            element: mainApp,
            props: props
        };
        this.renderFunc = renderFunc;
        return this;
    }
    build(onStarted) {
        super.build();
        onStarted === null || onStarted === void 0 ? void 0 : onStarted();
    }
    getConfig() {
        return this.config;
    }
}
exports.Thunder = Thunder;
