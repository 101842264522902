"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_ProgressBar = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
require("./TS_ProgressBar.scss");
class TS_ProgressBar extends ComponentSync_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderLinearBar = () => {
            return React.createElement("div", { className: 'ts-progress-bar__linear-bar' },
                React.createElement("div", { className: 'ts-progress-bar__linear-bar__text' },
                    this.state.percentage,
                    "%"),
                React.createElement("div", { className: 'ts-progress-bar__linear-bar__bar', style: { width: `${this.state.percentage}%` } },
                    React.createElement("div", { className: 'ts-progress-bar__linear-bar__text' },
                        this.state.percentage,
                        "%")));
        };
        this.renderRadialBar = () => {
            const radius = this.props.radius;
            const strokeDashArray = 2 * Math.PI * radius;
            const strokeDashOffset = strokeDashArray - (strokeDashArray * this.props.ratio);
            return React.createElement("div", { className: 'ts-progress-bar__radial-bar' },
                React.createElement("div", { className: 'ts-progress-bar__radial-bar__text' },
                    this.state.percentage,
                    "%"),
                React.createElement("svg", { className: "ts-progress-bar__radial-bar__bar", viewBox: '0 0 100 100' },
                    React.createElement("circle", { cx: "50", cy: "50", r: "30", strokeDasharray: strokeDashArray, strokeDashoffset: strokeDashOffset })));
        };
    }
    deriveStateFromProps(nextProps, state) {
        state !== null && state !== void 0 ? state : (state = this.state ? Object.assign({}, this.state) : {});
        if (nextProps.ratio < 0 || nextProps.ratio > 1)
            throw new ts_common_1.BadImplementationException('Ratio passed must be normalized to 0 < ratio < 1');
        state.percentage = Math.floor(nextProps.ratio * 100);
        return state;
    }
    getRenderer() {
        switch (this.props.type) {
            case 'linear-bar':
                return this.renderLinearBar;
            case 'radial':
                return this.renderRadialBar;
        }
    }
    render() {
        const Renderer = this.getRenderer();
        return React.createElement("div", { className: 'ts-progress-bar' },
            React.createElement(Renderer, null));
    }
}
TS_ProgressBar.defaultProps = {
    type: 'linear-bar',
    radius: 30,
};
exports.TS_ProgressBar = TS_ProgressBar;
