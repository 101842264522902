"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Button = void 0;
const React = require("react");
const tools_1 = require("../../utils/tools");
require("./TS_Button.scss");
/**
 * A button made simpler
 *
 *
 * <b>SCSS:</b>
 * ```scss
 * .ts-button {
 *   .ts-button__disabled {
 *   }
 * }
 * ```
 */
const TS_Button = (props) => {
    const { onDisabledClick } = props, rest = __rest(props, ["onDisabledClick"]);
    return React.createElement("div", Object.assign({}, rest, { className: (0, tools_1._className)('ts-button', props.className, props.disabled && 'ts-button__disabled'), onClick: props.disabled ? onDisabledClick : props.onClick }), props.children);
};
exports.TS_Button = TS_Button;
