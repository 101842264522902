"use strict";
/*
 * Firebase is a simpler Typescript wrapper to all of firebase services.
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirebaseAnalyticsModule = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ModuleFE_Firebase_1 = require("../ModuleFE_Firebase");
class FirebaseAnalyticsModule_Class extends ts_common_1.Module {
    constructor() {
        super(...arguments);
        this._init = async () => {
            const session = await ModuleFE_Firebase_1.ModuleFE_Firebase.createSession();
            this.analytics = session.getAnalytics();
            this.analytics.setAnalyticsCollectionEnabled(true);
        };
    }
    init() {
        this.runAsync('Init Analytics', this._init);
    }
    logEvent(eventName, eventParams) {
        if (!this.analytics)
            throw new ts_common_1.ImplementationMissingException('Missing analytics wrapper');
        return this.analytics.logEvent(eventName, eventParams);
    }
    setCurrentScreen(screenName) {
        if (!this.analytics)
            throw new ts_common_1.ImplementationMissingException('Missing analytics wrapper');
        return this.analytics.setCurrentScreen(screenName);
    }
}
exports.FirebaseAnalyticsModule = new FirebaseAnalyticsModule_Class();
