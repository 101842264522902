"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_AutoComplete = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const Validator_AutoComplete = {
    key: ts_common_1.tsValidateStringWithDashes,
    text: (0, ts_common_1.tsValidateString)(),
};
exports.DBDef_AutoComplete = {
    validator: Validator_AutoComplete,
    dbName: 'auto-complete',
    entityName: 'auto-complete',
};
