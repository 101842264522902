import * as React from 'react';
import {PackageType_Size, PackagingStatus} from '@app/shared';
import {ModuleFE_CustomerOrder} from '@modules/index';
import {_className, EditableItem, LL_H_C, LL_V_L, stopPropagation, TS_Checkbox, TS_PropRenderer, TS_Space} from '@nu-art/thunderstorm/frontend';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';
import {ICONSV4} from '@res/icons';
import {removeFromArrayByIndex} from '@nu-art/ts-common';
import {OtherDropDown} from '@pah/app-dropdown';


export const Component_OrderPackedStatus = (props: { editable: EditableItem<PackagingStatus> }) => {
	return <TS_Checkbox className={'ts-print__gone'} rounded={true} checked={props.editable.item.isReady} onCheck={(isReady, e) => {
		stopPropagation(e);
		return props.editable.update('isReady', isReady);
	}}>isPacked</TS_Checkbox>;
};

export class Component_PackagingStatus
	extends Item_Editor<PackagingStatus> {

	static defaultProps = {
		moduleFE: ModuleFE_CustomerOrder,
	};

	render() {
		const editable: EditableItem<PackagingStatus> = this.props.editable;
		const packagesEditable = editable.editProp('packagesSize', []);
		return <LL_V_L className={_className('customer-order__section customer-order__packaging-status', packagesEditable.item.length === 0 && 'ts-print__gone')}>
			<div className="customer-order__title">Packaging Status</div>
			<Component_OrderPackedStatus editable={editable}/>
			<TS_Space height={8}/>
			{this.renderPackages(packagesEditable)}
		</LL_V_L>;

	}

	private renderPackages(editable: EditableItem<PackageType_Size[]>) {
		const item = editable.item;

		const Header = <LL_H_C className="flex__space-between">
			Packages
			<ICONSV4.add className="ts-print__gone" onClick={() => {
				return editable.update(item.length, PackageType_Size.Small);
			}}/>
		</LL_H_C>;
		return <TS_PropRenderer.Vertical label={Header}>
			{item.map((_package, i) => {
				return <LL_H_C key={i} className="delivery-package">
					<ICONSV4.remove onClick={() => {
						removeFromArrayByIndex(item, i);
						return editable.save();
					}}/>
					<span>{i + 1} - </span>
					<OtherDropDown.PackageSize selected={_package} onSelected={_package => {
						return editable.update(i, _package);
					}}/>
				</LL_H_C>;
			})}
		</TS_PropRenderer.Vertical>;
	}
}