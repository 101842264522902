import * as React from 'react';
import {
	Header,
	LL_H_C,
	LL_VH_C,
	ModuleFE_RoutingV2,
	stopPropagation,
	StorageKey,
	TableHeaders,
	TS_Loader,
	TS_Route,
	TS_Table
} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_Shipments, ModuleFE_Vendors} from '@modules/index';
import * as Routes from '@consts/routes-consts';
import {sortArray} from '@nu-art/ts-common/utils/array-tools';
import {currentTimeMillis, formatTimestamp} from '@nu-art/ts-common';
import {DB_Shipment, DB_Vendor} from '@app/shared';
import {LOCALE} from '@res/locale';
import {ICONSV4} from '@res/icons';
import {Page_DBItemListV2, Props_DBItemListV2, State_DBItemListV2} from '@component/Page_DBItemListV2';
import {DateEditor, normalizeDateByFormat} from '@component/DateRenderer';
import {Props_SmartComponent, State_SmartComponent} from '@nu-art/thunderstorm/frontend';
import {Dialog_ImportShipment} from './dialogs/Dialog_ImportShipment';
import {Page_ShipmentV2} from '../shipment-v2/Page_ShipmentV2';


export const DateFormat_DD_MM_YYYY_WithDashes = 'DD-MM-YYYY';
export const DateFormat_DD_MM_YYYY_WithSlash = 'DD/MM/YYYY';
export const TimeFormat_HH_mm_ss_WithSemicolon = 'HH:mm:ss';

type State = { fromDate: number, toDate: number, vendors: DB_Vendor[] }

const StorageKey_fromDate = new StorageKey<number>('shipment-list--from-date');
const StorageKey_toDate = new StorageKey<number>('shipment-list--to-date');

type FullProps = Props_SmartComponent & Props_DBItemListV2<DB_Shipment>;
type FullState = State_SmartComponent & State_DBItemListV2<DB_Shipment> & State;

type Actions = 'debug';

// const tableHeaders: TableHeaders<DB_CustomerOrder, Actions> = ['orderNumber', 'timestampCreated', 'customerId', 'shippingAddress', 'shippingOption', 'itemIds', 'sync', 'debug'];
const tableHeaders1: TableHeaders<DB_Shipment, Actions> = [
	{header: 'locked', widthPx: 30},
	{header: '__updated', widthPx: 100},
	{header: 'landedDate', widthPx: 100},
	{header: 'vendorId', widthPx: 100},
	{header: 'totalValue', widthPx: 200},
	{header: 'currencyRates', widthPx: 100},
	{header: '_assetsIds', widthPx: 30},
	{header: 'paymentIds', widthPx: 30},
	{header: 'wayBill', widthPx: 40},
	{header: '_variantIds', widthPx: 100},
	{header: 'invoices', widthPx: 100},
];

export class Page_ShipmentList
	extends Page_DBItemListV2<DB_Shipment, State> {

	static Route: TS_Route = {path: 'list/v1', key: 'shipments-list-v1', Component: this};

	static defaultProps = {
		pageTitle: 'Shipments List',
		childRoute: Page_ShipmentV2.Route,
		newRoute: Routes.RouteKey_ShipmentNew,
		keys: ['_id'],
		moduleFE: ModuleFE_Shipments,
		modules: [ModuleFE_Shipments, ModuleFE_Vendors],
		headerButtons: [{
			icon: 'add',
			tooltip: 'Tooltip_CreateNewShipment',
			action: () => {
			}
		}, {
			icon: 'file_import',
			tooltip: 'Tooltip_ImportShipment',
			action: () => {
				Dialog_ImportShipment.show();
			}
		}],
	};

	protected async deriveStateFromProps(nextProps: FullProps, state: FullState) {
		await super.deriveStateFromProps(nextProps, state);
		state.items = await nextProps.moduleFE.cache.filter((shipment) => {
			const fromDate = this.state.fromDate;
			const toDate = this.state.toDate;
			const payedDate = shipment.payedDate || 0;
			const arrivedDate = shipment.arrivedDate || 0;
			return payedDate > fromDate && (arrivedDate < toDate || payedDate < toDate);
		});

		state.toDate = StorageKey_toDate.get(currentTimeMillis());
		state.fromDate = StorageKey_fromDate.get(normalizeDateByFormat(currentTimeMillis(), 'YYYY'));
		state.vendors = ModuleFE_Vendors.cache.allMutable();
		return state;
	}

	protected renderItems(_items: DB_Shipment[]): React.ReactNode {
		if (!_items)
			return <TS_Loader/>;

		const items = sortArray(_items, item => item.landedDate);
		const headerRenderer = (columnKey: Header<TableHeaders<DB_Shipment, Actions>>) => {
			switch (columnKey) {
				case 'locked':
					return <LL_VH_C><ICONSV4.lock/></LL_VH_C>;
				case '__updated':
					return 'Sync';
				case 'landedDate':
					return LOCALE.Date();
				case 'vendorId':
					return LOCALE.Vendor();
				case 'totalValue':
					return LOCALE.TotalValue();
				case '_assetsIds':
					return LOCALE.Assets();
				case '_variantIds':
					return LOCALE.Variants();
				case 'currencyRates':
					return 'Currency';
				case 'paymentIds':
					return 'Payments';
				default:
					return columnKey as string;
			}
		};

		return (
			<div className={'page__shipment-list match_width'} style={{marginInlineStart: 12}}>
				<LL_H_C>{DateEditor(this.state.fromDate, fromDate => {
					StorageKey_fromDate.set(fromDate);
					this.reDeriveState();
				})} =&gt; {DateEditor(this.state.toDate, toDate => {
					StorageKey_toDate.set(toDate);
					this.reDeriveState();
				})}</LL_H_C>
				<TS_Table<DB_Shipment, Actions>
					id="shipment"
					header={tableHeaders1}
					headerRenderer={headerRenderer}
					rows={items}
					tr={(row, rowIndex) => ({onClick: () => row && ModuleFE_RoutingV2.goToRoute(Page_ShipmentV2.Route, {_id: row._id})})}
					cellRenderer={(prop, item, index) => {
						if (prop === '__updated')
							return <div
								className="ll_v_c">{formatTimestamp(DateFormat_DD_MM_YYYY_WithSlash, item[prop])}<br/>{formatTimestamp(TimeFormat_HH_mm_ss_WithSemicolon, item[prop])}
							</div>;

						if (prop === 'locked')
							return <div className="ll_v_c" onClick={(e) => {
								stopPropagation(e);
								item[prop] = !item[prop];
								ModuleFE_Shipments.v1.upsert(item).execute();
							}}>{<ICONSV4.lock/>}</div>;

						if (prop === 'landedDate')
							return formatTimestamp(DateFormat_DD_MM_YYYY_WithDashes, item[prop]);

						if (prop === 'vendorId')
							return item[prop] && this.state.vendors.find(v => v._id === item[prop])?.title;

						if (prop === 'currencyRates')
							return `${item[prop]?.[0].factor || 0}`;

						if (prop === 'totalValue')
							return `${item[prop]?.value || 0} ${item[prop]?.currency || ''}`;

						if (prop === '_assetsIds')
							return item[prop]?.length || 0;

						if (prop === 'paymentIds')
							return item[prop]?.length || 0;

						if (prop === 'wayBill')
							return item[prop]?.id;

						if (prop === '_variantIds')
							return item[prop]?.length || 0;

						if (prop === 'invoices')
							return `(${item[prop]?.length || 0}) - ${item[prop]?.map(i => i.invoiceNumber)}`;

					}}
				/>
			</div>
		);
	}
}
