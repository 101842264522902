"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_BackupV2 = exports.ApiDef_Backup = void 0;
const types_1 = require("../types");
exports.ApiDef_Backup = {
    vv1: {
        initiateBackup: { method: types_1.HttpMethod.GET, path: 'v1/initiate-backup' },
        fetchBackupDocs: { method: types_1.HttpMethod.GET, path: 'v1/fetch-backup-docs' },
    }
};
exports.ApiDef_BackupV2 = {
    vv1: {
        initiateBackup: { method: types_1.HttpMethod.GET, path: 'v1/initiate-backup-v2' },
        fetchBackupDocs: { method: types_1.HttpMethod.GET, path: 'v1/fetch-backup-docs-v2' },
    }
};
