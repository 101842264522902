"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_EditableText = void 0;
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
require("./TS_EditableText.scss");
const TS_Input_1 = require("../TS_Input");
const TS_Button_1 = require("../TS_Button");
const tools_1 = require("../../utils/tools");
const TS_BusyButton_1 = require("../TS_BusyButton");
const TS_ReadMore_1 = require("../TS_ReadMore");
class TS_EditableText_Base extends ComponentSync_1.ComponentSync {
    constructor() {
        // ######################## Life Cycle ########################
        super(...arguments);
        // ######################## Logic ########################
        this.onEnableEdit = () => {
            if (this.props.disableEdit || this.props.editMode !== undefined)
                return;
            this.setState({ text: this.state.original, isEditing: true });
        };
        this.onTextChange = (text) => {
            this.setState({ text });
        };
        this.onSubmitChanges = async (e) => {
            var _a, _b;
            (0, tools_1.stopPropagation)(e);
            const text = this.state.text;
            if (!this.props.onTextSaved)
                this.setState({ original: text, isEditing: false });
            await ((_b = (_a = this.props).onTextSaved) === null || _b === void 0 ? void 0 : _b.call(_a, text));
            this.setState({ original: text });
        };
        this.onCancelChanges = (e) => {
            var _a, _b;
            if (e)
                (0, tools_1.stopPropagation)(e);
            const original = this.state.original;
            if (!this.props.onCancel)
                this.setState({ text: original, isEditing: false });
            this.setState({ text: original });
            (_b = (_a = this.props).onCancel) === null || _b === void 0 ? void 0 : _b.call(_a);
        };
        this.onResetChanges = (e) => {
            (0, tools_1.stopPropagation)(e);
            const original = this.state.original;
            this.setState({ text: original });
        };
        this.onGeneralClick = (e) => {
            if (this.state.isEditing)
                (0, tools_1.stopPropagation)(e);
        };
        this.handleBlur = (e) => {
            e.persist();
            //e.relatedTarget can be null if lost focus due to Enter
            if (e.relatedTarget === null || this.state.parentRef.current === e.relatedTarget || this.state.parentRef.current.contains(e.relatedTarget))
                return;
            this.onCancelChanges();
        };
        // ######################## Render ########################
        this.renderText = () => {
            var _a;
            const textToShow = (_a = this.props.shownText) !== null && _a !== void 0 ? _a : this.state.original;
            return React.createElement("div", { className: 'ts-editable-text__text', onClick: this.onEnableEdit },
                this.props.readMore && React.createElement(TS_ReadMore_1.TS_ReadMore, { collapsedHeight: this.props.readMoreCollapseHeight, text: textToShow }),
                !this.props.readMore && (this.props.highlightText
                    ? (0, tools_1.stringReplacer)(textToShow, this.props.highlightText, (match, i) => React.createElement("i", { key: i, className: 'ts-editable-text__highlight' }, match))
                    : textToShow));
        };
        this.renderButton = {
            reset: () => {
                var _a;
                return React.createElement(TS_Button_1.TS_Button, { className: 'ts-editable-text__buttons__reset', onClick: this.onResetChanges }, ((_a = this.props.renderers) === null || _a === void 0 ? void 0 : _a.resetButton) || 'Reset');
            },
            cancel: () => {
                var _a;
                return React.createElement(TS_Button_1.TS_Button, { className: 'ts-editable-text__buttons__cancel', onClick: this.onCancelChanges }, ((_a = this.props.renderers) === null || _a === void 0 ? void 0 : _a.cancelButton) || 'Cancel');
            },
            accept: () => {
                var _a;
                return React.createElement(TS_BusyButton_1.TS_BusyButton, { className: 'ts-editable-text__buttons__save', onClick: this.onSubmitChanges }, ((_a = this.props.renderers) === null || _a === void 0 ? void 0 : _a.saveButton) || 'Save');
            }
        };
    }
    deriveStateFromProps(nextProps) {
        var _a;
        const state = Object.assign({}, this.state) || {};
        state.original = nextProps.text;
        state.text || (state.text = nextProps.text);
        state.isEditing = (_a = nextProps.editMode) !== null && _a !== void 0 ? _a : false;
        state.parentRef || (state.parentRef = React.createRef());
        return state;
    }
}
class TS_EditableText_TextArea extends TS_EditableText_Base {
    constructor() {
        super(...arguments);
        this.renderButtons = () => {
            return React.createElement("div", { className: 'ts-editable-text-area__buttons' },
                !this.props.hideReset && this.renderButton.reset(),
                this.renderButton.cancel(),
                this.renderButton.accept());
        };
        this.renderTextArea = () => {
            return React.createElement("div", { className: 'ts-editable-text-area__edit' },
                React.createElement(TS_Input_1.TS_TextArea, { className: 'ts-editable-text-area__text-area', type: 'text', focus: true, value: this.state.isEditing ? this.state.text : this.props.shownText, onChange: this.onTextChange, onAccept: (val, e) => this.onSubmitChanges(e) }),
                this.renderButtons());
        };
    }
    render() {
        const Renderer = this.state.isEditing ? this.renderTextArea : this.renderText;
        const className = (0, tools_1._className)('ts-editable-text-area', this.props.className);
        return React.createElement("div", { className: className, onBlur: this.handleBlur, tabIndex: 1, onClick: this.onGeneralClick, ref: this.state.parentRef },
            React.createElement(Renderer, null));
    }
}
class TS_EditableText_Input extends TS_EditableText_Base {
    constructor() {
        super(...arguments);
        this.renderInput = () => {
            return React.createElement("div", { className: 'ts-editable-text-input__edit' },
                this.renderButton.reset(),
                React.createElement(TS_Input_1.TS_Input, { className: 'ts-editable-text-input__input', type: 'text', focus: true, value: this.state.text, onChange: this.onTextChange, onAccept: (val, e) => this.onSubmitChanges(e) }),
                React.createElement("div", { className: 'ts-editable-text-input__buttons' },
                    this.renderButton.cancel(),
                    this.renderButton.accept()));
        };
    }
    render() {
        const Renderer = this.state.isEditing ? this.renderInput : this.renderText;
        const className = (0, tools_1._className)('ts-editable-text-input', this.props.className);
        return React.createElement("div", { className: className, tabIndex: 1, onBlur: this.handleBlur, onClick: this.onGeneralClick, ref: this.state.parentRef },
            React.createElement(Renderer, null));
    }
}
exports.TS_EditableText = {
    TextArea: TS_EditableText_TextArea,
    Input: TS_EditableText_Input,
};
