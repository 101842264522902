import * as React from 'react';
import {FirebaseAnalyticsModule} from '@nu-art/firebase/frontend';
import {
	AppToolsScreen,
	genericNotificationAction,
	LL_H_C,
	LL_V_L,
	ModuleFE_BaseApi,
	SmartComponent,
	State_SmartComponent,
	Thunder,
	TS_BusyButton
} from '@nu-art/thunderstorm/frontend';
import {DB_Object, Minute, sortArray} from '@nu-art/ts-common';
import {ModuleFE_SyncEnvV2} from '@nu-art/thunderstorm/frontend/modules/sync-env/ModuleFE_SyncEnvV2';


type ATS_SyncCollectionToEnv_Props = {
	//
};
type ATS_SyncCollectionToEnv_State = {
	upgradableModules: ModuleFE_BaseApi<any, any>[];
};

export class ATS_SyncCollectionToEnv
	extends SmartComponent<ATS_SyncCollectionToEnv_Props, ATS_SyncCollectionToEnv_State> {

	static screen: AppToolsScreen = {name: `DevTool - SyncCollectionToEnv`, renderer: this};

	static defaultProps = {
		modules: () => Thunder.getInstance().filterModules(module => (module as unknown as {
			ModuleFE_BaseDB: boolean
		}).ModuleFE_BaseDB),
		pageTitle: () => this.screen.name
	};

	constructor(p: ATS_SyncCollectionToEnv_Props) {
		super(p);
		// @ts-ignore
		FirebaseAnalyticsModule.setCurrentScreen(this.pageTitle);
	}

	protected async deriveStateFromProps(nextProps: {}, state: ATS_SyncCollectionToEnv_State & State_SmartComponent) {
		state.upgradableModules ??= sortArray(Thunder.getInstance().filterModules(module => {
			const _module = module as ModuleFE_BaseApi<any, any>;
			return (!!_module.getCollectionName && !!_module.v1.upgradeCollection);
		}), i => i.getCollectionName());
		return state;
	}

	private syncToEnv = async (collectionName: string, module: ModuleFE_BaseApi<DB_Object, any>) => {
		await genericNotificationAction(async () => {
			const toSyncRequest = {env: 'dev' as const, moduleName: module.dbDef.dbName, items: module.cache.allMutable()};
			await ModuleFE_SyncEnvV2.vv1.syncToEnv(toSyncRequest).setTimeout(5 * Minute)
				.executeSync();
		}, `Upgrading ${collectionName}`);
	};

	render() {
		return <LL_V_L className="collection-upgrades-page">
			<LL_H_C className={'buttons-container'}>
				{this.state.upgradableModules.map(module => {
					const name = module.getCollectionName().replace(/-/g, ' ');
					return <TS_BusyButton
						key={name}
						onClick={() => this.syncToEnv(name, module)}
					>{name} ({module.cache.all().length})</TS_BusyButton>;
				})}
			</LL_H_C>
		</LL_V_L>;
	}
}
