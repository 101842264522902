"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Tree = void 0;
const React = require("react");
const react_1 = require("react");
const tools_1 = require("../../utils/tools");
require("./TS_Tree.scss");
const ComponentSync_1 = require("../../core/ComponentSync");
const ts_common_1 = require("@nu-art/ts-common");
const ignoreToggler = () => {
};
class TS_Tree extends ComponentSync_1.ComponentSync {
    // ######################## Life Cycle ########################
    constructor(props) {
        super(props);
        this.containerRefs = {};
        this.rendererRefs = {};
        // ######################## Logic ########################
        this.onNodeClicked = (e) => {
            var _a, _b;
            const path = e.currentTarget.getAttribute('data-path');
            if (!path)
                return this.logError('No Path for tree node:', e);
            //FIXME: consider typing the return from resolveItemFromPath instead of limiting the return to just the item
            (_b = (_a = this.props).onNodeClicked) === null || _b === void 0 ? void 0 : _b.call(_a, path, TS_Tree.resolveItemFromPath(this.state.adapter.data, path));
        };
        this.onContextMenuClicked = (e) => {
            var _a, _b;
            const path = e.currentTarget.getAttribute('data-path');
            if (!path)
                return this.logError('No Path for tree node:', e);
            //FIXME: consider typing the return from resolveItemFromPath instead of limiting the return to just the item
            (_b = (_a = this.props).onContextMenuClicked) === null || _b === void 0 ? void 0 : _b.call(_a, e, path, TS_Tree.resolveItemFromPath(this.state.adapter.data, path));
        };
        this.toggleExpandState = (e, _expanded) => this.expandOrCollapse(this.resolveTreeNode(e.currentTarget), _expanded);
        this.expandOrCollapse = (path, forceExpandState) => {
            if (path === '/' && this.state.adapter.hideRoot && forceExpandState === false)
                return;
            const treeExpandedState = this.state.expanded;
            const currentExpandState = treeExpandedState[path];
            let newExpandState = currentExpandState === undefined;
            if (forceExpandState !== undefined)
                newExpandState = forceExpandState ? forceExpandState : false;
            if (newExpandState)
                treeExpandedState[path] = newExpandState;
            else
                delete treeExpandedState[path];
            this.forceUpdate();
        };
        // ######################## Render ########################
        this.renderNode = (_data, key, _path, level) => {
            var _a, _b;
            const nodePath = `${_path}${key}/`;
            const adjustedNode = this.state.adapter.adjust(_data);
            const data = adjustedNode.data;
            let filteredKeys = [];
            let expanded = !!this.props.checkExpanded(this.state.expanded, nodePath);
            if (nodePath.endsWith('_children/'))
                expanded = true;
            let renderChildren = expanded;
            if (typeof data !== 'object')
                renderChildren = false;
            if (renderChildren)
                filteredKeys = this.state.adapter.getFilteredChildren(_data);
            const nodeRefResolver = this.nodeResolver(nodePath, renderChildren, filteredKeys);
            const containerRefResolver = this.resolveContainer(nodePath, renderChildren, filteredKeys);
            const isSelected = ((_b = (_a = this.state).isSelected) === null || _b === void 0 ? void 0 : _b.call(_a, _data)) || _data === this.state.selected.item;
            return React.createElement(react_1.Fragment, { key: nodePath },
                this.renderItem(data, nodePath, key, nodeRefResolver, level, isSelected, expanded),
                this.renderChildren(data, nodePath, _path, level, filteredKeys, renderChildren, adjustedNode, containerRefResolver));
        };
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }
    deriveStateFromProps(nextProps) {
        var _a;
        return {
            adapter: nextProps.adapter,
            expanded: nextProps.expanded || ((_a = this.state) === null || _a === void 0 ? void 0 : _a.expanded) || { '/': true },
            isSelected: nextProps.isSelected,
            selected: {
                path: nextProps.selectedPath,
                item: nextProps.selectedItem
            }
        };
    }
    componentDidUpdate() {
        var _a, _b, _c, _d;
        if (!this.props.scrollSelectedIntoView || !(((_a = this.state.selected) === null || _a === void 0 ? void 0 : _a.item) || ((_b = this.state.selected) === null || _b === void 0 ? void 0 : _b.path)) || !((_c = this.props.containerRef) === null || _c === void 0 ? void 0 : _c.current))
            return;
        const itemPath = (_d = this.state.selected.path) !== null && _d !== void 0 ? _d : (0, ts_common_1._keys)(this.rendererRefs).find(key => this.getItemByPath(key) === this.state.selected.item);
        const childRect = this.rendererRefs[itemPath].getBoundingClientRect();
        const containerRect = this.props.containerRef.current.getBoundingClientRect();
        const inView = (childRect.top >= containerRect.top) && (childRect.bottom <= containerRect.top + this.props.containerRef.current.clientHeight);
        if (!inView) {
            const scrollTop = childRect.top - containerRect.top;
            const scrollBot = childRect.bottom - containerRect.bottom;
            let scroll = this.props.containerRef.current.scrollTop;
            if (Math.abs(scrollTop) < Math.abs(scrollBot))
                scroll += scrollTop;
            else
                scroll += scrollBot;
            this.props.containerRef.current.scroll({ top: scroll });
        }
    }
    nodeResolver(nodePath, renderChildren, filteredKeys) {
        return (_ref) => {
            if (this.rendererRefs[nodePath])
                return;
            this.rendererRefs[nodePath] = _ref;
            if (this.containerRefs[nodePath] && renderChildren && filteredKeys.length > 0)
                this.forceUpdate();
        };
    }
    resolveContainer(nodePath, renderChildren, filteredKeys) {
        return (_ref) => {
            if (this.containerRefs[nodePath])
                return;
            this.containerRefs[nodePath] = _ref;
            if (renderChildren && filteredKeys.length > 0)
                this.forceUpdate();
        };
    }
    getItemByPath(path) {
        return TS_Tree.resolveItemFromPath(this.state.adapter.data, path);
    }
    static resolveItemFromPath(data, path) {
        if (!path)
            return;
        let item = data;
        const hierarchy = path.split('/');
        hierarchy.shift();
        for (const el of hierarchy) {
            if (el) {
                item = item[el];
                if (!item)
                    return;
            }
        }
        return item;
    }
    resolveTreeNode(currentTarget) {
        if (!currentTarget) {
            this.logError('Could not find node!!');
            return '';
        }
        if (!currentTarget.getAttribute('data-path'))
            return this.resolveTreeNode(currentTarget.parentElement || undefined);
        return currentTarget.getAttribute('data-path') || '';
    }
    renderChildren(data, nodePath, _path, level, filteredKeys, renderChildren, adjustedNode, containerRefResolver) {
        if (!(filteredKeys.length > 0 && renderChildren))
            return;
        const containerRef = this.containerRefs[nodePath];
        return (React.createElement("div", { className: "ts-tree__children-container", ref: containerRefResolver }, containerRef && filteredKeys.map((childKey) => this.renderNode(data[childKey], childKey, nodePath + (adjustedNode.deltaPath ? adjustedNode.deltaPath + '/' : ''), level + 1))));
    }
    renderItem(item, path, key, nodeRefResolver, level, isSelected, expanded) {
        if (this.state.adapter.hideRoot && path.length === 1)
            return null;
        const TreeNodeRenderer = this.state.adapter.treeNodeRenderer;
        // console.log("isParent: ", this.state.adapter.isParent(item));
        const isParent = this.state.adapter.isParent(item);
        const node = {
            adapter: this.state.adapter,
            propKey: key,
            item,
            expandToggler: isParent ? this.toggleExpandState : ignoreToggler,
            expanded: !!expanded,
        };
        if (this.state.adapter.childrenKey === key)
            return null;
        const className = (0, tools_1._className)('ts-tree__node', isParent && 'ts-tree__parent-node', isSelected && 'ts-tree__selected-node', `depth-${level}`);
        return React.createElement("div", { tabIndex: 1, "data-path": path, className: className, ref: nodeRefResolver, onClick: this.onNodeClicked, onContextMenu: this.onContextMenuClicked },
            React.createElement(TreeNodeRenderer, { item: item, node: node }));
    }
    render() {
        return React.createElement("div", { className: (0, tools_1._className)('ts-tree', this.props.className), style: this.props.treeContainerStyle }, this.renderNode(this.state.adapter.data, '', '', (this.state.adapter.hideRoot ? -1 : 0)));
    }
}
// ######################## Static ########################
TS_Tree.defaultProps = {
    checkExpanded: (expanded, path) => expanded[path]
};
exports.TS_Tree = TS_Tree;
