"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseHttpModule_Class = void 0;
const ts_common_1 = require("@nu-art/ts-common");
class BaseHttpModule_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.timeout = 10000;
        this.defaultHeaders = {};
        this.setDefaultOnComplete = (defaultOnComplete) => {
            this.defaultOnComplete = defaultOnComplete;
        };
        this.setDefaultConfig({ compress: true });
    }
    init() {
        this.timeout = this.config.timeout || this.timeout;
    }
    getOrigin() {
        return this.origin;
    }
    shouldCompress() {
        return this.config.compress;
    }
    addDefaultHeader(key, header) {
        this.defaultHeaders[key] = header;
    }
    getDefaultHeaders() {
        return Object.keys(this.defaultHeaders).reduce((toRet, _key) => {
            const defaultHeader = this.defaultHeaders[_key];
            switch (typeof defaultHeader) {
                case 'string':
                    toRet[_key] = [defaultHeader];
                    break;
                case 'function':
                    toRet[_key] = defaultHeader();
                    break;
                case 'object':
                    if (Array.isArray(defaultHeader)) {
                        toRet[_key] = defaultHeader;
                        break;
                    }
                // eslint-disable-next-line no-fallthrough
                case 'boolean':
                case 'number':
                case 'symbol':
                case 'bigint':
                case 'undefined':
                    throw new ts_common_1.BadImplementationException('Headers values can only be of type: (() => string | string[]) | string | string[] ');
            }
            return toRet;
        }, {});
    }
}
exports.BaseHttpModule_Class = BaseHttpModule_Class;
