import {Shipping_WayBill, WayBill} from '@app/shared';
import * as React from 'react';
import {AssetView} from '@form/asset/AssetView';
import {AssetsSubmitter} from '@form/asset/AssetSubmitter';
import {DB_Asset} from '@nu-art/file-upload';
import {Component_EditableItem, EditableRef} from '@component/v2/Component_EditableItem';
import {TS_Input} from '@nu-art/thunderstorm/frontend/components/TS_Input';


type State = {};

type Props = {};

export class Component_WayBill
	extends Component_EditableItem<WayBill, Props, State> {

	constructor(props: Props & EditableRef<WayBill>) {
		super(props);
	}

	render() {
		const wayBill = this.state.editable.item;
		return (
			<div className="ll_h_c">
				<TS_Input
					type="text"
					placeholder="Way bill"
					value={wayBill.id}
					onAccept={(waybillId) => this.state.editable.update('id', waybillId)}
				/>
				{this.renderWayBillAsset()}
				{this.renderAssetSubmitter()}
			</div>
		);
	}

	private renderAssetSubmitter() {
		return <AssetsSubmitter
			onChange={(assets: DB_Asset[]) => this.state.editable.update('assetId', assets.map(asset => asset._id)[0])} open={false}
			assetType={Shipping_WayBill}/>;
	}

	private renderWayBillAsset() {
		const wayBill = this.state.editable.item;
		if (!wayBill.assetId)
			return;

		return <AssetView assetId={wayBill.assetId} onDelete={() => this.props.editable.update('assetId', undefined)}/>;
	}
}