"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_TranslationEntry = exports.IndexKey_Translation_GroupId = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const validators_1 = require("../validators");
exports.IndexKey_Translation_GroupId = 'groups';
const Validator_TranslationEntry = {
    auditId: ts_common_1.tsValidateUniqueId,
    groupId: (0, ts_common_1.tsValidateRegexp)(/^.*$/, false),
    sharedId: ts_common_1.tsValidateUniqueId,
    locale: validators_1.tsValidateStringOneLine,
    encoding: ts_common_1.tsValidateMustExist,
    regexp: ts_common_1.tsValidateOptional,
    content: (0, validators_1.tsValidateStringLength)(5000),
};
exports.DBDef_TranslationEntry = {
    validator: Validator_TranslationEntry,
    indices: [{ id: exports.IndexKey_Translation_GroupId, keys: ['groupId', 'locale'] }],
    uniqueKeys: ['sharedId', 'locale'],
    lockKeys: ['sharedId', 'locale'],
    versions: ['1.0.1', '1.0.0'],
    dbName: 'translations',
    entityName: 'translation',
};
