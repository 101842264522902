"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeightBounder = void 0;
const React = require("react");
const ComponentSync_1 = require("../core/ComponentSync");
class HeightBounder extends ComponentSync_1.ComponentSync {
    __onWindowResized() {
        this.forceUpdate();
    }
    deriveStateFromProps(nextProps) {
        return {};
    }
    render() {
        const ref = this.ref;
        let height = undefined;
        if (ref)
            height = `${window.innerHeight - ref.offsetTop - 3}px`;
        const tempProps = Object.assign({}, this.props);
        delete tempProps.style;
        return React.createElement("div", Object.assign({}, tempProps, { style: Object.assign(Object.assign({}, this.props.style), { height }), ref: ((_ref) => {
                if (this.ref)
                    return;
                this.ref = _ref;
                this.forceUpdate();
            }) }), this.props.children);
    }
}
exports.HeightBounder = HeightBounder;
