import {
	ModuleFE_BaseApi,
	EventType_Create,
	EventType_Delete,
	EventType_Query,
	EventType_Unique,
	EventType_Update,
	EventType_UpsertAll,
	Props_SmartComponent,
	SmartPage,
	State_SmartComponent
} from '@nu-art/thunderstorm/frontend';
import {getQueryParameter, ModuleFE_Routing, stopPropagation, TS_Loader} from '@nu-art/thunderstorm/frontend';
import {DB_Object, Default_UniqueKey, IndexKeys, PreDB, TypedMap} from '@nu-art/ts-common';
import * as React from 'react';
import {dispatch_updateHeaderOption, HeaderOption} from '@page/Page_Workspace/Component_MainHeader';
import {Props_DBItemEditorComponentV2} from '@component/Component_DBItemEditorV2';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';


export type Props_DBItemEditorPageV2<ItemType extends DB_Object, Ks extends keyof PreDB<ItemType> = Default_UniqueKey> = {
	keys: Ks[]
	pageTitle: string | (() => string)

	parentRoute: string
	childRoute: string
	newRoute: string

	moduleFE: ModuleFE_BaseApi<ItemType, Ks>
	headerButtons?: HeaderOption[]
	itemEditor?: React.ElementType<Props_DBItemEditorComponentV2<any, ItemType>>
}

export type State_DBItemEditorPageV2<ItemType extends DB_Object, Ks extends keyof PreDB<ItemType> = Default_UniqueKey> = {
	keys: IndexKeys<ItemType, Ks>,
	item?: ItemType
}

export abstract class Page_DBItemEditorV2<ItemType extends DB_Object,
	Ks extends keyof PreDB<ItemType> = Default_UniqueKey,
	S extends State_DBItemEditorPageV2<ItemType, Ks> = State_DBItemEditorPageV2<ItemType, Ks>,
	P extends Props_DBItemEditorPageV2<ItemType, Ks> = Props_DBItemEditorPageV2<ItemType, Ks>>
	extends SmartPage<P, S> {

	constructor(props: P) {
		super(props);

		// @ts-ignore
		this[props.moduleFE.defaultDispatcher.method] = this.__onItemUpdated;
	}

	protected async deriveStateFromProps(nextProps: Props_SmartComponent & P, state: State_SmartComponent & S) {
		const keys = this.resolveKeys(nextProps, (key: Ks) => getQueryParameter(key as string) as string);
		if (!keys && ModuleFE_Routing.getMyRouteKey() !== this.props.newRoute) {
			ModuleFE_Routing.goToRoute(this.props.parentRoute);
			return state;
		}

		let item: ItemType | undefined;
		try {
			item = await this.props.moduleFE.IDB.unique(keys);
		} catch (e: any) {
			this.logWarning(`error getting item from cache: `, e);
		}
		state.keys = keys;
		state.item = item || await this.generateEmptyItem();

		return state;
	}

	protected async generateEmptyItem(): Promise<ItemType> {
		return {} as ItemType;
	}

	private resolveKeys(props: P, resolver: (key: Ks) => string | undefined) {
		return props.keys.reduce((toRet, key) => {
			const value = resolver(key);
			if (value)
				toRet[key] = value as unknown as ItemType[Ks];
			return toRet;
		}, {} as IndexKeys<ItemType, Ks>);
	}

	private handleSave = (ev: KeyboardEvent) => {
		if ((ev.metaKey || ev.ctrlKey) && !ev.shiftKey && ev.key === 's') {
			stopPropagation(ev);
			this.saveImpl();
		}
	};

	protected saveImpl = (item?: Partial<ItemType>) => {
		this.props.moduleFE.v1.upsert((item || this.state.item) as ItemType).execute();
	};

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleSave);
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleSave);

		const headerButtons: HeaderOption[] | undefined = this.props.headerButtons;
		if (headerButtons)
			dispatch_updateHeaderOption.dispatchUI(headerButtons);
	}

	private __onItemUpdated = (...params: ApiCallerEventType<ItemType>): void => {
		if (params[0] === EventType_UpsertAll || params[0] === EventType_Query)
			return;

		if (this.props.keys.find(key => this.state.keys[key] && (params[1] as ItemType)[key] !== this.state.keys[key]))
			return this.logWarning('TBR: Not our items was updated');

		if (params[0] === EventType_Delete)
			return ModuleFE_Routing.goToRoute(this.props.parentRoute);

		if (params[0] === EventType_Create) {
			const keys = this.resolveKeysFromInstance(params[1]) as unknown as TypedMap<string>;
			return ModuleFE_Routing.goToRoute(this.props.childRoute, keys);
		}

		if (params[0] === EventType_Unique || params[0] === EventType_Update) {
			(async () => {
				const keys = this.resolveKeysFromInstance(params[1]);
				if (this.state.item?._id !== params[1]._id) {
					ModuleFE_Routing.goToRoute(this.props.childRoute, keys as unknown as TypedMap<string>);
					return {};
				}

				const item = await this.props.moduleFE.IDB.unique(keys);
				if (!item) {
					this.logWarning('THIS SHOULD NOT HAPPEN');
					return {};
				}

				return {item, keys} as S;
			})()
				.then((state) => this.setState(state))
				.catch(e => this.logError(`error: ${e}`));
			return;
		}
	};

	private resolveKeysFromInstance(item: ItemType) {
		return this.resolveKeys(this.props, (key: Ks) => item[key] as unknown as string);
	}

	render() {
		const item = this.state.item;
		if (!item)
			return <TS_Loader/>;

		return this.renderEditor(item as ItemType);
	}

	renderEditor(item: ItemType) {
		const ItemEditor = this.props.itemEditor;
		if (!ItemEditor)
			return 'This editor class should override the renderEditor method!';

		// @ts-ignore
		return <ItemEditor keys={this.state.keys} moduleFE={this.props.moduleFE} onChange={this.saveImpl}/>;
	}
}