"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Accounts = exports.DBDef_Session = exports.Validator_Generated = exports.Validator_Modifiable = void 0;
const validators_1 = require("@nu-art/ts-common/validator/validators");
const type_validators_1 = require("@nu-art/ts-common/validator/type-validators");
const consts_1 = require("./consts");
exports.Validator_Modifiable = {
    accountId: validators_1.tsValidateUniqueId,
    deviceId: validators_1.tsValidateUniqueId,
    prevSession: (0, type_validators_1.tsValidateArray)((0, type_validators_1.tsValidateString)(), false),
    sessionId: (0, type_validators_1.tsValidateString)(),
    timestamp: (0, type_validators_1.tsValidateTimestamp)(),
    needToRefresh: (0, type_validators_1.tsValidateBoolean)(false)
};
exports.Validator_Generated = Object.assign({}, validators_1.DB_Object_validator);
exports.DBDef_Session = {
    modifiablePropsValidator: exports.Validator_Modifiable,
    generatedPropsValidator: exports.Validator_Generated,
    dbName: 'user-account--sessions',
    entityName: 'Session',
    uniqueKeys: ['accountId', 'deviceId'],
    versions: ['1.0.0'],
};
const modifiablePropsValidator = {
    email: validators_1.tsValidateEmail,
    type: (0, type_validators_1.tsValidateValue)(consts_1._accountTypes),
    thumbnail: (0, type_validators_1.tsValidateString)(undefined, false),
    displayName: (0, type_validators_1.tsValidateString)(undefined, false),
};
const generatedPropsValidator = Object.assign(Object.assign({}, validators_1.DB_Object_validator), { _auditorId: (0, type_validators_1.tsValidateString)(), _newPasswordRequired: (0, type_validators_1.tsValidateBoolean)(false), salt: validators_1.tsValidator_nonMandatoryString, saltedPassword: validators_1.tsValidator_nonMandatoryString });
exports.DBDef_Accounts = {
    dbName: 'user-account--accounts',
    entityName: 'Account',
    modifiablePropsValidator: modifiablePropsValidator,
    generatedPropsValidator: generatedPropsValidator,
    versions: ['1.0.0']
};
