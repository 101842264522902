"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.tsValidateObject = exports.tsValidateResult = exports.tsValidate = exports.tsValidateExists = exports.assertValidateMandatoryProperty = exports.ValidationException = void 0;
const object_tools_1 = require("../utils/object-tools");
const exceptions_1 = require("../core/exceptions/exceptions");
class ValidationException extends exceptions_1.CustomException {
    constructor(debugMessage, input, result, e) {
        super(ValidationException, debugMessage, e);
        this.result = result;
        this.input = input;
    }
}
exports.ValidationException = ValidationException;
const CONST_NO_ERROR = '###';
const assertValidateMandatoryProperty = (mandatory, input) => {
};
exports.assertValidateMandatoryProperty = assertValidateMandatoryProperty;
const tsValidateExists = (mandatory = true) => {
    return (input) => {
        if (input !== undefined && input !== null)
            return;
        if (mandatory)
            return 'Missing mandatory field';
        return CONST_NO_ERROR;
    };
};
exports.tsValidateExists = tsValidateExists;
//
// export const tsValidateObjectValues = <V, T extends { [k: string]: V } = { [k: string]: V }>(validator: ValidatorTypeResolver<V>, mandatory = true): Validator<T> =>
// 	[tsValidateExists(mandatory), (input?: T) => {
// 		for (const key of _keys(input!)) {
// 			// Validate keys too
// 			const inputValue = input![key];
// 			if (typeof inputValue === 'object') {
// 				// not 100% sure what is going on here why not tsValidateObject(validator[key])
// 				const objectValidator = tsValidateObjectValues(validator, mandatory);
// 				if (!objectValidator)
// 					continue;
//
// 				return tsValidateResult(inputValue as { [k: string]: V }, objectValidator);
// 			}
//
// 			return tsValidateResult(inputValue as unknown as V, validator);
// 		}
// 	}];
const tsValidate = (instance, _validator, strict = true) => {
    const results = (0, exports.tsValidateResult)(instance, _validator);
    if (results && strict) {
        console.error(results);
        throw new ValidationException(`Error validating object: `, instance, results);
    }
    return results;
};
exports.tsValidate = tsValidate;
const tsValidateResult = (instance, _validator, key, parentInstance) => {
    if (!_validator)
        return "No validator provided!";
    const validator = typeof _validator === 'function' ? [_validator] : _validator;
    if (Array.isArray(validator)) {
        const result = validator.reduce((result, __validator) => result === CONST_NO_ERROR ? result : result || __validator(instance, parentInstance), undefined);
        return result !== CONST_NO_ERROR ? result : undefined;
    }
    if (typeof validator === 'object') {
        if (!instance && validator)
            return `Unexpected object:\n The key '${String(key)}' wasn't defined in the instance.`;
        if (typeof instance !== 'object')
            return `Unexpected instance '${instance}'.\nExpected to receive an object, but received something else.`;
        const __validator = validator;
        return (0, exports.tsValidateObject)(__validator, instance);
    }
};
exports.tsValidateResult = tsValidateResult;
const tsValidateObject = (__validator, instance, path = '') => {
    const validatorKeys = (0, object_tools_1._keys)(__validator);
    const instanceKeys = Object.keys(instance);
    const result = {};
    for (const key of instanceKeys) {
        // @ts-ignore
        if (!validatorKeys.includes(key))
            // @ts-ignore
            result[key] = `Unexpected key '${path}${key}'.\nIf you want to ignore the validation of this property,\n add the following to your validator:\n {\n  ...\n  ${key}: undefined\n  ...\n}\n`;
    }
    for (const key of validatorKeys) {
        const value = instance[key];
        const validator = __validator[key];
        const propsResult = (0, exports.tsValidateResult)(value, validator, key, instance);
        if (propsResult && propsResult !== CONST_NO_ERROR)
            result[key] = propsResult;
    }
    if ((0, object_tools_1._keys)(result).length === 0)
        return;
    return result;
};
exports.tsValidateObject = tsValidateObject;
