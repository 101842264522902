"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleListAdapter = exports.SimpleTreeAdapter = exports.AdapterBuilder = exports.Adapter = exports.BaseAdapter = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const SimpleTreeNodeRenderer_1 = require("../TS_Tree/SimpleTreeNodeRenderer");
const tools_1 = require("../../utils/tools");
class BaseAdapter {
    constructor(data) {
        this.filter = (obj, key) => true;
        // by default all objects and arrays are parents
        this.isParent = (obj) => {
            if (obj === undefined || obj === null)
                return false;
            if (!this.childrenKey)
                return Array.isArray(obj) || typeof obj === 'object';
            return typeof obj === 'object' && obj['_isParent'] === true || Array.isArray(obj);
        };
        // this to allow us to navigate and skip into nested items in an object without changing the object
        // adjust = (obj: any): { data: any; deltaPath: string } => this.adjustImpl(obj, "_children");
        this.adjust = (obj) => {
            if (!this.childrenKey)
                return ({ data: obj, deltaPath: '' });
            if (!obj[this.childrenKey])
                return { data: obj, deltaPath: '' };
            const objElement = Object.assign(Object.assign({}, obj[this.childrenKey]), { type: obj.type, item: obj.item, _isParent: true });
            return { data: objElement, deltaPath: this.childrenKey || '' };
        };
        this.data = data;
    }
    setData(data) {
        this.data = data;
        return this;
    }
    // this can be gone.. and builders must use the new filterChildren
    getFilteredChildren(obj) {
        if (obj === undefined || obj === null)
            return [];
        if (typeof obj !== 'object')
            return [];
        if (Array.isArray(obj))
            return (0, ts_common_1._keys)(obj);
        if (!this.childrenKey)
            return (0, ts_common_1._keys)(obj).filter(k => this.filter(obj, k));
        const objElement = obj[this.childrenKey];
        if (!objElement)
            return [];
        return (0, ts_common_1._keys)(objElement);
    }
    clone(baseAdapter) {
        (0, ts_common_1._keys)(this).forEach(k => {
            baseAdapter[k] = this[k];
        });
        return baseAdapter;
    }
}
exports.BaseAdapter = BaseAdapter;
class Adapter extends BaseAdapter {
    constructor() {
        super(...arguments);
        this.hideRoot = false;
        this.treeNodeRenderer = SimpleTreeNodeRenderer_1.SimpleTreeNodeRenderer;
    }
    setTreeNodeRenderer(renderer) {
        this.treeNodeRenderer = renderer;
        return this;
    }
    resolveRenderer(propKey) {
        return (pah) => null;
    }
}
exports.Adapter = Adapter;
class BaseAdapterBuilder {
    constructor() {
        this.multiRenderer = false;
        this.filter = (obj, key) => true;
        // Utility - move to builder
        this.setChildrenKey = (childrenKey) => {
            this.childrenKey = childrenKey;
            return this;
        };
        this.defaultExpandCollapseRenderer = (props) => {
            function resolveSymbol() {
                if (typeof props.item !== 'object')
                    return '';
                if (Object.keys(props.item).length === 0)
                    return '';
                if (props.node.adapter.isParent(props.item)) {
                    if (props.node.expanded)
                        return '-';
                    return '+';
                }
                return '';
            }
            const className = (0, tools_1._className)('node-icon', props.node.expanded ? 'expanded' : undefined);
            return React.createElement("div", { className: className, style: { minWidth: '12px' } }, resolveSymbol());
        };
        this.defaultTreeNodeRenderer = (props) => {
            const _Renderer = this.resolveRenderer(props.item.type);
            return (React.createElement("div", { className: "ll_h_c clickable", onClick: props.node.expandToggler },
                React.createElement(this.expandCollapseRenderer, Object.assign({}, props)),
                React.createElement(_Renderer, { item: this.multiRenderer ? props.item.item : props.item, node: props.node })));
        };
        this.expandCollapseRenderer = this.defaultExpandCollapseRenderer;
    }
    setData(data) {
        this.data = data;
        return this;
    }
    setNodeRenderer(treeNodeRenderer) {
        this.treeNodeRenderer = treeNodeRenderer;
        return this;
    }
    setExpandCollapseRenderer(expandCollapseRenderer) {
        this.expandCollapseRenderer = expandCollapseRenderer;
        return this;
    }
    setFilter(filter) {
        this.filter = filter;
    }
}
class ListSingleAdapterBuilder extends BaseAdapterBuilder {
    constructor(renderer) {
        super();
        this.renderer = renderer;
        this.treeNodeRenderer = (props) => {
            const _Renderer = this.resolveRenderer();
            return React.createElement(_Renderer, { item: props.item, node: props.node });
        };
    }
    resolveRenderer(type) {
        return this.renderer;
    }
    nested() {
        this.childrenKey = '_children';
        this.treeNodeRenderer = (props) => {
            const _Renderer = this.renderer;
            return React.createElement(_Renderer, Object.assign({}, props));
        };
        return this;
    }
    build() {
        const adapter = new Adapter(this.data);
        adapter.hideRoot = true;
        adapter.treeNodeRenderer = this.treeNodeRenderer;
        adapter.childrenKey = this.childrenKey;
        adapter.isParent = (obj) => obj === this.data;
        // @ts-ignore
        adapter.itemRenderer = this.renderer;
        return adapter;
    }
}
class MultiTypeAdapterBuilder extends BaseAdapterBuilder {
    constructor(rendererMap) {
        super();
        this._hideRoot = true;
        this.multiRenderer = true;
        this.rendererMap = rendererMap;
        this.childrenKey = '_children';
        this.treeNodeRenderer = (props) => {
            const _Renderer = this.resolveRenderer(props.item.type);
            return React.createElement(_Renderer, { item: props.item.item, node: props.node });
        };
    }
    resolveRenderer(type) {
        if (!type)
            throw new ts_common_1.BadImplementationException('multi renderer adapter items must have a type to resolve renderer');
        const renderer = this.rendererMap[type];
        if (!renderer)
            throw new ts_common_1.BadImplementationException(`renderer of type ${type} doesn't exists, in rendererMap found keys: ${JSON.stringify((0, ts_common_1._keys)(this.rendererMap))}`);
        return renderer;
    }
    tree() {
        this.treeNodeRenderer = this.defaultTreeNodeRenderer;
        this._hideRoot = false;
        return this;
    }
    hideRoot() {
        this._hideRoot = true;
        return this;
    }
    build() {
        const adapter = new Adapter(this.data);
        adapter.hideRoot = this._hideRoot;
        adapter.treeNodeRenderer = this.treeNodeRenderer;
        adapter.childrenKey = this.childrenKey;
        return adapter;
    }
}
class TreeSingleAdapterBuilder extends BaseAdapterBuilder {
    constructor(renderer) {
        super();
        this._hideRoot = false;
        this.renderer = renderer;
        this.treeNodeRenderer = this.defaultTreeNodeRenderer;
    }
    resolveRenderer(type) {
        return this.renderer;
    }
    hideRoot() {
        this._hideRoot = true;
        return this;
    }
    build() {
        const adapter = new Adapter(this.data);
        adapter.treeNodeRenderer = this.treeNodeRenderer;
        adapter.hideRoot = this._hideRoot;
        return adapter;
    }
}
class ListAdapterBuilder {
    singleRender(renderer) {
        return new ListSingleAdapterBuilder(renderer);
    }
    multiRender(rendererMap) {
        return new MultiTypeAdapterBuilder(rendererMap);
    }
}
class TreeAdapterBuilder {
    singleRender(renderer) {
        return new TreeSingleAdapterBuilder(renderer);
    }
    multiRender(rendererMap) {
        return new MultiTypeAdapterBuilder(rendererMap).tree();
    }
}
class _AdapterBuilder {
    list() {
        return new ListAdapterBuilder();
    }
    tree() {
        return new TreeAdapterBuilder();
    }
}
function AdapterBuilder() {
    return new _AdapterBuilder();
}
exports.AdapterBuilder = AdapterBuilder;
function SimpleTreeAdapter(options, renderer) {
    return AdapterBuilder()
        .tree()
        .singleRender(renderer)
        .setData(options)
        .build();
}
exports.SimpleTreeAdapter = SimpleTreeAdapter;
function SimpleListAdapter(options, renderer) {
    return AdapterBuilder()
        .list()
        .singleRender(renderer)
        .setData(options)
        .build();
}
exports.SimpleListAdapter = SimpleListAdapter;
