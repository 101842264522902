"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldEditor = void 0;
const React = require("react");
const ComponentSync_1 = require("../core/ComponentSync");
const TS_Input_1 = require("../components/TS_Input/TS_Input");
const TS_TextArea_1 = require("../components/TS_Input/TS_TextArea");
class FieldEditor extends ComponentSync_1.ComponentSync {
    constructor(props) {
        super(props);
        this.onChange = (value) => {
            // this.logDebug(`input onChange: ${value}`);
            this.props.storageKey.set(value);
            this.forceUpdate();
        };
        this.renderInput = (value) => {
            // @ts-ignore
            return (React.createElement(TS_Input_1.TS_Input, Object.assign({}, this.props.inputProps, { focus: true, id: this.props.id, type: this.props.type, onAccept: this.props.onAccept, value: value, onChange: this.onChange })));
        };
        this.renderArea = (value) => {
            // @ts-ignore
            return (React.createElement(TS_TextArea_1.TS_TextArea, Object.assign({}, this.props.inputProps, { focus: true, id: this.props.id, type: this.props.type, onAccept: this.props.onAccept, value: value, onChange: this.onChange })));
        };
        this.renderLabel = () => {
            var _a;
            const label = this.props.value || ((_a = this.props.inputProps) === null || _a === void 0 ? void 0 : _a.placeholder) || '';
            if (typeof this.props.labelProps === 'function')
                return this.props.labelProps(label);
            return React.createElement("div", Object.assign({}, this.props.labelProps), label);
        };
        const prevValue = this.props.storageKey.get();
        if (!prevValue) {
            // this.logDebug(`FieldEditor: ${this.props.value}`);
            this.props.storageKey.set(this.props.value || '');
        }
    }
    deriveStateFromProps(nextProps) {
        return {};
    }
    render() {
        const value = this.props.storageKey.get() || '';
        if (!this.props.isEditing)
            return this.renderLabel();
        switch (this.props.editorType || 'input') {
            case 'input':
                return this.renderInput(value);
            case 'textarea':
                return this.renderArea(value);
        }
    }
}
exports.FieldEditor = FieldEditor;
