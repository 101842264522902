"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deltaDays = exports.isSameDay = exports.DateTimeFormat = exports.normalizeTimestamp = exports.parseTimeString = exports.formatTimestamp = exports.createReadableTimestampObject = exports.currentTimeMillisWithTimeZone = exports.currentLocalTimeMillis = exports.specificTimeTodayMillis = exports.currentTimeMillis = exports.auditBy = exports._clearInterval = exports._setInterval = exports._clearTimeout = exports._setTimeout = exports.sleep = exports.timeout = exports.Weekdays = exports.Format_YYYYMMDD_HHmmss = exports.Format_HHmmss_DDMMYYYY = exports.Year = exports.Week = exports.Day = exports.Hour = exports.Minute = exports.Second = void 0;
const moment_1 = require("moment");
const moment = require("moment");
exports.Second = 1000;
exports.Minute = exports.Second * 60;
exports.Hour = exports.Minute * 60;
exports.Day = exports.Hour * 24;
exports.Week = exports.Day * 7;
exports.Year = exports.Day * 365;
exports.Format_HHmmss_DDMMYYYY = 'HH:mm:ss_DD-MM-YYYY';
exports.Format_YYYYMMDD_HHmmss = 'YYYY-MM-DD_HH:mm:ss';
exports.Weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
function timeout(sleepMs) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise(resolve => setTimeout(resolve, sleepMs, undefined));
    });
}
exports.timeout = timeout;
exports.sleep = timeout;
function _setTimeout(handler, sleepMs = 0, ...args) {
    return setTimeout(handler, sleepMs, ...args);
}
exports._setTimeout = _setTimeout;
function _clearTimeout(handlerId) {
    if (!handlerId)
        return;
    return clearTimeout(handlerId);
}
exports._clearTimeout = _clearTimeout;
function _setInterval(handler, sleepMs = 0, ...args) {
    return setInterval(handler, sleepMs, ...args);
}
exports._setInterval = _setInterval;
function _clearInterval(handlerId) {
    if (!handlerId)
        return;
    return clearInterval(handlerId);
}
exports._clearInterval = _clearInterval;
/**
 * @param comment @deprecated
 */
function auditBy(user, comment, timestamp = currentTimeMillis()) {
    const _auditBy = {
        auditBy: user,
        auditAt: createReadableTimestampObject(exports.Format_HHmmss_DDMMYYYY, timestamp)
    };
    if (comment)
        _auditBy.comment = comment;
    return _auditBy;
}
exports.auditBy = auditBy;
function currentTimeMillis() {
    return Date.now();
}
exports.currentTimeMillis = currentTimeMillis;
function specificTimeTodayMillis(hours, minutes) {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.getTime();
}
exports.specificTimeTodayMillis = specificTimeTodayMillis;
function currentLocalTimeMillis() {
    const date = new Date();
    return date.getTime();
}
exports.currentLocalTimeMillis = currentLocalTimeMillis;
function currentTimeMillisWithTimeZone() {
    const date = new Date();
    return date.getTime() + date.getTimezoneOffset();
}
exports.currentTimeMillisWithTimeZone = currentTimeMillisWithTimeZone;
function createReadableTimestampObject(pattern = exports.Format_HHmmss_DDMMYYYY, timestamp = currentTimeMillis(), timezone) {
    const timeObj = {
        timestamp: timestamp,
        pretty: formatTimestamp(pattern, timestamp)
    };
    if (timezone)
        timeObj.timezone = timezone;
    return timeObj;
}
exports.createReadableTimestampObject = createReadableTimestampObject;
/**
 * For detailed list of formats visit https://momentjs.com/docs/#/displaying/format/
 */
function formatTimestamp(pattern = exports.Format_HHmmss_DDMMYYYY, timestamp = currentTimeMillis(), timezone = Intl.DateTimeFormat()
    .resolvedOptions().timeZone) {
    const m = (0, moment_1.utc)(timestamp);
    m.utcOffset(-new Date().getTimezoneOffset());
    return m.format(pattern);
}
exports.formatTimestamp = formatTimestamp;
function parseTimeString(timestamp, pattern = exports.Format_HHmmss_DDMMYYYY) {
    return (0, moment_1.utc)(timestamp, pattern).valueOf();
}
exports.parseTimeString = parseTimeString;
function normalizeTimestamp(timestamp, pattern) {
    return parseTimeString(formatTimestamp(pattern, timestamp), pattern);
}
exports.normalizeTimestamp = normalizeTimestamp;
const DateTimeFormat = (format) => {
    return {
        parse: (timestampAsString) => parseTimeString(timestampAsString, format),
        format: (timestamp = currentTimeMillis()) => formatTimestamp(format, timestamp)
    };
};
exports.DateTimeFormat = DateTimeFormat;
function isSameDay(date1, date2) {
    return moment(date1).isSame(date2, 'day');
}
exports.isSameDay = isSameDay;
function deltaDays(d1, d2) {
    const date1 = typeof d1 === 'number' ? new Date(d1) : d1;
    const date2 = typeof d2 === 'number' ? new Date(d2) : d2;
    //If both dates are the same day, return 0
    if (isSameDay(date1, date2))
        return 0;
    const millis1 = typeof d1 === 'number' ? d1 : d1.getTime();
    const millis2 = typeof d2 === 'number' ? d2 : d2.getTime();
    const days = Math.floor((millis1 - millis2) / exports.Day);
    //If date2 + the amount of days calculated actually lands on the same day as date1, return days
    //Else, an extra day needs to be given
    const date2Offset = new Date(date2.getTime() + (days * exports.Day));
    return isSameDay(date1, date2Offset) ? days : days + 1;
}
exports.deltaDays = deltaDays;
