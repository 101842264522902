"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringReplacer = exports.mouseEventHandler = exports.stopPropagation = exports.HOOK_useEffectAsync = exports.HOOK_useEffect = exports.HOOK_useState = exports.HOOK = exports._className = exports.convertBase64ToFile = exports.base64ToBlob = exports.browserType = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
function browserType() {
    if (navigator === null || navigator === void 0 ? void 0 : navigator.vendor.includes('Google')) {
        return 'chrome';
    }
    throw new ts_common_1.BadImplementationException('No matching browser detected');
}
exports.browserType = browserType;
async function base64ToBlob(imageAsBase64) {
    return (await fetch(imageAsBase64)).blob();
}
exports.base64ToBlob = base64ToBlob;
//data:image/jpeg;base64,<!-- Base64 data -->
function convertBase64ToFile(fileName, base64, _mimeType) {
    const arr = base64.split(',');
    const match = arr[0].match(/:(.*?);/);
    const mimeType = (match && match[1]) || (_mimeType && _mimeType);
    if (!mimeType)
        throw new ts_common_1.ImplementationMissingException('Could not extract mime type from data...');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mimeType });
}
exports.convertBase64ToFile = convertBase64ToFile;
function _className(...classes) {
    return (0, ts_common_1.filterInstances)(classes.filter(c => !!c)).join(' ');
}
exports._className = _className;
function HOOK(fc, props) {
    return fc(props);
}
exports.HOOK = HOOK;
function HOOK_useState(initialState) {
    return React.useState(initialState);
}
exports.HOOK_useState = HOOK_useState;
function HOOK_useEffect(effect, deps) {
    return React.useEffect(effect, deps);
}
exports.HOOK_useEffect = HOOK_useEffect;
const HOOK_useEffectAsync = (action, deps, destructor) => {
    React.useEffect(() => {
        (action)();
        return destructor;
    }, deps);
};
exports.HOOK_useEffectAsync = HOOK_useEffectAsync;
/**
 * Prevents default behaviour and stops propagation
 * @param e MouseEvent | React.MouseEvent | KeyboardEvent | React.KeyboardEvent
 */
const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
};
exports.stopPropagation = stopPropagation;
const mouseEventHandler = (e, actions) => {
    var _a;
    const key = e.button === 0 ? 'left' : (e.button === 1 ? 'middle' : 'right');
    return (_a = actions[key]) === null || _a === void 0 ? void 0 : _a.call(actions);
};
exports.mouseEventHandler = mouseEventHandler;
const stringReplacer = (_content, _toReplace, replacer) => {
    const toRet = [];
    let i = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        const content = _content.toLowerCase();
        const toReplace = _toReplace.toLowerCase();
        const index = content.indexOf(toReplace);
        if (index === -1) {
            toRet.push(_content);
            return toRet;
        }
        toRet.push(_content.slice(0, index));
        _content = _content.slice(index);
        toRet.push(replacer(_content.slice(0, toReplace.length), i));
        _content = _content.slice(toReplace.length);
        i++;
    }
};
exports.stringReplacer = stringReplacer;
