"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentAsync = void 0;
/**
 * Created by tacb0ss on 28/07/2018.
 */
const ComponentBase_1 = require("./ComponentBase");
class ComponentAsync extends ComponentBase_1.BaseComponent {
    constructor() {
        super(...arguments);
        this.derivingState = false;
        this.reDeriveCompletedCallback = () => {
            this.derivingState = false;
            if (!this.mounted)
                return this.logWarning('Will not trigger pending props - Component Unmounted');
            if (this.pendingProps) {
                this.logVerbose('Triggering pending props');
                this._deriveStateFromProps(this.pendingProps);
            }
        };
    }
    _deriveStateFromProps(nextProps) {
        if (this.derivingState) {
            this.logVerbose('Scheduling new props', nextProps);
            this.pendingProps = nextProps;
            return;
        }
        this.logVerbose('Deriving state from props', nextProps);
        this.pendingProps = undefined;
        this.derivingState = true;
        this.deriveStateFromProps(nextProps)
            .then((state) => {
            if (!this.mounted)
                return this.logWarning('Will not set derived state - Component Unmounted');
            this.setState(state, this.reDeriveCompletedCallback);
        })
            .catch(e => {
            this.logError(`error`, e);
            this.setState({ error: e }, this.reDeriveCompletedCallback);
        });
        return this.createInitialState(nextProps);
    }
    async deriveStateFromProps(nextProps) {
        return this.createInitialState(nextProps);
    }
    createInitialState(nextProps) {
        return { isLoading: true };
    }
}
exports.ComponentAsync = ComponentAsync;
