import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {BaseDB_ShopifyApiCallerV2} from '@modules/BaseDB_ShopifyApiCaller';
import {DB_Product, DBDef_Product} from '@app/shared';


export interface OnProductsUpdated {
	__onProductsUpdated: (...params: ApiCallerEventType<DB_Product>) => void;
}

export const dispatch_onProductsListChanged = new ThunderDispatcher<OnProductsUpdated, '__onProductsUpdated'>('__onProductsUpdated');

export class ModuleFE_Product_Class
	extends BaseDB_ShopifyApiCallerV2<DB_Product, 'storeId' | 'shopifyId'> {

	constructor() {
		super(DBDef_Product, dispatch_onProductsListChanged);
	}
}

export const ModuleFE_Products = new ModuleFE_Product_Class();
export const ModuleFE_Products_ = ModuleFE_Products as unknown as ModuleFE_BaseApi<any, any>;
