"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReactEntryComponentInjector = void 0;
const React = require("react");
const entry_component_loading_module_1 = require("./entry-component-loading-module");
class ReactEntryComponentInjector extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.__onRequestCompleted = (key, success) => {
            if (key !== this.props.src)
                return;
            if (!success)
                // Need to add error handling here...
                return;
            this.injectComponent(entry_component_loading_module_1.EntryComponentLoadingModule.getNode(key));
        };
        this.state = { loading: false, progress: 0 };
    }
    injectComponent(node) {
        var _a;
        (_a = this.myRef.current) === null || _a === void 0 ? void 0 : _a.appendChild(node);
        this.setState({ loading: false });
    }
    componentDidMount() {
        const src = this.props.src;
        const node = entry_component_loading_module_1.EntryComponentLoadingModule.getNode(src);
        if (node)
            return this.injectComponent(node);
        entry_component_loading_module_1.EntryComponentLoadingModule.loadScript(src, (progress) => this.setState({ progress }));
        this.setState({ loading: true });
    }
    render() {
        return React.createElement("div", { ref: this.myRef, id: this.props.src }, this.extracted());
    }
    extracted() {
        const Loader = this.props.loader;
        if (Loader)
            return React.createElement(Loader, { progress: this.state.progress });
        return React.createElement("div", { style: { width: '100%', height: '100%' } },
            this.state.progress,
            " %");
    }
}
exports.ReactEntryComponentInjector = ReactEntryComponentInjector;
