import {DB_CustomerOrder, DeliveryDetails, DeliveryServices} from '@app/shared';
import {
	DialogButtons,
	EditableItem,
	LL_H_C,
	LL_V_C,
	LL_V_L,
	ModuleFE_Dialog,
	ModuleFE_Toaster,
	Props_TSDialog,
	SimpleListAdapter,
	TS_Dialog,
	TS_DropDown,
	TS_Input
} from '@nu-art/thunderstorm/frontend';
import React from 'react';
import './Dialog_CreateOrderDelivery.scss';
import {Component_CustomerDetails, Component_LinkToGoogleMaps} from '@pah/customer-order/components/Component_Address';
import {ModuleFE_CustomerOrder} from '@modules/shopify';
import {currentTimeMillis, deepClone} from '@nu-art/ts-common';


export const EmptyDeliveryStatus: Readonly<DeliveryDetails> = Object.freeze({
	__createdAt: 0,
	number: '',
	type: DeliveryServices[0].key,
	status: 'not-ordered',
	lifecycle: [],
	corruptedLogs: []
});

type Props = {
	order: Partial<DB_CustomerOrder>
	editable: EditableItem<DeliveryDetails[]>,
}

type State = {
	selectedServiceKey: string
	deliveryNumber: string
}

export class Dialog_CreateOrderDelivery
	extends TS_Dialog<Props, State> {

	static defaultProps = {
		dialogId: 'create-order-delivery',
		className: 'create-order-delivery'
	};

	constructor(p: Props & Props_TSDialog) {
		super(p);
		this.state = {
			selectedServiceKey: DeliveryServices[0].key,
			deliveryNumber: ''
		};
	}

	static show(order: Partial<DB_CustomerOrder>, editable: EditableItem<DeliveryDetails[]>) {
		if (!order.shippingAddress)
			return ModuleFE_Toaster.toastError('Cannot order a delivery without a shipping address');

		ModuleFE_Dialog.show(<Dialog_CreateOrderDelivery order={order} editable={editable}/>);
	}

	protected renderHeader = (): React.ReactNode => <div>Order a Delivery for: #{this.props.order.orderNumber}</div>;
	protected renderBody = (): React.ReactNode => {
		const adapter = SimpleListAdapter(DeliveryServices, (node) => <div>{node.item.label}</div>);
		const selectedDeliveryService = DeliveryServices.find(s => s.key === this.state.selectedServiceKey);
		const address = this.props.order.shippingAddress!;
		const blob = JSON.parse(this.props.order.blob!);

		return <LL_V_C>
			<LL_V_L className="v-gap__s">
				<TS_DropDown
					adapter={adapter}
					selected={selectedDeliveryService || DeliveryServices[0]}
					onSelected={deliveryService => {
						this.setState({selectedServiceKey: deliveryService.key});
					}}/>

				<TS_Input
					type="text"
					value={this.state.deliveryNumber}
					placeholder={'order number'}
					onBlur={deliveryNumber => {
						this.setState({deliveryNumber});
						this.forceUpdate();
					}}/>

				<LL_H_C className="customer-order__title h-gap__l">
					<Component_CustomerDetails address={address}/>
					<Component_LinkToGoogleMaps address={address.fullFormattedAddress}/>
				</LL_H_C>
				<hr className="match_width"/>
				<LL_V_L>

					<div>{blob.shipping_address?.address1}</div>
					<div>{blob.shipping_address?.address2}</div>
					<div>{blob.shipping_address?.city}</div>
				</LL_V_L>
			</LL_V_L>
		</LL_V_C>;
	};

	buttons = (): DialogButtons => {
		return {
			right: [{
				content: this.state.deliveryNumber.length ? 'Save' : 'Create Delivery',
				associatedKeys: ['enter'],
				renderer: TS_Dialog.busyButton,
				onClick: async () => {
					if (this.state.deliveryNumber.length) {
						// this logic should move to BE
						const deliveriesDetails = this.props.editable.item;
						const item = deepClone(EmptyDeliveryStatus) as DeliveryDetails;
						item.__createdAt = currentTimeMillis();

						deliveriesDetails.push(item);
						item.number = this.state.deliveryNumber;
						item.type = this.state.selectedServiceKey;
						item.status = 'ordered';
						await this.props.editable.save();
						this.closeDialog();
						return;
					}

					const body = {
						orderId: this.props.order._id!,
						serviceKey: this.state.selectedServiceKey
					};
					await ModuleFE_CustomerOrder.orderDelivery(body).executeSync();
					this.closeDialog();
				},
			}]
		};
	};
}