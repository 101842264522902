import {
	ModuleFE_BaseApi,
	EventType_Query,
	EventType_Update,
	EventType_UpsertAll,
	Props_SmartComponent,
	SmartComponent,
	State_SmartComponent
} from '@nu-art/thunderstorm/frontend';
import {_keys, compare, DB_Object, Default_UniqueKey, IndexKeys, PreDB} from '@nu-art/ts-common';
import React from 'react';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';


export type Editable<T extends DB_Object> = Partial<T>
export type State_DBItemEditorComponentV2<State, ItemType extends DB_Object> = State & {
	item?: Editable<ItemType>
};

export type Props_DBItemEditorComponentV2<Props, ItemType extends DB_Object, Ks extends keyof PreDB<ItemType> = Default_UniqueKey> = Props & {
	keys: IndexKeys<ItemType, Ks>,
	onChange?: ((item: Editable<ItemType>, key: keyof ItemType) => void)
	moduleFE: ModuleFE_BaseApi<ItemType, Ks>
};

export abstract class Component_DBItemEditorV2< //
	ItemType extends DB_Object,
	Ks extends keyof PreDB<ItemType> = Default_UniqueKey,
	Props = {}, State = {},
	P extends Props_DBItemEditorComponentV2<Props, ItemType, Ks> = Props_DBItemEditorComponentV2<Props, ItemType, Ks>,
	S extends State_DBItemEditorComponentV2<State, ItemType> = State_DBItemEditorComponentV2<State, ItemType>>
	extends SmartComponent<P, S> {

	constructor(props: P) {
		super(props);
		// this.logDebug('constructor', props.keys);
		// @ts-ignore
		this[props.moduleFE.defaultDispatcher.method] = this.__onItemUpdated;
	}

	protected async deriveStateFromProps(nextProps: Props_SmartComponent & P, state: S & State_SmartComponent) {
		state.item = nextProps.keys && await nextProps.moduleFE.IDB.unique(nextProps.keys);
		if (!state.item)
			state.item = this.createEmptyItem();
		return state;
	}

	public createEmptyItem(): Partial<ItemType> {
		return {} as Partial<ItemType>;
	}

	render() {
		return this.renderItem(this.state.item);
	}

	protected save(item: Partial<ItemType>, key: keyof ItemType) {
		this.props.moduleFE.v1.upsert(item as ItemType).execute((updatedItem) => this.props.onChange?.(updatedItem, key));
	}

	private __onItemUpdated = (...params: ApiCallerEventType<ItemType>): void => {
		if (params[0] === EventType_UpsertAll || params[0] === EventType_Query)
			return this.reDeriveState();

		if (_keys(this.props.keys).find(key => this.state.item?.[key] && this.state.item?.[key] !== (params[1] as ItemType)?.[key]))
			return;

		if (params[0] === EventType_Update) {
			this.logDebug(`updated: ${params[1]}`);
			this.setState({item: (params[1] as ItemType)} as unknown as S);
			return;
		}

	};

	protected setPropValue = <K extends keyof ItemType>(key: K, value: Partial<ItemType[K]>) => {
		const item: (Editable<ItemType>) | undefined = this.state.item;
		if (!item)
			return this.logWarning('This should never happen!!! item is undefined');

		if (compare(item[key], value as ItemType[K]))
			return;

		item[key] = value as ItemType[K];
		this.props.onChange?.(item, key);
	};

	abstract renderItem(item?: Partial<ItemType>): React.ReactNode;
}

