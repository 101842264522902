"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openContent = exports.MenuBuilder = exports.getElementCenterPos = exports.resolveRealPosition = void 0;
const ModuleFE_MouseInteractivity_1 = require("./ModuleFE_MouseInteractivity");
const types_1 = require("./types");
const ts_common_1 = require("@nu-art/ts-common");
// ######################### General Helpers #########################
const resolveRealPosition = (button) => {
    const pos = button.getBoundingClientRect();
    return { y: pos.top + button.offsetHeight, x: pos.left };
};
exports.resolveRealPosition = resolveRealPosition;
const getElementCenterPos = (el) => {
    const rect = el.getBoundingClientRect();
    return {
        y: rect.y + (rect.height / 2),
        x: rect.x + (rect.width / 2),
    };
};
exports.getElementCenterPos = getElementCenterPos;
class MenuBuilder {
    constructor(menu, originPos, modalPos) {
        this.id = (0, ts_common_1.generateHex)(8);
        this.offset = { x: 0, y: 0 };
        this.adapter = menu;
        this.originPos = originPos;
        this.modalPos = modalPos;
    }
    show() {
        const model = {
            id: this.id,
            adapter: this.adapter,
            originPos: this.originPos,
            modalPos: this.modalPos,
            onNodeClicked: this.onNodeClicked,
            onNodeDoubleClicked: this.onNodeDoubleClicked,
            offset: this.offset
        };
        ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showMenu(model);
    }
    setId(id) {
        this.id = id;
        return this;
    }
    setOffset(offset) {
        this.offset = offset;
        return this;
    }
    setOnClick(func) {
        this.onNodeClicked = func;
        return this;
    }
    setOnDoubleClick(func) {
        this.onNodeDoubleClicked = func;
        return this;
    }
}
exports.MenuBuilder = MenuBuilder;
// ######################### Pop Up Helpers #########################
const OpenPopUpAtCenter = (id, content) => {
    return {
        onClick: (e) => {
            const model = {
                id,
                content,
                originPos: (0, exports.getElementCenterPos)(e.currentTarget),
                modalPos: { x: 0, y: 0 },
            };
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showContent(model);
        }
    };
};
const OpenPopUpAtLeft = (id, content, offset) => {
    return {
        onClick: (e) => {
            const margin = (e.currentTarget.getBoundingClientRect().width / 2);
            const model = {
                id,
                content,
                originPos: (0, exports.getElementCenterPos)(e.currentTarget),
                modalPos: { x: -1, y: 0 },
                offset: { x: -margin + (offset !== null && offset !== void 0 ? offset : 0), y: 0 }
            };
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showContent(model);
        }
    };
};
const OpenPopUpAtRight = (id, content, offset) => {
    return {
        onClick: (e) => {
            const margin = (e.currentTarget.getBoundingClientRect().width / 2);
            const model = {
                id,
                content,
                originPos: (0, exports.getElementCenterPos)(e.currentTarget),
                modalPos: { x: 1, y: 0 },
                offset: { x: margin + (offset !== null && offset !== void 0 ? offset : 0), y: 0 }
            };
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showContent(model);
        }
    };
};
const OpenPopUpAtBottom = (id, content, offset) => {
    return {
        onClick: (e) => {
            const triggerRect = e.currentTarget.getBoundingClientRect();
            const x = triggerRect.x + (triggerRect.width / 2);
            const y = triggerRect.y + (triggerRect.height / 2);
            const margin = (triggerRect.height / 2);
            const model = {
                id,
                content,
                originPos: { x, y },
                modalPos: { x: 0, y: 1 },
                offset: { x: 0, y: margin + (offset !== null && offset !== void 0 ? offset : 0) }
            };
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showContent(model);
        }
    };
};
const OpenPopUpAtTop = (id, content, offset) => {
    return {
        onClick: (e) => {
            const triggerRect = e.currentTarget.getBoundingClientRect();
            const x = triggerRect.x + (triggerRect.width / 2);
            const y = triggerRect.y + (triggerRect.height / 2);
            const margin = (triggerRect.height / 2);
            const model = {
                id,
                content,
                originPos: { x, y },
                modalPos: { x: 0, y: -1 },
                offset: { x: 0, y: -margin + (offset !== null && offset !== void 0 ? offset : 0) }
            };
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showContent(model);
        }
    };
};
const OpenToolTipAtCenter = (id, content, config) => {
    return {
        onMouseEnter: (e) => {
            const model = {
                id,
                content,
                originPos: (0, exports.getElementCenterPos)(e.currentTarget),
                modalPos: { x: 0, y: 0 },
                contentHoverDelay: config === null || config === void 0 ? void 0 : config.contentHoverDelay,
                overlayClass: config === null || config === void 0 ? void 0 : config.overlayClass,
                xAxisAnchor: config === null || config === void 0 ? void 0 : config.xAxisAnchor,
                yAxisAnchor: config === null || config === void 0 ? void 0 : config.yAxisAnchor,
            };
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showTooltip(model);
        },
        onMouseLeave: () => {
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.hide(types_1.mouseInteractivity_ToolTip);
        }
    };
};
const OpenToolTipAtLeft = (id, content, contentHoverDelay, config) => {
    return {
        onMouseEnter: (e) => {
            var _a;
            const margin = (e.currentTarget.getBoundingClientRect().width / 2);
            const model = {
                id,
                content,
                originPos: (0, exports.getElementCenterPos)(e.currentTarget),
                modalPos: { x: -1, y: 0 },
                offset: { x: -margin + ((_a = config === null || config === void 0 ? void 0 : config.offset) !== null && _a !== void 0 ? _a : 0), y: 0 },
                contentHoverDelay: config === null || config === void 0 ? void 0 : config.contentHoverDelay,
                overlayClass: config === null || config === void 0 ? void 0 : config.overlayClass,
                xAxisAnchor: config === null || config === void 0 ? void 0 : config.xAxisAnchor,
                yAxisAnchor: config === null || config === void 0 ? void 0 : config.yAxisAnchor,
            };
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showTooltip(model);
        },
        onMouseLeave: () => {
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.hide(types_1.mouseInteractivity_ToolTip);
        }
    };
};
const OpenToolTipAtRight = (id, content, config) => {
    return {
        onMouseEnter: (e) => {
            var _a;
            const margin = (e.currentTarget.getBoundingClientRect().width / 2);
            const model = {
                id,
                content,
                originPos: (0, exports.getElementCenterPos)(e.currentTarget),
                modalPos: { x: 1, y: 0 },
                offset: { x: margin + ((_a = config === null || config === void 0 ? void 0 : config.offset) !== null && _a !== void 0 ? _a : 0), y: 0 },
                contentHoverDelay: config === null || config === void 0 ? void 0 : config.contentHoverDelay,
                overlayClass: config === null || config === void 0 ? void 0 : config.overlayClass,
                xAxisAnchor: config === null || config === void 0 ? void 0 : config.xAxisAnchor,
                yAxisAnchor: config === null || config === void 0 ? void 0 : config.yAxisAnchor,
            };
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showTooltip(model);
        },
        onMouseLeave: () => {
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.hide(types_1.mouseInteractivity_ToolTip);
        }
    };
};
const OpenToolTipAtBottom = (id, content, config) => {
    return {
        onMouseEnter: (e) => {
            var _a;
            const margin = (e.currentTarget.getBoundingClientRect().width / 2);
            const model = {
                id,
                content,
                originPos: (0, exports.getElementCenterPos)(e.currentTarget),
                modalPos: { x: 0, y: 1 },
                offset: { x: 0, y: margin + ((_a = config === null || config === void 0 ? void 0 : config.offset) !== null && _a !== void 0 ? _a : 0) },
                contentHoverDelay: config === null || config === void 0 ? void 0 : config.contentHoverDelay,
                overlayClass: config === null || config === void 0 ? void 0 : config.overlayClass,
                xAxisAnchor: config === null || config === void 0 ? void 0 : config.xAxisAnchor,
                yAxisAnchor: config === null || config === void 0 ? void 0 : config.yAxisAnchor,
            };
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showTooltip(model);
        },
        onMouseLeave: () => {
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.hide(types_1.mouseInteractivity_ToolTip);
        }
    };
};
const OpenToolTipAtTop = (id, content, config) => {
    return {
        onMouseEnter: (e) => {
            var _a;
            const margin = (e.currentTarget.getBoundingClientRect().width / 2);
            const model = {
                id,
                content,
                originPos: (0, exports.getElementCenterPos)(e.currentTarget),
                modalPos: { x: 0, y: -1 },
                offset: { x: 0, y: -margin + ((_a = config === null || config === void 0 ? void 0 : config.offset) !== null && _a !== void 0 ? _a : 0) },
                contentHoverDelay: config === null || config === void 0 ? void 0 : config.contentHoverDelay,
                overlayClass: config === null || config === void 0 ? void 0 : config.overlayClass,
                xAxisAnchor: config === null || config === void 0 ? void 0 : config.xAxisAnchor,
                yAxisAnchor: config === null || config === void 0 ? void 0 : config.yAxisAnchor,
            };
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.showTooltip(model);
        },
        onMouseLeave: () => {
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.hide(types_1.mouseInteractivity_ToolTip);
        }
    };
};
// ######################### Menu Helpers #########################
const openMenuAtLeft = (e, content) => {
    return new MenuBuilder(content, (0, exports.getElementCenterPos)(e.currentTarget), { x: -1, y: 0 });
};
const openMenuAtRight = (e, content) => {
    return new MenuBuilder(content, (0, exports.getElementCenterPos)(e.currentTarget), { x: 1, y: 0 });
};
const openMenuAtTop = (e, content) => {
    return new MenuBuilder(content, (0, exports.getElementCenterPos)(e.currentTarget), { x: 0, y: -1 });
};
const openMenuAtBottom = (e, content) => {
    return new MenuBuilder(content, (0, exports.getElementCenterPos)(e.currentTarget), { x: 0, y: 1 });
};
const openMenuAtCenter = (e, content) => {
    return new MenuBuilder(content, (0, exports.getElementCenterPos)(e.currentTarget), { x: 0, y: 0 });
};
exports.openContent = {
    popUp: {
        left: OpenPopUpAtLeft,
        right: OpenPopUpAtRight,
        top: OpenPopUpAtTop,
        bottom: OpenPopUpAtBottom,
        center: OpenPopUpAtCenter,
    },
    tooltip: {
        left: OpenToolTipAtLeft,
        right: OpenToolTipAtRight,
        top: OpenToolTipAtTop,
        bottom: OpenToolTipAtBottom,
        center: OpenToolTipAtCenter,
    },
    menu: {
        left: openMenuAtLeft,
        right: openMenuAtRight,
        top: openMenuAtTop,
        bottom: openMenuAtBottom,
        center: openMenuAtCenter,
    }
};
