"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_StoreInventory = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const validators_1 = require("../../../validators");
const shopify_1 = require("../../../shopify");
const Validator_StoreInventory = Object.assign(Object.assign({}, shopify_1.StoreItem_validator), { title: validators_1.tsValidateStringOneLine, timestamp: ts_common_1.tsValidateMustExist, totalVariantsCount: ts_common_1.tsValidateMustExist, incompleteProductsCount: ts_common_1.tsValidateMustExist, totalItemsCount: ts_common_1.tsValidateMustExist, totalValue: ts_common_1.tsValidateMustExist });
exports.DBDef_StoreInventory = {
    validator: Validator_StoreInventory,
    dbName: 'store-inventory',
    entityName: 'store-inventory',
    uniqueKeys: ['storeId', 'timestamp']
};
