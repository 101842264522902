"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_CollapsableContainer = void 0;
const React = require("react");
require("./TS_CollapsableContainer.scss");
const ComponentSync_1 = require("../../core/ComponentSync");
const tools_1 = require("../../utils/tools");
const Layouts_1 = require("../Layouts");
class TS_CollapsableContainer extends ComponentSync_1.ComponentSync {
    constructor() {
        //######################### Life Cycle #########################
        super(...arguments);
        //######################### Logic #########################
        this.setContainerHeight = () => {
            if (!this.state.containerRef.current)
                return;
            const currentContent = this.state.contentRef.current;
            const maxHeight = this.state.collapsed
                ? 0
                : this.props.maxHeight ? this.props.maxHeight : currentContent.getBoundingClientRect().height;
            this.state.containerRef.current.style.maxHeight = typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight;
        };
        this.toggleCollapse = (e) => {
            var _a, _b;
            //If shared-components is controlled, return
            (_b = (_a = this.props).onCollapseToggle) === null || _b === void 0 ? void 0 : _b.call(_a, this.isCollapsed(), e);
            if (this.props.collapsed !== undefined)
                return;
            this.setState({ collapsed: !this.state.collapsed });
        };
    }
    deriveStateFromProps(nextProps) {
        var _a, _b, _c, _d, _e;
        const state = this.state ? Object.assign({}, this.state) : {};
        state.collapsed = (_c = (_a = nextProps.collapsed) !== null && _a !== void 0 ? _a : (_b = this.state) === null || _b === void 0 ? void 0 : _b.collapsed) !== null && _c !== void 0 ? _c : true;
        (_d = state.contentRef) !== null && _d !== void 0 ? _d : (state.contentRef = React.createRef());
        (_e = state.containerRef) !== null && _e !== void 0 ? _e : (state.containerRef = React.createRef());
        return state;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setContainerHeight();
    }
    componentDidMount() {
        if (this.state.collapsed)
            this.setContainerHeight();
        else
            setTimeout(() => this.setContainerHeight(), 10);
    }
    //TODO: check if this is still supposed to be here
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // let res = super.shouldComponentUpdate(nextProps, nextState, nextContext);
        // if (!res)
        // 	res = !compare(this.props.collapsed, nextProps.collapsed);
        // return res;
        return true;
    }
    isCollapsed() {
        //Return the collapse from props if controlled, otherwise state
        return this.props.collapsed !== undefined ? this.props.collapsed : this.state.collapsed;
    }
    //######################### Render #########################
    renderCaret() {
        if (this.props.showCaret === false)
            return '';
        const collapsed = this.isCollapsed();
        const className = (0, tools_1._className)('ts-collapsable-container__header__caret', collapsed ? 'collapsed' : undefined);
        if (this.props.customCaret)
            return React.createElement("span", { className: className }, typeof this.props.customCaret === 'function' ? this.props.customCaret() : this.props.customCaret);
        return React.createElement("span", { className: className }, this.isCollapsed() ? '+' : '-');
    }
    renderHeader() {
        const className = (0, tools_1._className)('ts-collapsable-container__header', this.isCollapsed() ? 'collapsed' : undefined, this.props.flipHeaderOrder ? 'flip' : undefined);
        return React.createElement("div", { className: className, onClick: this.toggleCollapse },
            this.renderCaret(),
            typeof this.props.headerRenderer === 'function' ? this.props.headerRenderer() : this.props.headerRenderer);
    }
    renderContainer() {
        const className = (0, tools_1._className)('ts-collapsable-container__container', this.isCollapsed() ? 'collapsed' : undefined);
        return React.createElement("div", { className: className, ref: this.state.containerRef },
            React.createElement("div", { ref: this.state.contentRef, className: 'ts-collapsable-container__container-wrapper', style: { width: '100%' } }, (typeof this.props.containerRenderer === 'function' ? this.props.containerRenderer() : this.props.containerRenderer)));
    }
    render() {
        const className = (0, tools_1._className)('ts-collapsable-container', this.props.className);
        return React.createElement(Layouts_1.LL_V_L, { className: className, style: this.props.style, id: this.props.id, onMouseOver: this.props.onMouseOver, onMouseEnter: this.props.onMouseEnter, onMouseLeave: this.props.onMouseLeave },
            this.renderHeader(),
            this.renderContainer());
    }
}
exports.TS_CollapsableContainer = TS_CollapsableContainer;
