import {AssetRenderer} from '@component/AssetRenderer';
import * as React from 'react';
import {ICONSV4} from '@res/icons';
import {ModuleFE_Assets} from '@nu-art/file-upload/frontend';
import {SmartComponent, State_SmartComponent} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_Dialog, ModuleFE_Thunderstorm, stopPropagation} from '@nu-art/thunderstorm/frontend';
import './AssetView.scss';


type Props = {
	assetId: string
	onDelete?: (assetId: string) => void
}

type State = {
	hover: boolean
	signedUrl: string
}

export class AssetView
	extends SmartComponent<Props, State> {

	static defaultProps = {
		modules: [ModuleFE_Assets]
	};

	constructor(p: Props) {
		super(p);
	}

	protected async deriveStateFromProps(nextProps: Props, state: State_SmartComponent & State) {
		state.hover = false;
		state.signedUrl = await ModuleFE_Assets.resolveValidSignedUrl(this.props.assetId);
		return state;
	}

	render() {
		const asset = ModuleFE_Assets.cache.unique(this.props.assetId)!;
		if (!this.state.signedUrl)
			return <div className="ll_v_c">'hmm... no asset??'
				{ICONSV4.bin({
					onClick: (ev) => {
						stopPropagation(ev);
						this.props.onDelete?.(this.props.assetId);
					}
				})}</div>;

		const url = this.state.signedUrl;
		if (!url)
			return 'hmm... no url??';

		return (
			<div
				className={'asset-view'}
				onMouseEnter={() => this.setState({hover: true})}
				onMouseLeave={() => this.setState({hover: false})}>
				<div className={'asset-renderer-wrapper'}>
					<AssetRenderer
						src={url}
						mimeType={asset.mimeType}
						data={asset}/>
				</div>

				{this.state.hover &&
					<div className="clickable asset-renderer-overlay"
							 onClick={(ev) => {
								 stopPropagation(ev);
								 ModuleFE_Dialog.show(<div className={"asset-full-viewer"}>
									 <AssetRenderer
										 src={url}
										 mimeType={asset.mimeType}
										 data={asset}/>
								 </div>);
							 }}>
						{this.renderDeleteButton(url)}
						{this.renderDownloadButton(url)}
					</div>}
			</div>);
	}

	private renderDeleteButton(url: string) {
		const onDelete = this.props.onDelete;
		if (!onDelete)
			return;

		return <div className="clickable">
			{ICONSV4.bin({
				onClick: (ev) => {
					stopPropagation(ev);
					AssetRenderer.revokeFileUrl(url);
					onDelete(this.props.assetId);
				}
			})}
		</div>;
	}

	private renderDownloadButton(url: string) {
		const onDelete = this.props.onDelete;
		if (!onDelete)
			return;

		const asset = ModuleFE_Assets.cache.unique(this.props.assetId);
		if (!asset)
			return;

		return <div className="clickable">
			{ICONSV4.download({
				onClick: (ev) => {
					stopPropagation(ev);
					ModuleFE_Thunderstorm.downloadFile({url, fileName: asset.name});
				}
			})}
		</div>;
	}
}

