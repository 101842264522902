"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_PermissionsProject_ = exports.ModuleFE_PermissionsProject = exports.ModuleFE_PermissionsProject_Class = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const shared_1 = require("../../shared");
const dispatch_OnPermissionsProjectsUpdated = new frontend_1.ThunderDispatcher('__OnPermissionsProjectsUpdated');
class ModuleFE_PermissionsProject_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_PermissionProjects, dispatch_OnPermissionsProjectsUpdated);
    }
}
exports.ModuleFE_PermissionsProject_Class = ModuleFE_PermissionsProject_Class;
exports.ModuleFE_PermissionsProject = new ModuleFE_PermissionsProject_Class();
exports.ModuleFE_PermissionsProject_ = exports.ModuleFE_PermissionsProject;
