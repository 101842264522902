"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_FormV3 = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const core_1 = require("../../core");
const TS_PropRenderer_1 = require("../TS_PropRenderer");
class Component_FormV3 extends core_1.ComponentSync {
    constructor(p) {
        super(p);
    }
    deriveStateFromProps(nextProps, state) {
        const _state = (state || {});
        _state.editable = nextProps.editable;
        return _state;
    }
    render() {
        return (React.createElement("div", { className: `ll_v_c ${this.props.className}`, style: { justifyContent: 'space-evenly' } }, (0, ts_common_1._keys)(this.props.renderers).map(key => this.renderField(this.state.editable, key))));
    }
    renderField(editable, prop) {
        const renderer = this.props.renderers[prop];
        const Editor = renderer.editor;
        return React.createElement(TS_PropRenderer_1.TS_PropRenderer.Vertical, { label: renderer.label },
            React.createElement(Editor, { editable: editable, prop: prop }));
    }
}
Component_FormV3.defaultProps = {};
exports.Component_FormV3 = Component_FormV3;
