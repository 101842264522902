"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_AssetUploader = exports.ApiDef_Assets = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
exports.ApiDef_Assets = {
    vv1: {
        fetchSpecificFile: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/assets/get-read-secured-url' },
    }
};
exports.ApiDef_AssetUploader = {
    vv1: {
        uploadFile: { method: thunderstorm_1.HttpMethod.PUT, path: '' },
        getUploadUrl: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/upload/get-url' },
        processAssetManually: { method: thunderstorm_1.HttpMethod.GET, path: 'v1/upload/process-asset-manually' },
    }
};
