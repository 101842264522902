"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiSelect = exports.Permissions_DropDown = void 0;
const React = require("react");
const ModuleFE_PermissionsAccessLevel_1 = require("../modules/manage/ModuleFE_PermissionsAccessLevel");
const ModuleFE_PermissionsDomain_1 = require("../modules/manage/ModuleFE_PermissionsDomain");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const ModuleFE_PermissionsGroup_1 = require("../modules/assign/ModuleFE_PermissionsGroup");
const TS_MultiSelect_1 = require("@nu-art/thunderstorm/frontend/components/TS_MultiSelect");
const ModuleFE_PermissionsProject_1 = require("../modules/manage/ModuleFE_PermissionsProject");
const Props_Project = {
    module: ModuleFE_PermissionsProject_1.ModuleFE_PermissionsProject,
    modules: [ModuleFE_PermissionsProject_1.ModuleFE_PermissionsProject_],
    mapper: item => [item.name],
    placeholder: 'Choose a Project',
    renderer: item => React.createElement("div", { key: item._id }, item.name),
    sortBy: [item => item.name]
};
const Props_AccessLevel = {
    module: ModuleFE_PermissionsAccessLevel_1.ModuleFE_PermissionsAccessLevel,
    modules: [ModuleFE_PermissionsAccessLevel_1.ModuleFE_PermissionsAccessLevel_],
    mapper: level => {
        const domain = ModuleFE_PermissionsDomain_1.ModuleFE_PermissionsDomain.cache.unique(level.domainId);
        return [domain.namespace, level.name, String(level.value)];
    },
    placeholder: 'Choose an Access Level',
    renderer: item => {
        const levelId = item._id;
        const level = ModuleFE_PermissionsAccessLevel_1.ModuleFE_PermissionsAccessLevel.cache.unique(levelId);
        const domain = ModuleFE_PermissionsDomain_1.ModuleFE_PermissionsDomain.cache.unique(level.domainId);
        return React.createElement("div", { key: levelId }, `${domain.namespace}: ${level.name} (${level.value})`);
    },
    sortBy: [
        item => item.value,
        item => ModuleFE_PermissionsDomain_1.ModuleFE_PermissionsDomain.cache.unique(item.domainId).namespace
    ]
};
const Props_Domain = {
    module: ModuleFE_PermissionsDomain_1.ModuleFE_PermissionsDomain,
    modules: [ModuleFE_PermissionsDomain_1.ModuleFE_PermissionsDomain_],
    mapper: item => [item.namespace],
    placeholder: 'Choose a Domain',
    renderer: item => {
        const domainId = item._id;
        const domain = ModuleFE_PermissionsDomain_1.ModuleFE_PermissionsDomain.cache.unique(domainId);
        return React.createElement("div", { key: domainId }, domain.namespace);
    },
    sortBy: [
        item => item.namespace,
    ]
};
const Props_Group = {
    module: ModuleFE_PermissionsGroup_1.ModuleFE_PermissionsGroup,
    modules: [ModuleFE_PermissionsGroup_1.ModuleFE_PermissionsGroup_],
    mapper: item => [item.label],
    placeholder: 'Choose a Group',
    renderer: item => {
        const groupId = item._id;
        const group = ModuleFE_PermissionsGroup_1.ModuleFE_PermissionsGroup.cache.unique(groupId);
        return React.createElement("div", { key: groupId }, group === null || group === void 0 ? void 0 : group.label);
    },
    sortBy: [
        item => item.label,
    ]
};
exports.Permissions_DropDown = {
    Project: (props) => React.createElement(frontend_1.GenericDropDown, Object.assign({}, Props_Project, props)),
    AccessLevel: (props) => React.createElement(frontend_1.GenericDropDown, Object.assign({}, Props_AccessLevel, props)),
    Domain: (props) => React.createElement(frontend_1.GenericDropDown, Object.assign({}, Props_Domain, props)),
    Group: (props) => React.createElement(frontend_1.GenericDropDown, Object.assign({}, Props_Group, props)),
};
class DomainLevelRenderer extends frontend_1.ComponentSync {
    render() {
        const selectedLevels = ModuleFE_PermissionsAccessLevel_1.ModuleFE_PermissionsAccessLevel.cache.filter(item => this.props.existingItems.includes(item._id));
        const availableDomainsIds = ModuleFE_PermissionsDomain_1.ModuleFE_PermissionsDomain.cache.filter(item => !selectedLevels.find(level => level.domainId === item._id)).map(ts_common_1.dbObjectToId);
        return React.createElement("div", null,
            React.createElement(exports.Permissions_DropDown.Domain, { selected: this.state.domainId, queryFilter: domain => availableDomainsIds.includes(domain._id), onSelected: domain => this.setState({ domainId: domain._id }) }),
            React.createElement(exports.Permissions_DropDown.AccessLevel, { disabled: !this.state.domainId, queryFilter: level => this.state.domainId === level.domainId, selected: undefined, onSelected: (accessLevel) => this.props.onSelected(accessLevel._id) }));
    }
    deriveStateFromProps(nextProps, state) {
        return { domainId: undefined, onSelected: nextProps.onSelected };
    }
}
class GroupRenderer extends frontend_1.ComponentSync {
    render() {
        return React.createElement("div", null,
            React.createElement(exports.Permissions_DropDown.Group, { queryFilter: group => !this.props.existingItems.find(g => g.groupId === group._id), selected: undefined, onSelected: group => this.props.onSelected({ groupId: group._id }) }));
    }
    deriveStateFromProps(nextProps, state) {
        return { onSelected: nextProps.onSelected };
    }
}
const MultiSelect_PermissionsAccessLevel = {
    itemRenderer: (accessLevelId, onDelete) => {
        var _a;
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: "ts-icon__small", onClick: onDelete }, "X"),
            React.createElement("span", null, ((_a = ModuleFE_PermissionsAccessLevel_1.ModuleFE_PermissionsAccessLevel.cache.unique(accessLevelId)) === null || _a === void 0 ? void 0 : _a.name) || 'not found'));
    },
    selectionRenderer: DomainLevelRenderer
};
const MultiSelect_PermissionsAccessLevelStam = {
    itemRenderer: (accessLevelId, onDelete) => {
        var _a;
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: "ts-icon__small", onClick: onDelete }, "X"),
            React.createElement("span", null, ((_a = ModuleFE_PermissionsAccessLevel_1.ModuleFE_PermissionsAccessLevel.cache.unique(accessLevelId)) === null || _a === void 0 ? void 0 : _a.name) || 'not found'));
    },
    selectionRenderer: (selector) => React.createElement(exports.Permissions_DropDown.AccessLevel, { queryFilter: item => {
            const selectedLevels = ModuleFE_PermissionsAccessLevel_1.ModuleFE_PermissionsAccessLevel.cache.filter(item => selector.existingItems.includes(item._id));
            const availableDomainsIds = ModuleFE_PermissionsDomain_1.ModuleFE_PermissionsDomain.cache.filter(item => !selectedLevels.find(level => level.domainId === item._id)).map(ts_common_1.dbObjectToId);
            return availableDomainsIds.includes(item.domainId);
        }, onSelected: item => selector.onSelected(item._id) })
};
const MultiSelect_PermissionsGroup = {
    itemRenderer: (group, onDelete) => {
        var _a;
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: "ts-icon__small", onClick: onDelete }, "X"),
            React.createElement("span", null, ((_a = ModuleFE_PermissionsGroup_1.ModuleFE_PermissionsGroup.cache.unique(group === null || group === void 0 ? void 0 : group.groupId)) === null || _a === void 0 ? void 0 : _a.label) || 'not found'));
    },
    selectionRenderer: GroupRenderer
};
exports.MultiSelect = {
    AccessLevel_StamTest: TS_MultiSelect_1.TS_MultiSelect_V2.prepare(MultiSelect_PermissionsAccessLevelStam),
    AccessLevel: TS_MultiSelect_1.TS_MultiSelect_V2.prepare(MultiSelect_PermissionsAccessLevel),
    Group: TS_MultiSelect_1.TS_MultiSelect_V2.prepare(MultiSelect_PermissionsGroup),
};
