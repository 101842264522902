import * as React from 'react';
import {TS_Route} from '@nu-art/thunderstorm/frontend';
import {DB_ProductVariantConfig} from '@app/shared';
import {Filter} from '@nu-art/ts-common';
import {EditorItem_ProductVariantConfig} from './EditorItem_ProductVariantConfig';
import {Page_ItemsEditor, Props_ItemsEditor} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_ProductVariantConfigs_} from '@modules/shopify/ModuleFE_ProductVariantConfigs';
import {ModuleFE_Vendors_} from '@modules/shopify';


export class Page_ProductsVariantConfigV2
	extends Page_ItemsEditor<DB_ProductVariantConfig> {

	static Route: TS_Route<{ _id?: string }> = {path: 'product-variants', key: 'product-variants-editor', Component: this};

	static defaultProps: Partial<Props_ItemsEditor<DB_ProductVariantConfig>> = {
		pageTitle: 'Products',
		modules: [ModuleFE_ProductVariantConfigs_, ModuleFE_Vendors_],
		module: ModuleFE_ProductVariantConfigs_,
		filter: new Filter<DB_ProductVariantConfig>((vendor) => [vendor.label, vendor.description || '']),
		sort: (item: DB_ProductVariantConfig) => item.label,
		itemRenderer: (item: DB_ProductVariantConfig) => <>{item.label}</>,
		EditorRenderer: EditorItem_ProductVariantConfig,
		route: this.Route
	};
}

