"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageKey = exports.ModuleFE_LocalStorage = exports.StorageModule_Class = void 0;
/**
 * Created by tacb0ss on 27/07/2018.
 */
const ts_common_1 = require("@nu-art/ts-common");
const thunder_dispatcher_1 = require("../core/thunder-dispatcher");
class StorageModule_Class extends ts_common_1.Module {
    constructor() {
        super(...arguments);
        this.cache = {};
        this.keys = [];
        this.handleStorageEvent = (e) => {
            const dispatcher = new thunder_dispatcher_1.ThunderDispatcher('__onStorageKeyEvent');
            dispatcher.dispatchUI(e);
            dispatcher.dispatchModule(e);
        };
        this.getStorage = (persist) => persist ? localStorage : sessionStorage;
    }
    // private isIncognito!: boolean;
    init() {
        window.addEventListener('storage', this.handleStorageEvent);
        // const IsIncognito = new StorageKey<boolean>('is-incognito');
        // IsIncognito.set(false);
        // this.isIncognito = IsIncognito.get(true);
    }
    async __onClearWebsiteData(resync) {
        const items = this.keys.map(key => key.get());
        localStorage.clear();
        this.keys.forEach((key, index) => key.set(items[index]));
    }
    set(key, value, persist = true) {
        if (value === undefined)
            return this.delete(key);
        this.cache[key] = value;
        this.getStorage(persist).setItem(key, JSON.stringify(value));
    }
    delete(key, persist = true) {
        this.clearCache(key);
        this.getStorage(persist).removeItem(key);
    }
    clearCache(key) {
        delete this.cache[key];
    }
    get(key, defaultValue, persist = true) {
        let value = this.cache[key];
        if (value)
            return value;
        value = this.getStorage(persist).getItem(key);
        // this.logDebug(`get: ${key} = ${value}`)
        if (!(0, ts_common_1.exists)(value) || value === 'null' || value === 'undefined')
            return defaultValue;
        // if (!this.isIncognito)
        return this.cache[key] = JSON.parse(value);
    }
    query(query) {
        const toRet = [];
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key === null || key === void 0 ? void 0 : key.match(query)) {
                const item = localStorage.getItem(key);
                if (item) {
                    try {
                        const exp = JSON.parse(item);
                        toRet.push(exp);
                    }
                    catch (e) {
                        this.logError(e);
                    }
                }
            }
        }
        return toRet;
    }
    deleteAll(query) {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key === null || key === void 0 ? void 0 : key.match(query)) {
                localStorage.removeItem(key);
            }
        }
    }
    addKey(storageKey) {
        this.keys.push(storageKey);
    }
}
exports.StorageModule_Class = StorageModule_Class;
exports.ModuleFE_LocalStorage = new StorageModule_Class();
//TODO Generic Keys like in the tests contexts
class StorageKey {
    constructor(key, persist = true) {
        this.key = key;
        this.persist = persist;
    }
    withstandDeletion() {
        exports.ModuleFE_LocalStorage.addKey(this);
        return this;
    }
    get(defaultValue) {
        // @ts-ignore
        return exports.ModuleFE_LocalStorage.get(this.key, defaultValue, this.persist);
    }
    patch(value) {
        const previousValue = this.get();
        const mergedValue = (0, ts_common_1.merge)(previousValue, value);
        this.set(mergedValue);
        return mergedValue;
    }
    set(value) {
        // @ts-ignore
        exports.ModuleFE_LocalStorage.set(this.key, value, this.persist);
        // console.log('StorageKey', this.key);
        return value;
    }
    delete() {
        exports.ModuleFE_LocalStorage.delete(this.key, this.persist);
    }
    clearCache() {
        exports.ModuleFE_LocalStorage.clearCache(this.key);
    }
}
exports.StorageKey = StorageKey;
