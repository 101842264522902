"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_ToastOverlay = void 0;
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
const ModuleFE_Toaster_1 = require("../../component-modules/ModuleFE_Toaster");
require("./TS_ToastOverlay.scss");
class TS_ToastOverlay extends ComponentSync_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.__showToast = (model) => {
            this.setState({ model });
            if (!model)
                return;
            const duration = model.duration;
            if (duration <= 0)
                return;
            setTimeout(() => ModuleFE_Toaster_1.ModuleFE_Toaster.hideToast(model), duration);
        };
    }
    deriveStateFromProps(nextProps) {
        var _a;
        return { model: (_a = this.state) === null || _a === void 0 ? void 0 : _a.model };
    }
    render() {
        const toast = this.state.model;
        if (!(toast === null || toast === void 0 ? void 0 : toast.content))
            return null;
        return this.renderToaster(toast);
    }
    renderToaster(toast) {
        return (React.createElement("div", { className: "ts-toaster" }, toast.content));
    }
}
exports.TS_ToastOverlay = TS_ToastOverlay;
