"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticLogger = exports.Logger = void 0;
const debug_flags_1 = require("../debug-flags");
const types_1 = require("./types");
const BeLogged_1 = require("./BeLogged");
class Logger {
    constructor(tag) {
        this.tag = tag !== null && tag !== void 0 ? tag : this.constructor['name'];
        this._DEBUG_FLAG = debug_flags_1.DebugFlags.createFlag(this.tag);
        this._DEBUG_FLAG.enable(Logger.defaultFlagState);
    }
    setMinLevel(minLevel) {
        this._DEBUG_FLAG.setMinLevel(minLevel);
    }
    setTag(tag) {
        this.tag = tag;
        this._DEBUG_FLAG.rename(tag);
    }
    logVerbose(...toLog) {
        this.log(types_1.LogLevel.Verbose, false, toLog);
    }
    logDebug(...toLog) {
        this.log(types_1.LogLevel.Debug, false, toLog);
    }
    logInfo(...toLog) {
        this.log(types_1.LogLevel.Info, false, toLog);
    }
    logWarning(...toLog) {
        this.log(types_1.LogLevel.Warning, false, toLog);
    }
    logError(...toLog) {
        this.log(types_1.LogLevel.Error, false, toLog);
    }
    logVerboseBold(...toLog) {
        this.log(types_1.LogLevel.Verbose, true, toLog);
    }
    logDebugBold(...toLog) {
        this.log(types_1.LogLevel.Debug, true, toLog);
    }
    logInfoBold(...toLog) {
        this.log(types_1.LogLevel.Info, true, toLog);
    }
    logWarningBold(...toLog) {
        this.log(types_1.LogLevel.Warning, true, toLog);
    }
    logErrorBold(...toLog) {
        this.log(types_1.LogLevel.Error, true, toLog);
    }
    log(level, bold, toLog) {
        if (!this.assertCanPrint(level))
            return;
        // @ts-ignore
        BeLogged_1.BeLogged.logImpl(this.tag, level, bold, toLog);
    }
    assertCanPrint(level) {
        if (!this._DEBUG_FLAG.isEnabled())
            return;
        return this._DEBUG_FLAG.canLog(level);
    }
}
Logger.defaultFlagState = true;
exports.Logger = Logger;
class StaticLogger {
    static setMinLevel(minLevel) {
        this._DEBUG_FLAG.setMinLevel(minLevel);
    }
    static logVerbose(tag, ...toLog) {
        this.log(tag, types_1.LogLevel.Verbose, false, toLog);
    }
    static logDebug(tag, ...toLog) {
        this.log(tag, types_1.LogLevel.Debug, false, toLog);
    }
    static logInfo(tag, ...toLog) {
        this.log(tag, types_1.LogLevel.Info, false, toLog);
    }
    static logWarning(tag, ...toLog) {
        this.log(tag, types_1.LogLevel.Warning, false, toLog);
    }
    static logError(tag, ...toLog) {
        this.log(tag, types_1.LogLevel.Error, false, toLog);
    }
    static logVerboseBold(tag, ...toLog) {
        this.log(tag, types_1.LogLevel.Verbose, true, toLog);
    }
    static logDebugBold(tag, ...toLog) {
        this.log(tag, types_1.LogLevel.Debug, true, toLog);
    }
    static logInfoBold(tag, ...toLog) {
        this.log(tag, types_1.LogLevel.Info, true, toLog);
    }
    static logWarningBold(tag, ...toLog) {
        this.log(tag, types_1.LogLevel.Warning, true, toLog);
    }
    static logErrorBold(tag, ...toLog) {
        this.log(tag, types_1.LogLevel.Error, true, toLog);
    }
    static log(tag, level, bold, toLog) {
        if (!this.assertCanPrint(level))
            return;
        // @ts-ignore
        BeLogged_1.BeLogged.logImpl(tag, level, bold, toLog);
    }
    static assertCanPrint(level) {
        if (!this._DEBUG_FLAG.isEnabled())
            return;
        return this._DEBUG_FLAG.canLog(level);
    }
}
StaticLogger._DEBUG_FLAG = debug_flags_1.DebugFlags.createFlag('StaticLogger');
(() => {
    StaticLogger._DEBUG_FLAG.enable(Logger.defaultFlagState);
})();
exports.StaticLogger = StaticLogger;
