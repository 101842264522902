"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_ActionProcessorConfirmation = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const ComponentSync_1 = require("../../../core/ComponentSync");
const ts_styles_1 = require("@nu-art/ts-styles");
require("./Dialog_ActionProcessorConfirmation.scss");
const ModuleFE_Dialog_1 = require("../../../component-modules/ModuleFE_Dialog");
const Layouts_1 = require("../../../components/Layouts");
const TS_Button_1 = require("../../../components/TS_Button");
class Dialog_ActionProcessorConfirmation extends ComponentSync_1.ComponentSync {
    constructor() {
        // ######################### Static #########################
        super(...arguments);
        // ######################### Logic #########################
        this.closeDialog = () => {
            ModuleFE_Dialog_1.ModuleFE_Dialog.close();
        };
        this.executeAction = () => {
            this.props.onExecute();
            this.closeDialog();
        };
        // ######################### Render #########################
        this.renderHeader = () => {
            return React.createElement(Layouts_1.LL_H_C, { className: 'dialog__header' },
                React.createElement("div", { className: 'dialog__header__title' }, "Refactoring Action Conformation"));
        };
        this.renderMain = () => {
            return React.createElement(Layouts_1.LL_H_C, { className: 'dialog__main' },
                React.createElement(ts_styles_1.TS_Icons.information.component, { className: 'dialog__main__warning-icon' }),
                React.createElement(Layouts_1.LL_V_L, { className: 'dialog__main__warning' },
                    React.createElement("div", { className: 'dialog__main__warning__title' }, "Action Description"),
                    React.createElement("div", { className: 'dialog__main__warning__text' }, this.props.action.description)));
        };
        this.renderButtons = () => {
            return React.createElement(Layouts_1.LL_H_C, { className: 'dialog__buttons' },
                React.createElement(TS_Button_1.TS_Button, { onClick: this.closeDialog }, "Cancel"),
                React.createElement(TS_Button_1.TS_Button, { onClick: this.executeAction }, "Execute"));
        };
    }
    static show(action, onExecute) {
        ModuleFE_Dialog_1.ModuleFE_Dialog.show(React.createElement(Dialog_ActionProcessorConfirmation, { action: action, onExecute: onExecute }), ts_common_1.functionThatReturnsTrue);
    }
    // ######################### Life Cycle #########################
    deriveStateFromProps(nextProps, state) {
    }
    render() {
        return React.createElement(Layouts_1.LL_V_L, { className: 'dialog-refactoring-action-conformation' },
            this.renderHeader(),
            this.renderMain(),
            this.renderButtons());
    }
}
exports.Dialog_ActionProcessorConfirmation = Dialog_ActionProcessorConfirmation;
