"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dispatcher = exports.Processor = void 0;
const Logger_1 = require("./logger/Logger");
class Processor extends Logger_1.Logger {
    constructor(method) {
        super(method);
        this.method = method;
        this.filter = (listener) => !!listener[this.method];
    }
    processModules(processor) {
        return this.filterModules().filter(this.filter).map(processor);
    }
    processModulesAsync(processor) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.all(this.filterModules().map(processor));
        });
    }
    processModulesAsyncSerial(processor) {
        return __awaiter(this, void 0, void 0, function* () {
            const modules = this.filterModules();
            const toRet = [];
            for (const module of modules) {
                toRet.push(yield processor(module));
            }
            return toRet;
        });
    }
    filterModules() {
        const listeners = Dispatcher.modulesResolver();
        return listeners.filter(this.filter);
    }
}
exports.Processor = Processor;
class Dispatcher extends Processor {
    constructor(method) {
        super(method);
    }
    dispatchModule(...p) {
        return this.processModules((listener) => {
            // @ts-ignore
            return (listener[this.method])(...p);
        });
    }
    dispatchModuleAsync(...p) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.processModulesAsync((listener) => {
                // const newVar = this.resolveListenerName(listener);
                // this.logVerbose(`Calling ${newVar} (${p})`);
                // @ts-ignore
                return listener[this.method](...p);
            });
        });
    }
    // private resolveListenerName(listener: any) {
    // 	return 'name' in listener ? listener.name :
    // 		'constructor' in listener ? listener['constructor']['name'] : '';
    // }
    dispatchModuleAsyncSerial(...p) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.processModulesAsyncSerial((listener) => {
                // @ts-ignore
                return listener[this.method](...p);
            });
        });
    }
}
exports.Dispatcher = Dispatcher;
