"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shipping_WayBill = exports.Types_WayBill = exports.LocalTransport = exports.AirwayBill = void 0;
exports.AirwayBill = 'airway-bill';
exports.LocalTransport = 'local-transport';
exports.Types_WayBill = [exports.AirwayBill, exports.LocalTransport];
exports.Shipping_WayBill = {
    fileTypes: ['pdf', 'tif', 'tiff', 'png', 'jpeg', 'jpg'],
    key: 'way-bill',
    options: [{ label: 'AWB - AirWay Bill', key: exports.AirwayBill }]
};
