"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_ActionProcessor = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const action_processor_1 = require("../../../shared/action-processor");
const typed_api_1 = require("../../core/typed-api");
class ModuleFE_ActionProcessor_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.vv1 = {
            execute: (0, typed_api_1.apiWithBody)(action_processor_1.ApiDef_ActionProcessing.vv1.execute),
            list: (0, typed_api_1.apiWithQuery)(action_processor_1.ApiDef_ActionProcessing.vv1.list),
        };
    }
}
exports.ModuleFE_ActionProcessor = new ModuleFE_ActionProcessor_Class();
