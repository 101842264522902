import * as React from 'react';
import {
	COLORS,
	ColorsType
} from '@res/colors';
import {_keys} from '@nu-art/ts-common';
import {
	AppPage,
	TS_TextArea
} from '@nu-art/thunderstorm/frontend';


class Pg_Component
	extends AppPage<{}, { selected?: keyof ColorsType }> {

	static defaultProps = {
		pageTitle: () => PgDev_Colors.name
	};

	private createDiv = (key: keyof ColorsType, color: () => string) => {
		const colorHex = color();
		return <div key={key} className="ll_v_c" style={{padding: '20px'}} onClick={() => this.setState({selected: key})}>
			<div style={{
				width: '50px',
				height: '50px',
				borderRadius: '50px',
				marginBottom: '5px',
				border: '1px solid black',
				background: colorHex
			}}/>
			<div style={{marginBottom: '2px', fontFamily: '"Courier New", Courier, monospace'}}>{key}</div>
			<div style={{marginBottom: '2px', fontFamily: '"Courier New", Courier, monospace'}}>{colorHex}</div>
		</div>;
	};

	constructor(p: {}) {
		super(p,);
	}

	protected deriveStateFromProps(nextProps: {}) {
		return {selected: _keys(COLORS)[0]};
	}

	private createGrid = () => _keys(COLORS).map(color => this.createDiv(color, COLORS[color]));

	render() {
		return <div className="match_height match_width" style={{overflowY: 'scroll'}}>
			{this.renderSelected()}
			<div className="ll_h_c">
				{this.createGrid()}
			</div>
		</div>;
	}

	private renderSelected() {
		const snippet = `COLORS.${this.state.selected}()`;
		return <TS_TextArea
			id={'color-snippet'}
			type={'text'}
			style={{fontFamily: 'monospace !important', resize: 'none', width: '400px', marginRight: '12px'}}
			value={snippet}
			enable={false}/>;
	}
}

export const PgDev_Colors = {name: 'DevTool - Color', renderer: Pg_Component};
