import * as React from 'react';
import {AppPage, LL_H_C, LL_V_L, TS_Input, TS_Link} from '@nu-art/thunderstorm/frontend';
import {ICONSV4} from '@res/icons';


const Message_DidYouCallUs = 'היי\nראינו שניסית ליצור איתנו קשר, איך אפשר לעזור?';

class Pg_Component
	extends AppPage<{}, { phone?: string }> {

	static defaultProps = {
		pageTitle: () => PgDev_WhatsApp.name
	};

	constructor(p: {}) {
		super(p);
	}

	protected deriveStateFromProps(nextProps: {}) {
		return {};
	}

	componentDidMount() {
	}

	render() {
		return <LL_V_L><LL_H_C style={{width: 1000, marginTop: 60}}>
			<TS_Input type={'text'} onChange={(phone) => this.setState({phone})}/>
			<TS_Link
				url={'https://web.whatsapp.com/send'}
				params={{phone: this.state.phone, text: Message_DidYouCallUs}}
				target={'_blank'}
			>Did you call us? <ICONSV4.send/></TS_Link>

		</LL_H_C>
		</LL_V_L>;
	}
}

export const PgDev_WhatsApp = {name: 'DevTool - WhatsApp', renderer: Pg_Component};
