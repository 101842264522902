import * as React from 'react';
import {BadBarcodeDef, DB_Vendor} from '@app/shared';
import {CellRenderer, LL_H_T, LL_V_L, LL_VH_C, TS_Input, TS_PropRenderer, TS_Table} from '@nu-art/thunderstorm/frontend';
import {OtherDropDown} from '@pah/app-dropdown';
import {AssetView} from '@form/asset/AssetView';
import {ICONSV4} from '@res/icons';
import {removeFromArrayByIndex} from '@nu-art/ts-common';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';


export class EditorItem_Vendor
	extends Item_Editor<DB_Vendor> {

	render() {
		const editable = this.props.editable;

		const item = editable.item;
		return <LL_H_T className="flex__grow match_height margin__inline">
			<LL_V_L>
				<LL_H_T className="margin__bottom">
					<LL_V_L className="item-editor margin__end">
						<TS_PropRenderer.Vertical label={'Name'} className="vendor-title">
							<TS_Input type="text" value={item.title} onChange={value => editable.update('title', value)}/>
						</TS_PropRenderer.Vertical>

						<TS_PropRenderer.Vertical label={'Currency'} className="currency">
							<OtherDropDown.Currency selected={item.currency} onSelected={currency => editable.update('currency', currency)}/>
						</TS_PropRenderer.Vertical>

						<TS_PropRenderer.Vertical label={'Invoice Parser'} className="invoice-parser">
							<OtherDropDown.VariantParser selected={item.variantsParserType}
																					 onSelected={variantsParserType => editable.update('variantsParserType', variantsParserType)}/>
						</TS_PropRenderer.Vertical>

					</LL_V_L>
					<div style={{width: 250, height: 250}}>{item.logoId && <AssetView assetId={item.logoId}/>}</div>
				</LL_H_T>
				{this.renderBadBarcodeMapping(item.barcodeErrorMap || (item.barcodeErrorMap = []))}
			</LL_V_L>
		</LL_H_T>;
	}

	private renderBadBarcodeMapping(barcodes: BadBarcodeDef[]) {
		type Actions = 'delete'

		function renderInput(id: string, value: string | number, onChange: (value: string, id: string) => void) {
			return <TS_Input
				onChange={onChange}
				type="text"
				id={id}
				style={{border: '1px solid black'}}
				value={value.toString()}/>;
		}

		const cellRenderer: CellRenderer<BadBarcodeDef, Actions> = (prop, item, rowIndex: number) => {
			if (prop === 'delete')
				return <LL_VH_C><ICONSV4.x onClick={() => {
					removeFromArrayByIndex(barcodes, rowIndex);
					this.forceUpdate();
				}}/></LL_VH_C>;

			return renderInput(`${prop}-${rowIndex}`, item[prop], (value: string) => {
				item[prop] = value;
				this.forceUpdate();
			});
		};

		const addNewRow = () => {
			barcodes[barcodes.length] = {wrong: '', right: '', text: ''};
			this.forceUpdate();
		};

		return <LL_V_L>
			<TS_Table<BadBarcodeDef, Actions>
				id="barcodeErrorMap"
				header={['wrong', 'right', 'text', 'delete']}
				rows={barcodes}
				headerRenderer={columnKey => <div>{columnKey}</div>}
				cellRenderer={cellRenderer}
				tr={{style: {padding: '5px'}}}
			/>
			{ICONSV4.add({onClick: addNewRow})}
		</LL_V_L>;
	}
}
