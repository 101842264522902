import * as React from 'react';
import {LL_H_C, LL_V_L, ModuleFE_Toaster, TS_Checkbox, TS_Input} from '@nu-art/thunderstorm/frontend';
import {CurrencyValue, DB_OrderShippingMethod} from '@app/shared';
import {ICONSV4} from '@res/icons';
import {Component_CurrencyValue} from '@component/CurrencyValue';
import {removeFromArrayByIndex} from '@nu-art/ts-common';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';


export class EditorItem_ShippingOption
	extends Item_Editor<DB_OrderShippingMethod> {

	render() {
		const editing = this.props.editable;
		const price = editing.item.price || (editing.item.price = {} as CurrencyValue);
		const sourcesEditor = editing.editProp('sources', []);

		return <LL_V_L className="item-editor">
			<LL_H_C>
				<TS_Input
					style={{width: 300}}
					value={editing.item.label}
					onBlur={async value => editing.update('label', value)}
					type="text"/>
				<Component_CurrencyValue value={price} onChange={async (value: CurrencyValue) => editing.update('price', value)}/>
			</LL_H_C>
			<LL_H_C>
				<TS_Checkbox key={editing.item._id} checked={editing.item.isPickup} onCheck={async checked => {
					await editing.update('isPickup', checked);
					this.forceUpdate();
				}}/>Pick up
			</LL_H_C>
			<LL_V_L className="sources">
				{sourcesEditor.item.map((source, index) => <LL_H_C key={index} className="match_width">
					<ICONSV4.remove
						style={{marginRight: 10}}
						onClick={async () => {
							removeFromArrayByIndex(sourcesEditor.item, index);
							await editing.save();
						}}/>
					<TS_Input type="text" value={source} onBlur={(value) => {
						if (sourcesEditor.item[index] === value)
							return;

						if (sourcesEditor.item.includes(value)) {
							ModuleFE_Toaster.toastInfo(`Option already exists: "${value}"`);
							this.forceUpdate();
							return;
						}

						return sourcesEditor.update(index, value);
					}}/>
				</LL_H_C>)}
				<TS_Input
					className="new-source"
					value={''}
					style={{width: 300}}
					onBlur={async value => {
						if (sourcesEditor.item.includes(value))
							return ModuleFE_Toaster.toastInfo(`Option already exists: "${value}"`);

						return sourcesEditor.update(sourcesEditor.item.length, value);
					}}
					type="text"/>
			</LL_V_L>
		</LL_V_L>;
	}
}
