"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tsValidator_ArrayOfObjectsByKey = exports.tsValidator_valueByKey = exports.tsValidateNonMandatoryObject = exports.tsValidateAudit = exports.tsValidateTimestamp = exports.tsValidateRegexp = exports.tsValidateRange = exports.tsValidateIsInRange = exports.tsValidateValue = exports.tsValidateBoolean = exports.tsValidateEnum = exports.tsValidateNumber = exports.tsValidateStringMinLength = exports.tsValidateString = exports.tsValidateArray = exports.tsValidateUnionV3 = exports.tsValidateCustom = exports.tsValidateUnion = exports.tsValidateDynamicObject = void 0;
const tools_1 = require("../utils/tools");
const validator_core_1 = require("./validator-core");
const date_time_tools_1 = require("../utils/date-time-tools");
const array_tools_1 = require("../utils/array-tools");
const object_tools_1 = require("../utils/object-tools");
const exceptions_1 = require("../core/exceptions/exceptions");
const tsValidateDynamicObject = (valuesValidator, keysValidator, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            if (!input)
                return;
            const keys = (0, object_tools_1._keys)(input);
            const _result = keys.reduce((res, key) => {
                const _valRes = (0, validator_core_1.tsValidateResult)(input[key], valuesValidator);
                const _keyRes = (0, validator_core_1.tsValidateResult)(key, keysValidator);
                if (_valRes && _keyRes)
                    res[key] = `Key: ${_keyRes}}\nValue: ${_valRes}`;
                else if (_valRes)
                    res[key] = 'Value: ' + _valRes;
                else if (_keyRes)
                    res[key] = 'Key: ' + _keyRes;
                return res;
            }, {});
            return (0, object_tools_1._keys)(_result).length ? _result : undefined;
        }];
};
exports.tsValidateDynamicObject = tsValidateDynamicObject;
const tsValidateUnion = (validators, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            const results = [];
            for (const validator of validators) {
                const _res = (0, validator_core_1.tsValidateResult)(input, validator);
                if (!_res)
                    return;
                results.push(_res);
            }
            return (0, array_tools_1.filterInstances)(results).length !== 0 ? ['Input does not match any of the possible types',
                results] : undefined;
        }];
};
exports.tsValidateUnion = tsValidateUnion;
const tsValidateCustom = (processor, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory), processor];
};
exports.tsValidateCustom = tsValidateCustom;
const typeFunc = (type) => typeof type;
const tsValidateUnionV3 = (validatorObject, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            const _type = typeof input;
            return (0, object_tools_1._keys)(validatorObject)
                .includes(_type) ? (0, validator_core_1.tsValidateResult)(input, validatorObject[_type]) : 'Input does not match any of the possible types';
        }];
};
exports.tsValidateUnionV3 = tsValidateUnionV3;
const tsValidateArray = (validator, mandatory = true, minimumLength = 0) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            const results = [];
            const _input = input;
            if (_input.length < minimumLength)
                return 'Array length smaller than minimum defined length';
            for (let i = 0; i < _input.length; i++) {
                results[i] = (0, validator_core_1.tsValidateResult)(_input[i], validator);
            }
            return (0, array_tools_1.filterInstances)(results).length !== 0 ? results : undefined;
        }];
};
exports.tsValidateArray = tsValidateArray;
const tsValidateString = (length = -1, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            // noinspection SuspiciousTypeOfGuard
            if (typeof input !== 'string')
                return `input is not a string`;
            if (length === -1)
                return;
            if (input.length <= length)
                return;
            return `input is longer than ${length}`;
        }];
};
exports.tsValidateString = tsValidateString;
const tsValidateStringMinLength = (length, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            // noinspection SuspiciousTypeOfGuard
            if (typeof input !== 'string')
                return `input is not a string`;
            if (input.length >= length)
                return;
            return `input has less than ${length} chars`;
        }];
};
exports.tsValidateStringMinLength = tsValidateStringMinLength;
const tsValidateNumber = (mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            // noinspection SuspiciousTypeOfGuard
            if (typeof input === 'number')
                return;
            return `Input is not a number! \nvalue: ${input}\ntype: ${typeof input}`;
        }];
};
exports.tsValidateNumber = tsValidateNumber;
const tsValidateEnum = (enumType, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            // @ts-ignore
            if ((0, tools_1.exists)(enumType[input]))
                return;
            return `Input is not a valid enum value in ${(0, tools_1.__stringify)(enumType)}`;
        }];
};
exports.tsValidateEnum = tsValidateEnum;
const tsValidateBoolean = (mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            // noinspection SuspiciousTypeOfGuard
            if (typeof input === 'boolean')
                return;
            return `Input is not a boolean! \nvalue: ${input}\ntype: ${typeof input}`;
        }];
};
exports.tsValidateBoolean = tsValidateBoolean;
const tsValidateValue = (values, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            if (values.includes(input))
                return;
            return `Input is not valid:\n  input: ${input}\n  options: ${(0, tools_1.__stringify)(values)}\n`;
        }];
};
exports.tsValidateValue = tsValidateValue;
const tsValidateIsInRange = (ranges, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            if (ranges.some((range) => range[0] <= input && input <= range[1]))
                return;
            return `Input is not valid: ${input}.  It should fit one of the expected range: ${(0, tools_1.__stringify)(ranges)}`;
        }];
};
exports.tsValidateIsInRange = tsValidateIsInRange;
const tsValidateRange = (mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            if (!input)
                return 'Missing range';
            if (typeof input[0] !== 'number' || typeof input[1] !== 'number')
                return `Got range value that's not a number`;
            if (input[0] > input[1]) {
                return 'Range min is larger than range max';
            }
            return;
        }];
};
exports.tsValidateRange = tsValidateRange;
const tsValidateRegexp = (regexp, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            // console.log({input, regexp});
            if (regexp.test(input))
                return;
            return `Input does not match regexp:\n  input: ${input}\n  regexp: ${regexp}\n`;
        }];
};
exports.tsValidateRegexp = tsValidateRegexp;
const tsValidateTimestamp = (interval, mandatory = true) => {
    return [(0, validator_core_1.tsValidateExists)(mandatory),
        (input) => {
            const now = (0, date_time_tools_1.currentTimeMillis)();
            const minTimestamp = now - (interval || now);
            if (minTimestamp <= input && input <= now)
                return;
            return `Input is not valid:\n  input: ${input}\n  Expected Interval: ${minTimestamp} - ${now}\n`;
        }];
};
exports.tsValidateTimestamp = tsValidateTimestamp;
const tsValidateAudit = (range) => {
    return (audit) => {
        var _a;
        return (0, validator_core_1.tsValidateResult)((_a = audit === null || audit === void 0 ? void 0 : audit.auditAt) === null || _a === void 0 ? void 0 : _a.timestamp, (0, exports.tsValidateIsInRange)([[0,
                Number.MAX_VALUE]]));
    };
};
exports.tsValidateAudit = tsValidateAudit;
const tsValidateNonMandatoryObject = (validator) => {
    return [(0, validator_core_1.tsValidateExists)(false),
        (input) => (0, validator_core_1.tsValidateResult)(input, validator)];
};
exports.tsValidateNonMandatoryObject = tsValidateNonMandatoryObject;
const tsValidator_valueByKey = (validatorObject) => {
    return (0, exports.tsValidateCustom)((value, parentObject) => {
        return (0, validator_core_1.tsValidateResult)(value, validatorObject[parentObject.type]);
    });
};
exports.tsValidator_valueByKey = tsValidator_valueByKey;
const tsValidator_ArrayOfObjectsByKey = (key, validatorMap) => {
    return (0, exports.tsValidateArray)((0, exports.tsValidateCustom)((value) => {
        const _value = value;
        const validator = validatorMap[_value[key]];
        if (!validator)
            throw new exceptions_1.BadImplementationException(`No validator defined for key ${key} with value ${_value[key]}`);
        return (0, validator_core_1.tsValidateResult)(_value, validator);
    }));
};
exports.tsValidator_ArrayOfObjectsByKey = tsValidator_ArrayOfObjectsByKey;
