"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwFirebaseSessionFE = void 0;
// tslint:disable:no-import-side-effect
require("firebase/auth");
const ts_common_1 = require("@nu-art/ts-common");
// noinspection TypeScriptPreferShortImport
const SwMessagingWrapperFE_1 = require("../messaging/SwMessagingWrapperFE");
const messaging_1 = require("firebase/messaging");
class SwFirebaseSessionFE extends ts_common_1.Logger {
    constructor(sessionName, app) {
        super(`service worker firebase: ${sessionName}`);
        this.sessionName = sessionName;
        this.app = app;
    }
    getMessaging() {
        if (this.messaging)
            return this.messaging;
        return this.messaging = new SwMessagingWrapperFE_1.SwMessagingWrapperFE((0, messaging_1.getMessaging)(this.app));
    }
}
exports.SwFirebaseSessionFE = SwFirebaseSessionFE;
