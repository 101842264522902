import * as React from 'react';
import {ModuleFE_MessageTemplates, ModuleFE_MessageTemplates_} from '@modules/index';
import {DB_MessageTemplate} from '@app/shared';
import {TS_Route} from '@nu-art/thunderstorm/frontend';
import {Filter, filterInstances} from '@nu-art/ts-common';
import {EditorItem_MessagesTemplate} from './EditorItem_MessagesTemplate';
import {Page_ItemsEditor, Props_ItemsEditor} from '@nu-art/thunderstorm/frontend';


export class Page_MessagesTemplateV2
	extends Page_ItemsEditor<DB_MessageTemplate> {

	static Route: TS_Route<{ _id?: string }> = {path: 'messages-template', key: 'messages-template-editor', Component: this};

	static defaultProps: Partial<Props_ItemsEditor<DB_MessageTemplate>> = {
		pageTitle: 'Message Template',
		modules: [ModuleFE_MessageTemplates_],
		module: ModuleFE_MessageTemplates,
		filter: new Filter<DB_MessageTemplate>((messageTemplate) => filterInstances([messageTemplate.label, messageTemplate.template])),
		sort: (messageTemplate: DB_MessageTemplate) => messageTemplate.__created,
		itemRenderer: (messageTemplate: DB_MessageTemplate) => <>{messageTemplate.label}</>,
		EditorRenderer: EditorItem_MessagesTemplate,
		route: this.Route
	};
}
