"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_PermissionDomain = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const validators_1 = require("../../validators");
const Validator_PermissionDomain = {
    projectId: validators_1.validateProjectId,
    namespace: (0, ts_common_1.tsValidateString)(50),
    _auditorId: (0, ts_common_1.tsValidateString)()
};
exports.DBDef_PermissionDomain = {
    validator: Validator_PermissionDomain,
    dbName: 'permissions--domain',
    entityName: 'PermissionsDomain',
    lockKeys: ['projectId'],
    generatedProps: ['_auditorId'],
};
