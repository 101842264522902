import {DB_Customer} from '@app/shared';
import {
	Model_Menu,
	ModuleFE_MouseInteractivity,
	ModuleFE_Thunderstorm,
	ModuleFE_Toaster,
	SimpleListAdapter,
	stopPropagation
} from '@nu-art/thunderstorm/frontend';
import {filterInstances, Replacer, StaticLogger} from '@nu-art/ts-common';
import * as React from 'react';
import {ModuleFE_MessageTemplates} from '../../modules/ModuleFE_MessageTemplates';
import {IconKey, ICONSV4} from '@res/icons';
import {Renderer_MenuItem_MessageTemplate} from './Renderer_MenuItem_MessageTemplate';


type Props = {
	icon: IconKey
	replacer: Replacer,
	customer: DB_Customer
}

export const MessagesSendingMenu = (props: Props) => {
	const Icon = ICONSV4[props.icon];
	return <Icon className="ts-print__hide" onClick={(e) => {
		stopPropagation(e);
		const menuItems = filterInstances(ModuleFE_MessageTemplates.cache.map(message => {
			try {
				props.replacer.replace(message.template);
			} catch (e) {
				return;
			}
			return ({
				message: message,
				onClick: () => {
					try {
						props.replacer.replace(message.template);
						const urlObj = {
							url: 'https://web.whatsapp.com/send',
							params: {
								phone: props.customer.phone,
								text: () => props.replacer.replace(message.template)
							}
						};
						ModuleFE_Thunderstorm.openUrl(urlObj, '_blank');
					} catch (e: any) {
						StaticLogger.logError(e);
						return ModuleFE_Toaster.toastError(`Error composing message: '${message.label}'`);
					}
				}
			});
		}));

		const menu: Model_Menu = {
			id: 'panel-categories__right-click-menu',
			adapter: SimpleListAdapter(menuItems, node => <Renderer_MenuItem_MessageTemplate message={node.item.message} replacer={props.replacer}/>),
			offset: {},
			originPos: {x: 1, y: 0},
			modalPos: {y: 0, x: -1},
			onNodeClicked: (path: string, item: any) => {
				ModuleFE_MouseInteractivity.hide('pop-up');
				item.onClick?.();
			}
		};

		ModuleFE_MouseInteractivity.showMenu(menu);
	}}/>;
};
