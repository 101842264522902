import {DB_InventoryItem, DBDef_InventoryItem} from '@app/shared';
import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';


export interface OnInventoryItemsUpdated {
	__onInventoryItemsUpdated: (...params: ApiCallerEventType<DB_InventoryItem>) => void;
}

export const dispatch_onInventoryItemsUpdated = new ThunderDispatcher<OnInventoryItemsUpdated, '__onInventoryItemsUpdated'>('__onInventoryItemsUpdated');

export class ModuleFE_InventoryItems_Class
	extends ModuleFE_BaseApi<DB_InventoryItem, 'storeId' | 'inventoryId' | 'variantId'> {

	constructor() {
		super(DBDef_InventoryItem, dispatch_onInventoryItemsUpdated);
	}
}

export const ModuleFE_InventoryItems = new ModuleFE_InventoryItems_Class();
