import * as React from 'react';
import {ModuleFE_Products_} from '@modules/index';
import {TS_Route} from '@nu-art/thunderstorm/frontend';
import {DB_Product} from '@app/shared';
import {Filter} from '@nu-art/ts-common';
import {EditorItem_Product} from './EditorItem_Product';
import {Page_ItemsEditor, Props_ItemsEditor} from '@nu-art/thunderstorm/frontend';


export class Page_ProductsV2
	extends Page_ItemsEditor<DB_Product> {

	static Route: TS_Route<{ _id?: string }> = {path: 'products', key: 'products-editor', Component: this};

	static defaultProps: Partial<Props_ItemsEditor<DB_Product>> = {
		pageTitle: 'Products',
		modules: [ModuleFE_Products_],
		module: ModuleFE_Products_,
		filter: new Filter<DB_Product>((vendor) => [vendor.title, vendor.description || '']),
		sort: (item: DB_Product) => item.title,
		itemRenderer: (item: DB_Product) => <>{item.title}</>,
		EditorRenderer: EditorItem_Product,
		route: this.Route
	};
}

