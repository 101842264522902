import * as React from 'react';
import {
	_className,
	LL_H_C,
	LL_V_C,
	LL_V_L,
	ModuleFE_RoutingV2,
	stopPropagation,
	TableHeaders,
	TS_Checkbox,
	TS_Link,
	TS_Loader,
	TS_Radio,
	TS_Route,
	TS_Space,
	TS_Table
} from '@nu-art/thunderstorm/frontend';
import {
	ModuleFE_CustomerContact,
	ModuleFE_CustomerOrder,
	ModuleFE_ShippingMethod,
	ModuleFE_Stores,
	OrderStatus_Groups,
	resolveDirectionStyleRTL
} from '@modules/index';
import {dispatcher_onShowDebugData} from '@consts/interfaces';
import {dbObjectToId, flatArray, formatTimestamp, normalizeTimestamp, removeItemFromArray, sortArray} from '@nu-art/ts-common';
import {ICONSV4} from '@res/icons';
import {DB_CustomerOrder, PackageType_Size} from '@app/shared';
import * as Routes from '@consts/routes-consts';
import {Page_DBItemListV2, Props_DBItemListV2, State_DBItemListV2} from '@component/Page_DBItemListV2';
import {Props_SmartComponent, State_SmartComponent} from '@nu-art/thunderstorm/frontend';
import {Page_CustomerOrder} from './Page_CustomerOrder';
import './Page_CustomerOrder.scss';
import {getOrderStatusClass} from '@pah/customer-order/utils';
import {Layer, Line, Stage} from 'react-konva';
import {DropDown} from '@pah/app-dropdown';
import {Component_DeliveryStatus} from '@pah/customer-order/components/Component_OrderDetails';


type Actions = 'sync' | 'debug' | 'select';

// const tableHeaders: TableHeaders<DB_CustomerOrder, Actions> = ['orderNumber', 'timestampCreated', 'customerId', 'shippingAddress', 'shippingOption', 'itemIds', 'sync', 'debug'];
const tableHeaders1: TableHeaders<DB_CustomerOrder, Actions> = [
	{header: 'select', widthPx: 30},
	{header: 'orderNumber', widthPx: 40},
	{header: 'packagingStatus', widthPx: 40},
	{header: 'itemIds', widthPx: 50, className: 'hide-2'},
	{header: 'timestampCreated', widthPx: 100, className: 'smaller-fonts'},
	{header: 'customerId', widthPx: 100, className: 'text_wrap'},
	{header: 'shippingAddress', widthPx: 200},
	{header: 'gateway', widthPx: 80, className: 'hide-1'},
	{header: 'shippingOption', widthPx: 100, className: 'hide-2'},
	{header: 'deliveryDetails', widthPx: 100},
	{header: 'sync', widthPx: 30},
	{header: 'debug', widthPx: 30},
];
const Props_icons = {
	style: {
		marginLeft: 8,
		marginRight: 8,
	}
};
type State = {
	selectedItems: string[]
	selectedProductId?: string
}

type FullProps = Props_SmartComponent & Props_DBItemListV2<DB_CustomerOrder>;
type FullState = State_SmartComponent & State_DBItemListV2<DB_CustomerOrder> & State;

export class Page_CustomerOrderList
	extends Page_DBItemListV2<DB_CustomerOrder, State> {

	static Route: TS_Route = {path: 'list', key: 'customer-order-list', Component: this};

	static defaultProps = {
		pageTitle: 'Customer Orders List',
		childRoute: Page_CustomerOrder.Route,
		newRoute: Routes.RouteKey_CustomerOrderList,
		moduleFE: ModuleFE_CustomerOrder,
		modules: [
			ModuleFE_Stores,
			ModuleFE_CustomerContact,
			ModuleFE_CustomerOrder,
		]
	};

	constructor(props: any) {
		super(props);
	}

	protected async deriveStateFromProps(nextProps: FullProps, state: FullState) {
		state.items = ModuleFE_CustomerOrder.getFilteredOrders();
		state.selectedItems = state.selectedItems || [];
		return state;
	}

	// protected async deriveStateFromProps(nextProps: Props_DBItemListV2<DB_CustomerOrder>): Promise<State> {
	// 	const state = await super.deriveStateFromProps(nextProps);
	// 	return state;
	// }

	protected renderItems(items: DB_CustomerOrder[]) {
		if (!items)
			return <TS_Loader/>;

		const rows = sortArray(items, order => order.timestampCreated, true);
		return (
			<LL_V_L className="customer-order match_parent">
				{this.renderFilterOptions()}
				{this.renderTimeline(items)}
				<div className="customer-order__list match_parent">
					<TS_Table<DB_CustomerOrder, Actions>
						header={tableHeaders1}
						rows={rows}
						tr={(row, rowIndex) => {
							return ({
								className: _className('clickable', getOrderStatusClass(row), row?.status?.includes('pending-payment') || row?.status?.includes('in-progress') ? 'customer-order__not-completed' : ''),
								onClick: () => rowIndex !== -1 && ModuleFE_RoutingV2.goToRoute(Page_CustomerOrder.Route, {_id: rows[rowIndex]._id})
							});
						}}
						headerRenderer={header => {
							switch (header) {
								case 'orderNumber':
									return 'Order #';

								case 'select':
									return <LL_V_C><TS_Checkbox checked={this.state.selectedItems.length === rows.length} onCheck={(checked) => {
										this.reDeriveState({selectedItems: checked ? [] : rows.map(dbObjectToId)});
									}}/></LL_V_C>;
								case 'timestampCreated':
									return 'Order At';

								case 'customerId':
									return 'Customer';

								case 'packagingStatus':
									return 'Packed';

								case 'shippingAddress':
									return 'Shipping Address';

								case 'shippingOption':
									return 'Delivery method';

								case 'itemIds':
									return 'Items #';

								case 'deliveryDetails':
									return 'Delivery';

								case 'debug':
									return <LL_V_C><ICONSV4.debug/></LL_V_C>;

								case 'sync':
									return <LL_V_C>
										{ICONSV4.sync({
											onClick: (e) => {
												stopPropagation(e);
												ModuleFE_CustomerOrder._v1.shopify.sync({}).execute();
											}
										})}
									</LL_V_C>;
							}

							return header;
						}}
						cellRenderer={(prop, item, index: number) => {
							switch (prop) {
								case 'debug':
									return <LL_V_C><ICONSV4.debug {...Props_icons} onClick={(e) => {
										stopPropagation(e);
										dispatcher_onShowDebugData.dispatchUI(item._id, `Order: ${item.orderNumber}`, item);
									}}/></LL_V_C>;
								case 'sync':
									return <LL_V_C><ICONSV4.sync {...Props_icons} onClick={(e) => {
										stopPropagation(e);
										ModuleFE_CustomerOrder._v1.shopify.sync({itemIds: [item._id], fromShopify: true}).execute();
									}}/></LL_V_C>;
								case 'select':
									return <LL_V_C><TS_Checkbox checked={this.state.selectedItems.includes(item._id)} onCheck={(checked, e) => {
										stopPropagation(e);
										if (checked)
											this.state.selectedItems.push(item._id);
										else
											removeItemFromArray(this.state.selectedItems, item._id);

										this.forceUpdate();
									}}/></LL_V_C>;
								case 'packagingStatus':
									return <TS_Radio
										className={'label-radio'}
										values={['No', 'Yes']}
										groupName={'is-packed'}
										checked={item[prop]?.isReady ? 'Yes' : item[prop] ? 'No' : '---'}
										onCheck={async val => {
											if (item[prop])
												item[prop].isReady = val === 'Yes';
											else
												item[prop] = {isReady: val === 'Yes', packagesSize: [PackageType_Size.Small]};
											await ModuleFE_CustomerOrder.v1.upsert(item).executeSync();
											this.forceUpdate();
										}}
									/>;

								case 'gateway': {
									let gateway = item[prop];
									if (!gateway)
										return '';

									const indexOf = gateway.indexOf('(');
									gateway = indexOf === -1 ? gateway : gateway.substring(0, indexOf - 1);
									if (gateway.includes('PayPlus'))
										gateway = 'Credit Card';
									if (gateway.includes('Bit'))
										gateway = 'Bit / PayBox';
									return <div>{gateway}</div>;
								}

								case 'deliveryDetails': {
									return <Component_DeliveryStatus order={item}/>;
								}
								case 'shippingOption': {
									const shippingOption = ModuleFE_ShippingMethod.cache.find(o => o._id === item.shippingOptionId)?.label || item.shippingOption?.title;
									if (!shippingOption)
										return '';

									const indexOf = shippingOption.indexOf('(');
									return <div>{indexOf === -1 ? shippingOption : shippingOption.substring(0, indexOf - 1)}</div>;
								}
							}

							if (prop === 'shippingAddress') {
								const address = item[prop];
								if (!address)
									return <div>No Shipping Address</div>;
								return <div className="ll_h_c match_parent" style={resolveDirectionStyleRTL(address.fullFormattedAddress)}>
									<TS_Link
										url={'https://maps.google.com/'}
										params={{q: address.fullFormattedAddress}}
										target={'_blank'}
									>{ICONSV4.location({style: {marginInlineEnd: 8}})}</TS_Link>
									<div style={{whiteSpace: 'normal'}}>{address.fullFormattedAddress}</div>
								</div>;
							}

							if (prop === 'orderNumber')
								return <LL_V_C>#{item[prop]}</LL_V_C>;

							if (prop === 'customerId') {
								const customer = ModuleFE_CustomerContact.cache.unique(item[prop]);
								if (!customer)
									return 'missing customer';

								const customerName = `${customer.firstName} ${customer.lastName}`;
								return <div style={resolveDirectionStyleRTL(customerName)}>{customerName}</div>;
							}

							if (prop === 'timestampCreated')
								return <LL_V_C>{formatTimestamp('ddd, MMM DD', item[prop])} at {formatTimestamp('HH:mm', item[prop])}</LL_V_C>;

							if (prop === 'itemIds') {
								const returnedItems = flatArray(item.refunds?.map(r => r.itemIds)).reduce((count, item) => count + item.quantity, 0);
								const refunded = returnedItems ? ` r(-${returnedItems})` : '';

								const customItems = item.missingItems?.reduce((count, item) => count + item.quantity, 0);
								const custom = customItems ? ` c(${customItems})` : '';

								const itemsCount = item[prop].reduce((count, item) => count + item.quantity, 0);
								return <div
									className="ll_v_c">{itemsCount}{custom}{refunded}</div>;

							}
							return;
						}}
					/></div>
			</LL_V_L>);
		// <div className={"match_width"}>
		// 	<div>{orders.map(order => <div onClick={(e) => {
		// 		stopPropagation(e);
		// 		const {blob, ...ours} = order
		// 		dispatcher_onShowDebugData.dispatchUI([order._id, `Order: ${order.orderNumber}`, {
		// 			ours,
		// 			shopify: JSON.parse(blob)
		// 		}]);
		// 	}}>#{order.orderNumber}</div>)}</div>
		// </div>;
	}

	renderFilterOptions() {
		return <LL_H_C className="customer-order__options">
			{OrderStatus_Groups.map(status => this.renderFilter(status.key, status.label))}
			<DropDown.Product
				// selectedProductId={ModuleFE_CustomerOrder.filter.productId.get(undefined)}
				onSelected={product => {
					ModuleFE_CustomerOrder.filter.productId.set(product._id);
					this.reDeriveState();
				}}/>
			{ICONSV4.x({
				onClick: () => {
					ModuleFE_CustomerOrder.filter.productId.delete();
					this.reDeriveState();
				}
			})}
		</LL_H_C>;
	}

	renderFilter(filterKey: string, label: string) {
		return <LL_H_C onClick={() => {
			ModuleFE_CustomerOrder.filter[filterKey].set(!ModuleFE_CustomerOrder.filter[filterKey].get(true));
			this.reDeriveState();
		}}>
			<TS_Checkbox
				checked={ModuleFE_CustomerOrder.filter[filterKey].get(true)}/>{label}
			<TS_Space width={32}/>
		</LL_H_C>;
	}

	renderTimeline(items: DB_CustomerOrder[]) {
		const timelineItems = sortArray(items.map((order) => {
			const hourOfDay = normalizeTimestamp(order.timestampCreated, 'HH');
			const dateInMonth = normalizeTimestamp(order.timestampCreated, 'dd');
			return {hourOfDay, dateInMonth, order};
		}), item => item.hourOfDay);

		// const counter = timelineItems.reduce((data, item) => {
		// 	data[item.hourOfDay]++;
		// 	return data;
		// }, new Array(24).fill(0));

		// const max = counter.reduce((max, count) => Math.max(max, count), 0);

		const width = 800;
		const horizontalPadding = 20;
		const viewportWidth = width - horizontalPadding * 2;

		const height = 150;
		const verticalPadding = 10;
		const viewportHeight = height - verticalPadding * 2;

		const yAxisPos = 20;
		// const y = [0, max];
		// const yTick = 1;

		const xAxisPos = 20;
		// const x = [0, 24];
		// const xTick = 1;

		return (
			<Stage width={800} height={150}>
				<Layer>
					<Line x={horizontalPadding} y={xAxisPos} width={viewportWidth} height={1} stroke={'#000000'}/>
					<Line x={yAxisPos} y={verticalPadding} width={1} height={viewportHeight} stroke={'#000000'}/>
					{timelineItems.map(item => {
						return;
					})}
				</Layer>
			</Stage>
		);
	}
}

