"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopifyItem_validator = exports.StoreItem_validator = void 0;
const ts_common_1 = require("@nu-art/ts-common");
exports.StoreItem_validator = {
    _oldId: ts_common_1.tsValidateOptionalId,
    storeId: ts_common_1.tsValidateOptionalId,
    _needToSync: (0, ts_common_1.tsValidateBoolean)(false),
};
exports.ShopifyItem_validator = Object.assign(Object.assign({}, exports.StoreItem_validator), { blob: ts_common_1.tsValidateMustExist, storeId: ts_common_1.tsValidateUniqueId, shopifyId: (0, ts_common_1.tsValidateNumber)(), syncTimestamp: (0, ts_common_1.tsValidateNumber)() });
