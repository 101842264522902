"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_SAML_BE = exports.ApiDefFE_Account = exports.ApiDefBE_Account = exports.HeaderKey_CurrentPage = exports.QueryParam_RedirectUrl = exports.QueryParam_SessionId = exports.QueryParam_Email = exports.HeaderKey_Email = exports.HeaderKey_Application = exports.HeaderKey_SessionId = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const ts_common_1 = require("@nu-art/ts-common");
exports.HeaderKey_SessionId = 'x-session-id';
exports.HeaderKey_Application = 'x-application';
exports.HeaderKey_Email = 'x-email';
exports.QueryParam_Email = 'userEmail';
exports.QueryParam_SessionId = exports.HeaderKey_SessionId;
exports.QueryParam_RedirectUrl = 'redirectUrl';
exports.HeaderKey_CurrentPage = 'current-page';
const API_LoginSaml = { loginSaml: { method: thunderstorm_1.HttpMethod.GET, path: 'v1/account/login-saml' } };
const API_Login = { login: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/account/login', timeout: ts_common_1.Minute } };
const API_Logout = { logout: { method: thunderstorm_1.HttpMethod.GET, path: 'v1/account/logout' } };
const API_RegisterAccount = {
    registerAccount: {
        method: thunderstorm_1.HttpMethod.POST,
        path: '/v1/account/register-account'
    }
};
const API_CreateAccount = { createAccount: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/account/create-account' } };
const API_ChangePassword = {
    changePassword: {
        method: thunderstorm_1.HttpMethod.POST,
        path: '/v1/account/change-password'
    }
};
const API_CreateToken = {
    createToken: {
        method: thunderstorm_1.HttpMethod.POST,
        path: 'v1/account/create-token',
        timeout: ts_common_1.Minute
    }
};
const API_SetPassword = { setPassword: { method: thunderstorm_1.HttpMethod.POST, path: '/v1/account/set-password' } };
const API_ValidateSession = {
    validateSession: {
        method: thunderstorm_1.HttpMethod.GET,
        path: 'v1/account/validate',
        timeout: ts_common_1.Minute
    }
};
exports.ApiDefBE_Account = {
    vv1: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, API_RegisterAccount), API_CreateAccount), API_ChangePassword), API_Login), API_Logout), API_ValidateSession), API_CreateToken), API_SetPassword)
};
exports.ApiDefFE_Account = {
    vv1: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, API_RegisterAccount), API_CreateAccount), API_ChangePassword), API_Login), API_LoginSaml), API_Logout), API_ValidateSession), API_CreateToken), API_SetPassword)
};
exports.ApiDef_SAML_BE = {
    vv1: Object.assign(Object.assign({}, API_LoginSaml), { assertSAML: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/account/assert' } })
};
