"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Replacer = void 0;
const Logger_1 = require("../core/logger/Logger");
const validator_core_1 = require("../validator/validator-core");
class Replacer extends Logger_1.Logger {
    constructor() {
        super();
        this.strictMode = true;
        this.input = {};
        this.aliases = [];
        // this.setMinLevel(LogLevel.Error);
    }
    setFallbackReplacer(fallbackReplacer) {
        this.fallbackReplacer = fallbackReplacer;
        return this;
    }
    setNotStrict() {
        this.strictMode = false;
        return this;
    }
    getInput() {
        return this.input;
    }
    setInput(input, aliases = []) {
        this.input = input;
        this.aliases = aliases;
        return this;
    }
    replace(_content = '', runtime) {
        let content = this.replaceLoops(_content, runtime);
        content = this.replaceParams(content, runtime);
        if (_content !== content)
            content = this.replace(content, runtime);
        return content;
    }
    replaceParams(content = '', runtime) {
        const matches = content.match(Replacer.Regexp_paramGroup);
        return (matches === null || matches === void 0 ? void 0 : matches.reduce((toRet, match) => {
            var _a;
            let param = match;
            while (Replacer.Regexp_param.test(param))
                param = (_a = param.match(Replacer.Regexp_param)) === null || _a === void 0 ? void 0 : _a[1];
            if (param === undefined)
                return toRet;
            const value = this.resolveParam(param, toRet, runtime);
            return toRet.replace(match, value);
        }, content)) || content;
    }
    resolveParam(param, toRet, runtime) {
        const value = this.resolveParamValue(param, runtime);
        if (this.fallbackReplacer && (value === undefined || value === ''))
            return this.fallbackReplacer.resolveParam(param, toRet, runtime);
        return value;
    }
    replaceLoops(content = '', runtime) {
        const matches = content.match(Replacer.Regexp_forLoopGroupStart);
        return (matches === null || matches === void 0 ? void 0 : matches.reduce((toRet, match) => {
            const varsMatch = match.match(Replacer.Regexp_forLoopParam);
            const string = varsMatch[0];
            const iterable = varsMatch[1];
            const iterator = varsMatch[2];
            const endMatch = `{{/foreach ${iterator}}}`;
            const indexOfEnd = toRet.indexOf(endMatch);
            const fullOriginLoopText = toRet.substring(toRet.indexOf(string), indexOfEnd + endMatch.length);
            const loopText = toRet.substring(toRet.indexOf(string) + string.length, indexOfEnd);
            this.logDebug(`indexOfEnd: ${endMatch} ${indexOfEnd}`);
            this.logDebug(`string: ${string} ${toRet.indexOf(string)}`);
            this.logDebug(`iterable: ${iterable}`);
            this.logDebug(`iterator: ${iterator}`);
            this.logDebug(`loopText: ${loopText}`);
            let loopArray;
            const iterableProp = `${iterator}`;
            try {
                loopArray = this.resolveParamValue(iterableProp, runtime);
            }
            catch (e) {
                throw new validator_core_1.ValidationException(`Error while looping on variable for parts: `, iterableProp, this.input, e);
            }
            if (!Array.isArray(loopArray))
                this.logWarning(`Loop object is not an array.. found:`, loopArray);
            const replacement = loopArray.reduce((_toRet, value) => {
                return _toRet + this.replace(loopText, { property: value });
            }, '');
            const s = toRet.replace(fullOriginLoopText, replacement);
            return s;
        }, content)) || content;
    }
    resolveParamValue(_param, runtime) {
        let param = _param;
        const alias = this.aliases.find(alias => alias.key === param);
        if (alias) {
            this.logInfo('using alias: ', alias);
            param = alias.value || param;
        }
        param = param.replace(/\[/g, '.').replace(/\]/g, '');
        if (param.endsWith('.'))
            param = param.substring(0, param.length - 1);
        if (param.startsWith(Replacer.Indicator_RuntimeParam))
            param = `${Replacer.RuntimeParam}.${param.substring(Replacer.Indicator_RuntimeParam.length)}`;
        const parts = param.split('\.');
        let value;
        try {
            if (runtime)
                value = parts.reduce((value, key) => value === null || value === void 0 ? void 0 : value[key], runtime);
            if (value === undefined)
                value = parts.reduce((value, key) => value[key], this.input);
        }
        catch (e) {
            this.logWarning('input', this.input);
            throw new validator_core_1.ValidationException(`Error while resolving runtime variable for parts ${param}`, param, this.input, e);
        }
        if (value === undefined) {
            this.logWarning('input', this.input);
            if (this.strictMode)
                throw new validator_core_1.ValidationException(`Cannot resolve runtime variable for parts ${param}`, param, this.input);
        }
        return value;
        // this.logDebug(`Input:`, this.input);
        // this.logDebug(`Param: ${param}`, 'Parts: ', parts);
        // this.logDebug(value);
    }
}
Replacer.RuntimeParam = '__runtime';
Replacer.Indicator_RuntimeParam = '__';
Replacer.Regexp_paramGroup = /\$\{(\{?.*?\}?)\}/g;
Replacer.Regexp_param = /\$\{(\{?.*?\}?)\}/;
Replacer.Regexp_forLoopGroupStart = /\{\{foreach (.*?) in (.*?)\}\}/g;
Replacer.Regexp_forLoopParam = /\{\{foreach (.*?) in (.*?)\}\}/;
exports.Replacer = Replacer;
