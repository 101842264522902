import {Props_SmartComponent, SmartComponent, State_SmartComponent} from '@nu-art/thunderstorm/frontend';
import {Digraph, toDot} from 'ts-graphviz';
import {ALL_NODES, calculateGraph} from '../../../../playground/examples/PgDev_DecisionTree/nodes';

import {graphviz} from 'd3-graphviz';
import React from 'react';
import {select} from 'd3';
import {DB_CustomerOrder} from '@app/shared';


export type Props = Props_SmartComponent & {
	dbOrder: DB_CustomerOrder
}

type State = State_SmartComponent & {
	digraph: Digraph;
	selectedStateId?: string;
}

export class Component_OrderLifecycle
	extends SmartComponent<Props, State> {

	private ref?: HTMLDivElement;

	protected async deriveStateFromProps(nextProps: Props, state: State) {
		state.digraph = this.buildGraph(nextProps.dbOrder);
		this.renderGraphviz(state.digraph); // if called before mounting, will skip
		return state;
	}

	buildGraph(dbOrder: DB_CustomerOrder): Digraph {
		return calculateGraph(ALL_NODES, dbOrder);
	}

	renderGraphviz(digraph: Digraph) {
		if (!this.ref)
			return;

		this.logError(`state.selectedStateId = ${this.state?.selectedStateId}`);
		if (!this.mounted || !digraph)
			return;

		const dotString = toDot(digraph);

		graphviz(this.ref, {useWorker: false, zoomScaleExtent: [0.5, 2], fit: true})
			.renderDot(dotString)
			.on('renderEnd', () => {
				const selection = select(this.ref!).selectAll('.node');
				selection.on('click', (e: any) => {
					const nodeId: string = e.currentTarget.id; // each node is given state._id as it's element id
					this.logInfo('clicked node', nodeId);

					this.reDeriveState({selectedStateId: nodeId});
				});
			});
	}

	getDigraphNodes() {
		return this.state?.digraph?.subgraphs.find(sg => sg.id === 'Container')?.nodes;
	}

	getNodeById(stateId: string) {
		const digraphNodes = this.getDigraphNodes();
		if (!digraphNodes)
			return;

		const clickedNodeIndex = digraphNodes.findIndex(node => node.id === stateId);

		return clickedNodeIndex > -1 ? digraphNodes[clickedNodeIndex] : undefined;
	}

	render() {
		if (!this.state?.digraph)
			return <div className={'page-message'}> No graph to show </div>;

		return <div className={'graph-viewer'} ref={ref => {
			if (this.ref || !ref)
				return;

			this.ref = ref as HTMLDivElement;

			this.renderGraphviz(this.state?.digraph);
		}}/>;
	}
}