import {cleanupPhoneNumberForUI, DB_Customer} from '@app/shared';
import * as React from 'react';
import {ModuleFE_CustomerContact, ModuleFE_CustomerOrder, resolveDirectionStyleRTL} from '@modules/index';
import {LOCALE} from '@res/locale';
import {LL_H_C, LL_V_L, ModuleFE_Thunderstorm} from '@nu-art/thunderstorm/frontend';
import {Replacer} from '@nu-art/ts-common';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';
import {MessagesSendingMenu} from '../../../../../new-order/customer-care/messages-templates/components/messages-menu/MessagesSendingMenu';


export class Component_Customer
	extends Item_Editor<DB_Customer, { replacer: Replacer }> {

	static defaultProps = {
		moduleFE: ModuleFE_CustomerOrder,
		modules: [ModuleFE_CustomerContact]
	};

	render() {
		const editable = this.props.editable;
		const customer = editable.item;

		const customerName = `${customer.firstName} ${customer.lastName}`;
		const orders = LOCALE.OrdersCount(customer.ordersCount || 0);

		const phoneNumber = cleanupPhoneNumberForUI(customer.phone);
		return (
			<LL_V_L className="customer-order__section">
				<LL_H_C className="customer-order__title">
					<div>{LOCALE.Customer()}</div>
					<MessagesSendingMenu icon={'email'} replacer={this.props.replacer} customer={customer as DB_Customer}/>
					<div>{orders}</div>
				</LL_H_C>
				<LL_H_C className="match_width flex__space-between">
					<LL_V_L>
						<div style={resolveDirectionStyleRTL(customerName)} onClick={async () => ModuleFE_Thunderstorm.copyToClipboard(customerName)}>{customerName}</div>
						<div>{customer.email}</div>
						{phoneNumber && <div onClick={async () => ModuleFE_Thunderstorm.copyToClipboard(phoneNumber)}>{phoneNumber}</div>}
					</LL_V_L>
				</LL_H_C>
			</LL_V_L>
		);
	}
}