"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Tabs = void 0;
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
const tools_1 = require("../../utils/tools");
require("./TS_Tabs.scss");
const ModuleFE_LocalStorage_1 = require("../../modules/ModuleFE_LocalStorage");
/**
 * Tabs made easy
 *
 *
 * <b>SCSS:</b>
 * ```scss
 * .ts-tabs {
 *   .ts-tabs__tabs-header {
 *     .ts-tabs__tab {}
 *     .ts-tabs__focusedTabId {}
 *     .unselectable {}
 *   }
 *
 *   .ts-tabs__content {}
 * }
 * ```
 */
class TS_Tabs extends ComponentSync_1.ComponentSync {
    constructor(p) {
        super(p);
        this.selectOnClick = (e, tab) => {
            var _a;
            (0, tools_1.stopPropagation)(e);
            if (tab.disabled)
                return;
            const selectedTabId = tab.uid;
            if (!selectedTabId)
                return;
            (_a = this.getStorageKey()) === null || _a === void 0 ? void 0 : _a.set(selectedTabId);
            if (this.props.onSelected) {
                this.props.onSelected(selectedTabId);
                return;
            }
            this.setState({ selectedTabId });
        };
        this.scrollToSelectedTab = () => {
            var _a;
            if (!this.props.selectedTabId)
                return;
            const selectedTab = document.getElementById(this.props.selectedTabId);
            if (!selectedTab)
                return;
            const deltaRight = selectedTab.getBoundingClientRect().right - window.innerWidth;
            if (deltaRight <= 1)
                return;
            (_a = selectedTab.parentElement) === null || _a === void 0 ? void 0 : _a.scroll({
                left: deltaRight,
                behavior: 'smooth',
            });
        };
    }
    //######################### Life Cycle #########################
    deriveStateFromProps(nextProps) {
        var _a, _b, _c, _d;
        const selectedTabId = ((_a = nextProps.tabs.find(t => t.uid === nextProps.selectedTabId)) === null || _a === void 0 ? void 0 : _a.uid)
            || ((_b = this.getStorageKey()) === null || _b === void 0 ? void 0 : _b.get(''))
            || ((_c = this.state) === null || _c === void 0 ? void 0 : _c.selectedTabId)
            || ((_d = nextProps.tabs[0]) === null || _d === void 0 ? void 0 : _d.uid);
        return {
            tabs: nextProps.tabs,
            selectedTabId
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.scrollToSelected)
            this.scrollToSelectedTab();
    }
    //######################### Logic #########################
    getStorageKey() {
        if (!this.props.id)
            return;
        return new ModuleFE_LocalStorage_1.StorageKey(`ts-tabs__${this.props.id}`, this.props.persistSelection);
    }
    //######################### Render #########################
    render() {
        const tabs = this.state.tabs;
        if (!tabs)
            return '';
        const getTitle = (tab) => {
            if (typeof tab.title === 'function')
                return tab.title();
            return tab.title;
        };
        const getContent = (tab) => {
            if (!tab)
                return '';
            if (typeof tab.content === 'function')
                return tab.content();
            return tab.content;
        };
        const wrapperClass = (0, tools_1._className)('ts-tabs', this.props.className);
        const headerClass = (0, tools_1._className)('ts-tabs__tabs-header', this.props.tabsHeaderClass);
        const contentClass = (0, tools_1._className)('ts-tabs__content', this.props.tabsContentClass);
        return (React.createElement("div", { id: this.props.id, className: wrapperClass },
            React.createElement("div", { className: headerClass }, tabs.map(tab => {
                const tabClasses = (0, tools_1._className)('ts-tabs__tab', 'unselectable', this.state.selectedTabId === tab.uid ? 'ts-tabs__focused' : undefined, tab.disabled ? 'disabled' : undefined);
                return React.createElement("div", { key: tab.uid, id: tab.uid, className: tabClasses, onClick: (e) => this.selectOnClick(e, tab) }, getTitle(tab));
            })),
            React.createElement("div", { className: contentClass }, getContent(tabs.find(tab => tab.uid === this.state.selectedTabId)))));
    }
}
exports.TS_Tabs = TS_Tabs;
