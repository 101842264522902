"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_BrowserHistory = exports.composeURL = exports.composeQuery = exports.encodeUrlParams = exports.getUrlQuery = exports.getQueryParameter = exports.getCurrentUrl = exports.ModuleFE_BrowserHistory_Class = void 0;
/**
 * Created by tacb0ss on 27/07/2018.
 */
const ts_common_1 = require("@nu-art/ts-common");
const history_1 = require("history");
// move all the shit from here to the Routing module
class ModuleFE_BrowserHistory_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.getEncodedQueryParams = () => {
            const queryParams = {};
            let queryAsString = window.location.search;
            if (!queryAsString || queryAsString.length === 0)
                return {};
            while (queryAsString.startsWith('?') || queryAsString.startsWith('/?')) {
                if (queryAsString.startsWith('?'))
                    queryAsString = queryAsString.substring(1);
                else if (queryAsString.startsWith('/?'))
                    queryAsString = queryAsString.substring(2);
                else
                    break;
            }
            const query = queryAsString.split('&');
            return query.map(param => {
                const parts = param.split('=');
                return { key: parts[0], value: parts[1].length === 0 ? undefined : parts[1] };
            }).reduce((toRet, param) => {
                toRet[param.key] = param.value;
                return toRet;
            }, queryParams);
        };
        this.history = (0, history_1.createBrowserHistory)();
    }
    push(push) {
        this.history.push(push);
    }
    replace(push) {
        this.history.replace(push);
    }
    composeQuery(queryParams) {
        const queryAsString = (0, ts_common_1.composeQueryParams)(queryParams);
        if (queryAsString.length === 0)
            return undefined;
        return queryAsString;
    }
    getQueryParams() {
        const params = this.getEncodedQueryParams();
        (0, ts_common_1._keys)(params).forEach(key => {
            const value = `${params[key]}`;
            if (!value) {
                return;
            }
            params[key] = decodeURIComponent(value);
        });
        return params;
    }
    setQuery(queryParams) {
        const encodedQueryParams = encodeUrlParams(queryParams);
        this.updateQueryParams(encodedQueryParams);
    }
    addQueryParam(key, value) {
        const encodedQueryParams = this.getEncodedQueryParams();
        encodedQueryParams[key] = encodeURIComponent(value);
        this.updateQueryParams(encodedQueryParams);
    }
    removeQueryParam(key) {
        const encodedQueryParams = this.getEncodedQueryParams();
        delete encodedQueryParams[key];
        const data = this.createHistoryDataFromQueryParams(encodedQueryParams);
        this.replace(data);
    }
    setUrl(url, queryParams) {
        this.push(this.createHistoryDataFromQueryParams(queryParams, url));
    }
    createHistoryDataFromQueryParams(encodedQueryParams, pathname = window.location.pathname) {
        return {
            pathname: !pathname.endsWith('/') ? pathname : pathname.substring(0, pathname.length - 1),
            search: !encodedQueryParams ? '' : this.composeQuery(encodedQueryParams)
        };
    }
    updateQueryParams(encodedQueryParams) {
        const data = this.createHistoryDataFromQueryParams(encodedQueryParams);
        this.replace(data);
    }
    getOrigin() {
        return window.location.origin;
    }
    getCurrent() {
        return this.history.location;
    }
    getHistory() {
        return this.history;
    }
    getQueryParameter(key) {
        const queryParams = exports.ModuleFE_BrowserHistory.getQueryParams();
        const value = queryParams[key];
        if (value === undefined && Object.keys(queryParams).includes(key))
            return null;
        return value;
    }
    getCurrentUrl() {
        return exports.ModuleFE_BrowserHistory.getCurrent().pathname;
    }
}
exports.ModuleFE_BrowserHistory_Class = ModuleFE_BrowserHistory_Class;
function getCurrentUrl() {
    return exports.ModuleFE_BrowserHistory.getCurrentUrl();
}
exports.getCurrentUrl = getCurrentUrl;
function getQueryParameter(name) {
    return exports.ModuleFE_BrowserHistory.getQueryParameter(name);
}
exports.getQueryParameter = getQueryParameter;
function getUrlQuery() {
    return exports.ModuleFE_BrowserHistory.getQueryParams();
}
exports.getUrlQuery = getUrlQuery;
function encodeUrlParams(queryParams) {
    const encodedQueryParams = Object.assign({}, queryParams);
    (0, ts_common_1._keys)(encodedQueryParams).forEach(key => {
        const value = encodedQueryParams[key];
        if (!value) {
            delete encodedQueryParams[key];
            return;
        }
        encodedQueryParams[key] = encodeURIComponent(value);
    });
    return encodedQueryParams;
}
exports.encodeUrlParams = encodeUrlParams;
function composeQuery(queryParams) {
    const queryAsString = (0, ts_common_1.composeQueryParams)(queryParams);
    if (queryAsString.length === 0)
        return '';
    return queryAsString;
}
exports.composeQuery = composeQuery;
function composeURL(url, queryParams) {
    const queryAsString = composeQuery(queryParams);
    return `${url}${queryAsString.length > 0 ? `?${queryAsString}` : ''}`;
}
exports.composeURL = composeURL;
exports.ModuleFE_BrowserHistory = new ModuleFE_BrowserHistory_Class();
