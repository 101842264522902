"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Locale = exports.ModuleFE_Locale_Class = void 0;
const ts_common_1 = require("@nu-art/ts-common");
class ModuleFE_Locale_Class extends ts_common_1.Module {
    constructor() {
        super();
    }
    init() {
        this.defaultLocale = this.config.languages[this.config.defaultLocal].texts;
        this.activeLocaleTexts = this.defaultLocale;
        this.setActiveLocale(this.config.defaultLocal);
    }
    setActiveLocale(locale) {
        const localeTexts = this.config.languages[locale].texts;
        if (!localeTexts)
            return;
        this.activeLocale = locale;
        this.setActiveLocaleImpl(localeTexts);
    }
    getActiveLocale() {
        return this.activeLocale;
    }
    setActiveLocaleImpl(localeStrings) {
        this.activeLocaleTexts = localeStrings;
    }
    stringify(key, previousKeys = []) {
        return (...params) => {
            let text = this.activeLocaleTexts[key];
            if (!text)
                text = this.defaultLocale[key];
            if (!text)
                return key;
            return params.reduce((_toRet, param, index) => {
                let toRet = _toRet;
                const refs = toRet.match(/@\{(.*?)\}/g);
                if (refs) {
                    toRet = refs.reduce((previousValue, stringKeyRef) => {
                        var _a;
                        const stringKey = (_a = stringKeyRef.match(/@\{(.*?)\}/)) === null || _a === void 0 ? void 0 : _a[1];
                        const nested = this.stringify(stringKey, [...previousKeys, key])();
                        return previousValue.replace(stringKeyRef, nested);
                    }, _toRet);
                }
                return toRet.replace(`\${${index}}`, `${param}`);
            }, text);
        };
    }
    getLanguage(locale) {
        var _a;
        return (_a = this.config.languages[locale]) === null || _a === void 0 ? void 0 : _a.language;
    }
    getActiveLanguage() {
        var _a;
        return (_a = this.config.languages[this.activeLocale]) === null || _a === void 0 ? void 0 : _a.language;
    }
    getAllLanguages() {
        return (0, ts_common_1._keys)(this.config.languages)
            .filter(key => Object.keys(this.config.languages[key].texts).length > 0)
            .map(key => this.config.languages[key].language);
    }
    convertNumber(param, locale) {
        return undefined;
    }
}
exports.ModuleFE_Locale_Class = ModuleFE_Locale_Class;
exports.ModuleFE_Locale = new ModuleFE_Locale_Class();
