"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.hashPasswordWithSalt = exports.randomObject = exports.randomNumber = void 0;
const crypto_1 = require("crypto");
function randomNumber(range) {
    return Math.floor(Math.random() * (range));
}
exports.randomNumber = randomNumber;
function randomObject(items) {
    return items[randomNumber(items.length)];
}
exports.randomObject = randomObject;
function hashPasswordWithSalt(salt, password) {
    return (0, crypto_1.createHmac)('sha512', salt)
        .update(password)
        .digest('hex');
}
exports.hashPasswordWithSalt = hashPasswordWithSalt;
