import {DB_MessageTemplate} from '@app/shared';
import {Replacer} from '@nu-art/ts-common';
import * as React from 'react';
import {ICONSV4} from '@res/icons';
import {_className, LL_H_C, openContent, TS_TextArea} from '@nu-art/thunderstorm/frontend';
import './_style.scss';


type Props = {
	message: DB_MessageTemplate,
	replacer: Replacer
}
export const Renderer_MenuItem_MessageTemplate = (props: Props) => {
	const tooltipRenderer = () => {
		return <div className={'message-template__tooltip-preview'}>
			<TS_TextArea type="text" value={props.replacer.replace(props.message.template)} disabled/>
		</div>;
	};

	return <LL_H_C className={_className('node-data', 'menu-item__message-template')}>
		{props.message.label}
		<div className={'info-circle'} {...openContent.popUp.left('messages-templates', tooltipRenderer)}>
			<ICONSV4.infoToast className={_className('ts-icon__small')}/>
		</div>
	</LL_H_C>;
};