import {DB_CustomerOrder, DeliveryDetails} from '@app/shared';
import {ComponentSync, LL_H_C, LL_V_C, LL_V_L, ModuleFE_Dialog, ModuleFE_Thunderstorm, TS_Input, TS_Printable} from '@nu-art/thunderstorm/frontend';
import React from 'react';
import './Dialog_ShippingSticker.scss';
import {ICONSV4} from '@res/icons';
import {ShippingSticker} from '@pah/customer-order/ShippingSticker/ShippingSticker';


type Props = {
	order: DB_CustomerOrder,
	deliveryDetails?: DeliveryDetails;
}

type State = {
	packagesCount: number
	refs: HTMLDivElement[];
}

export class Dialog_ShippingSticker
	extends ComponentSync<Props, State> {

	constructor(p: Props) {
		super(p);
	}

	protected deriveStateFromProps(nextProps: Props) {
		return {...this.state, packagesCount: this.state?.packagesCount || this.props.order.packagingStatus.packagesSize.length || 1, refs: this.state?.refs || []};
	}

	static show(order: DB_CustomerOrder, deliveryDetails?: DeliveryDetails) {
		ModuleFE_Dialog.show(<Dialog_ShippingSticker order={order} deliveryDetails={deliveryDetails}/>);
	}

	render() {
		// const barcode = canvas.toDataURL('image/png');

		const order = this.props.order;
		const deliveryDetails = this.props.deliveryDetails;

		return (
			<LL_V_L className="customer-order__shipping-sticker dialog">
				<LL_V_C className="match_width packages-count">
					<LL_H_C>Packages:
						<TS_Input type="number" value={`${this.state.packagesCount}`} onBlur={(value) => this.setState({packagesCount: +value})}/>
						<ICONSV4.print className="print-icon ts-print__gone"
													 onClick={() => this.state.refs[0] && ModuleFE_Thunderstorm.printDiv(this.state.refs[0])}/>

					</LL_H_C>
				</LL_V_C>
				<TS_Printable className="match_width shipping-sticker__printable-area" printable={(_ref) => {
					this.state.refs[0] = _ref;
					ModuleFE_Thunderstorm.printDiv(this.state.refs[0]);
				}}>
					{new Array(this.state.packagesCount).fill(0).map((_, i) => {
						return <ShippingSticker key={i} order={order} deliveryDetails={deliveryDetails} packageNumber={i + 1} totalPackages={this.state.packagesCount}/>;
					})}
				</TS_Printable>
			</LL_V_L>
		);
	}
}