"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// export type DB_ProductVariant = DB_Object & {
// 	id: number,
// 	productId: string,
// 	inventory: number
// 	price: number,
// 	sku?: string,
// 	taxable?: false, // can be defined in store level for undefined
// 	barcode?: string,
// 	weight?: Weight
// 	requiresShipping?: boolean, // can be defined in store level for undefined
// 	// "position": 1, will be in the product as variants:string[]
// }
