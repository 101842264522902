"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Routing = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
const route_1 = require("./route");
const ModuleFE_BrowserHistory_1 = require("../ModuleFE_BrowserHistory");
const react_router_dom_1 = require("react-router-dom");
class ModuleFE_Routing_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.routes = {};
        this.ordinalRoutes = [];
        this.getMyRouteKey = () => Object.keys(this.routes).find(key => this.routes[key].path === ModuleFE_BrowserHistory_1.ModuleFE_BrowserHistory.getCurrent().pathname);
        this.getCurrentUrl = () => window.location.href;
        this.getEncodedQueryParams = () => {
            const queryParams = {};
            let queryAsString = window.location.search;
            if (!queryAsString || queryAsString.length === 0)
                return {};
            while (queryAsString.startsWith('?') || queryAsString.startsWith('/?')) {
                if (queryAsString.startsWith('?'))
                    queryAsString = queryAsString.substring(1);
                else if (queryAsString.startsWith('/?'))
                    queryAsString = queryAsString.substring(2);
                else
                    break;
            }
            const query = queryAsString.split('&');
            return query.map(param => {
                const parts = param.split('=');
                return { key: parts[0], value: parts[1] };
            }).reduce((toRet, param) => {
                if (param.key && param.value)
                    toRet[param.key] = param.value;
                return toRet;
            }, queryParams);
        };
        this.goToUrl = (url) => window.location.href = url;
        this.createNavLinkNode = route_1.defaultNavLinkNode;
        this.createLinkNode = route_1.defaultLinkNode;
        this.createRouteNode = route_1.defaultRouteNode;
    }
    init() {
    }
    clearRoutes() {
        for (const item of this.ordinalRoutes) {
            delete this.routes[item];
        }
        this.ordinalRoutes.splice(0);
    }
    addRoute(key, route, component) {
        const previousRoute = this.routes[key];
        if (previousRoute)
            throw new ts_common_1.BadImplementationException(`Route key '${key}' MUST be unique!!\n  Found two routes with matching key: '${route}' && '${previousRoute.path}'`);
        (0, ts_common_1.addItemToArray)(this.ordinalRoutes, key);
        return this.routes[key] = new route_1.RoutePath(key, route, component);
    }
    getRoute(key) {
        const route = this.routes[key];
        if (!route)
            throw new ts_common_1.BadImplementationException(`No Route for key '${key}'... Did you forget to add it??`);
        return route;
    }
    getPath(key) {
        return this.getRoute(key).path;
    }
    goToRoute(key, params) {
        const pathname = this.getPath(key);
        const search = (0, ts_common_1.composeQueryParams)(params);
        ModuleFE_BrowserHistory_1.ModuleFE_BrowserHistory.push({ pathname, search });
    }
    redirect(key) {
        return React.createElement(react_router_dom_1.Navigate, { to: exports.ModuleFE_Routing.getPath(key) });
    }
    // need to figure out how to create parameterized urls from this call !!
    getNavLinks(keys) {
        return keys.map(key => this.getRoute(key)).filter(route => route.visible && route.visible()).map(route => this.createNavLinkNode(route));
    }
    getNavLink(key) {
        return this.createNavLinkNode(this.getRoute(key));
    }
    getLink(key) {
        return this.createLinkNode(this.getRoute(key));
    }
    getRoutesMap(keys) {
        return React.createElement(react_router_dom_1.Routes, null, (keys || this.ordinalRoutes).map(key => this.createRouteNode(this.getRoute(key))));
    }
    getSearch() {
        const params = this.getEncodedQueryParams();
        (0, ts_common_1._keys)(params).forEach(key => {
            const value = `${params[key]}`;
            if (!value) {
                delete params[key];
                return;
            }
            params[key] = decodeURIComponent(value);
        });
        return params;
    }
}
exports.ModuleFE_Routing = new ModuleFE_Routing_Class();
