"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayIncludesAny = exports.firstElement = exports.lastElement = exports.asOptionalArray = exports.asArray = exports.generateArray = exports.toggleInArray = exports.groupArrayBy = exports.filterFlatInstances = exports.flatArray = exports.batchActionParallel = exports.Promise_all_sequentially = exports.batchAction = exports.sortArray = exports.reduceToMap = exports.arrayToMap = exports.filterFalsy = exports.filterInstances = exports.filterDuplicates = exports.findDuplicates = exports.filterAsync = exports.toggleElementInArray = exports.addItemToArrayAtIndex = exports.addItemToArray = exports.removeFromArrayByIndex = exports.removeFromArray = exports.removeItemFromArray = exports.filterInOut = void 0;
const tools_1 = require("./tools");
const object_tools_1 = require("./object-tools");
function filterInOut(input, filter) {
    return {
        filteredIn: input.filter(filter),
        filteredOut: input.filter((object) => !filter(object))
    };
}
exports.filterInOut = filterInOut;
/**
 * Finds and removes first instance of item from array
 * tested V
 */
function removeItemFromArray(array, item) {
    const index = array.indexOf(item);
    return removeFromArrayByIndex(array, index);
}
exports.removeItemFromArray = removeItemFromArray;
/**
 * Removes the first item answering the condition given from array in place
 * tested V
 */
function removeFromArray(array, item) {
    const index = array.findIndex(item);
    return removeFromArrayByIndex(array, index);
}
exports.removeFromArray = removeFromArray;
/**
 * Removes item from array in index
 * tested V
 */
function removeFromArrayByIndex(array, index) {
    if (index > -1)
        array.splice(index, 1);
    return array;
}
exports.removeFromArrayByIndex = removeFromArrayByIndex;
/**
 * Deprecated
 */
function addItemToArray(array, item) {
    array.push(item);
    return array;
}
exports.addItemToArray = addItemToArray;
function addItemToArrayAtIndex(array, item, index) {
    array.splice(index, 0, item);
    return array;
}
exports.addItemToArrayAtIndex = addItemToArrayAtIndex;
function toggleElementInArray(array, item) {
    const index = array.indexOf(item);
    if (index > -1)
        array.splice(index, 1);
    else
        array.push(item);
    return array;
}
exports.toggleElementInArray = toggleElementInArray;
/**
 * Removes all items answering the condition given from array in place
 */
function filterAsync(arr, filter) {
    return __awaiter(this, void 0, void 0, function* () {
        //const boolArray = await arr.map(item => filter(item)); changed
        const boolArray = yield Promise.all(arr.map(item => filter(item)));
        return arr.filter((item, index) => boolArray[index]);
    });
}
exports.filterAsync = filterAsync;
/**
 * builds array that holds all items that are in array1 and array2
 * problem with objects
 */
function findDuplicates(array1, array2) {
    return array1.filter(val => array2.indexOf(val) !== -1);
}
exports.findDuplicates = findDuplicates;
const defaultMapper = (item) => item;
/**
 remove all duplicates in array
 * */
function filterDuplicates(source, mapper = defaultMapper) {
    if (defaultMapper === mapper)
        return Array.from(new Set(source));
    const uniqueKeys = new Set(source.map(mapper));
    return source.filter(item => uniqueKeys.delete(mapper(item)));
}
exports.filterDuplicates = filterDuplicates;
/**
 * filter array of all undefined and null
 * */
function filterInstances(array) {
    return ((array === null || array === void 0 ? void 0 : array.filter(item => (0, tools_1.exists)(item))) || []);
}
exports.filterInstances = filterInstances;
/**
 * filter array of all falsy instances
 * */
function filterFalsy(array) {
    return ((array === null || array === void 0 ? void 0 : array.filter(item => !!item)) || []);
}
exports.filterFalsy = filterFalsy;
/**
 * receives array and builds hashmap whom keys are decided via function and values are from array
 * */
function arrayToMap(array, getKey, map = {}) {
    return reduceToMap(array, getKey, item => item, map);
}
exports.arrayToMap = arrayToMap;
/**
 * turns array into object that is similar to hashmap
 * */
function reduceToMap(array, keyResolver, mapper, map = {}) {
    return array.reduce((toRet, element, index) => {
        toRet[keyResolver(element, index, toRet)] = mapper(element, index, toRet);
        return toRet;
    }, map);
}
exports.reduceToMap = reduceToMap;
/**
 * sorts array
 * */
function sortArray(array, map = i => i, invert = false) {
    const functionMap = map;
    if (typeof functionMap === 'function') {
        const compareFn = (a, b) => {
            const _a = functionMap(a);
            const _b = functionMap(b);
            return (_a < _b ? -1 : (_a === _b ? 0 : 1)) * (invert ? -1 : 1);
        };
        return array.sort(compareFn);
    }
    let keys;
    if (!Array.isArray(map))
        keys = [map];
    else
        keys = map;
    return keys.reduce((array, key) => {
        return sortArray(array, item => item[key]);
    }, array);
}
exports.sortArray = sortArray;
/**
 * "splits" array into given size of chunks and then does "action" on chunk and return to array of actions on chunks +-
 * */
function batchAction(arr, chunk, action) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = [];
        for (let i = 0, j = arr.length; i < j; i += chunk) {
            const items = yield action(arr.slice(i, i + chunk));
            if (Array.isArray(items))
                //addAllItemToArray(result, items);
                result.push(...items);
            else
                addItemToArray(result, items);
        }
        return result;
    });
}
exports.batchAction = batchAction;
/**
 * Processes an array of promise-returning tasks sequentially.
 *
 * @typeParam T - The type of resolved value of the promises.
 * @returns A promise that resolves to an array of resolved values.
 *
 * @example
 * ```typescript
 * let tasks = [
 *     () => new Promise<number>(resolve => setTimeout(() => resolve(1), 1000)),
 *     () => new Promise<number>(resolve => setTimeout(() => resolve(2), 500)),
 *     () => new Promise<number>(resolve => setTimeout(() => resolve(3), 1500))
 * ];
 *
 * Promise_all_sequentially(tasks).then(console.log);  // [1, 2, 3]
 * ```
 * @param promises
 */
function Promise_all_sequentially(promises) {
    return __awaiter(this, void 0, void 0, function* () {
        const results = [];
        for (const promise of promises) {
            results.push(yield promise());
        }
        return results;
    });
}
exports.Promise_all_sequentially = Promise_all_sequentially;
function batchActionParallel(arr, chunk, action) {
    return __awaiter(this, void 0, void 0, function* () {
        const promises = [];
        for (let i = 0, j = arr.length; i < j; i += chunk) {
            addItemToArray(promises, action(arr.slice(i, i + chunk)));
        }
        const toRet = [];
        const results = yield Promise.all(promises);
        for (const items of results) {
            if (Array.isArray(items))
                //addAllItemToArray(toRet, items);
                toRet.push(...items);
            else
                //addItemToArray(toRet, items);
                toRet.push(items);
        }
        return toRet;
    });
}
exports.batchActionParallel = batchActionParallel;
/**
 * Returns a flat array from an array of arrays.
 * @param arr An array that is potentially a matrix
 * @param result A flat array of single values
 */
function flatArray(arr, result = []) {
    for (let i = 0, length = arr.length; i < length; i++) {
        const value = arr[i];
        if (Array.isArray(value)) {
            flatArray(value, result);
        }
        else {
            result.push(value);
        }
    }
    return result;
}
exports.flatArray = flatArray;
function filterFlatInstances(arr, result = []) {
    return filterInstances(flatArray(arr, result));
}
exports.filterFlatInstances = filterFlatInstances;
function groupArrayBy(arr, mapper) {
    const map = arr.reduce((agg, item, index) => {
        const key = mapper(item, index);
        (agg[key] || (agg[key] = [])).push(item);
        return agg;
    }, {});
    return (0, object_tools_1._keys)(map).map(key => ({ key, values: map[key] }));
}
exports.groupArrayBy = groupArrayBy;
function toggleInArray(arr, item, mapper = item => item) {
    const index = arr.findIndex(_item => mapper(_item) === mapper(item));
    if (index !== -1)
        removeFromArrayByIndex(arr, index);
    else
        arr.push(item);
}
exports.toggleInArray = toggleInArray;
function generateArray(length, mapper = i => i) {
    return Array.from({ length }).map((e, i) => mapper(i));
}
exports.generateArray = generateArray;
function asArray(toBeArray) {
    return Array.isArray(toBeArray) ? toBeArray : [toBeArray];
}
exports.asArray = asArray;
function asOptionalArray(toBeArray) {
    if (!(0, tools_1.exists)(toBeArray))
        return undefined;
    return asArray(toBeArray);
}
exports.asOptionalArray = asOptionalArray;
function lastElement(array) {
    return array === null || array === void 0 ? void 0 : array[(array === null || array === void 0 ? void 0 : array.length) - 1];
}
exports.lastElement = lastElement;
function firstElement(array) {
    return array === null || array === void 0 ? void 0 : array[1];
}
exports.firstElement = firstElement;
function arrayIncludesAny(arr1, arr2) {
    return arr1.some(item => arr2.includes(item));
}
exports.arrayIncludesAny = arrayIncludesAny;
