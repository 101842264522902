import {DB_Payment, DBDef_Payment} from '@app/shared';
import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';


export interface OnPaymentsUpdated {
	__onPaymentsUpdated: (...params: ApiCallerEventType<DB_Payment>) => void;
}

export const dispatch_onPaymentsUpdated = new ThunderDispatcher<OnPaymentsUpdated, '__onPaymentsUpdated'>('__onPaymentsUpdated');

export class ModuleFE_Payment_Class
	extends ModuleFE_BaseApi<DB_Payment> {

	constructor() {
		super(DBDef_Payment,dispatch_onPaymentsUpdated);
	}
}

export const ModuleFE_Payments = new ModuleFE_Payment_Class();
