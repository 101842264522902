import * as React from 'react';
import {DB_InventoryItem, DB_Product, DB_StoreInventory, DB_Variant, DB_VariantOption, IndexKey_InventoryItems} from '@app/shared';
import {Component_DBItemEditorV2, Props_DBItemEditorComponentV2, State_DBItemEditorComponentV2} from '@component/Component_DBItemEditorV2';
import {
	ModuleFE_InventoryItems,
	ModuleFE_Products,
	ModuleFE_ProductVariants,
	ModuleFE_StoreInventory,
	ModuleFE_VariantOptions,
	OnStoreInventoryUpdated
} from '@modules/index';
import {LL_H_C, ModuleFE_Thunderstorm, stopPropagation, TS_Checkbox, TS_Input, TS_Table} from '@nu-art/thunderstorm/frontend';
import {PropertyRenderer} from '@component/PropertyRenderer';
import {csvSerializer, filterInstances, flatArray, formatTimestamp, MimeType_csv, roundNumber, sortArray} from '@nu-art/ts-common';
import {DatePropertyEditor} from '@component/DateRenderer';
import {ICONSV4} from '@res/icons';
import {DateFormat_DD_MM_YYYY_WithDashes} from '@page/input/shipment/Page_ShipmentList';
import {Props_SmartComponent, State_SmartComponent} from '@nu-art/thunderstorm/frontend';


type State = {
	inventoryItems: DB_InventoryItem[]
	products: DB_Product[]
	variants: DB_Variant[]
	variantOptions: DB_VariantOption[]
	filterOutNoQuantity: boolean
	filterOutNoIdentifier: boolean
};
type FullProps = Props_SmartComponent & Props_DBItemEditorComponentV2<{}, DB_StoreInventory>;

type FullState = State_SmartComponent & State_DBItemEditorComponentV2<State, DB_StoreInventory>;

export class Component_StoreInventory
	extends Component_DBItemEditorV2<DB_StoreInventory, '_id', {}, State>
	implements OnStoreInventoryUpdated {

	static defaultProps = {
		moduleFE: ModuleFE_StoreInventory,
	};

	__onStoreInventoryUpdated() {
		this.reDeriveState();
	}

	protected async deriveStateFromProps(nextProps: FullProps, state: FullState) {
		await super.deriveStateFromProps(nextProps, state);
		const inventoryId = state.item?._id;
		state.variants = await ModuleFE_ProductVariants.cache.allMutable();
		state.products = await ModuleFE_Products.cache.allMutable();
		state.variantOptions = await ModuleFE_VariantOptions.cache.allMutable();
		state.inventoryItems = inventoryId ? await ModuleFE_InventoryItems.IDB.query([inventoryId], IndexKey_InventoryItems) : [];
		return state;
	}

	renderItem(_item?: DB_StoreInventory): React.ReactNode {
		const item = _item;
		if (!item)
			return '';

		let inventoryItems = sortArray(this.state.inventoryItems, i => this.state.products.find(p => p._id === (i.productId))?.title || '');
		inventoryItems = inventoryItems.filter(i => !this.state.filterOutNoQuantity || i.quantity > 0);
		inventoryItems = inventoryItems.filter(i => {
			const variant = this.state.variants.find(v => v._id === i.variantId);
			return !this.state.filterOutNoIdentifier || variant?.sku || variant?.barcode;
		});

		inventoryItems = sortArray(inventoryItems, i => {
			const variant = this.state.variants.find(v => v._id === i.variantId) as DB_Variant;

			return variant.shipmentsHistory?.length || 0;
		});

		const mismatchCount = inventoryItems.findIndex(i => this.state.variants.find(v => v._id === i.variantId)?.shipmentsHistory?.length || 0 > 0);
		this.logWarning(`inventoryItems: ${inventoryItems.length}`);
		return <div className="match_width ll_v_l">
			<div className="ll_h_c">
				<PropertyRenderer label={'title:'}>
					<TS_Input onChange={(value) => item.title = value} type={'text'} id={'inventory-title-editor'} value={item.title}/>
				</PropertyRenderer>
				{this.renderAction(item)}({mismatchCount})
			</div>
			<LL_H_C>
				{DatePropertyEditor<Partial<DB_StoreInventory>, 'timestamp'>(item, 'timestamp', () => {
					ModuleFE_StoreInventory.v1.upsert(item).execute();
				})}
				<LL_H_C>
					<TS_Checkbox
						checked={this.state.filterOutNoQuantity}
						onCheck={filterOutNoQuantity => this.setState({filterOutNoQuantity})}/>Filter out no Quantity
				</LL_H_C>

				<LL_H_C>
					<TS_Checkbox
						checked={this.state.filterOutNoIdentifier}
						onCheck={filterOutNoIdentifier => this.setState({filterOutNoIdentifier})}/>Filter out no Identifier
				</LL_H_C>

				{ICONSV4.download({
					onClick: (e) => {
						stopPropagation(e);
						return this.downloadCsv(inventoryItems);
					}
				})}
			</LL_H_C>
			<TS_Table<DB_InventoryItem>
				id={`inventory-${item.title}`}
				header={['variantId', 'productId', 'quantity', 'history']}
				rows={inventoryItems}
				headerRenderer={header => header}
				tr={(row, rowIndex) => {
					if (!row)
						return {};

					return {style: {backgroundColor: this.state.variants.find(v => v._id === row.variantId)?.shipmentsHistory?.length === 0 ? '#FF444444' : 'unset'}};
				}}
				cellRenderer={(prop, item, index: number) => {
					const variant = this.state.variants.find(v => v._id === item.variantId);
					if (!variant)
						return 'error - ' + item[prop];

					if (prop === 'productId') {
						let id = '';
						if (variant.sku)
							id += `${variant.sku}-`;
						if (variant.sku !== variant.barcode && variant.barcode)
							id += `${variant.barcode}-`;

						return <div>{id.substring(0, id.length - 1)}</div>;
					}
					if (prop === 'variantId') {
						const productId = variant?.productId;
						const label = this.state.products.find(p => p._id === productId)?.title || 'pah';
						const variants = !variant.variantOptionIds?.length ? '' : ` - ${filterInstances(variant.variantOptionIds.map(variantOptionId => this.state.variantOptions.find(vo => vo._id === variantOptionId)?.label))}`;

						return <div style={{fontFamily: 'monospace'}}>{item[prop]} - {label}{variants}</div>;
					}

					if (prop === 'quantity')
						return <div>{item[prop]}</div>;

					if (prop === 'history')
						return item[prop]?.map(h => `${roundNumber(h.value * h.quantity * h.ratio, 2)}`).join(',');
				}}/>
		</div>;
	}

	private renderAction(item: DB_StoreInventory) {
		if (item._id)
			return <div className="clickable" onClick={() => ModuleFE_StoreInventory._v1.calculateInventory(item).execute()}>{'Sync Inventory'}</div>;

		return <div className="clickable" onClick={() => ModuleFE_StoreInventory.v1.upsert(item).execute()}>{'Create Inventory'}</div>;
	}

	private async downloadCsv(inventoryItems: DB_InventoryItem[]) {
		// type CSVRows = {
		// 	title: string,
		// 	barcode: string,
		// 	date: string,
		// 	quantity: number,
		// 	price: string,
		// 	totalPrice: string,
		// }
		const rows = flatArray(await Promise.all(inventoryItems.map(async item => {
			const variant = await ModuleFE_ProductVariants.cache.unique(item.variantId) as DB_Variant;
			const product = await ModuleFE_Products.cache.unique(item.productId) as DB_Product;
			return item.history.map(h => {
				return {
					title: product.title,
					barcode: variant.barcode || variant.sku || '',
					date: formatTimestamp(DateFormat_DD_MM_YYYY_WithDashes, h.timestamp),
					quantity: h.quantity,
					price: (h.value * h.ratio).toFixed(2),
					totalPrice: (h.quantity * h.value * h.ratio).toFixed(2),
				};
			});
		})));

		const content = csvSerializer(rows, {
			columns: [
				'title',
				'barcode',
				'date',
				'quantity',
				'price',
				'totalPrice',
			]
		});

		ModuleFE_Thunderstorm.downloadFile({fileName: `Inventory-${this.state.item?.title || 'PAH'}`, content, mimeType: MimeType_csv});
	}
}


