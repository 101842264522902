"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_PopUp = void 0;
const React = require("react");
require("./TS_PopUp.scss");
const TS_Overlay_1 = require("../../TS_Overlay");
const tools_1 = require("../../../utils/tools");
const ts_common_1 = require("@nu-art/ts-common");
const TS_MouseInteractivity_1 = require("../base/TS_MouseInteractivity");
const types_1 = require("../../../component-modules/mouse-interactivity/types");
const ModuleFE_MouseInteractivity_1 = require("../../../component-modules/mouse-interactivity/ModuleFE_MouseInteractivity");
class TS_PopUp extends TS_MouseInteractivity_1.TS_MouseInteractivity {
    constructor() {
        super(...arguments);
        this.__onPopUpDisplay = (model) => {
            this.setState({ model, open: !!model });
        };
    }
    __onWindowResized() {
        if (this.state.model)
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.hide(types_1.mouseInteractivity_PopUp);
    }
    render() {
        const { model, open } = this.state;
        if (!model || !open)
            return '';
        return React.createElement(TS_Overlay_1.TS_Overlay, { className: (0, tools_1._className)('ts-popup', model.overlayClass), showOverlay: open, onClickOverlay: (e) => {
                (0, tools_1.stopPropagation)(e);
                this.setState({ open: false });
            } },
            React.createElement("div", { className: "ts-popup__content", id: model.id, ref: this.ref }, (0, ts_common_1.resolveContent)(model.content)));
    }
}
exports.TS_PopUp = TS_PopUp;
