"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genericNotificationAction = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ModuleFE_Notifications_1 = require("../../component-modules/ModuleFE_Notifications");
const genericNotificationAction = async (action, _title, postDelay = 1.5) => {
    var _a;
    const title = typeof _title === 'string' ? _title : _title.inProgress;
    const successTitle = typeof _title === 'string' ? title + ' - Success' : _title.success || title + ' - Success';
    const notification = ModuleFE_Notifications_1.ModuleFE_Notifications.create().content(title).postDelayed(postDelay * ts_common_1.Second);
    try {
        await notification.execute(action);
        notification.content(successTitle).show();
    }
    catch (e) {
        const failTitle = typeof _title === 'string' ? title + ' - Failed' : typeof _title.failed === 'string' ? _title.failed : ((_a = _title.failed) === null || _a === void 0 ? void 0 : _a.call(_title, e)) || title + ' - Failed';
        notification.content(failTitle, e.message).show();
        ts_common_1.StaticLogger.logError(e);
    }
};
exports.genericNotificationAction = genericNotificationAction;
