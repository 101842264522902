"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tsValidateOptionalSku = exports.tsValidateSku = exports.tsValidateCurrencyValue = exports.tsValidateCountryOfOrigin = exports.tsValidateBarcode = exports.tsValidateCurrency = exports.tsValidateStringLength = exports.tsValidateStringOneLine = exports.tsValidateShopifyId = exports.tsValidateShopifyLocale = exports.tsValidateShopifyDomain = void 0;
const ts_common_1 = require("@nu-art/ts-common");
exports.tsValidateShopifyDomain = (0, ts_common_1.tsValidateRegexp)(/^[A-Za-z-_\.]+$/);
exports.tsValidateShopifyLocale = (0, ts_common_1.tsValidateRegexp)(/^[a-z]{2}$/);
exports.tsValidateShopifyId = (0, ts_common_1.tsValidateRegexp)(/^[0-9]{8,}$/);
exports.tsValidateStringOneLine = (0, ts_common_1.tsValidateRegexp)(/^.*$/);
const tsValidateStringLength = (length, mandatory = true) => (0, ts_common_1.tsValidateRegexp)(new RegExp(`.{1,${length}}`), mandatory);
exports.tsValidateStringLength = tsValidateStringLength;
exports.tsValidateCurrency = (0, ts_common_1.tsValidateValue)(['EUR', 'ILS', 'USD'], false);
exports.tsValidateBarcode = (0, ts_common_1.tsValidateRegexp)(/[0-9]{8,20}/, false);
exports.tsValidateCountryOfOrigin = (0, exports.tsValidateStringLength)(40, false);
exports.tsValidateCurrencyValue = {
    value: (0, ts_common_1.tsValidateIsInRange)([[0, Number.MAX_SAFE_INTEGER]]),
    currency: exports.tsValidateCurrency
};
function validateOptionalSku(mandatory = true) {
    return (0, ts_common_1.tsValidateRegexp)(/[0-9a-zA-z-_\.]{5,20}/, mandatory);
}
exports.tsValidateSku = validateOptionalSku();
exports.tsValidateOptionalSku = validateOptionalSku(false);
