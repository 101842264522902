"use strict";
/*
 * A generic push pub sub infra for webapps
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_PushMessages = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
exports.ApiDef_PushMessages = {
    v1: {
        unregister: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/push-messages/unregister' },
        register: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/push-messages/register' },
        registerAll: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/push-messages/register-all' }
    }
};
