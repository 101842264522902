import {ModuleFE_BaseApi, Props_SmartComponent, SmartPage, State_SmartComponent} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_RoutingV2, stopPropagation, TS_Route} from '@nu-art/thunderstorm/frontend';
import {DB_Object, Default_UniqueKey, IndexKeys, PreDB} from '@nu-art/ts-common';
import * as React from 'react';
import {dispatch_updateHeaderOption, HeaderOption} from '@page/Page_Workspace/Component_MainHeader';
import {PageKey_ID} from '@consts/routes-consts';


export type Props_DBItemListV2<ItemType extends DB_Object, Ks extends keyof PreDB<ItemType> = Default_UniqueKey> = {
	keys: Ks[]

	pageTitle: string | (() => string)

	childRoute: TS_Route<{ [K in Ks]: string }>
	newRoute?: string
	headerButtons?: HeaderOption[]
	moduleFE: ModuleFE_BaseApi<ItemType>
}

export type State_DBItemListV2<ItemType extends DB_Object> = { items?: ItemType[] };

export abstract class Page_DBItemListV2<ItemType extends DB_Object,
	State extends any = {},
	Ks extends keyof PreDB<ItemType> = Default_UniqueKey,
	Props extends any = {},
	S extends State_DBItemListV2<ItemType> & State = State_DBItemListV2<ItemType> & State,
	P extends Props_DBItemListV2<ItemType, Ks> & Props = Props_DBItemListV2<ItemType, Ks> & Props>
	extends SmartPage<P, S> {

	constructor(p: P) {
		super(p);

		// @ts-ignore
		this[p.moduleFE.defaultDispatcher.method] = this.__onItemUpdated;
	}

	protected async deriveStateFromProps(nextProps: Props_SmartComponent & P, state: S & State_SmartComponent) {
		state.items = nextProps.moduleFE.cache.allMutable();
		return state;
	}

	protected query(keys: IndexKeys<ItemType, any>) {
		(this.props.moduleFE as ModuleFE_BaseApi<any, any>).v1.queryUnique(keys).execute();
	}

	componentDidMount() {
		const headerButtons: HeaderOption[] | undefined = this.props.headerButtons;
		if (headerButtons)
			dispatch_updateHeaderOption.dispatchUI(headerButtons);
	}

	// private __onItemUpdated = (...params: ApiCallerEventType<ItemType>): void => {
	// 	switch (params[0]) {
	// 		case EventType_Create: {
	// 			const item = params[1];
	// 			const queryParams = resolveKeys<ItemType, Ks>(this.props.keys, (key) => item[key] as unknown as string) as unknown as QueryParams;
	// 			return ModuleFE_RoutingV2.goToRoute(this.props.childRoute as TS_Route, (queryParams || {}));
	// 		}
	// 	}
	// 	this.reDeriveState();
	// };

	render() {
		return this.renderItems(this.state.items);
	}

	protected abstract renderItems(items?: ItemType[]): React.ReactNode

	protected openPage = (ev: React.MouseEvent<HTMLDivElement>) => {
		stopPropagation(ev);
		this.openPageById(ev.currentTarget.id);
	};

	protected openPageById = (id: string) => {
		ModuleFE_RoutingV2.goToRoute(this.props.childRoute as TS_Route<{ _id: string }>, {[PageKey_ID]: id});
	};
}

