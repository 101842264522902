"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_BusyButton = void 0;
const React = require("react");
const tools_1 = require("../../utils/tools");
const Layouts_1 = require("../Layouts");
require("./TS_BusyButton.scss");
const TS_ButtonLoader_1 = require("../TS_ButtonLoader");
const ComponentAsync_1 = require("../../core/ComponentAsync");
/**
 * A button made simpler
 *
 *
 * <b>SCSS:</b>
 * ```scss
 * .ts-button {
 *   .ts-button__disabled {
 *   }
 * }
 * ```
 */
class TS_BusyButton extends ComponentAsync_1.ComponentAsync {
    constructor() {
        super(...arguments);
        this.renderItems = () => {
            if (this.state.isBusy) {
                const loadingRenderer = this.props.loadingRenderer;
                return typeof loadingRenderer === 'function' ? loadingRenderer() : loadingRenderer;
            }
            return this.props.children;
        };
        this.onClick = (e) => {
            if (this.state.isBusy)
                return;
            if (this.state.disabled && !this.props.onDisabledClick)
                return;
            this.setState({ isBusy: true }, async () => {
                var _a, _b;
                try {
                    await ((_b = (_a = this.props)[this.state.disabled ? 'onDisabledClick' : 'onClick']) === null || _b === void 0 ? void 0 : _b.call(_a, e));
                    if (!this.props.keepLoaderOnSuccess && this.mounted)
                        this.reDeriveState({ isBusy: false });
                }
                catch (err) {
                    if (this.mounted)
                        this.setState({ isBusy: false });
                }
            });
        };
    }
    // _constructor() {
    // 	this.logger.setMinLevel(LogLevel.Verbose);
    // }
    async deriveStateFromProps(nextProps, state = {}) {
        var _a;
        state.disabled = nextProps.disabled;
        (_a = state.isBusy) !== null && _a !== void 0 ? _a : (state.isBusy = false);
        return state;
    }
    render() {
        const _a = this.props, { onClick, disabled, loadingRenderer, onDisabledClick, keepLoaderOnSuccess } = _a, restOfProps = __rest(_a, ["onClick", "disabled", "loadingRenderer", "onDisabledClick", "keepLoaderOnSuccess"]);
        const className = (0, tools_1._className)('ts-busy-button', this.props.className, !this.state.isBusy && this.state.disabled && 'ts-busy-button__disabled', this.state.isBusy && 'ts-busy-button__loading');
        return React.createElement(Layouts_1.LL_H_C, Object.assign({}, restOfProps, { className: className, onClick: this.onClick }), this.renderItems());
    }
}
TS_BusyButton.defaultProps = {
    keepLoaderOnSuccess: false,
    loadingRenderer: React.createElement(TS_ButtonLoader_1.TS_ButtonLoader, null),
    disabled: false
};
exports.TS_BusyButton = TS_BusyButton;
