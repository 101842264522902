import {LL_V_C, ModuleFE_BrowserHistory, ModuleFE_Thunderstorm, ModuleFE_Toaster, TS_Printable, TS_Route} from '@nu-art/thunderstorm/frontend';
import {
	ModuleFE_CustomerContact,
	ModuleFE_CustomerOrder,
	ModuleFE_Products,
	ModuleFE_ProductVariantConfigs,
	ModuleFE_ProductVariants,
	ModuleFE_ShippingMethod,
	ModuleFE_VariantOptions,
	ModuleFE_Vendors
} from '@modules/index';
import {DB_CustomerOrder} from '@app/shared';
import {Editor_CustomerOrder} from './components/Editor_CustomerOrder';
import './Page_CustomerOrder.scss';
import {Page_DBItemEditorV3} from '@component/Page_DBItemEditorV3';
import {dispatch_updateHeaderOption} from '@page/Page_Workspace/Component_MainHeader';
import {PageKey_ID} from '@consts/routes-consts';
import {Dialog_OrderGraph} from '@pah/customer-order/dialogs/Dialog_OrderGraph';
import {addItemToArray, Module, removeItemFromArray} from '@nu-art/ts-common';
import React, {MouseEvent} from 'react';


export class Page_CustomerOrder
	extends Page_DBItemEditorV3<DB_CustomerOrder> {

	static Route: TS_Route<{ _id: string }> = {path: 'v1', key: 'customer-order', Component: this};
	private ref!: HTMLDivElement;

	static defaultProps = {
		pageTitle: 'Customer Order page',
		keys: ['_id'],
		route: this.Route,
		moduleFE: ModuleFE_CustomerOrder,
		itemEditor: Editor_CustomerOrder,
		modules: [
			ModuleFE_ShippingMethod,
			ModuleFE_CustomerOrder,
			ModuleFE_CustomerContact,
			ModuleFE_Vendors,
			ModuleFE_Products,
			ModuleFE_ProductVariants,
			ModuleFE_VariantOptions,
			ModuleFE_ProductVariantConfigs,
		]
	};

	componentDidMount() {
		dispatch_updateHeaderOption.dispatchUI([
			{
				icon: 'show',
				tooltip: 'Show Graph',
				action: this.toggleGraph
			},
			{
				condition: (ev) => (ev.metaKey || ev.ctrlKey) && !ev.shiftKey && (ev as KeyboardEvent).key === 'p',
				icon: 'print',
				action: () => {
					ModuleFE_Thunderstorm.printDiv(this.ref);
				}
			},
			{
				icon: 'triangle_down',
				tooltip: 'Previous Order',
				action: () => {
					const orders = ModuleFE_CustomerOrder.cache.sort(order => order.orderNumber);
					const item = this.state.editable.item;
					if (!orders || !item)
						return '';

					const previousOrder = orders[orders.findIndex((order: DB_CustomerOrder) => item._id === order._id) + -1];
					if (!previousOrder)
						return ModuleFE_Toaster.toastInfo('No Older orders');

					ModuleFE_BrowserHistory.addQueryParam(PageKey_ID, previousOrder._id!);
					this.reDeriveState();
				}
			},
			{
				icon: 'triangle_up',
				tooltip: 'Next Order',
				action: () => {
					const orders = ModuleFE_CustomerOrder.cache.sort(order => order.orderNumber);
					const item = this.state.editable.item;
					if (!orders || !item)
						return '';

					const nextOrder = orders[orders.findIndex((order: DB_CustomerOrder) => item._id === order._id) + 1];
					if (!nextOrder)
						return ModuleFE_Toaster.toastInfo('No Newer orders');

					ModuleFE_BrowserHistory.addQueryParam(PageKey_ID, nextOrder._id!);
					this.reDeriveState();
				}
			},
		]);
	}

	render() {
		return <LL_V_C className="match_parent">
			<TS_Printable className="customer-order" printable={(ref) => this.ref = ref}>
				{super.render()}
			</TS_Printable>
		</LL_V_C>;
	}

	componentWillUnmount() {

	}

	private toggleGraph = () => {
		Dialog_OrderGraph.show(this.state.editable.item);
	};
}

export type EventProcessor = {
	condition: (ev: KeyboardEvent | MouseEvent) => boolean
	action: (ev: KeyboardEvent | MouseEvent) => any
}

export class Module_KeyBinder_Class
	extends Module {

	eventProcessors: EventProcessor[] = [];

	bind = (eventProcessor: EventProcessor) => {
		addItemToArray(this.eventProcessors, eventProcessor);
	};

	unbind = (eventProcessor: EventProcessor) => {
		removeItemFromArray(this.eventProcessors, eventProcessor);
	};

	mount = () => {
		window.addEventListener('keydown', this.onKeyDown);
	};

	unmount = () => {
		window.removeEventListener('keydown', this.onKeyDown);
	};

	private onKeyDown = (ev: KeyboardEvent) => {
		this.eventProcessors.forEach(processor => processor.condition(ev) && processor.action(ev));
	};
}

export const Module_KeyBinder = new Module_KeyBinder_Class();