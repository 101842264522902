// import {ApiBinder_PurgeSyncItems, ApiBinder_ReSyncItems, DefAPI_PurgeSync, DefAPI_ReSync} from "@app/shared";
import {ApiDef_ShopifyItemSync, ApiStruct_ShopifyItemSync} from '@app/shared';
import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';
import {DB_Object, Default_UniqueKey, PreDB} from '@nu-art/ts-common';
import {ModuleFE_Stores} from '@modules/index';
import {ApiDefCaller} from '@nu-art/thunderstorm';
import {apiWithBody, ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {DBDef} from '@nu-art/ts-common';


export abstract class BaseDB_ShopifyApiCallerV2<DBType extends DB_Object, Ks extends keyof PreDB<DBType> = Default_UniqueKey>
	extends ModuleFE_BaseApi<DBType, Ks>
	implements ApiDefCaller<ApiStruct_ShopifyItemSync<DBType>> {

	_v1: ApiDefCaller<ApiStruct_ShopifyItemSync<DBType>>['_v1'];

	constructor(dbDef: DBDef<DBType, Ks>, defaultDispatcher: ThunderDispatcher<any, string, ApiCallerEventType<DBType>>) {
		super(dbDef, defaultDispatcher);

		const sync = apiWithBody(ApiDef_ShopifyItemSync<DBType, Ks>(dbDef)._v1.shopify.sync, this.onShopifyItemSyncCompleted);
		this._v1 = {
			shopify: {
				sync: (body: { itemIds?: string | string[], fromShopify?: boolean }) => {
					const itemIds = typeof body?.itemIds === 'string' ? [body.itemIds] : body?.itemIds;
					return sync({itemIds, fromShopify: body?.fromShopify, storeId: ModuleFE_Stores.getDefaultStore()._id});
				}
			}
		} as ApiDefCaller<ApiStruct_ShopifyItemSync<DBType>>['_v1'];
	}

	onShopifyItemSyncCompleted = async (response: DBType[]) => {
		return this.onEntriesUpdated(response);
	};
}
