import * as React from 'react';
import {DB_Object, Default_UniqueKey, PreDB} from '@nu-art/ts-common';
import {DialogRenderers, Props_UpsertDbItemDialog} from './types';
import {ModuleFE_Dialog, performAction, State_TSDialog, TS_Dialog} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';


export class Dialog_UpdateDbItem<T extends DB_Object, Ks extends keyof PreDB<T> = Default_UniqueKey, State extends State_TSDialog = State_TSDialog>
	extends TS_Dialog<Props_UpsertDbItemDialog<T, Ks, State>, State> {

	static defaultProps = {
		dialogId: 'dialog-update-item'
	};

	static show<T extends DB_Object, Ks extends keyof PreDB<T> = Default_UniqueKey, State extends State_TSDialog = State_TSDialog>(item: PreDB<T>, module: ModuleFE_BaseApi<T, Ks>, renderers: DialogRenderers<T, State>, action?: () => void) {
		ModuleFE_Dialog.show(<Dialog_UpdateDbItem<T, Ks, State> item={item} module={module} renderers={renderers} action={action}/>);
	}

	protected renderTitle = () => `${this.props.item._id ? 'Create' : 'Update'} ${this.props.renderers.title(this.props.item, this.state)}`;

	// @ts-ignore
	protected renderBody = () => this.props.renderers.body(this.props.item, this.state, this.setState);

	private upsert = () => {
		this.performAction(async () => {
			const title = this.props.renderers.title(this.props.item, this.state);
			await performAction(async () => {
				const item = await this.props.module.v1.upsert(this.props.item).executeSync();
				await this.props?.onCompleted?.(item);
				this.closeDialog();
			}, {
				type: 'toast',
				failContent: `Error ${this.props.item._id ? 'creating' : 'updating'} ${title}`,
				successContent: `${title} was ${this.props.item._id ? 'Created' : 'Updated'}`
			});
		});
	};

	buttons = () => ({
		left: [TS_Dialog.Button_Cancel],
		right: [{
			content: this.props.item._id ? 'Create' : 'Update',
			onClick: this.upsert,
			renderer: TS_Dialog.busyButton
		}]
	});

}