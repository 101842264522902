"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Input = void 0;
const React = require("react");
const tools_1 = require("../../utils/tools");
const TS_BaseInput_1 = require("./TS_BaseInput");
require("./TS_Input.scss");
/**
 * A better way to capture user input
 *
 * <code>
 * 		<input className="ts-input"/>
 * </code>
 *
 */
class TS_Input extends TS_BaseInput_1.TS_BaseInput {
    constructor() {
        super(...arguments);
        this.onKeyDown = (ev) => {
            var _a, _b, _c;
            let value = ev.currentTarget.value;
            if (!(ev.shiftKey || ev.altKey || ev.ctrlKey || ev.metaKey)) {
                if (ev.key === 'Enter') {
                    ev.persist();
                    if (this.props.trim)
                        value = value.trim();
                    //@ts-ignore - despite what typescript says, ev.target does have a blur function.
                    ev.target.blur();
                    if (this.props.onAccept) {
                        if (value !== this.props.value)
                            this.props.onAccept(value, ev);
                        ev.stopPropagation();
                    }
                }
                if (ev.key === 'Escape' && this.props.onCancel) {
                    this.props.onCancel();
                    ev.stopPropagation();
                }
            }
            if (ev.key === 'Enter' && ((_a = this.props.forceAcceptKeys) === null || _a === void 0 ? void 0 : _a.find(key => ev[key])))
                if (this.props.onAccept) {
                    if (value !== this.props.value)
                        this.props.onAccept(value, ev);
                    ev.stopPropagation();
                }
            (_c = (_b = this.props).onKeyDown) === null || _c === void 0 ? void 0 : _c.call(_b, ev);
        };
    }
    render() {
        const _a = this.props, { onAccept, trim, forceAcceptKeys, focus } = _a, props = __rest(_a, ["onAccept", "trim", "forceAcceptKeys", "focus"]);
        return React.createElement("input", Object.assign({}, props, { autoFocus: focus, ref: props.innerRef, onBlur: (event) => {
                var _a;
                const value = event.target.value;
                this.setState({ value });
                (_a = props.onBlur) === null || _a === void 0 ? void 0 : _a.call(props, value, event);
            }, name: props.name || props.id, className: (0, tools_1._className)('ts-input', props.disabled ? 'disabled' : undefined, props.className), value: this.state.value, onChange: this.changeValue, onKeyDown: this.onKeyDown, autoComplete: props.autoComplete ? 'on' : 'off' }));
    }
}
TS_Input.defaultProps = {
    forceAcceptKeys: ['ctrlKey', 'metaKey']
};
exports.TS_Input = TS_Input;
