import {PgDev_WhatsApp} from './PgDev_WhatsApp';
import {PgDev_Palette} from './PgDev_Palette';
import {PgDev_IndexDB} from './PgDev_IndexDB';
import {PgDev_Toaster} from './PgInfra_Toaster';
import {PgDev_Locales} from './PgDev_Locales';
import {PgDev_Colors} from './PgDev_Colors';
import {PgDev_Fonts} from './PgDev_Fonts';
import {PgDev_Icons} from './PgDev_Icons';
import {PgDev_DraftJs} from './PgDev_DraftJs';
import {PgDev_GraphQL} from './PgDev_GraphQL';
import {ATS_Admin} from './ATS_Admin';
import {PgDev_Push} from './PgDev_Push';
import {PgDev_Analytics} from './PgDev_Analytics';
import {AppToolsScreen, ATS_ActionProcessor} from '@nu-art/thunderstorm/frontend';
import {ATS_CollectionUpgrades, ATS_SyncEnvironment, ATS_SyncEnvironmentV2} from '@nu-art/thunderstorm/frontend';
import {ATS_Permissions} from '@nu-art/permissions/frontend';
import {ATS_Translation} from '@infra/translations/frontend/pages/ATS_Translation/ATS_Translation';
import {ATS_SyncCollectionToEnv} from './ATS_SyncCollectionToEnv';


export const PlaygroundScreens: AppToolsScreen[] = [
	PgDev_WhatsApp,
	PgDev_Palette,
	PgDev_IndexDB,
	PgDev_Toaster,
	ATS_Permissions.screen,
	ATS_Translation.screen,
	ATS_Admin.screen,
	ATS_SyncEnvironment.screen,
	ATS_SyncEnvironmentV2.screen,
	ATS_ActionProcessor.screen,
	ATS_CollectionUpgrades.screen,
	ATS_SyncCollectionToEnv.screen,
	PgDev_Locales,
	PgDev_Colors,
	PgDev_Fonts,
	PgDev_Icons,
	PgDev_DraftJs,
	PgDev_GraphQL,
	PgDev_Push,
	PgDev_Analytics,
];