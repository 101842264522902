"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_ProductVariantConfig = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("../types");
const Validator_VariantConfig = Object.assign(Object.assign({}, types_1.ShopifyItem_validator), { label: ts_common_1.tsValidateMustExist, isMain: (0, ts_common_1.tsValidateBoolean)(false), description: ts_common_1.tsValidateMustExist, vendorIds: ts_common_1.tsValidateMustExist, optionIds: ts_common_1.tsValidateMustExist });
exports.DBDef_ProductVariantConfig = {
    validator: Validator_VariantConfig,
    dbName: 'variant-configs',
    entityName: 'variant-config',
    uniqueKeys: ['shopifyId', 'storeId'],
    versions: ['1.0.2', '1.0.1', '1.0.0']
};
