"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_ShipmentStatus = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ts_common_2 = require("@nu-art/ts-common");
const types_1 = require("./types");
const Validator_ShipmentStatus = {
    shipmentId: ts_common_1.tsValidateUniqueId,
    timestamp: (0, ts_common_2.tsValidateIsInRange)([[0, Number.MAX_VALUE]]),
    status: (0, ts_common_2.tsValidateValue)((0, ts_common_2._values)(types_1.ShipmentStatus)),
};
exports.DBDef_ShipmentStatus = {
    validator: Validator_ShipmentStatus,
    dbName: 'shipment-status',
    entityName: 'shipment-status',
};
