"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThunderstormDefaultApp = void 0;
const ComponentSync_1 = require("./ComponentSync");
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const Thunder_1 = require("./Thunder");
const tools_1 = require("../utils/tools");
const ModuleFE_RoutingV2_1 = require("../modules/routing/ModuleFE_RoutingV2");
const TS_Dialog_1 = require("../components/TS_Dialog");
const TS_MouseInteractivity_1 = require("../components/TS_MouseInteractivity");
const TS_Toaster_1 = require("../components/TS_Toaster");
const TS_Notifications_1 = require("../components/TS_Notifications");
const TS_MemoryMonitor_1 = require("../components/TS_MemoryMonitor");
class ThunderstormDefaultApp extends ComponentSync_1.ComponentSync {
    static blockEvent(ev) {
        ev.preventDefault();
        ev.stopPropagation();
    }
    getAdditionalOverlayRenderer(overlay) {
        const Renderer = overlay;
        return React.createElement(Renderer, { key: (0, ts_common_1.generateUUID)() });
    }
    deriveStateFromProps(nextProps) {
        return {};
    }
    render() {
        var _a;
        // @ts-ignore
        const blockRightClick = !Thunder_1.Thunder.getInstance().config.isDebug;
        return (React.createElement("div", { id: "app", onDrop: tools_1.stopPropagation, onDragOver: tools_1.stopPropagation, onContextMenu: blockRightClick ? tools_1.stopPropagation : undefined },
            ModuleFE_RoutingV2_1.ModuleFE_RoutingV2.generateRoutes(this.props.rootRoute),
            React.createElement(TS_Dialog_1.TS_DialogOverlay, null),
            React.createElement(TS_MouseInteractivity_1.TS_PopUp, null),
            React.createElement(TS_MouseInteractivity_1.TS_ToolTip, null),
            React.createElement(TS_Toaster_1.TS_ToastOverlay, null),
            React.createElement(TS_Notifications_1.TS_Notifications, null),
            React.createElement(TS_MemoryMonitor_1.TS_MemoryMonitor, null), (_a = this.props.additionalOverlays) === null || _a === void 0 ? void 0 :
            _a.map(Overlay => this.getAdditionalOverlayRenderer(Overlay))));
    }
}
exports.ThunderstormDefaultApp = ThunderstormDefaultApp;
