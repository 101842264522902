import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {apiWithQuery, ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {ApiDef_ShippingOptions, ApiStruct_ShippingOptions, DB_OrderShippingMethod, DBDef_OrderShippingMethod} from '@app/shared';
import {ApiDefCaller} from '@nu-art/thunderstorm';


export interface OnShippingOptionUpdated {
	__onShippingOptionUpdated: (...params: ApiCallerEventType<DB_OrderShippingMethod>) => void;
}

export const dispatch_onShippingOptionListChanged = new ThunderDispatcher<OnShippingOptionUpdated, '__onShippingOptionUpdated'>(
	'__onShippingOptionUpdated');

export class ModuleFE_ShippingOption_Class
	extends ModuleFE_BaseApi<DB_OrderShippingMethod, 'storeId' | 'label'>
	implements ApiDefCaller<ApiStruct_ShippingOptions> {

	shopify: ApiDefCaller<ApiStruct_ShippingOptions>['shopify'];

	constructor() {
		super(DBDef_OrderShippingMethod, dispatch_onShippingOptionListChanged);
		this.shopify = {
			sync: apiWithQuery(ApiDef_ShippingOptions.shopify.sync, this.onShippingOptionsSyncCompleted)
		};
	}

	private async onShippingOptionsSyncCompleted() {
		this.v1.sync().execute();
	}
}

export const ModuleFE_ShippingMethod = new ModuleFE_ShippingOption_Class();
export const ModuleFE_ShippingMethod_ = ModuleFE_ShippingMethod as unknown as ModuleFE_BaseApi<any, any>;

