"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATS_ActionProcessor = void 0;
const React = require("react");
require("./ATS_ActionProcessor.scss");
const ts_common_1 = require("@nu-art/ts-common");
const dialogs_1 = require("../dialogs");
const ComponentAsync_1 = require("../../core/ComponentAsync");
const TS_AppTools_1 = require("../../components/TS_AppTools");
const ModuleFE_ActionProcessor_1 = require("../../modules/action-processor/ModuleFE_ActionProcessor");
const TS_Notifications_1 = require("../../components/TS_Notifications");
const Layouts_1 = require("../../components/Layouts");
const TS_BusyButton_1 = require("../../components/TS_BusyButton");
class ATS_ActionProcessor extends ComponentAsync_1.ComponentAsync {
    constructor() {
        super(...arguments);
        // ######################### Logic #########################
        this.onButtonClick = async (action) => {
            return dialogs_1.Dialog_ActionProcessorConfirmation.show(action, async () => {
                await (0, TS_Notifications_1.genericNotificationAction)(async () => {
                    this.setState({ actionsInProgress: [...this.state.actionsInProgress, action.key] });
                    await ModuleFE_ActionProcessor_1.ModuleFE_ActionProcessor.vv1.execute({ key: action.key }).executeSync();
                }, { inProgress: `Executing ${action.key}`, success: `${action.key} - Success`, failed: `${action.key} - Failure` });
                this.setState({ actionsInProgress: this.state.actionsInProgress.filter(i => i !== action.key) });
            });
        };
    }
    // ######################### Life Cycle #########################
    async deriveStateFromProps(nextProps) {
        var _b;
        const state = this.state ? Object.assign({}, this.state) : {};
        state.actions = await ModuleFE_ActionProcessor_1.ModuleFE_ActionProcessor.vv1.list({}).executeSync();
        (_b = state.actionsInProgress) !== null && _b !== void 0 ? _b : (state.actionsInProgress = []);
        return state;
    }
    // ######################### Render #########################
    renderButtons(actionGroup) {
        return React.createElement(Layouts_1.LL_H_C, { className: 'action-group__buttons' }, actionGroup.map(action => {
            return React.createElement(TS_BusyButton_1.TS_BusyButton, { key: action.key, onClick: () => this.onButtonClick(action), disabled: this.state.actionsInProgress.includes(action.key) }, action.key.replace(/-/g, ' '));
        }));
    }
    renderButtonGroups() {
        var _b;
        const groups = (_b = this.state.actions) === null || _b === void 0 ? void 0 : _b.reduce((acc, curr) => {
            if (!acc[curr.group])
                acc[curr.group] = [];
            acc[curr.group].push(curr);
            return acc;
        }, {});
        if (!groups)
            return '';
        return React.createElement(Layouts_1.LL_V_L, { className: 'action-groups' }, (0, ts_common_1._keys)(groups).map(key => {
            return React.createElement(Layouts_1.LL_V_L, { className: 'action-group', key: key },
                React.createElement("div", { className: 'action-group__title' }, key),
                this.renderButtons(groups[key]));
        }));
    }
    render() {
        return React.createElement("div", { className: 'refactoring-actions-page' },
            TS_AppTools_1.TS_AppTools.renderPageHeader('Refactoring Actions'),
            this.renderButtonGroups());
    }
}
_a = ATS_ActionProcessor;
ATS_ActionProcessor.screen = { name: 'Refactoring Actions', key: 'refactoring-actions', renderer: _a, group: 'TS Dev Tools' };
exports.ATS_ActionProcessor = ATS_ActionProcessor;
