"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleNodeRenderer = exports.SimpleTreeNodeRenderer = void 0;
const React = require("react");
const BaseRenderer_1 = require("../adapter/BaseRenderer");
class SimpleTreeNodeRenderer extends BaseRenderer_1.BaseNodeRenderer {
    renderCollapse() {
        let toDisplay;
        if (typeof this.props.item !== 'object')
            toDisplay = '';
        else if (Object.keys(this.props.item).length === 0)
            toDisplay = '';
        else if (this.props.node.expanded)
            toDisplay = '-';
        else
            toDisplay = '+';
        return React.createElement("div", { className: `clickable`, style: { width: '15px' } }, toDisplay);
    }
    renderItem(item) {
        return (React.createElement("div", { className: "ll_h_c" },
            this.renderCollapse(),
            React.createElement(SimpleNodeRenderer, Object.assign({}, this.props))));
    }
}
exports.SimpleTreeNodeRenderer = SimpleTreeNodeRenderer;
class SimpleNodeRenderer extends React.Component {
    render() {
        let label;
        const item = this.props.item;
        if (typeof item !== 'object')
            label = ` : ${item}`;
        else if (Object.keys(item).length === 0)
            label = ' : {}';
        else
            label = '';
        return ('propKey' || 'root') + label;
    }
}
exports.SimpleNodeRenderer = SimpleNodeRenderer;
