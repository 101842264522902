"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentSync = void 0;
/**
 * Created by tacb0ss on 28/07/2018.
 */
const ComponentBase_1 = require("./ComponentBase");
class ComponentSync extends ComponentBase_1.BaseComponent {
    _deriveStateFromProps(nextProps, state) {
        this.logVerbose('Deriving state from props');
        const _state = this.deriveStateFromProps(nextProps, state);
        this.mounted && _state && this.setState(_state);
        return _state;
    }
}
exports.ComponentSync = ComponentSync;
