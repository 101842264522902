"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Slider = void 0;
const React = require("react");
require("./TS_Slider.scss");
const ComponentSync_1 = require("../../core/ComponentSync");
const tools_1 = require("../../utils/tools");
const Layouts_1 = require("../Layouts");
class TS_Slider extends ComponentSync_1.ComponentSync {
    constructor() {
        // ######################### Life Cycle #########################
        super(...arguments);
        this.onValueChange = (e) => {
            let value = e.target.valueAsNumber;
            if (isNaN(value))
                value = undefined;
            this.setState({ value }, () => {
                var _a, _b;
                (_b = (_a = this.props).onValueChanged) === null || _b === void 0 ? void 0 : _b.call(_a, value !== null && value !== void 0 ? value : this.props.min);
            });
        };
        this.onMouseUp = (e) => {
            var _a, _b, _c;
            (_b = (_a = this.props).onValueSet) === null || _b === void 0 ? void 0 : _b.call(_a, (_c = this.state.value) !== null && _c !== void 0 ? _c : this.props.min);
        };
        this.calculateBGSize = () => {
            var _a;
            return ((((_a = this.state.value) !== null && _a !== void 0 ? _a : this.props.min) - this.props.min) * 100) / (this.props.max - this.props.min);
        };
    }
    deriveStateFromProps(nextProps) {
        return {
            value: nextProps.value === undefined ? this.getValue() : nextProps.value
        };
    }
    // ######################### Logic #########################
    getValue() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : this.props.startValue) !== null && _c !== void 0 ? _c : (this.props.min + this.props.max) / 2;
    }
    // ######################### Render #########################
    renderCurrentValue() {
        return React.createElement("input", { className: 'ts-slider__current-value', type: 'number', value: this.state.value, onChange: this.onValueChange });
    }
    renderSliderInput() {
        var _a;
        return React.createElement("input", { type: 'range', value: (_a = this.state.value) !== null && _a !== void 0 ? _a : this.props.min, min: this.props.min, max: this.props.max, onChange: this.onValueChange, onMouseUp: this.onMouseUp, style: { backgroundSize: `${this.calculateBGSize()}% 100%` } });
    }
    render() {
        const className = (0, tools_1._className)('ts-slider', this.props.className);
        return React.createElement(Layouts_1.LL_H_C, { className: className },
            this.renderSliderInput(),
            this.renderCurrentValue());
    }
}
exports.TS_Slider = TS_Slider;
