"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModulePack_ThunderstormFE = void 0;
const ModuleFE_Thunderstorm_1 = require("../modules/ModuleFE_Thunderstorm");
const ModuleFE_XHR_1 = require("../modules/http/ModuleFE_XHR");
const ModuleFE_Toaster_1 = require("../component-modules/ModuleFE_Toaster");
const ModuleFE_Dialog_1 = require("../component-modules/ModuleFE_Dialog");
const ModuleFE_RoutingV2_1 = require("../modules/routing/ModuleFE_RoutingV2");
const ModuleFE_BrowserHistory_1 = require("../modules/ModuleFE_BrowserHistory");
const ModuleFE_LocalStorage_1 = require("../modules/ModuleFE_LocalStorage");
const ModuleFE_Window_1 = require("../modules/ModuleFE_Window");
const ModuleFE_Notifications_1 = require("../component-modules/ModuleFE_Notifications");
const ModuleFE_ActionProcessor_1 = require("../modules/action-processor/ModuleFE_ActionProcessor");
exports.ModulePack_ThunderstormFE = [
    ModuleFE_Thunderstorm_1.ModuleFE_Thunderstorm,
    ModuleFE_XHR_1.ModuleFE_XHR,
    ModuleFE_Toaster_1.ModuleFE_Toaster,
    ModuleFE_Dialog_1.ModuleFE_Dialog,
    ModuleFE_RoutingV2_1.ModuleFE_RoutingV2,
    ModuleFE_BrowserHistory_1.ModuleFE_BrowserHistory,
    ModuleFE_LocalStorage_1.ModuleFE_LocalStorage,
    ModuleFE_Window_1.ModuleFE_Window,
    ModuleFE_Notifications_1.ModuleFE_Notifications,
    ModuleFE_ActionProcessor_1.ModuleFE_ActionProcessor,
];
