import {ApiDef_Translations, ApiStruct_Translations, DB_TranslationEntry, DBDef_TranslationEntry, IndexKey_Translation_GroupId} from '@app/shared';
import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';
import {ApiDefCaller} from '@nu-art/thunderstorm';

import {apiWithBody, ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {escape_RegExp, ThisShouldNotHappenException} from '@nu-art/ts-common';


export interface OnTranslationUpdated {
	__onTranslationUpdated: (...params: ApiCallerEventType<DB_TranslationEntry>) => void;
}

export const dispatch_onTranslationListChanged = new ThunderDispatcher<OnTranslationUpdated, '__onTranslationUpdated'>('__onTranslationUpdated');

export class ModuleFE_Translations_Class
	extends ModuleFE_BaseApi<DB_TranslationEntry, 'sharedId' | 'locale'>
	implements ApiDefCaller<ApiStruct_Translations> {

	_v1: ApiDefCaller<ApiStruct_Translations>['_v1'];

	constructor() {
		super(DBDef_TranslationEntry, dispatch_onTranslationListChanged);
		this._v1 = {
			googleTranslate: apiWithBody(ApiDef_Translations._v1.googleTranslate)
		};

	}

	async translateCountry(country: string, toLocale: string, fromLocale = 'en') {
		const matches = await this.IDB.filter(item => {
			if (!item.regexp)
				return false;

			try {
				const regexp = new RegExp(escape_RegExp(item.regexp || item.content));
				return !!country?.match(regexp);
			} catch (e: any) {
				this.logError(e);
				return false;
			}
		}, {
			indexKey: IndexKey_Translation_GroupId,
			query: ['countries', fromLocale]
		});

		if (matches.length > 1)
			throw new ThisShouldNotHappenException(`Too many matches (${fromLocale}:${matches.length}) for country: ${country}`);

		if (matches.length === 0) {
			this.logWarning(`No result match country (${fromLocale}): ${country}`);
			return country;
		}

		const translatedMatches = await this.IDB.query([matches[0].sharedId, toLocale]);

		if (translatedMatches.length > 1)
			throw new ThisShouldNotHappenException(`Too many translations (${toLocale}:${matches.length}) for country: ${country}`);

		if (translatedMatches.length === 0)
			throw new ThisShouldNotHappenException(`No translation for country (${toLocale}): ${country}`);

		return translatedMatches[0].content;
	}

	googleTranslate(toTranslate: string[] | string, sourceLocale: string, targetLocale: string) {
		if (typeof toTranslate === 'string')
			toTranslate = [toTranslate];

		return this._v1.googleTranslate({toTranslate, sourceLocale, targetLocale}).executeSync();
		// call backend to translate
	}
}

export const ModuleFE_Translations = new ModuleFE_Translations_Class();

