"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_BackupDocs = exports.Validator_BackupDoc = void 0;
const ts_common_1 = require("@nu-art/ts-common");
exports.Validator_BackupDoc = {
    timestamp: (0, ts_common_1.tsValidateTimestamp)(),
    backupPath: (0, ts_common_1.tsValidateString)(),
    firebasePath: (0, ts_common_1.tsValidateString)(),
    metadataPath: (0, ts_common_1.tsValidateString)(),
    metadata: {
        timestamp: (0, ts_common_1.tsValidateTimestamp)(),
        collectionsData: (0, ts_common_1.tsValidateArray)({
            collectionName: (0, ts_common_1.tsValidateString)(),
            numOfDocs: (0, ts_common_1.tsValidateNumber)(),
            version: (0, ts_common_1.tsValidateString)()
        })
    }
};
exports.DBDef_BackupDocs = {
    validator: exports.Validator_BackupDoc,
    dbName: 'firestore-backup-status-v2',
    entityName: 'firestoreBackupStatus',
    versions: ['1.0.0'],
};
