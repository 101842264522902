"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_MemoryMonitor = void 0;
/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const React = require("react");
require("./TS_MemoryMonitor.scss");
class MemoryMonitor extends React.Component {
    componentDidMount() {
        this.id = setInterval(() => {
            this.forceUpdate();
        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.id);
    }
    render() {
        // @ts-ignore
        const mem = window.performance.memory;
        if (!mem)
            return '';
        return React.createElement("div", null,
            Math.round(mem.usedJSHeapSize / 1024 / 1024),
            " / ",
            Math.round(mem.totalJSHeapSize / 1024 / 1024),
            "mb");
    }
}
class TS_MemoryMonitor extends React.Component {
    render() {
        return React.createElement("div", { className: "ts-memory-monitor" },
            React.createElement("div", { className: "ts-memory-monitor__version" }, this.props.labelResolver(process.env.appEnv, process.env.appVersion)),
            React.createElement(MemoryMonitor, null));
    }
}
TS_MemoryMonitor.defaultProps = {
    labelResolver: (buildMode, version) => {
        return `${process.env.appEnv}-${process.env.appVersion}`;
    }
};
exports.TS_MemoryMonitor = TS_MemoryMonitor;
