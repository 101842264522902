"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports._logger_indentNewLineBy = exports._logger_logException = exports._logger_convertLogParamsToStrings = exports._logger_logObject = void 0;
/**
 * Created by TacB0sS on 3/16/17.
 */
const array_tools_1 = require("../../utils/array-tools");
const tools_1 = require("../../utils/tools");
function _logger_logObject(instance) {
    return (0, tools_1.__stringify)(instance, true);
}
exports._logger_logObject = _logger_logObject;
function _logger_convertLogParamsToStrings(params) {
    return params.map(toLog => {
        if (typeof toLog === 'undefined')
            return 'undefined';
        if (toLog === null)
            return 'null';
        if (typeof toLog === 'string')
            return toLog;
        if (typeof toLog === 'number')
            return `${toLog}`;
        // @ts-ignore
        if (toLog.stack)
            return _logger_logException(toLog);
        return (0, tools_1.__stringify)(toLog, true);
    });
}
exports._logger_convertLogParamsToStrings = _logger_convertLogParamsToStrings;
function _logger_logException(error, fullStack = '') {
    let toPrint = '';
    let errorMessage;
    let isCustomException = false;
    if (error.stack) {
        const stackAsList = error.stack.split('\n');
        errorMessage = stackAsList[0];
        (0, array_tools_1.removeItemFromArray)(stackAsList, errorMessage);
        toPrint = stackAsList.reduce((toRet, stacktrace) => {
            if (fullStack.indexOf(stacktrace) !== -1)
                return toRet;
            return toRet + stacktrace + '\n';
        }, toPrint);
        isCustomException = toPrint.indexOf('CustomException') !== -1;
        toPrint = toPrint.replace(/\s+at.*?CustomException.*?\n/, '\n');
        toPrint = toPrint.replace(/\s+at.*?new\s+(.*?) \(.*?\n/, `${fullStack.length === 0 ? '' : '\nCaused by '}$1: ${errorMessage.replace('Error: ', '')}\n`);
        if (!isCustomException && errorMessage)
            toPrint = errorMessage + '\n' + toPrint;
    }
    const cause = error.cause;
    if (cause) {
        let causeStack = _logger_logException(cause, toPrint);
        causeStack = causeStack.replace(`Error: ${cause.message}`, `${cause.message}`);
        toPrint += `${causeStack}`;
    }
    return toPrint;
}
exports._logger_logException = _logger_logException;
function _logger_indentNewLineBy(linePrefix, input) {
    return linePrefix + input.replace(/\n/g, `\n${linePrefix}`);
}
exports._logger_indentNewLineBy = _logger_indentNewLineBy;
