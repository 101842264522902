"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogClient_MemBuffer = void 0;
const LogClient_BaseRotate_1 = require("./LogClient_BaseRotate");
class LogClient_MemBuffer extends LogClient_BaseRotate_1.LogClient_BaseRotate {
    constructor(name, maxBuffers = 10, maxBufferSize = 1024 * 1024) {
        super(name, maxBuffers, maxBufferSize);
        this.buffers = [""];
    }
    printLogMessage(log) {
        this.buffers[0] += log;
    }
    cleanup() {
    }
    rotateBuffer(fromIndex, toIndex) {
        this.buffers[toIndex] = this.buffers[fromIndex];
    }
    prepare() {
        this.buffers[0] = "";
    }
}
exports.LogClient_MemBuffer = LogClient_MemBuffer;
