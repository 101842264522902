"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DB_Object_validator = exports.tsValidator_AuditableV2 = exports.tsValidator_InternationalPhoneNumber = exports.tsValidator_LowerUpperStringWithDashesAndUnderscore = exports.tsValidator_LowerUpperStringWithSpaces = exports.tsValidator_LowercaseStringWithDashes = exports.tsValidateNameWithDashesAndDotsAndNumbers = exports.tsValidateNameWithDashesAndDots = exports.tsValidator_JavaObjectMemberName = exports.tsValidateStringAndNumbersWithDashes = exports.tsValidateStringWithDashes = exports.tsValidateOptionalId = exports.tsValidate_optionalArrayOfUniqueIds = exports.tsValidator_arrayOfUniqueIds = exports.tsValidateUniqueId = exports.tsValidateVersion = exports.tsValidateGeneralUrl = exports.tsValidateBucketUrl = exports.tsValidateEmail = exports.tsValidateId = exports.dbIdLength = exports.tsValidateOptional = exports.tsValidateMustExist = exports.tsValidator_colorHex = exports.tsValidateMD5 = exports.tsValidator_nonMandatoryString = exports.tsValidate_OptionalArray = void 0;
const validator_core_1 = require("./validator-core");
const type_validators_1 = require("./type-validators");
const tsValidate_OptionalArray = (validator) => (0, type_validators_1.tsValidateArray)(validator, false);
exports.tsValidate_OptionalArray = tsValidate_OptionalArray;
exports.tsValidator_nonMandatoryString = (0, type_validators_1.tsValidateString)(-1, false);
const tsValidateMD5 = (mandatory = true) => {
    return (0, type_validators_1.tsValidateRegexp)(/[a-zA-Z\d]{32}/, mandatory);
};
exports.tsValidateMD5 = tsValidateMD5;
exports.tsValidator_colorHex = (0, type_validators_1.tsValidateRegexp)(/^#(?:[a-fA-F0-9]{8}|[a-fA-F0-9]{6}|[a-fA-F0-9]{3,4})$/);
exports.tsValidateMustExist = (0, validator_core_1.tsValidateExists)();
exports.tsValidateOptional = (0, validator_core_1.tsValidateExists)(false);
exports.dbIdLength = 32;
const tsValidateId = (length, mandatory = true) => (0, type_validators_1.tsValidateRegexp)(new RegExp(`^[0-9a-f]{${length}}$`), mandatory);
exports.tsValidateId = tsValidateId;
exports.tsValidateEmail = (0, type_validators_1.tsValidateRegexp)(/[a-z0-9](?:\.?[a-z0-9!#$%&'*+/=?^_`{|}~\-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9_-]*[a-z0-9])?/);
const tsValidateBucketUrl = (mandatory) => (0, type_validators_1.tsValidateRegexp)(/gs?:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, mandatory);
exports.tsValidateBucketUrl = tsValidateBucketUrl;
const tsValidateGeneralUrl = (mandatory) => (0, type_validators_1.tsValidateRegexp)(/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/, mandatory);
exports.tsValidateGeneralUrl = tsValidateGeneralUrl;
exports.tsValidateVersion = (0, type_validators_1.tsValidateRegexp)(/\d{1,3}\.\d{1,3}\.\d{1,3}/);
exports.tsValidateUniqueId = (0, exports.tsValidateId)(exports.dbIdLength);
exports.tsValidator_arrayOfUniqueIds = (0, type_validators_1.tsValidateArray)(exports.tsValidateUniqueId);
exports.tsValidate_optionalArrayOfUniqueIds = (0, exports.tsValidate_OptionalArray)(exports.tsValidateUniqueId);
exports.tsValidateOptionalId = (0, exports.tsValidateId)(exports.dbIdLength, false);
exports.tsValidateStringWithDashes = (0, type_validators_1.tsValidateRegexp)(/^[A-Za-z-]+$/);
exports.tsValidateStringAndNumbersWithDashes = (0, type_validators_1.tsValidateRegexp)(/^[0-9A-Za-z-]+$/);
exports.tsValidator_JavaObjectMemberName = (0, type_validators_1.tsValidateRegexp)(/^[a-z][a-zA-Z0-9]+$/);
exports.tsValidateNameWithDashesAndDots = (0, type_validators_1.tsValidateRegexp)(/^[a-z-.]+$/);
exports.tsValidateNameWithDashesAndDotsAndNumbers = (0, type_validators_1.tsValidateRegexp)(/^[a-z0-9-.]+$/);
exports.tsValidator_LowercaseStringWithDashes = (0, type_validators_1.tsValidateRegexp)(/^[a-z-.]+$/);
exports.tsValidator_LowerUpperStringWithSpaces = (0, type_validators_1.tsValidateRegexp)(/^[A-Za-z ]+$/);
exports.tsValidator_LowerUpperStringWithDashesAndUnderscore = (0, type_validators_1.tsValidateRegexp)(/^[A-Za-z-_]+$/);
exports.tsValidator_InternationalPhoneNumber = (0, type_validators_1.tsValidateRegexp)(/^\+(?:[0-9] ?){6,14}[0-9]$/);
exports.tsValidator_AuditableV2 = { _auditorId: (0, type_validators_1.tsValidateString)() };
exports.DB_Object_validator = {
    _id: exports.tsValidateUniqueId,
    _v: exports.tsValidateVersion,
    _originDocId: exports.tsValidateOptionalId,
    __hardDelete: (0, type_validators_1.tsValidateBoolean)(false),
    __created: (0, type_validators_1.tsValidateTimestamp)(),
    __updated: (0, type_validators_1.tsValidateTimestamp)(),
};
