"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentStatus = void 0;
var ShipmentStatus;
(function (ShipmentStatus) {
    ShipmentStatus["Sent"] = "sent";
    ShipmentStatus["InTransit"] = "In transit";
    ShipmentStatus["InCustoms"] = "In customs";
    ShipmentStatus["ReleasedFromCustoms"] = "Released from customs";
    ShipmentStatus["Arrived"] = "Arrived";
})(ShipmentStatus = exports.ShipmentStatus || (exports.ShipmentStatus = {}));
