"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.dispatch_onApplicationException = exports.dispatch_onApplicationNotification = exports.ServerErrorSeverity_Ordinal = exports.ServerErrorSeverity = void 0;
const dispatcher_1 = require("./dispatcher");
var ServerErrorSeverity;
(function (ServerErrorSeverity) {
    ServerErrorSeverity["Debug"] = "Debug";
    ServerErrorSeverity["Info"] = "Info";
    ServerErrorSeverity["Warning"] = "Warning";
    ServerErrorSeverity["Error"] = "Error";
    ServerErrorSeverity["Critical"] = "Critical";
})(ServerErrorSeverity = exports.ServerErrorSeverity || (exports.ServerErrorSeverity = {}));
exports.ServerErrorSeverity_Ordinal = [
    ServerErrorSeverity.Debug,
    ServerErrorSeverity.Info,
    ServerErrorSeverity.Warning,
    ServerErrorSeverity.Error,
    ServerErrorSeverity.Critical
];
exports.dispatch_onApplicationNotification = new dispatcher_1.Dispatcher('__processApplicationNotification');
exports.dispatch_onApplicationException = new dispatcher_1.Dispatcher('__processApplicationException');
