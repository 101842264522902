"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_TempAssets = exports.Validator_TempAsset = exports.DBDef_Assets = exports.validateName = void 0;
const ts_common_1 = require("@nu-art/ts-common");
exports.validateName = (0, ts_common_1.tsValidateRegexp)(/^.{3,}$/);
const Validator_Asset = {
    timestamp: (0, ts_common_1.tsValidateNumber)(),
    name: exports.validateName,
    ext: (0, ts_common_1.tsValidateExists)(true),
    md5Hash: (0, ts_common_1.tsValidateExists)(false),
    feId: (0, ts_common_1.tsValidateExists)(true),
    mimeType: (0, ts_common_1.tsValidateExists)(true),
    key: (0, ts_common_1.tsValidateExists)(true),
    path: (0, ts_common_1.tsValidateExists)(true),
    _audit: (0, ts_common_1.tsValidateAudit)(),
    bucketName: (0, ts_common_1.tsValidateExists)(true),
    public: (0, ts_common_1.tsValidateBoolean)(false),
    metadata: (0, ts_common_1.tsValidateDynamicObject)(ts_common_1.tsValidateMustExist, (0, ts_common_1.tsValidateString)(), false),
    signedUrl: (0, ts_common_1.tsValidateNonMandatoryObject)({
        url: (0, ts_common_1.tsValidateString)(),
        validUntil: (0, ts_common_1.tsValidateNumber)()
    })
};
exports.DBDef_Assets = {
    upgradeChunksSize: 1,
    validator: Validator_Asset,
    versions: ['1.0.1', '1.0.0'],
    dbName: 'assets',
    entityName: 'assets',
};
exports.Validator_TempAsset = Object.assign(Object.assign({}, Validator_Asset), { timestamp: (0, ts_common_1.tsValidateTimestamp)(ts_common_1.Minute) });
exports.DBDef_TempAssets = {
    validator: exports.Validator_TempAsset,
    dbName: 'assets-temp',
    entityName: 'assets-temp',
};
