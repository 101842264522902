"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_MouseInteractivity = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const ComponentSync_1 = require("../../../core/ComponentSync");
class TS_MouseInteractivity extends ComponentSync_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.ref = React.createRef();
        this.minimumMargin = 5;
        this.setModalPosition = () => {
            var _a, _b, _c, _d;
            const model = this.state.model;
            const modalRect = this.ref.current.getBoundingClientRect();
            const halfWidth = (modalRect.width / 2);
            const halfHeight = (modalRect.height / 2);
            const xAxisAnchor = (_a = model.xAxisAnchor) !== null && _a !== void 0 ? _a : 'left';
            const yAxisAnchor = (_b = model.yAxisAnchor) !== null && _b !== void 0 ? _b : 'top';
            //Start the modal centered on the trigger
            const modalPosition = {
                x: (xAxisAnchor === 'right' ? window.innerWidth - model.originPos.x : model.originPos.x) - halfWidth,
                y: (yAxisAnchor === 'bottom' ? window.innerHeight - model.originPos.y : model.originPos.y) - halfHeight,
            };
            //Move the modal based on modalPos property
            modalPosition.x += model.modalPos.x * (xAxisAnchor === 'right' ? -halfWidth : halfWidth);
            modalPosition.y += model.modalPos.y * (yAxisAnchor === 'bottom' ? -halfHeight : halfHeight);
            //Add offsets to modal
            modalPosition.x += ((_c = model.offset) === null || _c === void 0 ? void 0 : _c.x) || 0;
            modalPosition.y += ((_d = model.offset) === null || _d === void 0 ? void 0 : _d.y) || 0;
            //Set position
            this.ref.current.style[yAxisAnchor] = `${modalPosition.y}px`;
            this.ref.current.style[xAxisAnchor] = `${modalPosition.x}px`;
        };
        this.getDistancesFromViewPort = () => {
            const rect = this.ref.current.getBoundingClientRect();
            return {
                top: rect.top,
                left: rect.left,
                right: window.innerWidth - rect.right,
                bottom: window.innerHeight - rect.bottom
            };
        };
        this.keepModalInView = () => {
            var _a, _b;
            const distances = this.getDistancesFromViewPort();
            const current = this.ref.current;
            const rect = current.getBoundingClientRect();
            const offset = { x: 0, y: 0 };
            const xAxisAnchor = (_a = this.state.model.xAxisAnchor) !== null && _a !== void 0 ? _a : 'left';
            const yAxisAnchor = (_b = this.state.model.xAxisAnchor) !== null && _b !== void 0 ? _b : 'top';
            //Fix vertical axis if only one side is overflowing
            if ((0, ts_common_1.logicalXOR)(distances.bottom < this.minimumMargin, distances.top < this.minimumMargin)) {
                //If bottom is overflowing
                if (distances.bottom < this.minimumMargin) {
                    const correction = distances.bottom - this.minimumMargin;
                    //If correction doesn't cause top to overflow
                    if (distances.top + correction >= this.minimumMargin)
                        offset.y = correction;
                }
                else { //Top is overflowing
                    const correction = -distances.top + this.minimumMargin;
                    //If correction doesn't cause bottom to overflow
                    if (distances.bottom - correction >= this.minimumMargin)
                        offset.y = correction;
                }
            }
            //Fix horizontal axis if only one side is overflowing
            if ((0, ts_common_1.logicalXOR)(distances.right < this.minimumMargin, distances.left < this.minimumMargin)) {
                //If right is overflowing
                if (distances.right < this.minimumMargin) {
                    const correction = distances.right - this.minimumMargin;
                    //If correction doesn't cause left to overflow
                    if (distances.left + correction >= this.minimumMargin)
                        offset.x = correction;
                }
                else { //Left is overflowing
                    const correction = -distances.left + this.minimumMargin;
                    //If correction doesn't cause right to overflow
                    if (distances.right - correction >= this.minimumMargin)
                        offset.x = correction;
                }
            }
            if (offset.y) {
                if (yAxisAnchor === 'top')
                    current.style.top = `${rect.top + offset.y}px`;
                else
                    current.style.bottom = `${rect.bottom - offset.y}px`;
            }
            if (offset.x) {
                if (xAxisAnchor === 'left')
                    current.style.left = `${rect.left + offset.x}px`;
                else
                    current.style.right = `${rect.right - offset.x}px`;
            }
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state !== null && state !== void 0 ? state : (state = this.state ? Object.assign({}, this.state) : {});
        (_a = state.open) !== null && _a !== void 0 ? _a : (state.open = false);
        return state;
    }
    componentDidUpdate() {
        if (!this.state.model || !this.state.open)
            return;
        this.setModalPosition();
        this.keepModalInView();
    }
}
exports.TS_MouseInteractivity = TS_MouseInteractivity;
