import {DB_ProductTag, DB_Vendor} from '@app/shared';
import {ModuleFE_ProductTags_, ModuleFE_Vendors_} from '@modules/shopify';
import * as React from 'react';
import {ModuleFE_Stores} from '@modules/store';
import {PreDB} from '@nu-art/ts-common';
import {ICONSV4} from '@res/icons';
import {DropDown} from '@pah/app-dropdown';
import {TS_MultiSelect, TS_MultiSelect_Renderer} from '@nu-art/thunderstorm/frontend/components/_TS_MultiSelect';


const MultiSelect_Vendors: TS_MultiSelect_Renderer<DB_Vendor> = {
	placeholder: 'Select Vendor',
	noOptionsRenderer: 'Create a Vendor',
	module: ModuleFE_Vendors_,
	createNewItemFromLabel: async (filterText: string, matchingItems: DB_Vendor[], e: React.KeyboardEvent) => ({
		title: filterText,
		storeId: ModuleFE_Stores.getDefaultStore().storeId
	} as PreDB<DB_Vendor>),
	itemRenderer: (vendor, onDelete?: () => Promise<void>) => <>
		<ICONSV4.bin className="ts-icon__small" onClick={onDelete}/>
		<span>{vendor?.title || 'not found'}</span></>,
	selectionRenderer: DropDown.Vendor
};

const MultiSelect_ProductTags: TS_MultiSelect_Renderer<DB_ProductTag> = {
	placeholder: 'Select Tag',
	noOptionsRenderer: 'New Tag',
	module: ModuleFE_ProductTags_,
	createNewItemFromLabel: async (filterText: string, matchingItems: DB_ProductTag[], e: React.KeyboardEvent) => ({
		title: filterText,
		storeId: ModuleFE_Stores.getDefaultStore().storeId
	} as PreDB<DB_ProductTag>),
	itemRenderer: (vendor, onDelete?: () => Promise<void>) => <>
		<ICONSV4.bin className="ts-icon__small" onClick={onDelete}/>
		<span>{vendor?.title || 'not found'}</span></>,
	selectionRenderer: DropDown.ProductTag
};


export const MultiSelect = {
	Vendor: TS_MultiSelect.prepare(MultiSelect_Vendors),
	ProductTag: TS_MultiSelect.prepare(MultiSelect_ProductTags)
};