"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Shipment = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const validators_1 = require("../../validators");
const waybill_1 = require("./waybill");
const shopify_1 = require("../../shopify");
const Validator_Shipment = Object.assign(Object.assign({}, shopify_1.StoreItem_validator), { _variantIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId), _assetsIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId), totalValue: validators_1.tsValidateCurrencyValue, vendorId: ts_common_1.tsValidateUniqueId, courierId: ts_common_1.tsValidateOptionalId, comment: (0, ts_common_1.tsValidateString)(500, false), wayBill: (0, ts_common_1.tsValidateNonMandatoryObject)({
        id: (0, ts_common_1.tsValidateRegexp)(/[A-Z0-9]/),
        type: (0, ts_common_1.tsValidateValue)(waybill_1.Types_WayBill),
        assetId: ts_common_1.tsValidateOptionalId
    }), currencyRates: ts_common_1.tsValidateMustExist, invoices: ts_common_1.tsValidateMustExist, shippedDate: ts_common_1.tsValidateMustExist, arrivedDate: ts_common_1.tsValidateMustExist, landedDate: ts_common_1.tsValidateMustExist, payedDate: ts_common_1.tsValidateMustExist, assets: ts_common_1.tsValidateOptional, locked: (0, ts_common_1.tsValidateBoolean)(), paymentIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId), _isDraft: (0, ts_common_1.tsValidateBoolean)() });
exports.DBDef_Shipment = {
    validator: Validator_Shipment,
    upgradeChunksSize: 1,
    dbName: 'shipments',
    entityName: 'shipment',
    generatedProps: ['_variantIds', '_assetsIds', 'totalValue', 'currencyRates'],
    versions: ['1.0.1', '1.0.0']
};
