import * as React from 'react';
import {StorageKey, ModuleFE_Thunderstorm, ModuleFE_XHR, AppToolsScreen} from '@nu-art/thunderstorm/frontend';
import {FirebaseAnalyticsModule} from '@nu-art/firebase/frontend';
import {ComponentStatus, Props_SmartComponent, SmartPage, State_SmartComponent} from '@nu-art/thunderstorm/frontend';
import {HttpMethod} from '@nu-art/thunderstorm';
import {OnVendorsUpdated, ModuleFE_Vendors} from '@modules/index';
import {OnShipmentsUpdated, ModuleFE_Shipments} from '@modules/index';
import {ICONSV4} from '@res/icons';
import {parseTimeString} from '@nu-art/ts-common';
import {BC_Orders, DB_Shipment, DB_ShipmentInvoice, DB_Vendor} from '@app/shared';


const shipmentsPref = new StorageKey<BC_Orders[]>('old-shipments');

type State = { shipments?: BC_Orders[], existingShipments: DB_Shipment[], vendors: DB_Vendor[] };

export class ATS_Admin
	extends SmartPage<{}, State>
	implements OnVendorsUpdated, OnShipmentsUpdated {

	static screen: AppToolsScreen = {name: 'DevTool - Admin', renderer: this};

	static defaultProps = {
		modules: [ModuleFE_Shipments, ModuleFE_Vendors],
		pageTitle: () => this.screen.name
	};

	protected async deriveStateFromProps(nextProps: Props_SmartComponent, state?: (Partial<State> & State_SmartComponent) | undefined) {
		return {
			shipments: shipmentsPref.get() || [],
			existingShipments: await ModuleFE_Shipments.IDB.query(),
			vendors: await ModuleFE_Vendors.IDB.query(),
			componentPhase: ComponentStatus.Synced
		};
	}

	constructor(p: {}) {
		super(p);
		// @ts-ignore
		FirebaseAnalyticsModule.setCurrentScreen(this.pageTitle);
	}

	__onVendorsUpdated(...params: any): void {
		this.forceUpdate();
	}

	__onShipmentsUpdated(...params: any): void {
		this.forceUpdate();
	}

	componentDidMount() {
		ModuleFE_Vendors.v1.sync().execute();
		ModuleFE_Shipments.v1.sync().execute();
	}

	render() {
		return <div className="ll_h_t">
			<div className="ll_v_l">
				{this.renderCreateCouriers()}
				<div style={{margin: 20}}/>
				{this.renderTriggerCleanup()}
				<div style={{margin: 20}}/>
				{this.renderDeleteAllAssets()}
				{this.renderDeleteAllShipments()}
				<div style={{margin: 20}}/>
				{this.renderMigrateEnvToDev()}
				{this.renderMigrateEnvToStaging()}
				{this.renderMigrateEnvToProd()}
				<div style={{margin: 20}}/>
				{this.renderCreateShipment()}
			</div>
			<div className="ll_v_l">
				{(this.state.shipments || []).map((shipment, i) => {
					return <div key={`shipment-${i}`} className="ll_v_l">
						{this.renderShipment(shipment)}
						<div className="ll_v_l">{(shipment.orders || []).map((order, j) =>
							<div key={j}>{order.orderNumber} {ICONSV4.download_csv({
								onClick: () => {
									ModuleFE_Thunderstorm.downloadFile({content: order.csv, fileName: `${order.orderNumber}.csv`});
								}
							})}</div>)}</div>
					</div>;
				})}
			</div>
		</div>;
	}

	private renderShipment(bcShipment: BC_Orders) {
		const vendorId = this.state.vendors.find(v => v.title === bcShipment.vendorName)?._id;
		const payedDate = parseTimeString(bcShipment.dateAsString, 'DD/MM/YYYY');
		const shipment = this.state.existingShipments?.find(s => {
			return s.vendorId === vendorId && s.payedDate === payedDate;
		});

		const invoices = shipment?.invoices || [];
		const icon = ICONSV4[!shipment ? 'add' : 'sync']({
			onClick: () => {
				const toUpsert = {
					...shipment,
					shippedDate: payedDate,
					arrivedDate: payedDate,
					landedDate: payedDate,
					payedDate,
					vendorId,
					invoices: bcShipment.orders.reduce((_invoices, order) => {
						const invoice = invoices.find(i => i.invoiceNumber === order.orderNumber);
						if (invoice)
							return _invoices;

						_invoices.push({invoiceNumber: order.orderNumber, date: payedDate} as DB_ShipmentInvoice);
						return _invoices;
					}, [] as DB_ShipmentInvoice[]),
					comment: 'Auto generated from inventory 2020',
				};
				ModuleFE_Shipments.v1.upsert(toUpsert as DB_Shipment).execute();
			}
		});
		return <div>{bcShipment.vendorName} {bcShipment.dateAsString} ({bcShipment.orders?.length || 0}) {icon}</div>;
	}

	private renderCreateCouriers() {
		return <div style={{margin: 10}} onClick={() => {
			ModuleFE_XHR
				.createRequest<any>({method: HttpMethod.GET, path: '/v1/dev/create-couriers'})
				.execute();
		}}>Create Couriers
		</div>;
	}

	private renderTriggerCleanup() {
		return <div style={{margin: 10}} onClick={() => {
			ModuleFE_XHR
				.createRequest<any>({method: HttpMethod.GET, path: '/v1/dev/trigger-assets-cleanup'})
				.execute();
		}}>Cleaning up TEMP assets
		</div>;
	}

	private renderDeleteAllAssets() {
		return <div style={{margin: 10}} onClick={() => {
			ModuleFE_XHR
				.createRequest<any>({method: HttpMethod.GET, path: '/v1/dev/delete-all-assets'})
				.execute();
		}}>Delete All Assets
		</div>;
	}

	private renderDeleteAllShipments() {
		return <div style={{margin: 10}} onClick={() => {
			ModuleFE_XHR
				.createRequest<any>({method: HttpMethod.GET, path: '/v1/dev/delete-all-shipments'})
				.execute();
		}}>Delete All Shipments
		</div>;
	}

	private renderMigrateEnvToDev() {
		return <div style={{margin: 10}} onClick={() => {
			ModuleFE_XHR
				.createRequest<any>({method: HttpMethod.GET, path: '/v1/dev/migrate-to-env'})
				.setUrlParams({projectId_to: 'shopify-manager-tool-dev'})
				.execute();
		}}>Migrate To Dev
		</div>;
	}

	private renderMigrateEnvToStaging() {
		return <div style={{margin: 10}} onClick={() => {
			ModuleFE_XHR
				.createRequest<any>({method: HttpMethod.GET, path: '/v1/dev/migrate-to-env'})
				.setUrlParams({projectId_to: 'shopify-manager-tool-staging'})
				.execute();
		}}>Migrate To Staging
		</div>;
	}

	private renderMigrateEnvToProd() {
		return <div style={{margin: 10}} onClick={() => {
			ModuleFE_XHR
				.createRequest<any>({method: HttpMethod.GET, path: '/v1/dev/migrate-to-env'})
				.setUrlParams({projectId_to: 'shopify-manager-tool'})
				.execute();
		}}>Migrate To Prod
		</div>;
	}

	private renderCreateShipment() {
		const vendors = ['Kek Amsterdam',];
		// const vendors = ['Konges Sløjd', 'MarMar Copenhagen', 'OYOY Mini', 'Kek Amsterdam', 'BabyMocs'];
		const vendorsAsString = vendors.join(',');
		return <div style={{margin: 10}} onClick={() => {
			ModuleFE_XHR
				.createRequest<any>({method: HttpMethod.POST, path: '/v1/dev/create-shipment-from-old-inventory'})
				.setBodyAsJson({vendors})
				.execute((shipments: BC_Orders[]) => {
					shipmentsPref.set(shipments);
					this.setState({shipments});
				});
		}}>{`Create Shipment from ${vendorsAsString}`}</div>;
	}

}

