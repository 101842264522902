import * as React from 'react';
import {getQueryParameter, LL_H_T, Tab, TS_Route, TS_Space, TS_Tabs} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_Products, ModuleFE_ProductVariants, ModuleFE_Shipments, ModuleFE_Stores, ModuleFE_VariantOptions, ModuleFE_Vendors} from '@modules/index';
import * as Routes from '@consts/routes-consts';
import {PageKey_TreePath} from '@consts/routes-consts';
import {DB_Shipment, DB_ShipmentInvoice, ShipmentAsset} from '@app/shared';
import 'react-datepicker/dist/react-datepicker.css';
import {dispatch_updateHeaderOption} from '@page/Page_Workspace/Component_MainHeader';
import {Page_DBItemEditorV3, Props_DBItemEditorPageV3, State_DBItemEditorPageV3} from '@component/Page_DBItemEditorV3';
import {Component_ShipmentOverview} from './Component_ShipmentOverview';
import './Page_ShipmentV2.scss';
import {Dialogs} from '@dialog/app-dialogs';


type State = State_DBItemEditorPageV3<DB_Shipment> & { selected?: string }

export function createEmptyShipment() {
	return {
		storeId: ModuleFE_Stores.getDefaultStore().storeId,
		assets: [] as ShipmentAsset[],
		invoices: [] as DB_ShipmentInvoice[],
		paymentIds: [] as string[],
		comment: '',
		locked: false,
		_isDraft: true,
	} as DB_Shipment;
}

export class Page_ShipmentV2
	extends Page_DBItemEditorV3<DB_Shipment, '_id', State> {

	static Route: TS_Route<{ _id: string }> = {path: 'v2', key: 'shipment-v2', Component: this};

	static defaultProps = {
		newRoute: Routes.RouteKey_ShipmentNewV2,
		parentRoute: Routes.RouteKey_ShipmentList,
		pageTitle: (state: State) => 'Shipment page',
		keys: ['_id'],
		moduleFE: ModuleFE_Shipments,
		modules: [
			ModuleFE_Stores,
			ModuleFE_Shipments,
			ModuleFE_Vendors,
			ModuleFE_Products,
			ModuleFE_ProductVariants,
			ModuleFE_VariantOptions
		]
	};

	constructor(props: Props_DBItemEditorPageV3<DB_Shipment>) {
		super(props);
		this.state = {...this.state};
	}

	async generateEmptyItem() {
		return createEmptyShipment();
	}

	componentDidMount() {
		super.componentDidMount();
		this.setState({selected: getQueryParameter(PageKey_TreePath) as string || '/'});
		dispatch_updateHeaderOption.dispatchUI([
			{
				icon: 'save',
				action: () => {
					this.saveImpl();
				}
			},
			{
				icon: 'remove',
				action: () => {
					Dialogs.Shipment.delete(this.state.editable.item as DB_Shipment);
				}
			}]);
	}

	render() {
		const editable = this.state.editable;

		return <LL_H_T className="workspace-page page__shipment-v2">
			<Component_ShipmentOverview editable={editable}/>
			<TS_Space width={2}/>
			{this.renderTabs()}
		</LL_H_T>;
	}

	renderTabs() {
		// const editable: EditableDBItem<DB_Shipment> = this.state.editable;

		const tabs:Tab[] = [
			// {
			// 	uid: 'invoices',
			// 	title: 'Invoices',
			// 	content: () => <Component_ShipmentInvoicesV2 shipment={editable.item as DB_Shipment} editable={editable.editProp('invoices', [])}/>
			// }, {
			// 	uid: 'variants',
			// 	title: 'Variants',
			// 	content: () => <Component_ProductTemplateVariants editable={editable}/>
			// }, {
			// 	uid: 'missing-variants',
			// 	title: 'Missing Variants',
			// 	content: () => <Component_ProductTemplateVariants editable={editable}/>
			// }, {
			// 	uid: 'payments',
			// 	title: 'Payments',
			// 	content: () => <Component_ProductTemplateVariants editable={editable}/>
			// }, {
			// 	uid: 'assets',
			// 	title: 'Assets',
			// 	content: () => <Component_ProductTemplateVariants editable={editable}/>
			// },

		];

		return <TS_Tabs tabs={tabs}/>;
	}

	protected saveImpl = () => {
		this.props.moduleFE.v1.upsert((this.state.editable.item) as DB_Shipment).execute();
	};
}

