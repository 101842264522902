"use strict";
/*
 * Firebase is a simpler Typescript wrapper to all of firebase services.
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Firebase = exports.ModuleFE_Firebase_Class = void 0;
/**
 * Created by tacb0ss on 25/08/2018.
 */
const ts_common_1 = require("@nu-art/ts-common");
const FirebaseSessionFE_1 = require("./auth/FirebaseSessionFE");
const SwFirebaseSessionFE_1 = require("./auth/SwFirebaseSessionFE");
const localSessionId = 'local';
class ModuleFE_Firebase_Class extends ts_common_1.Module {
    constructor() {
        super(...arguments);
        this.sessions = {};
        this.fetchLocalConfig = async () => {
            try {
                const resp = await fetch('/__/firebase/init.json');
                const config = await resp.json();
                // @ts-ignore
                this.setConfig({ [localSessionId]: config });
                return config;
            }
            catch (e) {
                throw new ts_common_1.ImplementationMissingException(`Either specify configs for the 'ModuleBE_Firebase' or use SDK auto-configuration with firebase hosting`);
            }
        };
    }
    async createSwSession() {
        const swSession = this.swSession;
        if (swSession)
            return swSession;
        const localSession = await this.createLocalSession();
        return this.swSession = new SwFirebaseSessionFE_1.SwFirebaseSessionFE(localSessionId, localSession.app);
    }
    async createLocalSession(token) {
        let session = this.sessions[localSessionId];
        if (session)
            return session;
        let localConfig = this.getProjectAuth(localSessionId);
        if (!localConfig)
            localConfig = await this.fetchLocalConfig();
        this.checkConfig(localConfig, localSessionId);
        // I need to recheck because since it is an async op there might be race conditions
        session = this.sessions[localSessionId];
        if (session)
            return session;
        return this.initiateSession(localSessionId, localConfig, token);
    }
    async createSession(projectId, token) {
        if (!projectId)
            return this.createLocalSession(token);
        if (typeof projectId === 'object')
            return this.createSessionWithConfigs(projectId, token);
        const session = this.sessions[projectId];
        if (session)
            return session;
        this.logInfo(`Creating session for config: ${projectId}`);
        const config = this.getProjectAuth(projectId);
        this.checkConfig(config, projectId);
        return this.initiateSession(projectId, config, token);
    }
    async createSessionWithConfigs(config, token) {
        if (!config || !config.projectId || !config.databaseURL || !config.authDomain || !config.apiKey)
            throw new ts_common_1.BadImplementationException(`Config: ${(0, ts_common_1.__stringify)(config)} is not a credentials pattern`);
        const projectId = config.projectId + (token || '');
        // @ts-ignore
        this.setConfig({ [projectId]: config });
        return this.createSession(projectId, token);
    }
    getProjectAuth(projectId) {
        var _a;
        return (_a = this.config) === null || _a === void 0 ? void 0 : _a[projectId];
    }
    async initiateSession(projectId, config, token) {
        const session = new FirebaseSessionFE_1.FirebaseSessionFE(projectId, config);
        this.sessions[projectId] = session;
        session.connect();
        if (token)
            await session.signInWithToken(token);
        return session;
    }
    checkConfig(config, projectId) {
        if (!config)
            throw new ts_common_1.BadImplementationException('Did you forget to add ModuleBE_Firebase to the main.ts in the modules array?');
        if (!config || !config.projectId || !config.apiKey || !config.authDomain)
            throw new ts_common_1.BadImplementationException(`Config for key ${projectId} is not an Admin credentials pattern`);
    }
}
exports.ModuleFE_Firebase_Class = ModuleFE_Firebase_Class;
exports.ModuleFE_Firebase = new ModuleFE_Firebase_Class();
