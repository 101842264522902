"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_MultiSelect_V2 = void 0;
const React = require("react");
const react_1 = require("react");
const ts_common_1 = require("@nu-art/ts-common");
require("./TS_MultiSelect.scss");
const Layouts_1 = require("../Layouts");
const tools_1 = require("../../utils/tools");
class TS_MultiSelect_V2 extends react_1.Component {
    static prepare(_props) {
        return (props) => React.createElement(TS_MultiSelect_V2, Object.assign({}, _props, props));
    }
    render() {
        const editable = this.props.editable;
        const prop = this.props.prop;
        const existingItems = (editable.item[prop] || (editable.item[prop] = []));
        const addInnerItem = async (item) => {
            await editable.update(prop, [...existingItems, item]);
            this.forceUpdate();
        };
        const props = this.props;
        const SelectionRenderer = props.selectionRenderer;
        return React.createElement(Layouts_1.LL_H_C, { className: (0, tools_1._className)('ts-multi-select__list', this.props.className) },
            existingItems.map((item, i) => {
                return React.createElement(Layouts_1.LL_H_C, { className: "ts-multi-select__list-value", key: i }, props.itemRenderer(item, async () => {
                    await editable.update(prop, [...(0, ts_common_1.removeItemFromArray)(existingItems, item)]);
                    this.forceUpdate();
                }));
            }),
            React.createElement(SelectionRenderer, { className: 'ts-multi-select__selector', onSelected: addInnerItem, existingItems: existingItems }));
    }
}
exports.TS_MultiSelect_V2 = TS_MultiSelect_V2;
