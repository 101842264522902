"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MimeType_pdf = exports.MimeType_gif = exports.MimeType_jpg = exports.MimeType_jpeg = exports.MimeType_png = exports.MimeType_csv = exports.MimeType_html = exports.MimeType_txt = void 0;
exports.MimeType_txt = "text/plain";
exports.MimeType_html = "text/html";
exports.MimeType_csv = "text/csv";
exports.MimeType_png = "image/png";
exports.MimeType_jpeg = "image/jpeg";
exports.MimeType_jpg = "image/jpg";
exports.MimeType_gif = "image/gif";
exports.MimeType_pdf = "application/pdf";
