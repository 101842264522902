"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Link = void 0;
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const React = require("react");
const ModuleFE_Thunderstorm_1 = require("../../modules/ModuleFE_Thunderstorm");
require("./TS_Link.scss");
const tools_1 = require("../../utils/tools");
class TS_Link extends React.Component {
    constructor() {
        super(...arguments);
        this.handleOnClick = (e) => {
            (0, tools_1.stopPropagation)(e);
            const params = this.props.params || {};
            ModuleFE_Thunderstorm_1.ModuleFE_Thunderstorm.openUrl({ url: this.props.url, params }, this.props.target);
        };
    }
    render() {
        const className = (0, tools_1._className)('ts-link', this.props.className);
        return React.createElement("div", { className: className, onClick: this.handleOnClick }, this.props.children);
    }
}
exports.TS_Link = TS_Link;
