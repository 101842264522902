"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationChangeListener = exports.dispatch_onLocationChanged = void 0;
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_1 = require("react");
const thunder_dispatcher_1 = require("../../core/thunder-dispatcher");
const ModuleFE_RoutingV2_1 = require("./ModuleFE_RoutingV2");
exports.dispatch_onLocationChanged = new thunder_dispatcher_1.ThunderDispatcher('__onLocationChanged');
const LocationChangeListener = () => {
    const location = (0, react_router_dom_1.useLocation)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(() => {
        exports.dispatch_onLocationChanged.dispatchUI(location.pathname);
    }, [location]);
    ModuleFE_RoutingV2_1.ModuleFE_RoutingV2.setNavigate(navigate);
    return React.createElement(React.Fragment, null);
};
exports.LocationChangeListener = LocationChangeListener;
