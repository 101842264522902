import * as React from 'react';
import {AppPage, LL_H_C, LL_V_C, LL_V_L} from '@nu-art/thunderstorm/frontend';
import './PgDev_Palette.scss';


const colors = [
	'black',
	'amber',
	'gold',
	'blue',
	'green',
];
const dummyArr = new Array(10).fill(0);
const rgbArrayToHex = (rgb: number[]) => `#${rgb.map(v => v.toString(16).padStart(2, '0')).join('')}`;
const rgbStringToArray = (rgb: string) => rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)!.splice(1, 3)
	.map(v => Number(v));

class PgDev_Palette_Renderer
	extends AppPage<{}, { colorHex?: string, className?: string }> {
	static defaultProps = {
		pageTitle: () => PgDev_Palette.name
	};

	constructor(p: {}) {
		super(p);
	}

	protected deriveStateFromProps(nextProps: {}): {} | undefined {
		return {};
	}

	renderSquareColor = (className: string, index: number) => {
		return <div className={`${className}-${index + 1}`} onClick={(e) => {
			const color = window.getComputedStyle(e.currentTarget).getPropertyValue('background-color');
			const colorHex = rgbArrayToHex(rgbStringToArray(color));
			return this.setState({className, colorHex});
		}}>
			{index + 1}
		</div>;
	};

	render() {
		return (
			<LL_V_L>
				<LL_H_C className={'palette-container'}>
					<LL_V_C>
						<h1>Palette</h1>
						{colors.map(this.renderColor)}
					</LL_V_C>
				</LL_H_C>
				{this.state.className}: {this.state.colorHex}
			</LL_V_L>
		);
	}

	private renderColor = (color: string) => <LL_H_C className="color-row">
		<div className="color-label">{color}:</div>
		{dummyArr.map((_, n) => this.renderSquareColor(color, n))}</LL_H_C>;
}

export const PgDev_Palette = {name: 'App Palette', renderer: PgDev_Palette_Renderer};

