"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_OrderShippingMethod = void 0;
const validators_1 = require("../../../../validators");
const shopify_1 = require("../../../../shopify");
const ts_common_1 = require("@nu-art/ts-common");
const Validator_OrderShippingMethod = Object.assign(Object.assign({}, shopify_1.ShopifyItem_validator), { isPickup: (0, ts_common_1.tsValidateBoolean)(false), label: (0, validators_1.tsValidateStringLength)(50), sources: (0, ts_common_1.tsValidateArray)((0, validators_1.tsValidateStringLength)(50)), price: validators_1.tsValidateCurrencyValue });
exports.DBDef_OrderShippingMethod = {
    validator: Validator_OrderShippingMethod,
    dbName: 'customer-shipping-options',
    entityName: 'customer-shipping-option',
    uniqueKeys: ['storeId', 'label'],
    versions: ['1.0.1', '1.0.0'],
};
