import * as React from 'react';
import {ModuleFE_Dialog, performAction, TS_Dialog} from '@nu-art/thunderstorm/frontend';
import {DB_Object, Default_UniqueKey, PreDB} from '@nu-art/ts-common';
import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {DialogRenderers, Props_Dialog} from './types';


export class Dialog_DeleteDbItem<T extends DB_Object, Ks extends keyof PreDB<T> = Default_UniqueKey>
	extends TS_Dialog<Props_Dialog<T, Ks>> {

	static defaultProps = {
		dialogId: 'dialog-delete-item'
	};

	static show<T extends DB_Object, Ks extends keyof PreDB<T> = Default_UniqueKey>(item: T, module: ModuleFE_BaseApi<T, Ks>, renderers: DialogRenderers<T>) {
		ModuleFE_Dialog.show(<Dialog_DeleteDbItem<T, Ks> item={item} module={module} renderers={renderers}/>);
	}

	constructor(p: Props_Dialog<T, Ks>) {
		super(p);
	}

	protected renderTitle = () => <>Delete {this.props.renderers.title(this.props.item, this.state)}</>;

	protected renderBody = () => this.props.renderers.body(this.props.item, this.state, this.setState);

	buttons = () => ({
		left: [TS_Dialog.Button_Cancel],
		right: [{
			content: 'Delete',
			onClick: this.delete,
			renderer: TS_Dialog.busyButton
		}]
	});

	private delete = () => {
		this.performAction(async () => {
			const title = this.props.renderers.title(this.props.item, this.state);
			await performAction(async () => {
				const item = await this.props.module.v1.delete({_id: this.props.item._id}).executeSync();
				await this.props?.onCompleted?.(item);
				this.closeDialog();
			}, {
				type: 'toast',
				failContent: `Error deleting ${title}`,
				successContent: `${title} was deleted`
			});
		});
	};
}

