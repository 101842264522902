import {
	DB_CustomerOrder,
	DeliveryStatus,
	DeliveryStatus_Delivered,
	DeliveryStatus_OnRoute,
	DeliveryStatus_PickedUp,
	OrderStatus_InProgress,
	OrderStatus_PendingPayment
} from '@app/shared';
import {DB_BaseObject, md5, TypedMap} from '@nu-art/ts-common';
import {ModuleFE_ShippingMethod} from '@modules/shopify';
import {Digraph, Edge, Node, Subgraph} from 'ts-graphviz';


type DecisionCondition<T> = (input: T) => boolean

type DecisionNode = DB_BaseObject & {
	label: string;
	parents?: string[]
	condition: DecisionCondition<any>
}

// @ts-ignore
const not = (condition: DecisionCondition<any>) => (input: any) => !condition(input);
const and = (...conditions: DecisionCondition<any>[]) => (input: any) => !conditions.find(condition => !condition(input));
const or = (...conditions: DecisionCondition<any>[]) => (input: any) => !!conditions.find(condition => condition(input));

const Condition_OrderExists = (dbOrder: DB_CustomerOrder) => !!dbOrder;
const Condition_isPickup = (dbOrder: DB_CustomerOrder) => {
	const shippingOption = ModuleFE_ShippingMethod.cache.unique(dbOrder.shippingOptionId);
	return !!shippingOption?.isPickup;
};
const Condition_isDelivery = (dbOrder: DB_CustomerOrder) => !Condition_isPickup(dbOrder);
const Condition_isCash = (dbOrder: DB_CustomerOrder) => {
	const shippingOption = ModuleFE_ShippingMethod.cache.unique(dbOrder.shippingOptionId);
	return !!shippingOption?.isPickup;
};
const Condition_OrderInProgress = (dbOrder: DB_CustomerOrder) => dbOrder.status.includes(OrderStatus_InProgress);
const Condition_OrderPaymentPending = (dbOrder: DB_CustomerOrder) => dbOrder.status.includes(OrderStatus_PendingPayment);
const Condition_HasDeliveryCourier = (dbOrder: DB_CustomerOrder) => dbOrder.deliveryDetails?.length > 0;
const Condition_isOrderPacked = (dbOrder: DB_CustomerOrder) => dbOrder.packagingStatus?.isReady;

const Condition_MessageNotSend = (messageId: string) => (dbOrder: DB_CustomerOrder) => {
	return !!dbOrder.messages?.[messageId];
};
const Condition_DeliveryStatus = (toMatch: DeliveryStatus[]) => (dbOrder: DB_CustomerOrder) => {
	const status = dbOrder.deliveryDetails[dbOrder.deliveryDetails.length - 1]?.status;
	if (!status)
		return false;

	return toMatch.includes(status);
};

const createNode = (label: string, condition: DecisionCondition<any>, parents?: string[]): DecisionNode => {
	return {
		_id: md5(label),
		label: label,
		condition,
		parents
	};
};

const createMessageNode = (label: string, messageId: string, parents?: string[], condition?: DecisionCondition<any>): DecisionNode => {
	const msgCondition = Condition_MessageNotSend(messageId);
	return createNode(`MSG: ${label}`, condition ? and(msgCondition, condition) : msgCondition, parents);
};

const MSG_PaymentPending = 'Payment Pending';
const MSG_PickupAndCash = 'Pickup & Cash';

const NODE_Created = createNode('Created', Condition_OrderExists);
const NODE_MSG_ThankYou = createMessageNode('Thank You', 'Thank You for Your Order', [NODE_Created._id], Condition_MessageNotSend(MSG_PaymentPending));

const NODE_Unpaid = createNode('Unpaid', and(Condition_OrderPaymentPending), [NODE_Created._id]);
const NODE_ErrorCashDelivery = createNode('Error Cash & Delivery', and(Condition_isCash, Condition_isDelivery), [NODE_Unpaid._id]);
const NODE_MSG_NoCashDelivery = createMessageNode('Error Cash & Delivery', 'No Cash & Delivery', [NODE_ErrorCashDelivery._id]);

const NODE_PaymentPending = createNode('Payment Pending', and(Condition_OrderPaymentPending), [NODE_Unpaid._id]);
const NODE_MSG_PaymentPending = createMessageNode('Payment Pending', MSG_PaymentPending, [NODE_PaymentPending._id]);

const NODE_PickupAndCash = createNode('Pickup & Cash', and(Condition_isCash, Condition_isPickup), [NODE_Unpaid._id]);
const NODE_MSG_PickupAndCash = createMessageNode('Pickup & Cash', MSG_PickupAndCash, [NODE_Unpaid._id]);

const NODE_RequestDelivery = createNode('Order Delivery Courier', and(Condition_isDelivery, Condition_HasDeliveryCourier), [NODE_MSG_ThankYou._id, NODE_MSG_PaymentPending._id, NODE_Created._id, NODE_PaymentPending._id]);

const NODE_NeedToPackOrder = createNode('Pack Order', and(Condition_isOrderPacked, or(and(Condition_isCash, Condition_isPickup), and(Condition_isDelivery, Condition_OrderInProgress))), [NODE_MSG_ThankYou._id, NODE_MSG_PickupAndCash._id, NODE_MSG_PaymentPending._id, NODE_Created._id, NODE_PaymentPending._id]);

// const NODE_Paid = createNode('Order Paid', and(Condition_OrderInProgress), [NODE_Unpaid._id, NODE_MSG_PaymentPending._id, NODE_PaymentPending._id]);

const NODE_ReadyForShipping = createNode('Ready For Shipping', and(Condition_HasDeliveryCourier, Condition_isOrderPacked), [NODE_RequestDelivery._id, NODE_NeedToPackOrder._id]);

const NODE_OnRoute = createNode('On Route', and(Condition_HasDeliveryCourier, Condition_DeliveryStatus([DeliveryStatus_PickedUp, DeliveryStatus_OnRoute])), [NODE_ReadyForShipping._id]);

const NODE_Delivered = createNode('Delivered', and(Condition_HasDeliveryCourier, Condition_DeliveryStatus([DeliveryStatus_Delivered])), [NODE_ReadyForShipping._id]);

// const NODE_TestAnd_Fail = createNode('Fail AND', and(() => true, () => true, () => false));
// const NODE_TestAnd_Success = createNode('Success AND', and(() => true, () => true));
// const NODE_TestOr_Fail = createNode('Fail OR', or(() => false, () => false, () => false));
// const NODE_TestOr_Success = createNode('Success OR', or(() => false, () => true, () => false));
export const ALL_NODES = [
	// NODE_TestAnd_Fail,
	// NODE_TestAnd_Success,
	// NODE_TestOr_Fail,
	// NODE_TestOr_Success,
	NODE_Created,
	NODE_MSG_ThankYou,
	NODE_Unpaid,
	NODE_ErrorCashDelivery,
	NODE_MSG_NoCashDelivery,
	NODE_PaymentPending,
	NODE_MSG_PaymentPending,
	NODE_PickupAndCash,
	NODE_MSG_PickupAndCash,
	NODE_RequestDelivery,
	NODE_NeedToPackOrder,
	NODE_ReadyForShipping,
	NODE_OnRoute,
	NODE_Delivered,
];

export const calculateGraph = (nodes: DecisionNode[], context: any) => {
	const graph = new Digraph();
	const container = new Subgraph('Container');
	graph.addSubgraph(container);
	const nodeToID: TypedMap<Node> = {};
	nodes.forEach(_node => {
		const output = _node.condition(context);
		const node = new Node(_node._id, {
			id: _node._id,
			color: output ? '#82FF1BCD' : '#FF0000AB',
			fillcolor: '#B0FFFB94',
			style: 'bold,dashed,solid,filled',
			label: _node.label,
			tooltip: _node.label
		});

		nodeToID[_node._id] = node;
		container.addNode(node);
		_node.parents?.forEach(parentId => {
			container.addEdge(new Edge([nodeToID[parentId], nodeToID[_node._id]], {
				color: 'black'
			}));
		});
	});
	return graph;
};
