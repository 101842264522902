"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Overlay = void 0;
const React = require("react");
require("./TS_Overlay.scss");
const tools_1 = require("../../utils/tools");
class TS_Overlay extends React.Component {
    render() {
        if (!this.props.showOverlay)
            return this.props.children;
        const overlayChild = React.createElement("div", { className: "ts-overlay__child", onClick: tools_1.stopPropagation }, this.props.children);
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: (0, tools_1._className)('ts-overlay', this.props.className), onClick: this.props.onClickOverlay, onMouseMove: e => e.stopPropagation(), onMouseOver: e => e.stopPropagation(), onMouseEnter: e => e.stopPropagation(), onMouseLeave: e => e.stopPropagation(), onMouseOut: e => e.stopPropagation() }, !this.props.flat && overlayChild),
            this.props.flat && overlayChild);
    }
}
exports.TS_Overlay = TS_Overlay;
