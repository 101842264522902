"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Toast = void 0;
require("./TS_ToastOverlay.scss");
const React = require("react");
const tools_1 = require("../../utils/tools");
const TS_Toast = (props) => {
    return React.createElement("div", { className: (0, tools_1._className)('ts-toast', props.toastType) },
        React.createElement("span", { className: "ts-toast__content" }, props.text));
};
exports.TS_Toast = TS_Toast;
