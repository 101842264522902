"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_ShopifyAsset = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const validators_1 = require("../../validators");
const types_1 = require("../types");
const Validator_ShopifyAsset = Object.assign(Object.assign({}, types_1.ShopifyItem_validator), { originUrl: ts_common_1.tsValidateMustExist, meta: ts_common_1.tsValidateMustExist, synced: ts_common_1.tsValidateMustExist, type: (0, ts_common_1.tsValidateValue)(['image', 'video']), alt: (0, validators_1.tsValidateStringLength)(500, false) });
exports.DBDef_ShopifyAsset = {
    validator: Validator_ShopifyAsset,
    dbName: 'assets-shopify',
    entityName: 'assets-shopify',
};
