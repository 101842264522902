"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericDropDownV3 = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
const TS_Dropdown_1 = require("../TS_Dropdown");
const Adapter_1 = require("../adapter/Adapter");
const core_1 = require("../../core");
// const defaultQueryFilter = () => true;
class GenericDropDownV3 extends core_1.ComponentSync {
    deriveStateFromProps(nextProps) {
        var _b, _c, _d;
        const state = {};
        const items = ((_c = (_b = this.props).itemResolver) === null || _c === void 0 ? void 0 : _c.call(_b)) || nextProps.module.cache.allMutable();
        if (!nextProps.queryFilter)
            state.items = items;
        else {
            state.items = items.filter(nextProps.queryFilter);
            if (state.items.length === 0 && nextProps.ifNoneShowAll === true)
                state.items = items;
        }
        //Sort Items by sort function or object keys
        state.items = ((_d = nextProps.sortBy) === null || _d === void 0 ? void 0 : _d.reduce((toRet, sortBy) => {
            return (0, ts_common_1.sortArray)(state.items, typeof sortBy === 'function' ? sortBy : item => item[sortBy]);
        }, state.items)) || state.items;
        //Set selected item
        state.selected = this.getSelected(nextProps.module, nextProps.selected);
        state.filter = new ts_common_1.Filter(nextProps.mapper);
        state.adapter = (0, Adapter_1.SimpleListAdapter)(state.items, props => nextProps.renderer(props.item));
        return state;
    }
    getSelected(module, selectMethod) {
        switch (typeof selectMethod) {
            case 'string':
                return module.cache.unique(selectMethod);
            case 'function':
                return selectMethod();
            case 'object':
                if (!Array.isArray(selectMethod))
                    return selectMethod;
        }
        return undefined;
    }
    render() {
        return React.createElement(TS_Dropdown_1.TS_DropDown, { className: this.props.className, placeholder: this.props.placeholder || 'Choose one', inputValue: this.props.inputValue, adapter: this.state.adapter, filter: this.state.filter, selected: this.state.selected, onNoMatchingSelectionForString: this.props.onNoMatchingSelectionForString, onSelected: this.props.onSelected, caret: this.props.caret, boundingParentSelector: this.props.boundingParentSelector, renderSearch: this.props.renderSearch, limitItems: this.props.limitItems, canUnselect: this.props.canUnselect, disabled: this.props.disabled });
    }
}
_a = GenericDropDownV3;
GenericDropDownV3.prepareEditable = (mandatoryProps) => {
    return (props) => {
        const { editable, prop } = props, restProps = __rest(props, ["editable", "prop"]);
        return React.createElement(GenericDropDownV3, Object.assign({}, (0, ts_common_1.resolveContent)(mandatoryProps), restProps, { onSelected: item => editable.update(prop, item._id), selected: editable.item[prop] }));
    };
};
GenericDropDownV3.prepareSelectable = (mandatoryProps) => {
    return (props) => React.createElement(GenericDropDownV3, Object.assign({}, (0, ts_common_1.resolveContent)(mandatoryProps), props));
};
GenericDropDownV3.prepare = (mandatoryProps) => {
    return {
        editable: _a.prepareEditable(mandatoryProps),
        selectable: _a.prepareSelectable(mandatoryProps)
    };
};
exports.GenericDropDownV3 = GenericDropDownV3;
