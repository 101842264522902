import * as React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {CurrencyValue, DB_Shipment, DB_ShipmentInvoice, WayBill} from '@app/shared';
import {LL_H_C, LL_V_L, ModuleFE_Toaster, stopPropagation} from '@nu-art/thunderstorm/frontend';
import {ICONSV4} from '@res/icons';
import {DateEditor} from '@component/DateRenderer';
import {DropDown} from '@pah/app-dropdown';
import {Component_WayBill} from '../shipment/components/Component_WayBill';
import {currentTimeMillis} from '@nu-art/ts-common';
import {Component_EditableItem} from '@component/v2/Component_EditableItem';
import {Dialogs} from '@dialog/app-dialogs';


type DateProps = 'payedDate' | 'shippedDate' | 'landedDate' | 'arrivedDate';

export class Component_ShipmentOverview
	extends Component_EditableItem<DB_Shipment> {

	private applyDates = async (timestamp: number, key: DateProps) => {
		const dateProps: (DateProps)[] = [
			'payedDate',
			'shippedDate',
			'landedDate',
			'arrivedDate'];

		const item = this.state.editable.item;
		item[key] = timestamp;

		for (let index = dateProps.indexOf(key); index < dateProps.length; index++) {
			if (index + 1 >= dateProps.length)
				break;

			const dateProp = dateProps[index];
			const nextProp = dateProps[index + 1];
			if (item[dateProp] === undefined)
				continue;

			if (item[nextProp] === undefined || (item[nextProp] || 0) < (item[dateProp] || 0)) {
				item[nextProp] = item[dateProp];
			}
		}

		dateProps.reverse();

		for (let index = dateProps.indexOf(key); index < dateProps.length; index++) {
			if (index + 1 >= dateProps.length)
				break;

			const dateProp = dateProps[index];
			const nextProp = dateProps[index + 1];
			if (item[dateProp] === undefined)
				continue;

			if (item[nextProp] === undefined || (item[nextProp] || 0) > (item[dateProp] || 0)) {
				item[nextProp] = item[dateProp];
			}
		}

		await this.state.editable.save();
	};

	render() {
		const item = this.state.editable.item;
		return (
			<div className="shipment-overview ll_v_l match_height">
				<LL_V_L className="match_width">
					{this.renderProp('Vendor', this.renderVendor(item))}

					{item.vendorId && this.renderProp('Courier', this.renderCourier(item))}
					{item.courierId && this.renderProp('Waybill', this.renderWaybill(item))}

					{item.wayBill && this.renderProp('Payed at', this.DateEditor('payedDate'))}
					{item.payedDate && this.renderProp('Shipped at', this.DateEditor('shippedDate'))}
					{item.shippedDate && this.renderProp('Landed at', this.DateEditor('landedDate'))}
					{item.landedDate && this.renderProp('Arrived at', this.DateEditor('arrivedDate'))}
				</LL_V_L>
				{this.renderProp('Invoices', this.renderInvoices(item.invoices || (item.invoices = [])), 'flex__grow')}
				<LL_V_L>
					{item.vendorId && this.renderProp('Add Invoice', this.renderAddInvoice(item))}
					{item.payedDate && this.renderProp('Currencies', this.renderCurrencies(item))}
					{item.totalValue && this.renderProp('Total Value', this.renderValue(item.totalValue))}
					{item.vendorId && this.renderProp('Status', this.renderLock(item))}
				</LL_V_L>
			</div>
		);
	}

	private renderInvoices(invoices: DB_ShipmentInvoice[]) {
		return <LL_V_L className="match_width">
			{invoices.map(invoice => <LL_H_C className="match_width flex__space-between">
				<div className="invoice">{invoice.invoiceNumber}</div>
				<ICONSV4.bin onClick={() => this.deleteInvoice(invoice)}></ICONSV4.bin>
			</LL_H_C>)}
		</LL_V_L>;
	}

	private deleteInvoice = (invoice: Partial<DB_ShipmentInvoice>) => {
		Dialogs.Shipment.deleteInvoice(this.item() as DB_Shipment, invoice as DB_ShipmentInvoice);
	};

	private DateEditor(key: DateProps) {
		const item = this.state.editable.item;
		return DateEditor(item[key] || currentTimeMillis(), (timestamp) => this.applyDates(timestamp, key));
	}

	private renderAddInvoice(item: Partial<DB_Shipment>) {
		return <div className="ll_h_c">
			<div style={{marginInlineStart: 8}}>{ICONSV4.add({
				onClick: (e) => {
					stopPropagation(e);
					this.state.editable.update('invoices', (_invoices) => {
						const invoices = _invoices || [];
						invoices.push({date: item.payedDate} as DB_ShipmentInvoice);
						return invoices;
					});
				}
			})}</div>
		</div>;
	}

	private renderValue(totalValue: CurrencyValue) {
		return <div className="ll_h_c">
			<div>{totalValue.value} {totalValue.currency}</div>
		</div>;
	}

	private renderCurrencies(item: Partial<DB_Shipment>) {
		return item.currencyRates?.map(cr => <div key={`${cr.from} - ${cr.to}`}>{cr.from} &gt;&gt; {cr.to} ~= {cr.factor}</div>);
	}

	private renderCourier(item: Partial<DB_Shipment>) {
		if (!item.vendorId)
			return;

		return <DropDown.Courier
			selected={item.courierId}
			onSelected={courier => this.state.editable.update('courierId', courier._id)}
		/>;
	}

	private renderVendor = (item: Partial<DB_Shipment>) => {
		return <DropDown.Vendor
			selected={item.vendorId}
			onSelected={vendor => {
				if (item.invoices?.find((invoice: DB_ShipmentInvoice) => invoice.variants.length > 0)) {
					ModuleFE_Toaster.toastError('Cannot switch vendor while having products in invoices');
					this.forceUpdate();
					return true;
				}

				this.state.editable.update('vendorId', vendor._id);
				this.reDeriveState({});
			}}
		/>;
	};

	private renderWaybill(item: Partial<DB_Shipment>) {
		return <Component_WayBill editable={this.state.editable.editProp('wayBill', {} as WayBill)}/>;
	}

	private renderLock(item: Partial<DB_Shipment>) {
		return <div onClick={() => this.state.editable.update('locked', (locked) => !locked)}>
			{item.locked ? 'Mark Unlocked' : 'Mark Locked'}
		</div>;
	}

}

