"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceProduct_PDF = exports.InvoiceProduct_CSV = exports.Options_InvoiceProducts = void 0;
exports.Options_InvoiceProducts = ['csv-invoice-products', 'pdf-invoice-products'];
exports.InvoiceProduct_CSV = {
    fileTypes: ['csv'],
    key: 'invoice-products',
    options: [{ label: 'CSV products list', key: 'csv-invoice-products' }]
};
exports.InvoiceProduct_PDF = {
    fileTypes: ['pdf'],
    key: 'invoice-products',
    options: [{ label: 'PDF products list', key: 'pdf-invoice-products' }]
};
