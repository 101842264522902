"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropDown_AutoComplete = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ModuleFE_AutoComplete_1 = require("../modules/ModuleFE_AutoComplete");
const React = require("react");
const Props_AutoComplete = {
    module: ModuleFE_AutoComplete_1.ModuleFE_AutoComplete,
    modules: [ModuleFE_AutoComplete_1.ModuleFE_AutoComplete],
    mapper: item => [item.text],
    placeholder: 'Choose a label',
    renderer: item => React.createElement("div", { className: "ll_h_c" }, item.text)
};
exports.DropDown_AutoComplete = {
    AutoComplete: (props) => React.createElement(frontend_1.GenericDropDown, Object.assign({}, Props_AutoComplete, props)),
};
