import * as React from 'react';
import {ElementType, HTMLAttributes} from 'react';
import {_className} from '@nu-art/thunderstorm/frontend';
import {_keys} from '@nu-art/ts-common';

import addUrl, {ReactComponent as add} from '@res/icons/icon__add.svg';
import arrowDownUrl, {ReactComponent as arrowDown} from '@res/icons/icon__arrowDown.svg';
import bellUrl, {ReactComponent as bell} from '@res/icons/icon__bell.svg';
import binUrl, {ReactComponent as bin} from '@res/icons/icon__bin.svg';
import cleanUrl, {ReactComponent as clean} from '@res/icons/icon__clean.svg';
import closeUrl, {ReactComponent as close} from '@res/icons/icon__close.svg';
import copyUrl, {ReactComponent as copy} from '@res/icons/icon__copy.svg';
import customerUrl, {ReactComponent as customer} from '@res/icons/icon__customer.svg';
import customer_orderUrl, {ReactComponent as customer_order} from '@res/icons/icon__customer_order.svg';
import debugUrl, {ReactComponent as debug} from '@res/icons/icon__debug.svg';
import decision_treeUrl, {ReactComponent as decision_tree} from '@res/icons/icon__decision_tree.svg';
import delete_xUrl, {ReactComponent as delete_x} from '@res/icons/icon__delete_x.svg';
import documentUrl, {ReactComponent as document} from '@res/icons/icon__document.svg';
import documentsUrl, {ReactComponent as documents} from '@res/icons/icon__documents.svg';
import downloadUrl, {ReactComponent as download} from '@res/icons/icon__download.svg';
import download_csvUrl, {ReactComponent as download_csv} from '@res/icons/icon__download_csv.svg';
import editUrl, {ReactComponent as edit} from '@res/icons/icon__edit.svg';
import emailUrl, {ReactComponent as email} from '@res/icons/icon__email.svg';
import errorToastUrl, {ReactComponent as errorToast} from '@res/icons/icon__errorToast.svg';
import file_exportUrl, {ReactComponent as file_export} from '@res/icons/icon__file_export.svg';
import file_importUrl, {ReactComponent as file_import} from '@res/icons/icon__file_import.svg';
import infoToastUrl, {ReactComponent as infoToast} from '@res/icons/icon__infoToast.svg';
import invoiceUrl, {ReactComponent as invoice} from '@res/icons/icon__invoice.svg';
import invoicesUrl, {ReactComponent as invoices} from '@res/icons/icon__invoices.svg';
import listUrl, {ReactComponent as list} from '@res/icons/icon__list.svg';
import locationUrl, {ReactComponent as location} from '@res/icons/icon__location.svg';
import lockUrl, {ReactComponent as lock} from '@res/icons/icon__lock.svg';
import magic_stickUrl, {ReactComponent as magic_stick} from '@res/icons/icon__magic_stick.svg';
import menuUrl, {ReactComponent as menu} from '@res/icons/icon__menu.svg';
import missing_variantsUrl, {ReactComponent as missing_variants} from '@res/icons/icon__missing_variants.svg';
import paymentUrl, {ReactComponent as payment} from '@res/icons/icon__payment.svg';
import printUrl, {ReactComponent as print} from '@res/icons/icon__print.svg';
import productUrl, {ReactComponent as product} from '@res/icons/icon__product.svg';
import removeUrl, {ReactComponent as remove} from '@res/icons/icon__remove.svg';
import saveUrl, {ReactComponent as save} from '@res/icons/icon__save.svg';
import scrapeUrl, {ReactComponent as scrape} from '@res/icons/icon__scrape.svg';
import scrape_editUrl, {ReactComponent as scrape_edit} from '@res/icons/icon__scrape_edit.svg';
import sendUrl, {ReactComponent as send} from '@res/icons/icon__send.svg';
import shipment_boxesUrl, {ReactComponent as shipment_boxes} from '@res/icons/icon__shipment_boxes.svg';
import shipment_planeUrl, {ReactComponent as shipment_plane} from '@res/icons/icon__shipment_plane.svg';
import showUrl, {ReactComponent as show} from '@res/icons/icon__show.svg';
import successToastUrl, {ReactComponent as successToast} from '@res/icons/icon__successToast.svg';
import syncUrl, {ReactComponent as sync} from '@res/icons/icon__sync.svg';
import translateUrl, {ReactComponent as translate} from '@res/icons/icon__translate.svg';
import triangle_downUrl, {ReactComponent as triangle_down} from '@res/icons/icon__triangle_down.svg';
import triangle_upUrl, {ReactComponent as triangle_up} from '@res/icons/icon__triangle_up.svg';
import vUrl, {ReactComponent as v} from '@res/icons/icon__v.svg';
import xUrl, {ReactComponent as x} from '@res/icons/icon__x.svg';


const genIcon = (Icon: ElementType) =>
	(props: HTMLAttributes<HTMLSpanElement>) => <div {...props} className={_className('ll_v_c match_height flex__justify-center ts-icon', props.className)}>
		<Icon/>
	</div>;

const genIconV4 = (icon: ElementType) =>
	(props: HTMLAttributes<HTMLDivElement>) => {
		const className = _className('ts-icon__v4', props.className);
		return <div {...props} className={className} style={{...props.style, WebkitMaskImage: `url(${icon})`, maskImage: `url(${icon})`}}/>;
	};

const AllIcons = {

	add: genIcon(add),
	arrowDown: genIcon(arrowDown),
	bell: genIcon(bell),
	bin: genIcon(bin),
	clean: genIcon(clean),
	close: genIcon(close),
	copy: genIcon(copy),
	customer: genIcon(customer),
	customer_order: genIcon(customer_order),
	debug: genIcon(debug),
	decision_tree: genIcon(decision_tree),
	delete_x: genIcon(delete_x),
	document: genIcon(document),
	documents: genIcon(documents),
	download: genIcon(download),
	download_csv: genIcon(download_csv),
	edit: genIcon(edit),
	email: genIcon(email),
	errorToast: genIcon(errorToast),
	file_export: genIcon(file_export),
	file_import: genIcon(file_import),
	infoToast: genIcon(infoToast),
	invoice: genIcon(invoice),
	invoices: genIcon(invoices),
	list: genIcon(list),
	location: genIcon(location),
	lock: genIcon(lock),
	magic_stick: genIcon(magic_stick),
	menu: genIcon(menu),
	missing_variants: genIcon(missing_variants),
	payment: genIcon(payment),
	print: genIcon(print),
	product: genIcon(product),
	remove: genIcon(remove),
	save: genIcon(save),
	scrape: genIcon(scrape),
	scrape_edit: genIcon(scrape_edit),
	send: genIcon(send),
	shipment_boxes: genIcon(shipment_boxes),
	shipment_plane: genIcon(shipment_plane),
	show: genIcon(show),
	successToast: genIcon(successToast),
	sync: genIcon(sync),
	translate: genIcon(translate),
	triangle_down: genIcon(triangle_down),
	triangle_up: genIcon(triangle_up),
	v: genIcon(v),
	x: genIcon(x),
};

const AllIconsV4 = {

	add: genIconV4(addUrl),
	arrowDown: genIconV4(arrowDownUrl),
	bell: genIconV4(bellUrl),
	bin: genIconV4(binUrl),
	clean: genIconV4(cleanUrl),
	close: genIconV4(closeUrl),
	copy: genIconV4(copyUrl),
	customer: genIconV4(customerUrl),
	customer_order: genIconV4(customer_orderUrl),
	debug: genIconV4(debugUrl),
	decision_tree: genIconV4(decision_treeUrl),
	delete_x: genIconV4(delete_xUrl),
	document: genIconV4(documentUrl),
	documents: genIconV4(documentsUrl),
	download: genIconV4(downloadUrl),
	download_csv: genIconV4(download_csvUrl),
	edit: genIconV4(editUrl),
	email: genIconV4(emailUrl),
	errorToast: genIconV4(errorToastUrl),
	file_export: genIconV4(file_exportUrl),
	file_import: genIconV4(file_importUrl),
	infoToast: genIconV4(infoToastUrl),
	invoice: genIconV4(invoiceUrl),
	invoices: genIconV4(invoicesUrl),
	list: genIconV4(listUrl),
	location: genIconV4(locationUrl),
	lock: genIconV4(lockUrl),
	magic_stick: genIconV4(magic_stickUrl),
	menu: genIconV4(menuUrl),
	missing_variants: genIconV4(missing_variantsUrl),
	payment: genIconV4(paymentUrl),
	print: genIconV4(printUrl),
	product: genIconV4(productUrl),
	remove: genIconV4(removeUrl),
	save: genIconV4(saveUrl),
	scrape: genIconV4(scrapeUrl),
	scrape_edit: genIconV4(scrape_editUrl),
	send: genIconV4(sendUrl),
	shipment_boxes: genIconV4(shipment_boxesUrl),
	shipment_plane: genIconV4(shipment_planeUrl),
	show: genIconV4(showUrl),
	successToast: genIconV4(successToastUrl),
	sync: genIconV4(syncUrl),
	translate: genIconV4(translateUrl),
	triangle_down: genIconV4(triangle_downUrl),
	triangle_up: genIconV4(triangle_upUrl),
	v: genIconV4(vUrl),
	x: genIconV4(xUrl),
};

export type IconsType = typeof AllIcons
export type IconKey = keyof IconsType

export const ICONS: IconsType = AllIcons;
export const ICONSV4: typeof AllIconsV4 = AllIconsV4;
export const ICON_KEYS: IconKey[] = _keys(AllIcons);
