"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_DragAndDrop = void 0;
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
require("./TS_DragAndDrop.scss");
const Layouts_1 = require("../Layouts");
const ts_common_1 = require("@nu-art/ts-common");
const DND_Styles = {
    Idle: 'idle',
    Dragging: 'dragging',
    Positive: 'positive',
    Negative: 'negative',
    PartialNegative: 'partial',
};
const timeoutSeconds = 2000;
class TS_DragAndDrop extends ComponentSync_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.inputRef = React.createRef();
        this.timers = [];
        this.onSelect = (e) => {
            if (e.target.files) {
                this.updateFileArray(Object.values(e.target.files));
            }
        };
        this.extractPayload = (ev) => {
            const files = TS_DragAndDrop.extractContent(ev);
            if (files.length === 0) {
                this.setState({ dndState: 'Negative' });
                this.timers.push(setTimeout(() => this.setState({ dndState: 'Idle' }), timeoutSeconds));
                return;
            }
            return files;
        };
        this.onDrop = (ev) => {
            ev.preventDefault();
            const files = this.extractPayload(ev);
            files && this.updateFileArray(files);
            TS_DragAndDrop.removeDragData(ev);
        };
        this.updateFileArray = (_files) => {
            const files = this.props.validate(_files);
            const acceptedFiles = files.filter(file => file.accepted).map(file => file.file);
            const rejectedFiles = files.filter(file => !file.accepted).map(file => file.file);
            let resultState = 'PartialNegative';
            if (acceptedFiles.length === 0)
                resultState = 'Negative'; // all files rejected
            else if (rejectedFiles.length === 0)
                resultState = 'Positive'; // all files accepted
            this.props.onChange(acceptedFiles, rejectedFiles);
            this.setState({ dndState: resultState });
        };
        this.onDragEnter = (ev) => {
            ev.preventDefault();
            this.setState({ dndState: 'Dragging' });
        };
        this.onDragOver = (ev) => {
            ev.preventDefault();
            if (this.state.dndState !== 'Idle')
                return;
            const dndState = (ev.dataTransfer.items && ev.dataTransfer.items.length > 0) ? 'Dragging' : 'Negative';
            this.setState({ dndState });
        };
        this.onDragLeave = (ev) => {
            this.setState({ dndState: 'Idle' });
        };
        this.openFileChooser = () => {
            this.inputRef.current && this.inputRef.current.click();
        };
    }
    deriveStateFromProps(nextProps) {
        return { dndState: 'Idle' };
    }
    componentWillUnmount() {
        this.timers.forEach(k => clearTimeout(k));
    }
    render() {
        var _a;
        return (React.createElement(Layouts_1.LL_VH_C, { className: 'ts-drag-and-drop', id: (_a = this.props) === null || _a === void 0 ? void 0 : _a.id },
            React.createElement("div", { className: `ts-drag-and-drop__content ts-drag-and-drop__${DND_Styles[this.state.dndState]}`, onDrop: this.onDrop, onDragEnter: this.onDragEnter, onDragOver: this.onDragOver, onDragLeave: this.onDragLeave, onClick: this.openFileChooser },
                React.createElement("input", { className: "ts-drag-and-drop__input", id: "fileInput", type: "file", ref: this.inputRef, hidden: true, multiple: true, onChange: this.onSelect }))));
    }
}
TS_DragAndDrop.extractContent = (ev) => Object.values(ev.dataTransfer.files);
TS_DragAndDrop.validateFilesBySuffix = (fileExt) => (files) => {
    const extensions = (0, ts_common_1.asArray)(fileExt);
    return files.map((file) => {
        const accepted = extensions.some(ext => RegExp(`.${ext}$`, 'i').test(file.name));
        return { file, accepted };
    });
};
TS_DragAndDrop.removeDragData = (ev) => {
    if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to remove the drag data
        ev.dataTransfer.items.clear();
    }
    else {
        // Use DataTransfer interface to remove the drag data
        ev.dataTransfer.clearData();
    }
};
exports.TS_DragAndDrop = TS_DragAndDrop;
