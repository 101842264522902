import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {apiWithQuery, StorageKey, ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {
	ApiDef_OrderDeliveryStatus,
	DB_CustomerOrder,
	DBDef_CustomerOrder,
	DeliveryService,
	OrderStatus_Cancelled, OrderStatus_Completed,
	OrderStatus_NeedToPack,
	OrderStatus_OnTheWay,
	OrderStatus_OrderDelivery,
	OrderStatus_PendingPayment,
	OrderStatus_PreOrder,
	OrderStatus_ReturnInProgress,
	OrderStatus_WaitingCurrier,
	OrderStatus_WaitingPickup
} from '@app/shared';
import {BaseDB_ShopifyApiCallerV2} from '@modules/BaseDB_ShopifyApiCaller';
import {calculateDayTime} from '@consts/tools';
import {arrayIncludesAny, currentTimeMillis, sortArray, TS_Object, TypedMap, Week} from '@nu-art/ts-common';
import {DeliveryServices} from '@app/shared/delivery-courier/_tbd-delivery-services';


export interface OnCustomerOrdersUpdated {
	__onCustomerOrdersUpdated: (...params: ApiCallerEventType<DB_CustomerOrder>) => void;
}

export function calculateDayTimeGreeting() {
	const daytime = calculateDayTime();
	switch (daytime) {
		case 'night':
			return 'לילה טוב';
		case 'morning':
			return 'בוקר טוב';
		case 'noon':
			return 'צהרים טובים';
		case 'after-noon':
			return 'אחר הצהרים טובים';
		case 'evening':
			return 'ערב טוב';

	}
}

export const OrderStatus_Groups = [
	{
		key: 'blocked',
		label: 'Blocked',
		status: [OrderStatus_PendingPayment, OrderStatus_PreOrder]
	},
	{
		key: 'to-prepare',
		label: 'To Prepare',
		status: [OrderStatus_OrderDelivery, OrderStatus_NeedToPack]
	},
	{
		key: 'on-route',
		label: 'On Route',
		status: [OrderStatus_WaitingCurrier, OrderStatus_OnTheWay]
	},
	{
		key: 'awaiting-pickup',
		label: 'Pickups',
		status: [OrderStatus_WaitingPickup]
	},
	{
		key: 'returns',
		label: 'Returns',
		status: [OrderStatus_ReturnInProgress]
	},
	{
		key: 'completed',
		label: 'Completed',
		status: [OrderStatus_Completed]
	},
	{
		key: 'cancelled',
		label: 'Cancelled',
		status: [OrderStatus_Cancelled]
	},
];

export const dispatch_onCustomerOrdersListChanged = new ThunderDispatcher<OnCustomerOrdersUpdated, '__onCustomerOrdersUpdated'>('__onCustomerOrdersUpdated');
const filter = OrderStatus_Groups.reduce((filter, orderStatus) => {
	filter[orderStatus.key] = new StorageKey<boolean>(`order-filter__${orderStatus.key}`);
	return filter;
}, {
	productId: new StorageKey<string>('order-filter__productId'),
	fromDate: new StorageKey<number>('order-filter__fromDate'),
	toDate: new StorageKey<number>('order-filter__toDate'),
} as TypedMap<StorageKey<any>>);

export class ModuleFE_CustomerOrder_Class
	extends BaseDB_ShopifyApiCallerV2<DB_CustomerOrder, 'storeId' | 'shopifyId'> {

	readonly checkDeliveryStatus;
	readonly orderDelivery;

	constructor() {
		super(DBDef_CustomerOrder, dispatch_onCustomerOrdersListChanged);
		this.checkDeliveryStatus = apiWithQuery(ApiDef_OrderDeliveryStatus.checkDeliveryStatus, (dbOrder) => this.onEntryUpdated(dbOrder, dbOrder));
		this.orderDelivery = apiWithQuery(ApiDef_OrderDeliveryStatus.orderDelivery, (dbOrder) => this.onEntryUpdated(dbOrder, dbOrder));
	}

	protected init() {
		super.init();
	}

	getFilteredOrders() {
		return this.applyFilter(this.cache.allMutable());
	}

	filter = filter;

	private applyFilter(orders: DB_CustomerOrder[]) {
		const fromDate = filter.fromDate.get(currentTimeMillis() - 8 * Week);
		const toDate = filter.toDate.get(currentTimeMillis());
		orders = orders.filter(order => {
			if (order.timestampCreated < fromDate && order.timestampCompleted < fromDate)
				return false;

			return order.timestampCompleted < toDate;
		});

		orders = sortArray(orders, order => order.timestampCreated, true);
		orders = orders.filter(order => {
			// if (!filter.show_cancelled.get(true) && order.status.includes('cancelled'))
			// 	return false;
			//
			// const deliveryDetail = order.deliveryDetails?.[order.deliveryDetails.length - 1];
			// if (filter.show_onRoute.get(true) && deliveryDetail && deliveryDetail?.status !== 'delivered')
			// 	return true;
			//
			// if (!filter.show_completed.get(true) && order.status.includes('completed'))
			// 	return false;
			//
			// if (filter.show_preOrder.get(true) && order.tags?.includes('pre-order'))
			// 	return true;
			//
			// if (filter.show_pendingPayment.get(true) && order.status.includes('pending-payment'))
			// 	return true;
			//
			// if (filter.show_needPacking.get(true) && !order.packagingStatus.isReady)
			// 	return true;
			//
			// if (filter.show_isPacked.get(true) && order.packagingStatus.isReady)
			// 	return true;
			//
			// if (filter.show_delivered.get(true) && deliveryDetail && deliveryDetail?.status === 'delivered')
			// 	return true;

			//
			// if (!filter.show_completed.get(true) && order.status === 'in-progress')
			// 	return false;
			//
			//
			const show = OrderStatus_Groups.find(orderStatus => {
				return filter[orderStatus.key].get(true) && arrayIncludesAny(order.status, orderStatus.status);
			});

			return !!show;
		});

		const productId = filter.productId.get();
		if (productId)
			orders = orders.filter(order => order.itemIds.find(item => item.productId === productId));

		return orders;
	}

	prepareOrderInput(order: Partial<DB_CustomerOrder>) {
		const greeting = () => calculateDayTimeGreeting();
		const toPay = order.paid?.value;
		let input: TS_Object = {greeting, orderNumber: order.orderNumber};

		if (toPay)
			input = {...input, toPay};

		const deliveryDetails = order.deliveryDetails?.[order.deliveryDetails!.length - 1];
		if (deliveryDetails)
			input = {
				...input,
				deliveryNumber: deliveryDetails?.number,
				deliveryStatusLink: DeliveryServices.find((s: DeliveryService) => s.key === deliveryDetails.type)?.customer?.checkStatus
			};

		return input;
	}
}

export const ModuleFE_CustomerOrder = new ModuleFE_CustomerOrder_Class();
export const _ModuleFE_CustomerOrder = ModuleFE_CustomerOrder as ModuleFE_BaseApi<DB_CustomerOrder, any>;

//a7160de3.67e42afe8d275266e0376ebe5c22d984138