// import {dialogGenerator} from '@component/Dialog_ApiGenV2';
// import {ModuleFE_Translations} from '@modules/index';
//
//
// export const Dialogs = {
// 	translations: dialogGenerator(ModuleFE_Translations, {
// 		typeName: () => 'Translation',
// 		contextLabel: item => item.content
// 	})
// };

import {DB_Object, Default_UniqueKey, PreDB, removeItemFromArray} from '@nu-art/ts-common';
import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_Dialog, State_TSDialog, TS_PropRenderer} from '@nu-art/thunderstorm/frontend';
import {DB_Customer, DB_MessageTemplate, DB_ProductTemplate, DB_Shipment, DB_ShipmentInvoice, DB_TranslationEntry} from '@app/shared';
import {LOCALE} from '@res/locale';
import {ModuleFE_MessageTemplates} from '../../new-order/customer-care/messages-templates/modules/ModuleFE_MessageTemplates';
import {ModuleFE_Shipments} from '@modules/store';
import {ModuleFE_CustomerContact_, ModuleFE_ProductTemplate} from '@modules/shopify';
import * as React from 'react';
import {DialogRenderers} from '../../core/dialogs/types';
import {Dialog_DeleteDbItem} from '../../core/dialogs/Dialog_DeleteDbItem';
import {Dialog_UpdateDbItem} from '../../core/dialogs/Dialog_UpdateDbItem';
import {ModuleFE_Translations} from '@modules/ModuleFE_Translations';
import {DropDown} from '@pah/app-dropdown';
import {Dialog_CreateDbItem, State_CreateDialog} from '../../core/dialogs/Dialog_CreateDbItem';


function prepareDelete<T extends DB_Object, Ks extends keyof PreDB<T> = Default_UniqueKey>(module: ModuleFE_BaseApi<T, Ks>, renderers: DialogRenderers<T>) {
	return (item: T, onCompleted?: (item: T) => Promise<void>) => {
		ModuleFE_Dialog.show(<Dialog_DeleteDbItem<T, Ks> item={item} module={module} renderers={renderers} onCompleted={onCompleted}/>);
	};
}

function prepareUpsert<T extends DB_Object, Ks extends keyof PreDB<T> = Default_UniqueKey, State extends State_TSDialog = State_TSDialog>(module: ModuleFE_BaseApi<T, Ks>, renderers: DialogRenderers<T, State>, action?: () => void) {
	return (item: PreDB<T>, onCompleted?: (item: T) => Promise<void>) => {
		ModuleFE_Dialog.show(<Dialog_UpdateDbItem<T, Ks, State> item={item} module={module} renderers={renderers} onCompleted={onCompleted} action={action}/>);
	};
}

function prepareCreate<T extends DB_Object, Ks extends keyof PreDB<T> = Default_UniqueKey, State extends State_CreateDialog<T> = State_CreateDialog<T>>(module: ModuleFE_BaseApi<T, Ks>, renderers: DialogRenderers<T, State>) {
	return (onCompleted?: (item: T) => Promise<void>) => {
		ModuleFE_Dialog.show(<Dialog_CreateDbItem<T, Ks, State> module={module} renderers={renderers} onCompleted={onCompleted}/>);
	};
}

const Renderers_MessageTemplate: DialogRenderers<DB_MessageTemplate> = {
	entityName: () => `Message Template`,
	title: (item) => `template '${item.label}'`,
	body: (item) => `Message: '${item.template}'`,
};

const Renderers_Shipment: DialogRenderers<DB_Shipment> = {
	entityName: () => `shipment`,
	title: () => `shipment`,
	body: (item) => `Are you sure you would like to Delete this shipment?`,
};

const Renderers_Shipment_DeleteInvoice = (invoice: DB_ShipmentInvoice) => {
	const renderers: DialogRenderers<DB_Shipment> = {
		entityName: () => `shipment`,
		title: () => `Delete Invoice`,
		body: (item) => `Are you sure you would like to Delete this Invoice '${invoice}'?`,
	};
	return renderers;
};

const Renderers_Customer: DialogRenderers<DB_Customer> = {
	entityName: () => `Customer`,
	title: (item) => LOCALE.DialogTitle_DeleteCustomer(`${item.lastName} ${item.firstName}`),
	body: (item) => `Are you sure you would like to Delete this Customer?`,
};

const Renderers_ProductTemplate: DialogRenderers<DB_ProductTemplate> = {
	entityName: () => 'Product Template',
	title: () => 'Product Template',
	body: (item) => `Are you sure you want to Delete this Product Template'${item.label}'?`,
};
const Renderers_ProductTemplate_Upsert: DialogRenderers<DB_ProductTemplate, State_CreateDialog<DB_ProductTemplate> & { productId?: string }> = {
	entityName: () => 'Product Template',
	title: () => 'Product Template',
	body: (item, state, setState) => <TS_PropRenderer.Vertical label={'Translation:'}>
		<DropDown.Product
			selected={state.productId}
			onSelected={product => setState({productId: product._id})}/>
	</TS_PropRenderer.Vertical>,
};

const Renderers_Translation_Upsert: DialogRenderers<DB_TranslationEntry> = {
	entityName: () => `Translation`,
	title: () => `Translation`,
	body: (item) => <TS_PropRenderer.Vertical label={'Translation:'}>
		{item.content}
	</TS_PropRenderer.Vertical>,
};

export const Dialogs = {
	MessageTemplate: {
		delete: prepareDelete<DB_MessageTemplate>(ModuleFE_MessageTemplates, Renderers_MessageTemplate)
	},
	ProductTemplate: {
		create: prepareCreate<DB_ProductTemplate, '_id', State_CreateDialog<DB_ProductTemplate> & {
			productId?: string
		}>(ModuleFE_ProductTemplate, Renderers_ProductTemplate_Upsert),
		delete: prepareDelete<DB_ProductTemplate>(ModuleFE_ProductTemplate, Renderers_ProductTemplate)
	},
	Shipment: {
		upsert: prepareUpsert<DB_Shipment>(ModuleFE_Shipments, Renderers_Shipment),
		delete: prepareDelete<DB_Shipment>(ModuleFE_Shipments, Renderers_Shipment),
		deleteInvoice: (shipment: DB_Shipment, invoice: DB_ShipmentInvoice) => prepareUpsert<DB_Shipment>(ModuleFE_Shipments, Renderers_Shipment_DeleteInvoice(invoice), () => removeItemFromArray(shipment.invoices!, invoice)),
	},
	Customer: {
		delete: prepareDelete<DB_Customer>(ModuleFE_CustomerContact_, Renderers_Customer),
	},
	Translation: {
		upsert: prepareUpsert<DB_TranslationEntry, 'sharedId' | 'locale'>(ModuleFE_Translations, Renderers_Translation_Upsert),
	}
};