"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_DialogOverlay = void 0;
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
const TS_Overlay_1 = require("../TS_Overlay");
const tools_1 = require("../../utils/tools");
const ModuleFE_Dialog_1 = require("../../component-modules/ModuleFE_Dialog");
require("./TS_DialogOverlay.scss");
class TS_DialogOverlay extends ComponentSync_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.__showDialog = (model) => {
            if (!model) {
                this.state.models.pop();
            }
            else {
                this.state.models.push(model);
            }
            this.forceUpdate();
        };
        this.onOverlayClicked = (e) => {
            (0, tools_1.stopPropagation)(e);
            //Exit if click should not close this current dialog
            if (!this.state.models[0].closeOverlayOnClick())
                return;
            //Close there is only one dialog
            if (this.state.models.length === 1)
                return ModuleFE_Dialog_1.ModuleFE_Dialog.close();
            //Close only this dialog if more than one
            this.state.models.pop();
            this.forceUpdate();
        };
    }
    deriveStateFromProps(nextProps) {
        return { models: [] };
    }
    render() {
        if (!this.state.models.length)
            return '';
        return (React.createElement("div", { className: "ts-dialog__overlay" },
            React.createElement(TS_Overlay_1.TS_Overlay, { showOverlay: true, onClickOverlay: this.onOverlayClicked }, this.state.models.map((model, i) => {
                if (i === this.state.models.length - 1)
                    //This model content is wrapped in a div to keep the React hierarchy. if you remove it, the model stack won't work.
                    return React.createElement("div", { key: i }, model.content);
                return React.createElement("div", { key: i, style: { display: 'none', height: 0 } }, model.content);
            }))));
    }
}
exports.TS_DialogOverlay = TS_DialogOverlay;
