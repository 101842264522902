"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_OrderDeliveryStatus = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const db_def_1 = require("./db-def");
const ts_common_1 = require("@nu-art/ts-common");
exports.ApiDef_OrderDeliveryStatus = {
    checkDeliveryStatus: { method: thunderstorm_1.HttpMethod.GET, path: `v1/${db_def_1.DBDef_CustomerOrder.entityName}/check-delivery-status` },
    orderDelivery: { method: thunderstorm_1.HttpMethod.GET, path: `v1/${db_def_1.DBDef_CustomerOrder.entityName}/order-delivery`, timeout: 2 * ts_common_1.Minute }
};
