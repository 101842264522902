"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_TextArea = void 0;
const React = require("react");
const TS_BaseInput_1 = require("./TS_BaseInput");
require("./TS_TextArea.scss");
const tools_1 = require("../../utils/tools");
class TS_TextArea extends TS_BaseInput_1.TS_BaseInput {
    constructor() {
        super(...arguments);
        this.onKeyDown = (ev) => {
            var _a, _b;
            if (!(ev.shiftKey || ev.altKey)) {
                if (ev.ctrlKey || ev.metaKey) {
                    if (ev.key === 'Enter') {
                        ev.persist();
                        const value = ev.currentTarget.value;
                        //@ts-ignore - despite what typescript says, ev.target does have a blur function.
                        ev.target.blur();
                        if (this.props.onAccept) {
                            this.props.onAccept(value, ev);
                            ev.stopPropagation();
                        }
                    }
                    return;
                }
                if (ev.key === 'Escape' && this.props.onCancel) {
                    this.props.onCancel();
                    ev.stopPropagation();
                }
            }
            (_b = (_a = this.props).onKeyDown) === null || _b === void 0 ? void 0 : _b.call(_a, ev);
        };
    }
    render() {
        const _a = this.props, { onAccept, focus, enable } = _a, props = __rest(_a, ["onAccept", "focus", "enable"]);
        return React.createElement("textarea", Object.assign({}, props, { ref: input => {
                if (this.ref || !input)
                    return;
                this.ref = input;
                this.props.focus && this.ref.focus();
            }, onBlur: (event) => {
                var _a, _b;
                this.ref = undefined;
                const value = event.target.value;
                this.setState({ value });
                (_b = (_a = this.props).onBlur) === null || _b === void 0 ? void 0 : _b.call(_a, value, event);
            }, disabled: this.props.disabled, name: this.props.name || this.props.id, key: this.props.id, id: this.props.id, className: (0, tools_1._className)('ts-textarea', this.props.className), style: this.props.style, value: this.state.value, placeholder: this.props.placeholder, onChange: this.changeValue, onKeyDown: this.props.onKeyDown || this.onKeyDown, autoComplete: this.props.autoComplete ? 'on' : 'off', spellCheck: this.props.spellCheck }));
    }
}
exports.TS_TextArea = TS_TextArea;
