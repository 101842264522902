"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_ChangePassword = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const ModuleFE_Account_1 = require("../../modules/ModuleFE_Account");
const consts_1 = require("../../core/consts");
class Component_ChangePassword extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        // ######################## Logic ########################
        this.submitNewPassword = async () => {
            var _a, _b;
            if (!this.state.newPassword || !this.state.newPasswordCheck) {
                this.logError('No password or password check');
                return;
            }
            try {
                if (!this.state.shouldGiveCurrentPassword)
                    await ModuleFE_Account_1.ModuleFE_Account.vv1.setPassword({
                        password: this.state.newPassword,
                        passwordCheck: this.state.newPasswordCheck,
                    }).executeSync();
                else {
                    if (!this.state.currentPassword) {
                        this.logError('No current password given');
                        return;
                    }
                    await ModuleFE_Account_1.ModuleFE_Account.vv1.changePassword({
                        oldPassword: this.state.currentPassword,
                        password: this.state.newPassword,
                        passwordCheck: this.state.newPasswordCheck,
                    }).executeSync();
                }
                (_b = (_a = this.props).postSubmitAction) === null || _b === void 0 ? void 0 : _b.call(_a);
            }
            catch (e) {
                this.logError(e);
            }
        };
        // ######################## Render ########################
        this.renderCurrentPassword = () => {
            if (!this.state.shouldGiveCurrentPassword)
                return '';
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Current Password' },
                React.createElement(frontend_1.TS_Input, { type: 'password', value: this.state.currentPassword, onChange: val => this.setState({ currentPassword: val }) }));
        };
    }
    // ######################## Life Cycle ########################
    deriveStateFromProps(nextProps, state) {
        state !== null && state !== void 0 ? state : (state = this.state ? Object.assign({}, this.state) : {});
        if (!ModuleFE_Account_1.ModuleFE_Account.accountId)
            throw new ts_common_1.ThisShouldNotHappenException('Rendering a change password component without user logged in');
        state.shouldGiveCurrentPassword = consts_1.SessionKey_Account_FE.get().hasPassword;
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'ts-account__change-password' },
            this.renderCurrentPassword(),
            React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'New Password' },
                React.createElement(frontend_1.TS_Input, { type: 'password', value: this.state.newPassword, onChange: val => this.setState({ newPassword: val }) })),
            React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'New Password Check' },
                React.createElement(frontend_1.TS_Input, { type: 'password', value: this.state.newPasswordCheck, onChange: val => this.setState({ newPasswordCheck: val }) })),
            React.createElement(frontend_1.TS_BusyButton, { onClick: this.submitNewPassword }, "Submit"));
    }
}
exports.Component_ChangePassword = Component_ChangePassword;
