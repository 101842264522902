import * as React from 'react';
import {Component} from 'react';
import {LOCALE} from '@res/locale';
import {Address, cleanupPhoneNumberForUI, DB_CustomerOrder} from '@app/shared';
import {hasRTL, resolveDirectionStyleRTL} from '@modules/index';
import {_className, LL_H_C, LL_V_L, TS_Link, TS_Space} from '@nu-art/thunderstorm/frontend';
import {filterInstances} from '@nu-art/ts-common';
import {ICONSV4} from '@res/icons';
import {TS_CopyToClipboard} from '@nu-art/thunderstorm/frontend/components/TS_CopyToClipboard';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';


export class Component_LinkToGoogleMaps
	extends Component<{ address: string }, any> {
	render() {
		return <TS_Link
			url={'https://maps.google.com/'}
			params={{q: this.props.address}}
			target={'_blank'}
		>{ICONSV4.location({style: {marginInlineEnd: 8}})}</TS_Link>;
	}
}

export const Component_CustomerName = (props: { address: Address }) => {
	if (!props.address.contact)
		return <></>;

	const contactName = `${props.address.contact?.firstName} ${props.address.contact?.lastName}`;
	const className = _className('match_width', hasRTL(contactName) ? 'ts-languages__rtl' : 'unset');
	return <TS_CopyToClipboard className={className} textToCopy={contactName}/>;
};

export const Component_CustomerPhoneNumber = (props: { address: Address }) => {
	const phoneNumber = cleanupPhoneNumberForUI(props.address.contact?.phoneNumber);
	if (!phoneNumber)
		return <></>;

	return <TS_CopyToClipboard className="match_width ts-languages__rtl" textToCopy={phoneNumber}/>;
};

export const Component_CustomerAddress = (props: { address: Address }) => {
	const address = props.address;
	const cityAndCountry = `${address.city}, ${address.country}`;

	const streetAndNumber = `${address.street} ${address.houseNumber}, ${cityAndCountry}`;
	let moreDetails = '';
	if (address.apartment) {
		const entrance = address.apartment.entrance ? `כניסה ${address.apartment.entrance}` : undefined;
		const floor = address.apartment.floor ? `קומה ${address.apartment.floor}` : undefined;
		const number = address.apartment.number ? `דירה ${address.apartment.number}` : undefined;
		moreDetails = filterInstances([entrance, floor, number]).join(', ');
	}

	return <>
		<LL_V_L style={resolveDirectionStyleRTL(address.street, {justifyContent: 'space-between'})}>
			<TS_CopyToClipboard textToCopy={streetAndNumber}/>
			<TS_CopyToClipboard textToCopy={moreDetails}/>
		</LL_V_L>
	</>;
};

export class Component_CustomerDetails
	extends Component<{ address: Address }, any> {
	render() {
		const address = this.props.address;

		return <LL_V_L className="v-gap__s match_width">
			<Component_CustomerName address={address}/>
			<Component_CustomerPhoneNumber address={address}/>
			<Component_CustomerAddress address={address}/>
		</LL_V_L>;
	}

}

export class Component_Address
	extends Item_Editor<DB_CustomerOrder> {

	render() {
		const order = this.props.editable.item;
		const address = order.shippingAddress || order.billingAddress;
		if (!address)
			return '';

		const blob = JSON.parse(order.blob!);
		return (
			<LL_V_L className="customer-order__section">
				<LL_H_C className="customer-order__title">
					<div>{LOCALE.ShippingAddress()}</div>
					<Component_LinkToGoogleMaps address={address.fullFormattedAddress}/>
				</LL_H_C>
				<Component_CustomerDetails address={address}/>
				<TS_Space height={5}/>
				<hr className="match_width"/>
				<TS_Space height={5}/>
				<div>{blob.shipping_address?.address1}</div>
				<div>{blob.shipping_address?.address2}</div>
				<div>{blob.shipping_address?.city}</div>
			</LL_V_L>
		);
	}
}