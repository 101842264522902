import React from 'react';
import {Editor,} from 'react-draft-wysiwyg';
import {ContentState, convertToRaw, EditorState} from 'draft-js';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {AppPage} from '@nu-art/thunderstorm/frontend';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class Pg_Component
	extends AppPage {
	state = {
		editorState: EditorState.createEmpty(),
		pageTitle: () => PgDev_DraftJs.name

	};

	constructor(p: {}) {
		super(p);
	}

	protected deriveStateFromProps(nextProps: {}) {
		const contentBlock = htmlToDraft('<p>asdfasdfasdf</p>');
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			return {editorState,};
		}
	}

	onEditorStateChange = (editorState: EditorState) => {
		this.setState({
			editorState,
		});
	};

	render() {
		const {editorState} = this.state;
		return (
			<div>
				<Editor
					editorState={editorState}
					onEditorStateChange={this.onEditorStateChange}
				/>
				<textarea
					disabled
					value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
				/>
			</div>
		);
	}
}

export const PgDev_DraftJs = {name: 'DevTool - DraftJs', renderer: Pg_Component};
