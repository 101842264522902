import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {DB_ProductTag, DBDef_ProductTag,} from '@app/shared';


export interface OnProductTagsUpdated {
	__onProductTagsUpdated: (...params: ApiCallerEventType<DB_ProductTag>) => void;
}

export const dispatch_onProductTagsUpdated = new ThunderDispatcher<OnProductTagsUpdated, '__onProductTagsUpdated'>('__onProductTagsUpdated');

export class ModuleFE_ProductTags_Class
	extends ModuleFE_BaseApi<DB_ProductTag, 'storeId' | 'title'> {

	constructor() {
		super(DBDef_ProductTag, dispatch_onProductTagsUpdated);
	}
}

export const ModuleFE_ProductTags = new ModuleFE_ProductTags_Class();
export const ModuleFE_ProductTags_ = ModuleFE_ProductTags as unknown as ModuleFE_BaseApi<any, any>;
