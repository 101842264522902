"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirebaseSessionFE = void 0;
/**
 * Created by tacb0ss on 19/09/2018.
 */
const ts_common_1 = require("@nu-art/ts-common");
const MessagingWrapperFE_1 = require("../messaging/MessagingWrapperFE");
const AnalyticsWrapperFE_1 = require("../analytics/AnalyticsWrapperFE");
const DatabaseWrapperFE_1 = require("../database/DatabaseWrapperFE");
const auth_1 = require("firebase/auth");
const app_1 = require("firebase/app");
// import auth = firebase.auth;
class FirebaseSessionFE extends ts_common_1.Logger {
    constructor(sessionName, config) {
        super(`firebase: ${sessionName}`);
        this.sessionName = sessionName;
        this.config = config;
    }
    connect() {
        this.app = (0, app_1.initializeApp)(this.config, this.sessionName);
    }
    /**
     * Returns an instance of the MessagingWrapperFE class if it exists, or creates and returns a new instance if it does not
     */
    getMessaging() {
        if (this.messaging)
            return this.messaging;
        return this.messaging = new MessagingWrapperFE_1.MessagingWrapperFE(this.app);
    }
    /**
     * Returns an instance of the AnalyticsWrapperFE class if it exists, or creates and returns a new instance if it does not
     */
    getAnalytics() {
        if (this.analytics)
            return this.analytics;
        return this.analytics = new AnalyticsWrapperFE_1.AnalyticsWrapperFE(this.app);
    }
    /**
     * Returns an instance of the DatabaseWrapperFE class if it exists, or creates and returns a new instance if it does not
     */
    getDatabase() {
        if (this.database)
            return this.database;
        return this.database = new DatabaseWrapperFE_1.DatabaseWrapperFE(this.app);
    }
    /**
     * Authenticates the user with the given token
     * @param token
     */
    async signInWithToken(token) {
        return (0, auth_1.signInWithCustomToken)((0, auth_1.getAuth)(this.app), token);
    }
    /**
     * Signs out the currently authenticated user
     */
    async signOut() {
        return (0, auth_1.signOut)((0, auth_1.getAuth)(this.app));
    }
    getProjectId() {
        if (!this.config)
            throw new ts_common_1.ThisShouldNotHappenException('Missing config. Probably init not resolved yet!');
        if (!this.config.projectId)
            throw new ts_common_1.ThisShouldNotHappenException('Could not deduce project id from session config.. if you need the functionality.. add it to the config!!');
        return this.config.projectId;
    }
}
exports.FirebaseSessionFE = FirebaseSessionFE;
