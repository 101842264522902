import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {DB_ProductVariantConfig, DBDef_ProductVariantConfig} from '@app/shared';


export interface OnProductVariantConfigsUpdated {
	__onProductVariantConfigsUpdated: (...params: ApiCallerEventType<DB_ProductVariantConfig>) => void;
}

export const dispatch_onProductVariantConfigListChanged = new ThunderDispatcher<OnProductVariantConfigsUpdated, '__onProductVariantConfigsUpdated'>('__onProductVariantConfigsUpdated');

export class ModuleFE_ProductVariantConfigs_Class
	extends ModuleFE_BaseApi<DB_ProductVariantConfig, 'shopifyId' | 'storeId'> {

	constructor() {
		super(DBDef_ProductVariantConfig, dispatch_onProductVariantConfigListChanged);
	}
}

export const ModuleFE_ProductVariantConfigs = new ModuleFE_ProductVariantConfigs_Class();

export const ModuleFE_ProductVariantConfigs_ = ModuleFE_ProductVariantConfigs as unknown as ModuleFE_BaseApi<any, any>;
