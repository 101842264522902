"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Radio = void 0;
const React = require("react");
require("./TS_Radio.scss");
const ComponentSync_1 = require("../../core/ComponentSync");
const tools_1 = require("../../utils/tools");
class TS_Radio extends ComponentSync_1.ComponentSync {
    constructor() {
        // ######################### Life Cycle #########################
        super(...arguments);
        // ######################### Logic #########################
        this.handleOptionClick = (e, value) => {
            (0, tools_1.stopPropagation)(e);
            if (this.state.disabled)
                return;
            const prevValue = this.state.checked;
            const nextValue = (this.props.canUnselect && value === prevValue)
                ? undefined
                : value;
            this.setState({ checked: nextValue }, () => {
                var _a, _b;
                (_b = (_a = this.props).onCheck) === null || _b === void 0 ? void 0 : _b.call(_a, nextValue, prevValue);
                this.forceUpdate();
            });
        };
        // ######################### Render #########################
        this.renderRadioOption = (value) => {
            const className = (0, tools_1._className)('ts-radio__container', (value === this.state.checked ? 'checked' : ''));
            return React.createElement("label", { key: String(value), htmlFor: `${this.props.groupName}-${value}`, className: className, onClick: (e) => this.handleOptionClick(e, value) },
                this.renderRadioButton(value),
                this.renderRadioLabel(value));
        };
        this.renderRadioLabel = (value) => {
            var _a;
            const renderer = (_a = this.props.labelRenderer) !== null && _a !== void 0 ? _a : ((value) => String(value));
            return React.createElement("span", { className: 'ts-radio__label' }, renderer(value));
        };
        this.renderRadioButton = (value) => {
            return React.createElement("span", { className: 'ts-radio__button' });
        };
    }
    deriveStateFromProps(nextProps) {
        var _a;
        return {
            values: nextProps.values,
            checked: nextProps.checked || ((_a = this.state) === null || _a === void 0 ? void 0 : _a.checked) || undefined,
            disabled: nextProps.disabled,
        };
    }
    render() {
        var _a;
        const className = (0, tools_1._className)('ts-radio', ((_a = this.state) === null || _a === void 0 ? void 0 : _a.disabled) ? 'disabled' : undefined, this.props.className);
        return React.createElement("div", { className: className }, this.props.values.map(value => {
            return this.renderRadioOption(value);
        }));
    }
}
exports.TS_Radio = TS_Radio;
