import * as React from 'react';
import {LOCALE} from '@res/locale';
import {DB_CustomerOrder, DB_OrderShippingMethod} from '@app/shared';
import {LL_H_C, LL_V_L} from '@nu-art/thunderstorm/frontend';
import {ICONSV4} from '@res/icons';
import {Dialog_ShippingSticker} from '@pah/customer-order/dialogs/Dialog_ShippingSticker';
import {DropDown} from '@pah/app-dropdown';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_ShippingMethod} from '@modules/shopify';


export class Component_ShippingMethod
	extends Item_Editor<DB_CustomerOrder, { originalShippingId?: string }> {

	render() {
		const editable = this.props.editable;
		const order = this.props.editable.item;
		const shippingMethod = (ModuleFE_ShippingMethod.cache.unique(editable.item.shippingOptionId));

		return (
			<LL_V_L className=" customer-order__section">
				<LL_H_C className="customer-order__title">
					<div>{LOCALE.DeliveryMethod()}</div>
					{shippingMethod?.isPickup &&
						<ICONSV4.print className="show-shipping-sticker ts-print__gone" onClick={() => Dialog_ShippingSticker.show(order as DB_CustomerOrder)}/>}
				</LL_H_C>

				<LL_H_C className="match_width customer-order__shipping-method">
					<DropDown.ShippingMethod
						selected={shippingMethod as DB_OrderShippingMethod}
						onSelected={shippingMethod => editable.update('shippingOptionId', shippingMethod._id)}/>

					{this.props.originalShippingId !== shippingMethod?._id && <ICONSV4.sync className="show-shipping-sticker ts-print__gone" onClick={() => {
						return editable.update('shippingOptionId', this.props.originalShippingId);
					}}/>}
				</LL_H_C>
			</LL_V_L>
		);
	}
}