import * as React from 'react';
import {
	_className,
	EditableItem,
	genericNotificationAction,
	LL_H_C,
	LL_H_T,
	LL_V_C,
	LL_V_L,
	ModuleFE_RoutingV2,
	ModuleFE_Thunderstorm,
	openContent,
	stopPropagation,
	StorageKey,
	TS_Checkbox,
	TS_Loader,
	TS_Printable,
	TS_Route
} from '@nu-art/thunderstorm/frontend';
import {
	_ModuleFE_CustomerOrder,
	ModuleFE_CustomerContact,
	ModuleFE_CustomerOrder,
	ModuleFE_ShippingMethod,
	ModuleFE_Stores,
	OrderStatus_Groups
} from '@modules/index';
import {
	currentTimeMillis,
	dbObjectToId,
	exists,
	filterInstances,
	formatTimestamp,
	lastElement,
	LogLevel,
	removeItemFromArray,
	Replacer,
	sortArray, Week
} from '@nu-art/ts-common';
import {
	Address,
	DB_CustomerOrder,
	OrderStatus,
	OrderStatus_Cancelled,
	OrderStatus_Completed,
	OrderStatus_NeedToPack,
	OrderStatus_OnTheWay,
	OrderStatus_OrderDelivery,
	OrderStatus_PendingPayment,
	OrderStatus_PreOrder,
	OrderStatus_ReturnInProgress,
	OrderStatus_WaitingCurrier,
	OrderStatus_WaitingPickup
} from '@app/shared';
import * as Routes from '@consts/routes-consts';
import {
	EditableDBItem,
	Item_Editor,
	Item_EditorController,
	Props_SmartComponent,
	Props_SmartPage,
	SmartPage,
	State_SmartComponent
} from '@nu-art/thunderstorm/frontend';
import {Page_CustomerOrder} from './Page_CustomerOrder';
import './Page_CustomerOrder.scss';
import {getOrderStatusClass} from '@pah/customer-order/utils';
import {ICONSV4} from '@res/icons';
import {DropDown} from '@pah/app-dropdown';
import {Component_CustomerAddress, Component_CustomerName, Component_CustomerPhoneNumber} from '@pah/customer-order/components/Component_Address';
import {dispatcher_onShowDebugData} from '@consts/interfaces';
import {Dialog_OrderGraph} from '@pah/customer-order/dialogs/Dialog_OrderGraph';
import {Component_OrderPackedStatus} from '@pah/customer-order/components/Component_PackagingStatus';
import {MessagesSendingMenu} from '../../../new-order/customer-care/messages-templates/components/messages-menu/MessagesSendingMenu';
import {Component_DeliveryStatus} from '@pah/customer-order/components/Component_OrderDetails';
import {dispatch_updateHeaderOption, HeaderOption} from '@page/Page_Workspace/Component_MainHeader';
import {ShippingSticker} from '@pah/customer-order/ShippingSticker/ShippingSticker';
import {Editor_CustomerOrder} from '@pah/customer-order/components/Editor_CustomerOrder';
import {IOrder} from 'shopify-api-node';
import {inferFullAddress} from '@app/shared/utils/address';
import {DateEditor} from '@component/DateRenderer';


type State = {
	orders: DB_CustomerOrder[]
	printStickers?: boolean
	printOrders?: boolean
	selectedItems: string[]
	selectedProductId?: string
}

type FullProps = Props_SmartComponent & Props_SmartPage<DB_CustomerOrder>;
type FullState = State_SmartComponent & State;

class Component_OrdersFilter
	extends React.Component<{ onFilterChanged: () => void }> {
	render() {
		return (
			<LL_V_L className="customer-order__filter-menu v-gap__n">
				{OrderStatus_Groups.map(status => this.renderFilter(status.key, status.label))}
				<DropDown.Product
					canUnselect={true}
					selected={ModuleFE_CustomerOrder.filter.productId.get(undefined)}
					onSelected={product => {
						if (product)
							ModuleFE_CustomerOrder.filter.productId.set(product._id);
						else
							ModuleFE_CustomerOrder.filter.productId.delete();

						this.forceUpdate();
						this.props.onFilterChanged();
					}}/>
				<LL_H_C>
					{DateEditor(ModuleFE_CustomerOrder.filter.fromDate.get(currentTimeMillis() - 8 * Week), (dateInMs) => {
						ModuleFE_CustomerOrder.filter.fromDate.set(dateInMs);
						this.forceUpdate();
						this.props.onFilterChanged();
					})}
					=&gt;
					{DateEditor(ModuleFE_CustomerOrder.filter.toDate.get(currentTimeMillis()), (dateInMs) => {
						ModuleFE_CustomerOrder.filter.toDate.set(dateInMs);
						this.forceUpdate();
						this.props.onFilterChanged();
					})}
				</LL_H_C>
			</LL_V_L>
		);
	}

	renderFilter(filterKey: string, label: string) {
		const filter = ModuleFE_CustomerOrder.filter[filterKey];
		return <LL_H_C key={filterKey} onClick={() => {
			filter.set(!filter.get(true));
			this.forceUpdate();
			this.props.onFilterChanged();
		}}>
			<TS_Checkbox checked={filter.get(true)}>{label}</TS_Checkbox>
		</LL_H_C>;
	}
}

const selectedOrders = new StorageKey<string[]>('customer-order-list__selected-order-ids');

export class Page_CustomerOrderListV3
	extends SmartPage<{}, State> {

	static Route: TS_Route = {path: 'list-v3', key: 'customer-order-list-v3', Component: this};

	static defaultProps = {
		keys: ['_id'],
		pageTitle: 'Customer Orders List',
		childRoute: Page_CustomerOrder.Route,
		newRoute: Routes.RouteKey_CustomerOrderList,
		moduleFE: ModuleFE_CustomerOrder,
		modules: [
			ModuleFE_Stores,
			ModuleFE_CustomerContact,
			ModuleFE_ShippingMethod,
			ModuleFE_CustomerOrder,
		]
	};

	constructor(props: any) {
		super(props);
		this.logger.setMinLevel(LogLevel.Verbose);
	}

	protected async deriveStateFromProps(nextProps: FullProps, state: FullState) {
		state.orders = ModuleFE_CustomerOrder.getFilteredOrders();
		state.selectedItems = state.selectedItems || selectedOrders.get([]);
		return state;
	}

	private updateHeaderActions = () => {
		const headerActions: HeaderOption[] = [
			{
				icon: 'print',
				tooltip: 'Print Shipping Stickers',
				condition: (ev) => (ev.metaKey || ev.ctrlKey) && !ev.shiftKey && (ev as KeyboardEvent).key === 'p',
				action: () => {
					this.reDeriveState({printStickers: !this.state.printStickers});
				}
			},
			{
				icon: 'print',
				tooltip: 'Print Orders',
				condition: (ev) => (ev.metaKey || ev.ctrlKey) && ev.shiftKey && (ev as KeyboardEvent).key === 'p',
				action: () => {
					this.reDeriveState({printOrders: !this.state.printOrders});
				}
			},
			{
				icon: () => <TS_Checkbox key={this.state.selectedItems.length} checked={this.isSomeSelected()} onCheck={(checked) => {
					const selectedItems = checked ? (this.state.orders?.map(dbObjectToId) || []) : [];
					selectedOrders.set(selectedItems);
					this.setState({selectedItems}, this.updateHeaderActions);
				}}>{this.state.selectedItems.length} Orders</TS_Checkbox>,
				tooltip: () => this.isSomeSelected() ? 'select none' : 'select all',
				action: () => {
				}
			},
			{
				icon: 'download',
				tooltip: 'download',
				action: () => {
					const testCases = this.state.orders.slice(0, 200)
						.filter(order => order.shippingAddress)
						.map(order => {
							const rawOrder: IOrder = JSON.parse(order.blob);
							const originAddress = rawOrder.shipping_address || rawOrder.billing_address;

							const input = {
								address1: originAddress.address1,
								address2: originAddress.address2,
								city: originAddress.city,
								country: originAddress.country,
							};
							const detailedAddress = order.shippingAddress!;
							detailedAddress.houseNumber = !exists(detailedAddress.houseNumber) ? 0 : detailedAddress.houseNumber;
							detailedAddress.moreDetails = !exists(detailedAddress.moreDetails) ? '' : detailedAddress.moreDetails;
							return {
								description: `Order #${order.orderNumber}`,
								input: input,
								result: {
									firstStep: {
										apartment: detailedAddress.apartment,
										address: inferFullAddress(input).address
									},
									withGoogle: {
										country: detailedAddress.country,
										city: detailedAddress.city,
										street: detailedAddress.street,
										fullFormattedAddress: detailedAddress.fullFormattedAddress,
										houseNumber: detailedAddress.houseNumber,
									}
								}
							};
						});
					ModuleFE_Thunderstorm.downloadFile({fileName: 'address-test.json', mimeType: 'application/json', content: JSON.stringify(testCases)});
				}
			},
		];
		setTimeout(() => dispatch_updateHeaderOption.dispatchUI(headerActions), 0);
	};

	private isSomeSelected() {
		return this.state.selectedItems?.length > 0;
	}

// protected async deriveStateFromProps(nextProps: Props_DBItemListV2<DB_CustomerOrder>): Promise<State> {
	// 	const state = await super.deriveStateFromProps(nextProps);
	// 	return state;
	// }
	renderFilterOptions() {
		return <LL_H_C className="customer-order__options match_width">
			<div className="flex__grow"></div>
			{/*{this.renderFilter('showAll', 'Show Completed')}*/}
			{/*{this.renderFilter('preOrder', 'Pre Orders')}*/}
			{/*{this.renderFilter('incomplete', 'Incomplete')}*/}
			{/*{this.renderFilter('pendingPayment', 'Pending Payment')}*/}
			{/*<DropDown.Product*/}
			{/*	// selectedProductId={ModuleFE_CustomerOrder.filter.productId.get(undefined)}*/}
			{/*	onSelected={product => {*/}
			{/*		ModuleFE_CustomerOrder.filter.productId.set(product._id);*/}
			{/*		this.reDeriveState();*/}
			{/*	}}/>*/}
			{/*{ICONSV4.x({*/}
			{/*	onClick: () => {*/}
			{/*		ModuleFE_CustomerOrder.filter.productId.delete();*/}
			{/*		this.reDeriveState();*/}
			{/*	}*/}
			{/*})}*/}

			<ICONSV4.menu {...openContent.popUp.left('orders-filter', () => <Component_OrdersFilter onFilterChanged={() => this.reDeriveState()}/>)}/>
		</LL_H_C>;
	}

	render() {
		const orders: DB_CustomerOrder[] = this.state.orders;
		if (!orders)
			return <TS_Loader/>;
		this.updateHeaderActions();

		return <LL_V_L className="customer-order__list-page match_parent">
			{this.printStickers()}
			{this.printOrders()}
			{this.renderFilterOptions()}
			<LL_H_T className="customer-order__list match_width flex__grow">
				{orders.map(order => (
					<Item_EditorController<DB_CustomerOrder>
						key={order._id}
						onCompleted={() => this.reDeriveState()}
						autoSave={true}
						module={_ModuleFE_CustomerOrder}
						item={order._id}
						editor={editable => <Card_CustomerOrder
							editable={editable}
							selected={this.state.selectedItems.includes(order._id)}
							onSelected={(checked: boolean, e: React.MouseEvent<HTMLDivElement>) => {
								stopPropagation(e);
								if (checked)
									this.state.selectedItems.push(order._id);
								else
									removeItemFromArray(this.state.selectedItems, order._id);

								selectedOrders.set(this.state.selectedItems);
								this.updateHeaderActions();
								this.forceUpdate();
							}}
						/>}
					/>
				))}
			</LL_H_T></LL_V_L>;
	}

	private printStickers() {
		if (!this.state.printStickers)
			return;

		const orders = sortArray(filterInstances(this.state.selectedItems.map(orderId => ModuleFE_CustomerOrder.cache.unique(orderId))), order => order.orderNumber);
		return <TS_Printable className="match_width shipping-sticker__printable-area" printable={(_ref) => {
			if (!_ref)
				return;

			ModuleFE_Thunderstorm.printDiv(_ref);
			this.setState({printStickers: false});
		}}>
			{orders.map(order => {
				const totalPackages = order.packagingStatus.packagesSize.length || 1;
				if (totalPackages === 1)
					return <ShippingSticker packageNumber={1} totalPackages={totalPackages} order={order} deliveryDetails={lastElement(order.deliveryDetails)}/>;

				return <>
					{new Array(totalPackages).fill(0).map((_, i) => {
						return <ShippingSticker
							key={i}
							order={order}
							deliveryDetails={lastElement(order.deliveryDetails)}
							packageNumber={i + 1}
							totalPackages={totalPackages}/>;
					})}
				</>;
			})}
		</TS_Printable>;
	}

	private printOrders() {
		if (!this.state.printOrders)
			return;

		const orders = sortArray(filterInstances(this.state.selectedItems.map(orderId => ModuleFE_CustomerOrder.cache.unique(orderId))), order => order.orderNumber, true);
		return orders.map(order => {
			return <LL_V_C className="match_parent">
				<TS_Printable className="customer-order" printable={(_ref) => {
					if (!_ref)
						return;

					ModuleFE_Thunderstorm.printDiv(_ref);
					this.setState({printOrders: false});
				}}>
					<Editor_CustomerOrder editable={new EditableDBItem(order, ModuleFE_CustomerOrder).setAutoSave(true)}/>
				</TS_Printable>
			</LL_V_C>;
		});
	}
}

type Created = {
	selected: boolean,
	onSelected: (checked: boolean, e: React.MouseEvent<HTMLDivElement>) => void
};

export class Card_CustomerOrder
	extends Item_Editor<DB_CustomerOrder, Created> {

	render() {
		const editable = this.props.editable;
		const order = editable.item;
		const address = order.shippingAddress || order.billingAddress;

		const classes = order.status?.includes('pending-payment') || order.status?.includes('in-progress') ? 'customer-order__not-completed' : '';
		const className = _className('order-card', 'clickable', 'flex__space-between', getOrderStatusClass(order), classes);

		return (
			<LL_V_L
				className={className}
				onClick={() => ModuleFE_RoutingV2.goToRoute(Page_CustomerOrder.Route, {_id: order._id})}>
				{this.renderHeader(order)}
				<LL_H_T className="match_width flex__space-between">
					{this.renderDeliveryStatus(editable)}
					{this.renderAddress(address)}
				</LL_H_T>
				<LL_V_C className="match_width"
								style={{fontSize: 20}}>{(typeof order.status === 'string' ? [order.status] : order.status)?.map(status => this.renderStatus(status))
					.join(' | ')}</LL_V_C>
			</LL_V_L>);
	}

	private renderStatus(status: OrderStatus) {
		if (!status)
			return 'Unknown';

		switch (status) {
			case OrderStatus_Cancelled:
				return 'Cancelled';
			case OrderStatus_Completed:
				return 'Delivered';
			case OrderStatus_NeedToPack:
				return 'Need to Pack';
			case OrderStatus_OrderDelivery:
				return 'Order Delivery';
			case OrderStatus_WaitingCurrier:
				return 'Waiting Currier';
			case OrderStatus_WaitingPickup:
				return 'Waiting Pickup';
			case OrderStatus_OnTheWay:
				return 'On the Way';
			case OrderStatus_PendingPayment:
				return 'Pending Payment';
			case OrderStatus_PreOrder:
				return 'Pre-Order';

			case OrderStatus_ReturnInProgress:
				return 'Return in Progress';
		}
	}

	private renderHeader(order: Partial<DB_CustomerOrder>) {
		const replacer = new Replacer().setInput(ModuleFE_CustomerOrder.prepareOrderInput(order));
		const time = `${formatTimestamp('ddd, MMM DD', order.timestampCreated)} at ${formatTimestamp('HH:mm', order.timestampCreated)}`;

		return <LL_H_C className="match_width flex__space-between margin__bottom">
			<LL_H_C>
				<TS_Checkbox checked={this.props.selected} onCheck={this.props.onSelected}/>
				<div className="larger-font margin__inline">#{order.orderNumber}</div>
				<ICONSV4.debug onClick={(e) => {
					stopPropagation(e);
					dispatcher_onShowDebugData.dispatchUI(order._id!, `Order: ${order.orderNumber}`, order);
				}}/>
				<ICONSV4.show onClick={(e) => {
					stopPropagation(e);
					Dialog_OrderGraph.show(order);
				}}/>
				<MessagesSendingMenu icon={'email'} replacer={replacer} customer={ModuleFE_CustomerContact.cache.unique(order.customerId)!}/>
			</LL_H_C>
			<LL_H_C>
				<div className="medium-font margin__end">{time}</div>
				<LL_V_C>
					<ICONSV4.sync className="sync-icon" onClick={(e) => {
						stopPropagation(e);
						return genericNotificationAction(async () => {
							await ModuleFE_CustomerOrder._v1.shopify.sync({itemIds: [order._id!], fromShopify: true}).executeSync();
						}, `Syncing Order #${order.orderNumber}`);
					}}/>
				</LL_V_C>
			</LL_H_C>
		</LL_H_C>;
	}

	private renderDeliveryStatus(editable: EditableItem<DB_CustomerOrder>) {
		const shippingMethodId = editable.item.shippingOptionId;
		const originalShippingId = (ModuleFE_ShippingMethod.cache.find(({sources}) => sources.includes(editable.item.shippingOption?.title || '')))?._id;

		return <LL_V_L className="width-30 v-gap__n">
			<Component_OrderPackedStatus editable={editable.editProp('packagingStatus', {})}/>
			<LL_H_C className="match_width">
				<DropDown.ShippingMethod
					className="flex__shrink"
					selected={shippingMethodId}
					onSelected={async shippingMethod => {
						await editable.update('shippingOptionId', shippingMethod._id);
						this.forceUpdate();
					}}/>

				{originalShippingId !== shippingMethodId && <ICONSV4.sync onClick={(e) => {
					stopPropagation(e);
					return editable.update('shippingOptionId', originalShippingId);
				}}/>}
			</LL_H_C>
			<Component_DeliveryStatus order={editable.item}/>
		</LL_V_L>;
	}

	private renderAddress(address?: Address) {
		if (!address)
			return '';

		return <LL_V_L className="ts-languages__rtl v-gap__s">
			<LL_H_C className="h-gap__n">
				<Component_CustomerName address={address}/>
				<Component_CustomerPhoneNumber address={address}/>
			</LL_H_C>
			<Component_CustomerAddress address={address}/>
		</LL_V_L>;
	}

}

