import * as React from 'react';
import {DB_CustomerOrder} from '@app/shared';
import {ModuleFE_CustomerContact, ModuleFE_CustomerOrder, ModuleFE_ShippingMethod} from '@modules/index';
import {Component_Customer} from '@page/output/customer/components/Component_Customer';
import {Component_Address} from './Component_Address';
import {LL_H_T, LL_V_C, LL_V_L, TS_Loader} from '@nu-art/thunderstorm/frontend';
import {Component_OrderDetails} from './Component_OrderDetails';
import {LOCALE} from '@res/locale';
import {formatTimestamp, Replacer, sortArray} from '@nu-art/ts-common';
import {dispatcher_onUpdateDebugData} from '@consts/interfaces';
import {Component_OrderItems} from './Component_OrderItems';
import {Component_ShippingMethod} from './Component_ShippingMethod';
import {Component_PaymentMethod} from './Component_PaymentMethod';
import {Component_DeliveryStatus} from './Component_DeliveryStatus';
import {EditableDBItem, Item_Editor} from '@nu-art/thunderstorm/frontend';
import './_style.scss';
import {Dialog_ProductPreview} from '../dialogs/Dialog_ProductPreview';
import {Component_PackagingStatus} from './Component_PackagingStatus';


export class Editor_CustomerOrder
	extends Item_Editor<DB_CustomerOrder> {

	render() {
		const order = this.props.editable.item as DB_CustomerOrder;
		if (!order)
			return <TS_Loader/>;

		setTimeout(() => dispatcher_onUpdateDebugData.dispatchUI(order._id!, 'Order ' + order.orderNumber, order));
		return <LL_V_C
			className="customer-order customer-order__content margin__block margin__inline gap__n">
			<Component_OrderDetails editable={this.props.editable}/>
			<LL_H_T className="gap__n">
				<Component_OrderItems editable={this.props.editable} onItemSelected={async (product, variant) => {
					Dialog_ProductPreview.show(product, variant);
				}}/>
				<LL_V_L className="customer-order__side-bar gap__n">
					{this.renderCustomer(order)}
					<Component_Address editable={this.props.editable}/>
					<Component_PaymentMethod editable={this.props.editable}/>
					{this.renderShippingMethod(order)}
					{this.renderDeliveryStatus(order)}
					{this.renderPackagingStatus(order)}
					<Component_PastOrders editable={this.props.editable}/>
				</LL_V_L>
			</LL_H_T>
			{this.extras()}
		</LL_V_C>;
	}

	private renderCustomer(order: Partial<DB_CustomerOrder>) {
		const item = ModuleFE_CustomerContact.cache.unique(order.customerId);
		if (!item)
			return '';

		const replacer = new Replacer().setInput(ModuleFE_CustomerOrder.prepareOrderInput(order));
		const editable = new EditableDBItem(item, ModuleFE_CustomerContact);
		return <Component_Customer editable={editable} replacer={replacer}/>;
	}

	private extras() {
		return <div className="ll_h_t">
			{this.renderNotes()}
			{this.renderPayments()}
		</div>;
	}

	private renderDeliveryStatus(order: DB_CustomerOrder) {
		if (ModuleFE_ShippingMethod.cache.unique(order.shippingOptionId)?.isPickup)
			return;

		return <Component_DeliveryStatus order={order as DB_CustomerOrder} editable={this.props.editable.editProp('deliveryDetails', [])}/>;
	}

	private renderPackagingStatus(item: DB_CustomerOrder) {
		return <Component_PackagingStatus editable={this.props.editable.editProp('packagingStatus', {})}/>;
	}

	private renderShippingMethod(order: DB_CustomerOrder) {
		const originalShippingId = (ModuleFE_ShippingMethod.cache.find(({sources}) => sources.includes(order!.shippingOption?.title || '')))?._id;

		return <Component_ShippingMethod editable={this.props.editable} originalShippingId={originalShippingId}/>;
	}

	private renderNotes() {
		return '';
	}

	private renderPayments() {
		return '';
	}

}

export class Component_PastOrders
	extends Item_Editor<DB_CustomerOrder> {

	render() {
		const orders = ModuleFE_CustomerOrder.cache.all().filter(order => order.customerId === this.props.editable.item.customerId);
		const sortedOrders = sortArray(orders, item => item.timestampCreated, true);
		const olderOrders = sortedOrders.slice(orders.findIndex(o => o._id === this.props.editable.item._id) + 1);
		if (!olderOrders.length)
			return '';

		const ordersToRender = olderOrders.slice(0, 5);
		return (
			<LL_V_L className=" customer-order__section ts-print__gone">
				<div className="customer-order__title">{LOCALE.PastOrders()}</div>
				{ordersToRender.map((order, i) => {
					return <LL_V_L key={i}>
						<div>{order.orderNumber}</div>
						<div style={{fontSize: 15, fontWeight: 400,}}>{formatTimestamp('ddd, DD MMM, YYYY [at] HH:mm', order.timestampCompleted)}</div>
					</LL_V_L>;
				})}
				{olderOrders.length > ordersToRender.length && <div style={{marginTop: 6}}>({(olderOrders.length - ordersToRender.length)} More Orders..)</div>}
			</LL_V_L>
		);
	}
}