import * as React from 'react';
import {LOCALE} from '@res/locale';
import {DB_CustomerOrder} from '@app/shared';
import {ModuleFE_CustomerOrder} from '@modules/index';
import {LL_V_L} from '@nu-art/thunderstorm/frontend';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';


export class Component_PaymentMethod
	extends Item_Editor<DB_CustomerOrder> {

	static defaultProps = {
		moduleFE: ModuleFE_CustomerOrder,
	};

	render() {
		const gateway = this.props.editable.item.gateway;

		return (
			<LL_V_L className={`customer-order__section`}>
				<div className="customer-order__title">{LOCALE.PaymentMethod()}</div>
				<div>{gateway}</div>
			</LL_V_L>
		);
	}
}