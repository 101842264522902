import * as React from 'react';
import {OnStoresUpdated} from '@modules/index';
import {DebugPanel} from '../workspace/DebugPanel';
import {LL_H_T, LL_V_L, TS_Route} from '@nu-art/thunderstorm/frontend';
import {Component_MainHeader} from './Component_MainHeader';
import {Component_SideBar} from './Component_SideBar';
import './Page_Workspace.scss';
import {Outlet} from 'react-router-dom';
import {Page_CustomerOrderList} from '@pah/customer-order/Page_CustomerOrderList';
import {Page_CustomerOrder} from '@pah/customer-order/Page_CustomerOrder';
import {Page_StoreInventory} from '@page/store/inventory/Page_StoreInventory';
import {Page_StoreInventoryList} from '@page/store/inventory/Page_StoreInventoryList';
import {Page_ShipmentV2} from '@page/input/shipment-v2/Page_ShipmentV2';
import {Page_ShipmentList} from '@page/input/shipment/Page_ShipmentList';
import {Page_CustomersV2} from '@page/sales/customers/Page_CustomersV2';
import {Page_ShippingOptionsV2} from '@page/sales/shippingOption/Page_ShippingOptionsV2';
import {Page_MessagesTemplateV2} from '@page/sales/messages-template/Page_MessagesTemplateV2';
import {Page_VendorsV2} from '@page/store/v2/vendors/Page_VendorsV2';
import {Page_ProductTagsV2} from '@page/store/v2/product-tags/Page_ProductTagsV2';
import {Page_ProductsV2} from '@page/store/v2/products/Page_ProductsV2';
import {Page_ProductTemplateV2} from '@page/store/v2/product-template/Page_ProductTemplateV2';
import {Page_ProductsVariantConfigV2} from '@page/store/v2/product-variants/Page_ProductsVariantConfigV2';
import {Page_CustomerOrderListV3} from '@pah/customer-order/Page_CustomerOrderListV3';


type Props = {}
type State = {}

function routeGroup(path: string, key: string, index: boolean = false, ...children: TS_Route<any>[]): TS_Route {
	return {path, key, index, children};
}

export class Page_Workspace
	extends React.Component<Props, State>
	implements OnStoresUpdated {

	static Route_Sales: TS_Route = {
		path: 'sales', key: 'sales', Component: Page_Workspace, fallback: true, children: [
			routeGroup('order', 'orders', true, {...Page_CustomerOrderList.Route, index: true}, Page_CustomerOrder.Route),
			Page_CustomerOrderListV3.Route,
			Page_CustomersV2.Route,
			Page_MessagesTemplateV2.Route,
			Page_ShippingOptionsV2.Route,
		]
	};

	private static Route_Product: TS_Route = {
		path: 'product', key: 'product', children: [
			{...Page_ProductTemplateV2.Route, index: true},
			Page_ProductTagsV2.Route,
			Page_ProductsVariantConfigV2.Route,
			Page_ProductsV2.Route,
		]
	};

	static Route_Store: TS_Route = {
		path: 'store', key: 'store', Component: Page_Workspace, fallback: true, children: [
			this.Route_Product,
			Page_VendorsV2.Route,
			routeGroup('inventory', 'inventory', false, Page_StoreInventoryList.Route, Page_StoreInventory.Route),
			routeGroup('shipment', 'shipment', false, Page_ShipmentList.Route, Page_ShipmentV2.Route),
		]
	};

	constructor(props: any) {
		super(props);
		this.state = {register: false};
	}

	__onStoresUpdated(): void {
		this.forceUpdate();
	}

	render() {
		return (
			<LL_V_L className="workspace">
				<Component_MainHeader/>
				<LL_H_T className="workspace-navigation">
					<Component_SideBar/>
					<LL_V_L className="match_parent knowledge-manager__main__content" style={{overflow: 'auto'}}>
						<Outlet/>
					</LL_V_L>
					<DebugPanel/>
				</LL_H_T>
			</LL_V_L>
		);
	}
}
