import {RouteKey_StoreInventoryList, RouteKey_StoreInventoryNew} from '@consts/routes-consts';
import {DB_StoreInventory} from '@app/shared';
import {ModuleFE_Products, ModuleFE_ProductVariants, ModuleFE_StoreInventory, ModuleFE_VariantOptions, ModuleFE_Vendors} from '@modules/index';
import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_Toaster, TS_Route} from '@nu-art/thunderstorm/frontend';
import {Page_DBItemEditorV2, State_DBItemEditorPageV2} from '@component/Page_DBItemEditorV2';
import {dispatch_updateHeaderOption, HeaderOption} from '@page/Page_Workspace/Component_MainHeader';
import {Component_StoreInventory} from './Component_StoreInventory';


type State = State_DBItemEditorPageV2<DB_StoreInventory> & {}

export class Page_StoreInventory
	extends Page_DBItemEditorV2<DB_StoreInventory, '_id', State> {

	static Route: TS_Route<{ _id: string }> = {path: 'v1', key: 'store-inventory-v1', Component: this};

	static defaultProps = {
		pageTitle: 'StoreInventory',
		keys: ['_id'],
		parentRoute: RouteKey_StoreInventoryList,
		newRoute: RouteKey_StoreInventoryNew,
		moduleFE: ModuleFE_StoreInventory,
		itemEditor: Component_StoreInventory,
		modules: [
			ModuleFE_Vendors,
			ModuleFE_Products,
			ModuleFE_ProductVariants,
			ModuleFE_VariantOptions,
		] as unknown as ModuleFE_BaseApi<any, any>[]
	};

	componentDidMount() {
		super.componentDidMount();
		const newVar: HeaderOption = {
			icon: 'remove',
			tooltip: 'Tooltip_DeleteStoreInventory',
			action: () => {
				const item = this.state.item;
				if (!item)
					return ModuleFE_Toaster.toastError('Item not found');

				ModuleFE_StoreInventory.v1.delete({_id: item._id}).execute();
			}
		};
		dispatch_updateHeaderOption.dispatchUI([newVar]);
	}
}







