"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_TranslationProject = exports.TranslationProject_Quality = void 0;
const ts_common_1 = require("@nu-art/ts-common");
exports.TranslationProject_Quality = ['self-translation', 'student', 'premium', 'expert'];
const Validator_ModifiableProps = {
    clientId: ts_common_1.tsValidateUniqueId,
    quality: (0, ts_common_1.tsValidateValue)(exports.TranslationProject_Quality),
    originLocalId: ts_common_1.tsValidateUniqueId,
    targetLocalIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    tagIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    title: (0, ts_common_1.tsValidateString)(100),
    description: (0, ts_common_1.tsValidateString)(2000),
};
const Validator_GeneratedProps = {};
exports.DBDef_TranslationProject = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbName: 'translation-project',
    entityName: 'translation-project',
};
