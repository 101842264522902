"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseComponent = void 0;
/**
 * Created by tacb0ss on 28/07/2018.
 */
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const Thunder_1 = require("./Thunder");
let instances = 0;
class BaseComponent extends React.Component {
    constructor(props) {
        var _a, _b, _c;
        super(props);
        this.timeoutMap = {};
        this.mounted = false;
        this.reDeriveState = (state) => {
            this.logVerbose('reDeriveState called..');
            this._deriveStateFromProps(this.props, Object.assign(Object.assign({}, this.state), state));
        };
        this.logger = new ts_common_1.Logger(this.constructor.name + '-' + (++instances));
        this.logger.setMinLevel(BaseComponent.MinLogLevel);
        this.logVerbose('Creating..');
        this._constructor();
        const __render = (_a = this.render) === null || _a === void 0 ? void 0 : _a.bind(this);
        if (!__render)
            throw new ts_common_1.ImplementationMissingException('Every UI Component must have a render function. Did you forget to add a render function?');
        this.render = () => {
            this.logVerbose('Rendering', this.state);
            return __render();
        };
        const __componentDidMount = (_b = this.componentDidMount) === null || _b === void 0 ? void 0 : _b.bind(this);
        this.componentDidMount = () => {
            // @ts-ignore
            Thunder_1.Thunder.getInstance().addUIListener(this);
            this.mounted = true;
            __componentDidMount === null || __componentDidMount === void 0 ? void 0 : __componentDidMount();
        };
        const __componentWillUnmount = (_c = this.componentWillUnmount) === null || _c === void 0 ? void 0 : _c.bind(this);
        this.componentWillUnmount = () => {
            __componentWillUnmount === null || __componentWillUnmount === void 0 ? void 0 : __componentWillUnmount();
            // @ts-ignore
            Thunder_1.Thunder.getInstance().removeUIListener(this);
            this.mounted = false;
        };
        this._deriveStateFromProps.bind(this);
        const state = this._deriveStateFromProps(props, this.state);
        if (state)
            this.state = state;
    }
    _constructor() {
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.shouldReDeriveState(nextProps))
            return;
        if (this.state) //skip the first time when the shared-components MUST update
            this.logDebug('Received new props, calling deriveStateFromProps', nextProps);
        const state = this._deriveStateFromProps(nextProps, Object.assign({}, this.state));
        if (state)
            this.setState(state);
    }
    debounce(handler, key, ms = 0) {
        this.logWarning('THIS IS LEGACY S***, NEED TO REMOVE');
        (0, ts_common_1._clearTimeout)(this.timeoutMap[key]);
        this.timeoutMap[key] = (0, ts_common_1._setTimeout)(handler, ms);
    }
    throttle(handler, key, ms = 0) {
        this.logWarning('THIS IS LEGACY S***, NEED TO REMOVE');
        if (this.timeoutMap[key])
            return;
        this.timeoutMap[key] = (0, ts_common_1._setTimeout)(() => {
            handler();
            delete this.timeoutMap[key];
        }, ms);
    }
    shouldReDeriveState(nextProps) {
        const _shouldRederive = () => {
            const propKeys = (0, ts_common_1.sortArray)((0, ts_common_1._keys)(this.props || ts_common_1.EmptyObject));
            const nextPropsKeys = (0, ts_common_1.sortArray)((0, ts_common_1._keys)(nextProps || ts_common_1.EmptyObject));
            if (propKeys.length !== nextPropsKeys.length)
                return true;
            this.logVerbose('CurrentPropKeys:', propKeys);
            this.logVerbose('CurrentProps:', this.props);
            this.logVerbose('NextPropKeys:', nextPropsKeys);
            this.logVerbose('NextProps:', nextProps);
            if (propKeys.some((key, i) => propKeys[i] !== nextPropsKeys[i] || this.props[propKeys[i]] !== nextProps[nextPropsKeys[i]]))
                return true;
            return false;
        };
        const willReDerive = _shouldRederive();
        this.logVerbose(`component will${!willReDerive ? ' NOT' : ''} re-derive State`);
        return willReDerive;
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const _shouldRender = () => {
            const stateKeys = (0, ts_common_1.sortArray)((0, ts_common_1._keys)(this.state || ts_common_1.EmptyObject));
            const nextStateKeys = (0, ts_common_1.sortArray)((0, ts_common_1._keys)(nextState || ts_common_1.EmptyObject));
            // this.logDebug('States Same Instance:', this.state === nextState);
            if (stateKeys.length !== nextStateKeys.length)
                return true;
            if (stateKeys.some((key, i) => stateKeys[i] !== nextStateKeys[i] || this.state[stateKeys[i]] !== nextState[nextStateKeys[i]]))
                return true;
            return false;
        };
        // const willRender = super.shouldComponentUpdate?.(nextProps, nextState, nextContext) || true;
        const willRender = _shouldRender();
        this.logVerbose(`component will${!willRender ? ' NOT' : ''} render`);
        return willRender;
    }
    logVerbose(...toLog) {
        this.logImpl(ts_common_1.LogLevel.Verbose, false, toLog);
    }
    logDebug(...toLog) {
        this.logImpl(ts_common_1.LogLevel.Debug, false, toLog);
    }
    logInfo(...toLog) {
        this.logImpl(ts_common_1.LogLevel.Info, false, toLog);
    }
    logWarning(...toLog) {
        this.logImpl(ts_common_1.LogLevel.Warning, false, toLog);
    }
    logError(...toLog) {
        this.logImpl(ts_common_1.LogLevel.Error, false, toLog);
    }
    log(level, bold, ...toLog) {
        this.logImpl(level, bold, toLog);
    }
    logImpl(level, bold, toLog) {
        this.logger.log(level, bold, toLog);
    }
    toString() {
        return this.constructor.name;
    }
}
BaseComponent.MinLogLevel = ts_common_1.LogLevel.Info;
exports.BaseComponent = BaseComponent;
