"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorBase = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
require("./editor-base.scss");
const newItemIdentifier = '##new-item##';
class EditorBase extends frontend_1.SmartComponent {
    constructor() {
        super(...arguments);
        //######################### Logic #########################
        this.selectItem = (itemId) => {
            if (!itemId)
                return this.setState({ selectedItemId: undefined, editedItem: undefined });
            const item = itemId === newItemIdentifier ? {} : this.state.items.find(item => item._id === itemId);
            if (!item)
                throw new ts_common_1.BadImplementationException(`Could not find item with id ${itemId}`);
            const newVar = { editedItem: new frontend_1.EditableDBItem(item, this.module) };
            return this.reDeriveState(Object.assign(Object.assign({}, newVar), { selectedItemId: newVar.editedItem.item._id }));
        };
        this.saveItem = async (e) => {
            if (!this.state.editedItem)
                return;
            this.logDebug('Saving Item', this.state.editedItem.item);
            await (0, frontend_1.genericNotificationAction)(() => this.state.editedItem.save(), `Saving ${this.itemName}`, 3);
        };
        this.deleteItem = async () => {
            if (!this.state.editedItem)
                return;
            await (0, frontend_1.genericNotificationAction)(() => this.state.editedItem.delete(), `Deleting ${this.itemName}`, 3);
        };
        this.setProperty = (key, value) => {
            if (!this.state.editedItem)
                throw new ts_common_1.ThisShouldNotHappenException('Got to setting property without an edited item in state');
            this.state.editedItem.update(key, value);
            this.forceUpdate();
        };
        //######################### Render #########################
        this.renderList = () => {
            const items = (0, ts_common_1.sortArray)((0, ts_common_1.cloneArr)(this.state.items), i => this.itemDisplay(i));
            return React.createElement(frontend_1.LL_V_L, { className: 'item-list' },
                React.createElement("div", { className: 'item-list__header' }, this.itemNamePlural),
                React.createElement(frontend_1.LL_V_L, { className: 'item-list__list' }, items.map(item => {
                    const className = (0, frontend_1._className)('item-list__list-item', item._id === this.state.selectedItemId ? 'selected' : undefined);
                    return React.createElement("div", { className: className, onClick: () => this.selectItem(item._id), key: item._id }, this.itemDisplay(item));
                })),
                this.renderListButton());
        };
        this.renderListButton = () => {
            return React.createElement(frontend_1.TS_Button, { className: 'item-list__add-button', onClick: () => this.selectItem(newItemIdentifier) },
                "Add New ",
                this.itemName);
        };
        this.renderEditor = () => {
            if (!this.state.editedItem)
                return '';
            const item = this.state.editedItem;
            return React.createElement(frontend_1.LL_V_L, { className: 'item-editor' },
                React.createElement("div", { className: 'item-editor__header' }, item.item._id ? this.itemDisplay(item.item) : `New ${this.itemName}`),
                React.createElement(frontend_1.LL_V_L, { className: 'item-editor__main' }, this.editorContent()),
                React.createElement(frontend_1.LL_H_C, { className: 'item-editor__buttons' },
                    item.item._id &&
                        React.createElement(frontend_1.TS_BusyButton, { onClick: this.deleteItem, className: 'delete-button' }, "Delete"),
                    React.createElement(frontend_1.TS_Button, { onClick: () => this.selectItem() }, "Cancel"),
                    React.createElement(frontend_1.TS_BusyButton, { onClick: this.saveItem }, "Save")));
        };
    }
    render() {
        return React.createElement(frontend_1.LL_H_C, { className: 'permissions-editor match_parent' },
            this.renderList(),
            this.renderEditor());
    }
}
exports.EditorBase = EditorBase;
