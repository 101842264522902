"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Archiving = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../../shared");
const core_1 = require("../../core");
class ModuleFE_Archiving_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.vv1 = {
            hardDeleteAll: (0, core_1.apiWithQuery)(shared_1.ApiDef_Archiving.vv1.hardDeleteAll),
            hardDeleteUnique: (0, core_1.apiWithBody)(shared_1.ApiDef_Archiving.vv1.hardDeleteUnique),
            getDocumentHistory: (0, core_1.apiWithQuery)(shared_1.ApiDef_Archiving.vv1.getDocumentHistory)
        };
    }
}
exports.ModuleFE_Archiving = new ModuleFE_Archiving_Class();
