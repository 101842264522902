"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Form = void 0;
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
class TS_Form extends ComponentSync_1.ComponentSync {
    deriveStateFromProps(nextProps) {
        const state = this.state ? Object.assign({}, this.state) : {};
        return state;
    }
    render() {
        return React.createElement("div", null);
    }
}
exports.TS_Form = TS_Form;
