import {LL_H_C, LL_V_C, LL_V_L} from '@nu-art/thunderstorm/frontend';
import React from 'react';
import {Address, DB_CustomerOrder, DeliveryDetails} from '@app/shared/store';
import JsBarcode from 'jsbarcode';
import {DeliveryServices} from '@app/shared/delivery-courier/_tbd-delivery-services';
import {cleanupPhoneNumberForUI} from '@app/shared';


type ShippingStickerParams = {
	packageNumber: number,
	totalPackages: number,
	order: DB_CustomerOrder
	deliveryDetails?: DeliveryDetails
};
export const ShippingSticker = (props: ShippingStickerParams) => {
	const deliveryDetails = props.deliveryDetails;
	const label = deliveryDetails && `${DeliveryServices.find(d => d.key === deliveryDetails.type)?.label || 'Error'} - ${deliveryDetails.number}`;

	return <LL_H_C key={props.packageNumber}
								 className="match_width shipping-sticker__content_wrapper ts-print__break-after ts-print__break-before ts-print__dont-break">
		<LL_V_L className="shipping-sticker__content">
			<LL_H_C className="shipping-sticker__title">
				<img src={require('@res/img/logo--petit-fawn.png')} alt="Logo"/>
				<div>#{props.order.orderNumber}</div>
			</LL_H_C>
			<div className="shipping-sticker__separator"/>
			<ShippingSticker_Barcode barcodeValue={deliveryDetails?.number} label={label}/>
			<div className="shipping-sticker__separator"/>
			<LL_H_C className="shipping-sticker__bottom">
				{props.order.shippingAddress && <ShippingSticker_Address address={props.order.shippingAddress}/>}
				<LL_H_C>
					<ShippingSticker_PackageCount packageNumber={props.packageNumber} totalPackages={props.totalPackages}/>
				</LL_H_C>
			</LL_H_C>
		</LL_V_L>
	</LL_H_C>;
};

const ShippingSticker_PackageCount = (props: { packageNumber: number, totalPackages: number }) => {
	if (props.totalPackages === 1)
		return <></>;

	return <div className="shipping-sticker__package-count">{props.packageNumber}/{props.totalPackages}</div>;
};

const ShippingSticker_Address = (props: { address: Address }) => {
	const address = props.address;
	if (!address?.contact)
		return <div>No Address</div>;

	return <LL_V_L className="shipping-sticker__shipping-address">
		<LL_H_C className="property-renderer">
			<div className="property-renderer__prop-value">{address.contact.firstName} {address.contact.lastName}</div>
		</LL_H_C>
		<LL_H_C className="property-renderer">
			<div className="property-renderer__prop-value">{address.street} {address.houseNumber}, {address.city}</div>
		</LL_H_C>
		<ShippingSticker_ApartmentDetails address={address}/>
		<LL_H_C className="property-renderer">
			<div className="property-renderer__prop-value">{cleanupPhoneNumberForUI(address.contact.phoneNumber)}</div>
		</LL_H_C>
	</LL_V_L>;
};

const ShippingSticker_Barcode = (props: { barcodeValue?: string, label?: string }) => {
	const barcodeValue = props.barcodeValue;
	if (!barcodeValue)
		return <div className="shipping-sticker__pickup">PICK UP</div>;

	return <LL_V_C className="shipping-sticker__delivery-detail">

		<LL_V_C className="shipping-sticker__barcode">
			<svg ref={ref => {
				if (ref)
					JsBarcode(ref, barcodeValue, {format: 'CODE128', displayValue: false, width: 5, margin: 0});
			}}/>
			{props.label && <div className="barcode-value">{props.label}</div>}
		</LL_V_C>
	</LL_V_C>;
};

const ShippingSticker_ApartmentDetails = (props: { address: Address }) => {
	const address = props.address;

	if (!address.entrance && !address.apartment)
		return <></>;

	const entranceElement = address.entrance ? <div>כניסה: {address.entrance}</div> : undefined;
	const floorElement = address.apartment?.floor ? <div>קומה: {address.apartment?.floor}</div> : undefined;
	const aptNumberElement = address.apartment?.number ? <div>דירה: {address.apartment?.number}</div> : undefined;

	return <LL_H_C className="property-renderer">
		<div className="property-renderer__prop-value">{entranceElement}{floorElement}{aptNumberElement}</div>
	</LL_H_C>;
};

