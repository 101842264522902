"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_ErrorBoundary = void 0;
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const React = require("react");
require("./TS_ErrorBoundary.scss");
const TS_Button_1 = require("../TS_Button");
const ComponentSync_1 = require("../../core/ComponentSync");
class TS_ErrorBoundary extends ComponentSync_1.ComponentSync {
    //######################### Static #########################
    constructor(props) {
        super(props);
        //######################### Logic #########################
        this.onButtonClick = () => {
            if (this.props.onError)
                return this.props.onError(this.state.error);
            this.setState({ error: undefined });
        };
        //######################### Render #########################
        // private renderErrorDetails = () => {
        // 	const env = Thunder.getInstance().getConfig().label;
        // 	if (env !== 'LOCAL' && env !== 'DEV')
        // 		return '';
        //
        // 	return <details style={{whiteSpace: 'pre-wrap'}}>
        // 		{this.state.errorInfo?.componentStack}
        // 	</details>;
        // };
        this.renderButton = () => {
            if (this.props.buttonRenderer)
                return this.props.buttonRenderer();
            return React.createElement(TS_Button_1.TS_Button, { className: 'ts-error-boundary__button', onClick: this.onButtonClick }, "Reload!");
        };
        this.defaultRenderer = () => {
            const titleMessage = randomMessages[Math.floor(Math.random() * randomMessages.length)];
            return React.createElement("div", { className: 'ts-error-boundary' },
                React.createElement("div", { className: 'ts-error-boundary__pic' }, "(\u30CE\u0CA0\u76CA\u0CA0\u30CE)"),
                React.createElement("div", { className: 'ts-error-boundary__title' }, titleMessage),
                this.renderButton());
        };
        this.state = {};
    }
    //######################### Life Cycle #########################
    static getDerivedStateFromError(error) {
        return { error };
    }
    deriveStateFromProps(nextProps) {
        var _a;
        return { error: (nextProps.error ? nextProps.error : (_a = this.state) === null || _a === void 0 ? void 0 : _a.error) };
    }
    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }
    render() {
        if (!this.state.error)
            return this.props.children;
        if (this.props.renderer)
            return this.props.renderer(this.state.error);
        return this.defaultRenderer();
    }
}
exports.TS_ErrorBoundary = TS_ErrorBoundary;
const randomMessages = [
    'Jeepers Creepers! An error!',
    'Something went wrong.. we\'re on it!',
    'This component is having a bad day',
];
