"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldEditorWithButtons = void 0;
const React = require("react");
const FieldEditor_1 = require("./FieldEditor");
const ModuleFE_LocalStorage_1 = require("../modules/ModuleFE_LocalStorage");
const ComponentSync_1 = require("../core/ComponentSync");
class FieldEditorWithButtons extends ComponentSync_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.handleEdit = () => {
            // Save the state's value in case of cancellation.
            this.setState({ isEditing: true });
        };
        this.handleSave = () => {
            this.props.onAccept(this.state.storageKey.get());
            this.handleCancel();
        };
        this.handleCancel = () => {
            this.state.storageKey.delete();
            this.setState({ isEditing: false });
        };
        this.renderEditButton = () => {
            return React.createElement("button", { onClick: this.handleEdit }, "Edit");
        };
        this.renderControlButtons = () => {
            return React.createElement("div", null,
                React.createElement("button", { onClick: this.handleSave }, "Save"),
                React.createElement("button", { onClick: this.handleCancel }, "Cancel"));
        };
    }
    createStorageKey() {
        return new ModuleFE_LocalStorage_1.StorageKey(`editable-label-controller-${this.props.id}`);
    }
    deriveStateFromProps(nextProps) {
        return {
            storageKey: this.createStorageKey(),
            isEditing: false,
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.setState({ storageKey: this.createStorageKey() });
        }
    }
    render() {
        const { isEditing } = this.state;
        return (React.createElement("div", { className: `ll_h_c`, style: { justifyContent: 'space-between' } },
            React.createElement("div", null,
                React.createElement(FieldEditor_1.FieldEditor, { id: this.props.id, type: this.props.type, isEditing: this.state.isEditing, inputProps: this.props.inputProps, labelProps: this.props.labelProps, onAccept: this.handleSave, onCancel: this.handleCancel, storageKey: this.state.storageKey, value: this.props.value })),
            isEditing ? this.renderControlButtons() : this.renderEditButton()));
    }
}
exports.FieldEditorWithButtons = FieldEditorWithButtons;
