import * as React from 'react';
import {_className, ComponentSync, LL_H_C, LL_V_C, ModuleFE_RoutingV2, StorageKey, TS_Route} from '@nu-art/thunderstorm/frontend';
import './Component_SideBar.scss';
import {IconsType, ICONSV4} from '@res/icons';
import {RouteKey} from '@consts/Routes';
import * as Routes from '@consts/routes-consts';
import {OnMenuButtonClicked} from '@consts/interfaces';
import {LOCALE} from '@res/locale';
import {ModuleFE_App} from '@modules/index';
import {Page_CustomerOrderList} from '@pah/customer-order/Page_CustomerOrderList';
import {Page_ProductTagsV2} from '@page/store/v2/product-tags/Page_ProductTagsV2';
import {Page_StoreInventoryList} from '@page/store/inventory/Page_StoreInventoryList';
import {Page_ShipmentList} from '@page/input/shipment/Page_ShipmentList';
import {Page_CustomersV2} from '@page/sales/customers/Page_CustomersV2';
import {Page_VendorsV2} from '@page/store/v2/vendors/Page_VendorsV2';
import {Page_MessagesTemplateV2} from '@page/sales/messages-template/Page_MessagesTemplateV2';
import {Page_ShippingOptionsV2} from '@page/sales/shippingOption/Page_ShippingOptionsV2';
import {Page_ProductsV2} from '@page/store/v2/products/Page_ProductsV2';
import {Page_ProductTemplateV2} from '@page/store/v2/product-template/Page_ProductTemplateV2';
import {resolveContent} from '@nu-art/ts-common';
import {Page_ProductsVariantConfigV2} from '@page/store/v2/product-variants/Page_ProductsVariantConfigV2';
import {Page_CustomerOrderListV3} from '@pah/customer-order/Page_CustomerOrderListV3';


type SideNavBarIcon = {
	icon: keyof IconsType;
	label: () => string | string
	route: RouteKey;
}
type FilterType = 'inventory' | 'store' | 'sales';

type SideNavBarFilter = {
	icon: keyof IconsType;
	key: FilterType
	label: string
	options: Option[]
}

type Option = {
	icon: keyof IconsType;
	label: (() => string) | string
	route: TS_Route;
}

// @ts-ignore
const icons: SideNavBarIcon[] = [
	{
		icon: 'translate',
		label: LOCALE.Translations,
		route: Routes.RouteKey_Translations
	},
	{
		icon: 'decision_tree',
		label: LOCALE.DecisionTree,
		route: Routes.RouteKey_DecisionTree
	},
	{
		icon: 'lock',
		label: LOCALE.Permissions,
		route: Routes.RouteKey_Permissions
	}
];

const Filter_Sales: SideNavBarFilter = {
	icon: 'customer_order',
	key: 'sales',
	label: 'Sales',
	options: [
		{
			icon: 'customer_order',
			label: LOCALE.Orders,
			route: Page_CustomerOrderList.Route
		},
		{
			icon: 'customer_order',
			label: LOCALE.Orders,
			route: Page_CustomerOrderListV3.Route
		},
		{
			icon: 'customer',
			label: LOCALE.Customers,
			route: Page_CustomersV2.Route
		},
		{
			icon: 'location',
			label: LOCALE.ShippingOptions,
			route: Page_ShippingOptionsV2.Route
		},
		{
			icon: 'email',
			label: LOCALE.MessageTemplate,
			route: Page_MessagesTemplateV2.Route
		}
	]
};
const Filter_Inventory: SideNavBarFilter = {
	icon: 'shipment_plane',
	key: 'inventory',
	label: 'Inventory',
	options: [
		{
			icon: 'shipment_plane',
			label: LOCALE.Shipments,
			route: Page_ShipmentList.Route
		},
		// {
		// 	icon: 'scrape',
		// 	label: LOCALE.InvoiceParser,
		// 	route: Routes.RouteKey_InvoiceParser
		// },
		// {
		// 	icon: 'magic_stick',
		// 	label: LOCALE.OrderGenerator,
		// 	route: Routes.RouteKey_OrderGenerator
		// },
	]
};
const Filter_Store: SideNavBarFilter = {
	icon: 'product',
	key: 'store',
	label: 'Store',
	options: [
		{
			icon: 'product',
			label: LOCALE.Products,
			route: Page_ProductsV2.Route
		},
		{
			icon: 'magic_stick',
			label: LOCALE.ProductTemplates,
			route: Page_ProductTemplateV2.Route
		},
		{
			icon: 'missing_variants',
			label: 'Product Variants',
			route: Page_ProductsVariantConfigV2.Route
		},
		{
			icon: 'documents',
			label: LOCALE.ProductTags,
			route: Page_ProductTagsV2.Route
		},
		{
			icon: 'shipment_boxes',
			label: LOCALE.StoreInventory,
			route: Page_StoreInventoryList.Route
		},
		{
			icon: 'missing_variants',
			label: LOCALE.Vendors,
			route: Page_VendorsV2.Route
		},
	]
};
const filterMenu: SideNavBarFilter[] = [
	Filter_Inventory,
	Filter_Store,
	Filter_Sales
];

type Props = {}

type State = {
	isOpen: boolean;
	filter: FilterType;
}

const menuFilter = new StorageKey<FilterType>('menu-filter-key');

export class Component_SideBar
	extends ComponentSync<Props, State>
	implements OnMenuButtonClicked {

	deriveStateFromProps(nextProps: Props, state = {} as State) {
		state.isOpen = ModuleFE_App.sideBarIsOpen.get(false);
		state.filter = menuFilter.get('sales');
		return state;
	}

	__onMenuButtonClicked(): void {
		const isOpen = ModuleFE_App.sideBarIsOpen.get(false);
		this.setState({...this.state, isOpen});
	}

	render() {
		const routeKey = ModuleFE_RoutingV2.getCurrentRouteKey()?.key;
		const options = filterMenu.find(item => item.key === menuFilter.get('sales'))?.options;
		options?.find(option => routeKey === option.route.key);
		return <div className={_className('manager__side-bar', 'unselectable', this.state.isOpen && 'open')}>
			<LL_H_C className="margin__block">
				{filterMenu.map((item, i) => {
					const Icon = ICONSV4[item.icon];
					return <Icon key={i} className={_className(menuFilter.get() === item.key && 'selected')} onClick={() => {
						menuFilter.set(item.key);
						ModuleFE_RoutingV2.goToRoute(item.options[0].route);
						this.forceUpdate();
					}}/>;
				})}
			</LL_H_C>
			{options?.map((icon, i) => {
				const Icon = ICONSV4[icon.icon];
				return <LL_H_C key={i} className={_className('manager__side-bar-item', routeKey === icon.route.key && 'selected')} onClick={() => {
					ModuleFE_RoutingV2.goToRoute(icon.route);
					this.forceUpdate();
				}}>
					<LL_V_C className="manager__side-bar__icon-wrapper"><Icon/></LL_V_C>
					<div className={_className('manager__side-bar-item__label', this.state.isOpen && 'open')}>{resolveContent(icon.label)}</div>
				</LL_H_C>;
			})}
		</div>;
	}
}