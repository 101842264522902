"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModulePackFE_TranslationInfra = void 0;
const project_1 = require("./frontend/project");
const frontend_1 = require("./translator/frontend");
const frontend_2 = require("./client/frontend");
const frontend_3 = require("./resource/frontend");
const frontend_4 = require("./entry/frontend");
const frontend_5 = require("./locale/frontend");
const frontend_6 = require("./tags/frontend");
exports.ModulePackFE_TranslationInfra = [
    project_1.ModuleFE_TranslationProject,
    frontend_1.ModuleFE_TranslationProvider,
    frontend_2.ModuleFE_TranslationClient,
    frontend_3.ModuleFE_TranslationResource,
    frontend_4.ModuleFE_TranslationEntry,
    frontend_5.ModuleFE_LanguageLocale,
    frontend_6.ModuleFE_GenericTags,
];
