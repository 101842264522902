"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_ConnectivityModule = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const thunder_dispatcher_1 = require("../core/thunder-dispatcher");
class ModuleFE_ConnectivityModule_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.dispatch_onConnectivityChange = new thunder_dispatcher_1.ThunderDispatcher('__onConnectivityChange');
        this.isConnected = () => this.connected;
        this.handleConnectionChange = () => {
            this.connected = this.getConnectivityStatus();
            this.dispatch_onConnectivityChange.dispatchModule();
            this.dispatch_onConnectivityChange.dispatchUI();
        };
        this.getConnectivityStatus = () => navigator.onLine;
        this.connected = this.getConnectivityStatus();
    }
    init() {
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    }
}
exports.ModuleFE_ConnectivityModule = new ModuleFE_ConnectivityModule_Class();
