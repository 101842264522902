import * as React from 'react';
import {DB_ProductTag} from '@app/shared';
import {LL_H_T} from '@nu-art/thunderstorm/frontend';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';


export class EditorItem_ProductTag
	extends Item_Editor<DB_ProductTag> {

	render() {
		return <LL_H_T className="flex__grow match_height margin__inline">
			{this.input('title', {disabled: true}).vertical('Tag')}
		</LL_H_T>;
	}
}
