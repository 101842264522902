"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_ForceUpgrade = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ModuleFE_XHR_1 = require("./http/ModuleFE_XHR");
const shared_1 = require("../../shared");
const tools_1 = require("../utils/tools");
const typed_api_1 = require("../core/typed-api");
const dispatch_onUpgradeRequired = new ts_common_1.Dispatcher('__onUpgradeRequired');
class ModuleFE_ForceUpgrade_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.compareVersion = () => {
            const def = { method: shared_1.HttpMethod.GET, path: this.config.assertVersionUrl };
            // this.v1.assertAppVersion({}).execute((response) => {
            // 	dispatch_onUpgradeRequired.dispatchModule(response);
            // });
            ModuleFE_XHR_1.ModuleFE_XHR
                .createRequest(def)
                .setRelativeUrl(this.config.assertVersionUrl)
                .execute((response) => {
                dispatch_onUpgradeRequired.dispatchModule(response);
            });
        };
        this.v1 = {
            assertAppVersion: (0, typed_api_1.apiWithQuery)(shared_1.ApiDef_ForceUpgrade.v1.assertAppVersion)
        };
    }
    init() {
        ModuleFE_XHR_1.ModuleFE_XHR.addDefaultHeader(shared_1.HeaderKey_AppVersion, `${process.env.appVersion}`);
        ModuleFE_XHR_1.ModuleFE_XHR.addDefaultHeader(shared_1.HeaderKey_BrowserType, `${(0, tools_1.browserType)()}`);
    }
    async __postInit() {
    }
}
exports.ModuleFE_ForceUpgrade = new ModuleFE_ForceUpgrade_Class();
