"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionUsersEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const editor_base_1 = require("./editor-base");
const module_pack_1 = require("../../core/module-pack");
const frontend_2 = require("@nu-art/user-account/frontend");
const ui_props_1 = require("../ui-props");
class PermissionUsersEditor extends editor_base_1.EditorBase {
    constructor() {
        //######################### Static #########################
        super(...arguments);
        this.module = module_pack_1.ModuleFE_PermissionsUser;
        this.itemName = 'Permission User';
        this.itemNamePlural = 'Permission Users';
        this.itemDisplay = (user) => this.props.renderAccount(user.accountId);
        //######################### Render #########################
        this.editorContent = () => {
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Groups' },
                React.createElement(frontend_1.LL_H_C, { className: 'groups' },
                    React.createElement(ui_props_1.MultiSelect.Group, { editable: this.state.editedItem, prop: 'groups' })));
        };
    }
    //######################### Life Cycle #########################
    __onPermissionsUsersUpdated(...params) {
        if ([frontend_1.EventType_Update, frontend_1.EventType_Create].includes(params[0])) {
            const level = params[1];
            this.reDeriveState({
                selectedItemId: level._id,
                editedItem: new frontend_1.EditableDBItem(level, module_pack_1.ModuleFE_PermissionsUser).setAutoSave(true)
            });
        }
        if (params[0] === frontend_1.EventType_Delete)
            this.reDeriveState({ selectedItemId: undefined, editedItem: undefined });
    }
    async deriveStateFromProps(nextProps, state) {
        state.items = module_pack_1.ModuleFE_PermissionsUser.cache.all();
        if (!state.editedItem && state.items.length) {
            state.editedItem = new frontend_1.EditableDBItem(state.items[0], module_pack_1.ModuleFE_PermissionsUser);
            state.selectedItemId = state.items[0]._id;
        }
        return state;
    }
}
PermissionUsersEditor.defaultProps = {
    modules: [module_pack_1.ModuleFE_PermissionsUser],
    renderAccount: (accountId) => { var _a; return ((_a = frontend_2.ModuleFE_Account.getAccounts().find(account => account._id === accountId)) === null || _a === void 0 ? void 0 : _a.email) || 'Not Found'; }
};
exports.PermissionUsersEditor = PermissionUsersEditor;
