"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATS_Permissions = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./ATS_Permissions.scss");
const __1 = require("../..");
const ModuleFE_PermissionsAssert_1 = require("../../modules/ModuleFE_PermissionsAssert");
const ui_props_1 = require("../ui-props");
class ATS_Permissions extends frontend_1.SmartComponent {
    constructor() {
        super(...arguments);
        //######################### Render #########################
        this.renderTabs = () => {
            const tabs = [
                { title: 'Projects', uid: 'projects', content: React.createElement(__1.PermissionProjectsEditor, null) },
                {
                    title: 'Domains',
                    uid: 'domains',
                    content: () => React.createElement(__1.PermissionDomainsEditor, { projectId: this.state.selectedProjectId })
                },
                {
                    title: 'Groups',
                    uid: 'groups',
                    content: React.createElement(__1.PermissionGroupsEditor, { projectId: this.state.selectedProjectId })
                },
                { title: 'Users', uid: 'users', content: React.createElement(__1.PermissionUsersEditor, null) },
            ];
            return React.createElement(frontend_1.TS_Tabs, { tabs: tabs });
        };
    }
    //######################### Life Cycle #########################
    async deriveStateFromProps(nextProps, state) {
        var _b, _c;
        state !== null && state !== void 0 ? state : (state = (this.state ? Object.assign({}, this.state) : {}));
        (_b = state.selectedProjectId) !== null && _b !== void 0 ? _b : (state.selectedProjectId = (_c = __1.ModuleFE_PermissionsProject.cache.all()[0]) === null || _c === void 0 ? void 0 : _c._id);
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { id: 'dev-page__permissions' },
            React.createElement(frontend_1.LL_H_C, { className: "match_width flex__space-between" },
                frontend_1.TS_AppTools.renderPageHeader('Permissions Editor'),
                React.createElement("div", { style: { width: 300 } },
                    React.createElement(ui_props_1.Permissions_DropDown.Project, { selected: this.state.selectedProjectId, onSelected: project => this.setState({ selectedProjectId: project._id }) })),
                React.createElement(frontend_1.TS_Button, { className: 'item-list__add-button', onClick: async () => await ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert
                        .v1.createProject({}).executeSync() }, "Create Project")),
            this.renderTabs());
    }
}
_a = ATS_Permissions;
ATS_Permissions.screen = {
    key: 'permissions',
    name: 'Permissions Editor',
    renderer: _a,
    group: 'Permissions'
};
ATS_Permissions.defaultProps = {
    modules: [__1.ModuleFE_PermissionsProject, __1.ModuleFE_PermissionsDomain, __1.ModuleFE_PermissionsAccessLevel, __1.ModuleFE_PermissionsGroup, __1.ModuleFE_PermissionsUser]
};
exports.ATS_Permissions = ATS_Permissions;
