"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Courier = void 0;
const validators_1 = require("../../../validators");
const shopify_1 = require("../../../shopify");
const ts_common_1 = require("@nu-art/ts-common");
const Validator_Courier = Object.assign(Object.assign({}, shopify_1.StoreItem_validator), { title: validators_1.tsValidateStringOneLine, description: ts_common_1.tsValidateOptional });
exports.DBDef_Courier = {
    validator: Validator_Courier,
    dbName: 'couriers',
    entityName: 'courier',
};
