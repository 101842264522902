"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Assets = exports.ModuleFE_Assets_Class = exports.dispatch_onAssetsListChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../shared");
exports.dispatch_onAssetsListChanged = new frontend_1.ThunderDispatcher('__onAssetsUpdated');
class ModuleFE_Assets_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_Assets, exports.dispatch_onAssetsListChanged);
    }
    async resolveValidSignedUrl(assetId) {
        var _a;
        const asset = this.cache.unique(assetId);
        const signedUrl = (((_a = asset === null || asset === void 0 ? void 0 : asset.signedUrl) === null || _a === void 0 ? void 0 : _a.validUntil) || 0) > (0, ts_common_1.currentTimeMillis)() ? asset === null || asset === void 0 ? void 0 : asset.signedUrl : undefined;
        if (signedUrl)
            return signedUrl.url;
        return (await exports.ModuleFE_Assets.v1.queryUnique(assetId).executeSync()).signedUrl.url;
    }
}
exports.ModuleFE_Assets_Class = ModuleFE_Assets_Class;
exports.ModuleFE_Assets = new ModuleFE_Assets_Class();
