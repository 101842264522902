"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Store = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ts_common_2 = require("@nu-art/ts-common");
const validators_1 = require("../../validators");
const types_1 = require("../types");
const ValidatorDB_Store = Object.assign(Object.assign({}, types_1.ShopifyItem_validator), { storeId: ts_common_2.tsValidateMustExist, shopifyId: (0, ts_common_2.tsValidateNumber)(), name: validators_1.tsValidateStringOneLine, email: ts_common_1.tsValidateEmail, domain: validators_1.tsValidateShopifyDomain, primaryLocale: validators_1.tsValidateShopifyLocale, supportEmail: ts_common_1.tsValidateEmail, timezone: ts_common_2.tsValidateMustExist, adminName: validators_1.tsValidateStringOneLine, plan_name: ts_common_2.tsValidateMustExist, shopifyDomain: validators_1.tsValidateShopifyDomain, currency: validators_1.tsValidateCurrency, countryCode: ts_common_2.tsValidateMustExist });
exports.DBDef_Store = {
    validator: ValidatorDB_Store,
    dbName: 'stores',
    entityName: 'store',
};
