"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogClient_Function = void 0;
const LogClient_1 = require("./LogClient");
const utils_1 = require("./utils");
class LogClient_Function_class extends LogClient_1.LogClient {
    constructor() {
        super();
        this.setComposer((tag, level) => `${level} ${tag}: `);
    }
    logMessage(level, bold, prefix, toLog) {
        for (const logParam of toLog) {
            if (logParam)
                // @ts-ignore
                if (logParam.stack) {
                    console.log(`${prefix}${(0, utils_1._logger_logException)(logParam)}`);
                    continue;
                }
            switch (typeof logParam) {
                case "undefined":
                case "function":
                case "symbol":
                case "bigint":
                    console.log(`${prefix}${typeof logParam}`);
                    continue;
                case "boolean":
                case "number":
                case "string":
                    console.log(`${prefix}${logParam}`);
                    continue;
                case "object":
                    console.log(`${prefix}${JSON.stringify(logParam)}`);
                    continue;
            }
        }
    }
}
exports.LogClient_Function = new LogClient_Function_class();
