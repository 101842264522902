"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Module = void 0;
const exceptions_1 = require("./exceptions/exceptions");
const merge_tools_1 = require("../utils/merge-tools");
const Logger_1 = require("./logger/Logger");
const date_time_tools_1 = require("../utils/date-time-tools");
class Module extends Logger_1.Logger {
    // noinspection TypeScriptAbstractClassConstructorCanBeMadeProtected
    constructor(tag) {
        super(tag);
        this.config = {};
        this.initiated = false;
        this.timeoutMap = {};
        this.runAsync = (label, toCall) => {
            setTimeout(() => {
                this.logDebug(`Running async: ${label}`);
                new Promise(toCall)
                    .then(() => {
                    this.logDebug(`Async call completed: ${label}`);
                })
                    .catch(reason => this.logError(`Async call error: ${label}`, reason));
            }, 0);
        };
        this.name = this.constructor['name'];
        if (!this.name.endsWith('_Class'))
            throw new exceptions_1.BadImplementationException(`Found module named: ${this.name}, Module class MUST end with '_Class' e.g. MyModule_Class`);
        this.name = this.name.replace('_Class', '');
    }
    debounce(handler, key, ms = 0) {
        (0, date_time_tools_1._clearTimeout)(this.timeoutMap[key]);
        this.timeoutMap[key] = (0, date_time_tools_1._setTimeout)(handler, ms);
    }
    // // possibly to add
    // public async debounceSync(handler: TimerHandler, key: string, ms = 0) {
    // 	_clearTimeout(this.timeoutMap[key]);
    //
    // 	await new Promise((resolve, reject) => {
    // 		this.timeoutMap[key] = setTimeout(async (..._args) => {
    // 			try {
    // 				await handler(..._args);
    // 				resolve();
    // 			} catch (e:any) {
    // 				reject(e);
    // 			}
    // 		}, ms) as unknown as number;
    // 	});
    // }
    throttle(handler, key, ms = 0) {
        if (this.timeoutMap[key])
            return;
        this.timeoutMap[key] = (0, date_time_tools_1._setTimeout)(() => {
            handler();
            delete this.timeoutMap[key];
        }, ms);
    }
    setConfigValidator(validator) {
        // @ts-ignore
        this.configValidator = validator;
    }
    setDefaultConfig(config) {
        // @ts-ignore
        this.config = (0, merge_tools_1.merge)(this.config, config);
    }
    getName() {
        return this.name;
    }
    setName(name) {
        this.name = name;
    }
    setConfig(config) {
        // @ts-ignore
        this.config = this.config ? (0, merge_tools_1.merge)(this.config, config || {}) : config;
        this.config.minLogLevel && this.setMinLevel(this.config.minLogLevel);
    }
    setManager(manager) {
        // @ts-ignore
        this.manager = manager;
    }
    init() {
        // ignorance is bliss
    }
    validate() {
        // ignorance is bliss
    }
}
exports.Module = Module;
