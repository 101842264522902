import * as React from 'react';
import {DB_CustomerOrder, DeliveryDetails} from '@app/shared';
import {_className, LL_H_C, LL_V_C, LL_V_L, SimpleListAdapter, TS_DropDown, TS_Input} from '@nu-art/thunderstorm/frontend';
import {Item_Editor, Props_ItemEditor} from '@nu-art/thunderstorm/frontend';
import {ICONSV4} from '@res/icons';
import {exists, filterInstances, removeFromArrayByIndex} from '@nu-art/ts-common';
import {Dialog_ShippingSticker} from '../dialogs/Dialog_ShippingSticker';
import {DeliveryServices} from '@app/shared/delivery-courier/_tbd-delivery-services';
import {ModuleFE_CustomerOrder} from '@modules/shopify';
import {Dialog_CreateOrderDelivery, EmptyDeliveryStatus} from '@pah/customer-order/dialogs/Dialog_CreateOrderDelivery';


type FullProps = Props_ItemEditor<DeliveryDetails[]> & { order: DB_CustomerOrder };

export class Component_DeliveryStatus
	extends Item_Editor<DeliveryDetails[], FullProps> {

	render(): React.ReactNode {
		const editable = this.props.editable;
		const deliveriesDetails = editable.item;
		const adapter = SimpleListAdapter(DeliveryServices, (node) => <div>{node.item.label}</div>);
		return <LL_V_L className=" customer-order__section delivery-details">
			<div className="customer-order__title">Delivery Status</div>

			{filterInstances(deliveriesDetails).map((deliveryDetails, i) => {
				const _deliveryDetails = editable.editProp(i, EmptyDeliveryStatus);
				const selectedDeliveryService = DeliveryServices.find(s => s.key === deliveryDetails?.type);

				const deliveryNumber = String(deliveryDetails.number);
				const hasDeliveryNumber = exists(deliveryNumber) && deliveryNumber !== '';
				return <LL_H_C className="delivery-details-item" key={i} style={{backgroundColor: selectedDeliveryService?.color, padding: 8}}>
					<LL_V_L>
						<TS_DropDown
							adapter={adapter}
							selected={selectedDeliveryService || DeliveryServices[0]}
							onSelected={deliveryService => {
								if (_deliveryDetails.item.type === deliveryService.key)
									return;

								return _deliveryDetails.update('type', deliveryService.key);
							}}/>

						<TS_Input
							type="text"
							value={deliveryNumber}
							placeholder={'order number'}
							onBlur={deliveryNumber => {
								if (_deliveryDetails.item.number === deliveryNumber)
									return;

								if (deliveryNumber)
									_deliveryDetails.set('status', 'ordered');

								return _deliveryDetails.update('number', deliveryNumber);
							}}/>
					</LL_V_L>
					<LL_V_C>
						<ICONSV4.remove className={_className('show-shipping-sticker', 'ts-print__gone')}
														onClick={() => {
															removeFromArrayByIndex(deliveriesDetails, i);
															return editable.save();
														}}/>
						<ICONSV4.print className={_className('show-shipping-sticker', 'ts-print__gone', deliveryNumber.trim().length === 0 && 'disable')}
													 onClick={() => deliveryNumber.trim().length !== 0 && Dialog_ShippingSticker.show(this.props.order, deliveryDetails)}/>

						<ICONSV4.magic_stick style={{visibility: hasDeliveryNumber ? 'hidden' : 'visible'}}
																 onClick={() => ModuleFE_CustomerOrder.orderDelivery({
																	 orderId: this.props.order._id,
																	 serviceKey: selectedDeliveryService?.key || ''
																 }).executeSync()}/>

					</LL_V_C>
				</LL_H_C>;
			})}

			<ICONSV4.add className="ts-print__gone" onClick={() => {
				Dialog_CreateOrderDelivery.show(this.props.order, this.props.editable);
				// deliveriesDetails.push(deepClone(EmptyDeliveryStatus));
				return editable.save();
			}}/>
		</LL_V_L>;
	}
}