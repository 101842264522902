"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BeLogged = void 0;
const array_tools_1 = require("../../utils/array-tools");
class BeLogged_Class {
    constructor() {
        this.clients = [];
        this.lineCount = 0;
    }
    addClient(client) {
        if (this.clients.indexOf(client) !== -1)
            return;
        this.clients.push(client);
    }
    removeConsole(client) {
        if (this.clients.indexOf(client) === -1)
            return;
        (0, array_tools_1.removeItemFromArray)(this.clients, client);
    }
    log(tag, level, bold, ...toLog) {
        this.logImpl(tag, level, bold, toLog);
    }
    logImpl(tag, level, bold, toLog) {
        for (const client of this.clients) {
            client.log(tag, level, bold, toLog);
        }
    }
    clearFooter() {
        for (let i = 0; i < this.lineCount + 3; i++) {
            process.stdout.write(`\n`);
        }
    }
    rewriteConsole(lineCount) {
        this.lineCount = lineCount;
    }
}
exports.BeLogged = new BeLogged_Class();
