export const RouteKey_Scrapers = 'scrapers';
export const RouteKey_Home = 'home';
export const RouteKey_Playground = 'playground';
export const RouteKey_DevScreen = 'dev-screen';

export const RouteKey_ShippingOptionList = 'shipping-option-list';

export const RouteKey_CustomerOrder = 'order';
export const RouteKey_CustomerOrderList = 'orders-list';

export const RouteKey_Product = 'product';
export const RouteKey_ProductNew = 'product-new';
export const RouteKey_ProductList = 'product-list';

export const RouteKey_ProductTemplate = 'product-template';
export const RouteKey_ProductTemplateNew = 'product-template-new';
export const RouteKey_ProductTemplateList = 'product-template-list';

export const RouteKey_Customer = 'customer';
export const RouteKey_CustomerNew = 'customer-new';
export const RouteKey_CustomerList = 'customer-list';

export const RouteKey_ShipmentV2 = 'shipment-v2';
export const RouteKey_ShipmentNewV2 = 'new-shipment-v2';
export const RouteKey_Shipment = 'shipment';
export const RouteKey_ShipmentNew = 'new-shipment';
export const RouteKey_ShipmentList = 'shipment-list';

export const RouteKey_Vendor = 'vendor';
export const RouteKey_VendorNew = 'new-vendor';
export const RouteKey_VendorList = 'vendor-list';

export const RouteKey_ProductTag = 'product-tag';
export const RouteKey_ProductTagNew = 'new-product-tag';
export const RouteKey_ProductTagList = 'product-tag-list';

export const RouteKey_StoreInventory = 'product-inventory';
export const RouteKey_StoreInventoryNew = 'new-product-inventory';
export const RouteKey_StoreInventoryList = 'product-inventory-list';

export const RouteKey_Permissions_Groups = 'permissions-groups';
export const RouteKey_Permissions_Users = 'permissions-users';
export const RouteKey_Permissions = 'permissions';
export const RouteKey_Permissions_To_Users = 'permissions-to-users';

export const RouteKey_MessageTemplateList = 'message-template-list';
export const RouteKey_InvoiceParser = 'invoice-parser';
export const RouteKey_OrderGenerator = 'order-generator';

export const RouteKey_StoreRoot = 'store-root';
export const RouteKey_OtherRoot = 'other-root';
export const RouteKey_InputRoot = 'input-root';
export const RouteKey_Translations = 'translations';
export const RouteKey_DecisionTree = 'decision-tree';

export const PageKey_ID = '_id';
export const PageKey_TreePath = 'tree-path';
export const PageKey_UserPermissions = 'user';
