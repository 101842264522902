"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_GenericTags = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const Validator_ModifiableProps = {
    parentIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    label: (0, ts_common_1.tsValidateString)(100)
};
const Validator_GeneratedProps = {};
exports.DBDef_GenericTags = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbName: 'generic-tags',
    entityName: 'generic-tags',
};
