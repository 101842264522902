"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LL_VH_C = exports.LL_H_B = exports.LL_H_C = exports.LL_H_T = exports.LL_V_R = exports.LL_V_C = exports.LL_V_L = exports.Grid = void 0;
const React = require("react");
require("./_Layouts.scss");
const tools_1 = require("../../utils/tools");
class LinearLayout extends React.Component {
    constructor(props, layoutClass) {
        super(props);
        this.layoutClass = layoutClass;
    }
    render() {
        const _a = this.props, { innerRef } = _a, props = __rest(_a, ["innerRef"]);
        return React.createElement("div", Object.assign({}, props, { ref: innerRef, className: (0, tools_1._className)(this.layoutClass, props.className) }), props.children);
    }
}
class Grid extends React.Component {
    render() {
        const _a = this.props, { innerRef } = _a, props = __rest(_a, ["innerRef"]);
        return React.createElement("div", Object.assign({}, props, { ref: innerRef, className: (0, tools_1._className)('ts-grid', props.className) }), props.children);
    }
}
exports.Grid = Grid;
class LL_V_L extends LinearLayout {
    constructor(props) {
        super(props, 'll_v_l');
    }
}
exports.LL_V_L = LL_V_L;
class LL_V_C extends LinearLayout {
    constructor(props) {
        super(props, 'll_v_c');
    }
}
exports.LL_V_C = LL_V_C;
class LL_V_R extends LinearLayout {
    constructor(props) {
        super(props, 'll_v_r');
    }
}
exports.LL_V_R = LL_V_R;
class LL_H_T extends LinearLayout {
    constructor(props) {
        super(props, 'll_h_t');
    }
}
exports.LL_H_T = LL_H_T;
class LL_H_C extends LinearLayout {
    constructor(props) {
        super(props, 'll_h_c');
    }
}
exports.LL_H_C = LL_H_C;
class LL_H_B extends LinearLayout {
    constructor(props) {
        super(props, 'll_h_b');
    }
}
exports.LL_H_B = LL_H_B;
class LL_VH_C extends LinearLayout {
    constructor(props) {
        super(props, 'll_v_c match_height flex__justify-center');
    }
}
exports.LL_VH_C = LL_VH_C;
