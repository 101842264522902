"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_BaseInput = void 0;
const React = require("react");
class TS_BaseInput extends React.Component {
    constructor(props) {
        super(props);
        this.changeValue = (event) => {
            var _a, _b;
            const value = event.target.value;
            this.setState({ value });
            (_b = (_a = this.props).onChange) === null || _b === void 0 ? void 0 : _b.call(_a, value, event.target.id);
        };
        this.state = TS_BaseInput.getInitialState(props);
    }
    static getDerivedStateFromProps(props, state) {
        if (props.id === state.id && state.name === props.name && state.initialValue === props.value)
            return { value: state.value };
        return TS_BaseInput.getInitialState(props);
    }
    static getInitialState(props) {
        return {
            id: props.id,
            name: props.name,
            initialValue: props.value,
            value: props.value || ''
        };
    }
}
exports.TS_BaseInput = TS_BaseInput;
