"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldEditorClick = void 0;
const React = require("react");
const FieldEditor_1 = require("./FieldEditor");
const ComponentSync_1 = require("../core/ComponentSync");
const ModuleFE_LocalStorage_1 = require("../modules/ModuleFE_LocalStorage");
class FieldEditorClick extends ComponentSync_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.handleSave = () => {
            this.props.onAccept(this.state.storageKey.get());
            this.endEdit();
        };
        this.startEdit = () => {
            if (this.state.isEditing)
                return;
            addEventListener('keydown', this.keyPressed);
            this.state.storageKey.set(this.props.value || '');
            this.setState({ isEditing: true });
        };
        this.endEdit = () => {
            removeEventListener('keydown', this.keyPressed);
            this.logDebug('endEdit');
            this.state.storageKey.delete();
            this.setState({ isEditing: false });
        };
        this.keyPressed = (e) => {
            if (e.code === 'Escape')
                this.endEdit();
        };
    }
    createStorageKey() {
        return new ModuleFE_LocalStorage_1.StorageKey(`editable-label-controller-${this.props.id}`);
    }
    deriveStateFromProps(nextProps) {
        return {
            storageKey: this.createStorageKey(),
            isEditing: false,
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let storageKey = prevState.storageKey;
        if (prevProps.id !== this.props.id) {
            storageKey = this.createStorageKey();
            this.setState({ storageKey: storageKey });
        }
        const prevValue = storageKey.get();
        if (!prevValue)
            storageKey.set(this.props.value || '');
    }
    render() {
        return (React.createElement("div", Object.assign({ style: { width: '100%' }, onClick: this.props.clicks === 1 ? this.startEdit : undefined, onDoubleClick: this.props.clicks === undefined || this.props.clicks === 2 ? this.startEdit : undefined, onBlur: () => this.handleSave() }, this.props.labelProps),
            React.createElement(FieldEditor_1.FieldEditor, { id: this.props.id, type: this.props.type, editorType: this.props.editorType, isEditing: this.state.isEditing, inputProps: this.props.inputProps, labelProps: this.props.labelProps, onCancel: this.endEdit, onAccept: this.handleSave, storageKey: this.state.storageKey, value: this.props.value })));
    }
}
exports.FieldEditorClick = FieldEditorClick;
