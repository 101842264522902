import * as React from 'react';
import {CustomerOrderItem, DB_CustomerOrder, DB_Product, DB_Variant} from '@app/shared';
import {ModuleFE_CustomerOrder, ModuleFE_Products, ModuleFE_ProductVariants, ModuleFE_VariantOptions} from '@modules/index';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';
import {filterInstances, roundNumber} from '@nu-art/ts-common';
import {ICONSV4} from '@res/icons';
import {LL_V_C, LL_V_L, TableHeaders, TS_Table} from '@nu-art/thunderstorm/frontend';


type OrderItemUI = {
	srcUrl: string,
	title: string,
	quantity: number,
	price: number,
	discount: number,
	variant: string,
	fulfilledCount: number,
	dbProduct?: DB_Product,
	dbVariant?: DB_Variant,
}

type State = {
	refundedOpen?: boolean,
};

type Props = {
	onItemSelected?: (product: DB_Product, variant: DB_Variant) => void
	startIndex?: number
};

const headers: TableHeaders<OrderItemUI> = [
	{header: 'srcUrl', widthPx: 60, className: 'image'},
	{header: 'quantity', widthPx: 50},
	{header: 'title', widthPx: 'auto'},
	{header: 'price', widthPx: 70},
	{header: 'discount', widthPx: 70},
];

export class Component_OrderItems
	extends Item_Editor<DB_CustomerOrder, Props, State> {

	static defaultProps = {
		moduleFE: ModuleFE_CustomerOrder,
		modules: []
	};

	render() {
		const order = this.props.editable.item;

		const refunded: CustomerOrderItem[] = order.refunds?.reduce((toRet, refund) => {
			toRet.push(...refund.itemIds);
			return toRet;
		}, [] as CustomerOrderItem[]) || [];

		const itemIds = order.itemIds;
		const rows: OrderItemUI[] = filterInstances((itemIds || []).map(orderItem => {
			const product = ModuleFE_Products.cache.all().find(p => p._id === orderItem.productId);
			if (!product)
				return;

			const fulfilledCount = order.fulfillments?.reduce((fulfilledCount, fulfillment) => {
				fulfillment.items.forEach(fItem => {
					if (fItem.variantId === orderItem.variantId)
						fulfilledCount += fItem.quantity;
				});
				return fulfilledCount;
			}, 0) || 0;

			const variant = ModuleFE_ProductVariants.cache.all().find(v => v._id === orderItem.variantId);
			const newVar: OrderItemUI = {
				srcUrl: JSON.parse(product.blob).image?.src,
				quantity: orderItem.quantity,
				fulfilledCount,
				title: product.title,
				variant: variant?.variantOptionIds.map(optionId => ModuleFE_VariantOptions.cache.all().find(vo => vo._id === optionId)?.label).join(' ') || '',
				price: orderItem.price,
				discount: orderItem.discount || 0,
				dbVariant: variant,
				dbProduct: product,
			};
			return newVar;
		}));

		const missingRows: OrderItemUI[] = (order.missingItems || [])?.map(item => {
			return {
				srcUrl: '',
				fulfilledCount: 0,
				quantity: item.quantity,
				title: item.name,
				variant: '',
				price: item.price,
				discount: item.discount || 0,
			};
		});

		const allRows = [...rows, ...missingRows];
		const totalCost = allRows.reduce((toRet, item) => toRet + ((item.price || 0) * (item.quantity) - (item.discount || 0)), 0);

		const notFulfilled = allRows.filter(row => row.fulfilledCount < row.quantity);
		const fulfilled = allRows.filter(row => row.fulfilledCount > 0);

		return <LL_V_L className="customer-order__section customer-order__items">
			{this.renderItems(notFulfilled)}
			{this.renderFulfilled(fulfilled)}
			{order.shippingOption &&
				<div style={{
					marginTop: 12,
					fontSize: 18
				}}>{order.shippingOption.title}{order.shippingOption.price.value ? `: ${order.shippingOption.price.value} ${order.shippingOption.price.currency}` : ''}</div>}
			<div style={{fontSize: 20, marginTop: 12}}>Subtotal: {roundNumber(totalCost, 2)}</div>
			<div style={{marginBottom: 8, fontSize: 22, marginTop: 8}}>Total: {roundNumber(totalCost + (order.shippingOption?.price.value || 0), 2)}</div>
			{this.renderReturnedItems(refunded)}
		</LL_V_L>;
	}

	private renderItems(allRows: OrderItemUI[]) {
		return <TS_Table<OrderItemUI>
			table={{border: '1', frame: 'void', rules: 'rows', style: {width: '100%'}} as any}
			header={headers}
			rows={allRows}
			tr={{border: '1px solid #fafafa'} as any}
			td={(row) => {
				return {onClick: () => row?.dbVariant && row?.dbProduct && this.props.onItemSelected?.(row.dbProduct, row.dbVariant)};
			}}
			cellRenderer={(prop, item, index) => {
				if (prop === 'srcUrl')
					return <img className="image" src={item[prop]} style={{width: 60, paddingTop: 12, paddingBottom: 12}}/>;

				if (prop === 'quantity') {
					let fontSize = 20;
					if (item[prop] > 1)
						fontSize += Math.min(item[prop] - 1, 5) * 5;

					return <LL_V_C style={{fontSize, fontWeight: fontSize == 20 ? 'unset' : 'bold'}}>{item[prop]}</LL_V_C>;
				}

				if (prop === 'title')
					return <LL_V_L className="product-title">
						<div style={{whiteSpace: 'pre-wrap'}}>{item[prop]}</div>
						<div>{item.variant}</div>
					</LL_V_L>;

				if (prop === 'price')
					return <LL_V_C style={{textDecoration: item.discount ? 'line-through' : 'unset'}}>{item[prop] * item.quantity}</LL_V_C>;

				if (prop === 'discount')
					return <LL_V_C>{item[prop] ? (item.price * item.quantity - item.discount) : ''}</LL_V_C>;

			}}/>;
	}

	private renderReturnedItems(refunded: CustomerOrderItem[]) {
		if (!refunded.length)
			return '';

		return <div className="ll_v_l match_width">
			<hr className="match_width"/>
			<div className="ll_h_c" style={{marginBottom: 8, marginTop: 8}} onClick={() => this.setState({refundedOpen: !this.state.refundedOpen})}>
				{ICONSV4[this.state.refundedOpen ? 'triangle_down' : 'triangle_up']({style: {marginInlineEnd: 8}})}
				<div>Returned Items</div>
			</div>
			{!this.state.refundedOpen ? '' : 'to be items'}
		</div>;
	}

	private renderFulfilled(fulfilled: OrderItemUI[]) {
		if (fulfilled.length === 0)
			return;

		return <>
			<div className="match_width" style={{opacity: 0.3}}>
				{this.renderItems(fulfilled)}
			</div>
		</>;
	}
}