"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_PropRenderer = void 0;
require("./TS_PropRenderer.scss");
const React = require("react");
const Layouts_1 = require("../Layouts");
const tools_1 = require("../../utils/tools");
const ts_common_1 = require("@nu-art/ts-common");
const TS_PropRenderer_Horizontal = (props) => {
    const className = (0, tools_1._className)('ts-prop-renderer horizontal', props.disabled && 'disabled', props.className);
    const { label, error } = props, _props = __rest(props, ["label", "error"]);
    let _LinearComponent;
    switch (props.verticalAlignment) {
        case 'top':
            _LinearComponent = Layouts_1.LL_H_T;
            break;
        case 'bottom':
            _LinearComponent = Layouts_1.LL_H_B;
            break;
        default:
            _LinearComponent = Layouts_1.LL_H_C;
            break;
    }
    return React.createElement(_LinearComponent, Object.assign({}, _props, { className: className }),
        React.createElement("div", { className: 'ts-prop-renderer__label' }, (0, ts_common_1.resolveContent)(label)),
        props.children);
};
const TS_PropRenderer_Vertical = (props) => {
    const className = (0, tools_1._className)('ts-prop-renderer vertical', props.disabled && 'disabled', props.className);
    const { label, error } = props, _props = __rest(props, ["label", "error"]);
    return React.createElement(Layouts_1.LL_V_L, Object.assign({}, _props, { className: className }),
        React.createElement("div", { className: 'ts-prop-renderer__label' }, (0, ts_common_1.resolveContent)(label)),
        props.children);
};
exports.TS_PropRenderer = {
    Vertical: TS_PropRenderer_Vertical,
    Horizontal: TS_PropRenderer_Horizontal,
};
