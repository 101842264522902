"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_ButtonLoader = void 0;
const React = require("react");
require("./TS_ButtonLoader.scss");
class TS_ButtonLoader extends React.Component {
    render() {
        return React.createElement("div", { className: "ts-button-loader" },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null));
    }
}
exports.TS_ButtonLoader = TS_ButtonLoader;
