import * as React from 'react';
import {AppPage, CellRenderer, TS_Input, TS_Table, ModuleFE_XHR} from '@nu-art/thunderstorm/frontend';
import {OnPushMessageReceived, ModuleFE_PushPubSub} from '@nu-art/push-pub-sub/frontend';
import {ICONSV4} from '@res/icons';
import {__stringify, removeFromArrayByIndex,} from '@nu-art/ts-common';
import {DB_Notifications, SubscribeProps} from '@nu-art/push-pub-sub/shared/types';
import {HttpMethod} from '@nu-art/thunderstorm';
import {Example_TriggerPush} from '@app/shared';


type ObjProps = {
	key: string
	value: string
}

type Actions = 'delete'

type State = {
	registerKey: string,
	registerProps: ObjProps[]
	triggerKey: string,
	triggerProps: ObjProps[]
}

type Props = {}

function createNewInstance() {
	return {key: '', value: ''};
}

class Pg_Component
	extends AppPage<Props, State>
	implements OnPushMessageReceived {

	static defaultProps = {
		pageTitle: () => PgDev_Push.name
	};

	constructor(p: Props) {
		super(p);
	}

	protected deriveStateFromProps(nextProps: Props): State | undefined {
		return {
			registerKey: '',
			registerProps: [createNewInstance()],
			triggerKey: '',
			triggerProps: [createNewInstance()],
		};
	}

	__onMessageReceived(notification: DB_Notifications<any>): void {
		console.log('GOT PUSH:', notification.pushKey, notification.props, notification.data);
	}

	render() {
		return <div className="ll_h_t">
			<div>{this.renderTable('Register', this.state.registerProps, 'registerKey', this.subscribe)}</div>
			<div style={{margin: '4px'}}/>
			<div>{this.renderTable('Trigger', this.state.triggerProps, 'triggerKey', this.trigger)}</div>
			{ICONSV4.x({
				onClick: (e) => {
					return ModuleFE_PushPubSub.deleteToken();
				}
			})}
		</div>;
	}

	private trigger = () => {
		const pushObj = {
			pushKey: this.state.triggerKey,
			props: this.state.triggerProps.reduce((toRet, item) => {
				if (!!item.key && item.key !== '')
					toRet[item.key] = item.value;
				return toRet;
			}, {} as SubscribeProps),
			data: JSON.parse('{"a":"aaa"}'),
		};

		this.logInfo(`triggering push: ${__stringify(pushObj)}`);
		ModuleFE_XHR
			.createRequest<Example_TriggerPush>({method: HttpMethod.POST, path: '/v1/sample/trigger-push'})
			.setBodyAsJson(pushObj)
			.execute();

	};

	private subscribe = async () => ModuleFE_PushPubSub.v1.register({
		pushKey: this.state.registerKey,
		props: this.state.registerProps.reduce((toRet, item) => {
			if (!!item.key && item.key !== '')
				toRet[item.key] = item.value;
			return toRet;
		}, {} as SubscribeProps)
	});

	private renderTable(title: string, rows: ObjProps[], key: 'registerKey' | 'triggerKey', action: () => void) {
		const cellRenderer: CellRenderer<ObjProps, Actions> = (prop, item, index: number) => {
			if (prop === 'delete')
				return ICONSV4.x({
					style: {verticalAlign: 'middle'},
					onClick: () => {
						removeFromArrayByIndex(rows, index);
						this.forceUpdate();
					}
				});

			return this.renderInput(`${key}-${prop}-${index}`, item[prop], (value: string) => {
				item[prop] = value;
				this.forceUpdate();
			});
		};

		const addNewRow = () => {
			rows[rows.length] = createNewInstance();
			this.forceUpdate();
		};

		return <div className="ll_v_l">
			{this.title(title)}
			{this.renderInputWithLabel(key, key, this.state[key], (value) => this.setState({[key]: value} as unknown as State))}
			{<TS_Table<ObjProps, Actions>
				id={key}
				header={['key', 'value', 'delete']}
				rows={rows}
				headerRenderer={columnKey => <div>{columnKey}</div>}
				cellRenderer={cellRenderer}
				tr={{style: {padding: '5px'}}}
			/>}
			{ICONSV4.add({onClick: addNewRow})}
			{ICONSV4.send({onClick: action})}
		</div>;
	}

	title(title: string): React.ReactNode {
		return <div>{title}</div>;
	}

	private renderInput(id: string, value: string | number, onChange: (value: string, id: string) => void) {
		return <div className="ll_v_l">
			<TS_Input
				onChange={onChange}
				type="text"
				id={id}
				style={{border: '1px solid black'}}
				value={value.toString()}/>
		</div>;
	}

	private renderInputWithLabel(label: string, id: string, value: string, onChange: (value: string, id: string) => void) {
		return <div className="ll_v_l">
			<div style={{marginBottom: '4px'}}>{label}</div>
			<TS_Input
				onChange={onChange}
				type="text"
				id={id}
				style={{border: '1px solid black'}}
				value={value}/>
		</div>;
	}
}

export const PgDev_Push = {name: 'DevTool - Push', renderer: Pg_Component};
