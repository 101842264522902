"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Notifications = exports.ModuleFE_Notifications_Class = exports.NotificationStatus_Failed = exports.NotificationStatus_Resolved = exports.NotificationStatus_InProgress = exports.NotificationStatus_Normal = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ModuleFE_LocalStorage_1 = require("../modules/ModuleFE_LocalStorage");
const thunder_dispatcher_1 = require("../core/thunder-dispatcher");
exports.NotificationStatus_Normal = 'normal';
exports.NotificationStatus_InProgress = 'in-progress';
exports.NotificationStatus_Resolved = 'resolved';
exports.NotificationStatus_Failed = 'failed';
const dispatch_showNotifications = new thunder_dispatcher_1.ThunderDispatcher('__showNotifications');
class ModuleFE_Notifications_Class extends ts_common_1.Module {
    constructor(maxNotifications = 15) {
        super();
        this.showing = [];
        this.timeouts = {};
        this.notificationStorage = new ModuleFE_LocalStorage_1.StorageKey('notifications');
        this.maxNotifications = maxNotifications;
    }
    create(id) {
        const __created = (0, ts_common_1.currentTimeMillis)();
        const notification = this.notificationStorage.get([]).find(n => n._id === id) || {
            postDelayed: 5000,
            status: exports.NotificationStatus_Normal,
            title: '',
            message: '',
            _id: (0, ts_common_1.generateHex)(8),
            __created,
            __updated: __created,
            _v: '1.0.0'
        };
        const uiNotification = {
            postDelayed: (postDelayed) => {
                notification.postDelayed = postDelayed;
                return uiNotification;
            },
            content: (title, message) => {
                notification.title = title;
                notification.message = message || '';
                return uiNotification;
            },
            setStatus: (status) => {
                notification.status = status;
                return uiNotification;
            },
            execute: async (action) => {
                notification.status = exports.NotificationStatus_InProgress;
                uiNotification.show(-1);
                try {
                    const t = await action();
                    notification.status = exports.NotificationStatus_Resolved;
                    uiNotification.show();
                    return t;
                }
                catch (e) {
                    notification.status = exports.NotificationStatus_Failed;
                    uiNotification.show();
                    throw e;
                }
            },
            show: (postDelay) => {
                this.showImpl(notification, postDelay || notification.postDelayed);
            },
            hide: () => {
                this.hide(notification);
            },
            delete: () => {
                this.delete(notification);
            },
        };
        return uiNotification;
    }
    upsert(notification) {
        const notifications = this.notificationStorage.get([]);
        const index = notifications.findIndex(n => n._id === notification._id);
        if (index !== -1)
            notifications[index] = notification;
        else
            (0, ts_common_1.addItemToArrayAtIndex)(notifications, notification, 0);
        //If length of array is bigger than max, pop last item
        if (notifications.length > this.maxNotifications)
            notifications.pop();
        this.notificationStorage.set(notifications);
    }
    showImpl(notification, postDelay) {
        this.upsert(notification);
        const index = this.showing.findIndex(n => n._id === notification._id);
        if (index !== -1)
            this.showing[index] = notification;
        else
            (0, ts_common_1.addItemToArrayAtIndex)(this.showing, notification, 0);
        dispatch_showNotifications.dispatchUI(this.showing);
        if (this.timeouts[notification._id]) {
            clearTimeout(this.timeouts[notification._id]);
            delete this.timeouts[notification._id];
        }
        if (postDelay <= 0)
            return;
        const timeoutId = setTimeout(() => this.hide(notification), postDelay);
        this.timeouts[notification._id] = timeoutId;
    }
    hide(notification) {
        const index = this.showing.findIndex(n => n._id === notification._id);
        if (index === -1)
            return;
        (0, ts_common_1.removeFromArrayByIndex)(this.showing, index);
        dispatch_showNotifications.dispatchUI(this.showing);
    }
    delete(notification) {
        this.hide(notification);
        this.notificationStorage.set(this.notificationStorage.get([]).filter(item => item._id !== notification._id));
    }
    showAllNotifications() {
        const notifications = this.notificationStorage.get([]);
        dispatch_showNotifications.dispatchUI(notifications);
    }
    hideAllNotifications() {
        dispatch_showNotifications.dispatchUI([]);
    }
    async show(title, content, action) {
        const notification = this.create().content(title, content);
        try {
            await notification.execute(action);
            notification.content(`${title} - Success`).show();
        }
        catch (e) {
            this.logError(e);
            notification.content(`Failed ${title}`, e.message).show();
            throw e;
        }
        return notification;
    }
}
exports.ModuleFE_Notifications_Class = ModuleFE_Notifications_Class;
exports.ModuleFE_Notifications = new ModuleFE_Notifications_Class();
