import {CONST_CountryCodes, DB_Product, DB_Variant} from '@app/shared';
import {LL_H_C, LL_V_C, LL_V_L, ModuleFE_Dialog, ModuleFE_Thunderstorm, ModuleFE_Toaster, TS_Printable} from '@nu-art/thunderstorm/frontend';
import React from 'react';
import {LogLevel} from '@nu-art/ts-common';
import {ModuleFE_Translations} from '@modules/ModuleFE_Translations';
import {ModuleFE_ProductTemplate, ModuleFE_Vendors} from '@modules/shopify';
import {ICONSV4} from '@res/icons';
import {Props_SmartComponent, SmartComponent, State_SmartComponent} from '@nu-art/thunderstorm/frontend';
import './Dialog_ProductPreview.scss';


type Props = {
	product: DB_Product,
	variant: DB_Variant
}

type State = {
	ref?: HTMLDivElement;
	country?: string;
}
type FullProps = Props_SmartComponent & Props;
type FullState = State_SmartComponent & State;


export class Dialog_ProductPreview
	extends SmartComponent<Props, State> {
	static defaultProps = {
		modules: [ModuleFE_Translations, ModuleFE_Vendors]
	};

	constructor(p: FullProps) {
		super(p);
		this.logger.setMinLevel(LogLevel.Verbose);
	}

	protected async deriveStateFromProps(nextProps: FullProps, state: FullState) {
		const countryOfOrigin = nextProps.variant.countryOfOrigin;
		if (countryOfOrigin) {
			state.country = await ModuleFE_Translations.translateCountry(CONST_CountryCodes[countryOfOrigin || ''], 'he');
		}
		return state;
	}

	static show(product: DB_Product, variant: DB_Variant) {
		ModuleFE_Dialog.show(<Dialog_ProductPreview product={product} variant={variant}/>);
	}

	render() {
		const {product, variant} = this.props;
		const vendor = ModuleFE_Vendors.cache.unique(product.vendorId)!;
		const properties = ModuleFE_ProductTemplate.resolveProductQuality(product.description);
		this.logInfo(properties);
		const material = properties?.find(p => p.key === 'Material')?.values.join(' / ');

		//todo need to translate the props

		return (
			<LL_V_C className="customer-order__product-preview dialog">
				<LL_V_C className="ts-dialog__header">{product.title}</LL_V_C>
				<LL_V_C className="ts-dialog__content">
					<div>Vendor: {vendor?.title}</div>
					{variant?.countryOfOrigin && <div>Country: {CONST_CountryCodes[variant.countryOfOrigin]}</div>}
					{/*{properties?.map(p => <div className="ll_v_l">*/}
					{/*	<div>{p.key}:</div>*/}
					{/*	{p.values.map(v => <div style={{marginLeft: 12}}>{v}</div>)}*/}
					{/*</div>)}*/}
					<LL_H_C className="ts-dialog__content">
						<ICONSV4.print className="print-icon" onClick={() => {
							if (!this.state.country)
								return ModuleFE_Toaster.toastError('Cannot print, missing country');

							if (!material)
								return ModuleFE_Toaster.toastError('Cannot print, missing material');

							return this.state.ref && ModuleFE_Thunderstorm.printDiv(this.state.ref);
						}}/>
						<div className="product-sticker__printable-area-wrapper">
							<TS_Printable className="product-sticker__printable-area" printable={(ref) => this.setState({ref})}>
								<LL_V_L className="printing-sticker">
									<LL_H_C>
										<div className="content-small">יצרן:</div>
										<div className="content-small">{vendor.title}</div>
									</LL_H_C>
									<LL_H_C>
										<div className="content-small">ארץ יצור:</div>
										<div className="content-larger">{this.state.country}</div>
									</LL_H_C>
									<LL_H_C>
										<div className="content-small">יבואן:</div>
										<div className="content-small">פטיט פאן, 209665686</div>
									</LL_H_C>
									<div className="content-larger">יורם קניוק 5, תל אביב</div>
									<LL_H_C>
										<div className="content-small">מחיר:</div>
										<div className="content-larger">{variant.price.toFixed(2)}</div>
									</LL_H_C>
									<div className="content-long">{material}</div>
								</LL_V_L>
							</TS_Printable>
						</div>
					</LL_H_C>
				</LL_V_C>
			</LL_V_C>
		);
	}
}