"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_BaseTheme = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ModuleFE_LocalStorage_1 = require("./ModuleFE_LocalStorage");
class ModuleFE_BaseTheme extends ts_common_1.Module {
    constructor(options) {
        super();
        this.getOptions = () => this.options;
        this.options = options;
    }
    init() {
        (0, ts_common_1._keys)(this.options).forEach((option) => {
            return this.setThemeProp(option, this.getThemeProp(option));
        });
    }
    setThemeProp(themeKey, themeValue) {
        var _a;
        const _themeKey = themeKey;
        (_a = document.querySelector('body')) === null || _a === void 0 ? void 0 : _a.setAttribute(_themeKey, themeValue);
        this.createStorageKey(themeKey).set(themeValue);
    }
    getThemeProp(themeKey) {
        return this.createStorageKey(themeKey).get(this.options[themeKey][0]);
    }
    createStorageKey(themeKey) {
        const _themeKey = themeKey;
        return new ModuleFE_LocalStorage_1.StorageKey(`theme-key--${_themeKey}`);
    }
}
exports.ModuleFE_BaseTheme = ModuleFE_BaseTheme;
