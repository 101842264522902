"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModulePackFE_TSEssentials = void 0;
const ModuleFE_XHR_1 = require("./http/ModuleFE_XHR");
__exportStar(require("./ModuleFE_BrowserHistory"), exports);
__exportStar(require("./ModuleFE_ConnectivityModule"), exports);
__exportStar(require("./ModuleFE_ForceUpgrade"), exports);
__exportStar(require("./ModuleFE_Window"), exports);
__exportStar(require("./ModuleFE_WindowMessenger"), exports);
__exportStar(require("./ModuleFE_Locale"), exports);
__exportStar(require("./ModuleFE_LocalStorage"), exports);
__exportStar(require("./ModuleFE_Thunderstorm"), exports);
__exportStar(require("./clearWebsiteDataDispatcher"), exports);
__exportStar(require("./ModuleFE_BaseTheme"), exports);
__exportStar(require("./routing"), exports);
__exportStar(require("./http/ModuleFE_XHR"), exports);
__exportStar(require("./action-processor/ModuleFE_ActionProcessor"), exports);
__exportStar(require("./component-loader"), exports);
exports.ModulePackFE_TSEssentials = [
    ModuleFE_XHR_1.ModuleFE_XHR,
];
