"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionKeysEditor = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
require("../permission-keys-editor.scss");
const Component_AccessLevelsEditor_1 = require("./Component_AccessLevelsEditor");
const ModuleFE_PermissionsAccessLevel_1 = require("../../../modules/manage/ModuleFE_PermissionsAccessLevel");
const ModuleFE_PermissionsAssert_1 = require("../../../modules/ModuleFE_PermissionsAssert");
class PermissionKeysEditor extends frontend_1.SmartComponent {
    constructor() {
        super(...arguments);
        this.renderKey = (item) => {
            var _a;
            const className = (0, frontend_1._className)('item-list__list-item', item.key === ((_a = this.state.editedItem) === null || _a === void 0 ? void 0 : _a.key) ? 'selected' : undefined);
            return React.createElement("div", { className: className, onClick: () => this.setState({ editedItem: item }), key: item.key }, item.key);
        };
        this.renderKeyList = () => {
            const allKeys = ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.getAllPermissionKeys();
            return React.createElement(frontend_1.LL_V_L, { className: 'item-list' },
                React.createElement("div", { className: 'item-list__header' }, "Permission Keys"),
                React.createElement(frontend_1.LL_V_L, { className: 'item-list__list' }, (0, ts_common_1._values)(allKeys).map(this.renderKey)));
        };
        this.renderKeyEditor = () => {
            if (!this.state.editedItem)
                return '';
            return React.createElement(frontend_1.LL_V_L, { className: 'item-editor' },
                React.createElement("div", { className: 'item-list__header' }, this.state.editedItem.key),
                this.renderAccessLevels());
        };
    }
    async deriveStateFromProps(nextProps, state) {
        state !== null && state !== void 0 ? state : (state = this.state ? Object.assign({}, this.state) : {});
        return state;
    }
    renderAccessLevels() {
        if (!this.state.editedItem)
            return null;
        return React.createElement(Component_AccessLevelsEditor_1.Component_AccessLevelsEditor, { permissionKey: this.state.editedItem });
    }
    render() {
        return React.createElement(frontend_1.LL_H_C, { className: 'permissions-key-editor match_parent' },
            this.renderKeyList(),
            this.renderKeyEditor());
    }
}
PermissionKeysEditor.defaultProps = {
    modules: [ModuleFE_PermissionsAccessLevel_1.ModuleFE_PermissionsAccessLevel]
};
exports.PermissionKeysEditor = PermissionKeysEditor;
