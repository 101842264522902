"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultLevelsRouteLookupWords = exports.DuplicateDefaultAccessLevels = exports.defaultAccessLevels = exports.DefaultAccessLevel_Admin = exports.DefaultAccessLevel_Delete = exports.DefaultAccessLevel_Write = exports.DefaultAccessLevel_Read = exports.DefaultAccessLevel_NoAccess = exports.DomainNamespace_PermissionManagement = exports.DomainNamespace_PermissionAssignment = exports.Prefix_PermissionKey = void 0;
const ts_common_1 = require("@nu-art/ts-common");
exports.Prefix_PermissionKey = 'permission-key--';
exports.DomainNamespace_PermissionAssignment = 'Permissions Assignment';
exports.DomainNamespace_PermissionManagement = 'Permissions Management';
exports.DefaultAccessLevel_NoAccess = Object.freeze({ name: 'No-Access', value: 0 });
exports.DefaultAccessLevel_Read = Object.freeze({ name: 'Read', value: 200 });
exports.DefaultAccessLevel_Write = Object.freeze({ name: 'Write', value: 400 });
exports.DefaultAccessLevel_Delete = Object.freeze({ name: 'Delete', value: 600 });
exports.DefaultAccessLevel_Admin = Object.freeze({ name: 'Admin', value: 1000 });
exports.defaultAccessLevels = [
    exports.DefaultAccessLevel_NoAccess,
    exports.DefaultAccessLevel_Read,
    exports.DefaultAccessLevel_Write,
    exports.DefaultAccessLevel_Delete,
    exports.DefaultAccessLevel_Admin,
];
const DuplicateDefaultAccessLevels = (seed) => {
    return [
        Object.assign({}, exports.DefaultAccessLevel_NoAccess),
        Object.assign({}, exports.DefaultAccessLevel_Read),
        Object.assign({}, exports.DefaultAccessLevel_Write),
        Object.assign({}, exports.DefaultAccessLevel_Delete),
        Object.assign({}, exports.DefaultAccessLevel_Admin),
    ].map(level => (Object.assign(Object.assign({}, level), { _id: (0, ts_common_1.md5)(`${seed}${level.name}`) })));
};
exports.DuplicateDefaultAccessLevels = DuplicateDefaultAccessLevels;
exports.defaultLevelsRouteLookupWords = {
    'query': 'Read',
    'query-unique': 'Read',
    'sync': 'Read',
    'patch': 'Write',
    'upsert': 'Write',
    'upsert-all': 'Write',
    'delete': 'Delete',
    'delete-all': 'Delete',
    'delete-unique': 'Delete'
};
