"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATS_Translation = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./ATS_Translation.scss");
const dropdown_1 = require("../../component/dropdown");
const ts_styles_1 = require("@nu-art/ts-styles");
const Dialog_TranslationProject_1 = require("../../project/dialogs/Dialog_TranslationProject");
const EditableDBItemV3_1 = require("@nu-art/thunderstorm/frontend/utils/EditableDBItemV3");
const project_1 = require("../../project");
const ts_common_1 = require("@nu-art/ts-common");
const frontend_2 = require("../../../frontend");
class ATS_Translation extends frontend_1.SmartComponent {
    constructor(p) {
        super(p);
        this.logger.setMinLevel(ts_common_1.LogLevel.Verbose);
    }
    //######################### Life Cycle #########################
    async deriveStateFromProps(nextProps, state) {
        var _b, _c;
        state !== null && state !== void 0 ? state : (state = (this.state ? Object.assign({}, this.state) : {}));
        (_b = state.selectedProjectId) !== null && _b !== void 0 ? _b : (state.selectedProjectId = (_c = project_1.ModuleFE_TranslationProject.cache.all()[0]) === null || _c === void 0 ? void 0 : _c._id);
        return state;
    }
    //######################### Render #########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { id: 'dev-page__translation' },
            React.createElement(frontend_1.LL_H_C, { className: "match_width flex__space-between" },
                frontend_1.TS_AppTools.renderPageHeader('Translation Editor'),
                React.createElement(frontend_1.LL_H_C, { style: { width: 300, marginRight: 100 } },
                    React.createElement(dropdown_1.Translation_DropDown.Project, { selected: this.state.selectedProjectId, onSelected: project => this.setState({ selectedProjectId: project._id }) }),
                    React.createElement(ts_styles_1.TS_Icons.save.component, { style: { width: 20 }, className: 'item-list__add-button', onClick: () => Dialog_TranslationProject_1.Dialog_TranslationProject.show(new EditableDBItemV3_1.EditableDBItemV3({}, project_1.ModuleFE_TranslationProject)) }))));
    }
}
_a = ATS_Translation;
ATS_Translation.screen = {
    key: 'translation',
    name: 'Translation Editor',
    renderer: _a,
    group: 'Translation'
};
ATS_Translation.defaultProps = {
    modules: frontend_2.ModulePackFE_TranslationInfra
};
exports.ATS_Translation = ATS_Translation;
