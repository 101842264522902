"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_AppTools = void 0;
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const React = require("react");
require("./TS_AppTools.scss");
const Layouts_1 = require("../Layouts");
const ModuleFE_LocalStorage_1 = require("../../modules/ModuleFE_LocalStorage");
const tools_1 = require("../../utils/tools");
const TS_ErrorBoundary_1 = require("../TS_ErrorBoundary");
const ModuleFE_RoutingV2_1 = require("../../modules/routing/ModuleFE_RoutingV2");
const ts_common_1 = require("@nu-art/ts-common");
const react_router_dom_1 = require("react-router-dom");
const TS_AppTools_Default_1 = require("./TS_AppTools_Default");
const ts_styles_1 = require("@nu-art/ts-styles");
const TS_CollapsableContainer_1 = require("../TS_CollapsableContainer");
const noGroupLabel = 'Other';
const collapseStateStorage = new ModuleFE_LocalStorage_1.StorageKey('app-tools-collapse-state');
class TS_AppTools extends React.Component {
    static createRoute(screens, path = 'app-tools') {
        this.screens = screens;
        return this.Route = {
            path: 'app-tools',
            key: 'app-tools',
            Component: this,
            children: [
                TS_AppTools_Default_1.TS_AppTools_Default.Route,
                ...TS_AppTools.screens.map(screen => {
                    return ({ key: screen.key || (screen.key = screen.name), path: (0, ts_common_1.md5)(screen.name), Component: screen.renderer });
                }),
            ]
        };
    }
    static renderPageHeader(title) {
        return React.createElement("div", { className: 'app-tools-page__page__header' }, title);
    }
    // ######################### Life Cycle #########################
    constructor(props) {
        var _a, _b;
        super(props);
        // ######################### Logic #########################
        this.toggleGroupCollapse = (key) => {
            const collapse = Object.assign({}, this.state);
            collapse.groups[key] = !collapse.groups[key];
            this.setState(collapse);
            collapseStateStorage.set(collapse);
        };
        this.toggleNavBarCollapse = () => {
            const collapse = Object.assign({}, this.state);
            collapse.navbarCollapse = !collapse.navbarCollapse;
            this.setState(collapse);
            collapseStateStorage.set(collapse);
        };
        this.groupsSort = (g1, g2) => {
            if (g1.label === noGroupLabel)
                return 1;
            if (g2.label === noGroupLabel)
                return -1;
            return g1.label > g2.label ? -1 : 1;
        };
        this.groupScreens = (screens) => {
            let groups = screens.reduce((acc, curr) => {
                if (!curr.group) {
                    acc[0].screens.push(curr);
                    return acc;
                }
                let index = acc.findIndex(i => i.label === curr.group);
                if (index < 0) {
                    acc.push({ label: curr.group, screens: [] });
                    index = acc.length - 1;
                }
                acc[index].screens.push(curr);
                return acc;
            }, [{ label: noGroupLabel, screens: [] }]);
            groups = groups.sort(this.groupsSort);
            return groups;
        };
        // ######################### Render #########################
        this.renderHeader = () => {
            return React.createElement(Layouts_1.LL_H_C, { className: 'ts-app-tools__header' },
                React.createElement(ts_styles_1.TS_Icons.menu.component, { className: 'ts-app-tools__header__caret', onClick: this.toggleNavBarCollapse }),
                React.createElement("span", { className: 'ts-app-tools__header__title' }, "App-Tools"));
        };
        this.renderNavbarItem = (screen) => {
            var _a;
            const route = TS_AppTools.Route.children.find(i => i.key === screen.key);
            if (!route)
                throw new ts_common_1.ThisShouldNotHappenException(`Couldn't find route for screen with key ${screen.name}`);
            const Icon = (_a = screen.icon) !== null && _a !== void 0 ? _a : ts_styles_1.TS_Icons.gear.component;
            return React.createElement(ModuleFE_RoutingV2_1.TS_NavLink, { key: screen.key, route: route, className: ({ isActive }) => (0, tools_1._className)('ts-app-tools__nav-bar__item', isActive ? 'selected' : undefined) },
                React.createElement(Icon, null),
                React.createElement("div", { className: 'ts-app-tools__nav-bar__item__title' }, screen.name));
        };
        this.renderNavbar = () => {
            const screens = (0, ts_common_1.sortArray)(TS_AppTools.screens, i => i.name);
            const groups = this.groupScreens(screens);
            const className = (0, tools_1._className)('ts-app-tools__nav-bar', this.state.navbarCollapse ? 'ts-app-tools__nav-bar-collapsed' : undefined);
            if (groups.length === 1) {
                return React.createElement(Layouts_1.LL_V_L, { className: className }, screens.map(this.renderNavbarItem));
            }
            return React.createElement(Layouts_1.LL_V_L, { className: className }, groups.map(group => {
                return React.createElement(TS_CollapsableContainer_1.TS_CollapsableContainer, { key: group.label, headerRenderer: group.label, containerRenderer: group.screens.map(this.renderNavbarItem), customCaret: React.createElement(ts_styles_1.TS_Icons.treeCollapse.component, null), flipHeaderOrder: true, onCollapseToggle: () => this.toggleGroupCollapse(group.label), collapsed: this.state.groups[group.label] });
            }));
        };
        const collapse = collapseStateStorage.get();
        this.state = {
            navbarCollapse: (_a = collapse === null || collapse === void 0 ? void 0 : collapse.navbarCollapse) !== null && _a !== void 0 ? _a : false,
            groups: (_b = collapse === null || collapse === void 0 ? void 0 : collapse.groups) !== null && _b !== void 0 ? _b : {},
        };
    }
    render() {
        return React.createElement(Layouts_1.LL_V_L, { className: 'ts-app-tools' },
            this.renderHeader(),
            React.createElement(Layouts_1.LL_H_C, { className: 'ts-app-tools__main' },
                this.renderNavbar(),
                React.createElement(TS_ErrorBoundary_1.TS_ErrorBoundary, null,
                    React.createElement("div", { className: "ts-app-tools__page" },
                        React.createElement(react_router_dom_1.Outlet, null)))));
    }
}
exports.TS_AppTools = TS_AppTools;
