import {ModuleFE_BaseApi, ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';
import {DB_Courier, DBDef_Courier} from '@app/shared';


export interface OnCouriersUpdated {
	__onCouriersUpdated: (...params: ApiCallerEventType<DB_Courier>) => void;
}

export const dispatch_onCourierListChanged = new ThunderDispatcher<OnCouriersUpdated, '__onCouriersUpdated'>('__onCouriersUpdated');

export class ModuleFE_Courier_Class
	extends ModuleFE_BaseApi<DB_Courier> {

	constructor() {
		super(DBDef_Courier,dispatch_onCourierListChanged);
	}
}

export const ModuleFE_Couriers = new ModuleFE_Courier_Class();
