"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Printable = void 0;
const React = require("react");
const tools_1 = require("../../utils/tools");
require("./TS_Printable.scss");
class TS_Printable extends React.Component {
    render() {
        const _a = this.props, { printable, printOnly } = _a, props = __rest(_a, ["printable", "printOnly"]);
        return (React.createElement("div", Object.assign({}, props, { className: (0, tools_1._className)('ts-printable', props.className, printOnly ? 'print-only' : undefined), ref: ((ref) => {
                if (this.ref)
                    return;
                this.ref = ref;
                printable(this.ref);
                this.forceUpdate();
            }) }), this.props.children));
    }
}
exports.TS_Printable = TS_Printable;
