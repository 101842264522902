"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CliParamsModule = void 0;
const module_1 = require("../core/module");
const exceptions_1 = require("../core/exceptions/exceptions");
const array_tools_1 = require("../utils/array-tools");
class CliParamsModule_Class extends module_1.Module {
    constructor() {
        super(...arguments);
        this.paramsValue = {};
        this.printHowTo = (params) => {
            const missingParams = params.filter((param) => !this.paramsValue[param.keyName] && !param.optional);
            const foundParams = params.filter((param) => this.paramsValue[param.keyName]);
            this.printFoundArgs('Found Args', foundParams, this.paramsValue);
            if (missingParams.length === 0)
                return;
            this.printFoundArgs('Missing Args', missingParams, this.paramsValue);
            throw new exceptions_1.ImplementationMissingException('Missing cli params');
        };
        this.getParams = () => {
            return this.paramsValue;
        };
    }
    init() {
        this.config.params.forEach((param) => this.paramsValue[param.keyName] = this.getParam(param));
        this.printHowTo(this.config.params);
        return this.paramsValue;
    }
    getParam(param, args = process.argv.slice(2, process.argv.length)) {
        if (!this.config.params.find(_param => _param.keyName === param.keyName))
            throw new exceptions_1.BadImplementationException('Requested not existing param');
        let value = this.extractParam(param, args);
        if (!value)
            value = param.defaultValue;
        if (!value)
            return value;
        return (param.process ? param.process(value) : value);
    }
    extractParam(param, argv) {
        if (param.isArray)
            return param.keys.reduce((values, key) => {
                values.push(...(0, array_tools_1.filterInstances)(argv.map(arg => { var _a; return (_a = arg.match(new RegExp(`${key}=(.*)`))) === null || _a === void 0 ? void 0 : _a[1]; })));
                return values;
            }, []);
        const find = param.keys.map(key => argv.map(arg => { var _a; return (_a = arg.match(new RegExp(`${key}=(.*)`))) === null || _a === void 0 ? void 0 : _a[1]; }));
        return (0, array_tools_1.flatArray)(find).find(k => k);
    }
    printFoundArgs(title, params, foundArgs) {
        if (params.length)
            return;
        this.logInfoBold(`  ${title}:`);
        params.forEach((param) => this.logInfo(`    ${param.keys[0]}=${foundArgs[param.keyName] || `<${param.name}>`}`));
    }
}
exports.CliParamsModule = new CliParamsModule_Class();
