"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoutePermissions = exports.PermissionsComponent = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ModuleFE_PermissionsAssert_1 = require("../modules/ModuleFE_PermissionsAssert");
class PermissionsComponent extends frontend_1.SmartComponent {
    constructor() {
        super(...arguments);
        this.renderLoader = () => {
            return React.createElement(React.Fragment, null);
        };
        this.renderWaitingOnPermissions = () => {
            const Loader = this.props.loadingComponent;
            return Loader ? React.createElement(Loader, null) : null;
        };
        this.renderPermitted = () => {
            return React.createElement(React.Fragment, null, this.props.children);
        };
        this.renderFallback = () => {
            const Fallback = this.props.fallback;
            if (Fallback)
                return React.createElement(Fallback, null);
            return null;
        };
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }
    async deriveStateFromProps(nextProps, state) {
        return state;
    }
    __onPermissionsChanged() {
        this.forceUpdate();
    }
    render() {
        const permitted = this.props.permissionKey.getAccessLevel();
        if (permitted === ModuleFE_PermissionsAssert_1.AccessLevel.Undefined)
            return this.renderWaitingOnPermissions();
        if (permitted === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess)
            return this.renderPermitted();
        return this.renderFallback();
    }
}
PermissionsComponent.defaultProps = {
    modules: [frontend_1.ModuleFE_AppConfig]
};
exports.PermissionsComponent = PermissionsComponent;
const RoutePermissions = (permissionKey) => () => permissionKey.getAccessLevel() === ModuleFE_PermissionsAssert_1.AccessLevel.HasAccess;
exports.RoutePermissions = RoutePermissions;
