"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Queue = void 0;
const Logger_1 = require("../core/logger/Logger");
const array_tools_1 = require("./array-tools");
class Queue extends Logger_1.Logger {
    constructor(name) {
        super(name);
        this.parallelCount = 1;
        this.running = 0;
        this.queue = [];
        this.ignore = () => {
        };
    }
    setParallelCount(parallelCount) {
        this.parallelCount = parallelCount;
        return this;
    }
    setOnQueueEmpty(onQueueEmpty) {
        this.onQueueEmpty = onQueueEmpty;
        return this;
    }
    addItem(toExecute, onCompleted, onError) {
        this.addItemImpl(toExecute.bind(this), onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted.bind(this), onError === null || onError === void 0 ? void 0 : onError.bind(this));
        this.execute();
    }
    addItemImpl(toExecute, onCompleted, onError) {
        (0, array_tools_1.addItemToArray)(this.queue, (resolve) => __awaiter(this, void 0, void 0, function* () {
            this.running++;
            try {
                const output = yield toExecute();
                onCompleted && onCompleted(output);
            }
            catch (e) {
                try {
                    onError && onError(e);
                }
                catch (e1) {
                    this.logError("Error while calling onError");
                    this.logError("--- Original: ", e);
                    this.logError("-- Secondary: ", e1);
                }
            }
            this.running--;
            resolve();
            this.execute();
        }));
    }
    execute() {
        var _a;
        if (this.queue.length === 0 && this.running === 0) {
            this.onQueueEmpty && this.onQueueEmpty();
            return (_a = this.finalResolve) === null || _a === void 0 ? void 0 : _a.call(this);
        }
        for (let i = 0; this.running < this.parallelCount && i < this.queue.length; i++) {
            const toExecute = this.queue[0];
            (0, array_tools_1.removeItemFromArray)(this.queue, toExecute);
            new Promise(toExecute.bind(this))
                .then(this.ignore)
                .catch(this.ignore);
        }
    }
    executeSync() {
        return __awaiter(this, void 0, void 0, function* () {
            yield new Promise(resolve => {
                this.finalResolve = resolve;
                this.execute();
            });
        });
    }
}
exports.Queue = Queue;
