"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.merge = exports.mergeArray = exports.mergeObject = void 0;
const object_tools_1 = require("./object-tools");
const tools_1 = require("./tools");
const exceptions_1 = require("../core/exceptions/exceptions");
function mergeObject(original, override) {
    if (original === override) {
        return override;
    }
    if (!(0, tools_1.exists)(original))
        return (0, object_tools_1.filterKeys)(override);
    const returnValue = (0, object_tools_1.deepClone)(original);
    return Object.keys(override).reduce((obj, key) => {
        obj[key] = merge(original[key], override[key]);
        if (obj[key] === undefined)
            delete obj[key];
        return obj;
    }, returnValue);
}
exports.mergeObject = mergeObject;
function mergeArray(original, override) {
    if (original === override) {
        return override;
    }
    // const returnValue = deepClone(original);
    // returnValue.reduce((array, value) => {
    // 	array.find((item)=>{
    // 		if()
    // 	});
    // }, returnValue as any[]);
    //
    // var result = original.filter((o1) => {
    // 	return override.some((o2) => {
    //
    // 		let originalKeys = Object.keys(o1);
    // 		originalKeys.some((key) =>)
    // 		return o1.id === o2.id; // return the ones with equal id
    // 	});
    // });
    return override;
}
exports.mergeArray = mergeArray;
function merge(original, override) {
    if (!(0, tools_1.exists)(override))
        return undefined;
    if (!(0, tools_1.exists)(original))
        return typeof override === 'object' ? (0, object_tools_1.filterKeys)(override) : override;
    if (typeof original !== typeof override || (typeof original === 'object' && typeof override === 'object' && Array.isArray(original) !== Array.isArray(override)))
        throw new exceptions_1.BadImplementationException(`trying to merge object of different types!! \\n Original: ${JSON.stringify(original)}\\n Override: ${JSON.stringify(override)}`);
    if (Array.isArray(original) && Array.isArray(override))
        return mergeArray(original, override);
    if (typeof original === 'object' && typeof override === 'object' && !Array.isArray(original) && !Array.isArray(override))
        return mergeObject(original || {}, override);
    return override;
}
exports.merge = merge;
