import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';
import {DB_Vendor, DBDef_Vendor} from '@app/shared';


export interface OnVendorsUpdated {
	__onVendorsUpdated: (...params: ApiCallerEventType<DB_Vendor>) => void;
}

export const dispatch_onVendorListChanged = new ThunderDispatcher<OnVendorsUpdated, '__onVendorsUpdated'>('__onVendorsUpdated');

export class ModuleFE_Vendor_Class
	extends ModuleFE_BaseApi<DB_Vendor, 'storeId' | 'shopifyId'> {

	constructor() {
		super(DBDef_Vendor, dispatch_onVendorListChanged);
	}
}

export const ModuleFE_Vendors = new ModuleFE_Vendor_Class();
export const ModuleFE_Vendors_ = ModuleFE_Vendors as unknown as ModuleFE_BaseApi<any, any>;

