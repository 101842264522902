"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_TranslationProject = exports.ModuleFE_TranslationProject_Class = exports.dispatch_onTranslationProjectChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const v3_types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/v3_types");
const project_1 = require("../../shared/project");
const ts_common_1 = require("@nu-art/ts-common");
exports.dispatch_onTranslationProjectChanged = new v3_types_1.ThunderDispatcherV3('__onTranslationProjectUpdated');
class ModuleFE_TranslationProject_Class extends frontend_1.ModuleFE_v3_BaseApi {
    constructor() {
        super(project_1.DBDef_TranslationProject, exports.dispatch_onTranslationProjectChanged);
        this.setMinLevel(ts_common_1.LogLevel.Verbose);
        this._v1 = {
            '?': (0, frontend_1.apiWithBody)(project_1.ApiDef_TranslationProject._v1['?']),
            '??': (0, frontend_1.apiWithQuery)(project_1.ApiDef_TranslationProject._v1['??']),
        };
    }
}
exports.ModuleFE_TranslationProject_Class = ModuleFE_TranslationProject_Class;
exports.ModuleFE_TranslationProject = new ModuleFE_TranslationProject_Class();
