"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_AutoComplete = exports.ModuleFE_AutoComplete_Class = exports.dispatch_onAutoCompletesUpdated = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const frontend_2 = require("@nu-art/thunderstorm/frontend");
const shared_1 = require("../../backend/shared");
exports.dispatch_onAutoCompletesUpdated = new frontend_2.ThunderDispatcher('__onAutoCompletesUpdated');
class ModuleFE_AutoComplete_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(shared_1.DBDef_AutoComplete, exports.dispatch_onAutoCompletesUpdated);
    }
}
exports.ModuleFE_AutoComplete_Class = ModuleFE_AutoComplete_Class;
exports.ModuleFE_AutoComplete = new ModuleFE_AutoComplete_Class();
