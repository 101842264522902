import * as React from 'react';
import {DB_CustomerOrder} from '@app/shared';
import {LOCALE} from '@res/locale';
import {capitalizeFirstLetter, formatTimestamp} from '@nu-art/ts-common';
import {genericNotificationAction, LL_H_C, LL_V_C, LL_V_L, stopPropagation} from '@nu-art/thunderstorm/frontend';
import {getOrderStatusClass, getOrderStatusLabel} from '@pah/customer-order/utils';
import {ICONSV4} from '@res/icons';
import {ModuleFE_CustomerOrder, ModuleFE_ShippingMethod} from '@modules/shopify';
import {TS_CopyToClipboard} from '@nu-art/thunderstorm/frontend/components/TS_CopyToClipboard';
import {EditableDBItem, Item_Editor} from '@nu-art/thunderstorm/frontend';
import {Dialog_CreateOrderDelivery} from '@pah/customer-order/dialogs/Dialog_CreateOrderDelivery';


export const Component_DeliveryStatus = (props: { order: Partial<DB_CustomerOrder> }) => {
	const deliveryDetails = props.order.deliveryDetails?.[props.order.deliveryDetails.length - 1];
	const isPickup = ModuleFE_ShippingMethod.cache.find(o => o._id === props.order.shippingOptionId)?.isPickup;

	if (isPickup)
		return <LL_V_C>----</LL_V_C>;

	if (!deliveryDetails)
		return <ICONSV4.add className="ts-print__gone" onClick={(e) => {
			stopPropagation(e);

			const editable = new EditableDBItem(props.order, ModuleFE_CustomerOrder).setAutoSave(true).editProp('deliveryDetails', []);
			Dialog_CreateOrderDelivery.show(props.order, editable);
		}}/>;

	return <LL_H_C>
		{deliveryDetails && <ICONSV4.sync className="ts-print__hide" onClick={(e) => {
			stopPropagation(e);
			return genericNotificationAction(() => ModuleFE_CustomerOrder.checkDeliveryStatus({orderId: props.order._id!})
				.executeSync(), `Syncing delivery ${LOCALE.OrderNo(props.order.orderNumber!)}`);
		}}/>}
		<TS_CopyToClipboard textToCopy={deliveryDetails?.number || ''}>
			{!deliveryDetails ? 'Not Ordered' : capitalizeFirstLetter(`${deliveryDetails.type} - ${deliveryDetails.status} - ${deliveryDetails.number}`)}
		</TS_CopyToClipboard>
	</LL_H_C>;
};

export class Component_OrderDetails
	extends Item_Editor<DB_CustomerOrder> {

	render() {
		const order = this.props.editable.item;

		const orderNumber = order.orderNumber!;
		return (
			<LL_H_C className="match_width print-header">
				<div className={`customer-order__section ${getOrderStatusClass(order)}`}>

					<LL_V_L className="match_parent ">
						<LL_H_C className="match_parent flex__space-between customer-order__title">
							<div>{LOCALE.OrderNo(orderNumber)}</div>
							<ICONSV4.sync className="ts-print__hide" onClick={(e) => {
								stopPropagation(e);
								return genericNotificationAction(() => ModuleFE_CustomerOrder._v1.shopify.sync({itemIds: [order._id!], fromShopify: true})
									.executeSync(), `Syncing ${LOCALE.OrderNo(orderNumber)}`);
							}}/>
							<div>{getOrderStatusLabel(order)}</div>
						</LL_H_C>
						<LL_H_C className="match_parent flex__space-between">
							<div>{formatTimestamp('ddd, DD MMM, YYYY [at] HH:mm', order.timestampCreated)}</div>
							{order.timestampCompleted && <div>{formatTimestamp('ddd, DD MMM, YYYY [at] HH:mm', order.timestampCompleted)}</div>}
						</LL_H_C>
					</LL_V_L>
				</div>
			</LL_H_C>
		);
	}
}

