"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_DropDown = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const tools_1 = require("../../utils/tools");
const Adapter_1 = require("../adapter/Adapter");
const TS_Overlay_1 = require("../TS_Overlay");
const TS_Tree_1 = require("../TS_Tree");
const ComponentSync_1 = require("../../core/ComponentSync");
const TS_Input_1 = require("../TS_Input");
require("./TS_DropDown.scss");
const Layouts_1 = require("../Layouts");
class TS_DropDown extends ComponentSync_1.ComponentSync {
    // ######################## Life Cycle ########################
    constructor(props) {
        super(props);
        this.closeList = (e, selectedItem, action) => {
            if (this.props.disabled)
                return;
            if (e)
                (0, tools_1.stopPropagation)(e);
            this.setState({
                open: false,
                filterText: undefined,
                focusedItem: undefined,
                selected: selectedItem === null ? undefined : selectedItem || this.state.selected
            }, action);
        };
        this.onSelected = (item, e) => {
            this.closeList(e, item, () => this.props.onSelected(item));
        };
        this.inputKeyEventHandler = (e) => {
            if (this.props.inputEventHandler)
                return this.setState(() => {
                    return this.props.inputEventHandler ? this.props.inputEventHandler(this.state, e) : this.state;
                });
            if (e.key === 'Enter') {
                e.persist();
                if (this.state.focusedItem) {
                    return this.onSelected(this.state.focusedItem);
                }
                const filterText = this.state.filterText;
                if (filterText) {
                    this.closeList(e, null, () => { var _b, _c; return (_c = (_b = this.props).onNoMatchingSelectionForString) === null || _c === void 0 ? void 0 : _c.call(_b, filterText, this.state.adapter.data, e); });
                }
                else
                    this.onSelected(this.state.adapter.data[0], e);
            }
            if (e.key === 'Escape')
                return this.closeList(e);
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                let index = this.state.focusedItem ? this.state.adapter.data.indexOf(this.state.focusedItem) + (e.key === 'ArrowDown' ? 1 : -1) : 0;
                index = (0, ts_common_1.clamp)(0, index, this.state.adapter.data.length - 1);
                this.setState({ focusedItem: this.state.adapter.data[index] });
            }
        };
        this.getChildrenContainerMaxHeight = (dropdownRef, dir) => {
            var _b;
            const rect = (_b = dropdownRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
            const boundingParent = this.getBoundingParent();
            if (boundingParent) {
                const boundingRect = boundingParent.getBoundingClientRect();
                return (dir === 'bottom' ? (boundingRect.bottom - rect.bottom - 20) : (rect.top - boundingRect.top - 20));
            }
            return (dir === 'bottom' ? (window.innerHeight - rect.bottom - 20) : (rect.top - 20));
        };
        this.getChildrenContainerPosX = (dropdownRef) => {
            const rect = dropdownRef.current.getBoundingClientRect();
            return rect.x;
        };
        this.getChildrenContainerPosY = (dropdownRef, dir) => {
            const rect = dropdownRef.current.getBoundingClientRect();
            if (dir === 'bottom') {
                return rect.bottom;
            }
            return window.innerHeight - rect.top;
        };
        this.getChildrenContainerWidth = (dropdownRef) => {
            const rect = dropdownRef.current.getBoundingClientRect();
            return rect.right - rect.left;
        };
        this.renderHeader = () => {
            var _b, _c;
            return (React.createElement("div", { className: "ts-dropdown__header", onClick: (e) => {
                    (0, tools_1.stopPropagation)(e);
                    if (this.props.disabled) {
                        return;
                    }
                    this.state.open ? this.closeList(e) : this.setState({ open: true });
                } },
                this.renderSelectedOrFilterInput(),
                this.state.open && this.props.caret ? (_b = this.props.caret) === null || _b === void 0 ? void 0 : _b.close : (_c = this.props.caret) === null || _c === void 0 ? void 0 : _c.open));
        };
        this.renderTree = () => {
            var _b, _c, _d;
            if (!this.state.open)
                return '';
            if (this.props.showNothingWithoutFilterText && !((_b = this.state.filterText) === null || _b === void 0 ? void 0 : _b.length))
                return '';
            let className = 'ts-dropdown__items-container';
            // const treeKeyEventHandler = treeKeyEventHandlerResolver(this.props.id);
            const style = {};
            if ((_c = this.state) === null || _c === void 0 ? void 0 : _c.dropDownRef.current) {
                //Get container data
                const containerData = {
                    posX: this.getChildrenContainerPosX(this.state.dropDownRef),
                    posY: this.getChildrenContainerPosY(this.state.dropDownRef, 'bottom'),
                    width: this.getChildrenContainerWidth(this.state.dropDownRef),
                    maxHeight: this.getChildrenContainerMaxHeight(this.state.dropDownRef, 'bottom'),
                };
                //Set initial top
                style.top = containerData.posY;
                //If not enough space at the bottom, re-calculate for top display
                if (containerData.maxHeight < 100) {
                    containerData.maxHeight = this.getChildrenContainerMaxHeight(this.state.dropDownRef, 'top');
                    containerData.posY = this.getChildrenContainerPosY(this.state.dropDownRef, 'top');
                    className += ' inverted';
                    delete style.top;
                    style.bottom = containerData.posY;
                }
                //Set style attributes
                style.position = 'absolute';
                style.left = containerData.posX;
                style.maxHeight = containerData.maxHeight;
                style.width = containerData.width;
            }
            if ((!this.props.filter || !this.props.showNothingWithoutFilterText || ((_d = this.state.filterText) === null || _d === void 0 ? void 0 : _d.length)) && this.state.adapter.data.length === 0) {
                if (this.props.noOptionsRenderer)
                    return React.createElement("div", { className: "ts-dropdown__empty", style: style }, (typeof this.props.noOptionsRenderer === 'function' ? this.props.noOptionsRenderer() : this.props.noOptionsRenderer));
                return React.createElement("div", { className: "ts-dropdown__empty", style: style }, "No options");
            }
            return React.createElement(Layouts_1.LL_V_L, { className: className, style: style, innerRef: this.state.treeContainerRef },
                this.props.canUnselect && React.createElement("div", { className: 'ts-dropdown__unselect-item', onClick: (e) => this.onSelected(undefined, e) }, "Unselect"),
                React.createElement(TS_Tree_1.TS_Tree, { adapter: this.state.adapter, selectedItem: this.state.focusedItem, onNodeClicked: (path, item) => this.onSelected(item), className: 'ts-dropdown__items', scrollSelectedIntoView: true, containerRef: this.state.treeContainerRef }));
        };
        this.renderSelectedItem = (selected) => {
            if (this.props.selectedItemRenderer)
                return this.props.selectedItemRenderer(selected);
            if (selected === undefined)
                return React.createElement("div", { className: "ts-dropdown__placeholder" }, this.props.placeholder || '');
            const adapter = typeof this.props.adapter === 'function' ? this.props.adapter(this.state.filterText) : this.props.adapter;
            const Renderer = adapter.treeNodeRenderer;
            const node = {
                propKey: 'string',
                path: 'string',
                item: 'any',
                adapter: adapter,
                expandToggler: (e, expxand) => {
                },
                onClick: (e) => {
                },
                expanded: true,
                focused: false,
                selected: true
            };
            return React.createElement("div", { className: 'ts-dropdown__selected', onContextMenu: this.props.onContextMenu },
                React.createElement(Renderer, { item: selected, node: node }));
        };
        this.renderSelectedOrFilterInput = () => {
            if (!this.state.open || !this.props.filter) {
                return this.renderSelectedItem(this.state.selected);
            }
            return this.props.renderSearch(this);
        };
        // ######################## To Remove ########################
        // TODO: THIS IS ALL DUPLICATE SHIT... DELETE ONCE TREE CAN PROPAGATE THE KEYBOARD EVENTS
        this.addKeyboardListener = () => {
            var _b;
            const onKeyboardEventListener = this.inputKeyEventHandler;
            if (!onKeyboardEventListener)
                return;
            (_b = this.node) === null || _b === void 0 ? void 0 : _b.addEventListener('keydown', this.keyboardEventHandler);
        };
        this.removeKeyboardListener = () => {
            var _b;
            const onKeyboardEventListener = this.inputKeyEventHandler;
            if (!onKeyboardEventListener)
                return;
            (_b = this.node) === null || _b === void 0 ? void 0 : _b.removeEventListener('keydown', this.keyboardEventHandler);
        };
        this.keyboardEventHandler = (e) => this.node && this.inputKeyEventHandler(e);
    }
    deriveStateFromProps(nextProps, state) {
        var _b, _c, _d, _e, _f;
        const nextState = this.state ? Object.assign({}, this.state) : {};
        const nextAdapter = typeof nextProps.adapter === 'function' ? nextProps.adapter(state === null || state === void 0 ? void 0 : state.filterText) : nextProps.adapter;
        const prevAdapter = typeof this.props.adapter === 'function' ? this.props.adapter(state === null || state === void 0 ? void 0 : state.filterText) : this.props.adapter;
        nextState.selected = nextProps.selected;
        (_b = nextState.filterText) !== null && _b !== void 0 ? _b : (nextState.filterText = nextProps.inputValue);
        nextState.dropDownRef = (_e = (_c = nextProps.innerRef) !== null && _c !== void 0 ? _c : (_d = this.state) === null || _d === void 0 ? void 0 : _d.dropDownRef) !== null && _e !== void 0 ? _e : React.createRef();
        nextState.treeContainerRef = (_f = state === null || state === void 0 ? void 0 : state.treeContainerRef) !== null && _f !== void 0 ? _f : React.createRef();
        nextState.className = nextProps.className;
        if (!nextState.adapter || (nextAdapter.data !== prevAdapter.data) || ((state === null || state === void 0 ? void 0 : state.filterText) !== nextState.filterText)) {
            nextState.adapter = this.createAdapter(nextAdapter, nextProps.limitItems, state === null || state === void 0 ? void 0 : state.filterText);
            nextState.focusedItem = undefined;
        }
        return {
            open: state === null || state === void 0 ? void 0 : state.open,
            adapter: nextState.adapter,
            selected: nextState.selected,
            hover: state === null || state === void 0 ? void 0 : state.hover,
            filterText: state === null || state === void 0 ? void 0 : state.filterText,
            dropDownRef: nextState.dropDownRef,
            focusedItem: nextState.focusedItem,
            treeContainerRef: nextState.treeContainerRef,
            className: nextState.className
        };
    }
    // ######################## Logic ########################
    getBoundingParent() {
        var _b;
        if (!this.props.boundingParentSelector)
            return undefined;
        return (_b = this.state.dropDownRef.current) === null || _b === void 0 ? void 0 : _b.closest(this.props.boundingParentSelector);
    }
    createAdapter(adapterToClone, limit, filterText) {
        //If no change to data
        if (!(filterText && this.props.filter) && !limit)
            return adapterToClone;
        let data = adapterToClone.data;
        //If filtering
        if (filterText && this.props.filter)
            data = this.props.filter.filterSort(data, filterText);
        //If limiting
        if (limit)
            data = limit ? data.slice(0, limit) : data;
        const adapter = adapterToClone.clone(new Adapter_1.Adapter(data));
        adapter.data = data;
        return adapter;
    }
    // ######################## Render ########################
    render() {
        const className = (0, tools_1._className)('ts-dropdown', this.state.className, this.state.open ? 'open' : undefined, this.props.disabled ? 'disabled' : undefined);
        return (React.createElement("div", { className: className, ref: this.state.dropDownRef, tabIndex: this.props.tabIndex, onFocus: this.addKeyboardListener, onBlur: this.removeKeyboardListener },
            this.renderHeader(),
            React.createElement(TS_Overlay_1.TS_Overlay, { flat: false, showOverlay: !!this.state.open, onClickOverlay: this.closeList }, this.renderTree())));
    }
}
_a = TS_DropDown;
// ######################## Static ########################
TS_DropDown.prepareEditable = (mandatoryProps) => {
    return (props) => React.createElement(TS_DropDown, Object.assign({}, (0, ts_common_1.resolveContent)(mandatoryProps), props, { onSelected: item => props.editable.update(props.prop, item), selected: props.editable.item[props.prop] }));
};
TS_DropDown.prepareSelectable = (mandatoryProps) => {
    return (props) => React.createElement(TS_DropDown, Object.assign({}, (0, ts_common_1.resolveContent)(mandatoryProps), props));
};
TS_DropDown.prepare = (mandatoryProps) => {
    return {
        editable: _a.prepareEditable(mandatoryProps),
        selectable: _a.prepareSelectable(mandatoryProps)
    };
};
TS_DropDown.defaultRenderSearch = (dropDown) => React.createElement(TS_Input_1.TS_Input, { type: "text", value: dropDown.props.inputValue, onChange: (filterText) => dropDown.reDeriveState({ filterText }), focus: true, style: { width: '100%' }, placeholder: dropDown.props.placeholder || '', 
    // onAccept={(value, ev) => {
    // 	const filterText = dropDown.state.filterText;
    // 	if (filterText) {
    // 		dropDown.setState({open: false}, () => dropDown.props.onNoMatchingSelectionForString?.(filterText, dropDown.state.adapter.data, ev));
    // 	} else
    // 		dropDown.onSelected(dropDown.state.adapter.data[0]);
    // }}
    onCancel: () => dropDown.reDeriveState({ open: false, filterText: undefined }), onKeyDown: dropDown.inputKeyEventHandler });
TS_DropDown.defaultProps = {
    renderSearch: TS_DropDown.defaultRenderSearch,
};
exports.TS_DropDown = TS_DropDown;
