"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.decode = exports.encode = exports.sha512 = exports.sha384 = exports.sha256 = exports.sha1 = exports.md5 = void 0;
const node_forge_1 = require("node-forge");
function md5(toBeConverted) {
    return node_forge_1.md.md5.create().update(toBeConverted).digest().toHex().toLowerCase();
}
exports.md5 = md5;
function sha1(toBeConverted) {
    return node_forge_1.md.sha1.create().update(toBeConverted).digest().toHex().toLowerCase();
}
exports.sha1 = sha1;
function sha256(toBeConverted) {
    return node_forge_1.md.sha256.create().update(toBeConverted).digest().toHex().toLowerCase();
}
exports.sha256 = sha256;
function sha384(toBeConverted) {
    return node_forge_1.md.sha384.create().update(toBeConverted).digest().toHex().toLowerCase();
}
exports.sha384 = sha384;
function sha512(toBeConverted) {
    return node_forge_1.md.sha512.create().update(toBeConverted).digest().toHex().toLowerCase();
}
exports.sha512 = sha512;
function encode(data, encoding = "base64") {
    let buffer;
    if (Buffer.isBuffer(data))
        buffer = data;
    else if (typeof data === 'string')
        buffer = Buffer.from(data, 'utf8');
    else
        buffer = Buffer.from(data.toString(), 'utf8');
    return buffer.toString(encoding);
}
exports.encode = encode;
function decode(encoded, from = "base64", to = "utf8") {
    return Buffer.from(encoded, from).toString(to);
}
exports.decode = decode;
