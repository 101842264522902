"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseHttpRequest = void 0;
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("./types");
const request_types_1 = require("./request-types");
const no_auth_listener_1 = require("./no-auth-listener");
class BaseHttpRequest {
    constructor(requestKey, requestData) {
        this.headers = {};
        this.method = types_1.HttpMethod.GET;
        this.timeout = 10000;
        this.params = {};
        this.aborted = false;
        this.clearOnCompleted = () => {
            delete this.onCompleted;
        };
        this.setOnCompleted = (onCompleted) => {
            if (!onCompleted)
                return this;
            if (this.onCompleted && onCompleted) {
                const _onCompleted = this.onCompleted;
                this.onCompleted = async (response, input, request) => {
                    await _onCompleted(response, input, request);
                    await onCompleted(response, input, request);
                };
            }
            else
                this.onCompleted = async (response, input, request) => {
                    await (onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted(response, input, request));
                };
            return this;
        };
        this.key = requestKey;
        this.requestData = requestData;
        this.label = `http request: ${requestKey}${requestData ? ` ${requestData}` : ''}`;
        this.compress = false;
        // @ts-ignore
        if (ts_common_1.ModuleManager.instance.config.isDebug)
            this.timeout = 5 * ts_common_1.Minute;
    }
    setLogger(logger) {
        this.logger = logger;
        return this;
    }
    getRequestData() {
        return this.requestData;
    }
    setOrigin(origin) {
        this.origin = origin;
        return this;
    }
    setOnProgressListener(onProgressListener) {
        this.onProgressListener = onProgressListener;
        return this;
    }
    setLabel(label) {
        this.label = label;
        return this;
    }
    setMethod(method) {
        this.method = method;
        return this;
    }
    setResponseType(responseType) {
        this.responseType = responseType;
        return this;
    }
    setUrlParams(params) {
        if (!params)
            return this;
        (0, ts_common_1._keys)(params).forEach((key) => {
            const param = params[key];
            return param && typeof param === 'string' && this.setUrlParam(key, param);
        });
        return this;
    }
    setUrlParam(key, value) {
        delete this.params[key];
        this.params[key] = value;
        return this;
    }
    setUrl(url) {
        this.url = url;
        return this;
    }
    getUrl() {
        return this.url;
    }
    setRelativeUrl(relativeUrl) {
        if (!this.origin)
            throw new ts_common_1.BadImplementationException('if you want to use relative urls, you need to set an origin');
        if (relativeUrl.startsWith('/'))
            relativeUrl = relativeUrl.substring(1);
        this.url = `${this.origin}/${relativeUrl}`;
        return this;
    }
    setTimeout(timeout) {
        this.timeout = timeout;
        return this;
    }
    setHeaders(headers) {
        if (!headers)
            return this;
        Object.keys(headers).forEach((key) => this.setHeader(key, headers[key]));
        return this;
    }
    addHeaders(headers) {
        if (!headers)
            return this;
        Object.keys(headers).forEach((key) => this.addHeader(key, headers[key]));
        return this;
    }
    setHeader(_key, value) {
        const key = _key.toLowerCase();
        delete this.headers[key];
        return this.addHeader(key, value);
    }
    addHeader(_key, value) {
        const key = _key.toLowerCase();
        return this._addHeaderImpl(key, value);
    }
    removeHeader(key) {
        delete this.headers[key];
        return this;
    }
    _addHeaderImpl(key, value) {
        const values = (0, ts_common_1.asArray)(value);
        if (!this.headers[key])
            this.headers[key] = values;
        else
            this.headers[key].push(...values);
        return this;
    }
    prepareJsonBody(bodyObject) {
        return bodyObject;
    }
    setBodyAsJson(bodyObject, compress) {
        this.setHeader('content-type', 'application/json');
        this.setBody(this.prepareJsonBody(bodyObject), compress);
        return this;
    }
    setBody(bodyAsString, _compress) {
        this.body = bodyAsString;
        this.compress = _compress === undefined ? this.compress : _compress;
        if (typeof bodyAsString === 'string' && this.compress)
            this.setHeader('Content-encoding', 'gzip');
        return this;
    }
    isValidStatus(statusCode) {
        return statusCode >= 200 && statusCode < 300;
    }
    print() {
        var _a, _b, _c;
        (_a = this.logger) === null || _a === void 0 ? void 0 : _a.logDebug(`Url: ${this.url}`);
        (_b = this.logger) === null || _b === void 0 ? void 0 : _b.logDebug(`Params:`, this.params);
        (_c = this.logger) === null || _c === void 0 ? void 0 : _c.logDebug(`Headers:`, this.headers);
    }
    async executeSync(print = false) {
        var _a, _b, _c, _d;
        if (print)
            this.print();
        await this.executeImpl();
        const status = this.getStatus();
        const requestData = this.body || this.params;
        if (this.aborted) {
            const httpException = new request_types_1.HttpException(status, this.url); // should be status 0
            await ((_a = this.onError) === null || _a === void 0 ? void 0 : _a.call(this, httpException, requestData, this));
            throw httpException;
        }
        if (!this.isValidStatus(status)) {
            const errorResponse = this.getErrorResponse();
            const httpException = new request_types_1.HttpException(status, this.url, errorResponse);
            if (status === 401)
                await no_auth_listener_1.dispatcher_onAuthRequired.dispatchModuleAsync(this);
            await ((_b = this.onError) === null || _b === void 0 ? void 0 : _b.call(this, httpException, requestData, this));
            throw httpException;
        }
        let response = this.getResponse();
        if (!(0, ts_common_1.exists)(response)) {
            await ((_c = this.onCompleted) === null || _c === void 0 ? void 0 : _c.call(this, response, requestData, this));
            return response;
        }
        try {
            response = JSON.parse(response);
        }
        catch (ignore) {
            //
        }
        await ((_d = this.onCompleted) === null || _d === void 0 ? void 0 : _d.call(this, response, requestData, this));
        return response;
    }
    execute(onSuccess = () => { var _a; return (_a = this.logger) === null || _a === void 0 ? void 0 : _a.logVerbose(`Completed: ${this.label}`); }, onError = reason => { var _a; return (_a = this.logger) === null || _a === void 0 ? void 0 : _a.logWarning(`Error: ${this.label}`, reason); }) {
        this.executeSync()
            .then(onSuccess)
            .catch(onError);
        return this;
    }
    setOnError(onError) {
        this.onError = onError;
        return this;
    }
    abort() {
        this.aborted = true;
        this.abortImpl();
    }
}
exports.BaseHttpRequest = BaseHttpRequest;
