import {DB_Object, Default_UniqueKey, PreDB} from '@nu-art/ts-common';


export function resolveKeys<ItemType extends DB_Object, Ks extends keyof PreDB<ItemType> = Default_UniqueKey>(keys: Ks[], resolver: (key: Ks) => string | undefined) {
	return keys.reduce((toRet, key) => {
		const value = resolver(key);
		if (value)
			toRet[key] = value as unknown as ItemType[Ks];
		return toRet;
	}, {} as { [K in Ks]: ItemType[K] });
}
