"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_TranslationProvider = exports.ModuleFE_TranslationProvider_Class = exports.dispatch_onTranslationProviderChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const v3_types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/v3_types");
const shared_1 = require("../shared");
exports.dispatch_onTranslationProviderChanged = new v3_types_1.ThunderDispatcherV3('__onTranslationProviderUpdated');
class ModuleFE_TranslationProvider_Class extends frontend_1.ModuleFE_v3_BaseApi {
    constructor() {
        super(shared_1.DBDef_TranslationProvider, exports.dispatch_onTranslationProviderChanged);
        this._v1 = {
            '?': (0, frontend_1.apiWithBody)(shared_1.ApiDef_TranslationProvider._v1['?']),
            '??': (0, frontend_1.apiWithQuery)(shared_1.ApiDef_TranslationProvider._v1['??']),
        };
    }
}
exports.ModuleFE_TranslationProvider_Class = ModuleFE_TranslationProvider_Class;
exports.ModuleFE_TranslationProvider = new ModuleFE_TranslationProvider_Class();
