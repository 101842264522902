"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventType_DeleteMulti = exports.EventType_Delete = exports.EventType_Query = exports.EventType_Unique = exports.EventType_Patch = exports.EventType_Update = exports.EventType_Create = exports.EventType_UpsertAll = exports.syncDispatcher = exports.DataStatus = exports.SyncStatus = void 0;
const thunder_dispatcher_1 = require("../thunder-dispatcher");
var SyncStatus;
(function (SyncStatus) {
    SyncStatus[SyncStatus["loading"] = 0] = "loading";
    SyncStatus[SyncStatus["idle"] = 1] = "idle";
    SyncStatus[SyncStatus["read"] = 2] = "read";
    SyncStatus[SyncStatus["write"] = 3] = "write";
})(SyncStatus = exports.SyncStatus || (exports.SyncStatus = {}));
var DataStatus;
(function (DataStatus) {
    DataStatus[DataStatus["NoData"] = 0] = "NoData";
    DataStatus[DataStatus["UpdatingData"] = 1] = "UpdatingData";
    DataStatus[DataStatus["ContainsData"] = 2] = "ContainsData";
})(DataStatus = exports.DataStatus || (exports.DataStatus = {}));
exports.syncDispatcher = new thunder_dispatcher_1.ThunderDispatcher('__onSyncStatusChanged');
exports.EventType_UpsertAll = 'upsert-all';
exports.EventType_Create = 'create';
exports.EventType_Update = 'update';
exports.EventType_Patch = 'patch';
exports.EventType_Unique = 'unique';
exports.EventType_Query = 'query';
exports.EventType_Delete = 'delete';
exports.EventType_DeleteMulti = 'delete-multi';
