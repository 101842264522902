"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Loader = void 0;
const React = require("react");
require("./TS_Loader.scss");
class TS_Loader extends React.Component {
    render() {
        return React.createElement("div", { className: "ts-loader" },
            React.createElement("div", { className: "ts-loader__content" }));
    }
}
exports.TS_Loader = TS_Loader;
