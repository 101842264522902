"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inferFullAddress = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const floorOptions = ['קומה', 'floor', 'f[\.:]'];
const entranceOptions = ['כניסה', 'entrance'];
const apartmentOption = ['דירה', 'apt[\.:]?', 'apartment', 'apartment number', 'aprtment', 'door'];
const houseNumberOption = ['בניין', 'building'];
const floorRegexpString = [`(?:${floorOptions.join('|')}):? (\\d+)`, `(\\d+) (?:${floorOptions.join('|')})`];
const apartmentRegexpString = [`(?:${apartmentOption.join('|')}):? (\\d+)`, `(\\d+) (?:${apartmentOption.join('|')})`, `^(\\d+)$`];
const entranceRegexpString = `(?:${entranceOptions.join('|')}):? ([\u0590-\u05ff\u0600-\u06ffa-zA-Z\\d])`;
const entranceRegexpString2 = `(?:${houseNumberOption.join('|')}):? ([\u0590-\u05ff\u0600-\u06ffa-zA-Z])`;
const houseNumberRegexpString = `(?:${houseNumberOption.join('|')}):?(\\s\\d+)?((?:[\u0590-\u05ff\u0600-\u06ffa-zA-Z]|\\s[\u0590-\u05ff\u0600-\u06ffa-zA-Z]\\s))`;
const streetNameAndHouseNumber1 = /^(\d+) [\u0590-\u05ff\u0600-\u06ffa-zA-Z -\.'"]{2,}/;
const streetNameAndHouseNumber2 = /^[\u0590-\u05ff\u0600-\u06ffa-zA-Z -\.'"]{2,} (\d+)([\u0590-\u05ff\u0600-\u06ffa-zA-Z])?/;
const floorRegexp = floorRegexpString.map(r => new RegExp(r));
const aptRegexp = apartmentRegexpString.map(r => new RegExp(r));
const entranceRegexp = new RegExp(entranceRegexpString);
const entranceRegexp2 = new RegExp(entranceRegexpString2);
const houseNumberRegexp = new RegExp(houseNumberRegexpString);
const streetNameAndHouseNumberRegexp1 = new RegExp(streetNameAndHouseNumber1);
const streetNameAndHouseNumberRegexp2 = new RegExp(streetNameAndHouseNumber2);
function fixInput(input) {
    var _a;
    let flip = false;
    input.address1 = input.address1.trim();
    if (input.address1.match(/^\d+$/))
        flip = true;
    if (input.address1 === '')
        flip = true;
    if (flip) {
        const t = input.address1;
        input.address1 = input.address2 || '';
        input.address2 = t;
    }
    if (!input.address1.match(/\d+/) && ((_a = input.address2) === null || _a === void 0 ? void 0 : _a.match(/^\d+$/))) {
        input.address1 += ` ${input.address2}`;
        input.address2 = '';
    }
}
function extractRegexp(input, regexps) {
    for (const regexp of regexps) {
        const matches = input.address1.match(regexp);
        if (!matches)
            continue;
        input.address1 = input.address1.replace(matches[0], '').trim();
        return [matches[1], matches[2]];
    }
    if (!input.address2)
        return;
    for (const regexp of regexps) {
        const matches = input.address2.match(regexp);
        if (!matches)
            continue;
        input.address2 = input.address2.replace(matches[0], '').trim();
        return [matches[1], matches[2]];
    }
}
function inferFullAddress(input) {
    var _a, _b, _c, _d, _e;
    input = (0, ts_common_1.deepClone)(input);
    if (input.address2 === input.address1)
        delete input.address2;
    function cleanup(value) {
        if (!value)
            return '';
        return value.toLowerCase().trim()
            .replace(/מיקוד\s?\d+/, ' ')
            .replace(/[\u202C\u202D\u202E]/g, ' ')
            .replace(/“|”|‘|’|—|–|…|\p{C}/gu, ' ')
            .replace(/ /g, ' ')
            .replace(/(\d)(st|nd|rd|th)/g, '$1')
            .replace('street', ' ')
            .replace('st.', ' ')
            .replace(input.city, ' ')
            .replace('רחוב ', ' ')
            .replace('רחֿ\'', ' ')
            .replace(',', ' ')
            .replace(/\s+/g, ' ').trim();
    }
    input.address1 = cleanup(input.address1);
    input.address2 = cleanup(input.address2 || '');
    fixInput(input);
    let houseNumber;
    let entrance;
    let apartmentNumber;
    let floorNumber;
    const floorNumberMatches = extractRegexp(input, floorRegexp);
    if (floorNumberMatches) {
        floorNumber = +(floorNumberMatches[0].trim());
    }
    const houseNumberAndBuildingMatches = extractRegexp(input, [houseNumberRegexp]);
    if (houseNumberAndBuildingMatches) {
        if (houseNumberAndBuildingMatches[0])
            houseNumber = +(houseNumberAndBuildingMatches[0].trim());
        entrance = houseNumberAndBuildingMatches[1].trim();
    }
    const apartmentNumberMatches = extractRegexp(input, aptRegexp);
    if (apartmentNumberMatches) {
        apartmentNumber = +(apartmentNumberMatches[0].trim());
    }
    fixInput(input);
    if (!(0, ts_common_1.exists)(houseNumber)) {
        const matches = input.address1.match(streetNameAndHouseNumberRegexp1) || input.address1.match(streetNameAndHouseNumberRegexp2);
        if (matches) {
            houseNumber = +(matches[1].trim());
            if (matches[2])
                entrance = matches[2].trim();
        }
    }
    const address = `${input.address1} ${input.address2}`.toLowerCase();
    const unionMatchHouseAndApartment = address.match(new RegExp('[\u0590-\u05ff\u0600-\u06ffa-z-\.]\\s(\\d+)/(\\d+)'));
    if (unionMatchHouseAndApartment) {
        houseNumber = +unionMatchHouseAndApartment[1];
        apartmentNumber = +unionMatchHouseAndApartment[2];
    }
    else {
        const unionMatchFloorAndApartment = address.match(new RegExp('(\\d+)/(\\d+)'));
        if (unionMatchFloorAndApartment) {
            const val1 = +unionMatchFloorAndApartment[1];
            const val2 = +unionMatchFloorAndApartment[2];
            floorNumber = Math.min(val1, val2);
            apartmentNumber = Math.max(val1, val2);
        }
    }
    const match = address.match(houseNumberRegexp);
    if (match) {
        if (!(0, ts_common_1.exists)(houseNumber))
            houseNumber = +(match === null || match === void 0 ? void 0 : match[1]);
        entrance = match === null || match === void 0 ? void 0 : match[2].trim();
    }
    if (!(0, ts_common_1.exists)(floorNumber)) {
        const _floorNumber = floorRegexp.reduce((floor, regexp) => { var _a; return floor ? floor : (_a = address.match(regexp)) === null || _a === void 0 ? void 0 : _a[1]; }, undefined);
        if (_floorNumber)
            floorNumber = +_floorNumber;
    }
    if (!(0, ts_common_1.exists)(apartmentNumber)) {
        const _apartmentNumber = aptRegexp.reduce((number, regexp) => { var _a; return number ? number : (_a = address.match(regexp)) === null || _a === void 0 ? void 0 : _a[1]; }, undefined);
        if (_apartmentNumber)
            apartmentNumber = +_apartmentNumber;
    }
    if (!(0, ts_common_1.exists)(apartmentNumber) && (0, ts_common_1.exists)(houseNumber)) {
        const _apartmentNumber = (_b = (_a = input.address2) === null || _a === void 0 ? void 0 : _a.match(new RegExp('^(\\d+)$'))) === null || _b === void 0 ? void 0 : _b[1];
        if (_apartmentNumber)
            apartmentNumber = +_apartmentNumber;
    }
    if (!(0, ts_common_1.exists)(entrance))
        entrance = (_c = address.match(entranceRegexp)) === null || _c === void 0 ? void 0 : _c[1];
    if (!(0, ts_common_1.exists)(entrance))
        entrance = (_d = address.match(entranceRegexp2)) === null || _d === void 0 ? void 0 : _d[1];
    let addressString = input.address1;
    if (!input.address1.match(/[0-9]{1,3}/)) {
        const match = input.address2.match(/([0-9]{1,3})/);
        addressString += `${match ? ` ${match[1]}` || '' : ''}`;
    }
    const apartment = {};
    if ((0, ts_common_1.exists)(floorNumber))
        apartment.floor = floorNumber;
    if ((0, ts_common_1.exists)(apartmentNumber))
        apartment.number = apartmentNumber;
    if ((0, ts_common_1.exists)(entrance))
        apartment.entrance = entrance;
    return {
        address: `${addressString ? `${addressString}, ` : ''}${input.city ? `${input.city}, ` : ''}${((_e = input.country) === null || _e === void 0 ? void 0 : _e.toLowerCase()) === 'israel' ? 'ישראל' : ''}`,
        houseNumber: houseNumber || 0,
        apartment
    };
}
exports.inferFullAddress = inferFullAddress;
