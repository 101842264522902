"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Created by TacB0sS on 3/16/17.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCustomException = exports.ApiException = exports.AssertionException = exports.WhoCallthisException = exports.DontCallthisException = exports.HasDependenciesException = exports.ThisShouldNotHappenException = exports.NotImplementedYetException = exports.MUSTNeverHappenException = exports.ImplementationMissingException = exports.BadImplementationException = exports.Exception = exports.CustomException = exports.isErrorOfType = void 0;
const utils_1 = require("../logger/utils");
/**
 * # <ins>isErrorOfType</ins>
 *
 * A function that checks if an error is of a certain type.
 *
 * @param e The error
 * @param _exceptionType The exception class to compare to
 *
 * @returns
 * - T - The error as the type checked if the error was of that type.
 * - undefined - otherwise.
 *
 * #### <ins>Usage:</ins>
 * ```js
 * try {
 *   ...
 * } catch(e: Error) {
 *   if(isErrorOfType(e,ThisShouldNotHappenException)) {
 *     e = new ThisShouldNotHappenException("this should not have happened",e);
 *     ...
 *   }
 * }
 * ```
 */
function isErrorOfType(e, _exceptionType) {
    var _a;
    const _e = e;
    if ((_a = _e.isInstanceOf) === null || _a === void 0 ? void 0 : _a.call(_e, _exceptionType))
        return e;
}
exports.isErrorOfType = isErrorOfType;
/**
 * # CustomException
 *
 * ### <ins>Intro</ins>
 * An abstract class defining the structure of custom exceptions.<br>
 * This class extends the java-script native Error object.<br>
 * In addition to collecting the error, this class also collects a message and the exception type, for better
 * error handling.<br>
 *
 * @category Exceptions
 */
class CustomException extends Error {
    constructor(exceptionType, message, cause) {
        super(message);
        this.message = message;
        this.stack = (new Error(message)).stack;
        this.cause = cause;
        this.exceptionType = exceptionType.name;
        this.isInstanceOf = (_exceptionType) => {
            return this.exceptionType === _exceptionType.name;
        };
        this.generateMrkDwnMessage = () => {
            return `*Exception Type :* ${this.exceptionType}\n`
                + `*Message :* ${this.message}`;
        };
    }
}
exports.CustomException = CustomException;
/**
 * # <ins>Exception</ins>
 * This class inherits {@link CustomException} and functions like it, after setting the exceptionType property as "Exception",
 * @category Exceptions
 */
class Exception extends CustomException {
    constructor(message, cause) {
        super(Exception, message, cause);
    }
}
exports.Exception = Exception;
/**
 * # <ins>BadImplementationException</ins>
 * This class inherits {@link CustomException} and functions like it, after setting the exceptionType property as "BadImplementationException",
 * @category Exceptions
 */
class BadImplementationException extends CustomException {
    constructor(message, cause) {
        super(BadImplementationException, message, cause);
    }
}
exports.BadImplementationException = BadImplementationException;
/**
 * # <ins>ImplementationMissingException</ins>
 * This class inherits {@link CustomException} and functions like it, after setting the exceptionType property as "ImplementationMissingException",
 * @category Exceptions
 */
class ImplementationMissingException extends CustomException {
    constructor(message, cause) {
        super(ImplementationMissingException, message, cause);
    }
}
exports.ImplementationMissingException = ImplementationMissingException;
/**
 * # <ins>MUSTNeverHappenException</ins>
 * This class inherits {@link CustomException} and functions like it, after setting the exceptionType property as "MUSTNeverHappenException",
 * @category Exceptions
 */
class MUSTNeverHappenException extends CustomException {
    constructor(message, cause) {
        super(MUSTNeverHappenException, message, cause);
    }
}
exports.MUSTNeverHappenException = MUSTNeverHappenException;
/**
 * # <ins>NotImplementedYetException</ins>
 * This class inherits {@link CustomException} and functions like it, after setting the exceptionType property as "NotImplementedYetException",
 * @category Exceptions
 */
class NotImplementedYetException extends CustomException {
    constructor(message, cause) {
        super(NotImplementedYetException, message, cause);
    }
}
exports.NotImplementedYetException = NotImplementedYetException;
/**
 * # <ins>ThisShouldNotHappenException</ins>
 * This class inherits {@link CustomException} and functions like it, after setting the exceptionType property as "ThisShouldNotHappenException",
 * @category Exceptions
 */
class ThisShouldNotHappenException extends CustomException {
    constructor(message, cause) {
        super(ThisShouldNotHappenException, message, cause);
    }
}
exports.ThisShouldNotHappenException = ThisShouldNotHappenException;
/**
 * #<ins>EntityHasDependencies</ins>
 * This class inherits {@link CustomException} and represents an error of a entity trying to be deleted that has dependencies
 * @category Exceptions
 */
class HasDependenciesException extends CustomException {
    constructor(debugMessage, body, entityName, e) {
        super(HasDependenciesException, debugMessage, e);
        this.body = body;
        this.entityName = entityName;
    }
}
exports.HasDependenciesException = HasDependenciesException;
/**
 * # <ins>DontCallthisException</ins>
 * This class inherits {@link CustomException} and functions like it, after setting the exceptionType property as "DontCallthisException",
 * @category Exceptions
 */
class DontCallthisException extends CustomException {
    constructor(message, cause) {
        super(DontCallthisException, message, cause);
    }
}
exports.DontCallthisException = DontCallthisException;
/**
 * # <ins>WhoCallthisException</ins>
 * This class inherits {@link CustomException} and functions like it, after setting the exceptionType property as "WhoCallthisException",
 * @category Exceptions
 */
class WhoCallthisException extends CustomException {
    constructor(message, cause) {
        super(WhoCallthisException, message, cause);
    }
}
exports.WhoCallthisException = WhoCallthisException;
/**
 * # <ins>AssertionException</ins>
 * This class inherits {@link CustomException} and functions like it, after setting the exceptionType property as "AssertionException",
 * @category Exceptions
 */
class AssertionException extends CustomException {
    constructor(message, cause) {
        super(AssertionException, message, cause);
    }
}
exports.AssertionException = AssertionException;
class ApiException extends CustomException {
    constructor(responseCode, causeOrMessage, cause) {
        super(ApiException, `${responseCode}${ApiException.getMessage(causeOrMessage)}`, ApiException.getCause(causeOrMessage, cause));
        this.responseBody = {};
        this.setErrorBody = (errorBody) => {
            this.responseBody.error = errorBody;
            return this;
        };
        this.responseCode = responseCode;
        this.responseBody.debugMessage = (0, utils_1._logger_logException)(this);
    }
    static getMessage(causeOrMessage) {
        return typeof causeOrMessage === 'string' ? `-${JSON.stringify(causeOrMessage)}` : '';
    }
    static getCause(causeOrMessage, cause) {
        return typeof causeOrMessage != 'string' ? causeOrMessage : cause;
    }
}
exports.ApiException = ApiException;
const allExceptions = [
    Exception,
    BadImplementationException,
    ImplementationMissingException,
    MUSTNeverHappenException,
    NotImplementedYetException,
    ThisShouldNotHappenException,
    DontCallthisException,
    WhoCallthisException,
    AssertionException,
];
function isCustomException(e) {
    return allExceptions.some(exc => !!isErrorOfType(e, exc));
}
exports.isCustomException = isCustomException;
