import {ComponentAsync, LL_H_C} from '@nu-art/thunderstorm/frontend';
import * as React from 'react';
import {DB_ProductTemplate} from '@app/shared';
import {EditableDBItem} from '@nu-art/thunderstorm/frontend';


type Props = {
	editable: EditableDBItem<DB_ProductTemplate>
};

type State = {};

export class Component_ProductInstances
	extends ComponentAsync<Props, State> {

	protected async deriveStateFromProps(nextProps: Props): Promise<State> {
		return {};
	}

	render() {
		const editable = this.props.editable;
		return <LL_H_C className="product-template__permutations">
			{editable.item.label}
		</LL_H_C>;
	}

}

