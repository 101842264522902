"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterKeys = exports.assert = exports.compare = exports.partialCompare = exports.cloneObj = exports.cloneArr = exports._setTypedProp = exports._values = exports._keys = exports.deepClone = void 0;
const exceptions_1 = require("../core/exceptions/exceptions");
const array_tools_1 = require("./array-tools");
function deepClone(obj) {
    if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean' || typeof obj === 'undefined' || obj === null)
        return obj;
    if (Array.isArray(obj))
        return cloneArr(obj);
    return cloneObj(obj);
}
exports.deepClone = deepClone;
function _keys(instance) {
    return Object.keys(instance);
}
exports._keys = _keys;
function _values(object) {
    return Object.values(object);
}
exports._values = _values;
function _setTypedProp(instance, key, value) {
    instance[key] = value;
}
exports._setTypedProp = _setTypedProp;
function cloneArr(value) {
    return value.map(a => deepClone(a));
}
exports.cloneArr = cloneArr;
function cloneObj(obj) {
    return _keys(obj).reduce((carry, key) => {
        carry[key] = deepClone(obj[key]);
        return carry;
    }, {});
}
exports.cloneObj = cloneObj;
function partialCompare(one, two, keysToFilterOut) {
    one = deepClone(one);
    two = deepClone(two);
    keysToFilterOut === null || keysToFilterOut === void 0 ? void 0 : keysToFilterOut.forEach(key => {
        one === null || one === void 0 ? true : delete one[key];
        two === null || two === void 0 ? true : delete two[key];
    });
    return compare(one, two);
}
exports.partialCompare = partialCompare;
function compare(one, two, keys) {
    const typeofOne = typeof one;
    const typeofTwo = typeof two;
    if (typeofOne !== typeofTwo)
        return false;
    if (one === undefined && two === undefined)
        return true;
    if (one === undefined || two === undefined)
        return false;
    if (one === null && two === null)
        return true;
    if (one === null || two === null)
        return false;
    if (typeofOne === 'function')
        throw new exceptions_1.BadImplementationException('This compare meant to compare two POJOs.. nothing more');
    if (typeofOne !== 'object')
        return one === two;
    if (Array.isArray(one) && Array.isArray(two)) {
        if (one.length !== two.length)
            return false;
        for (let i = 0; i < one.length; i++) {
            if (compare(one[i], two[i]))
                continue;
            return false;
        }
        return true;
    }
    const _one = one;
    const _two = two;
    const oneKeys = keys || Object.keys(_one);
    const twoKeys = keys || Object.keys(_two);
    if (oneKeys.length !== twoKeys.length)
        return false;
    for (const oneKey of oneKeys) {
        if (!twoKeys.includes(oneKey))
            return false;
    }
    for (const oneKey of oneKeys) {
        if (compare(_one[oneKey], _two[oneKey]))
            continue;
        return false;
    }
    return true;
}
exports.compare = compare;
function assert(message, expected, actual) {
    if (!compare(expected, actual))
        throw new exceptions_1.AssertionException(`Assertion Failed:\n  -- ${message}\n  -- Expected: ${JSON.stringify(expected)}\n  --   Actual: ${JSON.stringify(actual)}\n\n`);
}
exports.assert = assert;
function filterKeys(obj, keys = _keys(obj), filter = (k) => obj[k] === undefined || obj[k] === null) {
    if (typeof obj !== 'object' || obj === null) {
        throw new exceptions_1.BadImplementationException('Passed parameter for "obj" must be an object');
    }
    (0, array_tools_1.asArray)(keys).forEach(key => {
        if (filter(key, obj))
            delete obj[key];
    });
    return obj;
}
exports.filterKeys = filterKeys;
