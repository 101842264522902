import { ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';

import {apiWithQuery, ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {ApiDefCaller, QueryApi} from '@nu-art/thunderstorm/shared';
import {DB_BaseObject} from '@nu-art/ts-common';
import {ModuleFE_InventoryItems} from './ModuleFE_InventoryItems';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';
import {ApiDef_StoreInventory, ApiStruct_StoreInventory, DB_StoreInventory, DBDef_StoreInventory} from '@app/shared/store';


export interface OnStoreInventoryUpdated {
	__onStoreInventoryUpdated: (...params: ApiCallerEventType<DB_StoreInventory>) => void;
}

export const dispatch_onStoreInventoryUpdated = new ThunderDispatcher<OnStoreInventoryUpdated, '__onStoreInventoryUpdated'>('__onStoreInventoryUpdated');

export class ModuleFE_StoreInventory_Class
	extends ModuleFE_BaseApi<DB_StoreInventory, 'storeId' | 'timestamp'>
	implements ApiDefCaller<ApiStruct_StoreInventory> {

	readonly _v1: ApiDefCaller<ApiStruct_StoreInventory>['_v1'];

	constructor() {
		super(DBDef_StoreInventory, dispatch_onStoreInventoryUpdated);
		this._v1 = {
			calculateInventory: apiWithQuery<QueryApi<void, DB_BaseObject>>(ApiDef_StoreInventory._v1.calculateInventory, this.onInventoryCalculated)
		} as ApiDefCaller<ApiStruct_StoreInventory>['_v1'];
	}

	async onInventoryCalculated() {
		ModuleFE_InventoryItems.v1.sync().execute();
		this.v1.sync().execute();
	}
}

export const ModuleFE_StoreInventory = new ModuleFE_StoreInventory_Class();
export const ModuleFE_StoreInventory_ = ModuleFE_StoreInventory as unknown as ModuleFE_BaseApi<any, any>;
