"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.appWithJSX = exports.appWithMemoryRouter = exports.appWithBrowserRouter = void 0;
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
function appWithBrowserRouter(props) {
    const MainApp = props.element;
    return React.createElement(react_router_dom_1.BrowserRouter, null,
        React.createElement(MainApp, null));
}
exports.appWithBrowserRouter = appWithBrowserRouter;
function appWithMemoryRouter(props) {
    const MainApp = props.element;
    return React.createElement(react_router_dom_1.MemoryRouter, null,
        React.createElement(MainApp, null));
}
exports.appWithMemoryRouter = appWithMemoryRouter;
function appWithJSX(props) {
    const MainApp = props.element;
    return React.createElement(MainApp, Object.assign({}, props.props));
}
exports.appWithJSX = appWithJSX;
