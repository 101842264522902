"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_DeliveryCourier = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
exports.ApiDef_DeliveryCourier = {
    _v1: {
        create: { method: thunderstorm_1.HttpMethod.POST, path: `v1/delivery-courier/create` },
        update: { method: thunderstorm_1.HttpMethod.POST, path: `v1/delivery-courier/update` },
        delete: { method: thunderstorm_1.HttpMethod.POST, path: `v1/delivery-courier/delete` },
        calculate: { method: thunderstorm_1.HttpMethod.POST, path: `v1/delivery-courier/calculate` },
    }
};
