"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugFlags = exports.DebugFlag = void 0;
const array_tools_1 = require("../utils/array-tools");
const types_1 = require("./logger/types");
class DebugFlag {
    constructor(key, minLogLevel = types_1.LogLevel.Info) {
        this.key = key;
        this.minLogLevel = minLogLevel;
        DebugFlags.add(this);
    }
    setMinLevel(minLogLevel) {
        this.minLogLevel = minLogLevel;
    }
    rename(newKey) {
        DebugFlags.rename(this.key, newKey);
    }
    getKey() {
        return this.key;
    }
    enable(enable = true) {
        if (this.isEnabled() === enable)
            return;
        if (enable)
            this._enable();
        else
            this._disable();
    }
    isEnabled() {
        return DebugFlags.instance.ActiveDebugFlags.includes(this.key);
    }
    canLog(level) {
        return types_1.LogLevelOrdinal.indexOf(level) >= types_1.LogLevelOrdinal.indexOf(this.minLogLevel);
    }
    _enable() {
        (0, array_tools_1.addItemToArray)(DebugFlags.instance.ActiveDebugFlags, this.key);
    }
    _disable() {
        (0, array_tools_1.removeItemFromArray)(DebugFlags.instance.ActiveDebugFlags, this.key);
    }
}
exports.DebugFlag = DebugFlag;
class DebugFlags {
    constructor() {
        this.AllDebugFlags = {};
        this.ActiveDebugFlags = [];
    }
    static createFlag(key, minLogLevel = types_1.LogLevel.Info) {
        // @ts-ignore
        return new DebugFlag(key, minLogLevel);
    }
    static add(flag) {
        this.instance.AllDebugFlags[flag.getKey()] = flag;
    }
    static rename(previousKey, newKey) {
        const flag = this.instance.AllDebugFlags[previousKey];
        if (!flag)
            return;
        delete this.instance.AllDebugFlags[previousKey];
        this.instance.AllDebugFlags[newKey] = flag;
    }
}
DebugFlags.instance = new DebugFlags();
exports.DebugFlags = DebugFlags;
