"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports._ApiDef_TestPermissions = exports.ApiDef_Permissions = exports.ApiDef_PermissionsAssert = exports._ApiDef_PermissionUser = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const ts_common_1 = require("@nu-art/ts-common");
exports._ApiDef_PermissionUser = {
    pah: {
        assignAppPermissions: { method: thunderstorm_1.HttpMethod.POST, path: '/pah/permissions/assign/app-permissions' }
    }
};
exports.ApiDef_PermissionsAssert = {
    vv1: {
        assertUserPermissions: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/permissions/assert-user-access' }
    }
};
exports.ApiDef_Permissions = {
    v1: {
        // getUserUrlsPermissions: {method: HttpMethod.POST, path: 'v1/permissions/user-urls-permissions'},
        // getUserCFsByShareGroups: {method: HttpMethod.POST, path: 'v1/user-custom-fields/user-cf-by-share-groups'},
        // getUsersCFsByShareGroups: {method: HttpMethod.POST, path: 'v1/user-custom-fields/users-cf-by-share-groups'},
        // registerExternalProject: {method: HttpMethod.POST, path: 'v1/register/register-external-project'},
        // registerProject: {method: HttpMethod.GET, path: 'v1/register/register-project'},
        createProject: { method: thunderstorm_1.HttpMethod.GET, path: 'v1/permissions/create-first-project', timeout: ts_common_1.Minute },
        connectDomainToRoutes: { method: thunderstorm_1.HttpMethod.POST, path: 'v1/permissions/connect-domain-to-routes' },
    }
};
exports._ApiDef_TestPermissions = {
    v1: {
        test: { method: thunderstorm_1.HttpMethod.GET, path: 'v1/permissions-test/test' }
    }
};
