"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_XHR = exports.ModuleFE_XHR_Class = void 0;
const ts_common_1 = require("@nu-art/ts-common");
// noinspection TypeScriptPreferShortImport
const shared_1 = require("../../../shared");
// noinspection TypeScriptPreferShortImport
const zlib_1 = require("zlib");
class ModuleFE_XHR_Class extends shared_1.BaseHttpModule_Class {
    init() {
        super.init();
        let origin = this.config.origin;
        if (!origin)
            throw new ts_common_1.BadImplementationException('Did yo\ forget to set the origin config key for the HttpModule?');
        if (origin === null || origin === void 0 ? void 0 : origin.endsWith('/'))
            origin = origin.substring(0, origin.length - 1);
        this.origin = origin;
    }
    createRequest(apiDef, data) {
        const request = new XhrHttpRequest(apiDef.path, data, this.shouldCompress())
            .setLogger(this)
            .setMethod(apiDef.method)
            .setTimeout(this.timeout)
            .setOnCompleted(this.defaultOnComplete)
            .addHeaders(this.getDefaultHeaders());
        if (apiDef.fullUrl)
            request.setUrl(apiDef.fullUrl);
        else
            request
                .setOrigin(this.origin)
                .setRelativeUrl(apiDef.path);
        return request;
    }
}
exports.ModuleFE_XHR_Class = ModuleFE_XHR_Class;
exports.ModuleFE_XHR = new ModuleFE_XHR_Class();
class XhrHttpRequest extends shared_1.BaseHttpRequest {
    constructor(requestKey, requestData, shouldCompress) {
        super(requestKey, requestData);
        this.compress = shouldCompress === undefined ? true : shouldCompress;
    }
    getStatus() {
        const xhr = this.xhr;
        if (!xhr)
            throw new ts_common_1.BadImplementationException('No xhr object!');
        return xhr.status;
    }
    getResponse() {
        const xhr = this.xhr;
        if (!xhr)
            throw new ts_common_1.BadImplementationException('No xhr object!');
        return xhr.response;
    }
    abortImpl() {
        var _a;
        (_a = this.xhr) === null || _a === void 0 ? void 0 : _a.abort();
    }
    getErrorResponse() {
        const rawResponse = this.getResponse();
        let response = undefined;
        if (rawResponse) {
            try {
                response = rawResponse && JSON.parse(rawResponse);
            }
            catch (e) {
                response = { debugMessage: rawResponse };
            }
        }
        return response;
    }
    prepareJsonBody(bodyObject) {
        return JSON.stringify(bodyObject);
    }
    executeImpl() {
        //loop through whatever preprocessor
        return new Promise((resolve, reject) => {
            if (this.aborted)
                return resolve();
            const xhr = new XMLHttpRequest();
            // @ts-ignore
            // noinspection JSConstantReassignment
            this.xhr = xhr;
            this.xhr.onreadystatechange = () => {
                if (xhr.readyState !== 4)
                    return;
                resolve();
            };
            this.xhr.onerror = (err) => {
                if (xhr.readyState === 4 && xhr.status === 0) {
                    reject(new shared_1.HttpException(404, this.url));
                    return;
                }
                reject(err);
            };
            this.xhr.ontimeout = (err) => {
                reject(err);
            };
            this.xhr.onload = (err) => {
                // HttpModule.logVerbose("onload");
            };
            this.xhr.onloadstart = (err) => {
                // HttpModule.logVerbose("onloadstart");
            };
            this.xhr.onloadend = (err) => {
                // HttpModule.logVerbose("onloadend");
            };
            this.xhr.onabort = (err) => {
                // HttpModule.logVerbose("onabort");
            };
            const fullUrl = (0, ts_common_1.composeUrl)(this.url, this.params);
            // TODO: investigate which one should work
            this.xhr.onprogress = this.onProgressListener;
            this.xhr.upload.onprogress = this.onProgressListener;
            this.xhr.open(this.method, fullUrl);
            this.xhr.timeout = this.timeout;
            Object.keys(this.headers).forEach((key) => {
                xhr.setRequestHeader(key, this.headers[key].join('; '));
            });
            let body = this.body;
            if (typeof body === 'string' && this.compress)
                try {
                    body = (0, zlib_1.gzipSync)(this.body);
                }
                catch (error) {
                    return reject(error);
                }
            return this.xhr.send(body);
        });
    }
    getResponseHeader(headerKey) {
        if (!this.xhr)
            throw new ts_common_1.BadImplementationException('No xhr object!');
        return this.xhr.getResponseHeader(headerKey) || undefined;
    }
}
