"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const notification_dispatchers_1 = require("../notification-dispatchers");
require("./ResolveDependencyToast.scss");
const ModuleFE_Toaster_1 = require("../../../component-modules/ModuleFE_Toaster");
function ResolveDependencyToast(props) {
    const renderResolveDependenciesPanel = () => {
        notification_dispatchers_1.dispatch_onDeleteConflicts.dispatchUI(props.deletedEntity, props.dependencyConflicts);
        ModuleFE_Toaster_1.ModuleFE_Toaster.hideToast();
    };
    return (React.createElement("div", { className: 'item-dependency-toast' },
        React.createElement("div", null, "Entity has dependencies."),
        React.createElement("div", { onClick: () => renderResolveDependenciesPanel() },
            React.createElement("span", { className: 'click-here-label' }, "Click here"),
            " to resolve")));
}
exports.default = ResolveDependencyToast;
