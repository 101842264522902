import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {DB_Asset, DBDef_ShopifyAsset} from '@app/shared';


export interface OnAssetsShopifyUpdated {
	__onAssetsShopifyUpdated: (...params: ApiCallerEventType<DB_Asset<any>>) => void;
}

export const dispatch_onAssetsShopifyListChanged = new ThunderDispatcher<OnAssetsShopifyUpdated, '__onAssetsShopifyUpdated'>('__onAssetsShopifyUpdated');

export class ModuleFE_AssetsShopify_Class
	extends ModuleFE_BaseApi<DB_Asset<any>> {

	constructor() {
		super(DBDef_ShopifyAsset, dispatch_onAssetsShopifyListChanged);
	}
}

export const ModuleFE_ShopifyAssets = new ModuleFE_AssetsShopify_Class();
