"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_TranslationProject = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const db_def_1 = require("./db-def");
exports.ApiDef_TranslationProject = {
    _v1: {
        '?': { method: thunderstorm_1.HttpMethod.POST, path: `v1/${db_def_1.DBDef_TranslationProject.dbName}/post` },
        '??': { method: thunderstorm_1.HttpMethod.GET, path: `v1/${db_def_1.DBDef_TranslationProject.dbName}/get` },
    }
};
