"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppPageV2 = void 0;
const ComponentAsync_1 = require("./ComponentAsync");
const consts_1 = require("./consts");
class AppPageV2 extends ComponentAsync_1.ComponentAsync {
    constructor(p, pageTitle) {
        var _a, _b;
        super(p);
        this.updateTitle = () => {
            const newTitle = this.resolveTitle();
            document.title = newTitle;
            this.logDebug(`Mounting page: ${newTitle}`);
            consts_1.dispatch_onPageTitleChanged.dispatchUI(document.title);
        };
        this.resolveTitle = () => typeof this.pageTitle === 'function' ? this.pageTitle() : this.pageTitle;
        this.pageTitle = pageTitle || document.title;
        const _componentDidMount = (_a = this.componentDidMount) === null || _a === void 0 ? void 0 : _a.bind(this);
        this.componentDidMount = () => {
            _componentDidMount === null || _componentDidMount === void 0 ? void 0 : _componentDidMount();
            this.prevTitle = document.title;
            this.updateTitle();
        };
        const _componentWillUnmount = (_b = this.componentWillUnmount) === null || _b === void 0 ? void 0 : _b.bind(this);
        this.componentWillUnmount = () => {
            _componentWillUnmount === null || _componentWillUnmount === void 0 ? void 0 : _componentWillUnmount();
            document.title = this.prevTitle;
        };
    }
    setPageTitle(pageTitle) {
        this.pageTitle = pageTitle;
        if (this.mounted)
            this.updateTitle();
    }
}
exports.AppPageV2 = AppPageV2;
