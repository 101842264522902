"use strict";
/*
 * Firebase is a simpler Typescript wrapper to all of firebase services.
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatabaseWrapperFE = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const database_1 = require("firebase/database");
class DatabaseWrapperFE extends ts_common_1.Logger {
    constructor(app) {
        super();
        this.getRef = (path) => (0, database_1.ref)(this.database, path);
        this.database = (0, database_1.getDatabase)(app);
    }
    async get(path) {
        return new Promise((resolve, reject) => {
            (0, database_1.onValue)(this.getRef(path), snapshot => {
                resolve(snapshot.val());
            }, (error) => {
                reject(error);
            }, { onlyOnce: true });
        });
    }
    listen(path, callback) {
        return (0, database_1.onValue)(this.getRef(path), snapshot => {
            callback(!snapshot || snapshot.val());
        }, (error) => {
            throw new ts_common_1.BadImplementationException(`Error while getting value from path: ${path}`, error);
        }, { onlyOnce: false });
    }
    async set(path, value) {
        try {
            await (0, database_1.set)(this.getRef(path), value);
        }
        catch (e) {
            throw new ts_common_1.BadImplementationException(`Error while setting value to path: ${path}`);
        }
    }
    /** @deprecated */
    async update(path, value) {
        this.logWarning('update will be deprecated!! please use patch');
        return this.patch(path, value);
    }
    async patch(path, value) {
        try {
            await (0, database_1.update)(this.getRef(path), value);
        }
        catch (e) {
            throw new ts_common_1.BadImplementationException(`Error while updating value to path: ${path}`);
        }
    }
    /** @deprecated */
    async remove(path, assertionRegexp = '^/.*?/.*') {
        this.logWarning('remove will be deprecated!! please use delete');
        return this.delete(path, assertionRegexp);
    }
    async delete(path, assertionRegexp = '^/.*?/.*') {
        if (!path)
            throw new ts_common_1.BadImplementationException(`Falsy value, path: '${path}'`);
        if (!path.match(new RegExp(assertionRegexp)))
            throw new ts_common_1.BadImplementationException(`path: '${path}'  does not match assertion: '${assertionRegexp}'`);
        try {
            await (0, database_1.remove)(this.getRef(path));
        }
        catch (e) {
            throw new ts_common_1.BadImplementationException(`Error while removing path: ${path}`);
        }
    }
}
exports.DatabaseWrapperFE = DatabaseWrapperFE;
