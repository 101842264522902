"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollToTop = exports.scrollIntoView = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const extractElement = (element) => {
    return element instanceof HTMLElement ? element : element.current;
};
const scrollIntoView = (_child, _container, scrollBehavior = 'smooth') => {
    const child = extractElement(_child);
    const container = extractElement(_container);
    if (!child || !container)
        throw new ts_common_1.BadImplementationException('Got no elements');
    const childRect = child.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    const inView = (childRect.top >= containerRect.top) && (childRect.bottom <= containerRect.top + container.clientHeight);
    if (!inView) {
        const scrollTop = childRect.top - containerRect.top;
        const scrollBot = childRect.bottom - containerRect.bottom;
        let scroll = container.scrollTop;
        if (Math.abs(scrollTop) < Math.abs(scrollBot))
            scroll += scrollTop - 35;
        else
            scroll += scrollBot;
        container.scroll({ top: scroll, behavior: scrollBehavior });
    }
};
exports.scrollIntoView = scrollIntoView;
const scrollToTop = (_child, _container, scrollBehavior = 'smooth', offset = 0) => {
    const child = extractElement(_child);
    const container = extractElement(_container);
    if (!child || !container)
        throw new ts_common_1.BadImplementationException('Got no elements');
    const childRect = child.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    if (childRect.top === containerRect.top)
        return;
    let scroll = container.scrollTop;
    scroll += childRect.top - containerRect.top - offset;
    container.scroll({ top: scroll, behavior: scrollBehavior });
};
exports.scrollToTop = scrollToTop;
