"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_MessageTemplate = void 0;
const validators_1 = require("../validators");
const Validator_MessageTemplate = {
    label: (0, validators_1.tsValidateStringLength)(50),
    template: (0, validators_1.tsValidateStringLength)(400),
};
exports.DBDef_MessageTemplate = {
    validator: Validator_MessageTemplate,
    dbName: 'message-templates',
    entityName: 'message-template',
};
