import * as React from 'react';
import {_keys} from '@nu-art/ts-common';
import {
	AppPage,
	Language,
	LL_H_C,
	LL_V_L,
	ModuleFE_Locale,
	SimpleListAdapter,
	ModuleFE_Thunderstorm,
	TS_DropDown,
	TS_Input,
	TS_TextArea
} from '@nu-art/thunderstorm/frontend';
import {LOCALE} from '@res/locale';
import {AppStrings} from '@res/localization/AppLanguage';
import {ICONSV4} from '@res/icons';


type State = {
	language: Language
	stringKey: keyof AppStrings
	params: string[]
}

class Pg_Component
	extends AppPage<{}, State> {
	static defaultProps = {
		pageTitle: () => PgDev_Locales.name
	};

	constructor(props: {}) {
		super(props);
	}

	protected deriveStateFromProps(nextProps: {}) {
		return {
			language: ModuleFE_Locale.getActiveLanguage(),
			stringKey: _keys(LOCALE)[0],
			params: [''] as string[],
		};
	}

	render() {
		return <LL_V_L>
			<div style={{marginBottom: '16px'}}>Locales</div>
			{this.renderCodeSnippet()}
			{this.renderLocalesDropDown()}
			<LL_H_C>
				<div style={{marginRight: '12px'}}>Params:</div>
				{ICONSV4.add({onClick: () => this.setState(prev => ({params: [...prev.params, '']}))})}
			</LL_H_C>

			<LL_H_C>
				{this.renderParams()}

				<LL_V_L style={{marginLeft: '24px'}}>
					<div>OUTPUT:</div>
					<div>{LOCALE[this.state.stringKey]?.(...this.state.params)}</div>
				</LL_V_L>
			</LL_H_C>


			<LL_V_L style={{overflowY: 'scroll', marginTop: '24px'}}>
				<div style={{marginBottom: '12px'}}>Application Texts:</div>
				{_keys(LOCALE).map(stringKey => (
					<React.Fragment key={stringKey}>
						<div onClick={() => this.setState({stringKey})} style={{padding: '3px', float: 'left'}}>
							<div style={{marginRight: '12px', minWidth: '150px'}}>{stringKey}: {LOCALE[stringKey]()}</div>
						</div>
					</React.Fragment>))}
			</LL_V_L>
		</LL_V_L>;
	}

	protected generateCodeSnippet(): string {
		return `{LOCALE.${this.state.stringKey}(${this.state.params.join(', ')})}`;
	}

	private renderLocalesDropDown() {
		const adapter = SimpleListAdapter<Language>(ModuleFE_Locale.getAllLanguages(), (props) =>
			<div>{props.item.shortLocale} - {props.item.shortName}</div>
		);

		return <LL_H_C style={{minHeight: '30px', marginBottom: '12px'}}>
			<div style={{minWidth: '100px', marginRight: '16px'}}>Select a Language:</div>
			<TS_DropDown<Language>
				adapter={adapter}
				onSelected={selected => {
					ModuleFE_Locale.setActiveLocale(selected.shortLocale);
					this.setState({language: selected});
				}}
				selected={this.state.language}/>
		</LL_H_C>;
	}

	private renderCodeSnippet() {
		const snippet = this.generateCodeSnippet();
		return (
			<LL_H_C style={{marginBottom: '12px'}}>
				<TS_TextArea
					id={'snippet'}
					type={'text'} style={{fontFamily: 'monospace !important', resize: 'none', width: '400px', marginRight: '12px'}} value={snippet}
					enable={false}/>
				{ICONSV4.copy({
					style: {alignSelf: 'center'},
					onClick: async () => ModuleFE_Thunderstorm.copyToClipboard(snippet)
				})}
			</LL_H_C>
		);
	}

	private renderParams() {
		return (
			<LL_V_L>
				{this.state.params.map((param, index) => (

					<LL_V_L key={index}>
						<TS_Input
							onChange={value => {
								this.state.params[index] = value;
								this.forceUpdate();
							}}
							type="text"
							id={`input-${index}`}
							value={this.state.params[index]} style={{border: '1px solid black'}}/>
						{ICONSV4.remove({
							onClick: () => {
								this.state.params.splice(index, 1);
								this.forceUpdate();
							}
						})}
					</LL_V_L>
				))}
			</LL_V_L>
		);
	}
}

export const PgDev_Locales = {name: 'DevTool - Locales', renderer: Pg_Component};

