"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleManager = exports.moduleResolver = void 0;
const dispatcher_1 = require("./dispatcher");
const exceptions_1 = require("./exceptions/exceptions");
const Logger_1 = require("./logger/Logger");
const array_tools_1 = require("../utils/array-tools");
const tools_1 = require("../utils/tools");
const _modules = [];
function moduleResolver() {
    return _modules;
}
exports.moduleResolver = moduleResolver;
class ModuleManager extends Logger_1.Logger {
    // noinspection JSUnusedLocalSymbols
    constructor() {
        super();
        this.modules = _modules;
        if (ModuleManager.instance)
            throw new exceptions_1.BadImplementationException('Already have one instance of ModuleManager');
        ModuleManager.instance = this;
        dispatcher_1.Dispatcher.modulesResolver = moduleResolver;
    }
    // @ts-ignore
    static resetForTests() {
        _modules.length = 0;
        // @ts-ignore
        delete ModuleManager.instance;
    }
    filterModules(filter) {
        return this.modules.filter(filter);
    }
    setConfig(config) {
        this.config = config || {};
        return this;
    }
    addModulePack(modules) {
        modules.reduce((carry, module) => {
            if (!carry.includes(module))
                (0, array_tools_1.addItemToArray)(carry, module);
            return carry;
        }, this.modules);
        return this;
    }
    init() {
        if (this.config.logLevel)
            this.setMinLevel(this.config.logLevel);
        this.logInfo(`---------  initializing app  ---------`);
        const undefinedModule = this.modules.some(module => !(0, tools_1.exists)(module));
        if (undefinedModule) {
            const modulesList = JSON.stringify(this.modules.map(module => {
                // @ts-ignore
                return (module === null || module === void 0 ? void 0 : module.tag)
                    || 'undefined';
            }), null, 2);
            throw new exceptions_1.BadImplementationException(`Module was 'undefined' - probably cyclic import mess here are the list of modules: \n${modulesList}`);
        }
        this.modules.forEach((module) => {
            // @ts-ignore
            module.setManager(this);
            if (this.config)
                // @ts-ignore
                module.setConfig(this.config[module.getName()]);
        });
        this.modules.forEach(module => {
            this.logDebug(`---------  ${module.getName()}  ---------`);
            try { // @ts-ignore
                module.init();
                // @ts-ignore
                module.initiated = true;
            }
            catch (e) {
                this.logError(`Failed to init module ${module.getName()}.\n`, e);
            }
        });
        // @ts-ignore
        this.modules.forEach(module => module.validate());
        this.logInfo(`---------  INITIALIZED  ---------`);
        return this;
    }
    build() {
        this.init();
    }
}
exports.ModuleManager = ModuleManager;
