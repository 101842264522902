"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageType_Size = void 0;
var PackageType_Size;
(function (PackageType_Size) {
    PackageType_Size[PackageType_Size["Envelope"] = 0] = "Envelope";
    PackageType_Size[PackageType_Size["Small"] = 1] = "Small";
    PackageType_Size[PackageType_Size["Medium"] = 2] = "Medium";
    PackageType_Size[PackageType_Size["Large"] = 3] = "Large";
})(PackageType_Size = exports.PackageType_Size || (exports.PackageType_Size = {}));
