"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_PermissionsAssert = exports.ModuleFE_PermissionsAssert_Class = exports.AccessLevel = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const __1 = require("../..");
const ModuleFE_PermissionsApi_1 = require("./manage/ModuleFE_PermissionsApi");
const consts_1 = require("../consts");
// const dispatch_onPermissionsChanged = new ThunderDispatcher<OnPermissionsChanged, '__onPermissionsChanged'>('__onPermissionsChanged');
// const dispatch_onPermissionsFailed = new ThunderDispatcher<OnPermissionsFailed, '__onPermissionsFailed'>('__onPermissionsFailed');
var AccessLevel;
(function (AccessLevel) {
    AccessLevel[AccessLevel["Undefined"] = 0] = "Undefined";
    AccessLevel[AccessLevel["NoAccessLevelsDefined"] = 1] = "NoAccessLevelsDefined";
    AccessLevel[AccessLevel["NoAccess"] = 2] = "NoAccess";
    AccessLevel[AccessLevel["HasAccess"] = 3] = "HasAccess";
})(AccessLevel = exports.AccessLevel || (exports.AccessLevel = {}));
class ModuleFE_PermissionsAssert_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.permissionKeys = {};
        this.v1 = {
            createProject: (0, frontend_1.apiWithQuery)(__1.ApiDef_Permissions.v1.createProject),
            connectDomainToRoutes: (0, frontend_1.apiWithBody)(__1.ApiDef_Permissions.v1.connectDomainToRoutes, async () => await ModuleFE_PermissionsApi_1.ModuleFE_PermissionsApi.v1.sync().executeSync())
        };
    }
    getAccessLevelByKeyString(key) {
        return this.getAccessLevel(this.getPermissionKey(key));
    }
    getAccessLevel(key) {
        const keyData = key.get();
        if (!(0, ts_common_1.exists)(keyData))
            return consts_1.SessionKey_StrictMode_FE.get() ? AccessLevel.Undefined : AccessLevel.HasAccess;
        if (keyData.accessLevelIds.length === 0)
            return AccessLevel.NoAccessLevelsDefined;
        const userAccessLevels = consts_1.SessionKey_Permissions_FE.get();
        try {
            const canAccess = (0, ts_common_1._keys)(keyData._accessLevels).reduce((hasAccess, domainId) => {
                return hasAccess && userAccessLevels[domainId] >= keyData._accessLevels[domainId];
            }, true);
            return canAccess ? AccessLevel.HasAccess : AccessLevel.NoAccess;
        }
        catch (e) {
            return AccessLevel.NoAccess;
        }
    }
    getPermissionKey(key) {
        return this.permissionKeys[key];
    }
    registerPermissionKey(key) {
        if (this.permissionKeys[key.key])
            throw new ts_common_1.BadImplementationException(`Registered PermissionKey '${key}' more than once!`);
        this.permissionKeys[key.key] = key;
    }
    getAllPermissionKeys() {
        return this.permissionKeys;
    }
}
exports.ModuleFE_PermissionsAssert_Class = ModuleFE_PermissionsAssert_Class;
exports.ModuleFE_PermissionsAssert = new ModuleFE_PermissionsAssert_Class();
