import {ComponentSync, EditableItem, TS_PropRenderer} from '@nu-art/thunderstorm/frontend';
import {ReactNode} from 'react';
import * as React from 'react';


export type EditableRef<Item> = { editable: EditableItem<Item> };

export abstract class Component_EditableItem<ItemType, P = {}, S = {}>
	extends ComponentSync<P & EditableRef<ItemType>, S & EditableRef<ItemType>> {

	protected deriveStateFromProps(nextProps: P & EditableRef<ItemType>): S & EditableRef<ItemType> {
		return {
			editable: nextProps.editable,
		} as S & EditableRef<ItemType>;
	}

	protected renderProp(label: string, render: ReactNode, className?: string) {
		return (
			<TS_PropRenderer.Vertical label={label} className={className}>
				{render}
			</TS_PropRenderer.Vertical>
		);
	}

	item = (): Partial<ItemType> => this.state.editable.item;
}
