"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_Translations = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const db_def_1 = require("./db-def");
exports.ApiDef_Translations = {
    _v1: {
        googleTranslate: { method: thunderstorm_1.HttpMethod.POST, path: `v1/${db_def_1.DBDef_TranslationEntry.dbName}/googleTranslate` }
    }
};
