import * as React from 'react';
import {ModuleFE_CustomerContact_} from '@modules/index';
import {TS_Route} from '@nu-art/thunderstorm/frontend';
import {DB_Customer} from '@app/shared';
import {Filter, filterInstances} from '@nu-art/ts-common';
import {EditorItem_Customer} from './EditorItem_Customer';
import {Page_ItemsEditor, Props_ItemsEditor} from '@nu-art/thunderstorm/frontend';


export class Page_CustomersV2
	extends Page_ItemsEditor<DB_Customer> {

	static Route: TS_Route<{ _id?: string }> = {path: 'customers', key: 'customers-editor', Component: this};

	static defaultProps: Partial<Props_ItemsEditor<DB_Customer>> = {
		pageTitle: 'Customers',
		modules: [ModuleFE_CustomerContact_],
		module: ModuleFE_CustomerContact_,
		filter: new Filter<DB_Customer>((contact) => filterInstances([`${contact.firstName}${contact.lastName}`, contact.email, contact.phone])),
		sort: (contact: DB_Customer) => `${contact.firstName} ${contact.lastName}`,
		itemRenderer: (contact: DB_Customer) => <>{contact.firstName} {contact.lastName}</>,
		EditorRenderer: EditorItem_Customer,
		route: this.Route
	};
}

