import * as React from 'react';
import {ModuleFE_Products_, ModuleFE_ProductTemplate, ModuleFE_ProductTemplate_} from '@modules/index';
import {TS_Route} from '@nu-art/thunderstorm/frontend';
import {DB_ProductTemplate} from '@app/shared';
import {Filter} from '@nu-art/ts-common';
import {EditorItem_ProductTemplate} from './EditorItem_ProductTemplate';
import {Page_ItemsEditor, Props_ItemsEditor} from '@nu-art/thunderstorm/frontend';


export class Page_ProductTemplateV2
	extends Page_ItemsEditor<DB_ProductTemplate> {

	static Route: TS_Route<{ _id?: string }> = {path: 'product-template', key: 'product-template-editor', Component: this};

	static defaultProps: Partial<Props_ItemsEditor<DB_ProductTemplate>> = {
		pageTitle: 'Product Templates',
		modules: [ModuleFE_ProductTemplate_, ModuleFE_Products_],
		module: ModuleFE_ProductTemplate,
		filter: new Filter<DB_ProductTemplate>((productTag) => [productTag.label]),
		sort: (item: DB_ProductTemplate) => item.label,
		itemRenderer: (item: DB_ProductTemplate) => <>{item.label}</>,
		EditorRenderer: EditorItem_ProductTemplate,
		route: this.Route
	};
}

