import {ModuleFE_BaseApi, ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {DB_Shipment, DBDef_Shipment} from '@app/shared';
import {InvalidResult, PreDB} from '@nu-art/ts-common';


export interface OnShipmentsUpdated {
	__onShipmentsUpdated: (...params: ApiCallerEventType<DB_Shipment>) => void;
}

export const dispatch_onShipmentsUpdated = new ThunderDispatcher<OnShipmentsUpdated, '__onShipmentsUpdated'>('__onShipmentsUpdated');

export class ModuleFE_Shipment_Class
	extends ModuleFE_BaseApi<DB_Shipment> {

	constructor() {
		super(DBDef_Shipment, dispatch_onShipmentsUpdated);
	}

	protected onValidationError(instance: PreDB<DB_Shipment>, results: InvalidResult<DB_Shipment>) {
		if (instance._isDraft)
			return;

		super.onValidationError(instance, results);
	}
}

export const ModuleFE_Shipments = new ModuleFE_Shipment_Class();
