import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {DB_Variant, DBDef_Variant} from '@app/shared';


export interface OnProductVariantsUpdated {
	__onProductVariantsUpdated: (...params: ApiCallerEventType<DB_Variant>) => void;
}

export const dispatch_onProductVariantListChanged = new ThunderDispatcher<OnProductVariantsUpdated, '__onProductVariantsUpdated'>('__onProductVariantsUpdated');

export type ProductTemplateUI_Properties = { key: string, values: string[] };

export class ModuleFE_ProductVariants_Class
	extends ModuleFE_BaseApi<DB_Variant, 'storeId' | 'shopifyId'> {

	constructor() {
		super(DBDef_Variant, dispatch_onProductVariantListChanged);
	}

	getForProduct(_id: string) {
		return this.cache.filter(p => p.productId === _id);
	}
}

export const ModuleFE_ProductVariants = new ModuleFE_ProductVariants_Class();
