import {
	_className,
	ComponentAsync,
	ComponentSync,
	LL_H_C,
	LL_V_L,
	TS_Input,
	TS_PropRenderer,
	TS_Radio,
	ModuleFE_Toaster,
	TS_Checkbox
} from '@nu-art/thunderstorm/frontend';
import * as React from 'react';
import {addItemToArray, removeItemFromArray, roundNumber} from '@nu-art/ts-common';
import {ICONSV4} from '@res/icons';
import {DB_ProductTemplate, FulfillmentPolicy, FulfillmentPolicy_Values, OutOfStockPolicy, OutOfStockPolicy_Values} from '@app/shared';
import {ModuleFE_Products, ModuleFE_ProductTags, ModuleFE_ProductTemplate, ModuleFE_Stores} from '@modules/index';
import {DropDown, OtherDropDown} from '@pah/app-dropdown';
import {EditableDBItem} from '@nu-art/thunderstorm/frontend';


type Props = {
	editable: EditableDBItem<DB_ProductTemplate>
};

type State = {
	editable: EditableDBItem<DB_ProductTemplate>
};

export class Component_ProductOverview
	extends ComponentAsync<Props, State> {

	protected async deriveStateFromProps(nextProps: Props): Promise<State> {
		return {editable: nextProps.editable};
	}

	shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
		return super.shouldComponentUpdate(nextProps, nextState, nextContext) || this.props.editable !== nextProps.editable;
	}

	render() {
		const editable = this.props.editable;
		const template = editable.item;
		const tagIds = template.tagIds || (template.tagIds = []);
		return <LL_H_C className="product-template__overview">
			<LL_V_L className="product-template__general-props">
				<Renderer_BaseProduct editable={editable}/>
				<TS_PropRenderer.Vertical label="Vendor">
					<DropDown.Vendor
						selected={template.vendorId}
						onSelected={async vendor => {
							await editable.update('vendorId', vendor._id);
							this.forceUpdate();
						}}/>
				</TS_PropRenderer.Vertical>
				<LL_H_C className="ts-props-renderer__horizontal-chain">
					<TS_PropRenderer.Vertical label="Product Name">
						<TS_Input
							type="text"
							placeholder="product label"
							value={template.label || ''}
							onAccept={async (value: string) => {
								await editable.update('label', value);
								this.forceUpdate();
							}}/>
					</TS_PropRenderer.Vertical>
					<TS_PropRenderer.Vertical label="Price" className="width_25">
						<TS_Input
							type="number"
							className="align-center"
							placeholder={'Price'}
							value={`${roundNumber(template.price || 0, 2).toFixed(2)}`}
							onAccept={async (value: string) => {
								await editable.update('price', +value);
								this.forceUpdate();
							}}/>
					</TS_PropRenderer.Vertical>
				</LL_H_C>
				<LL_H_C className="ts-props-renderer__horizontal-chain">
					<TS_PropRenderer.Vertical label="Made in">
						<OtherDropDown.Country
							selected={template.countryOfOrigin}
							onSelected={async country => {
								await editable.update('countryOfOrigin', country);
								this.forceUpdate();
							}}/>
					</TS_PropRenderer.Vertical>
					<TS_PropRenderer.Vertical label="Taxable" className="width_25">
						<TS_Checkbox
							checked={template.taxable}
							onCheck={async taxable => {
								await editable.update('taxable', taxable);
								this.forceUpdate();
							}}>Taxable</TS_Checkbox>
					</TS_PropRenderer.Vertical>
				</LL_H_C>
				<TS_PropRenderer.Vertical label="Fulfillment Policy">
					<TS_Radio<FulfillmentPolicy>
						groupName={'ZEVEL'}
						checked={template.fulfillmentPolicy || (template.fulfillmentPolicy = 'manual')}
						values={FulfillmentPolicy_Values}
						onCheck={async (policy) => editable.update('fulfillmentPolicy', policy)}/>
				</TS_PropRenderer.Vertical>
				<TS_PropRenderer.Vertical label="Out of Stock Policy">
					<TS_Radio<OutOfStockPolicy>
						groupName={'ZEVEL'}
						checked={template.outOfStockPolicy || (template.outOfStockPolicy = 'deny')}
						values={OutOfStockPolicy_Values}
						onCheck={async (policy) => editable.update('outOfStockPolicy', policy)}/>
				</TS_PropRenderer.Vertical>
				<TS_PropRenderer.Vertical label="Tags">
					<LL_H_C className="ts-values-list">
						{tagIds.map(tagId => {
							const dbTag = ModuleFE_ProductTags.cache.unique(tagId);
							return <LL_H_C className="ts-values-list__value" key={tagId}>
								<ICONSV4.bin className="ts-icon__small" onClick={async () => {
									removeItemFromArray(tagIds, tagId);
									await editable.update('tagIds', tagIds);
									this.forceUpdate();
								}}/>
								<span>{dbTag?.title || 'not found'}</span>
							</LL_H_C>;
						})}
						<DropDown.ProductTag
							queryFilter={tag => !tagIds.includes(tag._id)}
							selected={undefined}
							onSelected={async (tag) => {
								addItemToArray(tagIds, tag._id);
								await editable.update('tagIds', tagIds);
								this.forceUpdate();
							}}
							placeholder={'Select Value'}
							noOptionsRenderer={'Create Tag'}
							onNoMatchingSelectionForString={async (tagLabel) => {
								const dbTag = await ModuleFE_ProductTags.v1.upsert({
									title: tagLabel,
									storeId: ModuleFE_Stores.getDefaultStore().storeId
								}).executeSync();
								addItemToArray(tagIds, dbTag._id);
								await editable.update('tagIds', tagIds);
								this.forceUpdate();
							}}
						/>
					</LL_H_C>
				</TS_PropRenderer.Vertical>
			</LL_V_L>
		</LL_H_C>;
	}
}

class Renderer_BaseProduct
	extends ComponentSync<Props, State & { isLoading?: boolean }> {
	constructor(p: Props) {
		super(p);
	}

	protected deriveStateFromProps(nextProps: Props): State {
		return {editable: nextProps.editable};
	}

	render() {
		return <TS_PropRenderer.Vertical label="Base Product">
			{this.renderBaseProduct()}
		</TS_PropRenderer.Vertical>;
	}

	private renderBaseProduct() {
		let placeholder = 'Select a product';
		let baseProductId = this.props.editable.item.baseProductId;
		let product;
		if (!baseProductId)
			placeholder = 'No Base Product';
		else {
			product = ModuleFE_Products.cache.unique(baseProductId);
			if (!placeholder) {
				placeholder = 'Missing Base Product';
				baseProductId = undefined;
			}
		}

		const syncIcon = <ICONSV4.sync
			className={_className('ts-icon margin__start', baseProductId ?? 'disabled')}
			onClick={async () => {
				const productId = baseProductId;
				if (!productId)
					return ModuleFE_Toaster.toastError('No product to sync');

				this.setState({isLoading: true}, async () => {
					await ModuleFE_ProductTemplate._v1.productToTemplate({
						productId,
						templateId: this.props.editable.item._id,
						storeId: ModuleFE_Stores.getDefaultStore()._id
					}).executeSync();

					if (!this.mounted)
						return;

					this.setState({isLoading: false});
				});
			}}/>;

		return <LL_H_C className="match_width flex__space-between">
			<DropDown.Product
				placeholder={placeholder}
				selected={product}
				onSelected={async product => {
					await this.props.editable.update('baseProductId', product._id);
				}}/>
			{syncIcon}
		</LL_H_C>;
	}

}