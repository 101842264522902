"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog_TranslationProject = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
require("./Dialog_TranslationProject.scss");
const dropdown_1 = require("../../component/dropdown");
const FormV3_1 = require("@nu-art/thunderstorm/frontend/components/formv3/FormV3");
class Dialog_TranslationProject extends frontend_1.TS_Dialog {
    constructor(p) {
        super(p);
        this.renderHeader = () => React.createElement("div", null, "Dialog TranslationProject Header");
        this.renderBody = () => {
            return React.createElement(ProjectFormEditor, { editable: this.props.editable });
        };
        this.buttons = () => {
            return {
                right: [{
                        content: 'Todo',
                        associatedKeys: ['enter'],
                        renderer: frontend_1.TS_Dialog.busyButton,
                        onClick: async () => {
                            frontend_1.ModuleFE_Toaster.toastInfo('Hmmm... what should this button do in Dialog_TranslationProject??', 10000);
                            this.closeDialog();
                        },
                    }]
            };
        };
        this.state = {};
    }
    static show(editable) {
        frontend_1.ModuleFE_Dialog.show(React.createElement(Dialog_TranslationProject, { editable: editable }));
    }
}
Dialog_TranslationProject.defaultProps = {
    dialogId: `dialog-TranslationProject`,
    className: `dialog-TranslationProject`
};
exports.Dialog_TranslationProject = Dialog_TranslationProject;
class ProjectFormEditor extends FormV3_1.Component_FormV3 {
}
ProjectFormEditor.defaultProps = {
    renderers: {
        title: { label: 'Title', editor: dropdown_1.Translation_Inputs.Text },
        description: { label: 'Description', editor: dropdown_1.Translation_Inputs.TextArea },
        originLocalId: { label: 'Origin Locale', editor: dropdown_1.Translation_DropDown.LanguageLocal.editable },
        clientId: { label: 'Client', editor: dropdown_1.Translation_DropDown.Client.editable },
        targetLocalIds: { label: 'Target Locales', editor: dropdown_1.Translation_MultiSelect.LanguageLocal },
        quality: { label: 'Quality', editor: dropdown_1.Translation_DropDown.Quality },
    }
};
