import * as React from 'react';
import {DB_ProductVariantConfig} from '@app/shared';
import {LL_V_L} from '@nu-art/thunderstorm/frontend';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_VariantOptions} from '@modules/shopify';
import {sortArray} from '@nu-art/ts-common';
import {MultiSelect} from '@pah/AppMultiSelect/AppMultiSelect';


export class EditorItem_ProductVariantConfig
	extends Item_Editor<DB_ProductVariantConfig> {

	render() {
		console.log(this.props.editable.item);
		const optionIds = this.props.editable.item.optionIds || [];
		const options = ModuleFE_VariantOptions.cache.filter(v => optionIds.includes(v._id));
		sortArray(options, item => {
			const matches = item.label.toLowerCase().match(/(\d+).*([my])/);
			if (!matches || !matches?.[1] || !matches?.[2])
				return item.label;

			return +matches[1] * (matches[2] === 'm' ? 1 : 12);
		});

		return <LL_V_L className="flex__grow match_height margin__inline">
			{this.input('label').vertical('Title')}
			<MultiSelect.Vendor editable={this.props.editable} prop={'vendorIds'}/>
			<LL_V_L>
				{options.map(option => <div key={option._id}>{option.label}</div>)}
			</LL_V_L>
		</LL_V_L>;
	}
}
