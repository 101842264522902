"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Product = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const validators_1 = require("../../validators");
const types_1 = require("../types");
const Validator_Product = Object.assign(Object.assign({}, types_1.ShopifyItem_validator), { label: ts_common_1.tsValidateOptional, title: validators_1.tsValidateStringOneLine, description: (0, ts_common_1.tsValidateString)(-1), properties: ts_common_1.tsValidateOptional, price: ts_common_1.tsValidateOptional, sku: ts_common_1.tsValidateOptional, taxable: ts_common_1.tsValidateOptional, tagIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false), variantIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId), imageIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false), vendorId: validators_1.tsValidateStringOneLine, fulfillmentPolicy: (0, ts_common_1.tsValidateValue)(['manual', 'automatic']), outOfStockPolicy: (0, ts_common_1.tsValidateValue)(['continue', 'deny']) });
exports.DBDef_Product = {
    validator: Validator_Product,
    dbName: 'products',
    entityName: 'product',
    uniqueKeys: ['storeId', 'shopifyId'],
    versions: ['1.0.2', '1.0.1', '1.0.0']
};
