import DatePicker from 'react-datepicker';
import {currentTimeMillis, formatTimestamp, parseTimeString} from '@nu-art/ts-common';
import * as React from 'react';
import {ExpectNumericProp} from '@consts/tools';


const Format_DateByDayPattern = 'DD/MM/YYYY';

export const DatePropertyEditor = <T extends any, K extends keyof T = keyof T, _K extends ExpectNumericProp<T, K> = ExpectNumericProp<T, K>>(item: T, prop: _K, onChange?: (item: T, key: keyof T) => void) => {
	return DateEditor(item[prop] as unknown as number || currentTimeMillis(), newDateInMillis => {
		item[prop] = newDateInMillis as unknown as T[_K];
		onChange?.(item, prop);
	});
};

export const DateEditor = (newDateInMillis: number, onChange: (newDateInMillis: number) => void) => {
	return <DatePicker selected={new Date(newDateInMillis)}
										 dateFormat="dd-MM-yyyy" onChange={(date: Date) => {
		const timestampString = formatTimestamp(Format_DateByDayPattern, date.getTime());
		onChange(parseTimeString(timestampString, Format_DateByDayPattern));
	}}/>;
};

export const normalizeDateByFormat = (timeAsMillis: number, pattern = Format_DateByDayPattern) => {
	return parseTimeString(formatTimestamp(pattern, timeAsMillis), pattern);
};