import {_className, ComponentSync, Header, LL_H_C, LL_V_C, LL_VH_C, TableHeaders, TS_Checkbox, TS_Input, TS_Table} from '@nu-art/thunderstorm/frontend';
import * as React from 'react';
import {DB_ProductTemplate, DB_ProductVariantConfig, TemplateVariant} from '@app/shared';
import {EditableDBItem} from '@nu-art/thunderstorm/frontend';
import {DropDown, OtherDropDown} from '@pah/app-dropdown';
import {ModuleFE_ProductTemplate, ModuleFE_ProductVariantConfigs} from '@modules/shopify';
import {ModuleFE_Stores} from '@modules/store';
import {addItemToArrayAtIndex, exists, removeFromArrayByIndex} from '@nu-art/ts-common';
import {ICONSV4} from '@res/icons';


type Props = {
	editable: EditableDBItem<DB_ProductTemplate>
};

type AllHeaders = TableHeaders<TemplateVariant, 'variant-1' | 'variant-2' | 'variant-3' | 'bin'>;
type State = {
	tableHeaders: AllHeaders
	editable: EditableDBItem<DB_ProductTemplate>
	new: Partial<TemplateVariant>
};

const variantHeaders: Header<AllHeaders>[] = [{header: 'variant-1', widthPx: 100}, {header: 'variant-2', widthPx: 100}, {header: 'variant-3', widthPx: 100}];
const Header_SKU: Header<AllHeaders> = {header: 'sku', widthPx: 150};
const Header_Barcode: Header<AllHeaders> = {header: 'barcode', widthPx: 150};
const Header_Country: Header<AllHeaders> = {header: 'countryOfOrigin', widthPx: 250};

// @ts-ignore
const allHeaders: AllHeaders = ['bin', 'dbId', Header_Country, Header_SKU, Header_Barcode, 'price', 'taxable', 'weight'];
const initialHeaders: AllHeaders = ['bin', 'dbId', Header_Country, Header_SKU, Header_Barcode];

export class Component_ProductTemplateVariants
	extends ComponentSync<Props, State> {

	protected deriveStateFromProps(nextProps: Props): State {
		let headers: AllHeaders = initialHeaders;
		const variantConfigIds = nextProps.editable.item.variantConfigIds;
		if (variantConfigIds)
			headers = [...headers, ...variantConfigIds.map((c, i) => variantHeaders[i])];
		return {
			tableHeaders: headers,
			editable: nextProps.editable,
			new: this.state?.new || {variantOptionIds: []}
		};
	}

	render() {
		const editable = this.state.editable;
		const template = editable.item;
		const rows = template.variants || (template.variants = []);
		const finalRows = [this.state.new as TemplateVariant, ...rows];
		return <LL_H_C className="product-template__variants">
			<TS_Table<TemplateVariant, 'variant-1' | 'variant-2' | 'variant-3' | 'bin'>
				header={this.state.tableHeaders}
				rows={finalRows}
				headerRenderer={(prop) => {
					let config: DB_ProductVariantConfig | undefined;
					switch (prop) {
						case 'bin':
							return '';

						case 'dbId':
							return 'Status';

						case 'countryOfOrigin':
							return 'Made in';

						case 'barcode':
							return 'Barcode';

						case 'sku':
							return 'SKU';

						case 'taxable':
							return 'Taxable';

						case 'price':
							return 'Price';

						case 'weight':
							return 'Weight';

						case 'variant-1':
							config = ModuleFE_ProductVariantConfigs.cache.unique(template.variantConfigIds![0]);
							break;
						case 'variant-2':
							config = ModuleFE_ProductVariantConfigs.cache.unique(template.variantConfigIds![1]);
							break;
						case 'variant-3':
							config = ModuleFE_ProductVariantConfigs.cache.unique(template.variantConfigIds![2]);
							break;
					}

					if (config) {
						return <LL_VH_C>
							<LL_H_C>{config?.label} <TS_Checkbox className="margin__start" checked={config?.isMain} onCheck={async () => {
								config!.isMain = !config!.isMain;
								await ModuleFE_ProductVariantConfigs.v1.upsert(config!).executeSync();
								await ModuleFE_ProductTemplate._v1.productToTemplate({
									productId: template.baseProductId!,
									templateId: template._id,
									storeId: ModuleFE_Stores.getDefaultStore()._id
								}).executeSync();
							}}/>
							</LL_H_C>
						</LL_VH_C>;
					}
				}}
				tr={row => ({className: _className(row === this.state.new && 'ts-table--editing-row')})}
				th={{className: 'padding__end padding__start align-center'}}
				td={{className: 'align-center'}}
				cellRenderer={(prop, item, index) => {
					let variantOptionIndex: number | undefined;
					const save = async () => {
						await editable.update('variants', rows);
						this.forceUpdate();
					};
					switch (prop) {
						case 'bin':
							if (this.state.new === item)
								return <ICONSV4.save onClick={async () => {
									addItemToArrayAtIndex(rows, this.state.new, 0);
									this.setState({new: {variantOptionIds: []}});
									await editable.update('variants', rows);
								}}/>;
							return <ICONSV4.bin onClick={async () => {
								removeFromArrayByIndex(rows, index - 1);
								await editable.update('variants', rows);
							}}/>;
							return;

						case 'dbId':
							return <LL_V_C>{!item[prop] ? 'New' : 'Exists'}</LL_V_C>;

						case 'countryOfOrigin':
							return <OtherDropDown.Country
								placeholder={template.countryOfOrigin ? 'From Product' : 'Select Origin'}
								selected={item[prop]}
								onSelected={async country => {
									item[prop] = country;
									return save();
								}}/>;
						case 'barcode':
						case 'sku':
							return <TS_Input
								className="align-center"
								type="text"
								value={item[prop]}
								onAccept={(value) => {
									item[prop] = value;
									return save();
								}}
							/>;

						case 'price':
							return <TS_Input
								className="align-center"
								type="number"
								value={String(item[prop])}
								onAccept={(value) => {
									item[prop] = +value;
									return save();
								}}
							/>;

						case 'weight':
						case 'taxable':
							return 'not implemented';

						case 'variant-1':
							variantOptionIndex = 0;
							break;

						case 'variant-2':
							variantOptionIndex = 1;
							break;
						case 'variant-3':
							variantOptionIndex = 2;
							break;
					}

					if (exists(variantOptionIndex)) {
						const index = variantOptionIndex!;
						const optionIds = ModuleFE_ProductVariantConfigs.cache.unique(template.variantConfigIds?.[index])?.optionIds;
						if (!optionIds)
							return 'Bad Config';

						return <DropDown.VariantOptions
							selected={item.variantOptionIds[index]}
							queryFilter={(item) => !!optionIds?.includes(item._id)}
							sortBy={[(item) => optionIds.indexOf(item._id)]}
							onSelected={(variantOption) => {
								item.variantOptionIds[index] = variantOption._id;
								return save();
							}}/>;
					}

					return `missing rendering for ${prop}`;
				}}
			/>
		</LL_H_C>;
	}
}

