"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_TranslationEntry = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const Validator_ModifiableProps = {
    resourceId: ts_common_1.tsValidateUniqueId,
    content: (0, ts_common_1.tsValidateString)(),
    localId: ts_common_1.tsValidateUniqueId,
    tagIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    uniqueKey: (0, ts_common_1.tsValidateString)(100),
};
const Validator_GeneratedProps = {
    _projectId: ts_common_1.tsValidateUniqueId
};
exports.DBDef_TranslationEntry = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbName: 'translation-entry',
    entityName: 'translation-entry',
};
