import { ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';

import {apiWithQuery, ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {ApiDef_StoreAPIs, ApiStruct_StoreAPIs, DB_Store, DBDef_Store,} from '@app/shared';
import {ApiDefCaller} from '@nu-art/thunderstorm';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';
import {ModuleFE_SyncManagerV2} from '@nu-art/thunderstorm/frontend/modules/sync-manager/ModuleFE_SyncManagerV2';


export interface OnStoresUpdated {
	__onStoresUpdated: (...params: ApiCallerEventType<DB_Store>) => void;
}

export const dispatch_onStoreListChanged = new ThunderDispatcher<OnStoresUpdated, '__onStoresUpdated'>('__onStoresUpdated');

export class ModuleFE_Stores_Class
	extends ModuleFE_BaseApi<DB_Store>
	implements ApiDefCaller<ApiStruct_StoreAPIs> {

	readonly _v1;
	store!: DB_Store;

	constructor() {
		super(DBDef_Store, dispatch_onStoreListChanged);

		this._v1 = {
			syncStore: apiWithQuery(ApiDef_StoreAPIs._v1.syncStore, this.onStoreSyncCompleted)
		} as ApiDefCaller<ApiStruct_StoreAPIs>['_v1'];
	}

	// __onLoginStatusUpdated;

	init() {
		this.runAsync('getting default store', async () => this.store = await this.getDefaultStore());
	}

	onStoreSyncCompleted = async () => {
		this.store = await this.getDefaultStore();
		return ModuleFE_SyncManagerV2.v1.checkSync().executeSync();
	};

	getDefaultStore() {
		return this.cache.all()[0];
	}

	getWorkingTranslationLocale() {
		return 'he';
	}
}

export const ModuleFE_Stores = new ModuleFE_Stores_Class();
