"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_ToolTip = void 0;
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const React = require("react");
require("./TS_ToolTip.scss");
const TS_MouseInteractivity_1 = require("../base/TS_MouseInteractivity");
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("../../../component-modules/mouse-interactivity/types");
const ModuleFE_MouseInteractivity_1 = require("../../../component-modules/mouse-interactivity/ModuleFE_MouseInteractivity");
class TS_ToolTip extends TS_MouseInteractivity_1.TS_MouseInteractivity {
    constructor() {
        super(...arguments);
        this.timeout = undefined;
        this.__onWindowResized = () => {
            var _a;
            if ((_a = this.state.model) === null || _a === void 0 ? void 0 : _a.id)
                ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.hide(types_1.mouseInteractivity_ToolTip);
        };
        this.__onToolTipDisplay = (model) => {
            var _a;
            //Clear timeout if one exists
            if (this.timeout)
                clearTimeout(this.timeout);
            //If a model is given
            if (model)
                return this.setState({ model, open: true });
            //Model is not given
            const allowContentHover = (_a = this.state.model) === null || _a === void 0 ? void 0 : _a.contentHoverDelay;
            if (allowContentHover)
                this.timeout = setTimeout(() => {
                    this.setState({ model: undefined, open: false });
                }, allowContentHover);
            else
                this.setState({ model: undefined, open: false });
        };
        this.onContentMouseEnter = () => {
            var _a;
            if (!((_a = this.state.model) === null || _a === void 0 ? void 0 : _a.contentHoverDelay))
                return;
            //Clear the timeout to stop hiding the content
            if (this.timeout)
                clearTimeout(this.timeout);
        };
        this.onContentMouseLeave = () => {
            ModuleFE_MouseInteractivity_1.ModuleFE_MouseInteractivity.hide(types_1.mouseInteractivity_ToolTip);
        };
    }
    render() {
        const { model, open } = this.state;
        if (!model || !open)
            return '';
        return React.createElement("div", { className: 'ts-tooltip__content', id: model.id, ref: this.ref, onMouseEnter: this.onContentMouseEnter, onMouseLeave: this.onContentMouseLeave, onMouseMove: this.onContentMouseEnter }, (0, ts_common_1.resolveContent)(model.content));
    }
}
exports.TS_ToolTip = TS_ToolTip;
