"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogClient_BaseRotate = void 0;
const LogClient_1 = require("./LogClient");
const utils_1 = require("./utils");
class LogClient_BaseRotate extends LogClient_1.LogClient {
    constructor(name, maxEntries = 10, maxSize = 1024 * 1024) {
        super();
        this.bufferSize = 0;
        this.name = name;
        this.maxSize = maxSize;
        this.maxEntries = maxEntries;
    }
    logMessage(level, bold, prefix, toLog) {
        const toLogAsString = (0, utils_1._logger_convertLogParamsToStrings)(toLog);
        this.rotate();
        for (const paramAsString of toLogAsString) {
            const log = paramAsString + "\n";
            this.printLogMessage(log);
            this.bufferSize += log.length;
        }
    }
    setRotationListener(rotationListener) {
        this.rotationListener = rotationListener;
        return this;
    }
    rotate() {
        var _a;
        if (this.bufferSize < this.maxSize)
            return;
        this.cleanup();
        for (let i = this.maxEntries - 1; i > 0; i--) {
            this.rotateBuffer(i - 1, i);
        }
        (_a = this.rotationListener) === null || _a === void 0 ? void 0 : _a.call(this);
        this.bufferSize = 0;
        this.prepare();
    }
}
exports.LogClient_BaseRotate = LogClient_BaseRotate;
