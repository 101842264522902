"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_AccessLevelsEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const ui_props_1 = require("../../ui-props");
const ts_styles_1 = require("@nu-art/ts-styles");
const ModuleFE_PermissionsDomain_1 = require("../../../modules/manage/ModuleFE_PermissionsDomain");
const ModuleFE_PermissionsAccessLevel_1 = require("../../../modules/manage/ModuleFE_PermissionsAccessLevel");
class Component_AccessLevelsEditor extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderLevelDropDown = () => {
            return React.createElement(ui_props_1.Permissions_DropDown.AccessLevel, { queryFilter: (item) => {
                    return !this.state.levels.map(i => i._id).includes(item._id);
                }, mapper: level => {
                    const domain = ModuleFE_PermissionsDomain_1.ModuleFE_PermissionsDomain.cache.unique(level.domainId);
                    return [domain.namespace, level.name, String(level.value)];
                }, className: 'access-levels-dropdown', selected: undefined, onSelected: async (level) => {
                    const levels = [...this.state.levels, level];
                    await this.props.permissionKey.set({ accessLevelIds: levels.map(ts_common_1.dbObjectToId) });
                    this.reDeriveState();
                }, placeholder: 'New Access Level', caret: {
                    open: React.createElement("div", { className: 'caret open' },
                        React.createElement(ts_styles_1.TS_Icons.treeCollapse.component, { style: { rotate: '90' } })),
                    close: React.createElement("div", { className: 'caret close' },
                        React.createElement(ts_styles_1.TS_Icons.treeCollapse.component, { style: { rotate: '-90' } })),
                }, boundingParentSelector: '.ts-tabs__content' });
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state !== null && state !== void 0 ? state : (state = this.state ? Object.assign({}, this.state) : {});
        const levelIds = (_a = nextProps.permissionKey.get().accessLevelIds) !== null && _a !== void 0 ? _a : [];
        state.levels = ModuleFE_PermissionsAccessLevel_1.ModuleFE_PermissionsAccessLevel.cache.filter(i => levelIds.includes(i._id));
        return state;
    }
    renderTag(level) {
        return React.createElement("div", { className: 'level', key: level._id },
            React.createElement(frontend_1.TS_Button, { onClick: async () => {
                    const levels = this.state.levels.filter(i => i._id !== level._id);
                    await this.props.permissionKey.set({ accessLevelIds: levels.map(ts_common_1.dbObjectToId) });
                    this.reDeriveState();
                } },
                React.createElement(ts_styles_1.TS_Icons.x.component, null)),
            React.createElement("span", null, level.name));
    }
    render() {
        return (React.createElement(frontend_1.LL_V_L, { className: 'access-levels-section' },
            React.createElement(frontend_1.LL_V_L, { className: 'access-levels' },
                this.state.levels.map(level => this.renderTag(level)),
                this.renderLevelDropDown())));
    }
}
exports.Component_AccessLevelsEditor = Component_AccessLevelsEditor;
