import {
	_className,
	ComponentSync,
	LL_H_C,
	LL_VH_C,
	Model_Menu,
	ModuleFE_MouseInteractivity,
	ModuleFE_Notifications,
	openContent,
	SimpleListAdapter,
	ThunderDispatcher
} from '@nu-art/thunderstorm/frontend';
import {IconKey, ICONSV4} from '@res/icons';
import {ModuleFE_Account, SessionKey_Account_FE} from '@nu-art/user-account/frontend';
import {LOCALE} from '@res/locale';
import * as React from 'react';
import {MouseEvent} from 'react';
import {dispatcher_onMenuButtonClicked, dispatcher_onToggleDebugVisibility, OnMenuButtonClicked} from '@consts/interfaces';
import {DeclaredStrings} from '@res/localization/AppLanguage';
import {ModuleFE_App, ModuleFE_Stores} from '@modules/index';
import {ModuleFE_PushPubSub} from '@nu-art/push-pub-sub/frontend';
import './Component_MainHeader.scss';
import {Minute} from '@nu-art/ts-common';
import {EventProcessor, Module_KeyBinder} from '@pah/customer-order/Page_CustomerOrder';
import {OnPageTitleChangedListener} from '@nu-art/thunderstorm/frontend/core/consts';


export type HeaderOption = {
	icon: IconKey | (() => React.ReactNode)
	tooltip?: (keyof DeclaredStrings | string) | (() => (keyof DeclaredStrings | string))
	condition?: (ev: KeyboardEvent | MouseEvent) => boolean
	action: (ev: KeyboardEvent | MouseEvent) => any
}

type Props = {}
type State = {
	title: string
	options: HeaderOption[]
}

export interface OnUpdateHeaderOptions {
	__updateHeaderOptions(options: HeaderOption[]): void;
}

export const dispatch_updateHeaderOption = new ThunderDispatcher<OnUpdateHeaderOptions, '__updateHeaderOptions'>('__updateHeaderOptions');
const menuOption: HeaderOption = {
	icon: 'menu',
	tooltip: 'Tooltip_ToggleMenuVisibility',
	action: () => {
		const isOpen = ModuleFE_App.sideBarIsOpen.get(false);
		ModuleFE_App.sideBarIsOpen.set(!isOpen);
		dispatcher_onMenuButtonClicked.dispatchUI();
	}
};
const showDebug: HeaderOption = {
	icon: 'debug',
	tooltip: 'Tooltip_ToggleDebugPanel',
	action: () => {
		dispatcher_onToggleDebugVisibility.dispatchUI();
	}
};
const syncShop: HeaderOption = {
	icon: 'sync',
	tooltip: 'Tooltip_SyncStore',
	action: () => {
		ModuleFE_Notifications
			.create()
			.content('Syncing with Shopify')
			.execute(async () => {
				const storeId = (await ModuleFE_Stores.getDefaultStore())?.storeId;
				return ModuleFE_Stores._v1.syncStore({storeId}).setTimeout(5 * Minute).executeSync();
			});
	}
};

export class Component_MainHeader
	extends ComponentSync<Props, State>
	implements OnPageTitleChangedListener, OnUpdateHeaderOptions, OnMenuButtonClicked {

	constructor(p: Props) {
		super(p);
	}

	protected deriveStateFromProps(nextProps: Props): State | undefined {
		return {title: '', options: [syncShop, showDebug]};
	}

	__onMenuButtonClicked() {
		this.forceUpdate();
	}

	__updateHeaderOptions(pageOptions: HeaderOption[]): void {
		this.setState((prevState) => {
			prevState.options.forEach(option => {
				if (!option.condition)
					return;

				Module_KeyBinder.unbind(option as EventProcessor);
			});

			const options = [syncShop, showDebug, ...pageOptions];
			options.forEach(option => {
				if (!option.condition)
					return;

				Module_KeyBinder.bind(option as EventProcessor);
			});

			return {options: options};
		});
	}

	__onPageTitleChanged(title: string) {
		this.setState({title});
	}

	render() {
		const isOpen = ModuleFE_App.sideBarIsOpen.get(false);
		return <LL_H_C className="main-header">

			<LL_H_C className="flex__grow">
				{this.renderAction(menuOption, 0, _className('main-header__sidebar-button', isOpen && 'open'))}
				{this.state.options.map((o, i) => this.renderAction(o, i + 1))}
			</LL_H_C>
			<div className="main-header__title flex__grow">{this.state.title}</div>

			<LL_H_C className="main-header__user flex__grow">
				<div>{ICONSV4.bell({
					onClick: async (e) => {
						await ModuleFE_PushPubSub.requestPermissions();
					}
				})}</div>
				{/*<PermissionsComponent url={'/v1/dev/migrate-to-env'}>*/}
				{/*	{ICONSV4.scrape({*/}
				{/*		onClick: async (e) => {*/}
				{/*			ModuleFE_Permissions.v1.registerProject({}).execute();*/}
				{/*		}*/}
				{/*	})}*/}
				{/*</PermissionsComponent>*/}
				<LL_H_C className="main-header__account" onClick={this.openMenu}
								onContextMenu={this.openMenu}>{SessionKey_Account_FE.get().displayName || SessionKey_Account_FE.get().email}</LL_H_C>
			</LL_H_C>
		</LL_H_C>;
	}

	private openMenu = (e: React.MouseEvent<HTMLSpanElement>) => {
		const menuItems: any[] = [
			{label: 'Logout', onClick: ModuleFE_Account.logout}
		];
		const userProfileMenu: Model_Menu = {
			id: 'user-thumbnail-menu',
			adapter: SimpleListAdapter(menuItems, renderer => <div>{renderer.item.label}</div>),
			offset: {},
			originPos: {x: 1, y: 0},
			modalPos: {y: 0, x: -1},

			onNodeClicked: (path: string, item: any) => {
				ModuleFE_MouseInteractivity.hide('pop-up');
				item.onClick?.();
			}
		};

		ModuleFE_MouseInteractivity.showMenu(userProfileMenu);
	};

	private renderAction = (option: HeaderOption, index: number, className?: string) => {
		const tooltipKey = !option.tooltip ? 'Tooltip_BeResponsible' : typeof option.tooltip === 'function' ? option.tooltip() : option.tooltip;

		let icon;
		if (typeof option.icon === 'function')
			icon = option.icon();
		else {
			const Icon = ICONSV4[option.icon];
			icon = <Icon/>;
		}

		return (
			<LL_VH_C
				className={_className('main-header__action-button', className)}
				onClick={option.action}
				key={index}
				{...openContent.tooltip.top(`action-${index}`, () => <div style={{margin: 4}}>{LOCALE[tooltipKey as keyof DeclaredStrings]?.() || tooltipKey}</div>)}>
				{icon}
			</LL_VH_C>
		);
	};
}