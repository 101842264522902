"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tsIconKeys = exports.TS_Icons = exports.iconsRenderer = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const icon__filter_svg_1 = require("./svgs/icon__filter.svg");
const icon__search_svg_1 = require("./svgs/icon__search.svg");
const icon__attention_svg_1 = require("./svgs/icon__attention.svg");
const icon__bell_svg_1 = require("./svgs/icon__bell.svg");
const icon__bin_svg_1 = require("./svgs/icon__bin.svg");
const icon__x_svg_1 = require("./svgs/icon__x.svg");
const icon__v_svg_1 = require("./svgs/icon__v.svg");
const icon__more_svg_1 = require("./svgs/icon__more.svg");
const icon__treeCollapse_svg_1 = require("./svgs/icon__treeCollapse.svg");
const icon__gear_svg_1 = require("./svgs/icon__gear.svg");
const icon__information_svg_1 = require("./svgs/icon__information.svg");
const icon__filter_stop_svg_1 = require("./svgs/icon__filter-stop.svg");
const icon__clear_svg_1 = require("./svgs/icon__clear.svg");
const icon__save_svg_1 = require("./svgs/icon__save.svg");
const icon__menu_svg_1 = require("./svgs/icon__menu.svg");
const icon__google_svg_1 = require("./svgs/icon__google.svg");
const icon__download_svg_1 = require("./svgs/icon__download.svg");
class RenderIcon extends React.Component {
    render() {
        var _a;
        const className = 'icon--wrapper ' + ((_a = this.props.className) !== null && _a !== void 0 ? _a : '');
        return React.createElement("div", Object.assign({}, this.props, { className: className, style: { WebkitMaskImage: `url(${this.props.icon})`, maskImage: `url(${this.props.icon})` } }));
    }
}
const iconsRenderer = (key, props) => {
    return React.createElement(RenderIcon, Object.assign({}, props, { icon: key.value }));
};
exports.iconsRenderer = iconsRenderer;
const genIcon = (Icon) => {
    return (props) => {
        var _a;
        return React.createElement("div", Object.assign({}, props, { className: 'icon--wrapper ' + ((_a = props.className) !== null && _a !== void 0 ? _a : '') }),
            React.createElement(Icon, null));
    };
};
exports.TS_Icons = {
    Filter: { component: genIcon(icon__filter_svg_1.ReactComponent), url: icon__filter_svg_1.default },
    Search: { component: genIcon(icon__search_svg_1.ReactComponent), url: icon__search_svg_1.default },
    attention: { component: genIcon(icon__attention_svg_1.ReactComponent), url: icon__attention_svg_1.default },
    bell: { component: genIcon(icon__bell_svg_1.ReactComponent), url: icon__bell_svg_1.default },
    bin: { component: genIcon(icon__bin_svg_1.ReactComponent), url: icon__bin_svg_1.default },
    more: { component: genIcon(icon__more_svg_1.ReactComponent), url: icon__more_svg_1.default },
    treeCollapse: { component: genIcon(icon__treeCollapse_svg_1.ReactComponent), url: icon__treeCollapse_svg_1.default },
    v: { component: genIcon(icon__v_svg_1.ReactComponent), url: icon__v_svg_1.default },
    x: { component: genIcon(icon__x_svg_1.ReactComponent), url: icon__x_svg_1.default },
    gear: { component: genIcon(icon__gear_svg_1.ReactComponent), url: icon__gear_svg_1.default },
    information: { component: genIcon(icon__information_svg_1.ReactComponent), url: icon__information_svg_1.default },
    filterStop: { component: genIcon(icon__filter_stop_svg_1.ReactComponent), url: icon__filter_stop_svg_1.default },
    clear: { component: genIcon(icon__clear_svg_1.ReactComponent), url: icon__clear_svg_1.default },
    save: { component: genIcon(icon__save_svg_1.ReactComponent), url: icon__save_svg_1.default },
    menu: { component: genIcon(icon__menu_svg_1.ReactComponent), url: icon__menu_svg_1.default },
    google: { component: genIcon(icon__google_svg_1.ReactComponent), url: icon__google_svg_1.default },
    download: { component: genIcon(icon__download_svg_1.ReactComponent), url: icon__download_svg_1.default }
};
const tsIconKeys = () => {
    return (0, ts_common_1._keys)(exports.TS_Icons);
};
exports.tsIconKeys = tsIconKeys;
