"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultLogPrefixComposer = exports._logger_getPrefix = exports._logger_finalDate = exports._logger_timezoneOffset = exports.LogClient = void 0;
const types_1 = require("./types");
class LogClient {
    constructor() {
        this.prefixComposer = exports.DefaultLogPrefixComposer;
        this.filter = () => true;
    }
    setComposer(logComposer) {
        this.prefixComposer = logComposer;
    }
    setFilter(filter) {
        this.filter = filter;
        return this;
    }
    log(tag, level, bold, toLog) {
        if (!this.filter(level, tag))
            return;
        this.logMessage(level, bold, this.prefixComposer(tag, level), toLog);
    }
}
exports.LogClient = LogClient;
exports._logger_timezoneOffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
exports._logger_finalDate = new Date();
function _logger_getPrefix(level) {
    switch (level) {
        case types_1.LogLevel.Verbose:
            return '-V-';
        case types_1.LogLevel.Debug:
            return '-D-';
        case types_1.LogLevel.Info:
            return '-I-';
        case types_1.LogLevel.Warning:
            return '-W-';
        case types_1.LogLevel.Error:
            return '-E-';
        default:
            return '---';
    }
}
exports._logger_getPrefix = _logger_getPrefix;
const DefaultLogPrefixComposer = (tag, level) => {
    exports._logger_finalDate.setTime(Date.now() - exports._logger_timezoneOffset);
    const date = exports._logger_finalDate.toISOString().replace(/T/, '_').replace(/Z/, '').substring(0, 23);
    return `  ${date} ${_logger_getPrefix(level)} ${tag}:  `;
};
exports.DefaultLogPrefixComposer = DefaultLogPrefixComposer;
