import {
	EditableDBItem,
	EventType_Create,
	EventType_Update,
	EventType_UpsertAll,
	ModuleFE_BaseApi,
	Props_SmartComponent,
	SmartPage,
	State_SmartComponent
} from '@nu-art/thunderstorm/frontend';
import {getQueryParameter, ModuleFE_RoutingV2, TS_Loader, TS_Route} from '@nu-art/thunderstorm/frontend';
import {_keys, DB_Object, Default_UniqueKey, IndexKeys, LogLevel, PreDB} from '@nu-art/ts-common';
import * as React from 'react';
import {dispatch_updateHeaderOption, HeaderOption} from '@page/Page_Workspace/Component_MainHeader';
import {resolveKeys} from './_tools';
import {QueryParams} from '@nu-art/thunderstorm';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';


export type Props_DBItemEditorPageV3<ItemType extends DB_Object, Ks extends keyof PreDB<ItemType> = Default_UniqueKey> = {
	keys: Ks[]
	pageTitle: string | (() => string)

	route: TS_Route

	moduleFE: ModuleFE_BaseApi<ItemType, Ks>
	headerButtons?: HeaderOption[]
	itemEditor: React.ElementType<{ editable: EditableDBItem<ItemType> }>
}

export type State_DBItemEditorPageV3<ItemType extends DB_Object, Ks extends keyof PreDB<ItemType> = Default_UniqueKey> = {
	keys: IndexKeys<ItemType, Ks>,
	editable: EditableDBItem<ItemType, Ks>
}

export abstract class Page_DBItemEditorV3<ItemType extends DB_Object,
	Ks extends keyof PreDB<ItemType> = Default_UniqueKey,
	S extends State_DBItemEditorPageV3<ItemType, Ks> = State_DBItemEditorPageV3<ItemType, Ks>,
	P extends Props_DBItemEditorPageV3<ItemType, Ks> = Props_DBItemEditorPageV3<ItemType, Ks>>
	extends SmartPage<P, S> {

	constructor(props: P) {
		super(props);
		this.logger.setMinLevel(LogLevel.Verbose);
		// @ts-ignore
		this[props.moduleFE.defaultDispatcher.method] = this.__onItemUpdated;
	}


	protected async deriveStateFromProps(nextProps: Props_SmartComponent & P, state: State_SmartComponent & S) {
		const keys = resolveKeys<ItemType, Ks>(nextProps.keys, (key: Ks) => getQueryParameter(key as string) as string);

		let item: ItemType | undefined;
		try {
			if (_keys(keys).length > 0)
				item = await this.props.moduleFE.cache.unique(keys);
		} catch (e: any) {
			this.logWarning(`error getting item from cache: `, e);
		}
		state.keys = keys;

		const onSave = (item: ItemType) => {
			this.reDeriveState({editable: this.state.editable.clone(item)} as State_SmartComponent & S);
		};
		const onError = (e: Error) => this.logError(e);
		state.editable = new EditableDBItem<ItemType, Ks>(item || await this.generateEmptyItem(), this.props.moduleFE, onSave, onError).setAutoSave(true);

		return state;
	}

	protected async generateEmptyItem(): Promise<ItemType> {
		return {} as ItemType;
	}

	private __onItemUpdated = (...params: ApiCallerEventType<ItemType>): void => {
		switch (params[0]) {
			case EventType_Create: {
				const item = params[1];
				const queryParams: QueryParams = resolveKeys<ItemType, Ks>(this.props.keys, (key) => item[key] as unknown as string) as unknown as QueryParams;
				return ModuleFE_RoutingV2.goToRoute(this.props.route, queryParams);
			}

			case EventType_UpsertAll:
			case EventType_Update:
				return this.reDeriveState({editable: this.state.editable.clone()} as State_SmartComponent & S);

			// case EventType_Delete:
			// 	return ModuleFE_RoutingV2.goToRoute(this.props.parentRoute);
		}
	};

	componentWillUnmount() {
	}

	componentDidMount() {
		const headerButtons: HeaderOption[] | undefined = this.props.headerButtons;
		if (headerButtons)
			dispatch_updateHeaderOption.dispatchUI(headerButtons);
	}

	render() {
		const editable = this.state.editable;
		if (!editable)
			return <TS_Loader/>;

		return this.renderEditor(editable);
	}

	renderEditor(editable: EditableDBItem<ItemType>) {
		const ItemEditor: React.ElementType<{ editable: EditableDBItem<ItemType> }> = this.props.itemEditor;
		if (!ItemEditor)
			return 'This editor class should override the renderEditor method!';

		return <ItemEditor editable={editable}/>;
	}
}