"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_Form = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
class Component_Form extends React.Component {
    constructor(p) {
        super(p);
        this.onValueChanged = (value, id) => {
            this.setState(state => {
                state.value[id] = value;
                return state;
            });
        };
        this.state = { value: p.value };
    }
    render() {
        const data = this.state.value;
        return (React.createElement("div", { className: `ll_v_c ${this.props.className}`, style: { justifyContent: 'space-evenly' } }, (0, ts_common_1._keys)(this.props.form).map(key => this.renderField(data, key))));
    }
    renderField(data, key) {
        var _a;
        const field = this.props.form[key];
        const fieldProps = {
            key,
            field,
            value: data[key],
            onChange: this.onValueChanged,
            showErrors: this.props.showErrors,
            validator: (_a = this.props.validator) === null || _a === void 0 ? void 0 : _a[key],
            onAccept: () => {
                this.props.onAccept(this.state.value);
            }
        };
        return this.props.renderer[key](fieldProps);
    }
}
exports.Component_Form = Component_Form;
