import {
	AllTypesDB,
	CONST_CountryCodes,
	CONST_KnownCsvParserTypes,
	Country,
	Currency,
	DB_Courier,
	DB_OrderShippingMethod,
	DB_Payment,
	DB_Product,
	DB_ProductTag,
	DB_TranslationEntry,
	DB_VariantOption,
	DB_Vendor,
	OtherTypes,
	PackageType_Size,
	VariantParserType
} from '@app/shared';
import {ModuleFE_Products_, ModuleFE_ProductTags_, ModuleFE_ShippingMethod_, ModuleFE_VariantOptions_, ModuleFE_Vendors_} from '@modules/shopify';
import {GenericDropDown, MandatoryProps_GenericDropDown, ModuleFE_BaseApi, PartialProps_GenericDropDown} from '@nu-art/thunderstorm/frontend';
import {_values, addItemToArrayAtIndex, DB_Object, Filter, filterDuplicates, sortArray} from '@nu-art/ts-common';
import React from 'react';
import {ModuleFE_Couriers, ModuleFE_Payments} from '@modules/store';
import {ModuleFE_Translations} from '@modules/ModuleFE_Translations';
import {PartialProps_DropDown, SimpleListAdapter, StorageKey, TS_DropDown} from '@nu-art/thunderstorm/frontend';
import {DropDown_AutoComplete} from '@app/tools/auto-complete/frontend/ui/DropDown_AutoComplete';


const PopularitySorter = <T extends any>(key: string, items: T[], mapper: (item: T) => string = String) => {
	const list = new StorageKey<string[]>(key).get([]);
	return sortArray([...items], (item) => {
		const index = list.indexOf(mapper(item));
		if (index === -1)
			return list.length;

		return index;
	});
};

const PopularitySorterSave = <T extends any>(key: string, item: T, mapper: (item: T) => string = String) => {
	const storageKey = new StorageKey<string[]>(key);
	const list = storageKey.get([]);
	addItemToArrayAtIndex(list, mapper(item), 0);
	storageKey.set(filterDuplicates(list));
};

const Props_Product: MandatoryProps_GenericDropDown<DB_Product> = {
	module: ModuleFE_Products_,
	modules: [ModuleFE_Products_],
	mapper: item => [item.title],
	placeholder: 'Choose a Product',
	renderer: item => <div className="ll_h_c">{item?.title || 'no item'}</div>
};

const Props_Vendor: MandatoryProps_GenericDropDown<DB_Vendor> = {
	module: ModuleFE_Vendors_,
	modules: [ModuleFE_Vendors_],
	mapper: item => [item.title],
	placeholder: 'Choose a Vendor',
	renderer: item => <div className="ll_h_c">{item.title}</div>
};

const Props_Payment: MandatoryProps_GenericDropDown<DB_Payment> = {
	module: ModuleFE_Payments,
	modules: [ModuleFE_Payments] as unknown as ModuleFE_BaseApi<DB_Object, any>[],
	mapper: item => [item.description, `${item.value}`],
	placeholder: 'Choose a Payment',
	renderer: item => <div className="ll_h_c">{item.description}</div>
};

const Props_Courier: MandatoryProps_GenericDropDown<DB_Courier> = {
	module: ModuleFE_Couriers,
	modules: [ModuleFE_Couriers] as unknown as ModuleFE_BaseApi<DB_Object, any>[],
	mapper: item => [item.title],
	placeholder: 'Choose a Courier',
	renderer: item => <div className="ll_h_c">{item.title}</div>
};

const Props_ProductTag: MandatoryProps_GenericDropDown<DB_ProductTag> = {
	module: ModuleFE_ProductTags_,
	modules: [ModuleFE_ProductTags_],
	mapper: item => [item.title],
	placeholder: 'Choose a Product Tag',
	renderer: item => <div className="ll_h_c">{item.title}</div>
};

const Props_Translation: MandatoryProps_GenericDropDown<DB_TranslationEntry, 'sharedId' | 'locale'> = {
	module: ModuleFE_Translations,
	modules: [ModuleFE_Translations] as unknown as ModuleFE_BaseApi<DB_Object, any>[],
	mapper: item => [item.content],
	placeholder: 'Select matching translation',
	renderer: item => <div className="ll_h_c">{item.content}</div>
};
const Props_ShippingMethod: MandatoryProps_GenericDropDown<DB_OrderShippingMethod> = {
	module: ModuleFE_ShippingMethod_,
	modules: [ModuleFE_ShippingMethod_],
	mapper: item => [item.label],
	placeholder: 'Select shipping method',
	renderer: item => <div className="ll_h_c">{item.label}</div>
};

const Props_VariantOption: MandatoryProps_GenericDropDown<DB_VariantOption> = {
	module: ModuleFE_VariantOptions_,
	modules: [ModuleFE_VariantOptions_],
	mapper: item => [item.label],
	placeholder: ' --- ',
	renderer: item => <div className="ll_h_c">{item.label}</div>
};

const LastUsedCurrency = new StorageKey<Currency>('last-user-currency');

const Props_Currency = {
	adapter: SimpleListAdapter(['EUR', 'USD', 'ILS'], item => <div>{item.item}</div>),
	placeholder: 'Select Currency',
};

const Props_VariantParser = {
	adapter: SimpleListAdapter(CONST_KnownCsvParserTypes, item => <div>{item.item}</div>),
	placeholder: 'Select Parser',
};

const Props_PackageType = {
	adapter: SimpleListAdapter([PackageType_Size.Envelope, PackageType_Size.Small, PackageType_Size.Medium, PackageType_Size.Large], item =>
		<div>{PackageType_Size[item.item]}</div>),
	placeholder: 'Package Size',
};

const Props_Country = () => ({
	adapter: SimpleListAdapter(PopularitySorter('dropdown-country', _values(CONST_CountryCodes)), item =>
		<div>{item.item}</div>),
	filter: new Filter<Country>(item => [item]),
	placeholder: 'Select a Country',
});

type PP_GDD<T> = PartialProps_GenericDropDown<T>

export const DropDown: { [K in keyof AllTypesDB]: ((props: PP_GDD<AllTypesDB[K]>) => JSX.Element) } = {
	Product: (props: PP_GDD<DB_Product>) => <GenericDropDown<DB_Product> {...Props_Product} {...props} />,
	ProductTag: (props: PP_GDD<DB_ProductTag>) => <GenericDropDown<DB_ProductTag> {...Props_ProductTag} {...props} />,
	Vendor: (props: PP_GDD<DB_Vendor>) => <GenericDropDown<DB_Vendor> {...Props_Vendor} {...props} />,
	Payment: (props: PP_GDD<DB_Payment>) => <GenericDropDown<DB_Payment> {...Props_Payment} {...props} />,
	Courier: (props: PP_GDD<DB_Courier>) => <GenericDropDown<DB_Courier> {...Props_Courier} {...props} />,
	Translation: (props: PP_GDD<DB_TranslationEntry>) => <GenericDropDown<DB_TranslationEntry, 'sharedId' | 'locale'> {...Props_Translation} {...props} />,
	ShippingMethod: (props: PP_GDD<DB_OrderShippingMethod>) => <GenericDropDown<DB_OrderShippingMethod> {...Props_ShippingMethod} {...props} />,
	VariantOptions: (props: PP_GDD<DB_VariantOption>) => <GenericDropDown<DB_VariantOption> {...Props_VariantOption} {...props} />,
	...DropDown_AutoComplete
};

type PP_DD<T> = PartialProps_DropDown<T>
export const OtherDropDown: { [K in keyof OtherTypes]: ((props: PP_DD<OtherTypes[K]>) => JSX.Element) } = {
	VariantParser: (props: PP_DD<VariantParserType>) => <TS_DropDown<VariantParserType> {...Props_VariantParser} {...props} />,
	Currency: (props: PP_DD<Currency>) => <TS_DropDown<Currency> {...Props_Currency} selected={LastUsedCurrency.get('EUR')} {...props} />,
	PackageSize: (props: PP_DD<PackageType_Size>) => <TS_DropDown<PackageType_Size> {...Props_PackageType} {...props} />,
	Country: (props: PP_DD<Country>) => <TS_DropDown<Country> {...Props_Country()} {...props}
																														onSelected={(country) => {
																															PopularitySorterSave('dropdown-country', country);
																															props.onSelected(country);
																														}}
	/>,
};

