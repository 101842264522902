import * as React from 'react';
import {DB_ProductTemplate} from '@app/shared';
import {LL_H_T, LL_V_L, LL_VH_C, TS_Space, TS_Tabs} from '@nu-art/thunderstorm/frontend';
import './_style.scss';
import {Component_ProductOverview} from './components/Component_ProductOverview';
import {Component_ProductInstances} from './components/Component_ProductInstances';
import {Component_ProductTemplateVariants} from './components/Component_ProductTemplateVariants';
import {Item_Editor} from '@nu-art/thunderstorm/frontend';


export class EditorItem_ProductTemplate
	extends Item_Editor<DB_ProductTemplate> {

	render(): React.ReactNode {
		const editable = this.props.editable;
		if (editable.item.sku === '')
			delete editable.item.sku;

		// const originLocale = this.state.originLocale;
		// const targetLocal = ModuleFE_Stores.getWorkingTranslationLocale();
		// const variantIds = item.variantIds || (item.variantIds = []);
		return <LL_H_T className="workspace-page product-template-v2">
			<Component_ProductOverview editable={editable}/>
			<TS_Space width={2}/>
			{editable.item.baseProductId ? this.renderTabs() : <LL_VH_C> MUST Select Base Product to Continue</LL_VH_C>}
			{/*<Component_ProductImages imageIds={item.imageIds || (item.imageIds = [])} onChanged={this.onPropsChanged}/>*/}
		</LL_H_T>;
	}

	renderTabs() {
		const tabs = [
			{
				uid: 'variants',
				title: 'Variants',
				content: () => <Component_ProductTemplateVariants editable={this.props.editable}/>
			}, {
				uid: 'instances',
				title: 'Instances',
				content: () => <Component_ProductInstances editable={this.props.editable}/>
			},

		];

		return <LL_V_L>
			<TS_Tabs tabs={tabs}/>
		</LL_V_L>;
	}
}

// <Component_ProductOverview editable={editable}/>