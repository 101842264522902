"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultRouteNode = exports.defaultLinkNode = exports.defaultNavLinkNode = exports.RoutePath = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const ReactEntryComponentInjector_1 = require("../component-loader/ReactEntryComponentInjector");
class RoutePath {
    constructor(key, route, component) {
        this.exact = false;
        this.visible = () => !!this.label;
        this.key = key;
        this.path = route;
        this.component = component;
    }
    setLogMessage(logMessage) {
        // @ts-ignore
        this.logMessage = logMessage;
        return this;
    }
    setLabel(label) {
        // @ts-ignore
        this.label = label;
        return this;
    }
    setVisible(visible) {
        // @ts-ignore
        this.visible = visible;
        return this;
    }
    setEnabled(enabled) {
        // @ts-ignore
        this.enabled = enabled;
        return this;
    }
    setExact(exact) {
        // @ts-ignore
        this.exact = exact;
        return this;
    }
    compose(params) {
        return (0, ts_common_1.composeUrl)(this.path, params);
    }
}
exports.RoutePath = RoutePath;
const getNavStyles = (props) => {
    return {
        color: props.isActive ? 'blue' : undefined,
    };
};
const defaultNavLinkNode = (route) => {
    return React.createElement(react_router_dom_1.NavLink, { key: route.key, to: route.path, style: getNavStyles }, route.label);
};
exports.defaultNavLinkNode = defaultNavLinkNode;
const defaultLinkNode = (route, node) => {
    return React.createElement(react_router_dom_1.Link, { key: route.key, to: route.path }, node || route.label || route.key);
};
exports.defaultLinkNode = defaultLinkNode;
const defaultRouteNode = (route) => {
    if (typeof route.component === 'string')
        return React.createElement(ReactEntryComponentInjector_1.ReactEntryComponentInjector, { src: route.component });
    const Component = route.component;
    return React.createElement(react_router_dom_1.Route, { key: route.key, path: route.path, element: React.createElement(Component, null) });
};
exports.defaultRouteNode = defaultRouteNode;
