"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.performAction = void 0;
const React = require("react");
const TS_Notifications_1 = require("../../components/TS_Notifications");
const ModuleFE_Toaster_1 = require("../../component-modules/ModuleFE_Toaster");
const genereteToasts_1 = require("./genereteToasts");
const Default_Toast_Duration = 4000;
const Successful_Action_Default_Label = 'Action Preformed Successfully';
const Failed_Action_Default_Label = 'Action Failed';
/**
 * #performAction
 * use this function to execute any async action that requires a feedback.
 * @param action the action to execute.
 * @param feedbackOptions feedback options to determine which kind of feedback to present
 * @param additionalData !Optional! any additional data the notification/toast might need
 * @param throwBackError !Optional! if true, will throw error back outside the action
 */
async function performAction(action, feedbackOptions, additionalData, throwBackError = false) {
    var _a, _b, _c;
    switch (feedbackOptions.type) {
        case 'notification':
            if (feedbackOptions.notificationLabels) {
                await (0, TS_Notifications_1.genericNotificationAction)(action, feedbackOptions.notificationLabels);
            }
            else {
                console.error('cannot use notification as feedback without labels');
            }
            break;
        case 'toast':
            try {
                const content = (_a = feedbackOptions.successContent) !== null && _a !== void 0 ? _a : React.createElement("div", null, Successful_Action_Default_Label);
                await action();
                new ModuleFE_Toaster_1.ToastBuilder()
                    .setContent(content)
                    .setDuration((_b = feedbackOptions.duration) !== null && _b !== void 0 ? _b : Default_Toast_Duration)
                    .show();
            }
            catch (err) {
                const content = (_c = feedbackOptions.failContent) !== null && _c !== void 0 ? _c : React.createElement("div", null, Failed_Action_Default_Label);
                new ModuleFE_Toaster_1.ToastBuilder()
                    .setContent((0, genereteToasts_1.generateErrorToastContent)(err, content, additionalData))
                    .setDuration(feedbackOptions.duration ? feedbackOptions.duration : Default_Toast_Duration).show();
                if (throwBackError)
                    throw err;
            }
    }
}
exports.performAction = performAction;
