"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_MouseInteractivity = exports.ModuleFE_MouseInteractivity_Class = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const thunder_dispatcher_1 = require("../../core/thunder-dispatcher");
const TS_Tree_1 = require("../../components/TS_Tree");
const showPopUp = new thunder_dispatcher_1.ThunderDispatcher('__onPopUpDisplay');
const showToolTip = new thunder_dispatcher_1.ThunderDispatcher('__onToolTipDisplay');
class ModuleFE_MouseInteractivity_Class extends ts_common_1.Module {
    constructor() {
        super(...arguments);
        this.showMenu = (model) => {
            const content = React.createElement(TS_Tree_1.TS_Tree, { className: 'ts-popup__content__menu', id: (0, ts_common_1.generateHex)(8), adapter: model.adapter, onNodeClicked: model.onNodeClicked });
            this.showContent({
                id: model.id,
                content,
                originPos: model.originPos,
                modalPos: model.modalPos,
                offset: model.offset,
            });
        };
        this.showContent = (model) => {
            showPopUp.dispatchUI(model);
        };
        this.showTooltip = (model) => {
            showToolTip.dispatchUI(model);
        };
        this.hide = (type) => {
            switch (type) {
                case 'pop-up':
                    showPopUp.dispatchUI();
                    break;
                case 'tool-tip':
                    showToolTip.dispatchUI();
                    break;
            }
        };
    }
}
exports.ModuleFE_MouseInteractivity_Class = ModuleFE_MouseInteractivity_Class;
exports.ModuleFE_MouseInteractivity = new ModuleFE_MouseInteractivity_Class();
