"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThunderDispatcher = void 0;
const ts_common_1 = require("@nu-art/ts-common");
// type ValidKeyResolver<T, K extends keyof T> = T[K] extends (...args: any) => any ? K : never
class ThunderDispatcher extends ts_common_1.Dispatcher {
    constructor(method, allowCache = false) {
        super(method);
        this.allowCache = allowCache;
        this.setMinLevel(ThunderDispatcher.MinLogLevel);
    }
    getCachedData() {
        return this.cache;
    }
    dispatchUI(...p) {
        if (this.allowCache)
            this.cache = p;
        this.logDebug('Dispatching (Sync): ', p);
        const listeners = ThunderDispatcher.listenersResolver();
        return listeners.filter(this.filter).map((listener) => {
            // @ts-ignore
            return listener[this.method](...p);
        });
    }
    async dispatchUIAsync(...p) {
        if (this.allowCache)
            this.cache = p;
        this.logDebug('Dispatching (Async): ', p);
        const listeners = ThunderDispatcher.listenersResolver();
        return Promise.all(listeners.filter(this.filter).map(async (listener) => {
            // @ts-ignore
            return listener[this.method](...p);
        }));
    }
    dispatchAll(...p) {
        const moduleResponses = this.dispatchModule(...p);
        const uiResponses = this.dispatchUI(...p);
        return [...moduleResponses, ...uiResponses];
    }
    async dispatchAllAsync(...p) {
        const listenersUI = ThunderDispatcher.listenersResolver();
        const listenersModules = ts_common_1.Dispatcher.modulesResolver();
        return Promise.all(listenersUI.concat(listenersModules).filter(this.filter).map(async (listener) => {
            // @ts-ignore
            return listener[this.method](...p);
        }));
    }
}
ThunderDispatcher.MinLogLevel = ts_common_1.LogLevel.Info;
exports.ThunderDispatcher = ThunderDispatcher;
