"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Payment = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const validators_1 = require("../../validators");
const shopify_1 = require("../../shopify");
const Validator_Payment = Object.assign(Object.assign({}, shopify_1.StoreItem_validator), { description: validators_1.tsValidateStringOneLine, assetIds: (0, ts_common_1.tsValidateArray)(ts_common_1.tsValidateUniqueId, false), date: (0, ts_common_1.tsValidateNumber)(), value: validators_1.tsValidateCurrencyValue });
exports.DBDef_Payment = {
    validator: Validator_Payment,
    dbName: 'payments',
    entityName: 'payment',
};
