import * as React from 'react';
import {PDF_Renderer} from '@modules/index';
import {MimeType_csv, MimeType_gif, MimeType_html, MimeType_jpeg, MimeType_jpg, MimeType_pdf, MimeType_png, MimeType_txt} from '@nu-art/ts-common';
import {_className} from '@nu-art/thunderstorm/frontend';


export type AssetRendererProps = {
	className?: string
	data: any
	src: string
	mimeType: string
}

type StaticRenderers = { [k: string]: (props: AssetRendererProps) => React.ReactNode };

export class AssetRenderer
	extends React.Component<AssetRendererProps, any> {
	private static staticRenderers: StaticRenderers = {} as StaticRenderers;

	static setCustomRenderers(renderers: StaticRenderers) {
		AssetRenderer.staticRenderers = renderers;
	}

	render() {
		switch (this.props.mimeType) {
			case MimeType_png:
			case MimeType_jpeg:
			case MimeType_jpg:
				return <img className={_className('asset-renderer', this.props.className)} src={this.props.src}/>;

			case MimeType_pdf:
				return <PDF_Renderer src={this.props.src}/>;
		}

		const renderer = AssetRenderer.staticRenderers[this.props.mimeType];
		if (renderer)
			return renderer(this.props);

		return <div>
		</div>;
	}

	static srcFromFile(file: File) {
		return URL.createObjectURL(file);
	}

	static revokeFileUrl(url: string) {
		URL.revokeObjectURL(url);
	}

	static mimeTypeFromFile(file: File) {
		const type = file.type.toLowerCase();
		switch (type) {
			case 'txt':
				return MimeType_txt;

			case 'html':
				return MimeType_html;

			case 'css':
				return MimeType_csv;
			case 'csv':
			case 'xml':
				return `text/${type}`;

			case 'png':
				return MimeType_png;
			case 'jpeg':
				return MimeType_jpeg;
			case 'jpg':
				return MimeType_jpg;
			case 'gif':
				return MimeType_gif;

			case 'js':
				return `text/javascript`;

			case 'pdf':
				return MimeType_pdf;

			case 'json':
				return `application/${type}`;

			default:
				return type;
		}
	}
}