"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndexedDB = void 0;
const ts_common_1 = require("@nu-art/ts-common");
//@ts-ignore - set IDBAPI as indexedDB regardless of browser
const IDBAPI = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
class IndexedDB {
    static getOrCreate(config) {
        return this.dbs[config.name] || (this.dbs[config.name] = new IndexedDB(config));
    }
    constructor(config) {
        this.store = async (write = false, store) => {
            if (store)
                return store;
            if (!this.db)
                await this.open();
            return this.db.transaction(this.config.name, write ? 'readwrite' : 'readonly').objectStore(this.config.name);
        };
        this.getCursor = async (query) => {
            const store = await this.store();
            let cursorRequest;
            if (query === null || query === void 0 ? void 0 : query.indexKey) {
                const idbIndex = store.index(query.indexKey);
                if (!idbIndex)
                    throw new ts_common_1.MUSTNeverHappenException('Could not find index for the given indexKey');
                cursorRequest = idbIndex.openCursor();
            }
            else
                cursorRequest = store.openCursor();
            return cursorRequest;
        };
        this.cursorHandler = (cursorRequest, perValueCallback, endCallback, limiterCallback) => {
            cursorRequest.onsuccess = (event) => {
                const cursor = event.target.result;
                //If reached the end or reached limit, call endCallback
                if (!cursor || (limiterCallback === null || limiterCallback === void 0 ? void 0 : limiterCallback()))
                    return endCallback();
                //run value through the value callback
                perValueCallback(cursor.value);
                //Continue to next value
                cursor.continue();
            };
        };
        this.config = Object.assign(Object.assign({}, config), { upgradeProcessor: (db) => {
                var _a, _b;
                if (!db.objectStoreNames.contains(this.config.name)) {
                    const store = db.createObjectStore(this.config.name, {
                        autoIncrement: config.autoIncrement,
                        keyPath: config.uniqueKeys
                    });
                    (_a = this.config.indices) === null || _a === void 0 ? void 0 : _a.forEach(index => {
                        var _a, _b;
                        return store.createIndex(index.id, index.keys, {
                            multiEntry: (_a = index.params) === null || _a === void 0 ? void 0 : _a.multiEntry,
                            unique: (_b = index.params) === null || _b === void 0 ? void 0 : _b.unique
                        });
                    });
                }
                (_b = config.upgradeProcessor) === null || _b === void 0 ? void 0 : _b.call(config, db);
            }, autoIncrement: config.autoIncrement || false, version: config.version || 1 });
    }
    async open() {
        return new Promise((resolve, reject) => {
            if (!IDBAPI)
                reject(new Error('Error - current browser does not support IndexedDB'));
            const request = IDBAPI.open(this.config.name);
            request.onupgradeneeded = () => {
                var _a, _b;
                const db = request.result;
                (_b = (_a = this.config).upgradeProcessor) === null || _b === void 0 ? void 0 : _b.call(_a, db);
            };
            request.onsuccess = (event) => {
                // console.log(`${this.config.name} - IDB result`, request.result);
                this.db = request.result;
                resolve(this.db);
            };
            request.onerror = (event) => {
                reject(new Error(`Error opening IDB - ${this.config.name}`));
            };
        });
    }
    // ######################### Data insertion functions #########################
    async insert(value, _store) {
        const store = await this.store(true, _store);
        const request = store.add(value);
        return new Promise((resolve, reject) => {
            request.onerror = () => reject(new Error(`Error inserting item in DB - ${this.config.name}`));
            request.onsuccess = () => resolve(request.result);
        });
    }
    async insertAll(values, _store) {
        const store = await this.store(true, _store);
        for (const value of values) {
            await this.insert(value, store);
        }
    }
    async upsert(value, _store) {
        const store = await this.store(true, _store);
        try {
            const request = store.put(value);
            return new Promise((resolve, reject) => {
                request.onerror = () => reject(new Error(`Error upserting item in DB - ${this.config.name}`));
                request.onsuccess = () => resolve(request.result);
            });
        }
        catch (e) {
            ts_common_1.StaticLogger.logError('trying to upsert: ', value);
            throw e;
        }
    }
    async upsertAll(values, _store) {
        const store = (await this.store(true, _store));
        for (const value of values) {
            await this.upsert(value, store);
        }
    }
    // ######################### Data collection functions #########################
    async get(key) {
        const map = this.config.uniqueKeys.map(k => key[k]);
        const request = (await this.store()).get(map);
        return new Promise((resolve, reject) => {
            request.onerror = () => reject(new Error(`Error getting item from DB - ${this.config.name}`));
            request.onsuccess = () => resolve(request.result);
        });
    }
    async query(query) {
        const store = await this.store();
        return new Promise((resolve, reject) => {
            let request;
            if (query.indexKey)
                request = store.index(query.indexKey).getAll(query.query, query.limit);
            else
                request = store.getAll(query.query, query.limit);
            request.onsuccess = () => {
                resolve(request.result);
            };
            request.onerror = () => {
                reject(new Error(`Error querying DB - ${this.config.name}`));
            };
        });
    }
    async queryFilter(filter, query) {
        const limit = (query === null || query === void 0 ? void 0 : query.limit) || 0;
        const cursorRequest = await this.getCursor(query);
        const matches = [];
        return new Promise((resolve, reject) => {
            this.cursorHandler(cursorRequest, (value) => {
                if (filter(value))
                    matches.push(value);
            }, () => resolve(matches), () => limit > 0 && matches.length >= limit);
        });
    }
    async WIP_queryMapNew(mapper, filter, query) {
        const limit = (query === null || query === void 0 ? void 0 : query.limit) || 0;
        const cursorRequest = await this.getCursor(query);
        const matches = [];
        return new Promise((resolve, reject) => {
            this.cursorHandler(cursorRequest, (item) => {
                if (filter ? filter(item) : true)
                    matches.push(mapper(item));
            }, () => resolve(matches), () => limit > 0 && matches.length >= limit);
        });
    }
    async WIP_queryMap(mapper, filter, query) {
        const limit = (query === null || query === void 0 ? void 0 : query.limit) || 0;
        const cursorRequest = await this.getCursor(query);
        const matches = [];
        return new Promise((resolve, reject) => {
            this.cursorHandler(cursorRequest, (item) => {
                if (filter ? filter(item) : true)
                    matches.push(mapper(item));
            }, () => resolve(matches), () => limit > 0 && matches.length >= limit);
        });
    }
    async queryFind(filter) {
        let match = undefined;
        const cursorRequest = await this.getCursor();
        return new Promise((resolve, reject) => {
            this.cursorHandler(cursorRequest, (value) => {
                if (filter(value))
                    match = value;
            }, () => resolve(match), () => !!match);
        });
    }
    async queryReduce(reducer, initialValue, filter, query) {
        let acc = initialValue;
        const alwaysTrue = () => true;
        const _filter = filter || alwaysTrue;
        const matches = await this.queryFilter(_filter, query);
        return new Promise((resolve, reject) => {
            matches.forEach((item, index) => acc = reducer(acc, item, index, matches));
            resolve(acc);
        });
    }
    // ######################### Data deletion functions #########################
    async clearDB() {
        const store = await this.store(true);
        return new Promise((resolve, reject) => {
            const request = store.clear();
            request.onsuccess = () => resolve();
            request.onerror = reject;
        });
    }
    async deleteDB() {
        if (this.db)
            this.db.close();
        return new Promise((resolve, reject) => {
            const request = IDBAPI.deleteDatabase(this.db.name);
            request.onerror = (event) => {
                ts_common_1.StaticLogger.logError(`Error deleting database: ${this.db.name}`);
                reject();
            };
            request.onsuccess = () => resolve();
        });
    }
    async deleteAll(keys) {
        return await Promise.all(keys.map(key => this.delete(key)));
    }
    async delete(key) {
        const keys = this.config.uniqueKeys.map(k => key[k]);
        const store = await this.store(true);
        return new Promise((resolve, reject) => {
            const itemRequest = store.get(keys);
            itemRequest.onerror = () => reject(new Error(`Error getting item in DB - ${this.config.name}`));
            itemRequest.onsuccess = () => {
                var _a;
                // @ts-ignore
                if (key.__updated !== undefined && ((_a = itemRequest.result) === null || _a === void 0 ? void 0 : _a.__updated) > key.__updated) {
                    return resolve(itemRequest.result);
                }
                const deleteRequest = store.delete(keys);
                deleteRequest.onerror = () => reject(new Error(`Error deleting item in DB - ${this.config.name}`));
                deleteRequest.onsuccess = () => resolve(itemRequest.result);
            };
        });
    }
}
IndexedDB.dbs = {};
exports.IndexedDB = IndexedDB;
