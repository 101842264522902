"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModulePackFE_FileUploader = void 0;
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const ModuleFE_AssetUploader_1 = require("../modules/ModuleFE_AssetUploader");
const frontend_1 = require("@nu-art/push-pub-sub/frontend");
const ModuleFE_Assets_1 = require("../modules/ModuleFE_Assets");
exports.ModulePackFE_FileUploader = [
    ...frontend_1.ModulePackFE_PushPubSub,
    ModuleFE_Assets_1.ModuleFE_Assets,
    ModuleFE_AssetUploader_1.ModuleFE_AssetUploader,
];
