
import {apiWithBody, ModuleFE_Locale, ModuleFE_Toaster, ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_ProductTags, ModuleFE_Stores, ModuleFE_Translations, ModuleFE_Vendors, ProductTemplateUI_Properties} from '@modules/index';
import {_keys, deepClone, filterDuplicates, filterInstances, PreDB, Replacer, stringFormat, TS_Object, TypedMap} from '@nu-art/ts-common';
import {ApiDefCaller} from '@nu-art/thunderstorm';
import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';
import {ApiDef_ProductTemplate, ApiStruct_ProductTemplate, DB_ProductTemplate, DB_TranslationEntry, DBDef_ProductTemplate, ProductToUpsert} from '@app/shared';


export interface OnProductTemplatesUpdated {
	__onProductTemplatesUpdated: (...params: ApiCallerEventType<DB_ProductTemplate>) => void;
}

export const dispatch_onProductTemplatesListChanged = new ThunderDispatcher<OnProductTemplatesUpdated, '__onProductTemplatesUpdated'>('__onProductTemplatesUpdated');

export type TemplateProductConfiguration = { [k: string]: { product: ProductToUpsert, replacer: Replacer } };

export class ModuleFE_ProductTemplate_Class
	extends ModuleFE_BaseApi<DB_ProductTemplate>
	implements ApiDefCaller<ApiStruct_ProductTemplate> {

	_v1: ApiDefCaller<ApiStruct_ProductTemplate>['_v1'];

	constructor() {
		super(DBDef_ProductTemplate, dispatch_onProductTemplatesListChanged);
		this._v1 = {
			productToTemplate: apiWithBody(ApiDef_ProductTemplate._v1.productToTemplate, (res) => this.onEntryUpdated(res, res)),
			templateToProduct: apiWithBody(ApiDef_ProductTemplate._v1.templateToProduct, (res) => this.onEntryUpdated(res, res)),
		};
	}

	async upsertTemplateToProduct(template: DB_ProductTemplate, instanceIndex: number, primaryLocal: string, otherLocales?: string[]) {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise<void>(async (resolve, error) => {
			try {
				const [product, replacer] = await this.prepareProductToUpsert(template, instanceIndex, primaryLocal);
				let translations: { [p: string]: ProductToUpsert } = {};
				if (otherLocales) {
					const _translations = await Promise.all(otherLocales.map(async locale => (await this.prepareProductToUpsert(template, instanceIndex, locale, replacer))[0]));
					translations = otherLocales.reduce((toRet, locale, index) => {
						toRet[locale] = _translations[index];
						return toRet;
					}, translations);
				}
				const store = await ModuleFE_Stores.getDefaultStore();

				this._v1.templateToProduct({storeId: store._id, templateId: template._id, instanceIndex, product, translations})
					.execute(async () => resolve());
			} catch (e: any) {
				error(e);
			}
		});
	}

	async syncTemplateFromProduct(productId: string, templateId?: string, requestData?: string) {
		const store = await ModuleFE_Stores.getDefaultStore();

		this._v1.productToTemplate({storeId: store._id, productId, templateId})
			.execute(async (response: DB_ProductTemplate) => {
				ModuleFE_Translations.v1.sync().execute(() => {
					const preDbObj = {...response} as PreDB<DB_ProductTemplate>;
					delete preDbObj._id;
					return this.onEntryUpdated(response, preDbObj);
				});
			});
	}

	private async prepareProductToUpsert(item: Partial<DB_ProductTemplate>, instanceIndex: number, locale: string, fallbackReplacer?: Replacer): Promise<[ProductToUpsert, Replacer]> {
		const {inputMap, translationsMap, replacer} = await this.resolveProductTemplateData(item, locale, instanceIndex, fallbackReplacer);

		const props = {
			title: 'titleId',
			body_html: 'descriptionId',
			metafields_global_title_tag: 'metaTitleId',
			metafields_global_description_tag: 'metaDescriptionId',
		};

		this.logWarning(inputMap);
		const product = _keys(props).reduce((product, prop) => {
			const templateProp = props[prop];
			const itemElement = item[templateProp as keyof DB_ProductTemplate];
			const content = translationsMap[itemElement as string]?.content;
			product[prop] = content || '';
			if (templateProp) {
				try {
					product[prop] = replacer.replace(content || '').replace(/<\/p>\n<p/g, '</p><p');
				} catch (e: any) {
					if (fallbackReplacer)
						try {
							product[prop] = fallbackReplacer?.replace(content || '').replace(/<\/p>\n<p/g, '</p>&nbsp;<p');
						} catch (e: any) {
							console.log(e);
							ModuleFE_Toaster.toastError('Unable to resolve translation params' + e.message);
						}
				}
			}
			return product;
		}, {} as ProductToUpsert);

		product.vendor = inputMap.vendor;
		if (item.tagIds)
			product.tags = (filterInstances(await Promise.all(item.tagIds.map(tagId => ModuleFE_ProductTags.cache.unique(tagId))))
				.map(tag => tag.title) || []).join(',');
		product.properties = (item.properties || []).map(p => {
			return {
				label: translationsMap[p.keyId]?.content,
				values: p.values.map(v => {
					return stringFormat(translationsMap[v.id]?.content, v.params);
				})
			};
		});
		return [product, replacer];
	}

	async resolveProductTemplateData(item: Partial<DB_ProductTemplate>, locale: string, instanceIndex: number, fallbackReplacer?: Replacer) {
		const inputMap = {} as TS_Object;
		const properties = deepClone(item.properties || []);

		const toQuery: string[] = [];

		item.titleId && toQuery.push(item.titleId);
		item.descriptionId && toQuery.push(item.descriptionId);

		item.metaTitleId && toQuery.push(item.metaTitleId);
		item.metaDescriptionId && toQuery.push(item.metaDescriptionId);

		item.properties?.forEach((property) => {
			toQuery.push(property.keyId);
			toQuery.push(...property.values.map(value => value.id));
		});
		item.instances?.forEach((instance) => {
			toQuery.push(instance.propertyGroupId);
			toQuery.push(instance.propertyValueId);
		});

		const translations = await Promise.all(filterInstances(filterDuplicates(toQuery)).map(sharedId => ModuleFE_Translations.cache.unique({
			sharedId,
			locale
		})));

		const translationsMap = (filterInstances(translations))?.reduce((toRet, translation) => {
			toRet[translation.sharedId] = translation;
			return toRet;
		}, {} as TypedMap<DB_TranslationEntry>);

		inputMap.properties = properties.filter(p => translationsMap[p.keyId] && !p.hidden).map((property, index) => {
			const propertyGroupId = item.instances?.[instanceIndex]?.propertyGroupId;
			const propertyValueId = item.instances?.[instanceIndex]?.propertyValueId;

			const value = property.keyId === propertyGroupId && propertyValueId && (fallbackReplacer?.getInput().properties[index].value || translationsMap[propertyValueId]?.content);
			return {
				key: translationsMap[property.keyId].content,
				value: value || property.values.map(value => stringFormat(translationsMap[value.id]?.content || '', value.params)).join('<br/>'),
				rawValues: property.values.map(value => ({
					value: translationsMap[value.id]?.content || '',
					params: value.params || []
				})),
			};
		});

		inputMap.hiddenProperties = properties.filter(p => translationsMap[p.keyId] && p.hidden).map((property, index) => {
			const propertyGroupId = item.instances?.[instanceIndex]?.propertyGroupId;
			const propertyValueId = item.instances?.[instanceIndex]?.propertyValueId;

			const value = property.keyId === propertyGroupId && propertyValueId && (fallbackReplacer?.getInput().hiddenProperties[index].value || translationsMap[propertyValueId]?.content);
			return {
				key: translationsMap[property.keyId].content,
				value: value || property.values.map(value => stringFormat(translationsMap[value.id]?.content || '', value.params)).join('<br/>'),
				rawValues: property.values.map(value => ({
					value: translationsMap[value.id]?.content || '',
					params: value.params || []
				})),
			};
		});

		inputMap.localeRTL = ModuleFE_Locale.getLanguage(locale)?.rtl || 'unset';
		inputMap.aliases = item.aliases || [];
		item.label && (inputMap.label = item.label);
		item.vendorId && (inputMap.vendor = (await ModuleFE_Vendors.cache.unique(item.vendorId))?.title);
		item.titleId && (inputMap.title = translationsMap[item.titleId]?.content);
		item.metaTitleId && (inputMap.metaTitle = translationsMap[item.metaTitleId]?.content);

		// noinspection CssInvalidPropertyValue
		inputMap.propertiesTable = `<br/>
			<table width="100%" border="none" style="direction: \${localeRTL}">
				<tbody>
					{{foreach property in properties}}
					<tr>
						<td style="width: 35%;">\${property.key}</td>
						<td>\${property.value}</td>
					</tr>
					{{/foreach properties}}
				</tbody>
			</table>`.replace(/[\t\n]/g, '');

		const replacer = new Replacer().setInput(inputMap, item.aliases).setFallbackReplacer(fallbackReplacer);
		return {inputMap, translationsMap, replacer};
	}

	async prepareProductsToCreate(item: Partial<DB_ProductTemplate>, instanceIndex: number, locales: string[]) {
		const outputs: [ProductToUpsert, Replacer][] = [];
		let output: [ProductToUpsert, Replacer] | undefined = undefined;
		for (const locale of locales) {
			output = await ModuleFE_ProductTemplate.prepareProductToUpsert(item, instanceIndex, locale, output?.[1]);
			outputs.push(output);
		}
		return locales.reduce((toRet, locale, index) => {
			toRet[locale] = {product: outputs[index][0], replacer: outputs[index][1]};
			return toRet;
		}, {} as TemplateProductConfiguration);
	}

	resolveProductDescription(description: string, debug = false) {
		if (debug)
			this.logDebug(description);
		description = description.slice(0, description.indexOf('<table'));
		if (debug)
			this.logDebug(description);

		return description;
	}

	resolveProductQuality(description: string, debug = false) {
		if (debug)
			this.logDebug(description);
		description = description.replace(/<tr.*?>/g, '<tr>');
		description = description.replace(/<td.*?>/g, '<td>');
		description = description.replace(/<meta charset="utf-8">/g, ' ');
		description = description.replace(/ data-mce-fragment="1"/g, '');
		description = description.replace(/<br>\s*<\/td>/g, '</td>');
		description = description.replace(/<span.*?>(.*?)<\/span>/g, '$1');
		description = description.replace(/<span.*?>/g, ' ');
		description = description.replace(/<\/span>/g, ' ');
		description = description.replace(/<p.*?>(.*?)<\/p>/g, '$1');
		description = description.replace(/<p>/g, ' ');
		description = description.replace(/<\/p>/g, ' ');
		description = description.replace(/&nbsp;/g, ' ');
		description = description.replace(/\s+/g, ' ');
		description = description.replace(/<div.*?>/g, '');
		description = description.replace(/<\/div>/g, '');
		if (debug)
			this.logDebug(description);
		const matches = description.match(/<tr>\s*<td>\s*(.*?)\s*<\/td>\s*<td>\s*(.*?)\s*<\/td>\s*<\/tr>/g);
		if (debug)
			this.logDebug(matches);
		const properties = matches?.map(match => match.match(/<tr>\s*<td>\s*(.*?)\s*<\/td>\s*<td>\s*(.*?)\s*<\/td>\s*<\/tr>/));
		return properties?.reduce((toRet, value) => {
			if (!value)
				return toRet;

			const property = this.resolveProperty(value, debug);
			if (property)
				toRet.push(property);

			return toRet;
		}, [] as ProductTemplateUI_Properties[]);
	}

	private resolveProperty(property: RegExpMatchArray | null, debug = false): ProductTemplateUI_Properties | undefined {
		const propertyKeyAsString = property?.[1];
		let propValueAsString = property?.[2];
		if (!propertyKeyAsString || !propValueAsString)
			return;

		switch (propertyKeyAsString) {
			case 'Material':
				propValueAsString = this.resolveMaterial(propValueAsString, debug);
				break;
		}

		const values = propValueAsString.split(/<br\/?>|, /g);
		return {key: propertyKeyAsString, values};
	}

	private resolveMaterial(propValueAsString: string, debug = false) {
		if (debug)
			this.logDebug(propValueAsString);
		propValueAsString = propValueAsString.replace(/&amp;/g, '&');
		if (debug)
			this.logDebug(propValueAsString);
		propValueAsString = propValueAsString.replace('Standard 100 by OEKO-TEX® certified', 'OEKO-TEX');
		propValueAsString = propValueAsString.replace(/OEKO-TEX®/g, 'OEKO-TEX');
		propValueAsString = propValueAsString.replace(/OEKO-TEX/g, '');
		propValueAsString = propValueAsString.replace('GOTS certified', 'GOTS');
		propValueAsString = propValueAsString.replace('Oeko-Tex certified', 'OEKO-TEX');

		if (debug)
			this.logDebug(propValueAsString);
		propValueAsString = propValueAsString.replace(/\.$/g, '');
		propValueAsString = propValueAsString.replace(/\.?<br>/g, ', ');
		propValueAsString = propValueAsString.replace(/\s+/g, ' ');
		propValueAsString = propValueAsString.replace(/(\d+%.*?) and /g, '$1, ');
		propValueAsString = propValueAsString.replace(/(\d+%.*?)\s\/\s(\d+%.*?)/g, '$1, $2');
		propValueAsString = propValueAsString.replace(/(\d+%.*?)\s\/\s(\d+%.*?)/g, '$1, $2');
		propValueAsString = propValueAsString.replace(/(\d+%.*?)\s-\s(\d+%.*?)/g, '$1, $2');
		propValueAsString = propValueAsString.replace(/(\d+%.*?)\s-\s(\d+%.*?)/g, '$1, $2');
		propValueAsString = propValueAsString.replace(/(\d+%.*?),?\s+(\d+%.*?)/g, '$1, $2');
		return propValueAsString;
	}

}

export const ModuleFE_ProductTemplate = new ModuleFE_ProductTemplate_Class();
export const ModuleFE_ProductTemplate_ = ModuleFE_ProductTemplate as unknown as ModuleFE_BaseApi<any, any>;
