"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_LanguageLocale = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const Validator_ModifiableProps = {
    language: (0, ts_common_1.tsValidateString)(),
    locale: (0, ts_common_1.tsValidateString)(),
};
const Validator_GeneratedProps = {};
exports.DBDef_LanguageLocale = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbName: 'language-locale',
    entityName: 'language-locale',
};
