"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_ReadMore = void 0;
const React = require("react");
const core_1 = require("../../core");
require("./TS_ReadMore.scss");
const tools_1 = require("../../utils/tools");
class TS_ReadMore extends core_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.ref = React.createRef();
        this.textRef = React.createRef();
        this.getCollapsedText = () => {
            if (this.state.showMore)
                return this.state.text;
            const container = this.ref.current;
            const textContainer = this.textRef.current;
            if (!container || !textContainer)
                return this.state.text;
            const computedStyle = getComputedStyle(container);
            const width = container.clientWidth - parseFloat(computedStyle.paddingInline);
            const collapsedNumLines = this.props.collapsedHeight / parseInt(computedStyle.lineHeight);
            const charsPerLine = Math.floor(width / parseInt(computedStyle.fontSize));
            //Count text lines
            const textLines = this.state.text.split('\n').reduce((lines, curr, i) => {
                const innerLines = Math.ceil((curr.length) / charsPerLine);
                //Always push the first line
                if (i === 0) {
                    lines.push({ text: curr, lines: innerLines });
                    return lines;
                }
                //Don't push empty strings
                if (!curr.length)
                    return lines;
                //Don't push if collapsed line threshold has passed
                if (lines.reduce((total, curr) => total + curr.lines, 0) >= collapsedNumLines)
                    return lines;
                //Push the text
                lines.push({ text: curr, lines: innerLines });
                return lines;
            }, []);
            let text = '';
            const readMoreTextLength = (this.props.readMoreText(this.state.showMore).length) + 3;
            let remainingLines = collapsedNumLines;
            //Concat all lines with logic
            textLines.forEach((textLine, i) => {
                //If not last textLine, append to text with a new line char
                if (i !== textLines.length - 1) {
                    text += textLine.text + '\n';
                    remainingLines -= textLine.lines;
                    return;
                }
                //Is last text line
                //Collect data
                const lastLineAllowedChars = charsPerLine * remainingLines * 1.75; // Actual chars allowed for this textLine  (1.75 factor to account for letters that are smaller than the font size + kerning)
                const lastLineTotalChars = textLine.text.length + readMoreTextLength + 15; // Total chars that will be in this line + the button chars + some margin for safety
                const overflowingChars = lastLineTotalChars - lastLineAllowedChars;
                //If there are overflowing chars, deduct from text
                if (overflowingChars > 0)
                    text += textLine.text.substring(0, textLine.text.length - overflowingChars);
                else
                    text += textLine.text;
            });
            return text;
        };
        this.renderButton = () => {
            return React.createElement(React.Fragment, null,
                this.state.showMore ? ' ' : '... ',
                React.createElement("span", { className: 'ts-read-more__button', onClick: () => this.setState({ showMore: !this.state.showMore }) }, this.props.readMoreText(this.state.showMore)));
        };
        this.renderText = () => {
            return React.createElement("span", { className: 'ts-read-more__text', ref: this.textRef }, this.getCollapsedText());
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state !== null && state !== void 0 ? state : (state = this.state ? Object.assign({}, this.state) : {});
        state.text = nextProps.text;
        (_a = state.showMore) !== null && _a !== void 0 ? _a : (state.showMore = false);
        return state;
    }
    componentDidMount() {
        new ResizeObserver(() => { var _a; return this.setState({ width: (_a = this.ref.current) === null || _a === void 0 ? void 0 : _a.scrollWidth }); }).observe(this.ref.current);
        this.forceUpdate();
    }
    render() {
        const style = {
            '--collapsed-height': `${this.props.collapsedHeight}px`,
        };
        return React.createElement("p", { className: (0, tools_1._className)('ts-read-more', this.state.showMore ? 'expand' : undefined), style: style, ref: this.ref },
            this.renderText(),
            this.renderButton());
    }
}
TS_ReadMore.defaultProps = {
    readMoreText: (showingMore) => showingMore ? 'Read Less' : 'Read More',
};
exports.TS_ReadMore = TS_ReadMore;
