"use strict";
/*
 * Firebase is a simpler Typescript wrapper to all of firebase services.
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessagingWrapperFE = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const messaging_1 = require("firebase/messaging");
/**
 Firebase Messaging wrapper
 */
class MessagingWrapperFE extends ts_common_1.Logger {
    /**
     * Constructor for the MessagingWrapperFE class
     * @param app
     */
    constructor(app) {
        super();
        this.messaging = (0, messaging_1.getMessaging)(app);
    }
    async getToken(options) {
        this.token = await (0, messaging_1.getToken)(this.messaging, options);
        if (this.callback)
            this.onMessage(this.callback);
        return this.token;
    }
    /**
     Deletes the current Firebase Messaging token for the client
     */
    async deleteToken() {
        this.logVerbose('Deleting firebase messaging token');
        await (0, messaging_1.deleteToken)(this.messaging);
        delete this.token;
    }
    onMessage(callback) {
        this.callback = callback;
        if (!this.token)
            return;
        return (0, messaging_1.onMessage)(this.messaging, callback);
    }
}
exports.MessagingWrapperFE = MessagingWrapperFE;
