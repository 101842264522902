"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageKey_SessionTimeoutTimestamp = exports.StorageKey_DeviceId = exports.StorageKey_SessionId = exports.SessionKey_Account_FE = void 0;
const ModuleFE_Account_1 = require("../modules/ModuleFE_Account");
const shared_1 = require("../../shared");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
exports.SessionKey_Account_FE = new ModuleFE_Account_1.SessionKey_FE('account');
exports.StorageKey_SessionId = new frontend_1.StorageKey(`storage-${shared_1.HeaderKey_SessionId}`);
exports.StorageKey_DeviceId = new frontend_1.StorageKey(`storage--device-id`).withstandDeletion();
exports.StorageKey_SessionTimeoutTimestamp = new frontend_1.StorageKey(`storage-accounts__session-timeout`);
