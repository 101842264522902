"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionDomainsEditor = void 0;
const React = require("react");
const editor_base_1 = require("./editor-base");
const module_pack_1 = require("../../core/module-pack");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const ts_styles_1 = require("@nu-art/ts-styles");
const dialogs_1 = require("@nu-art/thunderstorm/frontend/_ats/dialogs");
const ModuleFE_PermissionsAssert_1 = require("../../modules/ModuleFE_PermissionsAssert");
const consts_1 = require("../../../shared/consts");
const ui_props_1 = require("../ui-props");
const emptyLevel = Object.freeze({ name: '', domainId: '', value: -1 });
class PermissionDomainsEditor extends editor_base_1.EditorBase {
    constructor() {
        //######################### Static #########################
        super(...arguments);
        this.module = module_pack_1.ModuleFE_PermissionsDomain;
        this.itemName = 'Permission Domain';
        this.itemNamePlural = 'Permission Domains';
        this.itemDisplay = (item) => item.namespace;
        //######################### Logic #########################
        this.updateLevel = async (_level, key, value) => {
            const domain = this.state.editedItem;
            if (!domain)
                throw new ts_common_1.BadImplementationException('Editing a level with no selected domain');
            const level = new frontend_1.EditableDBItem(_level, module_pack_1.ModuleFE_PermissionsAccessLevel);
            level.update(key, value);
            if (!level.item.domainId)
                level.update('domainId', domain.item._id);
            await level.save();
            this.forceUpdate();
        };
        this.deleteLevel = async (_level) => {
            const level = new frontend_1.EditableDBItem(_level, module_pack_1.ModuleFE_PermissionsAccessLevel);
            return level.delete();
        };
        this.saveNewLevel = async () => {
            if (!this.state.editedItem)
                throw new ts_common_1.BadImplementationException('Saving level with no selected domain');
            this.state.newLevel.update('domainId', this.state.editedItem.item._id);
            return this.state.newLevel.save();
        };
        this._saveImpl = async (createLevels) => {
            frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
            await (0, frontend_1.genericNotificationAction)(async () => {
                await new frontend_1.EditableDBItem(this.state.editedItem.item, module_pack_1.ModuleFE_PermissionsDomain, async (domain) => {
                    if (!createLevels)
                        return;
                    await module_pack_1.ModuleFE_PermissionsAccessLevel.v1.upsertAll(consts_1.defaultAccessLevels.map(i => (Object.assign(Object.assign({}, i), { domainId: domain._id })))).executeSync();
                }).save();
            }, `Saving ${this.itemName}`, 3);
        };
        this.saveItem = async (e) => {
            var _a;
            if ((_a = this.state.editedItem) === null || _a === void 0 ? void 0 : _a.item._id)
                await this._saveImpl(false);
            const model = {
                id: 'save-initial-domain',
                modalPos: { x: 0, y: -1 },
                offset: { x: 0, y: -10 },
                originPos: (0, frontend_1.getElementCenterPos)(e.target),
                content: () => React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'save-initial-domain__title' }, "Create default access levels?"),
                    React.createElement(frontend_1.LL_H_C, { className: 'save-initial-domain__buttons' },
                        React.createElement(frontend_1.TS_Button, { onClick: () => this._saveImpl(false) }, "No"),
                        React.createElement(frontend_1.TS_Button, { onClick: () => {
                                this._saveImpl(true);
                            } }, "Yes"))),
            };
            frontend_1.ModuleFE_MouseInteractivity.showContent(model);
        };
        //######################### Render levels #########################
        this.renderLevelsTable = () => {
            const domain = this.state.editedItem;
            if (!domain)
                return '';
            let levels = module_pack_1.ModuleFE_PermissionsAccessLevel.cache.filter(level => level.domainId === domain.item._id);
            levels = (0, ts_common_1.sortArray)(levels, i => i.value);
            levels.push((0, ts_common_1.cloneObj)(emptyLevel));
            return React.createElement(frontend_1.TS_Table, { header: ['name', 'value', { widthPx: 50, header: 'action' }], headerRenderer: header => header === 'action' ? '' : (0, ts_common_1.capitalizeFirstLetter)(header), rows: levels, cellRenderer: this.levelsCellRenderer });
        };
        this.levelsCellRenderer = (prop, item, index) => {
            switch (prop) {
                case 'name':
                    return this.renderLevelName(item);
                case 'value':
                    return this.renderLevelValue(item);
                case 'action':
                    return this.renderLevelAction(item);
                default:
                    throw new ts_common_1.BadImplementationException(`No renderer defined for key ${prop}`);
            }
        };
        this.renderLevelName = (level) => {
            const actionProp = level._id
                ? { onBlur: async (value) => await this.updateLevel(level, 'name', value) }
                : {
                    onChange: (value) => {
                        this.state.newLevel.update('name', value);
                        this.forceUpdate();
                    }
                };
            return React.createElement(frontend_1.TS_Input, Object.assign({ type: 'text', value: level.name, placeholder: 'Enter level name' }, actionProp));
        };
        this.renderLevelValue = (level) => {
            const actionProp = level._id
                ? { onBlur: async (value) => await this.updateLevel(level, 'value', Number(value)) }
                : {
                    onChange: (value) => {
                        this.state.newLevel.update('value', Number(value));
                        this.forceUpdate();
                    }
                };
            return React.createElement(frontend_1.TS_Input, Object.assign({ type: 'number', value: level.value >= 0 ? String(level.value) : undefined, placeholder: 'Enter level value' }, actionProp));
        };
        this.renderLevelAction = (level) => {
            if (!level._id)
                return React.createElement(frontend_1.TS_BusyButton, { onClick: this.saveNewLevel, className: 'action-button save' },
                    React.createElement(ts_styles_1.TS_Icons.save.component, null));
            return React.createElement(frontend_1.TS_BusyButton, { onClick: async () => await this.deleteLevel(level), className: 'action-button delete', key: level._id },
                React.createElement(ts_styles_1.TS_Icons.bin.component, null));
        };
        //######################### Render #########################
        this.renderProjectsDropDown = () => {
            const domain = this.state.editedItem;
            if (!domain)
                return '';
            return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Project' },
                React.createElement(ui_props_1.Permissions_DropDown.Project, { selected: domain.item.projectId, onSelected: project => this.setProperty('projectId', project._id) }));
        };
        this.renderDBDefList = () => {
            return React.createElement(React.Fragment, null, this.state.dbDefs.map(dbDef => {
                return React.createElement("div", { onClick: () => {
                        frontend_1.ModuleFE_MouseInteractivity.hide(frontend_1.mouseInteractivity_PopUp);
                        dialogs_1.Dialog_ActionProcessorConfirmation.show({
                            key: 'connect-domain-to-routes',
                            description: `Connect domain ${this.state.editedItem.item.namespace} to default routes under module ${dbDef.entityName}?`,
                            group: ''
                        }, async () => {
                            await ModuleFE_PermissionsAssert_1.ModuleFE_PermissionsAssert.v1.connectDomainToRoutes({ domainId: this.state.editedItem.item._id, dbName: dbDef.dbName }).executeSync();
                        });
                    }, className: 'db-def-list__item' }, dbDef.entityName);
            }));
        };
        this.renderConnectDomainButton = () => {
            var _a;
            if (!((_a = this.state.editedItem) === null || _a === void 0 ? void 0 : _a.item._id))
                return '';
            return React.createElement(frontend_1.TS_Button, Object.assign({}, frontend_1.openContent.popUp.right('db-def-list', this.renderDBDefList), { className: 'db-def-button' }), "Connect To Routes");
        };
        this.editorContent = () => {
            const domain = this.state.editedItem;
            return React.createElement(React.Fragment, null,
                this.renderProjectsDropDown(),
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Namespace' },
                    React.createElement(frontend_1.LL_H_C, { className: 'match_width', style: { gap: '10px' } },
                        React.createElement(frontend_1.TS_Input, { type: 'text', value: domain.item.namespace, onChange: value => this.setProperty('namespace', value) }),
                        this.renderConnectDomainButton())),
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Levels' }, this.renderLevelsTable()));
        };
    }
    //######################### Life Cycle #########################
    __onPermissionsDomainsUpdated(...params) {
        if ([frontend_1.EventType_Update, frontend_1.EventType_Create].includes(params[0])) {
            const domain = params[1];
            this.reDeriveState({
                selectedItemId: domain._id,
                editedItem: new frontend_1.EditableDBItem(domain, module_pack_1.ModuleFE_PermissionsDomain)
            });
        }
        if (params[0] === frontend_1.EventType_Delete)
            this.reDeriveState({ selectedItemId: undefined, editedItem: undefined });
    }
    __onPermissionsLevelsUpdated(...params) {
        this.forceUpdate();
    }
    async deriveStateFromProps(nextProps, state) {
        var _a, _b;
        state.items = module_pack_1.ModuleFE_PermissionsDomain.cache.filter(domain => !(0, ts_common_1.exists)(this.props.projectId) || domain.projectId === this.props.projectId);
        state.projects = module_pack_1.ModuleFE_PermissionsProject.cache.all();
        (_a = state.newLevel) !== null && _a !== void 0 ? _a : (state.newLevel = new frontend_1.EditableDBItem(emptyLevel, module_pack_1.ModuleFE_PermissionsAccessLevel));
        if (!state.editedItem && state.items.length) {
            state.editedItem = new frontend_1.EditableDBItem(state.items[0], module_pack_1.ModuleFE_PermissionsDomain);
            state.selectedItemId = state.items[0]._id;
        }
        (_b = state.dbDefs) !== null && _b !== void 0 ? _b : (state.dbDefs = (0, ts_common_1.filterInstances)(frontend_1.Thunder.getInstance()
            .filterModules(module => 'dbDef' in module)
            .map(module => 'dbDef' in module ? module.dbDef : undefined)));
        return state;
    }
}
PermissionDomainsEditor.defaultProps = {
    modules: [module_pack_1.ModuleFE_PermissionsDomain]
};
exports.PermissionDomainsEditor = PermissionDomainsEditor;
