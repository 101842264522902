"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_VariantOption = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const types_1 = require("../types");
const Validator_VariantOption = Object.assign(Object.assign({}, types_1.StoreItem_validator), { label: ts_common_1.tsValidateMustExist });
exports.DBDef_VariantOption = {
    validator: Validator_VariantOption,
    dbName: 'variant-options',
    entityName: 'variant-option',
    uniqueKeys: ['storeId', 'label']
};
