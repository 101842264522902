"use strict";
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_PermissionsUser = exports.ModuleFE_PermissionsUser_Class = void 0;
const index_1 = require("../../../index");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const dispatch_OnPermissionsUsersUpdated = new frontend_1.ThunderDispatcher('__onPermissionsUsersUpdated');
class ModuleFE_PermissionsUser_Class extends frontend_1.ModuleFE_BaseApi {
    constructor() {
        super(index_1.DBDef_PermissionUser, dispatch_OnPermissionsUsersUpdated);
    }
}
exports.ModuleFE_PermissionsUser_Class = ModuleFE_PermissionsUser_Class;
exports.ModuleFE_PermissionsUser = new ModuleFE_PermissionsUser_Class();
