"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProxyServiceAccount_EditorRenderer = void 0;
const React = require("react");
require("./ProxyServiceAccount_EditorRenderer.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
class ProxyServiceAccount_EditorRenderer extends frontend_1.EditorRenderer_BaseImpl {
    constructor() {
        super(...arguments);
        this.renderSaveButtons = () => React.createElement(frontend_1.LL_H_C, { className: 'buttons-container' },
            React.createElement(frontend_1.TS_Button, { onClick: () => { var _a, _b; return (_b = (_a = this.props).onCancel) === null || _b === void 0 ? void 0 : _b.call(_a, this.props.editable); } }, "CANCEL"),
            React.createElement(frontend_1.TS_Button, { onClick: () => this.props.editable.save() }, "SAVE"));
        this.renderEditorBody = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'form-wrapper match_height' },
                this.input('type').vertical('Email'),
                this.input('displayName').vertical('displayName'),
                this.input('email').vertical('Email'));
        };
    }
    render() {
        return React.createElement("div", { className: 'proxy-service-account-editor match_parent' },
            this.renderSaveButtons(),
            this.renderEditorBody());
    }
}
exports.ProxyServiceAccount_EditorRenderer = ProxyServiceAccount_EditorRenderer;
