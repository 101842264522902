"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Translation_Inputs = exports.Translation_MultiSelect = exports.Translation_DropDown = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const project_1 = require("../../shared/project");
const project_2 = require("../project");
const frontend_2 = require("../../tags/frontend");
const DBItemDropDownMultiSelector_1 = require("@nu-art/thunderstorm/frontend/components/_TS_MultiSelect/DBItemDropDownMultiSelector");
const React = require("react");
const ts_styles_1 = require("@nu-art/ts-styles");
const frontend_3 = require("../../locale/frontend");
const frontend_4 = require("../../client/frontend");
const frontend_5 = require("@nu-art/user-account/frontend");
const TS_V2_Input_1 = require("@nu-art/thunderstorm/frontend/components/TS_V2_Input");
const TS_V2_TextArea_1 = require("@nu-art/thunderstorm/frontend/components/TS_V2_TextArea");
const Props_Project = {
    module: project_2.ModuleFE_TranslationProject,
    modules: [project_2.ModuleFE_TranslationProject],
    mapper: item => [item.title, item.description],
    placeholder: 'Choose a Project',
    renderer: item => React.createElement("div", { key: item._id }, item.title),
    sortBy: [item => item.title]
};
const Props_Client = {
    module: frontend_4.ModuleFE_TranslationClient,
    modules: [frontend_4.ModuleFE_TranslationClient],
    mapper: item => { var _a, _b; return [item.companyName, ((_a = frontend_5.ModuleFE_Account.cache.unique(item.accountId)) === null || _a === void 0 ? void 0 : _a.displayName) || '', ((_b = frontend_5.ModuleFE_Account.cache.unique(item.accountId)) === null || _b === void 0 ? void 0 : _b.email) || '']; },
    placeholder: 'Choose a Client',
    renderer: item => {
        const account = frontend_5.ModuleFE_Account.cache.unique(item.accountId);
        return React.createElement("div", { key: item._id }, account.displayName || account.email);
    },
    sortBy: [item => {
            const account = frontend_5.ModuleFE_Account.cache.unique(item.accountId);
            return account.displayName || account.email;
        }]
};
const Props_Tag = {
    module: frontend_2.ModuleFE_GenericTags,
    modules: [frontend_2.ModuleFE_GenericTags],
    mapper: item => [item.label],
    placeholder: 'Choose a Tag',
    renderer: item => {
        return React.createElement("div", { key: item._id }, item.label);
    },
    sortBy: [
        item => item.label,
    ]
};
const Props_LanguageLocale = {
    module: frontend_3.ModuleFE_LanguageLocale,
    modules: [frontend_3.ModuleFE_LanguageLocale],
    mapper: item => [item.language, item.locale],
    placeholder: 'Choose Locale',
    renderer: item => {
        return React.createElement("div", { key: item._id },
            item.language,
            "(",
            item.locale,
            ")");
    },
    sortBy: [item => item.language]
};
const Props_TranslationQuality = {
    placeholder: 'Choose Quality',
    adapter: (0, frontend_1.SimpleListAdapter)(project_1.TranslationProject_Quality, ({ item }) => {
        return React.createElement("div", { key: item }, item);
    }),
};
exports.Translation_DropDown = {
    Project: frontend_1.GenericDropDownV3.prepareSelectable(Props_Project),
    Client: frontend_1.GenericDropDownV3.prepare(Props_Client),
    GenericTags: frontend_1.GenericDropDownV3.prepare(Props_Tag),
    LanguageLocal: frontend_1.GenericDropDownV3.prepare(Props_LanguageLocale),
    Quality: frontend_1.TS_DropDown.prepareEditable(Props_TranslationQuality)
};
const MultiSelect_GenericTag = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: frontend_2.ModuleFE_GenericTags,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(ts_styles_1.TS_Icons.x.component, { onClick: onDelete, className: 'ts-icon__small' }),
            item.label);
    },
    uiSelector: exports.Translation_DropDown.GenericTags.selectable,
});
const MultiSelect_LanguageLocale = DBItemDropDownMultiSelector_1.DBItemDropDownMultiSelector.propsV3({
    module: frontend_3.ModuleFE_LanguageLocale,
    itemRenderer: (item, onDelete) => {
        return !item ? React.createElement(React.Fragment, null, "Not Found") : React.createElement(React.Fragment, null,
            React.createElement(ts_styles_1.TS_Icons.x.component, { onClick: onDelete, className: 'ts-icon__small' }),
            item.language,
            " (",
            item.locale,
            ")");
    },
    uiSelector: exports.Translation_DropDown.LanguageLocal.selectable,
});
exports.Translation_MultiSelect = {
    GenericTags: frontend_1.TS_MultiSelect_V2.prepare(MultiSelect_GenericTag),
    LanguageLocal: frontend_1.TS_MultiSelect_V2.prepare(MultiSelect_LanguageLocale),
};
exports.Translation_Inputs = {
    Text: TS_V2_Input_1.TS_InputV2.editable({ type: 'text', trim: true, saveEvent: ['accept'] }),
    TextArea: TS_V2_TextArea_1.TS_TextAreaV2.editable({ trim: true, saveEvent: ['accept'] })
};
