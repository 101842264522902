import {AssetRenderer} from '@component/AssetRenderer';
import * as React from 'react';
import {CSSProperties} from 'react';
import {ICONSV4} from '@res/icons';
import {ComponentSync, ModuleFE_Dialog, stopPropagation} from '@nu-art/thunderstorm/frontend';
import {ModuleFE_AssetUploader} from '@nu-art/file-upload/frontend';
import {Asset} from './consts';
// import {FileInfo, FileStatus, OnFileStatusChanged, RequestKey_UploadFile, RequestKey_UploadUrl} from '@nu-art/file-upload';
import {FileInfo, FileStatus, OnFileStatusChanged,} from '@nu-art/file-upload';
import {OnRequestListener} from '@nu-art/thunderstorm';


const width = 80;
const height = 100;

const parentStyle: CSSProperties = {
	width,
	height,
	lineHeight: `${height - 3}px`,
	position: 'relative',
	backgroundColor: '#CCCCCC',
	marginTop: 5,
	marginBottom: 5,
	padding: 5,
	border: 'solid 1px',
	borderRadius: 3
};

type Props = {
	asset: Asset
	index: number
	onDelete: (index: number) => void
	onSave?: (index: number) => void
}

type State = {
	hover: boolean
}

export class AssetSubmitterRenderer
	extends ComponentSync<Props, State>
	implements OnRequestListener, OnFileStatusChanged {

	constructor(p: Props) {
		super(p);
	}

	protected deriveStateFromProps(nextProps: Props): State | undefined {
		return {hover: false};
	}

	render() {
		const asset = this.props.asset;
		const fileInfo = ModuleFE_AssetUploader.getFullFileInfo(asset.feId);

		return (
			<div
				style={parentStyle}
				onMouseEnter={() => this.setState({hover: true})}
				onMouseLeave={() => this.setState({hover: false})}>
				<div style={{position: 'absolute', maxWidth: width, maxHeight: height}}>
					<AssetRenderer
						// style={{maxWidth: width, maxHeight: height, margin: '0 auto', verticalAlign: 'middle'}}
						src={asset.url}
						mimeType={asset.mimeType}
						data={asset}/>
				</div>

				{this.state.hover &&
					<div className="clickable"
							 style={{background: '#88888888', width, height, position: 'absolute', lineHeight: 'initial'}}
							 onClick={(ev) => {
								 stopPropagation(ev);
								 ModuleFE_Dialog.show(<div>
									 <AssetRenderer
										 // style={{maxHeight: 800, maxWidth: 600, margin: '0 auto', verticalAlign: 'middle'}}
										 src={asset.url}
										 mimeType={asset.mimeType}
										 data={asset}/>
								 </div>);
							 }}>
						{this.renderDeleteButton(asset)}
						{this.props.onSave && this.renderSaveButton(asset, fileInfo)}
						{this.props.onSave && this.renderProcessButton(asset, fileInfo)}
						{this.props.onSave && this.renderState(asset, fileInfo)}
					</div>}
			</div>);
	}

	private renderDeleteButton(asset: Asset) {
		return <div className="clickable">
			{ICONSV4.bin({
				onClick: (ev) => {
					stopPropagation(ev);
					AssetRenderer.revokeFileUrl(asset.url);
					this.props.onDelete(this.props.index);
				}
			})}
		</div>;
	}

	private renderSaveButton(asset: Asset, fileInfo?: FileInfo) {
		return <div className="clickable">
			{ICONSV4.save({
				onClick: (ev) => {
					stopPropagation(ev);
					if (!fileInfo)
						this.props.onSave?.(this.props.index);
				}
			})}
		</div>;
	}

	private renderState(asset: Asset, fileInfo?: FileInfo) {
		if (!fileInfo)
			return;

		return <div>{fileInfo.status}</div>;
	}

	private renderProcessButton(asset: Asset, fileInfo?: FileInfo) {
		if (fileInfo?.status !== FileStatus.WaitingForProcessing)
			return;

		return ICONSV4.sync({
			onClick: (ev) => {
				stopPropagation(ev);
				ModuleFE_AssetUploader.processAssetManually(asset.feId);
			}
		});
	}

	__onFileStatusChanged = (id?: string | undefined) => {
		// this.logDebug(`${id} !== ${this.props.asset.id}`);
		if (id !== this.props.asset.feId)
			return;

		this.forceUpdate();
	};

	__onRequestCompleted(key: string, success: boolean, _fileIds: string | string[]): void {
		if (success)
			return;

		// if (key !== RequestKey_UploadFile && key !== RequestKey_UploadUrl)
		// 	return;

		const fileIds = typeof _fileIds === 'string' ? [_fileIds] : _fileIds;
		if (!fileIds.find(assetId => this.props.asset.feId === assetId))
			return;

		delete this.props.asset.feId;
		this.forceUpdate();
	}

}
