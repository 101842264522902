"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_ShippingOptions = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const db_def_1 = require("./db-def");
exports.ApiDef_ShippingOptions = {
    shopify: {
        sync: { method: thunderstorm_1.HttpMethod.GET, path: `v1/${db_def_1.DBDef_OrderShippingMethod.entityName}/shopify/sync` }
    }
};
