"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Variant = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ts_common_2 = require("@nu-art/ts-common");
const validators_1 = require("../../validators");
const types_1 = require("../types");
const Validator_Variant = Object.assign(Object.assign({}, types_1.ShopifyItem_validator), { weight: ts_common_2.tsValidateOptional, tagIds: ts_common_2.tsValidateOptional, productId: ts_common_1.tsValidateUniqueId, inventory: (0, ts_common_2.tsValidateIsInRange)([[-1000, 1000]]), price: (0, ts_common_2.tsValidateIsInRange)([[0, 5000]]), wholesalePrice: (0, ts_common_2.tsValidateIsInRange)([[0, 5000]], false), barcode: validators_1.tsValidateBarcode, sku: (0, ts_common_2.tsValidateRegexp)(/[0-9a-zA-z-_\.]{5,20}/, false), taxable: (0, ts_common_2.tsValidateBoolean)(), priceHistory: ts_common_2.tsValidateMustExist, shipmentsHistory: ts_common_2.tsValidateMustExist, countryOfOrigin: ts_common_2.tsValidateOptional, variantOptionIds: ts_common_2.tsValidateMustExist });
exports.DBDef_Variant = {
    validator: Validator_Variant,
    dbName: 'product-variants',
    entityName: 'product-variant',
    versions: ['1.0.1', '1.0.0'],
    uniqueKeys: ['storeId', 'shopifyId']
};
