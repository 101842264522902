"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_VirtualizedList = exports.VirtualizedList = void 0;
const React = require("react");
const react_window_1 = require("react-window");
const react_virtualized_auto_sizer_1 = require("react-virtualized-auto-sizer");
const VirtualizedList = ({ height, width, listToRender, itemHeight, selectedItem, className }) => {
    const listRef = React.useRef();
    React.useEffect(() => {
        if (selectedItem) {
            listRef.current.scrollToItem(selectedItem, 'center');
        }
    }, [selectedItem, listToRender]);
    function ItemWrapper({ index, style }) {
        return React.createElement("div", { style: style }, listToRender[index]);
    }
    return (React.createElement(react_window_1.FixedSizeList, { ref: listRef, itemSize: itemHeight, height: height, width: width, itemCount: listToRender.length, className: className, itemKey: index => index }, ItemWrapper));
};
exports.VirtualizedList = VirtualizedList;
const TS_VirtualizedList = (props) => {
    return (React.createElement(react_virtualized_auto_sizer_1.default, null, (size) => React.createElement(exports.VirtualizedList, { className: props.className, selectedItem: props.selectedItem, itemHeight: props.itemHeight, listToRender: props.listToRender, height: size.height, width: size.width })));
};
exports.TS_VirtualizedList = TS_VirtualizedList;
