import {DB_CustomerOrder} from '@app/shared';


export function getOrderStatusClass(order?: Partial<DB_CustomerOrder>) {
	if (!order)
		return;

	if (order.status?.includes('in-progress') && order.tags?.includes('pre-order'))
		return `customer-order__pre-order`;

	if (order.tags?.includes('returning-items'))
		return `customer-order__returning-items`;

	return `customer-order__${order.status}`;
}

export function getOrderStatusLabel(order: Partial<DB_CustomerOrder>) {
	const status = order.status;
	if (!status)
		return '';

	if (status.includes('in-progress'))
		return 'Need to ship';

	if (status.includes('pending-payment'))
		return 'Pending Payment';

	if (status.includes('cancelled'))
		return 'Cancelled';

	if (status.includes('completed'))
		return 'Completed';

}

