"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.composeDbObjectUniqueId = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const composeDbObjectUniqueId = (item, keys) => {
    const _unique = keys.reduce((aggregatedValues, _key) => {
        if (!(0, ts_common_1.exists)(item[_key]))
            throw new ts_common_1.MUSTNeverHappenException(`Unique key missing from db item!\nkey: ${_key}\nitem:${(0, ts_common_1.__stringify)(item, true)}`);
        return aggregatedValues + String(item[_key]);
    }, '');
    return (0, ts_common_1.md5)(_unique);
};
exports.composeDbObjectUniqueId = composeDbObjectUniqueId;
