"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogClient_Terminal = exports.NoColor = void 0;
const types_1 = require("./types");
const LogClient_1 = require("./LogClient");
const utils_1 = require("./utils");
exports.NoColor = '\x1b[0m';
class LogClient_Terminal_class extends LogClient_1.LogClient {
    getColor(level, bold = false) {
        let color;
        switch (level) {
            case types_1.LogLevel.Verbose:
                color = '\x1b[90m';
                break;
            case types_1.LogLevel.Debug:
                color = '\x1b[34m';
                break;
            case types_1.LogLevel.Info:
                color = '\x1b[32m';
                break;
            case types_1.LogLevel.Warning:
                color = '\x1b[33m';
                break;
            case types_1.LogLevel.Error:
                color = '\x1b[31m';
                break;
        }
        return color + (bold ? '\x1b[1m' : '');
    }
    logMessage(level, bold, prefix, toLog) {
        const color = this.getColor(level, bold);
        const paramsAsStrings = (0, utils_1._logger_convertLogParamsToStrings)(toLog);
        console.log((0, utils_1._logger_indentNewLineBy)(color + prefix, paramsAsStrings.join(" ")) + exports.NoColor);
    }
}
exports.LogClient_Terminal = new LogClient_Terminal_class();
