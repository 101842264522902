"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiWithBody = exports.apiWithQuery = void 0;
const ModuleFE_XHR_1 = require("../modules/http/ModuleFE_XHR");
function apiWithQuery(apiDef, onCompleted, onError) {
    // @ts-ignore
    return (params) => {
        return ModuleFE_XHR_1.ModuleFE_XHR
            .createRequest(apiDef)
            .setUrlParams(params)
            .setTimeout(apiDef.timeout || 10000)
            .setOnError(onError)
            .setOnCompleted(onCompleted);
    };
}
exports.apiWithQuery = apiWithQuery;
function apiWithBody(apiDef, onCompleted, onError) {
    // @ts-ignore
    return (body) => {
        return ModuleFE_XHR_1.ModuleFE_XHR
            .createRequest(apiDef)
            .setBodyAsJson(body)
            .setTimeout(apiDef.timeout || 10000)
            .setOnError(onError)
            .setOnCompleted(onCompleted);
    };
}
exports.apiWithBody = apiWithBody;
