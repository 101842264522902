"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Toggler = void 0;
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
const Layouts_1 = require("../Layouts");
const tools_1 = require("../../utils/tools");
require("./TS_Toggler.scss");
class TS_Toggler extends ComponentSync_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.onValueChange = (value) => {
            var _a, _b;
            const _value = value === this.state.value && !this.props.alwaysSelected ? undefined : value;
            (_b = (_a = this.props).onValueChange) === null || _b === void 0 ? void 0 : _b.call(_a, _value);
            this.setState({ value: _value });
            this.reDeriveState();
        };
        this.cycleToggler = () => {
            const options = this.props.options;
            const currentOptionIndex = options.findIndex((value, index) => value === this.state.value);
            const nextValue = options.length - 1 > currentOptionIndex ? options[currentOptionIndex + 1]
                : this.props.alwaysSelected ? options[0]
                    : undefined;
            this.onValueChange(nextValue);
        };
        this.renderButton = () => {
            let buttonDir = undefined;
            if (this.state.value === this.props.options[0])
                buttonDir = 'left';
            if (this.state.value === this.props.options[1])
                buttonDir = 'right';
            const buttonClassName = (0, tools_1._className)('ts-toggler__toggler-button', buttonDir);
            return React.createElement("div", { className: 'ts-toggler__toggler', onClick: () => !this.props.disabled && this.cycleToggler() },
                React.createElement("div", { className: buttonClassName }));
        };
        this.renderOption = (option) => {
            var _a;
            const className = (0, tools_1._className)('ts-toggler__option', option === ((_a = this.state) === null || _a === void 0 ? void 0 : _a.value) ? 'active' : undefined);
            return React.createElement("span", { className: className, onClick: () => !this.props.disabled && this.onValueChange(option) }, option);
        };
    }
    deriveStateFromProps(nextProps) {
        const state = {};
        //First derive
        if (!this.state)
            state.value = this.props.defaultValue;
        else
            state.value = this.props.value || this.state.value;
        if (!state.value && nextProps.alwaysSelected)
            state.value = nextProps.options[0];
        state.disabled = nextProps.disabled;
        return state;
    }
    render() {
        const className = (0, tools_1._className)('ts-toggler', this.state.disabled ? 'disabled' : undefined);
        return React.createElement(Layouts_1.LL_H_C, { className: className },
            this.renderOption(this.props.options[0]),
            this.renderButton(),
            this.renderOption(this.props.options[1]));
    }
}
exports.TS_Toggler = TS_Toggler;
