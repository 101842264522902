"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Table = void 0;
const tools_1 = require("../../utils/tools");
require("./TS_Table.scss");
const React = require("react");
class TS_Table extends React.Component {
    constructor(p) {
        super(p);
    }
    render() {
        const tableProps = typeof this.props.table === 'function' ? this.props.table() : this.props.table;
        const tableBodyProps = typeof this.props.body === 'function' ? this.props.body() : this.props.body;
        const tableHeadProps = typeof this.props.head === 'function' ? this.props.head() : this.props.head;
        return (React.createElement("table", Object.assign({ id: this.props.id }, tableProps, { className: (0, tools_1._className)('ts-table', tableProps === null || tableProps === void 0 ? void 0 : tableProps.className) }),
            React.createElement("thead", Object.assign({}, tableHeadProps, { className: (0, tools_1._className)('ts-table__head', tableHeadProps === null || tableHeadProps === void 0 ? void 0 : tableHeadProps.className) }), this.renderTableHeader()),
            React.createElement("tbody", Object.assign({}, tableBodyProps, { className: (0, tools_1._className)('ts-table__body', tableBodyProps === null || tableBodyProps === void 0 ? void 0 : tableBodyProps.className) }), this.renderTableBody())));
    }
    renderTableHeader() {
        if (!this.props.headerRenderer)
            return;
        let renderers;
        if (typeof this.props.headerRenderer === 'object')
            renderers = this.props.headerRenderer;
        else
            renderers = this.props.header.reduce((toRet, headerProp) => {
                const prop = typeof headerProp === 'object' ? headerProp.header : headerProp;
                toRet[prop] = this.props.headerRenderer;
                return toRet;
            }, {});
        const tablePropsTR = typeof this.props.tr === 'function' ? this.props.tr(undefined, -1) : this.props.tr;
        const classNameTR = (0, tools_1._className)('ts-table__tr', tablePropsTR === null || tablePropsTR === void 0 ? void 0 : tablePropsTR.className);
        return (React.createElement("tr", Object.assign({ key: `${this.props.id}-0` }, tablePropsTR, { className: classNameTR }), this.props.header.map((header, index) => {
            var _a;
            const prop = typeof header === 'object' ? header.header : header;
            const tablePropsTH = typeof this.props.th === 'function' ? this.props.th(prop) : this.props.th;
            let classNameTH = (0, tools_1._className)('ts-table__th', tablePropsTH === null || tablePropsTH === void 0 ? void 0 : tablePropsTH.className);
            const style = {};
            if (typeof header === 'object') {
                const width = typeof header.widthPx === 'number' ? `${header.widthPx}px` : header.widthPx;
                classNameTH = (0, tools_1._className)('ts-table__th', tablePropsTH === null || tablePropsTH === void 0 ? void 0 : tablePropsTH.className, header.className);
                style.width = width;
                style.minWidth = typeof header.widthPx === 'number' ? width : '';
            }
            return React.createElement("th", Object.assign({ key: `${this.props.id}-${index}` }, tablePropsTH, { className: classNameTH, style: style }), (_a = renderers[prop]) === null || _a === void 0 ? void 0 : _a.call(renderers, prop));
        })));
    }
    renderTableBody() {
        let renderers;
        if (typeof this.props.cellRenderer === 'object')
            renderers = this.props.cellRenderer;
        else
            renderers = this.props.header.reduce((toRet, headerProp) => {
                const prop = typeof headerProp === 'object' ? headerProp.header : headerProp;
                toRet[prop] = this.props.cellRenderer;
                return toRet;
            }, {});
        return this.props.rows.map((row, rowIndex) => {
            const tablePropsTR = typeof this.props.tr === 'function' ? this.props.tr(row, rowIndex) : this.props.tr;
            const classNameTR = (0, tools_1._className)('ts-table__tr', tablePropsTR === null || tablePropsTR === void 0 ? void 0 : tablePropsTR.className);
            return (React.createElement("tr", Object.assign({ key: `${this.props.id}-${rowIndex}` }, tablePropsTR, { className: classNameTR }), this.props.header.map((header, columnIndex) => {
                var _a;
                const prop = typeof header === 'object' ? header.header : header;
                const tablePropsTD = typeof this.props.td === 'function' ? this.props.td(row, rowIndex, prop) : this.props.td;
                let classNameTD = (0, tools_1._className)('ts-table__td', tablePropsTD === null || tablePropsTD === void 0 ? void 0 : tablePropsTD.className, typeof header === 'object' && header.className);
                const style = {};
                if (!this.props.headerRenderer && typeof header === 'object' && rowIndex === 0) {
                    const width = typeof header.widthPx === 'number' ? `${header.widthPx}px` : header.widthPx;
                    style.width = width;
                    classNameTD = (0, tools_1._className)('ts-table__td', tablePropsTD === null || tablePropsTD === void 0 ? void 0 : tablePropsTD.className, header.className);
                    style.minWidth = typeof header.widthPx === 'number' ? width : '';
                }
                return React.createElement("td", Object.assign({ key: `${this.props.id}-${columnIndex}` }, tablePropsTD, { className: classNameTD, style: style }), (_a = renderers[prop]) === null || _a === void 0 ? void 0 : _a.call(renderers, prop, row, rowIndex));
            })));
        });
    }
}
TS_Table.defaultProps = {
    actionHeaderRenderer: (action) => React.createElement("div", null, action)
};
exports.TS_Table = TS_Table;
