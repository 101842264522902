"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.keepPartialObject = exports.keepDBObjectKeys = exports.deleteKeysObject = exports.removeDBObjectKeys = exports.dbObjectToId_V3 = exports.dbObjectToId = exports.KeysOfDB_Object = void 0;
const object_tools_1 = require("./object-tools");
const tools_1 = require("./tools");
exports.KeysOfDB_Object = ['_id', '_v', '__created', '__updated'];
function dbObjectToId(i) {
    return i._id;
}
exports.dbObjectToId = dbObjectToId;
function dbObjectToId_V3(i) {
    return i._id;
}
exports.dbObjectToId_V3 = dbObjectToId_V3;
function removeDBObjectKeys(instance) {
    const _instance = (0, object_tools_1.deepClone)(instance);
    exports.KeysOfDB_Object.forEach(key => delete _instance[key]);
    return _instance;
}
exports.removeDBObjectKeys = removeDBObjectKeys;
function deleteKeysObject(instance, keysToRemove) {
    const _instance = (0, object_tools_1.deepClone)(instance);
    keysToRemove.forEach(key => delete _instance[key]);
    return _instance;
}
exports.deleteKeysObject = deleteKeysObject;
function keepDBObjectKeys(instance) {
    return keepPartialObject(instance, exports.KeysOfDB_Object);
}
exports.keepDBObjectKeys = keepDBObjectKeys;
function keepPartialObject(instance, keys) {
    return keys.reduce((objectToRet, key) => {
        if ((0, tools_1.exists)(instance[key]))
            objectToRet[key] = instance[key];
        return objectToRet;
    }, {});
}
exports.keepPartialObject = keepPartialObject;
