"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_CustomerOrder = exports.DBDef_CustomerOrder_Versions = exports.IndexKey_CustomerOrders_Customer = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const shopify_1 = require("../../../shopify");
const consts_1 = require("./consts");
const delivery_courier_1 = require("../../../delivery-courier");
exports.IndexKey_CustomerOrders_Customer = 'by-customer';
const Validator_CustomerOrder = Object.assign(Object.assign({}, shopify_1.ShopifyItem_validator), { priority: (0, ts_common_1.tsValidateNonMandatoryObject)({
        message: (0, ts_common_1.tsValidateString)(),
        value: (0, ts_common_1.tsValidateIsInRange)([[0, 10]])
    }), deliveryDetails: ts_common_1.tsValidateOptional, packagingStatus: {
        isReady: (0, ts_common_1.tsValidateBoolean)(false),
        packagesSize: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateEnum)(delivery_courier_1.PackageType_Size))
    }, messages: ts_common_1.tsValidateOptional, orderNumber: ts_common_1.tsValidateMustExist, timestampCreated: ts_common_1.tsValidateMustExist, timestampCompleted: ts_common_1.tsValidateMustExist, cart: ts_common_1.tsValidateOptional, token: ts_common_1.tsValidateMustExist, checkoutToken: ts_common_1.tsValidateMustExist, gateway: ts_common_1.tsValidateOptional, refunds: ts_common_1.tsValidateMustExist, fulfillments: ts_common_1.tsValidateMustExist, note: ts_common_1.tsValidateOptional, tags: ts_common_1.tsValidateOptional, status: (0, ts_common_1.tsValidateArray)((0, ts_common_1.tsValidateValue)(consts_1.OrderStatuses)), customerId: ts_common_1.tsValidateMustExist, shippingAddress: ts_common_1.tsValidateOptional, missingItems: ts_common_1.tsValidateOptional, billingAddress: ts_common_1.tsValidateMustExist, shippingOptionId: ts_common_1.tsValidateOptional, shippingOption: ts_common_1.tsValidateOptional, isPickup: (0, ts_common_1.tsValidateBoolean)(false), paid: ts_common_1.tsValidateMustExist, locale: ts_common_1.tsValidateMustExist, itemIds: ts_common_1.tsValidateMustExist });
exports.DBDef_CustomerOrder_Versions = ['1.0.2', '1.0.1', '1.0.0'];
exports.DBDef_CustomerOrder = {
    validator: Validator_CustomerOrder,
    dbName: 'customer-orders',
    entityName: 'customer-order',
    upgradeChunksSize: 50,
    versions: exports.DBDef_CustomerOrder_Versions,
    indices: [{ id: exports.IndexKey_CustomerOrders_Customer, keys: ['customerId'] }],
    uniqueKeys: ['storeId', 'shopifyId']
};
