"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntryComponentLoadingModule = exports.PageLoadingModule_Class = void 0;
const ts_common_1 = require("@nu-art/ts-common");
// noinspection TypeScriptPreferShortImport
const ModuleFE_XHR_1 = require("../http/ModuleFE_XHR");
const ModuleFE_BrowserHistory_1 = require("../ModuleFE_BrowserHistory");
const shared_1 = require("../../../shared");
class PageLoadingModule_Class extends ts_common_1.Module {
    constructor() {
        super(...arguments);
        this.injected = {};
    }
    loadScript(src, progressListener) {
        const apiDef = {
            method: shared_1.HttpMethod.GET,
            baseUrl: ModuleFE_BrowserHistory_1.ModuleFE_BrowserHistory.getOrigin(),
            path: src
        };
        ModuleFE_XHR_1.ModuleFE_XHR
            .createRequest(apiDef)
            .setOnProgressListener((ev) => {
            const progress = ev.loaded / ev.total;
            progressListener(progress);
        })
            .execute(response => {
            const divElement = document.createElement('script');
            divElement.innerHTML = response;
            divElement.id = src;
            divElement.async = true;
            this.injected[src] = divElement;
        });
    }
    getNode(src) {
        return this.injected[src];
    }
}
exports.PageLoadingModule_Class = PageLoadingModule_Class;
exports.EntryComponentLoadingModule = new PageLoadingModule_Class();
