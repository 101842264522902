"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_ProductTag = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ts_common_2 = require("@nu-art/ts-common");
const validators_1 = require("../../validators");
const types_1 = require("../types");
const Validator_ProductTag = Object.assign(Object.assign({}, types_1.StoreItem_validator), { title: validators_1.tsValidateStringOneLine, keywordIds: (0, ts_common_2.tsValidateArray)(ts_common_1.tsValidateUniqueId, false) });
exports.DBDef_ProductTag = {
    validator: Validator_ProductTag,
    dbName: 'product-tags',
    entityName: 'product-tag',
    uniqueKeys: ['storeId', 'title'],
    versions: ['1.0.1', '1.0.0']
};
