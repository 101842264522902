import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {DB_Customer, DBDef_CustomerContact,} from '@app/shared';
import {BaseDB_ShopifyApiCallerV2} from '@modules/BaseDB_ShopifyApiCaller';


export interface OnCustomersUpdated {
	__onCustomersUpdated: (...params: ApiCallerEventType<DB_Customer>) => void;
}

export const dispatch_onCustomersUpdated = new ThunderDispatcher<OnCustomersUpdated, '__onCustomersUpdated'>('__onCustomersUpdated');

export class ModuleFE_CustomerContact_Class
	extends BaseDB_ShopifyApiCallerV2<DB_Customer, 'storeId' | 'shopifyId'> {

	constructor() {
		super(DBDef_CustomerContact, dispatch_onCustomersUpdated);
	}
}

export const ModuleFE_CustomerContact = new ModuleFE_CustomerContact_Class();
export const ModuleFE_CustomerContact_ = ModuleFE_CustomerContact as unknown as ModuleFE_BaseApi<any, any>;


