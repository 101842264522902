"use strict";
/*
 * User secured registration and login management system..
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_Login = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
require("./Component_Login.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ModuleFE_Account_1 = require("../../modules/ModuleFE_Account");
const consts_1 = require("../../core/consts");
const form = {
    email: {
        type: 'text',
        hint: 'email',
        label: 'Email',
    },
    password: {
        type: 'password',
        hint: '****',
        label: 'Password',
    }
};
class Component_Login extends frontend_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.renderErrorMessages = () => {
            var _a;
            if (!((_a = this.state.errorMessages) === null || _a === void 0 ? void 0 : _a.length))
                return '';
            return React.createElement("ul", { className: 'ts-account__error-messages' }, this.state.errorMessages.map((message, i) => {
                return React.createElement("li", { key: i }, message);
            }));
        };
        this.onValueChanged = (value, id) => {
            const data = Object.assign({}, this.state.data);
            data[id] = value;
            this.setState({ data, errorMessages: undefined });
        };
        this.loginClicked = async () => {
            const data = this.state.data;
            const errors = (0, ts_common_1._keys)(form).map(key => {
                const field = form[key];
                return data[key] ? undefined : `  * missing ${field.label}`;
            }).filter(error => !!error);
            // const validateError = this.props.validate(data);
            // if (validateError)
            // 	addItemToArray(errors, validateError);
            if (errors.length > 0)
                return frontend_1.ModuleFE_Toaster.toastError(`Wrong input:\n${errors.join('\n')}`);
            try {
                await ModuleFE_Account_1.ModuleFE_Account.vv1.login(Object.assign(Object.assign({}, this.state.data), { deviceId: consts_1.StorageKey_DeviceId.get() })).executeSync();
            }
            catch (err) {
                this.setState({ errorMessages: ['Email or password incorrect'] });
            }
        };
    }
    deriveStateFromProps(nextProps, state) {
        var _a;
        state !== null && state !== void 0 ? state : (state = this.state ? Object.assign({}, this.state) : {});
        (_a = state.data) !== null && _a !== void 0 ? _a : (state.data = {});
        return state;
    }
    render() {
        const data = this.state.data;
        return React.createElement(frontend_1.LL_V_C, { className: "ts-account__authenticate" },
            (0, ts_common_1._keys)(form).map((key, i) => {
                const field = form[key];
                return React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: field.label, key: i },
                    React.createElement(frontend_1.TS_Input, { id: key, value: data[key], type: field.type, onChange: this.onValueChanged, onAccept: this.loginClicked }));
            }),
            this.renderErrorMessages(),
            React.createElement(frontend_1.TS_BusyButton, { onClick: this.loginClicked, className: `clickable ts-account__action-button` }, "Login"));
    }
}
exports.Component_Login = Component_Login;
