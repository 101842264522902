"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Window = exports.ModuleFE_Window_Class = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const thunder_dispatcher_1 = require("../core/thunder-dispatcher");
const dispatch_WindowResized = new thunder_dispatcher_1.ThunderDispatcher('__onWindowResized');
class ModuleFE_Window_Class extends ts_common_1.Module {
    constructor() {
        super(...arguments);
        this.onWindowResized = () => {
            dispatch_WindowResized.dispatchUI();
        };
    }
    init() {
        window.addEventListener('resize', this.onWindowResized);
    }
}
exports.ModuleFE_Window_Class = ModuleFE_Window_Class;
exports.ModuleFE_Window = new ModuleFE_Window_Class();
