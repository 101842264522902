/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// tslint:disable:no-import-side-effect
import './res/styles/index.scss';
import {ModuleFE_ActionProcessor, ModuleFE_Locale, ModuleFE_SyncManager, ModuleFE_Window, Thunder} from '@nu-art/thunderstorm/frontend';
import {FirebaseAnalyticsModule} from '@nu-art/firebase/frontend';
import {ModulePackFE_PushPubSub} from '@nu-art/push-pub-sub/frontend';
import {
	ModuleFE_App,
	ModuleFE_Couriers,
	ModuleFE_CustomerContact,
	ModuleFE_CustomerOrder,
	ModuleFE_InventoryItems,
	ModuleFE_MessageTemplates,
	ModuleFE_Payments,
	ModuleFE_PDF,
	ModuleFE_Products,
	ModuleFE_ProductTags,
	ModuleFE_ProductTemplate,
	ModuleFE_ProductVariantConfigs,
	ModuleFE_ProductVariants,
	ModuleFE_Shipments,
	ModuleFE_ShippingMethod,
	ModuleFE_ShopifyAssets,
	ModuleFE_StoreInventory,
	ModuleFE_Stores,
	ModuleFE_Translations,
	ModuleFE_VariantOptions,
	ModuleFE_Vendors
} from '@modules/index';
import {ModulePackFE_Permissions} from '@nu-art/permissions/frontend';
import {ModulePackFE_FileUploader} from '@nu-art/file-upload/frontend';
import {AssetRenderer, AssetRendererProps} from '@component/AssetRenderer';
import {MimeType_csv} from '@nu-art/ts-common';
import * as React from 'react';
import {LanguageEN, Locale_EN} from '@res/localization/en';
import {LanguageNL, Locale_NL} from '@res/localization/nl';
import {LanguageHE, Locale_HE} from '@res/localization/he';
import {Page_Main} from '@page/Page_Main';
import './App.scss';
import {ModulePack_ThunderstormFE} from '@nu-art/thunderstorm/frontend/core/thunderstorm-modulepack';
import {ThunderstormDefaultApp} from '@nu-art/thunderstorm/frontend/core/ThunderstormDefaultApp';
import {ModuleFE_Theme} from '@modules/ModuleFE_Themes';
import {Module_KeyBinder} from '@pah/customer-order/Page_CustomerOrder';
import {ModuleFE_AutoComplete} from '@app/tools/auto-complete/frontend';
import {ModulePackFE_Accounts} from '@nu-art/user-account/frontend';
import {ModulePackFE_TranslationInfra} from '@infra/translations/frontend';


// ModuleFE_LocalStorage.setPersistentDebugState();
const modules = [
	ModuleFE_ActionProcessor,
	FirebaseAnalyticsModule,
	ModuleFE_Window,
	ModuleFE_PDF,

	ModuleFE_Stores,
	ModuleFE_Translations,
	ModuleFE_ProductTemplate,

	ModuleFE_Products,
	ModuleFE_ShippingMethod,
	ModuleFE_CustomerOrder,
	ModuleFE_CustomerContact,
	ModuleFE_Couriers,
	ModuleFE_Payments,
	ModuleFE_AutoComplete,
	ModuleFE_Shipments,
	ModuleFE_ProductTags,
	ModuleFE_Vendors,
	ModuleFE_VariantOptions,
	ModuleFE_ProductVariantConfigs,
	ModuleFE_ProductVariants,
	ModuleFE_StoreInventory,
	ModuleFE_InventoryItems,
	ModuleFE_ShopifyAssets,
	ModuleFE_MessageTemplates,

	ModuleFE_SyncManager,
	ModuleFE_Locale,
	ModuleFE_App,
	ModuleFE_Theme,
];

ModuleFE_Locale.setDefaultConfig({
	languages: {
		[LanguageHE.shortLocale]: {
			language: LanguageHE,
			texts: Locale_HE
		},
		[LanguageEN.shortLocale]: {
			language: LanguageEN,
			texts: Locale_EN
		},
		[LanguageNL.shortLocale]: {
			language: LanguageNL,
			texts: Locale_NL
		}
	},
	defaultLocal: LanguageEN.shortLocale
});

AssetRenderer.setCustomRenderers({
	[MimeType_csv]: (p: AssetRendererProps) => <div>
		<img src={require('@res/img/file-type--csv.png')}/>
	</div>
});

Module_KeyBinder.mount();
new Thunder()
	.setConfig(require('./config').config)
	.addModulePack(ModulePack_ThunderstormFE)
	.addModulePack(ModulePackFE_Accounts)
	.addModulePack(ModulePackFE_PushPubSub)
	.addModulePack(ModulePackFE_FileUploader)
	.addModulePack(ModulePackFE_Permissions)
	.addModulePack(ModulePackFE_TranslationInfra)
	.addModulePack(modules)
	.setMainApp(ThunderstormDefaultApp, {rootRoute: Page_Main.Route})
	.build();


