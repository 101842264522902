"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Thunderstorm = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ModuleFE_Toaster_1 = require("../component-modules/ModuleFE_Toaster");
const ModuleFE_BrowserHistory_1 = require("./ModuleFE_BrowserHistory");
const types_1 = require("../../shared/types");
const tools_1 = require("../utils/tools");
const ModuleFE_XHR_1 = require("./http/ModuleFE_XHR");
const clearWebsiteDataDispatcher_1 = require("./clearWebsiteDataDispatcher");
class ModuleFE_Thunderstorm_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.setDefaultConfig({ appName: 'Thunderstorm-WebApp' });
    }
    init() {
        this.setAppName(this.config.appName);
        this.setChromeThemeColor(this.config.themeColor);
    }
    setAppName(appName) {
        document.title = appName;
    }
    printDiv(div, bodyAttributes) {
        var _a;
        const themeValue = document.body.getAttribute('theme');
        if (themeValue)
            (bodyAttributes || (bodyAttributes = [])).push({ key: 'theme', value: themeValue });
        //create, and remove iframe from body dynamically!!
        const printingIFrame = document.createElement('iframe');
        printingIFrame.style.width = '0';
        printingIFrame.style.height = '0';
        printingIFrame.style.position = 'absolute';
        const body = document.getElementsByTagName('body')[0];
        body === null || body === void 0 ? void 0 : body.appendChild(printingIFrame);
        this._populatePrintFrame(printingIFrame, div, bodyAttributes);
        (_a = printingIFrame.contentWindow) === null || _a === void 0 ? void 0 : _a.addEventListener('afterprint', () => {
            body.removeChild(printingIFrame);
        });
    }
    _populatePrintFrame(printingIFrame, div, bodyAttributes) {
        var _a, _b, _c, _d, _e;
        const printingContentWindow = printingIFrame.contentWindow;
        if (!printingContentWindow)
            return this.logWarning('printingContentWindow is undefined');
        //Populate the window document
        const containerText = '<div style="display: none">printDiv function!</div>';
        printingContentWindow.document.open();
        printingContentWindow.document.write(containerText);
        //Grab essential elements
        const html = (_a = printingContentWindow.document.getElementsByTagName('html')) === null || _a === void 0 ? void 0 : _a[0];
        const body = (_b = html.getElementsByTagName('body')) === null || _b === void 0 ? void 0 : _b[0];
        //Add body attributes
        bodyAttributes === null || bodyAttributes === void 0 ? void 0 : bodyAttributes.forEach(att => {
            body.setAttribute(att.key, att.value);
        });
        //Clone and append the printed item to the body
        const toAppend = div.cloneNode(true);
        body.appendChild(toAppend);
        //Copy head content from app to the iframe (for css and metas)
        html.removeChild((_c = html.getElementsByTagName('head')) === null || _c === void 0 ? void 0 : _c[0]);
        html === null || html === void 0 ? void 0 : html.insertBefore((_e = (_d = window.document.getElementsByTagName('head')) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.cloneNode(true), body);
        //Close document for writing and call the print dialog
        printingContentWindow.document.close();
        printingContentWindow.focus();
        setTimeout(async () => printingContentWindow.print(), 1500);
        return body;
    }
    setChromeThemeColor(themeColor) {
        let themeTag = document.head.querySelector('meta[name="theme-color"]');
        if (!themeTag) {
            themeTag = document.createElement('meta');
            themeTag.name = 'theme-color';
            document.getElementsByTagName('head')[0].appendChild(themeTag);
        }
        themeTag.setAttribute('content', themeColor);
    }
    async clearWebsiteData(resync = false) {
        return clearWebsiteDataDispatcher_1.dispatch_onClearWebsiteData.dispatchModuleAsync(resync);
    }
    async copyToClipboard(toCopy) {
        try {
            await navigator.clipboard.writeText(toCopy);
            ModuleFE_Toaster_1.ModuleFE_Toaster.toastInfo(`Copied to Clipboard:\n"${toCopy}"`);
        }
        catch (e) {
            ModuleFE_Toaster_1.ModuleFE_Toaster.toastError(`Failed to copy to Clipboard:\n"${toCopy}"`);
        }
    }
    async readFileContent(file) {
        const fullUrl = URL.createObjectURL(file);
        const content = ModuleFE_XHR_1.ModuleFE_XHR.createRequest({ method: types_1.HttpMethod.GET, fullUrl, path: '' }).executeSync();
        URL.revokeObjectURL(fullUrl);
        return content;
    }
    async writeToClipboard(imageAsBase64, contentType = 'image/png') {
        try {
            // const clipboardItem = new ClipboardItem({'image/png': imageAsBase64});
            const clipboardItem = new ClipboardItem({ contentType: await (0, tools_1.base64ToBlob)(imageAsBase64) });
            await navigator.clipboard.write([clipboardItem]);
            // TODO: Render Blob in toast
            ModuleFE_Toaster_1.ModuleFE_Toaster.toastInfo(`Copied image Clipboard`);
        }
        catch (error) {
            ModuleFE_Toaster_1.ModuleFE_Toaster.toastError(`Failed to copy image to Clipboard`);
        }
    }
    getAppName() {
        return this.config.appName;
    }
    openUrl(url, target) {
        if (!window)
            throw new ts_common_1.BadImplementationException('no window in vm context');
        let urlObj = url;
        if (typeof urlObj === 'string')
            urlObj = { url: urlObj };
        const params = urlObj.params || {};
        const calculatedParams = (0, ts_common_1._keys)(params || {}).reduce((toRet, key) => {
            const param = params[key];
            if (typeof param === 'function') {
                const value = param === null || param === void 0 ? void 0 : param();
                if (value)
                    toRet[key] = value;
            }
            else
                toRet[key] = param;
            return toRet;
        }, {});
        window.open((0, ModuleFE_BrowserHistory_1.composeURL)(urlObj.url, calculatedParams), target || '_self');
    }
    downloadFile(props) {
        if (!document)
            return;
        const element = document.createElement('a');
        if (props.content) {
            let content;
            if (typeof props.content === 'string')
                content = encodeURIComponent(props.content);
            else
                content = URL.createObjectURL(props.content);
            element.setAttribute('href', `data:${props.mimeType || 'text/text'};charset=${props.charset || 'utf-8'},${content}`);
        }
        else {
            element.setAttribute('href', props.url);
        }
        element.setAttribute('download', `${props.fileName}`);
        element.click();
    }
}
exports.ModuleFE_Thunderstorm = new ModuleFE_Thunderstorm_Class();
