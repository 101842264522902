import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';

import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {DB_VariantOption, DBDef_VariantOption} from '@app/shared';


export interface OnVariantOptionsUpdated {
	__onVariantOptionsUpdated: (...params: ApiCallerEventType<DB_VariantOption>) => void;
}

export const dispatch_onVariantOptionListChanged = new ThunderDispatcher<OnVariantOptionsUpdated, '__onVariantOptionsUpdated'>('__onVariantOptionsUpdated');

export class ModuleFE_VariantOptions_Class
	extends ModuleFE_BaseApi<DB_VariantOption, 'storeId' | 'label'> {

	constructor() {
		super(DBDef_VariantOption, dispatch_onVariantOptionListChanged);
	}
}

export const ModuleFE_VariantOptions = new ModuleFE_VariantOptions_Class();
export const ModuleFE_VariantOptions_ = ModuleFE_VariantOptions as unknown as ModuleFE_BaseApi<any, any>;
