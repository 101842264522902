"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_TranslationProvider = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const Validator_ModifiableProps = {
    accountId: ts_common_1.tsValidateUniqueId,
    localeIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    platformIds: ts_common_1.tsValidator_arrayOfUniqueIds,
    tagIds: ts_common_1.tsValidator_arrayOfUniqueIds
};
const Validator_GeneratedProps = {};
exports.DBDef_TranslationProvider = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbName: 'translation-provider',
    entityName: 'translation-provider',
};
