"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_RoutingV2 = exports.TS_NavLink = void 0;
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const ts_common_1 = require("@nu-art/ts-common");
const LocationChangeListener_1 = require("./LocationChangeListener");
const tools_1 = require("../../utils/tools");
class ModuleFE_RoutingV2_Class extends ts_common_1.Module {
    constructor() {
        // ######################## Inner Data ########################
        super(...arguments);
        this.routesMapByKey = {};
        this.routesMapByPath = {};
        this.routeBuilder = (route, _path = '') => {
            var _a;
            const path = `${_path}/`;
            this.routesMapByKey[route.key] = { route, fullPath: _path };
            this.routesMapByPath[_path] = route;
            const routes = route.children || [];
            const indicesRoutes = routes === null || routes === void 0 ? void 0 : routes.filter(route => route.index);
            if (indicesRoutes && indicesRoutes.length > 1)
                throw new ts_common_1.BadImplementationException(`more than one index route found in ${path}: ${indicesRoutes.map(r => r.key).join(', ')}`);
            const indexRoute = indicesRoutes === null || indicesRoutes === void 0 ? void 0 : indicesRoutes[0];
            if (indexRoute)
                this.logDebug(`index: ${path}${indexRoute.path}`);
            if (route.fallback)
                this.logDebug(`fallback: ${path}`);
            let _indexRoute;
            if (indexRoute)
                if (indexRoute.path)
                    _indexRoute = React.createElement(react_router_dom_1.Route, { index: true, element: React.createElement(react_router_dom_1.Navigate, { to: `${path}${indexRoute.path}` }) });
                else {
                    _indexRoute = React.createElement(react_router_dom_1.Route, { index: true, Component: indexRoute.Component, element: indexRoute.element });
                    (0, ts_common_1.removeItemFromArray)(routes, indexRoute);
                }
            return React.createElement(react_router_dom_1.Route, { key: route.key, path: route.path, Component: route.Component, element: route.element },
                _indexRoute, (_a = route.children) === null || _a === void 0 ? void 0 :
                _a.filter(route => { var _a, _b; return (_b = (_a = route.enabled) === null || _a === void 0 ? void 0 : _a.call(route)) !== null && _b !== void 0 ? _b : true; }).map(route => this.routeBuilder(route, `${path}${route.path}`)),
                route.fallback && React.createElement(react_router_dom_1.Route, { path: "*", element: React.createElement(react_router_dom_1.Navigate, { to: path }) }));
        };
    }
    // constructor() {
    // 	super();
    // 	this.setMinLevel(LogLevel.Debug);
    // }
    // ######################## Public Functions ########################
    goToRoute(route, params) {
        const fullPath = this.getFullPath(route.key);
        try {
            const url = (0, ts_common_1.composeUrl)(fullPath, params);
            if (window.location.href === url)
                return this.logWarning(`attempting to set same route: ${url}`);
            this.navigate(url);
        }
        catch (e) {
            this.logError(`cannot resolve route for route: `, route, e);
            throw e;
        }
    }
    generateRoutes(rootRoute) {
        const element = this.routeBuilder(rootRoute);
        return React.createElement(react_router_dom_1.BrowserRouter, null,
            React.createElement(LocationChangeListener_1.LocationChangeListener, null),
            React.createElement(react_router_dom_1.Routes, null, element));
    }
    getRouteByKey(routeKey) {
        var _a;
        return (_a = this.routesMapByKey[routeKey]) === null || _a === void 0 ? void 0 : _a.route;
    }
    getFullPath(routeKey) {
        const route = this.routesMapByKey[routeKey];
        if (!route)
            throw new ts_common_1.BadImplementationException(`Cannot find full path for route key: ${routeKey}`);
        return route.fullPath;
    }
    getCurrentRouteKey() {
        return this.routesMapByPath[window.location.pathname];
    }
    setNavigate(navigate) {
        this.navigate = navigate;
    }
}
const TS_NavLink = (props) => {
    const { route, children } = props, _props = __rest(props, ["route", "children"]);
    const fullPath = exports.ModuleFE_RoutingV2.getFullPath(route.key);
    if (!fullPath)
        throw new ts_common_1.BadImplementationException(`Route with key ${route.key} is not defined in routing module`);
    return React.createElement(react_router_dom_1.NavLink, Object.assign({}, _props, { to: fullPath, key: route.key, onMouseUp: e => (0, tools_1.mouseEventHandler)(e, {
            middle: () => window.open(fullPath, '_blank'),
        }) }), children);
};
exports.TS_NavLink = TS_NavLink;
exports.ModuleFE_RoutingV2 = new ModuleFE_RoutingV2_Class();
