"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_GenericTags = exports.ModuleFE_GenericTags_Class = exports.dispatch_onGenericTagsChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const v3_types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/v3_types");
const shared_1 = require("../shared");
exports.dispatch_onGenericTagsChanged = new v3_types_1.ThunderDispatcherV3('__onGenericTagsUpdated');
class ModuleFE_GenericTags_Class extends frontend_1.ModuleFE_v3_BaseApi {
    constructor() {
        super(shared_1.DBDef_GenericTags, exports.dispatch_onGenericTagsChanged);
        this._v1 = {
            '?': (0, frontend_1.apiWithBody)(shared_1.ApiDef_GenericTags._v1['?']),
            '??': (0, frontend_1.apiWithQuery)(shared_1.ApiDef_GenericTags._v1['??']),
        };
    }
}
exports.ModuleFE_GenericTags_Class = ModuleFE_GenericTags_Class;
exports.ModuleFE_GenericTags = new ModuleFE_GenericTags_Class();
