"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATS_ComponentPermissionKeys = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const permission_keys_editor_1 = require("./subEditors/permission-keys-editor");
class ATS_ComponentPermissionKeys extends frontend_1.SmartComponent {
    //######################### Life Cycle #########################
    async deriveStateFromProps(nextProps, state) {
        state !== null && state !== void 0 ? state : (state = (this.state ? Object.assign({}, this.state) : {}));
        return state;
    }
    //######################### Render #########################
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: '' },
            React.createElement(permission_keys_editor_1.PermissionKeysEditor, null));
    }
}
_a = ATS_ComponentPermissionKeys;
ATS_ComponentPermissionKeys.screen = {
    key: 'component-permission-keys',
    name: 'Component Permission Keys',
    renderer: _a,
    group: 'Permissions'
};
exports.ATS_ComponentPermissionKeys = ATS_ComponentPermissionKeys;
