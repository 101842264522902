/*
 * A typescript & react boilerplate with api call example
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';
import {AppPage, LL_VH_C, LL_V_C} from '@nu-art/thunderstorm/frontend';
import {Component_Login, Component_Register} from '@nu-art/user-account/frontend';
import './Page_Login.scss';


type Props = {}
type State = { register: boolean }

export class Page_Login
	extends AppPage<Props, State> {

	constructor(props: any) {
		super(props);
		this.state = {register: false};
	}

	protected deriveStateFromProps(nextProps: Props): State | undefined {
		return;
	}

	render() {
		return <LL_VH_C>
			<LL_V_C className="sm-login flex__space-between unselectable">
				<div className="sm-login__title">Shopify Manager Tool</div>
				{this.state.register ? <Component_Register/> : <Component_Login/>}
				<div className="sm-login__swap clickable"
						 onClick={() => this.setState({register: !this.state.register})}>{this.state.register ? 'login' : 'register'}</div>
			</LL_V_C>
		</LL_VH_C>;
	}
}
