"use strict";
/*
 * A generic push pub sub infra for webapps
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_PushPubSub = exports.ModuleFE_PushPubSub_Class = exports.pushSessionIdKey = exports.Command_SwToApp = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const index_1 = require("../../index");
const frontend_2 = require("@nu-art/firebase/frontend");
exports.Command_SwToApp = 'SwToApp';
exports.pushSessionIdKey = 'x-push-session-id';
const pushSessionId = new frontend_1.StorageKey(exports.pushSessionIdKey, false);
class ModuleFE_PushPubSub_Class extends ts_common_1.Module {
    constructor() {
        super();
        this.subscriptions = [];
        this.dispatch_pushMessage = new frontend_1.ThunderDispatcher('__onMessageReceived');
        this.timeout = 800;
        this.registerServiceWorker = async () => {
            if (!('serviceWorker' in navigator)) {
                this.logWarning('serviceWorker property is missing in navigator');
                return undefined;
            }
            const registration = await navigator.serviceWorker.register(`/${this.config.swFileName || 'pubsub-sw.js'}`);
            await registration.update();
            navigator.serviceWorker.oncontrollerchange = () => {
                this.logDebug('This page is now controlled by:', this.getControlingServiceWorker());
            };
            navigator.serviceWorker.onmessage = (event) => {
                this.processMessageFromSw(event.data);
            };
            return registration;
        };
        this.initApp = () => {
            this.runAsync('Initializing Firebase SDK and registering SW', async () => {
                var _a;
                const registration = await this.registerServiceWorker();
                const session = await frontend_2.ModuleFE_Firebase.createSession();
                this.messaging = session.getMessaging();
                this.messaging.onMessage((payload) => {
                    if (!payload.data)
                        return this.logInfo('No data passed to the message handler, I got this', payload);
                    this.processMessage(payload.data);
                });
                this.logDebug('Getting new Token');
                await this.getToken({ vapidKey: (_a = this.config) === null || _a === void 0 ? void 0 : _a.publicKeyBase64, serviceWorkerRegistration: registration });
                this.logDebug('GOT new Token');
                if (this.getControlingServiceWorker()) {
                    this.logDebug(`This page is currently controlled by: `, this.getControlingServiceWorker());
                }
            });
        };
        this.requestPermissions = async () => {
            if (this.isNotificationEnabled())
                return this.logVerbose('Notification already allowed');
            const permission = await Notification.requestPermission();
            if (permission !== 'granted')
                return this.logWarning('Notification was NOT granted');
            return this.logVerbose('Notification WAS granted');
        };
        this.getToken = async (options) => {
            if (!this.isNotificationEnabled())
                return;
            this.firebaseToken = await this.messaging.getToken(options);
            this.logVerbose('new token received: ' + this.firebaseToken);
        };
        this.processMessageFromSw = (data) => {
            this.logInfo('Got data from SW: ', data);
            if (!data.command || !data.message || data.command !== exports.Command_SwToApp)
                return;
            this.processMessage(data.message);
        };
        this.processMessage = (data) => {
            this.logInfo('process message', data);
            const arr = JSON.parse(data.messages);
            arr.forEach(s => {
                this.dispatch_pushMessage.dispatchModule(s);
            });
        };
        window.name = window.name || (0, ts_common_1.generateHex)(32);
        this.pushSessionId = pushSessionId.set(window.name);
        const register = (0, frontend_1.apiWithBody)(index_1.ApiDef_PushMessages.v1.register);
        this.v1 = {
            register: (subscription) => {
                this.subscribeImpl(subscription);
                return register(this.composeRegisterRequest());
            },
            unregister: (subscription) => {
                (0, ts_common_1.removeFromArray)(this.subscriptions, d => d.pushKey === subscription.pushKey && (0, ts_common_1.compare)(subscription.props, d.props));
                return register(this.composeRegisterRequest());
            },
            registerAll: (subscriptions) => {
                subscriptions.forEach(subscription => this.subscribeImpl(subscription));
                return register(this.composeRegisterRequest());
            }
        };
    }
    composeRegisterRequest() {
        if (!this.firebaseToken)
            throw new ts_common_1.ThisShouldNotHappenException('Firebase token not found');
        const body = {
            firebaseToken: this.firebaseToken,
            pushSessionId: this.getPushSessionId(),
            subscriptions: this.subscriptions.map(({ pushKey, props }) => ({ pushKey, props }))
        };
        return body;
    }
    init() {
        var _a, _b;
        if (((_a = this.config) === null || _a === void 0 ? void 0 : _a.registerOnInit) === false)
            return;
        if (!((_b = this.config) === null || _b === void 0 ? void 0 : _b.publicKeyBase64))
            throw new ts_common_1.ImplementationMissingException(`ModuleFE_PushPubSub config is missing the publicKeyBase64`);
        this.initApp();
    }
    getPushSessionId() {
        return this.pushSessionId;
    }
    getControlingServiceWorker() {
        return navigator.serviceWorker.controller;
    }
    deleteToken() {
        return this.messaging.deleteToken();
    }
    isNotificationEnabled() {
        return Notification.permission === 'granted';
    }
    subscribeImpl(subscription) {
        if (this.subscriptions.find(d => d.pushKey === subscription.pushKey && (0, ts_common_1.compare)(subscription.props, d.props)))
            return;
        (0, ts_common_1.addItemToArray)(this.subscriptions, subscription);
    }
}
exports.ModuleFE_PushPubSub_Class = ModuleFE_PushPubSub_Class;
exports.ModuleFE_PushPubSub = new ModuleFE_PushPubSub_Class();
