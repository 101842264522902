"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_Account = exports.SessionKey_FE = exports.dispatch_onAccountsUpdated = exports.dispatch_onLoginStatusChanged = exports.LoggedStatus = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const pako_1 = require("pako");
const ts_common_1 = require("@nu-art/ts-common");
const shared_1 = require("../../shared");
const consts_1 = require("../core/consts");
var LoggedStatus;
(function (LoggedStatus) {
    LoggedStatus[LoggedStatus["VALIDATING"] = 0] = "VALIDATING";
    LoggedStatus[LoggedStatus["LOGGED_OUT"] = 1] = "LOGGED_OUT";
    LoggedStatus[LoggedStatus["SESSION_TIMEOUT"] = 2] = "SESSION_TIMEOUT";
    LoggedStatus[LoggedStatus["LOGGED_IN"] = 3] = "LOGGED_IN";
})(LoggedStatus = exports.LoggedStatus || (exports.LoggedStatus = {}));
exports.dispatch_onLoginStatusChanged = new frontend_1.ThunderDispatcher('__onLoginStatusUpdated');
exports.dispatch_onAccountsUpdated = new frontend_1.ThunderDispatcher('__onAccountsUpdated');
class ModuleFE_Account_Class extends frontend_1.ModuleFE_v3_BaseApi {
    constructor() {
        super(shared_1.DBDef_Accounts, exports.dispatch_onAccountsUpdated);
        this.status = LoggedStatus.VALIDATING;
        this.getLoggedStatus = () => this.status;
        this.isStatus = (status) => this.status === status;
        this.onAccountCreated = async (response) => {
            await this.onEntriesUpdated([response]);
        };
        this.setLoggedStatus = (newStatus) => {
            if (this.status === newStatus)
                return;
            const pervStatus = this.status;
            this.status = newStatus;
            if (newStatus === LoggedStatus.LOGGED_IN || newStatus === LoggedStatus.LOGGED_OUT)
                consts_1.StorageKey_SessionTimeoutTimestamp.delete();
            this.logInfo(`Login status changes: ${LoggedStatus[pervStatus]} => ${LoggedStatus[newStatus]}`);
            exports.dispatch_onLoginStatusChanged.dispatchUI();
            exports.dispatch_onLoginStatusChanged.dispatchModule();
        };
        this.setLoginInfo = async (response, body, request) => {
            this.accountId = response._id;
            this.setLoggedStatus(LoggedStatus.LOGGED_IN);
        };
        this.onLoginCompletedSAML = async (response) => {
            if (!response.loginUrl)
                return;
            window.location.href = response.loginUrl;
        };
        this.composeSAMLUrl = () => {
            const params = new URLSearchParams(window.location.search);
            const paramsObj = {};
            for (const [key, value] of params) {
                paramsObj[key] = value;
            }
            return (0, ts_common_1.composeUrl)(window.location.origin + window.location.pathname, Object.assign(Object.assign({}, paramsObj), { [shared_1.QueryParam_SessionId]: shared_1.QueryParam_SessionId.toUpperCase() }));
        };
        this.getSessionId = () => {
            return consts_1.StorageKey_SessionId.get('');
        };
        this.logout = (url) => {
            this.vv1.logout({}).execute();
            consts_1.StorageKey_SessionId.delete();
            if (url)
                return window.location.href = url;
            this.setLoggedStatus(LoggedStatus.LOGGED_OUT);
        };
        this.vv1 = {
            registerAccount: (0, frontend_1.apiWithBody)(shared_1.ApiDefFE_Account.vv1.registerAccount, this.setLoginInfo),
            createAccount: (0, frontend_1.apiWithBody)(shared_1.ApiDefFE_Account.vv1.createAccount, this.onAccountCreated),
            changePassword: (0, frontend_1.apiWithBody)(shared_1.ApiDefFE_Account.vv1.changePassword, this.setLoginInfo),
            login: (0, frontend_1.apiWithBody)(shared_1.ApiDefFE_Account.vv1.login, this.setLoginInfo),
            loginSaml: (0, frontend_1.apiWithQuery)(shared_1.ApiDefFE_Account.vv1.loginSaml, this.onLoginCompletedSAML),
            logout: (0, frontend_1.apiWithQuery)(shared_1.ApiDefFE_Account.vv1.logout),
            createToken: (0, frontend_1.apiWithBody)(shared_1.ApiDefFE_Account.vv1.createToken),
            setPassword: (0, frontend_1.apiWithBody)(shared_1.ApiDefFE_Account.vv1.setPassword, this.setLoginInfo),
        };
    }
    __onAuthRequiredListener(request) {
        consts_1.StorageKey_SessionId.delete();
        consts_1.StorageKey_SessionTimeoutTimestamp.set((0, ts_common_1.currentTimeMillis)());
        return this.setLoggedStatus(LoggedStatus.SESSION_TIMEOUT);
    }
    getAccounts() {
        return this.cache.all().map(i => (0, ts_common_1.cloneObj)(i));
    }
    init() {
        if (!(0, ts_common_1.exists)(consts_1.StorageKey_DeviceId.get())) {
            const deviceId = (0, ts_common_1.generateHex)(32);
            console.log(`Defining new device Id: ${deviceId}`);
            consts_1.StorageKey_DeviceId.set(deviceId);
        }
        frontend_1.ModuleFE_XHR.addDefaultHeader(shared_1.HeaderKey_SessionId, () => consts_1.StorageKey_SessionId.get());
        frontend_1.ModuleFE_XHR.setDefaultOnComplete(async (__, _, request) => {
            if (!request.getUrl().startsWith(frontend_1.ModuleFE_XHR.getOrigin()))
                return;
            const responseHeader = request.getResponseHeader(shared_1.HeaderKey_SessionId);
            if (!responseHeader)
                return;
            const sessionId = typeof responseHeader === 'string' ? responseHeader : responseHeader[0];
            consts_1.StorageKey_SessionId.set(sessionId);
            this.processSessionStatus(sessionId);
        });
        const sessionId = (0, frontend_1.getQueryParameter)(shared_1.QueryParam_SessionId);
        if (sessionId) {
            consts_1.StorageKey_SessionId.set(String(sessionId));
            frontend_1.ModuleFE_BrowserHistory.removeQueryParam(shared_1.QueryParam_SessionId);
        }
        const _sessionId = consts_1.StorageKey_SessionId.get();
        if (_sessionId)
            return this.processSessionStatus(_sessionId);
        this.logDebug('login out user.... ');
        this.setLoggedStatus(LoggedStatus.LOGGED_OUT);
    }
    processSessionStatus(sessionId) {
        const now = (0, ts_common_1.currentTimeMillis)();
        try {
            const sessionData = this.decode(sessionId);
            if (!(0, ts_common_1.exists)(sessionData.session.expiration) || now > sessionData.session.expiration)
                return this.setLoggedStatus(LoggedStatus.SESSION_TIMEOUT);
            this.accountId = sessionData.account._id;
            this.sessionData = sessionData;
            return this.setLoggedStatus(LoggedStatus.LOGGED_IN);
        }
        catch (e) {
            return this.setLoggedStatus(LoggedStatus.SESSION_TIMEOUT);
        }
    }
    decode(sessionData) {
        if (!sessionData.length)
            return;
        return JSON.parse(new TextDecoder('utf8').decode((0, pako_1.ungzip)(Uint8Array.from(atob(sessionData), c => c.charCodeAt(0)))));
    }
}
class SessionKey_FE {
    constructor(key) {
        this.key = key;
    }
    get() {
        // @ts-ignore
        const sessionData = exports.ModuleFE_Account.sessionData;
        // if (!sessionData)
        // 	return undefined;
        if (!(this.key in sessionData))
            throw new ts_common_1.BadImplementationException(`Couldn't find key "${this.key}" in session data`);
        return sessionData[this.key];
    }
}
exports.SessionKey_FE = SessionKey_FE;
exports.ModuleFE_Account = new ModuleFE_Account_Class();
