"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_TranslationEntry = exports.ModuleFE_TranslationEntry_Class = exports.dispatch_onTranslationEntryChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const v3_types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/v3_types");
const shared_1 = require("../shared");
exports.dispatch_onTranslationEntryChanged = new v3_types_1.ThunderDispatcherV3('__onTranslationEntryUpdated');
class ModuleFE_TranslationEntry_Class extends frontend_1.ModuleFE_v3_BaseApi {
    constructor() {
        super(shared_1.DBDef_TranslationEntry, exports.dispatch_onTranslationEntryChanged);
        this._v1 = {
            '?': (0, frontend_1.apiWithBody)(shared_1.ApiDef_TranslationEntry._v1['?']),
            '??': (0, frontend_1.apiWithQuery)(shared_1.ApiDef_TranslationEntry._v1['??']),
        };
    }
}
exports.ModuleFE_TranslationEntry_Class = ModuleFE_TranslationEntry_Class;
exports.ModuleFE_TranslationEntry = new ModuleFE_TranslationEntry_Class();
