"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Space = void 0;
const React = require("react");
const tools_1 = require("../../utils/tools");
class TS_Space extends React.Component {
    render() {
        return React.createElement("div", { className: (0, tools_1._className)('ts-space', this.props.className), style: { height: this.props.height || '100%', width: this.props.width || '100%' } });
    }
}
exports.TS_Space = TS_Space;
