"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryStatuses = exports.DeliveryStatus_ReturnInProgress = exports.DeliveryStatus_Delivered = exports.DeliveryStatus_Cancelled = exports.DeliveryStatus_Delayed = exports.DeliveryStatus_FailedToDeliver = exports.DeliveryStatus_OnRoute = exports.DeliveryStatus_PickedUp = exports.DeliveryStatus_Ordered = exports.DeliveryStatus_NotOrdered = exports.DeliveryStatus_Unknown = exports.OrderStatuses = exports.OrderStatus_InProgress = exports.OrderStatus_ReturnInProgress = exports.OrderStatus_PreOrder = exports.OrderStatus_PendingPayment = exports.OrderStatus_OnTheWay = exports.OrderStatus_WaitingPickup = exports.OrderStatus_WaitingCurrier = exports.OrderStatus_OrderDelivery = exports.OrderStatus_NeedToPack = exports.OrderStatus_Completed = exports.OrderStatus_Cancelled = void 0;
exports.OrderStatus_Cancelled = 'cancelled';
exports.OrderStatus_Completed = 'completed';
exports.OrderStatus_NeedToPack = 'need-to-pack';
exports.OrderStatus_OrderDelivery = 'order-delivery';
exports.OrderStatus_WaitingCurrier = 'waiting-currier';
exports.OrderStatus_WaitingPickup = 'waiting-pickup';
exports.OrderStatus_OnTheWay = 'on-the-way';
exports.OrderStatus_PendingPayment = 'pending-payment';
exports.OrderStatus_PreOrder = 'pre-order';
exports.OrderStatus_ReturnInProgress = 'return-in-progress';
exports.OrderStatus_InProgress = 'in-progress';
exports.OrderStatuses = [
    exports.OrderStatus_PreOrder,
    exports.OrderStatus_WaitingCurrier,
    exports.OrderStatus_OrderDelivery,
    exports.OrderStatus_NeedToPack,
    exports.OrderStatus_Cancelled,
    exports.OrderStatus_OnTheWay,
    exports.OrderStatus_Completed,
    exports.OrderStatus_WaitingPickup,
    exports.OrderStatus_PendingPayment,
    exports.OrderStatus_ReturnInProgress,
    exports.OrderStatus_InProgress,
];
exports.DeliveryStatus_Unknown = 'unknown';
exports.DeliveryStatus_NotOrdered = 'not-ordered';
exports.DeliveryStatus_Ordered = 'ordered';
exports.DeliveryStatus_PickedUp = 'picked-up';
exports.DeliveryStatus_OnRoute = 'on-route';
exports.DeliveryStatus_FailedToDeliver = 'failed-to-deliver';
exports.DeliveryStatus_Delayed = 'delayed';
exports.DeliveryStatus_Cancelled = 'cancelled';
exports.DeliveryStatus_Delivered = 'delivered';
exports.DeliveryStatus_ReturnInProgress = 'return-in-progress';
exports.DeliveryStatuses = [
    exports.DeliveryStatus_NotOrdered,
    exports.DeliveryStatus_Ordered,
    exports.DeliveryStatus_PickedUp,
    exports.DeliveryStatus_OnRoute,
    exports.DeliveryStatus_FailedToDeliver,
    exports.DeliveryStatus_Delayed,
    exports.DeliveryStatus_Cancelled,
    exports.DeliveryStatus_Delivered,
    exports.DeliveryStatus_ReturnInProgress,
    exports.DeliveryStatus_Unknown,
];
