import * as React from 'react';
import {ModuleFE_ProductTags_} from '@modules/index';
import {TS_Route} from '@nu-art/thunderstorm/frontend';
import {DB_ProductTag} from '@app/shared';
import {Filter} from '@nu-art/ts-common';
import {EditorItem_ProductTag} from './EditorItem_ProductTag';
import {Page_ItemsEditor, Props_ItemsEditor} from '@nu-art/thunderstorm/frontend';


export class Page_ProductTagsV2
	extends Page_ItemsEditor<DB_ProductTag> {

	static Route: TS_Route<{ _id?: string }> = {path: 'product-tags', key: 'product-tags-editor', Component: this};

	static defaultProps: Partial<Props_ItemsEditor<DB_ProductTag>> = {
		pageTitle: 'Product Tags',
		modules: [ModuleFE_ProductTags_],
		module: ModuleFE_ProductTags_,
		filter: new Filter<DB_ProductTag>((productTag) => [productTag.title]),
		sort: (item: DB_ProductTag) => item.title,
		itemRenderer: (item: DB_ProductTag) => <>{item.title}</>,
		EditorRenderer: EditorItem_ProductTag,
		route: this.Route
	};
}

