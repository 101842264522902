import * as React from 'react';
import {ModuleFE_Vendors_} from '@modules/index';
import {TS_Route} from '@nu-art/thunderstorm/frontend';
import {DB_Vendor} from '@app/shared';
import {Filter} from '@nu-art/ts-common';
import {EditorItem_Vendor} from './EditorItem_Vendor';
import {Page_ItemsEditor, Props_ItemsEditor} from '@nu-art/thunderstorm/frontend';


export class Page_VendorsV2
	extends Page_ItemsEditor<DB_Vendor> {

	static Route: TS_Route<{ _id?: string }> = {path: 'vendors', key: 'vendors-editor', Component: this};

	static defaultProps: Partial<Props_ItemsEditor<DB_Vendor>> = {
		pageTitle: 'Vendors',
		modules: [ModuleFE_Vendors_],
		module: ModuleFE_Vendors_,
		filter: new Filter<DB_Vendor>((vendor) => [vendor.title, vendor.description || '']),
		sort: (item: DB_Vendor) => item.title,
		itemRenderer: (item: DB_Vendor) => <>{item.title}</>,
		EditorRenderer: EditorItem_Vendor,
		route: this.Route
	};
}

