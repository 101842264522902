"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_ProductTemplate = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const db_def_1 = require("./db-def");
exports.ApiDef_ProductTemplate = {
    _v1: {
        productToTemplate: { method: thunderstorm_1.HttpMethod.POST, path: `v1/${db_def_1.DBDef_ProductTemplate.entityName}/productToTemplate` },
        templateToProduct: { method: thunderstorm_1.HttpMethod.POST, path: `v1/${db_def_1.DBDef_ProductTemplate.entityName}/templateToProduct` },
    }
};
