import * as React from 'react';
import {ModuleFE_Routing, TS_Route} from '@nu-art/thunderstorm/frontend';
import {DB_StoreInventory} from '@app/shared';
import {ModuleFE_StoreInventory_} from '@modules/index';
import {Page_DBItemListV2, Props_DBItemListV2} from '@component/Page_DBItemListV2';
import {RouteKey_StoreInventoryNew} from '@consts/routes-consts';
import {Page_StoreInventory} from '@page/store/inventory/Page_StoreInventory';


type State = {
	filtered: DB_StoreInventory[],
	forceFilter: boolean
};

export class Page_StoreInventoryList
	extends Page_DBItemListV2<DB_StoreInventory, State> {

	static Route: TS_Route = {path: 'list/v1', key: 'store-inventory-list-v1', Component: this};

	static defaultProps: Partial<Props_DBItemListV2<DB_StoreInventory>> = {
		pageTitle: 'Store Inventory',
		keys: ['_id'],
		childRoute: Page_StoreInventory.Route,
		newRoute: RouteKey_StoreInventoryNew,
		moduleFE: ModuleFE_StoreInventory_,
		headerButtons: [{
			icon: 'add',
			tooltip: 'Tooltip_CreateNewStoreInventory',
			action: () => {
				ModuleFE_Routing.goToRoute(RouteKey_StoreInventoryNew);
			}
		}]
	};

	componentDidMount() {
		super.componentDidMount();
	}

	protected renderItems(items: DB_StoreInventory[]): React.ReactNode {
		if (!items)
			return '';

		return <div className="match_parent ll_v_l">
			{items.map(item => <div id={item._id} onClick={e => this.openPage(e)}>{item.title}</div>)}
		</div>;
	}
}



