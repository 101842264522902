"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component_GoogleSAMLLogin = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_styles_1 = require("@nu-art/ts-styles");
require("./Component_GoogleSAMLLogin.scss");
const ModuleFE_Account_1 = require("../../modules/ModuleFE_Account");
const consts_1 = require("../../core/consts");
const Component_GoogleSAMLLogin = (props) => {
    var _a;
    const onClick = async () => {
        const url = ModuleFE_Account_1.ModuleFE_Account.composeSAMLUrl();
        await ModuleFE_Account_1.ModuleFE_Account.vv1.loginSaml({ redirectUrl: url, deviceId: consts_1.StorageKey_DeviceId.get() }).executeSync();
    };
    return React.createElement(frontend_1.TS_BusyButton, { className: 'ts-account__saml-button', onClick: onClick },
        React.createElement("div", { className: 'ts-account__saml-button__text' }, (_a = props.text) !== null && _a !== void 0 ? _a : 'Login With Google'),
        React.createElement(ts_styles_1.TS_Icons.google.component, { className: 'ts-account__saml-button__icon' }));
};
exports.Component_GoogleSAMLLogin = Component_GoogleSAMLLogin;
