import * as React from 'react';
import {ModuleFE_ShippingMethod_} from '@modules/index';
import {DB_OrderShippingMethod} from '@app/shared';
import {TS_Route} from '@nu-art/thunderstorm/frontend';
import {Filter, filterInstances} from '@nu-art/ts-common';
import {EditorItem_ShippingOption} from './EditorItem_ShippingOption';
import {Page_ItemsEditor, Props_ItemsEditor} from '@nu-art/thunderstorm/frontend';


export class Page_ShippingOptionsV2
	extends Page_ItemsEditor<DB_OrderShippingMethod> {

	static Route: TS_Route<{ _id?: string }> = {path: 'shipping-options', key: 'shipping-options-editor', Component: this};

	static defaultProps: Partial<Props_ItemsEditor<DB_OrderShippingMethod>> = {
		pageTitle: 'Customers',
		modules: [ModuleFE_ShippingMethod_],
		module: ModuleFE_ShippingMethod_,
		filter: new Filter<DB_OrderShippingMethod>((shippingOption) => filterInstances([shippingOption.label])),
		sort: (shippingOption: DB_OrderShippingMethod) => shippingOption.label,
		itemRenderer: (shippingOption: DB_OrderShippingMethod) => <>{shippingOption.label}</>,
		EditorRenderer: EditorItem_ShippingOption,
		route: this.Route
	};
}
