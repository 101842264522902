import * as React from 'react';
import {LL_H_C, StorageKey, TS_Input} from '@nu-art/thunderstorm/frontend';
import {Currency, CurrencyValue} from '@app/shared';
import {OtherDropDown} from '@pah/app-dropdown';
import './Component_CurrencyValue.scss';


export const LastUsedCurrency = new StorageKey<Currency>('last-user-currency');

export function getLastUsedCurrency() {
	return LastUsedCurrency.get();
}

export type Props = {
	value: CurrencyValue,
	onChange: (value: CurrencyValue) => void,
	defaultCurrency?: Currency
}

export type State = {
	editing: boolean
}

export class Component_CurrencyValue
	extends React.Component<Props, State> {

	static defaultProps = {
		defaultCurrency: LastUsedCurrency.get()
	};

	render() {
		const currencyValue = this.props.value;
		const value = currencyValue.value || (currencyValue.value = 0);

		return <LL_H_C className="component__currency-value">
			<TS_Input
				className="value"
				type="number"
				onFocus={() => this.setState({editing: true})}
				onBlur={() => this.setState({editing: false})}
				value={`${value}`}
				onAccept={(value) => {
					currencyValue.value = +value;
					this.props.onChange(currencyValue);
				}}
				placeholder={'0.00'}
			/>
			<OtherDropDown.Currency selected={currencyValue.currency || this.props.defaultCurrency} onSelected={(currency) => {
				currencyValue.currency = currency;
				this.props.onChange(currencyValue);
			}}/>
		</LL_H_C>;

	}
}