"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_TranslationResource = exports.TranslationResource_Type = void 0;
const ts_common_1 = require("@nu-art/ts-common");
exports.TranslationResource_Type = ['android', 'ios', 'key-value'];
const Validator_ModifiableProps = {
    type: (0, ts_common_1.tsValidateValue)(exports.TranslationResource_Type),
    assetId: ts_common_1.tsValidateUniqueId,
    projectId: ts_common_1.tsValidateUniqueId,
};
const Validator_GeneratedProps = {};
exports.DBDef_TranslationResource = {
    modifiablePropsValidator: Validator_ModifiableProps,
    generatedPropsValidator: Validator_GeneratedProps,
    versions: ['1.0.0'],
    dbName: 'translation-resource',
    entityName: 'translation-resource',
};
