"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logicalXOR = exports.freeze = exports.exists = exports.resolveFunctionOrValue = exports.resolveContent = exports.functionThatReturnsTrue = exports.functionThatReturnsFalse = exports.voidFunction = exports.EmptyArray = exports.EmptyObject = exports.__stringify = exports.calculateJsonSizeMb = exports.createFilterPattern = exports.regexpCase = void 0;
const object_tools_1 = require("./object-tools");
function regexpCase(value, reg) {
    return value.match(new RegExp(reg)) || { input: undefined };
}
exports.regexpCase = regexpCase;
function createFilterPattern(rawFilter) {
    let filter = rawFilter || '';
    filter = filter.trim();
    filter = filter.replace(/\s+/, ' ');
    filter = filter.replace(new RegExp('(.)', 'g'), '.*?$1');
    filter.length === 0 ? filter = '.*?' : filter += '.*';
    return filter;
}
exports.createFilterPattern = createFilterPattern;
function calculateJsonSizeMb(data) {
    const number = JSON.stringify(data).length / 1024 / 1024;
    return Math.round(number * 100) / 100;
}
exports.calculateJsonSizeMb = calculateJsonSizeMb;
function __stringify(obj, pretty) {
    if (!obj)
        return '';
    if (typeof obj === 'string')
        return obj;
    if (Array.isArray(pretty))
        return `${(0, object_tools_1._keys)(obj).reduce((carry, key, idx) => {
            return carry + `  ${String(key)}: ${__stringify(obj[key], pretty.includes(key))}${idx !== (0, object_tools_1._keys)(obj).length - 1 && ',\n'}`;
        }, `{\n`)}\n}`;
    if (pretty)
        return JSON.stringify(obj, null, 2);
    return JSON.stringify(obj);
}
exports.__stringify = __stringify;
exports.EmptyObject = Object.freeze({});
exports.EmptyArray = Object.freeze([]);
exports.voidFunction = Object.freeze(() => __awaiter(void 0, void 0, void 0, function* () {
}));
exports.functionThatReturnsFalse = Object.freeze(() => false);
exports.functionThatReturnsTrue = Object.freeze(() => true);
const resolveContent = (content, ...param) => typeof content === 'function' ? content(param) : content;
exports.resolveContent = resolveContent;
exports.resolveFunctionOrValue = exports.resolveContent;
function exists(item) {
    return item !== undefined && item !== null;
}
exports.exists = exists;
function freeze(item) {
    return Object.freeze(item);
}
exports.freeze = freeze;
const logicalXOR = (a, b) => {
    return (a && !b) || (!a && b);
};
exports.logicalXOR = logicalXOR;
