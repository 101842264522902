"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.composeUrl = exports.composeQueryParams = void 0;
function composeQueryParams(params = {}) {
    return Object.keys(params).map((paramKey) => {
        let param = params[paramKey];
        if (param === undefined || param === null)
            return `${paramKey}=`;
        if (typeof param === 'function')
            param = param();
        return `${paramKey}=${encodeURIComponent(param)}`;
    }).join('&');
}
exports.composeQueryParams = composeQueryParams;
function composeUrl(url, params = {}) {
    const queryAsEncodedString = composeQueryParams(params);
    if (queryAsEncodedString.length)
        return `${url}?${queryAsEncodedString}`;
    return url;
}
exports.composeUrl = composeUrl;
