"use strict";
/*
 * ts-common is the basic building blocks of our typescript projects
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogClient_Browser = void 0;
const types_1 = require("./types");
const LogClient_1 = require("./LogClient");
const get_log_style_1 = require("../../tools/get-log-style");
class LogClient_Browser_class extends LogClient_1.LogClient {
    constructor() {
        super(...arguments);
        this.style = {
            base: {
                // 'background-color': '#fff',
                'padding': '2px 0px',
                'border-radius': '2px',
            },
            verbose: {
                'color': '#808080',
                'background-color': 'unset'
            },
            debug: {
                'color': '#6564c9',
            },
            info: {
                'color': '#189702',
            },
            warning: {
                'color': '#926E00',
            },
            error: {
                'color': '#B40000',
            }
        };
    }
    getColor(level, bold) {
        switch (level) {
            case types_1.LogLevel.Verbose:
                return (0, get_log_style_1.getLogStyle)(this.style.base, this.style.verbose);
            case types_1.LogLevel.Debug:
                return (0, get_log_style_1.getLogStyle)(this.style.base, this.style.debug);
            case types_1.LogLevel.Info:
                return (0, get_log_style_1.getLogStyle)(this.style.base, this.style.info);
            case types_1.LogLevel.Warning:
                return (0, get_log_style_1.getLogStyle)(this.style.base, this.style.warning);
            case types_1.LogLevel.Error:
                return (0, get_log_style_1.getLogStyle)(this.style.base, this.style.error);
            default:
                return (0, get_log_style_1.getLogStyle)({ 'color': '#000000' });
        }
    }
    logMessage(level, bold, prefix, toLog) {
        for (const param of toLog) {
            if (typeof param === 'string') {
                console.log(`%c${prefix}${param}`, this.getColor(level, bold));
                continue;
            }
            if (typeof param === 'object') {
                console.log(`%c${prefix}`, this.getColor(level, bold), param);
                continue;
            }
            console.log(`%c${prefix}`, this.getColor(level, bold), param);
        }
    }
}
exports.LogClient_Browser = new LogClient_Browser_class();
