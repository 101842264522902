"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_AppTools_Default = void 0;
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
const Layouts_1 = require("../Layouts");
class TS_AppTools_Default extends ComponentSync_1.ComponentSync {
    deriveStateFromProps(nextProps, state) {
    }
    render() {
        return React.createElement(Layouts_1.LL_V_L, { id: 'app-tools-default' },
            React.createElement("div", { className: 'title' }, "Welcome To App Tools!"),
            React.createElement("div", { className: 'sub-title' }, "Pick a screen on the left to show"));
    }
}
_a = TS_AppTools_Default;
TS_AppTools_Default.Route = {
    key: 'app-tools-default',
    path: '',
    index: true,
    Component: _a,
};
exports.TS_AppTools_Default = TS_AppTools_Default;
