"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Dialog = void 0;
const React = require("react");
require("./TS_Dialog.scss");
const ts_common_1 = require("@nu-art/ts-common");
const ComponentSync_1 = require("../../core/ComponentSync");
const TS_BusyButton_1 = require("../TS_BusyButton");
const TS_Button_1 = require("../TS_Button");
const ModuleFE_Dialog_1 = require("../../component-modules/ModuleFE_Dialog");
const TS_ErrorBoundary_1 = require("../TS_ErrorBoundary");
const Layouts_1 = require("../Layouts");
const tools_1 = require("../../utils/tools");
/**
 * ##TS_Dialog
 *
 * This class defines the logic and render behavior for dialogs in the system.
 * Any dialog class in the system is meant to inherit this class to utilize its features.
 */
class TS_Dialog extends ComponentSync_1.ComponentSync {
    constructor() {
        // ######################## Life Cycle ########################
        super(...arguments);
        // ######################## KeyMap ########################
        /**
         * A map held per instance connecting a (keyboard)key to a dialog button.
         * This map is filled in by calling _keyActionMapCreator with buttons.
         * @protected
         */
        this.keyActionMap = {};
        /**
         * A function to fill in keyActionMap.
         *
         * This function receives an array of buttons and for each button will connect any associated
         * keys to the button, to be used by dialogKeyEventHandler.
         *
         * Keep in mind:
         *
         * 1. If an associated key is found in more than one button, an error will be thrown.
         *
         * 2. This function must be called in the constructor of the inheriting class.
         *
         * @param buttons - an array of buttons of type DialogButton
         */
        this._keyActionMapCreator = () => {
            (0, ts_common_1.flatArray)((0, ts_common_1.filterInstances)((0, ts_common_1._values)(this.buttons()))).forEach(button => {
                var _b;
                if (!((_b = button.associatedKeys) === null || _b === void 0 ? void 0 : _b.length))
                    return;
                const ref = React.createRef();
                button.associatedKeys.forEach(key => {
                    if (this.keyActionMap[key])
                        throw new ts_common_1.BadImplementationException(`Trying to assign action to key ${key} more than once`);
                    this.keyActionMap[key] = ref;
                });
            });
        };
        this.dialogKeyEventHandler = (e) => {
            var _b, _c;
            e.persist();
            if (e.key === 'Escape' && !this.state.dialogIsBusy)
                this.closeDialog();
            (_c = (_b = this.keyActionMap[e.key]) === null || _b === void 0 ? void 0 : _b.current) === null || _c === void 0 ? void 0 : _c.click();
        };
        // ######################## Utils ########################
        this._buttonsCreator = (buttons) => {
            if (!(buttons === null || buttons === void 0 ? void 0 : buttons.length))
                return undefined;
            return React.createElement(React.Fragment, null, buttons.map((button, i) => {
                var _b;
                const ref = button.associatedKeys ? this.keyActionMap[button.associatedKeys[0]] : undefined;
                return ((_b = button.renderer) !== null && _b !== void 0 ? _b : TS_Dialog.normalButton)(button, i, ref);
            }));
        };
        this.closeDialog = () => {
            ModuleFE_Dialog_1.ModuleFE_Dialog.close();
        };
        // ######################## Render - Header ########################
        this.dialogHeader = (headerContent) => {
            return headerContent && React.createElement("div", { className: 'ts-dialog__header' }, headerContent);
        };
        this.renderHeader = () => {
            return undefined;
        };
        // ######################## Render - Main ########################
        this.dialogBody = (mainContent) => {
            return mainContent && React.createElement("div", { className: 'ts-dialog__main' }, mainContent);
        };
        this.renderBody = () => {
            return undefined;
        };
        // ######################## Render - Buttons ########################
        this.dialogButtons = (buttons) => {
            if ((0, ts_common_1._values)(buttons).every(arr => !arr || !arr.length))
                return undefined;
            return React.createElement("div", { className: 'ts-dialog__buttons' },
                buttons.left && React.createElement("div", { className: 'ts-dialog__buttons__left' }, this._buttonsCreator(buttons.left)),
                buttons.center &&
                    React.createElement("div", { className: 'ts-dialog__buttons__center' }, this._buttonsCreator(buttons.center)),
                buttons.right && React.createElement("div", { className: 'ts-dialog__buttons__right' }, this._buttonsCreator(buttons.right)));
        };
        this.errorButtonRenderer = () => {
            return React.createElement(TS_Button_1.TS_Button, { className: 'ts-error-boundary__button', onClick: this.closeDialog }, "Close Dialog");
        };
        this.buttons = () => {
            return {};
        };
        this.performAction = (action) => {
            this.setState({ dialogIsBusy: true }, async () => {
                await action();
                if (this.mounted)
                    this.setState({ dialogIsBusy: false });
            });
        };
    }
    componentDidMount() {
        this._keyActionMapCreator();
        const dialog = document.getElementById(this.props.dialogId);
        dialog === null || dialog === void 0 ? void 0 : dialog.focus();
        this.forceUpdate();
    }
    deriveStateFromProps(nextProps, state) {
        return {};
    }
    // ######################## Render ########################
    render() {
        let buttons = {};
        let mainContent;
        let headerContent;
        try {
            buttons = this.buttons();
            mainContent = this.renderBody();
            headerContent = this.renderHeader();
        }
        catch (err) {
            if (!this.state.error)
                this.setState({ error: err });
        }
        return React.createElement(TS_ErrorBoundary_1.TS_ErrorBoundary, { buttonRenderer: this.errorButtonRenderer, error: this.state.error },
            React.createElement(Layouts_1.LL_V_L, { className: (0, tools_1._className)('ts-dialog', this.props.className), id: this.props.dialogId, tabIndex: -1, onKeyDown: this.dialogKeyEventHandler },
                this.dialogHeader(headerContent),
                this.dialogBody(mainContent),
                this.dialogButtons(buttons)));
    }
}
_a = TS_Dialog;
TS_Dialog.busyButton = (button, index, ref) => {
    return React.createElement(TS_BusyButton_1.TS_BusyButton, { className: button.className, innerRef: ref, onClick: async () => { var _b; return await ((_b = button.onClick) === null || _b === void 0 ? void 0 : _b.call(button)); }, disabled: button.disabled, onDisabledClick: button.onDisabledClick, key: `button-${index}` }, button.content);
};
TS_Dialog.normalButton = (button, index, ref) => {
    return React.createElement(TS_Button_1.TS_Button, { className: button.className, ref: ref, onClick: button.onClick, onDisabledClick: button.onDisabledClick, disabled: button.disabled, key: `button-${index}` }, button.content);
};
TS_Dialog.Button_Cancel = {
    content: 'Cancel',
    onClick: () => ModuleFE_Dialog_1.ModuleFE_Dialog.close(),
    associatedKeys: ['Escape']
};
exports.TS_Dialog = TS_Dialog;
