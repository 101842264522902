"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_CopyToClipboard = void 0;
const React = require("react");
const tools_1 = require("../../utils/tools");
const modules_1 = require("../../modules");
const TS_CopyToClipboard = (_props) => {
    const { textToCopy } = _props, props = __rest(_props, ["textToCopy"]);
    return (React.createElement("div", Object.assign({}, props, { onClick: async (e) => {
            var _a;
            (0, tools_1.stopPropagation)(e);
            await modules_1.ModuleFE_Thunderstorm.copyToClipboard(textToCopy);
            (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, e);
        } }), props.children || textToCopy));
};
exports.TS_CopyToClipboard = TS_CopyToClipboard;
