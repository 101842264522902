"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionGroupsEditor = void 0;
const React = require("react");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ts_common_1 = require("@nu-art/ts-common");
const editor_base_1 = require("./editor-base");
const module_pack_1 = require("../../core/module-pack");
const ui_props_1 = require("../ui-props");
const ts_styles_1 = require("@nu-art/ts-styles");
class PermissionGroupsEditor extends editor_base_1.EditorBase {
    constructor() {
        //######################### Static #########################
        super(...arguments);
        this.module = module_pack_1.ModuleFE_PermissionsGroup;
        this.itemName = 'Permission Group';
        this.itemNamePlural = 'Permission Groups';
        this.itemDisplay = (item) => item.label;
        //######################### Render #########################
        this.renderLevels = () => {
            const group = this.state.editedItem;
            if (!group)
                return '';
            return React.createElement(ui_props_1.MultiSelect.AccessLevel, { editable: group, prop: 'accessLevelIds', className: 'api-editor__editor__level-list', itemRenderer: (levelId, onDelete) => {
                    const level = module_pack_1.ModuleFE_PermissionsAccessLevel.cache.unique(levelId);
                    const domain = module_pack_1.ModuleFE_PermissionsDomain.cache.unique(level.domainId);
                    return React.createElement("div", { key: levelId, className: 'api-editor__editor__level-list__item' },
                        React.createElement(ts_styles_1.TS_Icons.x.component, { onClick: onDelete }),
                        `${domain.namespace}: ${level.name} (${level.value})`);
                } });
        };
        this.editorContent = () => {
            const group = this.state.editedItem;
            return React.createElement(React.Fragment, null,
                React.createElement(frontend_1.TS_PropRenderer.Vertical, { label: 'Label' },
                    React.createElement(frontend_1.TS_Input, { type: 'text', value: group.item.label, onChange: value => this.setProperty('label', value) })),
                this.renderLevels());
        };
    }
    //######################### Life Cycle #########################
    __onPermissionsGroupsUpdated(...params) {
        if ([frontend_1.EventType_Update, frontend_1.EventType_Create].includes(params[0])) {
            const level = params[1];
            this.reDeriveState({
                selectedItemId: level._id,
                editedItem: new frontend_1.EditableDBItem(level, module_pack_1.ModuleFE_PermissionsGroup)
            });
        }
        if (params[0] === frontend_1.EventType_Delete)
            this.reDeriveState({ selectedItemId: undefined, editedItem: undefined });
    }
    async deriveStateFromProps(nextProps, state) {
        state.items = module_pack_1.ModuleFE_PermissionsGroup.cache.filter(group => !(0, ts_common_1.exists)(this.props.projectId) || group.projectId === this.props.projectId);
        if (!state.editedItem && state.items.length > 0) {
            state.editedItem = new frontend_1.EditableDBItem(state.items[0], module_pack_1.ModuleFE_PermissionsGroup);
            state.selectedItemId = state.items[0]._id;
        }
        return state;
    }
}
PermissionGroupsEditor.defaultProps = {
    modules: [module_pack_1.ModuleFE_PermissionsGroup]
};
exports.PermissionGroupsEditor = PermissionGroupsEditor;
