"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Receiver = exports.Messenger = exports.ModuleFE_WindowMessenger = void 0;
const ts_common_1 = require("@nu-art/ts-common");
class ModuleFE_WindowMessenger_Class extends ts_common_1.Module {
    constructor() {
        //######################### Static #########################
        super(...arguments);
        this.receivers = [];
    }
    //######################### Life Cycle #########################
    addReceiver(receiver) {
        if (this.receivers.includes(receiver))
            return;
        this.receivers.push(receiver);
        if (this.receivers.length > 0 && !this.listener)
            window.addEventListener('message', this.listener = (e) => {
                const { data, origin } = e;
                this.receivers.forEach(receiver => receiver.execute(origin, data));
            });
    }
    removeReceiver(receiver) {
        (0, ts_common_1.removeItemFromArray)(this.receivers, receiver);
        if (this.receivers.length === 0) {
            window.removeEventListener('message', this.listener);
            delete this.listener;
        }
    }
    sendMessage(message, target) {
        target.postMessage(message);
    }
    createMessenger(target) {
        return new Messenger(target);
    }
    createReceiver(origin) {
        return new Receiver(origin);
    }
}
exports.ModuleFE_WindowMessenger = new ModuleFE_WindowMessenger_Class();
//Message Sender
class Messenger {
    constructor(target) {
        this.target = target;
    }
    sendMessage(message) {
        exports.ModuleFE_WindowMessenger.sendMessage(message, this.target);
    }
}
exports.Messenger = Messenger;
//Message Receiver
class Receiver extends ts_common_1.Logger {
    constructor(origin = '.*') {
        super();
        this.messageProcessorMap = {};
        this.setTag(`Receiver (${origin})`);
        this.origin = origin;
        this.regex = new RegExp(this.origin);
    }
    mount() {
        exports.ModuleFE_WindowMessenger.addReceiver(this);
        return this;
    }
    unmount() {
        exports.ModuleFE_WindowMessenger.removeReceiver(this);
    }
    addProcessor(key, processor) {
        this.messageProcessorMap[key] = processor;
        return this;
    }
    execute(origin, message) {
        if (!this.regex.test(origin))
            return;
        const processor = this.messageProcessorMap[message.key];
        if (!processor) {
            this.logDebug('No message processor defined for key ${message.key}');
            return;
        }
        processor(message);
    }
}
exports.Receiver = Receiver;
