import * as React from 'react';
import {LL_H_C, LL_V_L, ModuleFE_Toaster, TS_Input, TS_TextArea} from '@nu-art/thunderstorm/frontend';
import {DB_MessageTemplate} from '@app/shared';
import {Replacer} from '@nu-art/ts-common';
import './EditorItem_MessagesTemplate.scss';
import {Item_Editor, Props_ItemEditor} from '@nu-art/thunderstorm/frontend';


export class EditorItem_MessagesTemplate
	extends Item_Editor<DB_MessageTemplate, Props_ItemEditor<DB_MessageTemplate>, { editing: boolean }> {

	render() {
		const editable = this.props.editable;
		const replacer = new Replacer();
		let value = editable.item.template || '';
		if (!editable)
			try {
				value = replacer.replace(value);
			} catch (e: unknown) {
				if (e instanceof Error)
					ModuleFE_Toaster.toastError(e.message);
			}

		return <LL_V_L className="messages-template__editor">
			<LL_H_C>
				<TS_Input
					style={{width: 300}}
					value={editable.item.label}
					onBlur={async value => editable.update('label', value)}
					type="text"/>
			</LL_H_C>

			<TS_TextArea
				className="message-template__text-area__editing"
				type={'text'}
				value={value}
				onFocus={() => this.setState({editing: true})}
				onBlur={(value) => this.setState({editing: false}, () => editable.update('template', value))}/>
		</LL_V_L>;
	}
}
