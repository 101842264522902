import * as React from 'react';
import {AppPage, stopPropagation, StorageKey, TS_Input, TS_TextArea, ModuleFE_XHR} from '@nu-art/thunderstorm/frontend';
import {FirebaseAnalyticsModule} from '@nu-art/firebase/frontend';
import {HttpMethod} from '@nu-art/thunderstorm';
import {ICONSV4} from '@res/icons';
import {__stringify, removeItemFromArray} from '@nu-art/ts-common';


type GQL_History = {
	label: string,
	variable: string,
	input: string
	_id: string
};

type State = GQL_History & {
	output: string
};

class Pg_GraphQL
	extends AppPage<{}, State> {

	history = new StorageKey<GQL_History[]>('graphql-history');
	variable = new StorageKey<string>('graphql-variable');
	_id = new StorageKey<string>('graphql-history-id');
	label = new StorageKey<string>('graphql-label');
	input = new StorageKey<string>('graphql-input');
	output = new StorageKey<string>('graphql-output');
	static defaultProps = {
		pageTitle: () => PgDev_GraphQL.name
	};

	constructor(p: {}) {
		super(p);
		// @ts-ignore
		FirebaseAnalyticsModule.setCurrentScreen(this.pageTitle);
	}

	protected deriveStateFromProps(nextProps: {}): State | undefined {
		return {input: this.input?.get(''), output: this.output?.get(''), variable: this.variable?.get(''), label: this.label?.get(''), _id: this._id?.get('')};
	}

	render() {
		const gqlHistories = this.history.get([]);
		return <div className="ll_v_l">
			<div className="ll_h_c">
				<TS_Input
					id="label"
					value={this.state.label}
					type="text"
					onChange={(label: string) => {
						this.label.set(label);
						return this.setState({label});
					}}
					style={{width: 250, height: 20}}/>
				{ICONSV4.add({onClick: this.save})}
			</div>

			<div className="ll_h_t match_parent" style={{minWidth: 1600}}>
				<div className="ll_v_l" style={{width: '45%', height: 800}}>
					<TS_TextArea
						id="input"
						value={this.state.input}
						type="text"
						onChange={(input: string) => {
							this.input.set(input);
							return this.setState({input});
						}}
						style={{width: 600, height: '60%'}}/>
					<TS_TextArea
						onChange={(variable => {
							this.variable.set(variable);
							return this.setState({variable});
						})}
						type={'text'}
						id={`variable-value`}
						value={this.state.variable}
						style={{width: 600, height: '40%'}}
					/>
				</div>
				{ICONSV4.send({onClick: this.makeCall})}
				<TS_TextArea
					id="output"
					style={{width: '45%', height: 800}}
					value={this.state.output}
					type="text"
					onChange={(output: string) => {
						this.output.set(output);
						return this.setState({output});
					}}/>
				<div className="ll_v_l">
					{gqlHistories.map(h => {
						return <div className="ll_h_c">
							{ICONSV4.sync({
								onClick: () => {
									this.setState({...h});
								}
							})}
							<TS_Input
								id={`label-${h._id}`}
								value={h.label}
								type="text"
								onChange={(label: string) => {
									h.label = label;
									this.history.set(gqlHistories);
									this.forceUpdate();
								}}
								style={{width: 250, height: 20}}/>
							{ICONSV4.save({
								onClick: () => {
									this.updateHistoryItem(h, gqlHistories);
									this.forceUpdate();
								}
							})}
							{ICONSV4.remove({
								onClick: () => {
									removeItemFromArray(gqlHistories, h);
									this.history.set(gqlHistories);
									this.forceUpdate();
								}
							})}
						</div>;
					})}
				</div>
			</div>
		</div>;
	}

	private save = async (e: React.MouseEvent) => {
		stopPropagation(e);
		const gqlHistories = this.history.get([]);
		const historyItem = {} as GQL_History;
		gqlHistories.push(historyItem);
		this.updateHistoryItem(historyItem, gqlHistories);
	};

	private updateHistoryItem = (historyItem: GQL_History, gqlHistories: GQL_History[]) => {
		historyItem._id = this.state._id;
		historyItem.input = this.state.input;
		historyItem.label = this.state.label;
		historyItem.variable = this.state.variable;
		this.history.set(gqlHistories);
		this.forceUpdate();
	};

	private makeCall = async (e: React.MouseEvent) => {
		stopPropagation(e);
		ModuleFE_XHR
			.createRequest<any>({method: HttpMethod.POST, path: '/v1/dev/query-graphql'})
			.setBodyAsJson({data: this.state.input, variables: this.state.variable})
			.execute(response => {
				const output = __stringify(response.output, true);
				this.output.set(output);
				this.setState({output: output});
			});
	};
}

export const PgDev_GraphQL = {name: 'DevTool - GraphQL', renderer: Pg_GraphQL};
