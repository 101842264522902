"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_CustomerContact = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ts_common_2 = require("@nu-art/ts-common");
const shopify_1 = require("../../../shopify");
const validators_1 = require("../../../validators");
const Validator_CustomerContact = Object.assign(Object.assign({}, shopify_1.ShopifyItem_validator), { firstName: (0, validators_1.tsValidateStringLength)(30), lastName: (0, validators_1.tsValidateStringLength)(30), note: (0, validators_1.tsValidateStringLength)(500, false), email: ts_common_1.tsValidateEmail, ordersCount: (0, ts_common_2.tsValidateNumber)(), googleContactId: (0, validators_1.tsValidateStringLength)(30, false), phone: (0, validators_1.tsValidateStringLength)(30, false), _createdAt: ts_common_2.tsValidateMustExist, _updatedAt: ts_common_2.tsValidateMustExist, company: ts_common_2.tsValidateOptional });
exports.DBDef_CustomerContact = {
    validator: Validator_CustomerContact,
    dbName: 'customers',
    entityName: 'customer',
    uniqueKeys: ['storeId', 'shopifyId'],
    versions: ['1.0.1', '1.0.0']
};
