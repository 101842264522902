"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_InventoryItem = exports.IndexKey_InventoryItems = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ts_common_2 = require("@nu-art/ts-common");
const shopify_1 = require("../../../shopify");
exports.IndexKey_InventoryItems = 'by-inventory-id';
const Validator_InventoryItem = Object.assign(Object.assign({}, shopify_1.StoreItem_validator), { variantId: ts_common_1.tsValidateUniqueId, productId: ts_common_1.tsValidateUniqueId, inventoryId: ts_common_1.tsValidateUniqueId, quantity: (0, ts_common_2.tsValidateIsInRange)([[-10, 10000]]), history: ts_common_2.tsValidateMustExist });
exports.DBDef_InventoryItem = {
    validator: Validator_InventoryItem,
    dbName: 'inventory-items',
    entityName: 'inventory-item',
    uniqueKeys: ['storeId', 'inventoryId', 'variantId'],
    indices: [{ id: exports.IndexKey_InventoryItems, keys: ['inventoryId'] }],
    versions: ['1.0.1', '1.0.0']
};
