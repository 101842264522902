"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutOfStockPolicy_Values = exports.FulfillmentPolicy_Values = exports.DBDef_ProductTemplate = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ts_common_2 = require("@nu-art/ts-common/");
const validators_1 = require("../validators");
const ts_common_3 = require("@nu-art/ts-common");
const shopify_1 = require("../shopify");
const Validator_ProductTemplate = Object.assign(Object.assign({}, shopify_1.StoreItem_validator), { baseProductId: ts_common_1.tsValidateOptionalId, aliases: (0, ts_common_3.tsValidate_OptionalArray)({
        key: ts_common_2.tsValidateMustExist,
        value: ts_common_2.tsValidateMustExist
    }), label: validators_1.tsValidateStringOneLine, titleId: ts_common_1.tsValidateUniqueId, descriptionId: ts_common_1.tsValidateUniqueId, properties: (0, ts_common_3.tsValidate_OptionalArray)({
        hidden: (0, ts_common_2.tsValidateBoolean)(false),
        keyId: ts_common_1.tsValidateUniqueId,
        values: ts_common_2.tsValidateMustExist,
    }), instances: (0, ts_common_3.tsValidate_OptionalArray)({
        barcode: ts_common_2.tsValidateOptional,
        productId: ts_common_1.tsValidateUniqueId,
        syncTimestamp: (0, ts_common_2.tsValidateNumber)(false),
        propertyGroupId: ts_common_1.tsValidateUniqueId,
        propertyValueId: ts_common_1.tsValidateUniqueId
    }), price: (0, ts_common_2.tsValidateIsInRange)([[0, 5000]]), sku: validators_1.tsValidateOptionalSku, _translationIds: ts_common_2.tsValidateMustExist, taxable: (0, ts_common_2.tsValidateBoolean)(false), metaTitleId: ts_common_1.tsValidateUniqueId, metaDescriptionId: ts_common_1.tsValidateUniqueId, variantConfigIds: ts_common_1.tsValidate_optionalArrayOfUniqueIds, tagIds: ts_common_1.tsValidate_optionalArrayOfUniqueIds, variantIds: ts_common_1.tsValidate_optionalArrayOfUniqueIds, imageIds: ts_common_1.tsValidate_optionalArrayOfUniqueIds, vendorId: validators_1.tsValidateStringOneLine, fulfillmentPolicy: (0, ts_common_2.tsValidateValue)(['manual', 'automatic'], false), outOfStockPolicy: (0, ts_common_2.tsValidateValue)(['allow', 'deny'], false), countryOfOrigin: validators_1.tsValidateCountryOfOrigin, variants: (0, ts_common_3.tsValidate_OptionalArray)({
        dbId: ts_common_1.tsValidateOptionalId,
        variantOptionIds: ts_common_2.tsValidateMustExist,
        weight: ts_common_2.tsValidateOptional,
        price: (0, ts_common_2.tsValidateIsInRange)([[0, 5000]], false),
        barcode: validators_1.tsValidateBarcode,
        sku: validators_1.tsValidateOptionalSku,
        countryOfOrigin: validators_1.tsValidateCountryOfOrigin,
        taxable: (0, ts_common_2.tsValidateBoolean)(false),
    }), _isDraft: (0, ts_common_2.tsValidateBoolean)(false) });
exports.DBDef_ProductTemplate = {
    validator: Validator_ProductTemplate,
    dbName: 'product-templates',
    entityName: 'product-template',
};
exports.FulfillmentPolicy_Values = ['manual', 'automatic'];
exports.OutOfStockPolicy_Values = ['deny', 'continue'];
