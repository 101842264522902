import {currentTimeMillis, formatTimestamp} from '@nu-art/ts-common';


export type ExpectNumericProp<T, K extends keyof T> = T[K] extends (number | undefined) ? K : never
export type ExpectStringProp<T, K extends keyof T> = T[K] extends (string | undefined) ? K : never

export const calculateDayTime = () => {
	const hour = +formatTimestamp('HH', currentTimeMillis());
	if (hour >= 0 && hour < 6)
		return 'night';

	if (hour >= 6 && hour < 12)
		return 'morning';

	if (hour >= 12 && hour < 15)
		return 'noon';

	if (hour >= 15 && hour < 19)
		return 'after-noon';

	if (hour >= 19 && hour < 22)
		return 'evening';

	if (hour >= 22 && hour < 24)
		return 'night';
};

