"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_Archiving = void 0;
const types_1 = require("../types");
exports.ApiDef_Archiving = {
    vv1: {
        hardDeleteAll: { method: types_1.HttpMethod.GET, path: 'v1/archiving/hard-delete-all' },
        hardDeleteUnique: { method: types_1.HttpMethod.POST, path: 'v1/archiving/hard-delete-unique' },
        getDocumentHistory: { method: types_1.HttpMethod.GET, path: 'v1/archiving/get-document-history' }
    }
};
