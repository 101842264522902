"use strict";
/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_Checkbox = void 0;
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
const tools_1 = require("../../utils/tools");
require("./TS_Checkbox.scss");
/**
 * Checkbox made simple..
 *
 * <b>SCSS:</b>
 * ```scss
 * .ts-checkbox ts-checkbox__checked/ts-checkbox__unchecked ts-checkbox__disabled ts-checkbox__rounded {
 * 	 .ts-checkbox__inner ts-checkbox__checked/ts-checkbox__unchecked ts-checkbox__disabled ts-checkbox__rounded {
 *
 * 	 }
 * }
 * ```
 */
class TS_Checkbox extends ComponentSync_1.ComponentSync {
    constructor(p) {
        super(p);
        this.onCheckboxClick = (e) => {
            var _a, _b;
            if (this.state.disabled)
                return;
            (_b = (_a = this.props).onCheck) === null || _b === void 0 ? void 0 : _b.call(_a, !this.state.checked, e);
        };
    }
    deriveStateFromProps(nextProps, state = {}) {
        var _a;
        state.checked = (_a = nextProps.checked) !== null && _a !== void 0 ? _a : false;
        state.disabled = nextProps.disabled;
        return state;
    }
    render() {
        const checkedClass = this.state.checked && 'ts-checkbox__button__checked' || 'ts-checkbox__button__unchecked';
        const disabledClass = this.state.disabled && 'ts-checkbox__button__disabled';
        const roundedClass = this.props.rounded && 'ts-checkbox__button__rounded';
        const className = (0, tools_1._className)('ts-checkbox__button', disabledClass, checkedClass, roundedClass);
        const innerClassName = (0, tools_1._className)('ts-checkbox__button__inner', disabledClass, checkedClass, roundedClass);
        return React.createElement("div", { className: (0, tools_1._className)('ts-checkbox', this.props.className), onClick: this.onCheckboxClick },
            React.createElement("div", { id: this.props.id, className: className }, this.props.icon ? this.props.icon : React.createElement("div", { className: innerClassName })),
            this.props.children && React.createElement("div", { className: "ts-checkbox__content" }, this.props.children));
    }
}
exports.TS_Checkbox = TS_Checkbox;
