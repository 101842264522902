import {ModuleFE_BaseApi} from '@nu-art/thunderstorm/frontend';

import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';
import {ApiCallerEventType} from '@nu-art/thunderstorm/frontend/core/db-api-gen/types';
import {DB_MessageTemplate, DBDef_MessageTemplate} from '@app/shared';


export interface OnMessageTemplatesUpdated {
	__onMessageTemplatesUpdated: (...params: ApiCallerEventType<DB_MessageTemplate>) => void;
}

export const dispatch_onMessageTemplatesChanged = new ThunderDispatcher<OnMessageTemplatesUpdated, '__onMessageTemplatesUpdated'>('__onMessageTemplatesUpdated');

export class ModuleFE_MessageTemplate_Class
	extends ModuleFE_BaseApi<DB_MessageTemplate> {

	constructor() {
		super(DBDef_MessageTemplate, dispatch_onMessageTemplatesChanged);
	}
}

export const ModuleFE_MessageTemplates = new ModuleFE_MessageTemplate_Class();
export const ModuleFE_MessageTemplates_ = ModuleFE_MessageTemplates as unknown as ModuleFE_BaseApi<any, any>;

