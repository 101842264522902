"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateErrorToastContent = void 0;
const React = require("react");
const ts_common_1 = require("@nu-art/ts-common");
const ResolveDependencyToast_1 = require("./resolve-dependency-toast/ResolveDependencyToast");
const isDependencyError = (e) => {
    var _a, _b;
    return (((_a = e.errorResponse) === null || _a === void 0 ? void 0 : _a.error.type) === 'has-dependencies' || ((_b = e.errorResponse) === null || _b === void 0 ? void 0 : _b.error.type) === 'has-dependencies-on-inner-value');
};
const generateErrorToastContent = (e, content, additionalData) => {
    if (isDependencyError(e))
        return React.createElement(ResolveDependencyToast_1.default, { dependencyConflicts: e.errorResponse.error.body, deletedEntity: additionalData });
    if ((0, ts_common_1.isErrorOfType)(e, ts_common_1.ValidationException))
        return React.createElement("div", null, `Invalid input for fields: ${(0, ts_common_1.filterInstances)((0, ts_common_1._keys)(e.result)).join(', ')}`);
    return React.createElement("div", null, "Something went wrong, try again later...");
};
exports.generateErrorToastContent = generateErrorToastContent;
