import React from 'react';
import {BaseComponent} from '@nu-art/thunderstorm/frontend/core/ComponentBase';
import {ModuleFE_Dialog, TS_DragAndDrop} from '@nu-art/thunderstorm/frontend';
import './Dialog_ImportShipment.scss';


type Props = {}
type State = {}

export class Dialog_ImportShipment
	extends BaseComponent<Props, State> {

	constructor(p: Props) {
		super(p);
	}

	protected _deriveStateFromProps(nextProps: Props, state: Partial<State> | undefined) {
		return state as State;
	}

	static show() {
		ModuleFE_Dialog.show(<Dialog_ImportShipment/>);
	}

	render() {
		return <div className="import-shipment__dialog dialog">
			<TS_DragAndDrop
				validate={TS_DragAndDrop.validateFilesBySuffix('json')}
				onChange={(acceptedFiles: File[], rejectedFiles: File[]) => {
					console.log(acceptedFiles, rejectedFiles);
				}}/>
		</div>;
	}
}