import * as React from 'react';
import './PropertyRenderer.scss';


type Props = {
	label: string | (() => string)
	orientation?: 'horizontal' | 'vertical'
	children: JSX.Element
}

export const PropertyRenderer = (props: Props) => {
	return (
		<div className={props.orientation === 'vertical' ? 'prop-renderer__vertical' : 'prop-renderer__horizontal'}>
			<div className="prop-renderer__label">{typeof props.label === 'function' ? props.label() : props.label}</div>
			<div className="prop-renderer__editor">{props.children}</div>
		</div>
	);
};
