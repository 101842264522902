import {ThunderDispatcher} from '@nu-art/thunderstorm/frontend';


export interface OnMenuButtonClicked {
	__onMenuButtonClicked(): void;
}

export const dispatcher_onMenuButtonClicked = new ThunderDispatcher<OnMenuButtonClicked, '__onMenuButtonClicked'>('__onMenuButtonClicked');

export interface OnDebugListener {
	__onToggleDebugVisibility(): void;

	__onShowDebugData(id: string, title: string, data: any, expandedPaths?: string[]): void;

	__onUpdateDebugData(id: string, title: string, data: any, expandedPaths?: string[]): void;
}

export const dispatcher_onToggleDebugVisibility = new ThunderDispatcher<OnDebugListener, '__onToggleDebugVisibility'>('__onToggleDebugVisibility');

/**
 * @param id: string
 * @param title: string
 * @param data: any
 * @param expandedPaths?: string[]
 */
export const dispatcher_onShowDebugData = new ThunderDispatcher<OnDebugListener, '__onShowDebugData'>('__onShowDebugData');
export const dispatcher_onUpdateDebugData = new ThunderDispatcher<OnDebugListener, '__onUpdateDebugData'>('__onUpdateDebugData');

