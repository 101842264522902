import {DB_CustomerOrder} from '@app/shared';
import {ModuleFE_Dialog, Props_TSDialog, TS_Dialog} from '@nu-art/thunderstorm/frontend';
import React from 'react';
import './Dialog_OrderGraph.scss';
import {Component_OrderLifecycle} from '@pah/customer-order/components/Component_OrderLifecycle';


type Props = {
	order: Partial<DB_CustomerOrder>,
}

type State = {}

export class Dialog_OrderGraph
	extends TS_Dialog<Props, State> {

	static defaultProps = {
		dialogId: 'order-graph',
		className: 'order-graph'
	};

	constructor(p: Props & Props_TSDialog) {
		super(p);
	}

	static show(order: Partial<DB_CustomerOrder>) {
		ModuleFE_Dialog.show(<Dialog_OrderGraph order={order}/>);
	}

	protected renderHeader = (): React.ReactNode => <div>Graph for order: #{this.props.order.orderNumber}</div>;
	protected renderBody = (): React.ReactNode => <Component_OrderLifecycle dbOrder={this.props.order as DB_CustomerOrder}/>;
}