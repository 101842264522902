"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PgDev_ProxyV2 = exports.ServiceAccountEditor = void 0;
const React = require("react");
require("./ServiceAccountEditor.scss");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const ProxyServiceAccount_EditorRenderer_1 = require("./ProxyServiceAccount_EditorRenderer");
const ts_common_1 = require("@nu-art/ts-common");
const ModuleFE_Account_1 = require("../modules/ModuleFE_Account");
class ServiceAccountEditor extends frontend_1.ComponentAsync {
    constructor() {
        super(...arguments);
        this.renderSelectedOptions = () => {
            return React.createElement(frontend_1.LL_V_L, { className: 'selected-options' },
                this.state.token && React.createElement(frontend_1.TS_TextArea, { type: 'text', value: this.state.token }),
                React.createElement(frontend_1.LL_H_C, null,
                    "TTL: ",
                    React.createElement(frontend_1.TS_Input, { type: 'number', onChange: (value) => this.setState({ ttl: value !== undefined ? value : undefined }), placeholder: 'ttl' })),
                this.renderAccountOptions(this.getSelected()));
        };
        this.renderAccountOptions = (item) => {
            return React.createElement(frontend_1.LL_H_C, null,
                item && React.createElement(frontend_1.TS_Button, { onClick: async () => {
                        if (!item)
                            return;
                        if (item === this.state.newAccount)
                            this.setState({ newAccount: undefined });
                        else {
                            await this.getEditableItem(item).delete();
                            this.setState({ newAccount: undefined, selectedIndex: undefined });
                        }
                    } }, "Delete"),
                React.createElement(frontend_1.TS_Button, { onClick: async () => {
                        if (!(item === null || item === void 0 ? void 0 : item._id))
                            return;
                        const response = await ModuleFE_Account_1.ModuleFE_Account.vv1.createToken({
                            accountId: item._id,
                            ttl: this.state.ttl || 0
                        }).executeSync();
                        this.setState({ token: response.token });
                    } }, "Create Token"));
        };
    }
    async deriveStateFromProps(nextProps) {
        var _a;
        const state = (_a = Object.assign({}, this.state)) !== null && _a !== void 0 ? _a : {};
        state.serviceAccounts = ModuleFE_Account_1.ModuleFE_Account.cache.allMutable();
        return state;
    }
    render() {
        return React.createElement(frontend_1.LL_V_L, { className: 'editor' },
            React.createElement(frontend_1.TS_Button, { className: 'add-new-button', onClick: () => {
                    this.setState({ newAccount: {} });
                } }, "Create New Service Account"),
            React.createElement(frontend_1.LL_H_C, null,
                React.createElement(frontend_1.LL_V_L, null, (0, ts_common_1.filterInstances)([this.state.newAccount, ...this.state.serviceAccounts]).map((serviceAcc, index) => {
                    const editableItem = this.getEditableItem(serviceAcc);
                    const compensatedIndex = index - (this.state.newAccount ? 1 : 0);
                    return React.createElement("div", { key: (0, ts_common_1.generateUUID)(), className: (0, frontend_1._className)('account', this.isSelected(serviceAcc) ? 'selected' : ''), onClick: () => {
                            if (serviceAcc === this.state.newAccount)
                                return;
                            this.setState({ selectedIndex: compensatedIndex });
                        } },
                        React.createElement(ProxyServiceAccount_EditorRenderer_1.ProxyServiceAccount_EditorRenderer, { key: (0, ts_common_1.generateUUID)(), editable: editableItem, onCancel: (item) => {
                                if (item.item === this.state.newAccount)
                                    this.setState({ newAccount: undefined });
                            } }));
                })),
                !!this.state.serviceAccounts.length && this.renderSelectedOptions()));
    }
    getEditableItem(item) {
        return new frontend_1.EditableItem(item, async (item) => {
            this.logInfo('save');
            try {
                // await ModuleFE_Account.vv1.createAccount(item).executeSync();
                if (item === this.state.newAccount)
                    this.setState({ newAccount: undefined });
            }
            catch (e) {
                if ((0, ts_common_1.isErrorOfType)(e, ts_common_1.ValidationException))
                    frontend_1.ModuleFE_Toaster.toastError((0, ts_common_1.__stringify)(e.result, true));
            }
            return;
        }, async (item) => {
            this.logInfo('delete');
            if (!item._id) {
                this.logWarning('Can\'t delete an item with no _id!');
                return;
            }
            // await ModuleFE_v2_RemoteProxy.v1.delete(item).executeSync();
        });
    }
    getSelected() {
        return this.state.selectedIndex != undefined ? this.state.serviceAccounts[this.state.selectedIndex] : undefined;
    }
    isSelected(item) {
        return item === this.getSelected();
    }
}
exports.ServiceAccountEditor = ServiceAccountEditor;
exports.PgDev_ProxyV2 = { name: 'Proxy V2 Editor', renderer: ServiceAccountEditor };
