import {ComponentSync, TS_DragAndDrop} from '@nu-art/thunderstorm/frontend';
import {BadImplementationException, filterInstances, removeFromArrayByIndex} from '@nu-art/ts-common';
import * as React from 'react';
import {ICONSV4} from '@res/icons';
import {AssetType} from '@app/shared';
import {AssetRenderer} from '@component/AssetRenderer';
import {Asset} from './consts';
import {ModuleFE_AssetUploader} from '@nu-art/file-upload/frontend';
import {DB_Asset, FileStatus, OnFileStatusChanged} from '@nu-art/file-upload';
import {AssetSubmitterRenderer} from './AssetSubmitterRenderer';


type AssetsSubmitterProps<Type extends string = string> = {
	open: boolean
	id?: string
	assetType: AssetType<Type>
	minCount: number
	maxCount: number
	onChange: AssetsListener
}

type State = {
	state: 'Idle' | 'Choosing'
	type?: string
	assets: Asset[]
}
export type AssetsListener = (assets: DB_Asset[]) => void;

export class AssetsSubmitter
	extends ComponentSync<AssetsSubmitterProps, State>
	implements OnFileStatusChanged {

	static defaultProps = {
		minCount: 1,
		maxCount: 1
	};

	constructor(props: AssetsSubmitterProps) {
		super(props);
	}

	protected deriveStateFromProps(nextProps: AssetsSubmitterProps): State | undefined {
		return {assets: this.state?.assets || [], state: this.state?.state || nextProps.open ? 'Choosing' : 'Idle'};
	}

	render() {
		switch (this.state.state) {
			case 'Idle':
				return ICONSV4.add({
					onClick: () => {
						this.setState({state: 'Choosing'});
					}
				});
			case 'Choosing':
				return this.renderChoosing();

		}
		return;
	}

	private renderChoosing() {
		return <div className="ll_v_l">
			<TS_DragAndDrop
				id="shipment-docs"
				validate={TS_DragAndDrop.validateFilesBySuffix(this.props.assetType.fileTypes)}
				onChange={(assetsFiles, rejectedFiles) => {
					const assets = assetsFiles.map(file => ({file, url: AssetRenderer.srcFromFile(file), mimeType: AssetRenderer.mimeTypeFromFile(file)}));
					this.setState({assets}, () => assets.forEach((asset, index) => this.saveFile(index)));
				}}>
				{this.state.assets.length === 0 ?
					<div className="ll_v_c">
						<div>Drop Files Here</div>
						<div>or</div>
						<div>Click to Select Files</div>
					</div> :
					<div className="ll_v_l" style={{height: '100%', width: '500'}}>
						{this.state.assets.map((asset, index) => {
							return <AssetSubmitterRenderer
								key={index}
								index={index}
								asset={asset}
								onDelete={this.removeFile}
								onSave={this.saveFile}
							/>;
						})}
					</div>

				}
			</TS_DragAndDrop>
		</div>;
	}

	private saveFile = (index: number) => {
		const assetsDocs = ModuleFE_AssetUploader.upload([this.state.assets[index].file], this.props.assetType.key);
		this.state.assets[index].feId = assetsDocs[0].feId;
		this.forceUpdate();
	};

	private removeFile = (index: number) => {
		removeFromArrayByIndex(this.state.assets, index);
		this.forceUpdate();
	};

	__onFileStatusChanged(id: string): void {
		if (ModuleFE_AssetUploader.getFullFileInfo(id)?.status !== FileStatus.Completed)
			return;

		const completedFEAssets = this.state.assets.filter(asset => {
			return ModuleFE_AssetUploader.getFullFileInfo(asset.feId)?.status === FileStatus.Completed;
		});

		completedFEAssets.forEach(asset => AssetRenderer.revokeFileUrl(asset.url));
		this.setState({
			assets: this.state.assets.filter(asset => {
				return ModuleFE_AssetUploader.getFullFileInfo(asset.feId)?.status !== FileStatus.Completed;
			})
		});

		this.props.onChange(filterInstances(completedFEAssets.map(asset => ModuleFE_AssetUploader.getFullFileInfo(asset.feId)?.asset)));
	}
}

export class AssetsSubmitterBuilder<Options extends string> {

	private props: Partial<AssetsSubmitterProps> = {
		minCount: 1,
		maxCount: 1
	};
	private _onChanged!: AssetsListener;

	constructor(assetType: AssetType, id?: string) {
		this.props.assetType = assetType;
		this.props.id = id || assetType.key;
	}

	setMaxAssets(maxAssets: number) {
		if (maxAssets <= 0)
			throw new BadImplementationException(`Max assets cannot be lesser or equal to zero!!! got: ${maxAssets}`);

		this.props.minCount = maxAssets;
		return this;
	}

	setMinAssets(minAssets: number) {
		if (minAssets <= 0)
			throw new BadImplementationException(`Max assets cannot be lesser or equal to zero!!! got: ${minAssets}`);

		this.props.minCount = minAssets;
		return this;
	}

	render() {
		const props = this.props as AssetsSubmitterProps;
		return <AssetsSubmitter {...props} onChange={this._onChanged}/>;
	}

	onChange(onChanged: AssetsListener) {
		this._onChanged = onChanged;
		return this;
	}
}

