"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_TranslationResource = exports.ModuleFE_TranslationResource_Class = exports.dispatch_onTranslationResourceChanged = void 0;
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const v3_types_1 = require("@nu-art/thunderstorm/frontend/core/db-api-gen/v3_types");
const shared_1 = require("../shared");
exports.dispatch_onTranslationResourceChanged = new v3_types_1.ThunderDispatcherV3('__onTranslationResourceUpdated');
class ModuleFE_TranslationResource_Class extends frontend_1.ModuleFE_v3_BaseApi {
    constructor() {
        super(shared_1.DBDef_TranslationResource, exports.dispatch_onTranslationResourceChanged);
        this._v1 = {
            '?': (0, frontend_1.apiWithBody)(shared_1.ApiDef_TranslationResource._v1['?']),
            '??': (0, frontend_1.apiWithQuery)(shared_1.ApiDef_TranslationResource._v1['??']),
        };
    }
}
exports.ModuleFE_TranslationResource_Class = ModuleFE_TranslationResource_Class;
exports.ModuleFE_TranslationResource = new ModuleFE_TranslationResource_Class();
