"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TS_ComponentTransition = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const React = require("react");
const ComponentSync_1 = require("../../core/ComponentSync");
const tools_1 = require("../../utils/tools");
class TS_ComponentTransition extends ComponentSync_1.ComponentSync {
    constructor() {
        super(...arguments);
        this.triggerNextTimeout = () => {
            switch (this.state.transitionPhase) {
                case 'mount':
                    setTimeout(() => {
                        this.setState({ transitionPhase: 'enter' });
                    }, this.props.mountTimeout);
                    break;
                case 'enter':
                    setTimeout(() => {
                        var _a, _b;
                        this.setState({ transitionPhase: 'enter-done' });
                        (_b = (_a = this.props).onEnterDone) === null || _b === void 0 ? void 0 : _b.call(_a);
                    }, this.props.transitionTimeout);
                    break;
                case 'exit':
                    setTimeout(() => {
                        this.setState({ transitionPhase: 'exit-done' });
                    }, this.props.transitionTimeout);
                    break;
                case 'exit-done':
                    setTimeout(() => {
                        this.setState({ transitionPhase: 'unmount' });
                    }, this.props.unmountTimeout);
            }
        };
    }
    shouldReDeriveState(nextProps) {
        var _a;
        return ((_a = this.props) === null || _a === void 0 ? void 0 : _a.trigger) !== nextProps.trigger;
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }
    deriveStateFromProps(nextProps, state) {
        state !== null && state !== void 0 ? state : (state = this.state ? Object.assign({}, this.state) : {});
        //First load
        if (!state.transitionPhase)
            state.transitionPhase = nextProps.trigger
                ? nextProps.skipAnimationOnMount ? 'enter-done' : 'mount'
                : 'unmount';
        //Triggered change
        else
            state.transitionPhase = nextProps.trigger ? 'mount' : 'exit';
        return state;
    }
    render() {
        this.triggerNextTimeout();
        if (this.state.transitionPhase === 'unmount')
            return '';
        if (!this.props.children)
            throw new ts_common_1.BadImplementationException('Component Expects at least one child');
        return React.createElement(React.Fragment, null, React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                className: (0, tools_1._className)(child.props.className, this.props.transitionPrefix ? `${this.props.transitionPrefix}-${this.state.transitionPhase}` : this.state.transitionPhase),
                style: { transitionDuration: `${this.props.transitionTimeout}ms` }
            });
        }));
    }
}
TS_ComponentTransition.defaultProps = {
    unmountTimeout: 0,
    mountTimeout: 0,
};
exports.TS_ComponentTransition = TS_ComponentTransition;
