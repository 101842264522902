"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_ActionProcessing = void 0;
const types_1 = require("../types");
const ts_common_1 = require("@nu-art/ts-common");
exports.ApiDef_ActionProcessing = {
    vv1: {
        execute: { method: types_1.HttpMethod.POST, path: 'v1/action-processor/execute', timeout: 5 * ts_common_1.Minute },
        list: { method: types_1.HttpMethod.GET, path: 'v1/action-processor/list' },
    }
};
