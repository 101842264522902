"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditableItem = void 0;
const ts_common_1 = require("@nu-art/ts-common");
/**
 * A utility class for editing any item of type T.
 * It encapsulates an item along with functions to save and delete the item.
 * This class can be used with any type of item that can be modified, saved, and deleted.
 *
 * @template T The type of the item.
 */
class EditableItem {
    /**
     * Constructs an EditableItem instance.
     *
     * @param item The item to be edited.
     * @param saveAction The function to be called when saving the item.
     * @param deleteAction The function to be called when deleting the item.
     */
    constructor(item, saveAction, deleteAction) {
        this._autoSave = EditableItem.AUTO_SAVE;
        this.item = Object.isFrozen(item) ? (0, ts_common_1.cloneObj)(item) : item;
        this.saveAction = saveAction;
        this.deleteAction = deleteAction;
    }
    /**
     * Set the auto-save mode.
     *
     * @param mode The auto-save mode.
     * @returns The instance itself for method chaining.
     */
    setAutoSave(mode) {
        this._autoSave = mode;
        return this;
    }
    /**
     * Set the value of a specific property in the item.
     *
     * @template K The type of the key.
     * @param key The key of the property.
     * @param value The new value of the property.
     * @returns A boolean indicating whether the value has been set.
     */
    set(key, value) {
        const finalValue = (0, ts_common_1.resolveContent)(value);
        if (!(0, ts_common_1.exists)(finalValue) && (0, ts_common_1.exists)(this.item[key])) {
            delete this.item[key];
            return true;
        }
        if ((0, ts_common_1.compare)(finalValue, this.item[key]))
            return false;
        this.item[key] = finalValue;
        return true;
    }
    /**
     * Update the value of a specific property in the item and perform auto-save if enabled.
     *
     * @template K The type of the key.
     * @param key The key of the property.
     * @param value The new value of the property.
     * @returns A promise representing the auto-save operation if enabled, undefined otherwise.
     */
    async update(key, value) {
        if (this.set(key, value))
            return this.autoSave();
    }
    autoSave() {
        if (this._autoSave)
            return this.save();
    }
    /**
     * Save the item by calling the saveAction function.
     *
     * @returns The promise returned by the saveAction function.
     */
    async save() {
        return this.saveAction(this.item);
    }
    /**
     * Create a new instance of EditableItem with the same properties and behaviors as the current instance.
     *
     * @param item The item of the new instance.
     * @returns The new instance.
     */
    clone(item) {
        return new EditableItem(item || this.item, this.saveAction, this.deleteAction).setAutoSave(this._autoSave);
    }
    /**
     * Delete the item by calling the deleteAction function.
     *
     * @template K The type of the key.
     * @returns A promise representing the delete operation.
     */
    async delete() {
        return this.deleteAction(this.item);
    }
    /**
     * Return a new EditableItem that represents a property of the current item.
     *
     * @template K The type of the key.
     * @param key The key of the property.
     * @param defaultValue The default value of the property.
     * @returns The new EditableItem.
     */
    editProp(key, defaultValue) {
        return new EditableItem(this.item[key] || (this.item[key] = defaultValue), async (value) => {
            this.set(key, value);
            return this.autoSave();
        }, () => this.delete()).setAutoSave(true);
    }
}
EditableItem.AUTO_SAVE = false;
exports.EditableItem = EditableItem;
