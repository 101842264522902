"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiDef_ShopifyItemSync = void 0;
const thunderstorm_1 = require("@nu-art/thunderstorm");
const ApiDef_ShopifyItemSync = (dbDef) => {
    return {
        _v1: {
            shopify: {
                sync: { method: thunderstorm_1.HttpMethod.POST, path: `v1/${dbDef.entityName}/shopify/reSync` }
            }
        }
    };
};
exports.ApiDef_ShopifyItemSync = ApiDef_ShopifyItemSync;
