"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleFE_AssetUploader = exports.ModuleFE_AssetUploader_Class = void 0;
/*
 * Permissions management system, define access level for each of
 * your server apis, and restrict users by giving them access levels
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const frontend_1 = require("@nu-art/thunderstorm/frontend");
const shared_1 = require("../../shared");
const ModuleBase_AssetUploader_1 = require("../../shared/modules/ModuleBase_AssetUploader");
const ModuleFE_PushPubSub_1 = require("@nu-art/push-pub-sub/frontend/modules/ModuleFE_PushPubSub");
class ModuleFE_AssetUploader_Class extends ModuleBase_AssetUploader_1.ModuleBase_AssetUploader {
    constructor() {
        super();
        this.dispatch_fileStatusChange = new frontend_1.ThunderDispatcher('__onFileStatusChanged');
        this.vv1 = {
            uploadFile: (0, frontend_1.apiWithBody)(shared_1.ApiDef_AssetUploader.vv1.uploadFile),
            getUploadUrl: (0, frontend_1.apiWithBody)(shared_1.ApiDef_AssetUploader.vv1.getUploadUrl),
            processAssetManually: (0, frontend_1.apiWithQuery)(shared_1.ApiDef_AssetUploader.vv1.processAssetManually),
        };
    }
    upload(files, key, _public) {
        return this.uploadImpl(files.map((file => {
            return {
                name: file.name,
                mimeType: file.type,
                key,
                file,
                public: _public
            };
        })));
    }
    dispatchFileStatusChange(id) {
        this.dispatch_fileStatusChange.dispatchUI(id);
    }
    async subscribeToPush(toSubscribe) {
        const subscriptions = toSubscribe.map(r => ({ pushKey: shared_1.PushKey_FileUploaded, props: { feId: r.asset.feId } }));
        await ModuleFE_PushPubSub_1.ModuleFE_PushPubSub.v1.registerAll(subscriptions).executeSync();
    }
    __onMessageReceived(notification) {
        var _a, _b, _c;
        super.__onMessageReceived(notification);
        if (((_a = notification.data) === null || _a === void 0 ? void 0 : _a.status) === shared_1.FileStatus.Completed || ((_c = (_b = notification.data) === null || _b === void 0 ? void 0 : _b.status) === null || _c === void 0 ? void 0 : _c.startsWith('Error')))
            ModuleFE_PushPubSub_1.ModuleFE_PushPubSub.v1.unregister({ pushKey: shared_1.PushKey_FileUploaded, props: notification.props });
    }
}
exports.ModuleFE_AssetUploader_Class = ModuleFE_AssetUploader_Class;
exports.ModuleFE_AssetUploader = new ModuleFE_AssetUploader_Class();
