"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DBDef_Vendor = void 0;
const ts_common_1 = require("@nu-art/ts-common");
const ts_common_2 = require("@nu-art/ts-common");
const validators_1 = require("../../validators");
const types_1 = require("../types");
const types_2 = require("./types");
const Validator_Vendor = Object.assign(Object.assign({}, types_1.ShopifyItem_validator), { logoId: ts_common_1.tsValidateOptionalId, title: validators_1.tsValidateStringOneLine, description: ts_common_1.tsValidateOptionalId, currency: validators_1.tsValidateCurrency, invoiceParsers: ts_common_2.tsValidateOptional, variantsParserType: (0, ts_common_2.tsValidateValue)(types_2.CONST_KnownCsvParserTypes, false), barcodeErrorMap: (0, ts_common_2.tsValidateArray)({
        wrong: validators_1.tsValidateBarcode,
        right: validators_1.tsValidateBarcode,
        text: validators_1.tsValidateStringOneLine
    }, false) });
exports.DBDef_Vendor = {
    validator: Validator_Vendor,
    dbName: 'vendors',
    entityName: 'vendor',
    uniqueKeys: ['storeId', 'shopifyId'],
    versions: ['1.0.1', '1.0.0']
};
