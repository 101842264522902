"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionsEditableComponent = void 0;
const React = require("react");
const PermissionsComponent_1 = require("./PermissionsComponent");
const frontend_1 = require("@nu-art/thunderstorm/frontend");
class PermissionsEditableComponent extends PermissionsComponent_1.PermissionsComponent {
    constructor() {
        super(...arguments);
        this.renderFallback = () => {
            if (!this.props.value)
                return React.createElement(React.Fragment, null);
            const className = (0, frontend_1._className)('permissions-editable__value', this.props.className);
            return React.createElement("div", { className: className, style: this.props.style }, this.props.value);
        };
        this.renderPermitted = () => {
            if (this.props.forceLock)
                return this.renderFallback();
            return React.createElement(React.Fragment, null, this.props.children);
        };
    }
    async deriveStateFromProps(nextProps, state) {
        state = await super.deriveStateFromProps(nextProps, state);
        state.forceLock = nextProps.forceLock;
        return state;
    }
}
exports.PermissionsEditableComponent = PermissionsEditableComponent;
