"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryServices = exports.DeliveryService_Gett = exports.DeliveryService_GetPackage = exports.DeliveryService_Adam = exports.DeliveryService_Jaden = exports.DeliveryService_Tapuz = exports.DeliveryService_Rimon = exports.DeliverySystemInfra_Gett = exports.DeliverySystemInfra_GetPackage = exports.DeliverySystemInfra_Human = exports.DeliverySystemInfra_Baldar = void 0;
exports.DeliverySystemInfra_Baldar = 'baldar';
exports.DeliverySystemInfra_Human = 'human';
exports.DeliverySystemInfra_GetPackage = 'get-package';
exports.DeliverySystemInfra_Gett = 'gett';
exports.DeliveryService_Rimon = {
    serviceType: exports.DeliverySystemInfra_Baldar,
    key: 'rimon',
    label: 'Rimon',
    color: '#FF000054',
    domain: '',
    url_fetchCities: '',
    url_fetchStreets: '',
    url_loginPage: '',
    url_login: '',
    url_deliveryOrderPage: '',
    url_deliveryOrder: '',
    url_checkStatus: '',
    customer: {
        checkStatus: 'https://bit.ly/3Xoutsz'
    }
};
exports.DeliveryService_Tapuz = {
    hasIntegration: true,
    tls: true,
    serviceType: exports.DeliverySystemInfra_Baldar,
    key: 'tapuz',
    label: 'Tapuz',
    color: '#FDB3357F',
    domain: 'crm.tapuzdelivery.co.il',
    url_fetchStreets: 'Baldar/NewDelivery.aspx/GetStreets',
    url_fetchCities: 'Baldar/NewDelivery.aspx/GetCitiesAC',
    url_loginPage: 'Baldar/Login.aspx',
    url_login: 'Baldar/login.aspx/Login',
    url_deliveryOrderPage: 'Baldar/NewDelivery.aspx',
    url_deliveryOrder: 'Baldar/NewDelivery.aspx/SaveDelivery1',
    url_checkStatus: 'Baldar/DeliveryStatus.aspx/GetStatus',
    customer: {
        checkStatus: 'https://bit.ly/3BOK1NH'
    },
    pickupInfo: {
        address: {
            cityId: '1373',
            streetId: '28993',
            streetName: 'יורם קניוק',
            houseNumber: '5',
            entrance: '6',
            floor: '7',
            apartmentNumber: '16'
        },
        phoneNumber: '',
        name: 'אדם',
        companyName: 'Petit Fawn'
    }
};
exports.DeliveryService_Jaden = {
    serviceType: exports.DeliverySystemInfra_Human,
    key: 'jaden',
    label: 'Jaden',
    color: '#54F0FA33',
};
exports.DeliveryService_Adam = {
    serviceType: exports.DeliverySystemInfra_Human,
    key: 'adam',
    label: 'Adam',
    color: '#54F0FA33',
};
exports.DeliveryService_GetPackage = {
    serviceType: exports.DeliverySystemInfra_GetPackage,
    key: 'get-package',
    label: 'Get Package',
    color: '#0FA20332',
};
exports.DeliveryService_Gett = {
    serviceType: exports.DeliverySystemInfra_Gett,
    key: 'gett',
    label: 'Gett',
    color: '#F5920B32',
};
exports.DeliveryServices = [exports.DeliveryService_Tapuz, exports.DeliveryService_Rimon, exports.DeliveryService_GetPackage, exports.DeliveryService_Jaden, exports.DeliveryService_Adam, exports.DeliveryService_Gett];
